import LOCATION_CONST from './const';
//for country
export const getCountryRequest = (data) => ({
  type: LOCATION_CONST.COUNTRY_LIST_REQUEST,
  payload: data,
});

export const getCountrySuccess = (response) => ({
  type: LOCATION_CONST.COUNTRY_LIST_SUCCESS,
  payload: response,
});

export const getCountryFailed = (error) => ({
  type: LOCATION_CONST.COUNTRY_LIST_FAILED,
  payload: error,
});
//for state
export const getStateByCountryRequest = ({requestObj, isCompareLocation}) => ({
  type: LOCATION_CONST.STATE_LIST_REQUEST,
  payload: {
    requestObj,
    isCompareLocation
  }
});

export const getStateByCountrySuccess = (response) => ({
  type: LOCATION_CONST.STATE_LIST_SUCCESS,
  payload: response,
});

export const getStateByCountryFailed = (error) => ({
  type: LOCATION_CONST.STATE_LIST_FAILED,
  payload: error,
});
//for district
export const getDistrictByStateRequest = ({requestObj, isCompareLocation}) => ({
  type: LOCATION_CONST.DISTRICT_LIST_REQUEST,
  payload: {
    requestObj,
    isCompareLocation,
  }
});

export const getDistrictByStateSuccess = (response) => ({
  type: LOCATION_CONST.DISTRICT_LIST_SUCCESS,
  payload: response,
});

export const getDistrictByStateFailed = (error) => ({
  type: LOCATION_CONST.DISTRICT_LIST_FAILED,
  payload: error,
});

//for Location convention
export const getLocationConventionRequest = (data) => ({
  type: LOCATION_CONST.LOCATION_CONVENTION_REQUEST,
  payload: data,
});

export const getLocationConventionSuccess = (response) => ({
  type: LOCATION_CONST.LOCATION_CONVENTION_SUCCESS,
  payload: response,
});

export const getLocationConventionFailed = (error) => ({
  type: LOCATION_CONST.LOCATION_CONVENTION_FAILED,
  payload: error,
});

//for country back office
export const getCountryBoRequest = (data) => ({
  type: LOCATION_CONST.COUNTRY_LIST_BO_REQUEST,
  payload: data,
});

export const getCountryBoSuccess = (response) => ({
  type: LOCATION_CONST.COUNTRY_LIST_BO_SUCCESS,
  payload: response,
});

export const getCountryBoFailed = (error) => ({
  type: LOCATION_CONST.COUNTRY_LIST_BO_FAILED,
  payload: error,
});

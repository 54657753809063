import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Chart from 'react-apexcharts';
import _ from 'lodash';
import { MONTHS_LIST } from '../../../Components/constants';
import { t } from 'react-multi-lang';

const DistrictRainfallModal = props => {
  const [series, setSeries] = useState([]);
  const [YAxisOptions, setYAxisOptions] = useState([
    {
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: '#008FFB',
      },
      labels: {
        style: {
          colors: '#008FFB',
          fontSize: '12px',
          fontFamily: 'National',
        },
        formatter: function(value) {
          if (!value == null || !isNaN(value)) {
            if (value % 1 != 0) {
              return value.toFixed(2);
            } else {
              return value;
            }
          }
        },
      },
      title: {
        style: {
          color: '#008FFB',
          fontFamily: 'National',
          fontWeight: 400
        },
      },
      tooltip: {
        enabled: true,
      },
      scaleLabel: {
        display: true,
      },
      ticks: {
        callback: function(value) {
          return value;
        },
      },
    },
  ]);

  const [options, setOptions] = useState({
    chart: {
      type: 'column',
      stacked: false,
      zoom: {
        enabled: true,
        type: 'x',
      },
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: [0, 1],
    },
    stroke: {
      width: 4,
    },
    title: {
      align: 'left',
      offsetX: 110,
      style: {
        fontSize: '14px',
        fontWeight: 'normal',
        fontFamily: 'National',
        color: '#2A547C',
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '55%',
        barHeight: '70%',
      },
    },
    xaxis: {
      categories: [],
      labels: {
        showDuplicates: false,
        style: {
          colors: '#008FFB',
          fontSize: '12px',
          fontFamily: 'National',
        },
        crossShair: {
          stroke: {
            curve: 'straight',
          },
        },
      },
      axisBorder: {
        show: true,
        color: '#008FFB',
        height: 1,
        width: '100%',
        offsetX: 0,
        offsetY: 0
    },
    axisTicks: {
        show: true,
        borderType: 'solid',
        color: '#008FFB',
        height: 6,
        offsetX: 0,
        offsetY: 0
    },
   
    },
    yaxis: YAxisOptions,
    tooltip: {
      fixed: {
        enabled: true,
        position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
        offsetY: 30,
        offsetX: 60,
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      horizontalAlign: 'left',
      offsetX: 40,
      markers:{
        radius: 28
      }
    },
  });

  useEffect(() => {
    if (props?.data?.length) {
      let rainfallData = props.data[0];
      let YAxisoptionsData = YAxisOptions;
      let seriesData = [];
      const monthsList = MONTHS_LIST;

      monthsList?.map(item => {
        seriesData.push(Number(rainfallData[item.toLocaleLowerCase()]));
      });

      setSeries([
        {
          name: 'Rainfall(mm)',
          data: seriesData,
          type: 'column',
        },
      ]);

      YAxisoptionsData[0] = {
        ...YAxisoptionsData[0],
        labels: {
          style: {
            colors: '#008FFB',
            fontSize: '12px',
            fontFamily: 'National',
          },
          formatter: function(value) {
            if (!value == null || !isNaN(value)) {
              if (value % 1 !== 0) {
                return value.toFixed(2);
              } else {
                return value;
              }
            }
          },
        },
        title: {
          ...YAxisoptionsData[0].title,
          text: 'Rainfall (mm)',
          style: {
            ...YAxisoptionsData[0].title.style,
            color: '#008FFB',
          },
        },
      };

      setYAxisOptions(YAxisoptionsData);

      setOptions(prev => ({
        ...prev,
        chart: {
          ...prev.chart,
          toolbar: {
            show: true,
            export: {
              csv: {
                filename: `${props?.districtData?.value} district rainfall graph`,
                columnDelimiter: ',',
                headerCategory: 'Month',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString();
                },
              },
              svg: {
                filename: `${props?.districtData?.value} district rainfall graph`,
                columnDelimiter: ',',
                headerCategory: 'Month',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString();
                },
              },
              png: {
                filename: `${props?.districtData?.value} district rainfall graph`,
                columnDelimiter: ',',
                headerCategory: 'Month',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString();
                },
              },
            },
          },
        },
        title: {
          ...prev.title,
          text: `Mean monthly rainfall ${props?.districtData?.value} district (${props?.stateData?.value})`,
          align: 'left',
          offsetX: 20,
          style: {
            fontSize:  '16px',
            fontWeight:  'normal',
            fontFamily:  'National',
            color:  '#363636',
          },
        },
        xaxis: {
          ...options.xaxis,
          type: 'date',
          categories: monthsList,
          tickPlacement: 'on',
          min: monthsList && monthsList[0],
          max: monthsList && monthsList[monthsList.length - 1],
          labels: {
            ...options.xaxis.labels,
            showDuplicates: false,
            hideOverlappingLabels: true,
          },
          //range: 30,
        },
      }));
    }
  }, [props.data]);

  return (
    <div>
      <Modal isOpen={props.isOpen} toggle={props.toggle} className="rainfall-modal">
        <ModalHeader
          toggle={props.toggle}
        ></ModalHeader>
        {series?.length ? (
          <>
            <ModalBody>
              <Chart id="chart" options={options} series={series} type="bar" />
            </ModalBody>
            <ModalFooter>{`(Source:IMD, 1901-2020)`}</ModalFooter>
          </>
        ) : (
          <div className="no-chart-style">
            <p>{t('CHARTS.NO_CHART_FOUND')}</p>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default DistrictRainfallModal;

import WEATHER_CONST from './weatherConst';

const Weather = (state, action) => {
  if (typeof state == 'undefined') {
    state = {
      isWeatherRequesting: true,
      weatherData: false,
      weatherDataError: false,
      weatherDataNetworkError: false,
      allCitiesData: [],
      citiesListByCountry: [],
      noaaTimeSeriesData: null,
      erpasTimeSeriesData: null,
    };
  }
  switch (action.type) {
    case WEATHER_CONST.FETCH_REQUEST:
      return {
        ...state,
        isWeatherRequesting: true,
        weatherData: false,
        weatherDataError: false,
        weatherDataNetworkError: false,
      };
    case WEATHER_CONST.FETCH_SUCCESS:
      return {
        ...state,
        isWeatherRequesting: false,
        weatherData: action.payload.response,
        weatherDataError: false,
        weatherDataNetworkError: false,
      };
    case WEATHER_CONST.FETCH_FAILED:
      return {
        ...state,
        isWeatherRequesting: false,
        weatherData: false,
        weatherDataError: action.payload.error ? false : action.payload.response,
        weatherDataNetworkError: action.payload.error ? action.payload.error : false,
      };
    case WEATHER_CONST.GET_FORECAST_DATA_SOURCES_REQUEST:
      return {
        ...state,
        isWeatherForecastSourcesRequesting: true,
        weatherForecastSourcesData: false,
        weatherForecastSourcesDataError: false,
        weatherForecastSourcesDataNetworkError: false,
      };
    case WEATHER_CONST.GET_FORECAST_DATA_SOURCES_SUCCESS:
      return {
        ...state,
        isWeatherForecastSourcesRequesting: false,
        weatherForecastSourcesData: action.payload.response,
        weatherForecastSourcesDataError: false,
        weatherForecastSourcesDataNetworkError: false,
      };
    case WEATHER_CONST.GET_FORECAST_DATA_SOURCES_FAILED:
      return {
        ...state,
        isWeatherForecastSourcesRequesting: false,
        weatherForecastSourcesData: false,
        weatherForecastSourcesDataError: action.payload.error ? false : action.payload.response,
        weatherForecastSourcesDataNetworkError: action.payload.error ? action.payload.error : false,
      };
    case WEATHER_CONST.GET_FORECAST_DATA_REQUEST:
      return {
        ...state,
        isWeatherForecastRequesting: true,
        weatherForecastData: false,
        weatherForecastDataError: false,
        weatherForecastDataNetworkError: false,
      };
    case WEATHER_CONST.GET_FORECAST_DATA_SUCCESS:
      return {
        ...state,
        isWeatherForecastRequesting: false,
        weatherForecastData: action.payload.response,
        weatherForecastDataError: false,
        weatherForecastDataNetworkError: false,
      };
    case WEATHER_CONST.GET_FORECAST_DATA_FAILED:
      return {
        ...state,
        isWeatherForecastRequesting: false,
        weatherForecastData: false,
        weatherForecastDataError: action.payload.error ? false : action.payload.response,
        weatherForecastDataNetworkError: action.payload.error ? action.payload.error : false,
      };
    case WEATHER_CONST.NOAA_TIMESERIES_REQUEST:
      return {
        ...state,
        noaaTimeSeriesDataRequesting: true,
        noaaTimeSeriesData: false,
        noaaTimeSeriesError: false,
      };
    case WEATHER_CONST.NOAA_TIMESERIES_SUCCESS:
      return {
        ...state,
        noaaTimeSeriesDataRequesting: false,
        noaaTimeSeriesData: action.payload.response,
        noaaTimeSeriesDataError: false,
      };
    case WEATHER_CONST.NOAA_TIMESERIES_FAILED:
      return {
        ...state,
        noaaTimeSeriesDataRequesting: false,
        noaaTimeSeriesData: false,
        noaaTimeSeriesDataError: action.payload.error ? false : action.payload.response,
      };
    case WEATHER_CONST.ERPAS_TIMESERIES_REQUEST:
      return {
        ...state,
        erpasTimeSeriesDataRequesting: true,
        erpasTimeSeriesData: false,
        erpasTimeSeriesError: false,
      };
    case WEATHER_CONST.ERPAS_TIMESERIES_SUCCESS:
      return {
        ...state,
        erpasTimeSeriesDataRequesting: false,
        erpasTimeSeriesData: action.payload.response,
        erpasTimeSeriesDataError: false,
      };
    case WEATHER_CONST.ERPAS_TIMESERIES_FAILED:
      return {
        ...state,
        erpasTimeSeriesDataRequesting: false,
        erpasTimeSeriesData: false,
        erpasTimeSeriesDataError: action.payload.error ? false : action.payload.response,
      };

    case WEATHER_CONST.IRI_TIMESERIES_REQUEST:
      return {
        ...state,
        iriTimeSeriesDataRequesting: true,
        iriTimeSeriesData: false,
        iriTimeSeriesError: false,
      };
    case WEATHER_CONST.IRI_TIMESERIES_SUCCESS:
      return {
        ...state,
        iriTimeSeriesDataRequesting: false,
        iriTimeSeriesData: action.payload.response,
        iriTimeSeriesDataError: false,
      };
    case WEATHER_CONST.IRI_TIMESERIES_FAILED:
      return {
        ...state,
        iriTimeSeriesDataRequesting: false,
        iriTimeSeriesData: false,
        iriTimeSeriesDataError: action.payload.error ? false : action.payload.response,
      };

    case WEATHER_CONST.IRI_AVAILABLE_DATES_REQUEST:
      return {
        ...state,
        iriAvailableDatesDataRequesting: true,
        iriAvailableDatesData: false,
        iriAvailableDatesError: false,
      };
    case WEATHER_CONST.IRI_AVAILABLE_DATES_SUCCESS:
      return {
        ...state,
        iriAvailableDatesDataRequesting: false,
        iriAvailableDatesData: action.payload.response,
        iriAvailableDatesError: false,
      };
    case WEATHER_CONST.IRI_AVAILABLE_DATES_FAILED:
      return {
        ...state,
        iriAvailableDatesDataRequesting: false,
        iriAvailableDatesData: false,
        iriAvailableDatesError: action.payload.error ? false : action.payload.response,
      };

    // ecmwf producttt
    case WEATHER_CONST.ECMWF_PRODUCTS_REQUEST:
      return {
        ...state,
        isEcmwfProductLoading: true,
        isEcmwfProductData: false,
        isEcmwfProductError: false,
      };
    case WEATHER_CONST.ECMWF_PRODUCTS_SUCCESS:
      return {
        ...state,
        isEcmwfProductLoading: false,
        isEcmwfProductData: action.payload.response,
        isEcmwfProductError: false,
      };
    case WEATHER_CONST.ECMWF_PRODUCTS_FAILED:
      return {
        ...state,
        isEcmwfProductLoading: false,
        isEcmwfProductData: false,
        isEcmwfProductError: action.payload.error ? false : action.payload.response,
      };

    // ecmwf controls
    case WEATHER_CONST.ECMWF_CONTROLS_REQUEST:
      return {
        ...state,
        isEcmwfControlsLoading: true,
        isEcmwfControlData: false,
        isEcmwfControlError: false,
      };
    case WEATHER_CONST.ECMWF_CONTROLS_SUCCESS:
      return {
        ...state,
        isEcmwfControlsLoading: false,
        isEcmwfControlData: action.payload.response,
        isEcmwfControlError: false,
      };
    case WEATHER_CONST.ECMWF_CONTROLS_FAILED:
      return {
        ...state,
        isEcmwfControlsLoading: false,
        isEcmwfControlData: false,
        isEcmwfControlError: action.payload.error ? false : action.payload.response,
      };

    // data by  controls
    case WEATHER_CONST.ECMWF_DATA_BY_CONTROLS_REQUEST:
      return {
        ...state,
        isEcmwfDataByControlLoading: true,
        isEcmwfDataByControlData: false,
        isEcmwfDataByControlError: false,
      };
    case WEATHER_CONST.ECMWF_DATA_BY_CONTROLS_SUCCESS:
      return {
        ...state,
        isEcmwfDataByControlLoading: false,
        isEcmwfDataByControlData: action.payload.response,
        isEcmwfDataByControlError: false,
      };
    case WEATHER_CONST.ECMWF_DATA_BY_CONTROLS_FAILED:
      return {
        ...state,
        isEcmwfDataByControlLoading: false,
        isEcmwfDataByControlData: false,
        isEcmwfDataByControlError: action.payload.error ? false : action.payload.response,
      };

    default:
      return state;
  }
};

export default Weather;

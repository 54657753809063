import React from 'react';
import { Row, Col, Modal, ModalBody } from 'reactstrap';
import { AiFillDelete } from 'react-icons/ai';
import { GoCircleSlash } from 'react-icons/go';
import { GrFormClose } from 'react-icons/gr';
import { AiFillCheckCircle } from 'react-icons/ai';
import { t } from 'react-multi-lang';

const AlertModal = props => {
  const handleChanges = data => {
    if (props.onDeleteClick) {
      props.onClick(data.id);
    } else if (props.onPublishClick) {
      props.onClick(data, { publish: !data.status });
    }
  };

  return (
    <>
      <div className="news-feed-alert-modal">
        <Row>
          <Col>
            <Modal
              className="modal-dialog modal-lg modal-dialog-centered delete-modal"
              isOpen={props.isOpen}
              toggle={props.isToggle}
              centered
            >
              <ModalBody className="text-center delete-modal-body">
                <div
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                  className="cursor-ponter"
                >
                  <GrFormClose size={25} onClick={props.isToggle} />
                </div>
                {props.onDeleteClick && <AiFillDelete size={30} />}
                {props.onPublishClick &&
                  (props.data.status ? (
                    <GoCircleSlash size={30} />
                  ) : (
                    <>
                      <AiFillCheckCircle size={30} />
                    </>
                  ))}
                <div className="mt-3 text-dark">
                  <b>{t('USER_GUIDE.ARE_YOU_SURE')}</b>
                </div>
                <div className="mt-3">
                  {props.onPublishClick ? (
                    <>
                      {t('USER_GUIDE.THIS_WILL')} {props.data.status ? 'Unpublish' : 'Publish'}{' '}
                      {t('USER_GUIDE.THE_USER_GUIDE')}
                    </>
                  ) : (
                    <>{t('USER_GUIDE.DELETE_USER_TEXT')}</>
                  )}
                </div>
                <div className="mt-3">
                  <button className="btn btn-cancel mx-2" onClick={props.isToggle}>
                    {t('USER_GUIDE.CANCEL')}
                  </button>
                  <button
                    className="btn btn-news-feed-submit mx-2"
                    onClick={() => handleChanges(props.data)}
                  >
                    {props?.onDeleteClick
                      ? t('USER_GUIDE.DELETE')
                      : props?.onPublishClick && props.data.status
                      ? t('USER_GUIDE.UNPUBLISH')
                      : t('USER_GUIDE.PUBLISH')}
                  </button>
                </div>
              </ModalBody>
            </Modal>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AlertModal;

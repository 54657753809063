import React, { useEffect, useRef, useState } from 'react';
import { t } from 'react-multi-lang';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Col, Container, Form, FormGroup, Input, Row } from 'reactstrap';

import Breadcrumb from '../../Components/common/BreadCrumbsComponent';
import Loader from '../../Components/common/TextLoader';
import { showError, showSuccess } from '../../Components/Notifications';
import { contactUsRequest } from '../../redux/home/homeActions';
import Footer from './Footer';

function ContactUsForm() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    organization: '',
    message: '',
  });

  const { LandingPageData } = useSelector(state => ({
    LandingPageData: state.Home,
  }));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const isValid =
      formData.name &&
      formData.email &&
      formData.phone &&
      formData.organization &&
      formData.message;
    setIsFormValid(isValid);
  }, [formData]);

  const handleResetFormData = () => {
    setFormData({
      name: '',
      phone: '',
      email: '',
      organization: '',
      message: '',
    });
  };

  const isEditRef = useRef(true);
  useEffect(() => {
    if (isEditRef.current) {
      isEditRef.current = false;
      return;
    }
    if (LandingPageData.isContactUsRequesting) {
      setIsLoading(true);
    } else if (LandingPageData?.contactUsData?.data) {
      showSuccess(LandingPageData?.contactUsData?.data?.result);
      setIsLoading(false);
      history.push('/drought-management-system?Country=mali');
      handleResetFormData();
    } else if (LandingPageData.contactUsDataError || LandingPageData.contactUsDataNetworkError) {
      const errorMessageArray =
        LandingPageData.contactUsDataError?.response?.data?.response?.data?.message || [];
      const [errorMessage] = errorMessageArray;

      showError(errorMessage);
      setIsLoading(false);
    }
  }, [
    LandingPageData?.contactUsData,
    LandingPageData.isContactUsRequesting,
    LandingPageData.contactUsDataError,
    LandingPageData.contactUsDataNetworkError,
    dispatch,
  ]);

  const handleChange = e => {
    const { name, value } = e.target;

    let updatedFormData = {
      ...formData,
    };

    updatedFormData[name] = value;
    setFormData(updatedFormData);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const submissionData = { ...formData };
    dispatch(contactUsRequest({ submissionData, end_point: 'contact_us' }));
  };

  return (
    <div className="spacing-top">
      {isLoading && <Loader />}
      <Breadcrumb items={['Home', 'Contact Us']} />
      <Container fluid className="contact-us-form-container">
        <Row>
          <Col xs={12} lg={6}>
            <h1 className="contact-us-form-header mb-3">
              {t('LANDING_PAGE.CONTACT_US_FORM_HEADER')}
            </h1>
            <p className="contact-us-form-desc">{t('LANDING_PAGE.CONTACT_US_FORM_DESC')}</p>
          </Col>
          <Col xs={12} lg={6}>
            <Form onSubmit={handleSubmit}>
              <FormGroup row>
                <Col>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Name"
                    className="contact-us-input mb-3"
                    onChange={handleChange}
                    value={formData.name}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col>
                  <Input
                    type="number"
                    name="phone"
                    id="phone"
                    placeholder="Phone"
                    className="contact-us-input mb-3"
                    onChange={handleChange}
                    value={formData.phone}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email"
                    className="contact-us-input mb-3"
                    onChange={handleChange}
                    value={formData.email}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col>
                  <Input
                    type="text"
                    name="organization"
                    id="organization"
                    placeholder="Organization"
                    className="contact-us-input mb-3"
                    onChange={handleChange}
                    value={formData.organization}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col>
                  <Input
                    type="textarea"
                    name="message"
                    id="message"
                    placeholder="Message"
                    className="contact-us-input mb-3"
                    onChange={handleChange}
                    value={formData.message}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup check row className=" pl-0" style={{ paddingLeft: 0 }}>
                <Col sm={12} lg={{ size: 4 }}>
                  <button type="submit" className="contact-use-button contat-us-button-sm" disabled={!isFormValid}>
                    Submit
                  </button>
                </Col>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </Container>
      <Footer backgroundColor="bg-white" />
    </div>
  );
}

export default ContactUsForm;

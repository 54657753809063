import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../../Components/common/BreadCrumbsComponent';

import { NEWS_FEED_URL_CONSTANT } from '../../../Components/constants';
import { getDmsEventsRequest, getDmsNewsfeedRequest } from '../../../redux/home/homeActions';
import Footer from '../Footer';
import NewsCarousel from '../NewsCarousel';

function NewsEvents() {
  const dispatch = useDispatch();
  const [eventsData, setEventsData] = useState([]);
  const [newsfeedData, setNewsfeedData] = useState([]);
  const [reportsData, setReportsData] = useState([]);
  const [blogsData, setBlogsData] = useState([]);

  const { LandingPageData } = useSelector(state => ({
    LandingPageData: state.Home,
  }));

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getDmsEventsRequest({ event_type: 'upcoming' }));
    dispatch(getDmsNewsfeedRequest({ category: 'south-asia', limit: 10 }));
    const axios = require('axios');

    const fetchNewsFeed = async () => {
      try {
        const response = await axios.get(NEWS_FEED_URL_CONSTANT.RESOURCE_URL);
        const data = response.data.items;
        setReportsData(data);
      } catch (err) {
        setReportsData([]);
      }
    };

    fetchNewsFeed();

    const fetchBlogs = async () => {
      try {
        const response = await axios.get(NEWS_FEED_URL_CONSTANT.BLOGS_URL);
        const data = response.data.items;
        setBlogsData(data);
      } catch (err) {
        setBlogsData([]);
      }
    };

    fetchBlogs();
  }, [dispatch]);

  useEffect(() => {
    if (LandingPageData.getDmsEventsData) {
      const { result } = LandingPageData.getDmsEventsData.data;
      setEventsData(result);
    }
  }, [LandingPageData.getDmsEventsData]);

  useEffect(() => {
    if (LandingPageData.getDmsNewsfeedData) {
      const { result } = LandingPageData.getDmsNewsfeedData.data;
      if (result?.items?.length) {
        setNewsfeedData(result?.items);
      }
    }
  }, [LandingPageData.getDmsNewsfeedData]);

  const handleSelectedEvent = e => {
    const selected = e.target.getAttribute('data-name');
    const buttons = document.querySelectorAll('.event-btns');
    buttons.forEach(button => button.classList.remove('event-btns-active'));

    const clickedButton = e.target.closest('.event-btns');
    if (clickedButton) {
      dispatch(getDmsEventsRequest({ event_type: selected }));
      clickedButton.classList.add('event-btns-active');
    }
  };

  return (
    <div className="spacing-top">
      <Breadcrumb items={['Home', 'Insights']} />
      <NewsCarousel
        cardData={newsfeedData}
        title="News"
        dataType="news"
        backgroundColor="bg-white"
      />
      <NewsCarousel
        cardData={eventsData}
        title="Events"
        dataType="upcoming-events"
        backgroundColor=""
        handleSelectedEvent={handleSelectedEvent}
      />
      {/* Blogs*/}
      <NewsCarousel
        cardData={blogsData}
        title="Blogs"
        dataType="news"
        backgroundColor="bg-white"
      />
      <NewsCarousel
        cardData={reportsData}
        title="Publications"
        dataType="reports"
        backgroundColor=""
      />
      <Footer backgroundColor="" />
    </div>
  );
}

export default NewsEvents;

import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import HeroCarousel from '../../../Components/common/HeroSection';
import {
  getIndicesRequest,
  getIndicesSuccess,
  getMapPreferenceRequest,
} from '../../../redux/actions';
import { AXIOS_INSTANCE } from '../../../redux/api/apiEndPoint';
import { getDmsPillarsRequest } from '../../../redux/home/homeActions';
import Footer from '../Footer';
import CountrySpecificContent from './CountrySpecificContent';
// import DroughtAlerts from './DroughtAlerts';
import DroughtInsights from './DroughtInsights';

function CountrySpecificTool() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [cordinateAndZoom, setCordinateAndZoom] = useState({
    cordinates: [17.355, -3.518],
    zoom: 4,
  });
  const [isLoading, setIsLoading] = useState(false);
  const countryData = {
    id: 'e1eb8a52-2b98-4001-a7c2-7ff332725984',
    name: 'Mali Drought Management System',
    description:
      'Mali Drought Management System , developed under IWMI’s drought program with Indian Council of Agricultural Research (ICAR) and other partners, aids stakeholders in India in mitigating drought risks. It enables government authorities and development organizations to take timely action These features help monitor drought propagation and implement effective adaptation strategies for drought management.',
    latitude: 17.355,
    longitude: -3.518,
    website_url: null,
    created_by_id: 20,
    country_id: 'e1eb8a52-2b98-4001-a7c2-7ff332725984',
    order: 6,
    is_editable: false,
    file: null,
    icon:
      'https://sadms-iwmi.s3.ap-south-1.amazonaws.com/uat/landing_page/pillars/icon/IDMS1727522384007.png',
    country_name: 'Mali',
    country_zoom_level: 6,
    country_slug: 'mali',
  };
  const [boundariesData, setBoundariesData] = useState([]);
  const [geoJsonData, setGeoJsonData] = useState([]);

  const urlParams = new URLSearchParams(window.location.search);
  const selectedCountry = urlParams.get('Country');

  const { DroughtIndicesData, LandingPageData, boundries } = useSelector(state => ({
    DroughtIndicesData: state.AccessKey.getIndicesData,
    LandingPageData: state.Home,
    boundries: state.Map_Preference,
  }));

  useEffect(() => {
    return () => {
      dispatch(getIndicesSuccess({}));
    };
  }, []);

  useEffect(() => {
    localStorage?.setItem('countryData', JSON.stringify(countryData));
    dispatch(getMapPreferenceRequest({ country_id: countryData?.country_id }));
  }, []);

  useEffect(() => {
    if (!_.isEmpty(countryData)) {
      setCordinateAndZoom(prev => {
        return {
          ...prev,
          cordinates: [countryData?.latitude, countryData?.longitude],
          zoom: countryData?.country_slug === 'srilanka' ? 7 : 4,
        };
      });
      dispatch(
        getIndicesRequest({
          requestObj: {
            type: 'country',
            type_id:
              countryData?.country_name !== 'South Asia'
                ? countryData?.country_id
                : 'e2f886a7-3299-4c82-af2e-987fe92fab39', // ID of India
          },
          module: 'drought-monitor',
        }),
      );
    }
  }, [countryData?.id]);

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(getDmsPillarsRequest({}));
  }, [dispatch]);

  // * SETTING BOUNDARIES DATA
  const firstRun = useRef(true);
  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    if (boundries?.mapPreferenceData) {
      setBoundariesData(boundries?.mapPreferenceData?.data?.result);
    }
  }, [boundries?.mapPreferenceData]);

  const isSecondRun = useRef(true);
  useEffect(() => {
    if (isSecondRun.current) {
      isSecondRun.current = false;
      return;
    }
    if (boundariesData && boundariesData?.length) {
      let API_URL = [];
      boundariesData?.forEach(item => {
        if (item.category === 'Admin') {
          if (item?.data && item?.data.length) {
            item.data.forEach(boundary => {
              let boundaryTypes =
                countryData?.country_slug === 'southasia'
                  ? ['country']
                  : ['country', 'states_or_provinces'];
              if (boundaryTypes.includes(boundary?.boundarytype?.slug)) {
                if (boundary?.boundary_data_url.length) {
                  let config = {
                    name: boundary?.boundarytype?.slug,
                    id: boundary?.boundarytype_id,
                    color: boundary?.boundarytype?.color?.code,
                    category: boundary?.category,
                    country_id: boundary?.country_id,
                  };
                  API_URL.push(AXIOS_INSTANCE.get(boundary?.boundary_data_url, config));
                }
              }
            });
          }
        }
      });
      Promise.all(API_URL).then(response => {
        let res = JSON.parse(JSON.stringify(response));
        if (res?.length) {
          setGeoJsonData(res);
        }
      });
    }
  }, [boundariesData]);

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1)); // Remove the "#" from the hash
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <>
      <HeroCarousel />
      <CountrySpecificContent />
      <DroughtInsights
        id="drought-insigths"
        cordinateAndZoom={cordinateAndZoom}
        countryData={countryData}
        indicesData={DroughtIndicesData || {}}
        isLoading={isLoading}
        setCordinateAndZoom={setCordinateAndZoom}
        setIsLoading={setIsLoading}
        geoJsonData={geoJsonData}
      />
      {/* Commenting because of static data */}
      {/* <DroughtAlerts id="drought-alerts" /> */}
      <Footer backgroundColor="" />
    </>
  );
}

export default CountrySpecificTool;

import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

const DraftEditor = props => {
  const { content, onEditorChange } = props;
  return (
    <>
    <div className="decription-editor">
      <Editor
        value={content === undefined ? '' : content}
        apiKey="8sg2vaosizljdlh4xg6frgc3nsfs0wvg022tnp6ibklzdez6"
        plugins="wordcount"
        onEditorChange={Text => onEditorChange(Text)}
      />
    </div>
    <p className="text-test">Note:- For better user experience, kindly choose font family:"National/verdana" and font size:"14px".</p>
    </>
  );
};

export default DraftEditor;

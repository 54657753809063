import React, { useState, useEffect } from 'react';
import {
  Card,
  CardTitle,
  CardSubtitle,
  CardBody,
  Row,
  Col,
} from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { NEWS_FEED_LENGTH_CONSTANT } from '../../../Components/constants';
import _ from 'lodash';
import moment from 'moment';
import { DISPLAY_DATE_FORMAT } from '../../../Components/constants';
import { useTranslation } from 'react-multi-lang';
import { NEWS_FEED_CATEGORIES, NEWS_FEED_URL_CONSTANT } from '../../../Components/constants/index';
import { htmlToText } from '../../../Components/common/utils';
import Loader from '../../../Components/common/loader';

const Events = props => {
  const t = useTranslation();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [initialEventsRssFeedData, setInitialEventsRssFeedData] = useState('');
  const [eventsRssFeedData, setEventsRssFeedData] = useState();

  useEffect(() => {
    const axios = require('axios');
    axios.get(NEWS_FEED_URL_CONSTANT.EVENTS_URL).then(response => {
      let arrayData = response?.data;
      setInitialEventsRssFeedData(arrayData);
      let data = arrayData?.items?.slice(0, 6);
      setEventsRssFeedData({ ...arrayData, items: data });
      setPageCount(arrayData?.items?.length);
      setPageNumber(1);
    });
  }, []);

  const fetchNextTemplateData = () => {
    let pageNuM = pageNumber + 1;
    setPageNumber(pageNuM);
    if (initialEventsRssFeedData?.items) {
      let news_list = [];
      let list = _.cloneDeep(eventsRssFeedData?.items);
      let data = initialEventsRssFeedData?.items?.slice(
        eventsRssFeedData?.items?.length,
        initialEventsRssFeedData?.items?.length,
      );
      if (eventsRssFeedData?.items?.length) {
        if (pageNuM > 1) {
          news_list = data?.length ? list?.concat(data) : list;
        }
      } else {
        news_list = list;
      }
      setEventsRssFeedData({ ...eventsRssFeedData, items: news_list });
    } else {
      setEventsRssFeedData([]);
    }
  };

  return (
    <div className="iwmi-content-section news-feed-cls">
      <div className="content-title">
        <span className="">{NEWS_FEED_CATEGORIES.Events}</span>
        <hr className="title-hr" />
      </div>
      <div className="">
        {props.isNewsFeedRequesting && <Loader />}
        <InfiniteScroll
          dataLength={pageNumber * 6 < pageCount ? pageNumber * 6 : ''}
          next={() => fetchNextTemplateData()}
          hasMore={pageNumber * 6 < pageCount ? true : false}
          className="flex flex-wrap"
        >
          <Row>
            {eventsRssFeedData?.items?.reverse().map((i,index) => {
              return (
                <Col xs="4" md="4" lg="4" sm="4" key={index} className="iwmi-card-section">
                  <Card className="news-feed-card iwmi-card">
                    <CardBody className="iwmi-event-resource-card-body">
                      <CardTitle className="news-feed-card-title">
                        <a
                          className="news-feed-title-link"
                          href={i?.link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {i?.title?.length > NEWS_FEED_LENGTH_CONSTANT.SIXTY
                            ? `${i?.title.substring(0, 60)}... `
                            : i?.title}
                        </a>
                      </CardTitle>
                      <div className="news-feed-card-content">
                      {htmlToText(i.content)?.length > 300 ? (
                            <div  id="card-description" >
                              <div className="news-feed-raw-data-section">
                              {htmlToText(i.content).substring(0, 200)}
                              <span>...</span>
                              </div>
                            </div>
                        ) : (
                          <div dangerouslySetInnerHTML={{ __html: i?.content }} />
                        )}
                      </div>
                      <CardSubtitle className="news-feed-card-sub-title">
                        {t('BULLET_IN.PUBLISHED_ON') +
                          ' ' +
                          moment(i?.pubDate).format(DISPLAY_DATE_FORMAT)}
                      </CardSubtitle>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </InfiniteScroll>
      </div>
    </div>
  );
};
export default Events;

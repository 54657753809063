import React from 'react';
import { Row, Col, Input } from 'reactstrap';
import Cookies from 'js-cookie';
import ProjectNews from '../ProjectNews/index';
import IWMINews from '../IWMI-News/index';
import Events from '../Events/index';
import ExternalNews from '../ExternalNews/index';
import Resources from '../Resources/index';
import Button from 'reactstrap-button-loader';
import { useTranslation } from 'react-multi-lang';
import { NEWS_FEED_CATEGORIES } from '../../../Components/constants/index';

const NewsFeedContent = props => {
  const {
    pageNumber,
    pageCount,
    setPageCount,
    setPageNumber,
    emailSubmitHandler,
    isEmailSubmit,
    isSubscribeLoading,
    selectedMenuItem,
    newsFeedList,
    subscribeButtonHandler,
    subscribeLoggedInButtonHandler,
    emailInputHandler,
    isSubscribeClicked,
    emailSubscribed,
    isNewsFeedRequesting,
  } = props;
  const t = useTranslation();

  const renderContent = () => {
    switch (selectedMenuItem?.category_name) {
      case NEWS_FEED_CATEGORIES.Project_News:
        return (
          <ProjectNews
            newsFeedList={newsFeedList}
            selectedMenuItem={selectedMenuItem}
            isNewsFeedRequesting={isNewsFeedRequesting}
            pageNumber={pageNumber}
            pageCount={pageCount}
            setPageCount={setPageCount}
            setPageNumber={setPageNumber}
          />
        );
        break;
      case NEWS_FEED_CATEGORIES.IWMI_News:
        return (
          <IWMINews
            selectedMenuItem={selectedMenuItem}
            pageNumber={pageNumber}
            pageCount={pageCount}
            setPageCount={setPageCount}
            setPageNumber={setPageNumber}
          />
        );
        break;
      case NEWS_FEED_CATEGORIES.External_News:
        return (
          <ExternalNews
            selectedMenuItem={selectedMenuItem}
            newsFeedList={newsFeedList}
            isNewsFeedRequesting={isNewsFeedRequesting}
          />
        );
        break;
      case NEWS_FEED_CATEGORIES.Publications:
        return (
          <Resources
            newsFeedList={newsFeedList}
            selectedMenuItem={selectedMenuItem}
            isNewsFeedRequesting={isNewsFeedRequesting}
            pageNumber={pageNumber}
            pageCount={pageCount}
            setPageCount={setPageCount}
            setPageNumber={setPageNumber}
          />
        );
        break;
      case NEWS_FEED_CATEGORIES.Events:
        return (
          <Events
            newsFeedList={newsFeedList}
            selectedMenuItem={selectedMenuItem}
            isNewsFeedRequesting={isNewsFeedRequesting}
            pageNumber={pageNumber}
            pageCount={pageCount}
            setPageCount={setPageCount}
            setPageNumber={setPageNumber}
          />
        );
      default:
        return isNewsFeedRequesting && <h1>{t('NEWS_FEED.NO_RECORDS')}</h1>;
    }
  };

  const handleEmailSubmitClick = () => {
    emailSubmitHandler();
  };

  const handleEmailInput = e => {
    emailInputHandler(e.target.value);
  };

  const handleSubscribeClick = () => {
    subscribeButtonHandler();
  };

  const handleSubscribeClickForLoggedIn = () => {
    subscribeLoggedInButtonHandler();
  };

  return (
    <>
      <div className="news-feed-content">
        {!Cookies.get('user') ? (
          !emailSubscribed &&
          (isSubscribeClicked ? (
            <div className="user-input">
              <Row>
                <Col sm="8" md="8" xs="8" lg="8">
                  <Input
                    type="email"
                    name="email"
                    id="exampleEmail"
                    placeholder="Your Email Address"
                    bsSize="md"
                    size="50"
                    className="email-input"
                    onChange={e => handleEmailInput(e)}
                  />
                </Col>
                <Col sm="4" md="4" xs="4" lg="4">
                  <Button
                    className="email-submit-btn"
                    onClick={handleEmailSubmitClick}
                    loading={isSubscribeLoading}
                    disabled={!isEmailSubmit}
                  >
                    {t('NEWS_FEED.SUBMIT')}
                  </Button>
                </Col>
              </Row>
            </div>
          ) : (
            <Button className="subscribe-btn margin-space" onClick={handleSubscribeClick}>
              {t('NEWS_FEED.SUBSCRIBE_NOW')}
            </Button>
          ))
        ) : (
          <Button
            className="subscribe-btn"
            onClick={handleSubscribeClickForLoggedIn}
            loading={isSubscribeLoading}
          >
            {t('NEWS_FEED.SUBSCRIBE_NOW')}
          </Button>
        )}
      </div>
      <div className="news-feed-content">{renderContent()}</div>
    </>
  );
};
export default NewsFeedContent;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TabContent, TabPane, Row, Col } from 'reactstrap';
import { userListRequest, userPermissionsRequest } from '../../redux/user-list/userListAction';
import { UserListTable } from './Table';
import { isSuperAdmin, isStaffUser } from '../../Components/common/utils';
import { userDetailRequest } from '../../redux/user-list/userListAction';
import Filter from './Filter';
import RightArrow from '../../assets/Images/back-button.svg';
import FilterIcon from '../../assets/Images/icons/mi_filter.svg';
import { t } from 'react-multi-lang';

const Index = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [newRequestList, setNewRequestList] = useState();
  const [pageLimit, setPageLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [recordsCount, setRecordsCount] = useState();
  const [toggleRightBar, setToggleRightBar] = useState(false);
  const [permissionsData, setPermissionsData] = useState();
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    type: '',
  });
  const [filterFormData, setFilterFormData] = useState({
    Name: '',
    Email: '',
    Phone_number: '',
    Role: '',
  });
  const [filterData, setFilterData] = useState();
  const [userId, setUserId] = useState();
  const nextProps = useSelector(state => ({
    userListData: state.userList.userListData || null,
    permissionsData: state.userList.userPermissionsData || null,
    userByIdData: state.userList.userById || null,
    userDetailData: state.userList.userDetailData || null,
  }));
  useEffect(() => {
    handleFilterResultData(formData);
  }, [formData.name, formData.email, formData.mobile, formData.type]);
  useEffect(() => {
    if (isSuperAdmin() || isStaffUser()) {
      dispatch(userListRequest({ page: pageNumber, limit: pageLimit }));
    } else {
      history.push('/drought-management-system?Country=mali');
    }
    dispatch(userDetailRequest());
  }, []);

  useEffect(() => {
    if (nextProps.userDetailData) {
      const {
        data: { result },
      } = nextProps.userDetailData;
      if (result) {
        setUserId(result?.user?.id);
      }
    }
  }, [nextProps.userDetailData]);

  useEffect(() => {
    if (nextProps.permissionsData) {
      const {
        data: { result },
      } = nextProps.permissionsData;
      if (result) {
        setPermissionsData(result);
      }
    }
  }, [nextProps.permissionsData]);

  useEffect(() => {
    if (nextProps.userListData) {
      const {
        data: { result },
      } = nextProps.userListData;
      let a;
      if (result?.results?.length) {
        let filteredList = [];
        filteredList = result.results.filter(i => i.is_superuser !== 'True');
        filteredList.map(i => {
          return (i.role = i.is_staff === 'False' ? 'User' : 'IWMI Admin');
        });
        a = filteredList.filter(x => x.user_id !== userId);
        setNewRequestList(a);
        setPageCount(result.count);
        setRecordsCount(result.results.length);
      }
    }
  }, [nextProps.userListData]);

  useEffect(() => {
    if (nextProps.userByIdData) {
      const {
        data: { result },
      } = nextProps.userByIdData;
      if (result?.results?.length) {
        setNewRequestList(result.results);
        setPageCount(result.count);
        setRecordsCount(result.results.length);
      }
    }
  }, [nextProps.userByIdData]);

  const handlePageNumber = value => {
    setPageNumber(value);
    if (filterData) {
      dispatch(userListRequest({ page: value, limit: pageLimit, data: filterData }));
    } else {
      dispatch(userListRequest({ page: value, limit: pageLimit }));
    }
  };

  const handlePageSize = value => {
    setPageLimit(value);
    setPageNumber(1);
    if (filterData) {
      dispatch(userListRequest({ page: 1, limit: value, data: filterData }));
    } else {
      dispatch(userListRequest({ page: 1, limit: value }));
    }
  };

  const handleLatestData = value => {
    dispatch(userListRequest({ page: pageNumber, limit: value }));
  };

  const handleFilterResultData = data => {
    setFilterData(data);
    setPageNumber(1);
    dispatch(userListRequest({ data: data }));
  };

  const handleLatestDataForDelete = id => {
    dispatch(userListRequest({ page: pageNumber, limit: 10 }));
  };

  const handleCloseFilter = () => {
    setToggleRightBar(false);
  };

  const handleFilter = () => {
    dispatch(userPermissionsRequest());
    setToggleRightBar(!toggleRightBar);
  };

  const handleManageCropsBackButton = () => {
    history.push('/settingsDashboard');
  };

  const nameHandler = e => {
    formData.name = e.target.value;
    setFormData(formData);
    filterFormData.Name = e.target.value;
    setFilterFormData(filterFormData);
  };

  const mobileHandler = e => {
    formData.mobile = e.target.value;
    setFormData(formData);
    filterFormData.Phone_number = e.target.value;
    setFilterFormData(filterFormData);
  };

  const roleHandler = e => {
    formData.type = e.value;
    setFormData(formData);
    filterFormData.Role = e.value;
    setFilterFormData(filterFormData);
  };

  const emailHandler = e => {
    formData.email = e.target.value;
    setFormData(formData);
    filterFormData.Email = e.target.value;
    setFilterFormData(filterFormData);
  };
  const handleReset = () => {
    setFormData({});
    filterFormData.Name = '';
    filterFormData.Email = '';
    filterFormData.Phone_number = '';
    filterFormData.Role = '';
    setFilterFormData(filterFormData);
  };

  return (
    <>
      {toggleRightBar ? (
        <div className="">
          <Filter
            handleCloseFilter={handleCloseFilter}
            permissionsData={permissionsData}
            handleReset={handleReset}
            handleFilterResultData={handleFilterResultData}
            nameHandler={nameHandler}
            emailHandler={emailHandler}
            mobileHandler={mobileHandler}
            roleHandler={roleHandler}
            formData={formData}
            initialValues={filterFormData}
          />
        </div>
      ) : (
        ''
      )}
      <div className="right-img-wrapper api-key-list ">
        <Row className="user-list-header">
          <Col sm={1} lg={1} md={1}>
            <img
              src={RightArrow}
              alt="Right Arrow"
              className="header-back-button"
              onClick={() => handleManageCropsBackButton()}
            />
          </Col>
          <Col sm={5} lg={5} md={5}>
            <h3 className="heading-apikey">Manage Users</h3>
          </Col>
          <Col sm={6} lg={6} md={6}>
            <span className="user-list-filter" onClick={() => handleFilter()}>
              <img
                src={FilterIcon}
                alt={'filter icon'}
                className="st-filter-img user-filter-icon"
              />
              <h5 className="st-filter-text user-filter-text">{t('FILTER.FILTER')} </h5>
            </span>
          </Col>
        </Row>

        <TabContent>
          <TabPane>
            <Row>
              <Col lg="12" md="12" className="table-col">
                <>
                  {newRequestList ? (
                    <>
                      <UserListTable
                        latestData={handleLatestDataForDelete}
                        loading={false}
                        req_data={newRequestList}
                        pageCount={pageCount}
                        handlePageNumber={handlePageNumber}
                        pageNumber={pageNumber}
                        handleLatestData={handleLatestData}
                        handlePageSize={handlePageSize}
                        recordsCount={recordsCount}
                        handleReset={handleReset}
                      />
                    </>
                  ) : (
                    ''
                  )}
                </>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </>
  );
};

export default Index;

import L from 'leaflet';
import moment from 'moment';
import {
  MonthsArray,
  PARAMETER_SLUGS,
  SHOW_DAY_AND_QUANTILE,
  SHOW_VALIDTIME_AND_LAYERS,
  SOUTH_ASIA_CONST,
} from '../../Components/constants';
/**
 * Remove Legends
 */

export const removeLegend = id => {
  const legend = L.control();
  const image = document.getElementById(`${id}`);
  legend.onRemove = () => {
    if (image) {
      L.DomUtil.remove(image);
    }
  };
  legend.onRemove();
};

export const iriDate = (selectedDate, dateListing) => {
  const date = {
    startDate: '',
    endDate: '',
  };
  const endIriDate = selectedDate?.month?.id + '-' + selectedDate?.year?.value;
  const addValue = dateListing.find(each => each?.id === selectedDate?.lead_time?.id);
  const endIriEnd12 = moment(endIriDate, 'MM-YYYY')
    .add(addValue?.indexValue, 'months')
    .format('MM-YYYY');
  let iriStartDate = moment(endIriEnd12, 'MM-YYYY')
    .startOf('month')
    .format('DD-MM-YYYY');
  let iriEndDate = moment(endIriEnd12, 'MM-YYYY')
    .endOf('month')
    .format('DD-MM-YYYY');
  date.startDate = iriStartDate;
  date.endDate = iriEndDate;
  return date;
};

export const renderStaticResourceImageApi = (
  staticResourceData,
  selectedBasetime,
  selectedValidTime,
  selectedValidLayer,
  forecastType,
  daysData,
  quantileData,

  selectedStaticParam,
  selectedLocation,
) => {
  const requestData = {};

  if (staticResourceData && staticResourceData.length) {
    requestData.ecmwf_base_time = selectedBasetime?.value;
    requestData.country_id = selectedLocation?.country?.id;
    requestData.parameter_slug = selectedStaticParam?.slug;
    if (SHOW_VALIDTIME_AND_LAYERS.includes(selectedStaticParam?.slug)) {
      requestData.ecmwf_valid_time = selectedValidTime?.value;
    }
    // selectedStaticParam?.slug === PARAMETER_SLUGS.SOIL_MOISTURE
    if (selectedStaticParam?.slug === PARAMETER_SLUGS.SOIL_MOISTURE) {
      requestData.ecmwf_level = selectedValidLayer?.value;
    }

    if (selectedStaticParam?.slug === PARAMETER_SLUGS.PRECEIPITATION_SEAS5) {
      requestData.ecmwf_forecast_type = forecastType?.selectedForecastType?.value;
    }
    if (SHOW_DAY_AND_QUANTILE.includes(selectedStaticParam?.slug)) {
      // daysData?.selectedDay
      requestData.ecmwf_day = daysData?.selectedDay?.value;
      requestData.ecmwf_quantile = quantileData?.selectedQuantile?.value;
    }
  }
  return requestData;
};

export const EventDateFormatter = dateString => {
  return moment(dateString).format('MMMM D, YYYY');
};

export const EventTimeFormatter = time => {
  return moment(time, 'HH:mm:ss').format('h:mm A');
};

export const parseCoordinate = coordinate => {
  // Remove degree symbol and direction letters (N, S, E, W) in a case-insensitive manner
  const numericValue = coordinate.replace(/[°nsweNSWE]/gi, '').trim();
  return parseFloat(numericValue);
};

export const renderParametersOptions = (list, action) => {
  const regionOptions = {
    name: SOUTH_ASIA_CONST.name,
    label: SOUTH_ASIA_CONST.name,
    value: SOUTH_ASIA_CONST.id,
    id: SOUTH_ASIA_CONST.id,
    latitude: 24.5,
    longitude: 80.5,
  };

  if (list) {
    let params = [...list];
    let options = [];
    if (params.length) {
      let default_option =
        action === 'country' ? regionOptions : { name: 'select', label: 'Select', value: '' };
      options.push(default_option);
      params.map(param => {
        options.push(param);
        return true;
      });
      return options;
    }
  }
};

export const getCurrentYearMonth = () => {
  return moment(new Date()).format('YYYY-MM');
};

export const getBulletinMonthFormat = dateString => {
  const givenDate = new Date(dateString);
  const month = MonthsArray[givenDate.getMonth()];
  const year = givenDate.getFullYear();

  return `${month}-${year}`;
};

export const getBulletinDateFormatwithOutSubScript = dateString => {
  const givenDate = new Date(dateString);
  const formattedDate = moment(givenDate).format('DD/MMM/YYYY');
  return formattedDate;
};

export const getCoordinatesAndZoom = data => {
  const location = data?.district ? data?.district : data?.state ? data?.state : data?.country;

  return {
    latitude: location?.latitude,
    longitude: location?.longitude,
    zoom: location?.zoom_level || location?.country_zoom_level,
  };
};

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { AXIOS_INSTANCE, ACCESS_KEY_URL, PUBLIC_API_URL, API_BASE_URL } from '../api/apiEndPoint';
import {
  ACCESS_KEY_CONST,
  GET_ALL_BY_TYPE_CONST,
  GET_BY_ID_CONST,
  GET_PARAMETERS_TYPE_CONST,
  CHANGE_STATUS_CONST,
  DELETE_CONST,
  GET_INDICES_CONST,
} from './accessKeyConsts';
import {
  getAccessKeySuccess,
  getAccessKeyFailed,
  getAllByTypeSuccess,
  getAllByTypeFailed,
  getByIdSuccess,
  getByIdFailed,
  getParametersSuccess,
  getParametersFailed,
  changeStatusSuccess,
  changeStatusFailed,
  deleteSuccess,
  deleteFailed,
  getIndicesSuccess,
  getIndicesFailed,
} from './accessKeyActions';
import { showSuccess } from '../../Components/Notifications';

const getAccessKeyApiRequest = async payload => {
  let response = {};
  try {
    const responseData = await AXIOS_INSTANCE.post(`${ACCESS_KEY_URL}/request-accesskey`, payload);
    response = {
      data: responseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const getAllByTypeApiRequest = async data => {
  let response = {};
  try {
    const responseData = await AXIOS_INSTANCE.get(
      `${ACCESS_KEY_URL}/getAllByType/${data.status}?page=${data.page}&limit=${data.limit}`,
    );
    response = {
      data: responseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const getByIdApiRequest = async id => {
  let response = {};
  try {
    const responseData = await AXIOS_INSTANCE.get(`${ACCESS_KEY_URL}/getById/${id}`);
    response = {
      data: responseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const getParametersApiRequest = async () => {
  let response = {};
  try {
    const responseData = await AXIOS_INSTANCE.get(`${PUBLIC_API_URL}/getDroughtIndices`);
    response = {
      data: responseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const changeStatusApiRequest = async data => {
  let response = {};
  try {
    const responseData = await AXIOS_INSTANCE.post(`${ACCESS_KEY_URL}/changeStatus`, data);
    response = {
      apiResponse: responseData.data,
      data: data.id,
      status: 200,
    };
    showSuccess(response.apiResponse.result);
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const deleteApiRequest = async id => {
  let response = {};
  try {
    const responseData = await AXIOS_INSTANCE.delete(`${ACCESS_KEY_URL}/delete/${id}`);
    response = {
      data: responseData.data,
      status: 200,
    };
    showSuccess(response.data.result);
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const getIndicesDataApiRequest = async payload => {
  let response = {};
  try {
    const responseData = await AXIOS_INSTANCE.post(
      `${API_BASE_URL}/${payload.module}/getDroughtIndices`,
      payload.requestObj,
    );
    response = {
      data: responseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getAccessKeyRequest(action) {
  try {
    const apiResponse = yield call(getAccessKeyApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getAccessKeySuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getAccessKeyFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getAccessKeyFailed(errorData));
  }
}

function* getAllByTypeRequest(action) {
  try {
    const apiResponse = yield call(getAllByTypeApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getAllByTypeSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getAllByTypeFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getAllByTypeFailed(errorData));
  }
}

function* getByIdRequest(action) {
  try {
    const apiResponse = yield call(getByIdApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getByIdSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getByIdFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getByIdFailed(errorData));
  }
}

function* getParametersRequest(action) {
  try {
    const apiResponse = yield call(getParametersApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getParametersSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getParametersFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getParametersFailed(errorData));
  }
}

function* changeStatusRequest(action) {
  try {
    const apiResponse = yield call(changeStatusApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
        // showSuccess(apiResponse)
      };
      yield put(changeStatusSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(changeStatusFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(changeStatusFailed(errorData));
  }
}

function* deleteRequest(action) {
  try {
    const apiResponse = yield call(deleteApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
        // showSuccess(apiResponse)
      };
      yield put(deleteSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(deleteFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(changeStatusFailed(errorData));
  }
}

function* getIndicesData(action) {
  try {
    const apiResponse = yield call(getIndicesDataApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getIndicesSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getIndicesFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getIndicesFailed(errorData));
  }
}

export function* watchGetAccessKey() {
  yield takeEvery(ACCESS_KEY_CONST.GET_ACCESS_KEY_REQUEST, getAccessKeyRequest);
  yield takeEvery(GET_ALL_BY_TYPE_CONST.GET_ALL_BY_TYPE_REQUEST, getAllByTypeRequest);
  yield takeEvery(GET_BY_ID_CONST.GET_BY_ID_REQUEST, getByIdRequest);
  yield takeEvery(GET_PARAMETERS_TYPE_CONST.GET_PARAMETERS_TYPE_REQUEST, getParametersRequest);
  yield takeEvery(CHANGE_STATUS_CONST.CHANGE_STATUS_CONST_REQUEST, changeStatusRequest);
  yield takeEvery(DELETE_CONST.DELETE_CONST_REQUEST, deleteRequest);
  yield takeEvery(GET_INDICES_CONST.GET_INDICES_REQUEST, getIndicesData);
}

function* AccessKeySaga() {
  yield all([fork(watchGetAccessKey)]);
}

export default AccessKeySaga;

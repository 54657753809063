import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { userDetailRequest } from '../../redux/user-list/userListAction';
import ManageEvents from './ManageEvents';
import ManageImpacts from './ManageImpacts';
import ManagePartners from './ManagePartners';
import ManageTestimonials from './ManageTestimonials';
import Sidebar from './Sidebar';
import ManagePillars from './managePillars';

function DmsLandingPage() {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState({
    label: 'Manage Pillars',
    key: 'pillars',
  });
  const [userPermisions, setUserPermisions] = useState([]);

  const nextProps = useSelector(state => ({
    userDetailData: state.userList.userDetailData || null,
  }));

  const handleSelectOption = option => {
    setSelectedOption(option);
  };

  const renderComponent = () => {
    switch (selectedOption.key) {
      case 'pillars':
        return <ManagePillars title={selectedOption} userPermisions={userPermisions} />;
      case 'events':
        return <ManageEvents title={selectedOption} userPermisions={userPermisions} />;
      case 'testimonials':
        return <ManageTestimonials title={selectedOption} userPermisions={userPermisions} />;
      case 'partners':
        return <ManagePartners title={selectedOption} userPermisions={userPermisions} />;
      case 'impacts':
        return <ManageImpacts title={selectedOption} userPermisions={userPermisions} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    dispatch(userDetailRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (nextProps.userDetailData) {
      const {
        data: { result },
      } = nextProps.userDetailData;
      if (result?.user_permissions) {
        const permissions = result?.user_permissions.map(permission => permission.codename);
        setUserPermisions(permissions);
      }
    }
  }, [nextProps.userDetailData]);

  return (
    <div className="dms-landing-page d-flex">
      <Sidebar handleSelectOption={handleSelectOption} selectedOption={selectedOption} />
      <div className="dms-components">{renderComponent()}</div>
    </div>
  );
}

export default DmsLandingPage;

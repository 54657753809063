import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Container } from 'reactstrap';
import moment from 'moment';
import { t } from 'react-multi-lang';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import SettingIcon from '../assets/Images/dashboard-img.png';
import ManageStaticIcon from '../assets/Images/dashboard-img1.png';
import ManageUserIcon from '../assets/Images/dashboard-img2.png';
import ContigencyIcon from '../assets/Images/ContigencyIconBo.png';
import ManagePublicIcon from '../assets/Images/dashboard-img3.png';
import LandingPageIcon from '../assets/Images/landing-page-icon.svg';
import { isSuperAdmin, isStaffUser } from './common/utils';
import { userDetailRequest } from '../redux/user-list/userListAction';
import Loader from '../Components/common/loader';
import { userPermissionsRequest } from '../redux/user-list/userListAction';
import {
  contingencyPlanLogsRequest,
  contingencyPlanLogsSuccess,
} from '../redux/contingencyPlan/contingencyPlanAction';
const SettingsDashboard = () => {
  const cardsInfo = [
    {
      title: 'Manage Static Data',
      icon: ManageStaticIcon,
      path: '/manageStaticData',
      group_name: 'Manage Static Resource',
    },
    {
      title: 'Manage Public API',
      icon: ManagePublicIcon,
      path: '/public-api-admin',
      group_name: 'Manage Public API Access Key',
    },
    {
      title: 'Manage User',
      icon: ManageUserIcon,
      path: '/manage-user-list',
      group_name: 'Manage Users',
    },

    // {
    //   title: 'Manage Crops',
    //   icon: ManageUserIcon,
    //   path: '/ManageCrops',
    //   group_name: 'Manage Crops',
    // },
    {
      title: 'Manage Newsfeed',
      icon: ManageUserIcon,
      path: '/manage-newsfeed',
      group_name: 'News Feed',
    },
    {
      title: 'Manage User Guide',
      icon: ManageUserIcon,
      path: '/manage-userguide',
      group_name: 'User Guide',
    },
    // {
    //   title: 'Manage Template',
    //   icon: ManageUserIcon,
    //   path: '/bulletin',
    //   group_name: 'Manage Template',
    // },
    {
      title: 'Manage Bulletin',
      icon: ManageUserIcon,
      path: '/back-office-bulletin/:currentTab',
      group_name: 'Manage Bulletin',
    },
    {
      title: t('LANDING_PAGE.MANGE_DMS_LANDING_PAGE'),
      icon: LandingPageIcon,
      path: '/manage-dms',
      group_name: 'Manage Landing page',
    },
    {
      title: 'Download Contingency Log History',
      icon: ContigencyIcon,
      path: '',
      group_name: '',
      isSuperAdmin: true,
    },
  ];
  const history = useHistory();
  const dispatch = useDispatch();
  const [cardList, setCardList] = useState([]);
  const [userPermissions, setUserPermissions] = useState(); // individual user permissions
  const [cardsInformation, setCardsInformation] = useState();
  const nextProps = useSelector(state => ({
    userDetailData: state.userList.userDetailData || null,
    userPermissionsData: state.userList.userPermissionsData || null,
    contgencyLogsData: state?.ContingencyPlan?.contgencyLogsData,
  }));
  useEffect(() => {
    if (!isSuperAdmin()) {
      if (!isStaffUser()) {
        history.push('/drought-management-system?Country=mali');
      }
    }
    dispatch(userDetailRequest());
    dispatch(userPermissionsRequest());

    return () => {
      dispatch(contingencyPlanLogsSuccess({}));
    };
  }, []);

  useEffect(() => {
    if (nextProps.userPermissionsData) {
      const {
        data: { result },
      } = nextProps.userPermissionsData;
      if (result) {
        cardsInfo.map(i => {
          let b = [];
          result.map(data => {
            if (i.title === 'Download Contingency Log History' && isSuperAdmin()) {
              b.push(data.code_name);
            } else if (i.group_name === data.group_name || isSuperAdmin()) {
              b.push(data.code_name);
            }
            i.code_name_list = b;
          });
        });
        setCardsInformation(cardsInfo);
      }
    }
  }, [nextProps.userPermissionsData]);

  useEffect(() => {
    if (nextProps?.contgencyLogsData?.data?.length) {
      const data = nextProps?.contgencyLogsData?.data;

      // Step 2: Create a Blob
      const blob = new Blob([data], { type: 'text/plain' });

      // Step 3: Create a URL for the Blob
      const url = URL.createObjectURL(blob);

      // Step 4: Create an anchor element and trigger the download
      const a = document.createElement('a');
      a.href = url;
      const currentDate = moment().format('D/M/YYYY'); // Format the date as 20/5/2024
      a.download = `contigencylogs_${currentDate}.txt`;

      document.body.appendChild(a);
      a.click();

      // Clean up and remove the anchor element
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  }, [nextProps.contgencyLogsData]);
  useEffect(() => {
    if (nextProps.userDetailData) {
      const {
        data: { result },
      } = nextProps.userDetailData;
      if (result?.user_permissions) {
        setUserPermissions(result?.user_permissions);
      }
    }
  }, [nextProps.userDetailData]);

  useEffect(() => {
    if (cardsInformation?.length && userPermissions) {
      handleCards(userPermissions);
    }
  }, [cardsInformation, userPermissions]);

  const handleCards = list => {
    let a = [];
    if (list.length) {
      cardsInformation?.map(i => {
        list?.map(item => {
          if (i.code_name_list.includes(item.codename)) {
            if (!a.includes(i)) {
              a.push(i);
            }
          }
        });
      });
      setCardList(a);
    } else if (isSuperAdmin()) {
      setCardList(cardsInfo);
    }
  };

  const handleCardClick = i => {
    if (i.path) {
      history.push(i.path);
    } else {
      if (i.title === 'Download Contingency Log History') {
        dispatch(contingencyPlanLogsRequest());
      }
    }
  };

  return (
    <>
      <div className="global-top dashboard-border my-5 mx-4">
        <div className="dashboard-header">
          <img src={SettingIcon} alt={'icon-not available'} />
          <p className="dashboard-text">{t('DASHBOARD.DASHBOARD')}</p>
        </div>
        <Container>
          <Row>
            {!cardList?.length ? (
              <Loader />
            ) : (
              cardList.map((i, index) => {
                return (
                  <Col
                    lg={4}
                    sm={6}
                    xs={12}
                    key={i.title}
                    className="card-column"
                    onClick={() => handleCardClick(i)}
                  >
                    <Card className="dashboard-card dashboard-card-alignment custom-card-x cb-1 cursor-ponter">
                      <div className="setting-icon-container">
                        <img src={i.icon} alt={'not-available'} />
                        <p className="icon-text dt-1">{i.title}</p>
                      </div>
                      {/* <p className="icon-small-text mrl-1">{t('HOME_PAGE.DESCRIPTION')}</p> */}
                    </Card>
                  </Col>
                );
              })
            )}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default SettingsDashboard;

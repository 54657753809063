// @flow
import { all } from 'redux-saga/effects';
import HomeSaga from './home/homeSaga';
import usersSaga from './users/usersSaga';
import loginSaga from './login/loginSaga';
import weatherSaga from './weather/weatherSaga';
import mapPreferenceSaga from './map-preferences/mapPreferenceSaga';
import locationSaga from './location/saga';
import AccessKeySaga from './accessKey/accessKeySaga';
import registerSaga from './register/registerSaga';
import staticResourceSaga from './staticResource/staticResourceSaga';
import DroughtMonitorSaga from './droughtMonitor/droughtMonitorSaga';
import userListSaga from './user-list/userListSaga';
import manageCropsSaga from './manageCrops/manageCropsSaga';
import contingencyPlanSaga from './contingencyPlan/contingencyPlanSaga';
import newsFeedSaga from './newsFeed/newsFeedSaga';
import userGuideSaga from './userGuide/userGuideSaga';
import bulletinSaga from './bulletin/bulletinSaga';

export default function* rootSaga() {
  yield all([
    HomeSaga(),
    usersSaga(),
    loginSaga(),
    registerSaga(),
    weatherSaga(),
    locationSaga(),
    mapPreferenceSaga(),
    AccessKeySaga(),
    staticResourceSaga(),
    DroughtMonitorSaga(),
    userListSaga(),
    manageCropsSaga(),
    contingencyPlanSaga(),
    newsFeedSaga(),
    userGuideSaga(),
    bulletinSaga(),
  ]);
}

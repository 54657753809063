import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Row,
  Col,
  Input,
  Label,
  Collapse,
} from 'reactstrap';
import Button from 'reactstrap-button-loader';
import { useTranslation } from 'react-multi-lang';
import PropTypes from 'prop-types';
import { showError, showSuccess } from '../../Components/Notifications';
import moment from 'moment';
import { DISPLAY_DATE_FORMAT } from '../../Components/constants';
import {
  updatePermissionsRequest,
  updatePermissionsSuccess,
} from '../../redux/user-list/userListAction';

const Detailmodal = props => {
  const {
    handleReset,
    modalOpen,
    handleModalToggle,
    req_data,
    permissionsList,
    preSelectedPermissions,
    handleLatestData,
  } = props;
  const [modal, setModal] = useState(modalOpen);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [newList, setNewList] = useState([]);
  const [selectedParentCategoryName, setSelectedParentCategoryName] = useState();
  const [subArray, setSubArray] = useState([]);
  const [groupNamesList, setGroupNamesList] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);
  const dispatch = useDispatch();
  const t = useTranslation();
  const nextProps = useSelector(state => ({
    IndicesData: state.AccessKey.getParametersData || null,
    changeStatusData: state.AccessKey.changeStatusData || null,
    updatePermissionsData: state.userList.updatePermissionsData || null,
    isUpdatePermissionsRequesting: state.userList.isUpdatePermissionsRequesting,
    updatePermissionsError: state.userList.updatePermissionsError,
  }));

  useEffect(() => {
    if (nextProps.updatePermissionsData) {
      const {
        data: { result },
      } = nextProps.updatePermissionsData;
      if (result) {
        showSuccess(result);
        setModal(false);
        handleLatestData();
        handleModalToggle(false);
        handleReset();
      }
    }
  }, [nextProps.updatePermissionsData]);

  useEffect(() => {
    let a = [];
    if (permissionsList) {
      permissionsList?.map(i => {
        if (!a.includes(i.group_name)) {
          a.push(i.group_name);
        }
      });
    }
    setGroupNamesList(a);
  }, [permissionsList]);

  useEffect(() => {
    let y = [];
    if (groupNamesList && preSelectedPermissions) {
      let a = [];
      preSelectedPermissions.map(i => {
        a.push(i.id);
      });
      setSelectedPermissions(a);
      groupNamesList?.map(i => {
        let filterList = permissionsList.filter(item => item.group_name === i);
        y.push({
          category_name: i,
          list: filterList,
          checked: false,
          dropdownClicked: false,
        });
      });
      y.map(i => {
        let b = [];
        i.list.map(item => {
          if (a.includes(item.permission_id)) {
            b.push(item.permission_id);
          }
        });
        i.parentList = b;
        if (i.parentList.length === i.list.length) {
          i.checked = true;
        }
      });
      setNewList(y);
    }
  }, [groupNamesList, preSelectedPermissions]);

  useEffect(() => {
    if (selectedPermissions && permissionsList) {
      let a = [];
      let sub_arr = [];
      permissionsList?.forEach(i => {
        a.push(i.permission_id);
      });
      sub_arr = permissionsList.filter(i => i.permission_name != 'View User');
      setSubArray(sub_arr);
      let b = selectedPermissions?.sort().join(',') == a.sort().join(',');
      if (b) {
        setSelectedAll(true);
      } else {
        setSelectedAll(false);
      }
    }
  }, [selectedPermissions, permissionsList]);

  useEffect(() => {
    if (nextProps.updatePermissionsError) {
      const {
        data: { response },
      } = nextProps.updatePermissionsError;
      if (response?.data) {
        showError(response?.data.message);
        setModal(false);
        handleModalToggle(false);
      }
    }
  }, [nextProps.updatePermissionsError]);

  useEffect(() => {
    return () => {
      dispatch(updatePermissionsSuccess({}));
    };
  }, []);

  useEffect(() => {
    let a = [];
    let y = [];
    if (selectedPermissions.length) {
      if (selectedParentCategoryName) {
        let c = newList;
        c = newList.map(i => {
          if (selectedParentCategoryName === i.category_name) {
            i.list.map(x => {
              y.push(x.permission_id);
              selectedPermissions.filter(y => {
                if (x.permission_id === y) {
                  a.push(y);
                }
              });
            });
            let b = a.sort().join(',') === y.sort().join(',');
            i.checked = b;
          }
          return i;
        });
        setNewList(c);
      }
    }
  }, [selectedPermissions, selectedParentCategoryName]);

  const viewtoggle = value => {
    setModal(false);
    handleModalToggle(false);
  };

  const handleSelectAllPermissions = e => {
    let a = [];
    let b = [];
    if (!e.target.checked) {
      b = newList.map(data => {
        data.checked = false;
        return data;
      });
    } else {
      permissionsList.map(i => {
        a.push(i.permission_id);
      });
      b = newList.map(data => {
        data.checked = true;
        return data;
      });
    }
    setNewList(b);
    setSelectedPermissions(a);
    setSelectedAll(true);
  };

  const handleParentOnchangePermissions = (e, i) => {
    let a = selectedPermissions;
    let b;
    if (e.target.checked) {
      i.list.forEach(item => {
        if (!selectedPermissions.includes(item.permission_id)) {
          a.push(item.permission_id);
        }
      });
      b = newList.map(item => {
        if (item.category_name === i.category_name) {
          // i.dropdownClicked = i.dropdownClicked;
          i.checked = true;
        }
        return item;
      });
    } else {
      b = newList.map(item => {
        if (item.category_name === i.category_name) {
          a = selectedPermissions;
          i.list.map(data => {
            a = a.filter(y => {
              if (y !== data.permission_id) {
                return y;
              }
            });
          });
          //i.dropdownClicked = i.dropdownClicked;
          i.checked = false;
        }
        return item;
      });
    }
    setNewList(b);
    setSelectedPermissions(JSON.parse(JSON.stringify(a)));
  };
  const handleChildOnchangePermissions = (e, item) => {
    let a = [];
    a = selectedPermissions;
    setSelectedParentCategoryName(item.group_name);
    if (e.target.checked) {
      if (selectedPermissions.includes(item.permission_id)) {
        let b = [];
        b = selectedPermissions;
        setSelectedPermissions(b.filter(i => item.permission_id !== i));
      } else {
        if (subArray.some(r => r.permission_id === item.permission_id)) {
          a.push(item.permission_id);
          let p = permissionsList.filter(j => j.code_name === 'view_user');
          p.forEach(x => {
            if (!selectedPermissions.includes(x.permission_id)) {
              a.push(x.permission_id);
            }
          });
        } else {
          a.push(item.permission_id);
        }
        setSelectedPermissions(JSON.parse(JSON.stringify(a)));
      }
    } else {
      if (selectedPermissions.includes(item.permission_id)) {
        let b = [];
        b = selectedPermissions;
        setSelectedPermissions(b.filter(i => item.permission_id !== i));
        let x = newList;
        x.forEach(data => {
          if (item.group_name === data.category_name) {
            data.checked = false;
          }
        });
        setNewList(JSON.parse(JSON.stringify(x)));
      }
    }
  };

  const handleUpdate = () => {
    let data = {
      user_id: req_data.user.id,
      permission_ids: selectedPermissions,
    };
    dispatch(updatePermissionsRequest(data));
  };

  const handleDropdownClick = item => {
    let a = newList.map(i => {
      if (i.category_name === item.category_name) {
        i.dropdownClicked = !i.dropdownClicked;
      }
      return i;
    });
    setNewList(a);
  };

  return (
    <>
      <div className="client-modal">
        <Modal
          isOpen={modal}
          toggle={viewtoggle}
          className="user-modal-view-details modal-lg"
          backdrop="static"
        >
          <ModalHeader className="client-detail-header" toggle={viewtoggle}>
            {t('USER_LIST.DETAILS')}
          </ModalHeader>
          <ModalBody>
            <Table>
              <tr className="col-lg-12">
                <td className="col-lg-3 col-md-3 col-sm-3 client-deatils">
                  <h4>{t('USER_LIST.NAME')}</h4>
                  <p>
                    {req_data && req_data?.user_profile?.name
                      ? req_data?.user_profile?.name
                      : 'N/A'}
                  </p>
                </td>
                <td className="col-lg-3 col-md-3 col-sm-3 client-deatils">
                  <h4>{t('USER_LIST.EMAIL')}</h4>
                  <p>{req_data && req_data?.user?.email ? req_data?.user?.email : 'N/A'}</p>
                </td>
                <td className="col-lg-3 col-md-3 col-sm-3 client-deatils">
                  <h4>{t('USER_LIST.COUNTRY')}</h4>
                  <p>
                    {req_data && req_data?.user_profile?.country
                      ? req_data?.user_profile?.country
                      : 'N/A'}
                  </p>
                </td>
                <td className="col-lg-3 col-md-3 col-sm-3 client-deatils">
                  <h4>Phone</h4>
                  <p>
                    {req_data && req_data?.user_profile?.mobile_number
                      ? req_data?.user_profile?.mobile_number
                      : 'N/A'}
                  </p>
                </td>
              </tr>
              <tr className="col-lg-12">
                <td className="col-lg-3 col-md-3 col-sm-3 client-deatils">
                  <h4>{t('USER_LIST.ORGANIZATION')}</h4>
                  <p>
                    {req_data && req_data?.user_profile?.organization
                      ? req_data?.user_profile?.organization
                      : 'N/A'}
                  </p>
                </td>
                <td className="col-lg-3 col-md-3 col-sm-3 client-deatils">
                  <h4>{t('USER_LIST.LAST_LOGIN')}</h4>
                  <p>
                    {req_data && req_data?.user?.last_login
                      ? moment(req_data?.user?.last_login).format(DISPLAY_DATE_FORMAT)
                      : 'N/A'}
                  </p>
                </td>
                <td className="col-lg-3 col-md-3 col-sm-3 client-deatils">
                  <h4>{t('USER_LIST.DATE_OF_REGISTRATION')}</h4>
                  <p>
                    {req_data && req_data?.user_profile?.created_date
                      ? moment(req_data?.user_profile?.created_date).format(DISPLAY_DATE_FORMAT)
                      : 'N/A'}
                  </p>
                </td>
              </tr>
            </Table>

            <Row lg="12" className="view-detail-border" />
            <div className="mt-4 view-detail-select p-3 pb-4 user-permissions">
              <Row className="col-lg-12 view-row">
                <Col lg="6">
                  <h4 className="view-row-heading mb-4">Permissions</h4>
                </Col>
                <Col lg="6">
                  <div>
                    <Label check className="view-select-option">
                      <Input
                        type="checkbox"
                        checked={selectedAll}
                        onClick={e => handleSelectAllPermissions(e)}
                      ></Input>
                      {t('PUBLIC_API_ADMIN.SELECT_ALL')}
                    </Label>
                  </div>
                </Col>
              </Row>
              <Row className="col-lg-12 view-row">
                {newList.length &&
                  newList?.map(i => {
                    return (
                      <Col lg="4">
                        <Label className="md-filter-option dropdown-category-name">
                          <Input
                            type="checkbox"
                            checked={i?.checked}
                            onClick={e => handleParentOnchangePermissions(e, i)}
                          ></Input>
                          {i?.category_name}
                        </Label>
                        <i
                          className="fa fa-sort-down manage-user-dropdown-icon cursor-ponter"
                          onClick={() => handleDropdownClick(i)}
                        ></i>
                        <Collapse isOpen={i?.dropdownClicked}>
                          {i.list.map(item => {
                            return (
                              <Label check className="md-filter-option-collapse">
                                <Input
                                  type="checkbox"
                                  checked={selectedPermissions.includes(item.permission_id)}
                                  onClick={e => handleChildOnchangePermissions(e, item)}
                                ></Input>
                                {item.permission_name}
                              </Label>
                            );
                          })}
                        </Collapse>
                      </Col>
                    );
                  })}
              </Row>
            </div>
          </ModalBody>
          <ModalFooter className="modal-viewdeatil-footer">
            <>
              <Button onClick={() => viewtoggle('cancel')} className="reject-button">
                {t('PUBLIC_API_ADMIN.CANCEL')}
              </Button>{' '}
              <Button
                className="reject-button update-button"
                onClick={() => handleUpdate()}
                loading={nextProps.isUpdatePermissionsRequesting}
              >
                {t('PUBLIC_API_ADMIN.UPDATE')}
              </Button>{' '}
            </>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

export default Detailmodal;
Detailmodal.propTypes = {
  modalOpen: PropTypes.bool,
  handleModalToggle: PropTypes.func,
  req_data: PropTypes.object,
  handleLatestData: PropTypes.func,
  fromEditDetails: PropTypes.bool,
  activeTab: PropTypes.string,
  email: PropTypes.string,
  preSelectedPermissions: PropTypes.object,
};

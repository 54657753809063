import React from 'react';
import CloseIcon from '../../assets/Images/icons/close.png';
import Button from 'reactstrap-button-loader';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { t } from 'react-multi-lang';
import {
  renderTextField,
  renderReactSelectInputField,
} from '../../Components/common/renderInputField';

const UserFilter = props => {
  const {
    handleReset,
    nameHandler,
    emailHandler,
    mobileHandler,
    roleHandler,
    reset,
    handleCloseFilter,
  } = props;
  const roles = [
    { id: '1', name: 'User', value: 'users' },
    { id: '2', name: 'IWMI Admin', value: 'admin' },
  ];

  const renderAPIParameterOptions = (list) => {
    if (list) {
      let params = [...list];
      let options = [];
      if (params.length) {
        params.map(param => {
          let data = {
            value: param.value,
            label: `${param.name}`,
            id: param.id,
          };
          options.push(data);
          return true;
        });
        return options;
      }
    }
  };

  const handleClearFilters = () => {
    reset('filter-form');
    handleReset();
  };

  const handleName = e => {
    nameHandler(e);
  };

  const handleEmail = e => {
    emailHandler(e);
  };

  const handleMobileNumber = e => {
    mobileHandler(e);
  };

  const handleRoleChange = e => {
    roleHandler(e);
  };
  return (
    <div className="st-right-sidebar-wrapper">
      <div className="d-flex st-filter-header">
        <h5 className="st-txt-primary">{t('FILTER.FILTER')}</h5>
        <div className="st-close ml-auto">
          <img
            className="st-close-img"
            alt={''}
            src={CloseIcon}
            onClick={() => handleCloseFilter()}
          />
        </div>
      </div>
      <form id="filter-form" className="user-list-form">
        <div className="section-one-filter">
          <Field
            component={renderTextField}
            name="Name"
            className="mt-1 my-3 filter-select-placeholder"
            // placeholder=""
            type="text"
            onChange={e => handleName(e)}
            required={false}
            isMulti={false}
            placeholder="Name"
          ></Field>
          <Field
            component={renderTextField}
            name="Email"
            className="mt-1 my-3 filter-select-placeholder"
            // placeholder=""
            type="text"
            onChange={e => handleEmail(e)}
            required={false}
            isMulti={false}
            placeholder="Email"
          ></Field>
          <Field
            component={renderTextField}
            name="Phone_number"
            className="mt-1 my-3 filter-select-placeholder"
            // placeholder=""
            type="text"
            onChange={e => handleMobileNumber(e)}
            required={false}
            isMulti={false}
            placeholder="Phone"
          ></Field>
          <Field
            component={renderReactSelectInputField}
            name="role"
            placeholder="Select Role"
            className="mt-1 my-3 filter-select-placeholder select-role-field"
            onChange={e => handleRoleChange(e)}
            isMulti={false}
            options={renderAPIParameterOptions(roles)}
          ></Field>
        </div>
        <br />
        <div></div>
        <hr />
        <div className=" filter-buttons">
          <Button
            className="btn st-btn-custom-ot clear-filter-btn"
            onClick={() => handleClearFilters()}
          >
            {t('FILTER.CLEAR_FILTERS')}
          </Button>
        </div>
        <hr />
        <div style={{ height: '200px' }} />
      </form>
    </div>
  );
};
UserFilter.propTypes = {
  noRecordsErrorHandler: PropTypes.func,
  handleFilterFormData: PropTypes.func,
  formFilterData: PropTypes.object,
  nameHandler: PropTypes.func,
};
export default reduxForm({
  form: 'filter-form',
})(UserFilter);

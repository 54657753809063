import React, { useEffect, useState } from 'react';
import { Collapse, Button, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import '../assets/CSS/LeafletWrapper.css';
import { ReactComponent as ArrowUp } from '../assets/Images/upArrow.svg';
import { ReactComponent as ArrowDown } from '../assets/Images/downArrow.svg';
import { t } from 'react-multi-lang';
import { renderParamList } from './common/utils';
import { DEFAULT_PARAMETERS_CONST } from './constants';

const ParameterList = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isParameterOpen, setIsParameterOpen] = useState(false);
  const [params, setParams] = useState([]);
  const [selectedSourceId, setSelectedSourceId] = useState();
  const [selectedParent, setSelectedParent] = useState();
  const [selectedParameter, setSelectedParameter] = useState();
  const [categoryParams, setCategoryParams] = useState([]);

  useEffect(() => {
    if (props.initialParams) { 
      const categoredParamsList = renderParamList(props.initialParams);
      setCategoryParams(categoredParamsList);
      setParams(props.initialParams[0]);
    }
  }, [props.initialParams]);

  const { Weather, boundries } = useSelector(state => ({
    Weather: state.Weather,
  }));
  
  useEffect(() => {
    props.handleParameters(selectedParameter);
  }, [selectedParameter]);

  useEffect(()=>{
    props.handleDataSourceId(selectedSourceId)
  },[selectedSourceId])

  const toggle = () => setIsOpen(!isOpen);
  useEffect(() => {
    if (params) {
      let defaultValue = [];
      defaultValue.push(DEFAULT_PARAMETERS_CONST.CUMULATIVE_ANAMOLY);

      defaultValue.push(DEFAULT_PARAMETERS_CONST.PRECIPITATION);

      params?.parameterData?.map((item) => {
        item?.parameter?.map((i) => {
          if (defaultValue.includes(i.parameter.name)) {
            if (i.parameter_type !== '') {
              setIsParameterOpen(true);
              setSelectedParent(item.type);
            }
            setSelectedSourceId(i.parameter_id);
            if (categoryParams?.length) {
              const selectedParamIndex = categoryParams?.findIndex(
                (item) => item.parameter.name === i.parameter.name,
              );
              if (selectedParamIndex >= 0) {
                i.parameter.paramIndex = selectedParamIndex;
              }
              setSelectedParameter(i);
            }
            setIsOpen(true);
          }
        });
      });
    }
  }, [params]);

  
  const handleParamsList = (item) => {
    if (item.type === selectedParent) {
      setSelectedParent('');
      setSelectedSourceId('');
      setIsParameterOpen(false);
    } else {
      setSelectedParent(item.type);
      setIsParameterOpen(true);
      setSelectedSourceId('');
      setSelectedParameter('')
    }
  };
  const handleParentClick = (data) => {
    setSelectedParent('');
    const selectedParamIndex = categoryParams?.findIndex(
      (item) => item.parameter.name === data.parameter.name,
    );
    if (selectedParamIndex >= 0) {
      data.parameter.paramIndex = selectedParamIndex;
    }
    setSelectedParameter(data);
    setSelectedSourceId(data.parameter_id);
    
     

     setTimeout(()=>{
      
      const ele = document.getElementsByClassName("submitBtnClass");
     if(ele.length && !Weather.isWeatherForecastRequesting){
      ele[0].click()
     }
     },1)
  };
  const handleChildParameter = (data) => {
    const selectedParamIndex = categoryParams?.findIndex(
      (item) => item.parameter.name === data.parameter.name,
    );
    if (selectedParamIndex >= 0) {
      data.parameter.paramIndex = selectedParamIndex;
    }
    setSelectedParameter(data);
    setSelectedSourceId(data.parameter_id);
    setTimeout(()=>{
      const ele = document.getElementsByClassName("submitBtnClass");
     if(ele.length){
      ele[0].click()
     }
     },500)
  };

  return (
    <>
      <div className="params-list new-param">
        <Button
          className="param-button"
          onClick={toggle}
          style={{
            backgroundColor: isOpen ? '#1797A5' : '#5389C4',
            borderColor: isOpen ? '#1797A5' : '#5389C4',
          }}
        >
          {t('LEAFLET_WRAPPER.PARAMETERS')}
          <span className="collapse-style">{isOpen ? <ArrowUp /> : <ArrowDown />}</span>
        </Button>
        <Collapse isOpen={isOpen}>
          {params?.parameterData?.map((item) => {
            const { parameter, type } = item;
            if (type === '' || type === 'non-static') {
              return parameter.map((p,index) => {
                return (
                  <Button key={index}
                    className={
                      selectedSourceId === p.parameter.id
                        ? 'selected-parent-parameter'
                        : 'param-options'
                    }
                    onClick={() => Weather.isWeatherForecastRequesting ?  {} : handleParentClick(p)}
                  >
                    <span className="icon-style">
                      <img
                        src={
                          selectedSourceId === p.parameter_id
                            ? p.parameter.icon_selected
                            : p.parameter.icon
                        }
                        alt="img"
                        width={20}
                        height={20}
                      />
                    </span>
                    {p.parameter.name}
                  </Button>
                );
              });
            } else {
              return (
                <>
                  <Button 
                    className={
                      selectedParent === item.type ? 'selected-parent-parameter' : 'param-options'
                    }
                    onClick={() => handleParamsList(item)}
                  >
                    {item.type}
                    <span className={selectedParent === item.type ? 'blackStyle' : 'whiteStyle'}>
                      {selectedParent === item.type ? <ArrowUp /> : <ArrowDown />}
                    </span>
                  </Button>
                  {isParameterOpen && selectedParent === item.type && (
                    <Collapse isOpen={isParameterOpen}>
                      <Row className="child-row">
                        <Col sm="2" lg="2" md="2"></Col>
                        <Col sm="10" lg="10" md="10">
                          {item.parameter.map((data,index) => {
                            return (
                              <Button key={index}
                                className={
                                  selectedSourceId === data.parameter_id
                                    ? 'selected-child-parameter'
                                    : 'child-parameter'
                                }
                                onClick={() => handleChildParameter(data)}
                              >
                                <span className="icon-style">
                                  <img
                                    src={
                                      selectedSourceId === data.parameter_id
                                        ? data.parameter.icon_selected
                                        : data.parameter.icon
                                    }
                                    alt="img"
                                    width={20}
                                    height={20}
                                  />
                                </span>
                                {data.parameter.name}
                              </Button>
                            );
                          })}
                        </Col>
                      </Row>
                    </Collapse>
                  )}
                </>
              );
            }
          })}
        </Collapse>
      </div>
    </>
  );
};

ParameterList.propTypes = {
  initialParams: PropTypes.string,
  handleParameters: PropTypes.func,
  dataSourceId: PropTypes.string,
};

export default ParameterList;

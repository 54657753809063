import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Collapse, FormGroup, Label } from 'reactstrap';
import CloseIcon from '../../assets/Images/icons/close.png';
import UpIcon from '../../assets/Images/icons/up.png';
import DownIcon from '../../assets/Images/icons/down.png';
import Button from 'reactstrap-button-loader';
import { Field, reduxForm, change } from 'redux-form';
import PropTypes from 'prop-types';
import { t } from 'react-multi-lang';
import { renderTextField } from '../../Components/common/renderInputField';
import { ERPAS } from '../../Components/constants';
import _ from 'lodash';



const validate = values => {
  return {};
};

const RightSideBar = props => {
  const nextProps = useSelector(state => ({
    ForeCastSourcesData: state.Weather.weatherForecastSourcesData,
    StaticResourceData: state.staticResource.staticResourceData || null,
    isStaticResourceRequesting: state.staticResource.isStaticResourceRequesting,
  }));

  const dispatch = useDispatch();
  const {
    closed,
    handleClose,
    filterDataSources,
    handleClearFilterInitialValues,
    initialValues,
    formFilterData,
    handleFilterFormData,
    handleSubmit,
    reset,
    closeRightBar,
    handleFilter,
    noRecordsErrorHandler,
    updateInitialValues,
  } = props;

  const filter_req_data = {
    region_id: '',
    country_id: '',
    forecast_data_source_id: [],
    parameter_id: [],
    start_date: null,
    end_date: null,
    limit: 0,
  };

  const [isDataSourceOpen, setIsDataSourceOpen] = useState(false);
  const [isParametersOpen, setIsParametersOpen] = useState(false);
  const [isDataRangeOpen, setIsDataRangeOpen] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [handlersClicked,setHandlersClicked] = useState(false)



  useEffect(() => {
    if (nextProps.StaticResourceData) {
      const {
        data: { result },
      } = nextProps.StaticResourceData;
      if (result.length <= 0) {
        noRecordsErrorHandler();
      }
    }
  }, [nextProps.StaticResourceData]);

  useEffect(() => {
    if (formFilterData) {
      if (formFilterData?.forecast_data_source_id?.length !== 0) {
        setIsDataSourceOpen(true);
      }
      if (formFilterData?.parameter_id?.length !== 0) {
        setIsParametersOpen(true);
      }
      if (formFilterData?.start_date || formFilterData?.end_date) {
        setIsDataRangeOpen(true);
      }
    } 
  }, [formFilterData]);

  useEffect(()=>{
    if (handlersClicked){
      if (formFilterData?.forecast_data_source_id?.length !== 0 || formFilterData?.parameter_id?.length !== 0 || formFilterData?.start_date || formFilterData?.end_date){
        setSubmitDisabled(false)
      }
  }
  },[formFilterData.forecast_data_source_id, formFilterData.parameter_id, formFilterData.start_date, formFilterData?.end_date])


  useEffect(() => {
    if (!closed) {
      if (filterDataSources) {
        const { data } = filterDataSources;
        if (data?.result) {
          let categories = [];
          let paramArr = [];
          data?.result.map(i => {
            if (i.category !== 'Weather Forecast') {
              i.name = i.category;
              {
                i.data.map(item => {
                  i.id = item?.forecast_data_source?.forecast_data_source_category_id;
                  item.checked = false;
                });
              }
              categories.push(i);
            }
            if (i.category === 'Seasonal') {
              /**
               * changed by: sai krishna thalla
               * reason: filter paremeters data is not comming
               * date: 6-sep-2022
               */
              let params = i.data[0]?.forecast_data_source?.forecast_data_source_with_parameter;
              if (params?.length) {
                params?.map(item => {
                  item?.parameter?.map(p => {
                    let x = { ...p };
                    x.name = p.parameter.name;
                    x.id = p.parameter_id;
                    x.slug = p.parameter.slug;
                    x.checked = false;
                    paramArr.push(x);
                  });
                });
              }
              updateInitialValues({
                dataSourceData: categories,
                parameterData: paramArr,
                start_date: '',
                end_date: '',
              });
            }
          });
         categories =  handleErpasCatagory(categories)
        }
      }
    }
  }, [filterDataSources]);

  const handleErpasCatagory =(catagories)=>{
    let a =[]
    a = catagories[1].data.filter((i)=>{return i.forecast_data_source.name != ERPAS})
    catagories[1].data = a;
    return catagories
  }

  const handleChangeParameters = (e, id) => {
    let a = formFilterData.parameter_id;
    initialValues.parameterData.map(item => {
      if (item.id === id) {
        if (a.includes(id)) {
          item.checked = false;
          formFilterData.parameter_id = a.filter(i => i !== id);
          handleFilterFormData(formFilterData, item);
          if (a.length < 0) {
            setSubmitDisabled(true);
          }
        } else {
          item.checked = true;
          a.push(item.id);
          formFilterData.parameter_id = a;
          handleFilterFormData(formFilterData, item);
          setSubmitDisabled(false);
        }
      }
    });
    setHandlersClicked(true)
  };

  const handleDataSourceChange = (e, id) => {
    let a = formFilterData.forecast_data_source_id;
    initialValues.dataSourceData.map(i => {
      i.data.map(item => {
        if (item.forecast_data_source.id === id) {
          if (a?.includes(id)) {
            item.checked = false;
            formFilterData.forecast_data_source_id = a.filter(i => i !== id);
            handleFilterFormData(formFilterData, i);
            if (a.length < 0) {
              setSubmitDisabled(true);
            }
          } else {
            item.checked = true;
            a.push(item.forecast_data_source.id);
            formFilterData.forecast_data_source_id = a;
            handleFilterFormData(formFilterData, i);
            setSubmitDisabled(false);
          }
        }
      });
    });
    setHandlersClicked(true)
  };
  

  const onFormSubmit = formProps => {
    handleFilter(formFilterData);
  };
  const handleStartDate = e => {
    formFilterData.start_date = e.target.value;
    handleFilterFormData(formFilterData);
    updateInitialValues({
      ...initialValues,
      start_date: e.target.value !== '' ? e.target.value : null,
    });
    setSubmitDisabled(false);
    setHandlersClicked(true)
  };

  const handleEndDate = e => {
    formFilterData.end_date = e.target.value;
    updateInitialValues({
      ...initialValues,
      end_date: e.target.value !== '' ? e.target.value : null,
    });
    handleFilterFormData(formFilterData);
    setSubmitDisabled(false);
    setHandlersClicked(true)
  };

  const handleClearFilters = () => {
    reset('filter-form');
    dispatch(change('filter-form', 'start_date', ''));
    dispatch(change('filter-form', 'end_date', ''));
    let updatedInitialValues = JSON.parse(JSON.stringify(initialValues));
    let updatedParamValues = JSON.parse(JSON.stringify(initialValues));
    if (initialValues.dataSourceData) {
      updatedInitialValues = initialValues.dataSourceData.map(i => {
        let x = JSON.parse(JSON.stringify(i));
        x.data = i.data.map(item => {
          item.checked = false;
          return item;
        });
        return x;
      });
    }
    if (initialValues.parameterData) {
      updatedParamValues = initialValues.parameterData.map(i => {
        let x = JSON.parse(JSON.stringify(i));
        x.checked = false;
        return x;
      });
    }
    setIsDataRangeOpen(false);
    setIsParametersOpen(false);
    setIsDataSourceOpen(false);
    handleClearFilterInitialValues(updatedInitialValues, updatedParamValues);
    handleFilter(filter_req_data);
    window.scrollTo({ top: 0, behavior: 'auto' });
  };


  return (
    <div className="st-right-sidebar-wrapper">
      <div className="d-flex st-filter-header">
        <h5 className="st-txt-primary">{t('FILTER.FILTER')}</h5>
        <div className="st-close ml-auto">
          <img
            className="st-close-img"
            alt={'not-available'}
            src={CloseIcon}
            onClick={() => {
              handleClose();
              closeRightBar();
            }}
          />
        </div>
      </div>
      <div className="st-acc-bar" onClick={() => setIsDataSourceOpen(!isDataSourceOpen)}>
        <div className="d-flex">
          <h6 className="st-txt-primary">{t('FILTER.DATA_SOURCE')}</h6>
          <div className="st-close ml-auto">
            <img
              className="st-close-img"
              alt={'not-available'}
              src={isDataSourceOpen ? UpIcon : DownIcon}
            />
          </div>
        </div>
      </div>
      <form id="filter-form" onSubmit={handleSubmit(onFormSubmit)}>
        <div>
          <Collapse isOpen={isDataSourceOpen}>
            {initialValues?.dataSourceData?.map(i => {
              return (
                <div className="st-collapse-padding">
                  <p className="title-name">{i.name}</p>
                  {i.data.map(item => {
                    return (
                      <>
                        <span className="data-source-checkbox">
                          <Field
                            name={item?.forecast_data_source?.name}
                            id={item?.forecast_data_source?.id}
                            onChange={e =>
                              handleDataSourceChange(e, item?.forecast_data_source?.id)
                            }
                            component={'input'}
                            type="checkbox"
                            checked={item.checked}
                          ></Field>

                          <label htmlFor={item?.forecast_data_source?.name}>
                            {item?.forecast_data_source?.name}
                          </label>
                        </span>
                      </>
                    );
                  })}
                </div>
              );
            })}
          </Collapse>
        </div>
        <br />
        <div className="st-acc-bar" onClick={() => setIsParametersOpen(!isParametersOpen)}>
          <div className="d-flex">
            <h6 className="st-txt-primary">{t('FILTER.PARAMETERS')}</h6>
            <div className="st-close ml-auto">
              <img
                className="st-close-img"
                alt={'not-available'}
                src={isParametersOpen ? UpIcon : DownIcon}
              />
            </div>
          </div>
        </div>
        <div>
          <Collapse isOpen={isParametersOpen}>
            <div className="st-collapse-padding">
              {/* <p className="">{t('FILTER.SUB_SEASONAL')}</p> */}
              {initialValues?.parameterData?.map(item => {
                return (
                  <>
                    <FormGroup check inline>
                      <Label check>
                        <Field
                          component={'input'}
                          type="checkbox"
                          name={item.name}
                          onChange={e => handleChangeParameters(e, item.id)}
                          checked={item.checked}
                        />{' '}
                        {item.name}
                      </Label>
                    </FormGroup>
                  </>
                );
              })}
            </div>
          </Collapse>
        </div>
        <br />
        <div className="st-acc-bar" onClick={() => setIsDataRangeOpen(!isDataRangeOpen)}>
          <div className="d-flex">
            <h6 className="st-txt-primary">{t('FILTER.DATE_RANGE')}</h6>
            <div className="st-close ml-auto">
              <img
                className="st-close-img"
                alt={'not-available'}
                src={isDataRangeOpen ? UpIcon : DownIcon}
              />
            </div>
          </div>
        </div>
        <div>
          <Collapse isOpen={isDataRangeOpen}>
            <div className="st-collapse-padding">
              <FormGroup>
                <Field
                  component={renderTextField}
                  name="start_date"
                  bsSize="sm"
                  className="mt-1 my-3 input-style-width filter-select-placeholder"
                  placeholder="Select Start date"
                  type="date"
                  value={initialValues.start_date}
                  defaultValue={initialValues.start_date}
                  onChange={e => handleStartDate(e)}
                  isMulti={false}
                />
              </FormGroup>
              <br />
              <FormGroup>
                <Field
                  component={renderTextField}
                  name="end_date"
                  bsSize="sm"
                  className="mt-1 my-3 input-style-width filter-select-placeholder"
                  placeholder="Select End date"
                  type="date"
                  value={initialValues.end_date}
                  defaultValue={initialValues.end_date}
                  onChange={e => handleEndDate(e)}
                  isMulti={false}
                ></Field>
              </FormGroup>
            </div>
          </Collapse>
        </div>
        <hr />
        <div className="filter-buttons">
          <Button
            className="btn st-btn-custom-ot clear-filter-btn clear-filter-btn-box-shadow"
            onClick={() => handleClearFilters()}
          >
            {t('FILTER.CLEAR_FILTERS')}
          </Button>
          <Button
            className="btn st-btn-custom manage-static-submit-btn"
            disabled={submitDisabled}
            // loading={nextProps.isStaticResourceRequesting} commented because no need to show loader on this button
          >
            {t('FILTER.SHOW_RESULT')}
          </Button>
        </div>
        <hr />
        <div style={{ height: '200px' }} />
      </form>
    </div>
  );
};
RightSideBar.propTypes = {
  noRecordsErrorHandler: PropTypes.func,
  handleFilterFormData: PropTypes.func,
  formFilterData: PropTypes.object,
};
export default reduxForm({
  form: 'filter-form',
  validate,
  enableReinitialize: true,
})(RightSideBar);

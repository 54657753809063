import { USER_LIST_CONST, USER_BY_ID_CONST, USER_DELETE_CONST,USER_PERMISSIONS_CONST, UPDATE_PERMISSIONS_CONST, USER_PERMISSIONS_BY_ID_CONST,USER_DETAIL_CONST}  from './userListConst';
export const userListRequest = (data) => ({
    type: USER_LIST_CONST.USER_LIST_REQUEST,
    payload: data,
  });
  
  export const userListSuccess = (response) => ({
    type: USER_LIST_CONST.USER_LIST_SUCCESS,
    payload: response,
  });
  
  export const userListFailed = (error) => ({
    type: USER_LIST_CONST.USER_LIST_FAILED,
    payload: error,
  });


  export const userByIdRequest = (data) => ({
    type: USER_BY_ID_CONST.USER_BY_ID_REQUEST,
    payload: data,
  });
  
  export const userByIdSuccess = (response) => ({
    type: USER_BY_ID_CONST.USER_BY_ID_SUCCESS,
    payload: response,
  });
  
  export const userByIdFailed = (error) => ({
    type: USER_BY_ID_CONST.USER_BY_ID_FAILED,
    payload: error,
  });


  export const deleteUserRequest = (data) => ({
    type: USER_DELETE_CONST.USER_DELETE_REQUEST,
    payload: data,
  });
  
  export const deleteUserSuccess = (response) => ({
    type: USER_DELETE_CONST.USER_DELETE_SUCCESS,
    payload: response,
  });
  
  export const deleteUserFailed = (error) => ({
    type: USER_DELETE_CONST.USER_DELETE_FAILED,
    payload: error,
  });


  export const userPermissionsRequest = (data) => ({
    type: USER_PERMISSIONS_CONST.USER_PERMISSION_REQUEST,
    payload: data,
  });
  
  export const userPermissionsSuccess = (response) => ({
    type: USER_PERMISSIONS_CONST.USER_PERMISSION_SUCCESS,
    payload: response,
  });
  
  export const userPermissionsFailed = (error) => ({
    type: USER_PERMISSIONS_CONST.USER_PERMISSION_FAILED,
    payload: error,
  });


  export const userDetailRequest = (data) => ({
    type: USER_DETAIL_CONST.USER_DETAIL_REQUEST,
    payload: data,
  });
  
  export const userDetailSuccess = (response) => ({
    type: USER_DETAIL_CONST.USER_DETAIL_SUCCESS,
    payload: response,
  });
  
  export const userDetailFailed = (error) => ({
    type: USER_DETAIL_CONST.USER_DETAIL_FAILED,
    payload: error,
  });




  export const updatePermissionsRequest = (data) => ({
    type: UPDATE_PERMISSIONS_CONST.UPDATE_PERMISSION_REQUEST,
    payload: data,
  });
  
  export const updatePermissionsSuccess = (response) => ({
    type: UPDATE_PERMISSIONS_CONST.UPDATE_PERMISSION_SUCCESS,
    payload: response,
  });
  
  export const updatePermissionsFailed = (error) => ({
    type: UPDATE_PERMISSIONS_CONST.UPDATE_PERMISSION_FAILED,
    payload: error,
  });
  

  export const userPermissionsByIdRequest = (data) => ({
    type: USER_PERMISSIONS_BY_ID_CONST.USER_PERMISSIONS_BY_ID_REQUEST,
    payload: data,
  });
  
  export const userPermissionsByIdSuccess = (response) => ({
    type: USER_PERMISSIONS_BY_ID_CONST.USER_PERMISSIONS_BY_ID_SUCCESS,
    payload: response,
  });
  
  export const userPermissionsByIdFailed = (error) => ({
    type: USER_PERMISSIONS_BY_ID_CONST.USER_PERMISSIONS_BY_ID_FAILED,
    payload: error,
  });
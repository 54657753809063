import React from 'react';
import TimePicker from 'react-time-picker';

const EventTimePicker = props => {
  const { timeChange, time } = props;
  const handleTime = value => {
    timeChange(value);
  };

  return (
    <TimePicker
      onChange={value => handleTime(value)}
      value={time}
      secondPlaceholder="--"
      hourPlaceholder="hh"
      minutePlaceholder="mm"
      amPmAriaLabel="Select AM/PM"
    />
  );
};
export default EventTimePicker;

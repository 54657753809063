const LOCATION_CONST = {
  //for country
  COUNTRY_LIST_REQUEST: 'COUNTRY_LIST_REQUEST',
  COUNTRY_LIST_SUCCESS: 'COUNTRY_LIST_SUCCESS',
  COUNTRY_LIST_FAILED: 'COUNTRY_LIST_FAILED',
  //for state
  STATE_LIST_REQUEST: 'STATE_LIST_REQUEST',
  STATE_LIST_SUCCESS: 'STATE_LIST_SUCCESS',
  STATE_LIST_FAILED: 'STATE_LIST_FAILED',
  //for district
  DISTRICT_LIST_REQUEST: 'DISTRICT_LIST_REQUEST',
  DISTRICT_LIST_SUCCESS: 'DISTRICT_LIST_SUCCESS',
  DISTRICT_LIST_FAILED: 'DISTRICT_LIST_FAILED',
  //for Location convention
  LOCATION_CONVENTION_REQUEST: 'LOCATION_CONVENTION_REQUEST',
  LOCATION_CONVENTION_SUCCESS: 'LOCATION_CONVENTION_SUCCESS',
  LOCATION_CONVENTION_FAILED: 'LOCATION_CONVENTION_FAILED',
  //for country back office
  COUNTRY_LIST_BO_REQUEST: 'COUNTRY_LIST_BO_REQUEST',
  COUNTRY_LIST_BO_SUCCESS: 'COUNTRY_LIST_BO_SUCCESS',
  COUNTRY_LIST_BO_FAILED: 'COUNTRY_LIST_BO_FAILED',
};

export default LOCATION_CONST;

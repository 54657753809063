import React, { useState, useEffect } from 'react';
import { FeatureGroup, Circle } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import _ from 'lodash';
import 'leaflet-draw';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css'; // Re-uses images from ~leaflet package
import 'leaflet-defaulticon-compatibility';
import PropTypes from 'prop-types';
import { t } from 'react-multi-lang';
import { DRAW_SHAPE } from './../../Components/constants/index';
import { showError } from '../../Components/Notifications';
import { getRegionData, removeRegionTooltip, setRegionData } from '../../Components/common/utils';

function DrawShape(props) {
  const { handleSelectedRegion, country, state, district } = props;
  const [editableFG, setEditableFG] = useState(null);
  const [mapLayers, setMapLayers] = useState([]);
  let drawControlRef = React.useRef();

  useEffect(() => {
    let mapData = JSON.parse(JSON.stringify(mapLayers));
    handleSelectedRegion(mapData);
    setRegionData(mapData);
  }, [mapLayers]);

  useEffect(() => {
    if (editableFG) {
      // here you have all the stored layers
      const drawnItems = editableFG?._layers;
      if (!_.isEmpty(drawnItems)) {
        Object.keys(drawnItems).forEach((layerid, index) => {
          if (index === 0) {
            if (_.isEmpty(mapLayers)) {
              editableFG.removeLayer(drawnItems[layerid]);
              return;
            }
          }
          if (index < DRAW_SHAPE.MAXIMUM_SHAPE) {
            return;
          } else {
            const layer = drawnItems[layerid];
            editableFG.removeLayer(layer);
            setMapLayers(mapLayers.filter((data) => data.id !== Number(layerid)));
            showError(t('EDIT_CONTROL.MAXIMUM_SHAPE_ERROR'));
          }
        });
      }
    }
  }, [editableFG]);

  useEffect(() => {
    if (!_.isEmpty(editableFG)) {
      // here you have all the stored layers
      const drawnItems = editableFG?._layers;
      // if the number of layers is bigger than 1 then delete the first
      if (!_.isEmpty(drawnItems)) {
        Object.keys(drawnItems).forEach((layerid, index) => {
          const layer = drawnItems[layerid];
          editableFG.removeLayer(layer);
        });
        setMapLayers([]);
      }
    }
  }, [country, state, district]);

  let tempArray = [];

  const onCreated = (e) => {
    const { layerType, layer } = e;
    let regionData = getRegionData();
    let polyCount = regionData?.length
      ? (regionData?.filter((item) => item.layerType === 'polygon')).length
      : 0;

    if (layerType === 'marker') {
      let latlang = [layer._latlng.lat, layer._latlng.lng];
      setMapLayers((data) => [
        ...data,
        { id: layer._leaflet_id, layerType, polygonCount: '', latlngs: [...latlang] },
      ]);
      tempArray.push(e);
      setEditableFG(tempArray);
      return;
    }
    if (layerType === 'polygon') {
      const { _leaflet_id } = layer;
      let layerLatLng = [];
      const cordinatesArray = layer.getLatLngs()[0];
      cordinatesArray?.map(({ lat, lng }) => {
        layerLatLng.push([lat, lng]);
      });
      if (_leaflet_id && layerLatLng?.length) {
        setMapLayers((layers) => [...layers, { id: _leaflet_id, layerType, latlngs: layerLatLng, polygonCount: polyCount + 1, }]);
        tempArray.push(e);
        setEditableFG(tempArray);
      }
    }
  };

  const onEdited = (e) => {
    const {
      layers: { _layers },
    } = e;
    Object.values(_layers).map(({ _leaflet_id, editing }) => {
      let layerLatLng = [];
      if (editing.latlngs?.length) {
        const cordinatesArray = editing.latlngs[0];
        cordinatesArray?.length &&
          cordinatesArray[0].map(({ lat, lng }) => {
            layerLatLng.push([lat, lng]);
          });
        setMapLayers((layers) =>
          layers.map((l) => (l.id === _leaflet_id ? { ...l, latlngs: [...layerLatLng] } : l)),
        );
      } else if (!_.isEmpty(editing._marker._latlng)) {
        let cordinates = editing._marker._latlng;
        setMapLayers((layers) =>
          layers.map((l) =>
            l.id === _leaflet_id ? { ...l, latlngs: [cordinates.lat, cordinates.lng] } : l,
          ),
        );
      }
    });
  };

  const onDelete = (e) => {
    const {
      layers: { _layers },
    } = e;

    Object.values(_layers).map(({ _leaflet_id }) => {
      setMapLayers((layers) => layers.filter((l) => l.id !== _leaflet_id));
    });
  };

  const onFeatureGroupReady = (reactFGref) => {
    if (reactFGref) {
      const drawnItems = reactFGref?._layers;
      let count = 1;
      var elements = document.getElementsByClassName('draw-polygon');
      var markerElements = document.getElementsByClassName('draw-marker');

      removeRegionTooltip(drawnItems, elements);
      removeRegionTooltip(drawnItems, markerElements);

      Object.values(drawnItems)?.forEach((layer, index) => {
        if (!_.isEmpty(layer)) {
          if (layer.hasOwnProperty('_latlngs')) {
            if (index <= 1) {
              layer.bindTooltip(`polygon-${count}`, {
                className: 'draw-polygon',
                permanent: true,
                direction: 'top',
              });
              count++;
            }
          }
           else if(layer?.hasOwnProperty('_latlng')){
            let tooltipName = `(${(layer.editing?._marker?._latlng.lat)?.toFixed(
              2||''
            )},${(layer.editing?._marker?._latlng.lng)?.toFixed(2) || ''})`;
            layer.bindTooltip(tooltipName, {
              className: 'draw-marker',
              permanent: true,
              direction: 'top',
            });
          }
        }
      });
    }
    // store the ref for future access to content
    setEditableFG(reactFGref);
  };

  const handleMarker = (e) => {};
  const onMounted = (e) => {
    drawControlRef.current = e;
  };

  return (
    <div>
      <FeatureGroup
        ref={(featureGroupRef) => {
          onFeatureGroupReady(featureGroupRef);
        }}
      >
        <EditControl
          position="topright"
          onCreated={onCreated}
          onDeleted={onDelete}
          onMounted={onMounted}
          onEdited={onEdited}
          draw={{
            polyline: false,
            rectangle: false,
            circle: false,
            circlemarker: false,
          }}
          // edit={{ edit: false }}
          marker={handleMarker}
        />

        <Circle center={[51.51, -0.06]} radius={200} />
      </FeatureGroup>
    </div>
  );
}

DrawShape.propTypes = {
  handleSelectedRegion: PropTypes.func,
};

export default DrawShape;

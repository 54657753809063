import {MANAGE_CROPS_CONST, MANAGE_CROPS_ADD_CONST, MANAGE_CROPS_UPDATE_CONST,MANAGE_CROPS_DELETE_CONST,MANAGE_CROPS_SEASONS_CONST,MANAGE_CROPS_LIST_BY_ID_CONST }  from './manageCropsConst';

export const manageCropsListRequest = (data) => ({
  type: MANAGE_CROPS_CONST.MANAGE_CROPS_REQUEST,
  payload: data,
});

export const manageCropsListSuccess = (response) => ({
  type: MANAGE_CROPS_CONST.MANAGE_CROPS_SUCCESS,
  payload: response,
});

export const manageCropsListFailed = (error) => ({
  type: MANAGE_CROPS_CONST.MANAGE_CROPS_FAILED,
  payload: error,
});




export const manageCropsListByIdRequest = (data) => ({
  type: MANAGE_CROPS_LIST_BY_ID_CONST.MANAGE_CROPS_LIST_BY_ID_REQUEST,
  payload: data,
});

export const manageCropsListByIdSuccess = (response) => ({
  type: MANAGE_CROPS_LIST_BY_ID_CONST.MANAGE_CROPS_LIST_BY_ID_SUCCESS,
  payload: response,
});

export const manageCropsListByIdFailed = (error) => ({
  type: MANAGE_CROPS_LIST_BY_ID_CONST.MANAGE_CROPS_LIST_BY_ID_FAILED,
  payload: error,
});


export const manageCropsAddRequest = (data) => ({
  type: MANAGE_CROPS_ADD_CONST.MANAGE_CROPS_ADD_REQUEST,
  payload: data,
});

export const manageCropsAddSuccess = (response) => ({
  type: MANAGE_CROPS_ADD_CONST.MANAGE_CROPS_ADD_SUCCESS,
  payload: response,
});

export const manageCropsAddFailed = (error) => ({
  type: MANAGE_CROPS_ADD_CONST.MANAGE_CROPS_ADD_FAILED,
  payload: error,
});




export const manageCropsUpdateRequest = (data) => ({
  type: MANAGE_CROPS_UPDATE_CONST.MANAGE_CROPS_UPDATE_REQUEST,
  payload: data,
});

export const manageCropsUpdateSuccess = (response) => ({
  type: MANAGE_CROPS_UPDATE_CONST.MANAGE_CROPS_UPDATE_SUCCESS,
  payload: response,
});

export const manageCropsUpdateFailed = (error) => ({
  type: MANAGE_CROPS_UPDATE_CONST.MANAGE_CROPS_UPDATE_FAILED,
  payload: error,
});




export const manageCropsDeleteRequest = (data) => ({
  type: MANAGE_CROPS_DELETE_CONST.MANAGE_CROPS_DELETE_REQUEST,
  payload: data,
});

export const manageCropsDeleteSuccess = (response) => ({
  type: MANAGE_CROPS_DELETE_CONST.MANAGE_CROPS_DELETE_SUCCESS,
  payload: response,
});

export const manageCropsDeleteFailed = (error) => ({
  type: MANAGE_CROPS_DELETE_CONST.MANAGE_CROPS_DELETE_FAILED,
  payload: error,
});


export const manageCropsSeasonsRequest = (data) => ({
  type: MANAGE_CROPS_SEASONS_CONST.MANAGE_CROPS_SEASONS_REQUEST,
  payload: data,
});

export const manageCropsSeasonsSuccess = (response) => ({
  type: MANAGE_CROPS_SEASONS_CONST.MANAGE_CROPS_SEASONS_SUCCESS,
  payload: response,
});

export const manageCropsSeasonsFailed = (error) => ({
  type: MANAGE_CROPS_SEASONS_CONST.MANAGE_CROPS_SEASONS_FAILED,
  payload: error,
});
import React from 'react';
import { GeoJSON } from 'react-leaflet';
import {
  GEOJSON_COUNTRY_TOOLTIP_DATA,
  MAP_PREFERENCES_lABELS,
} from '../../../Components/constants';

function GeoJsonMapContainer({ geoJsonData, countryData }) {
  const handleEachFeatureChange = (selectedCountry, locationType, feat, layer) => {
    let selectedCountryData = GEOJSON_COUNTRY_TOOLTIP_DATA?.find(
      item => item.selectedCountry === selectedCountry?.country_name,
    );
    let labelprop = selectedCountryData ? selectedCountryData[locationType] : '';
    if (labelprop && feat.properties[labelprop])
      layer.bindTooltip(feat.properties[labelprop] + '', { sticky: true, direction: 'auto' });
  };

  return (
    <>
      <GeoJSON
        key={MAP_PREFERENCES_lABELS.COUNTRY + geoJsonData[0]?.config?.color}
        style={{ color: geoJsonData[0]?.config?.color, fillColor: '#0000', weight: 1 }}
        data={geoJsonData[0]?.data?.features}
        onEachFeature={(feat, layer) =>
          handleEachFeatureChange(countryData, 'country', feat, layer)
        }
      />

      <GeoJSON
        key={MAP_PREFERENCES_lABELS.STATES + geoJsonData[1]?.config?.color}
        style={{
          color: geoJsonData[1]?.config?.color,
          fillColor: '#0000',
          weight: 1.6,
        }}
        data={geoJsonData[1]?.data?.features}
        onEachFeature={(feat, layer) => {
          handleEachFeatureChange(
            countryData,
            'states_or_provinces',
            feat,
            layer,
          );
        }}
      />
    </>
  );
}

export default GeoJsonMapContainer;

const DROUGHT_CONST = {
   GET_DROUGHT_MAP_REQUEST: 'GET_DROUGHT_MAP_REQUEST',
   GET_DROUGHT_MAP_SUCCESS: 'GET_DROUGHT_MAP_SUCCESS',
   GET_DROUGHT_MAP_FAILED:  'GET_DROUGHT_MAP_FAILED',

   GET_DROUGHT_TIMESERIES_REQUEST: 'GET_DROUGHT_TIMESERIES_REQUEST',
   GET_DROUGHT_TIMESERIES_SUCCESS: 'GET_DROUGHT_TIMESERIES_SUCCESS',
   GET_DROUGHT_TIMESERIES_FAILED: 'GET_DROUGHT_TIMESERIES_FAILED',

   GET_DROUGHT_ALL_SEASON_REQUEST: 'GET_DROUGHT_ALL_SEASON_REQUEST',
   GET_DROUGHT_ALL_SEASON_SUCCESS: 'GET_DROUGHT_ALL_SEASON_SUCCESS',
   GET_DROUGHT_ALL_SEASON_FAILED: 'GET_DROUGHT_ALL_SEASON_FAILED', 

   GET_ALERT_ACTIVE_YEAR_MONTHS_REQUEST: 'GET_ALERT_ACTIVE_YEAR_MONTHS_REQUEST',
   GET_ALERT_ACTIVE_YEAR_MONTHS_SUCCESS: 'GET_ALERT_ACTIVE_YEAR_MONTHS_SUCCESS',
   GET_ALERT_ACTIVE_YEAR_MONTHS_FAILED: 'GET_ALERT_ACTIVE_YEAR_MONTHS_FAILED',

   GET_FINAL_DROUGHT_MAP_REQUEST: 'GET_FINAL_DROUGHT_MAP_REQUEST',
   GET_FINAL_DROUGHT_MAP_SUCCESS: 'GET_FINAL_DROUGHT_MAP_SUCCESS',
   GET_FINAL_DROUGHT_MAP_FAILED: ' GET_FINAL_DROUGHT_MAP_FAILED',

   GET_DSS_THRESHOLD_DATA_REQUEST: 'GET_DSS_THRESHOLD_DATA_REQUEST',
   GET_DSS_THRESHOLD_DATA_SUCCESS: 'GET_DSS_THRESHOLD_DATA_SUCCESS',
   GET_DSS_THRESHOLD_DATA_FAILED: 'GET_DSS_THRESHOLD_DATA_FAILED',

   GET_DSS_INDICES_REQUEST:'GET_DSS_INDICES_REQUEST',
   GET_DSS_INDICES_SUCCESS:'GET_DSS_INDICES_SUCCESS',
   GET_DSS_INDICES_FAILED:'GET_DSS_INDICES_FAILED',

   GET_DROUGHT_PREDICTION_INDICES_MONTHS : 'GET_DROUGHT_PREDICTION_INDICES_MONTHS',
   GET_DROUGHT_PREDICTION_INDICES_MONTHS_SUCCESS: 'GET_DROUGHT_PREDICTION_INDICES_MONTHS_SUCCESS',
   GET_DROUGHT_PREDICTION_INDICES_MONTHS_FAILED: 'GET_DROUGHT_PREDICTION_INDICES_MONTHS_FAILED',
}

export default DROUGHT_CONST;

import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ContingencyPlanFilter from './Filter/index';
import moment from 'moment';
import CustomMarkerIcon from '../../Components/common/CustomIcon.js';
import {
  contingencyPlanRiskRequest,
  contingencyPlanMonsoonRequest,
  contingencyPlanSystemRequest,
  contingencyPlanStageRequest,
  contingencyPlanDroughtIndicesRequest,
  contingencyPlanStrategyRequest,
  contingencyPlanDroughtStageTimePeriodRequest,
  contingencyPlanCateoryRequest,
  contingencyPlanSubCateoryRequest,
  contingencyPlanDistrictProfileRequest,
  contingencyPlanCropTypeRequest,
  contingencyPlanMeasuresDataRequest,
  contingencyDistrictGraphRequest,
  contingencyPlanRiskSuccess,
  contingencyPlanMonsoonSuccess,
  contingencyPlanSystemSuccess,
  contingencyPlanStrategySuccess,
  contingencyPlanDroughtStageTimePeriodSuccess,
  contingencyPlanStageSuccess,
  // contingencyPlanLogsRequest,
  // contingencyPlanLogsSuccess,
} from '../../redux/contingencyPlan/contingencyPlanAction';
import LayerControl from './CustomLayerControl/LayerControl';
import * as ReactDOMServer from 'react-dom/server';
import { FaMapMarkerAlt } from 'react-icons/fa';
import L from 'leaflet';
import _ from 'lodash';
import TransitionModal from './TransitionModal';
import { convertToPascalCase, getUserId } from '../../Components/common/utils';
import {
  LOCATION,
  DEFAULT_COUNTRY,
  INDIA_COORDINATE,
  CONTINGENCY_FILTER_TABS,
  MAP_PREFERENCES_lABELS,
  INDICES_PARAMS,
} from '../../Components/constants';
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  useMap,
  useMapEvents,
  ZoomControl,
  LayersControl,
  LayerGroup,
  Marker,
  Tooltip,
} from 'react-leaflet';
import { Button, Row, Col } from 'reactstrap';
import myConstClass from '../../Constants';
import mapPreferenceIcon from '../../assets/Images/mapPreferenceIcon.svg';
import {
  getCountryRequest,
  getDistrictByStateRequest,
  getDroughtMapRequest,
  getDroughtMapSuccess,
  getMapPreferenceBoundariesSuccess,
  getMapPreferenceRequest,
  getMapPreferenceSuccess,
  getStateByCountryRequest,
  getStateByCountrySuccess,
  getDistrictByStateSuccess,
} from '../../redux/actions';

import { userPermissionsByIdRequest } from '../../redux/user-list/userListAction';
import MapPreferences from '../WeatherForecast/MapPreferences';
import CustomLegend from '../WeatherForecast/CustomLayerControl/CustomLegend';
import DiscreteLegend from '../WeatherForecast/CustomLayerControl/DiscreteLegend';
import 'leaflet.control.opacity/dist/L.Control.Opacity.css';
import 'leaflet.control.opacity';
import { t } from 'react-multi-lang';
import {
  addLayers,
  filterSelectedGeoJsonData,
  getGenericMapPreferencesData,
  mapPreferCreateRef,
  mapPreferenceGeoJsonAPICall,
  removeLayers,
  isLogedIn,
} from '../../Components/common/utils';
import Loader from '../../Components/common/TextLoader';
import { showError } from '../../Components/Notifications';
import { API_BASE_URL, AXIOS_INSTANCE } from '../../redux/api/apiEndPoint';
import { useHistory } from 'react-router-dom';

const ContingencyPlan = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { Overlay } = LayersControl;
  const [map, setMap] = useState(false);
  const [mapPreferences, setMapPreferences] = useState({
    country: { layer: useRef(), color: '', data: '' },
    states_or_provinces: { layer: useRef(), color: '', data: '' },
    districts_or_cities: { layer: useRef(), color: '', data: '' },
    meteorological_locations: { layer: useRef(), color: '', data: '' },
    basins: { layer: useRef(), color: '', data: '' },
    rivers_or_reservoirs: { layer: useRef(), color: '', data: '' },
    talukas: { layer: useRef(), color: '', data: '' },
  });

  const [countryJson, setCountryJson] = useState('');
  const [isHideBar, setBar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [countryList, setCountryList] = useState();
  const [stateList, setStateList] = useState();
  const [districtList, setDistrictList] = useState();
  const [timePeriodList, setTimePeriodList] = useState([]);
  const [selectedCountryOption, setSelectedCountryOption] = useState({});
  const [selectedDropdown, setSelectedDropdown] = useState('country');
  const [checkedIndicesList, setCheckedIndicesList] = useState([]);
  const [riskList, setRiskList] = useState([]);
  const [monsoonList, setMonsoonList] = useState([]);
  const [filterActiveTab, setFilterActiveTab] = useState(CONTINGENCY_FILTER_TABS.MAP);
  const [isMapTabShow, setIsMapTabShow] = useState(true);
  const [getBoundariesData, setGetBoundariesData] = useState([]);
  const [systemList, setSystemList] = useState([]);
  const [stageList, setStageList] = useState([]);
  const [strategyList, setStrategyList] = useState([]);
  const [cropTypeData, setCropTypeData] = useState([]);
  const [droughtIndicesList, setDroughtIndicesList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [districtProfileList, setDistrictProfileList] = useState([]);
  const [districtProfileTable, setDistrictProfileTable] = useState({});
  const [districtProfileView, setDistrictProfileView] = useState();
  const [isContingencyMeasuresDisabled, setIsContingencyMeasuresDisabled] = useState(true);
  const [contingencyMeasuresView, setContingencyMeasuresView] = useState();
  const [contingencyMeasuresTable, setContingencyMeasuresTable] = useState();
  const [dropdownDataLoading, setDropdownDataLoading] = useState({});
  const [districtProfileButtonShow, setDistrictProfileButtonShow] = useState(false);
  const [districtProfileModalShow, setDistrictProfileModalShow] = useState(false);
  const [contingencyMeasuresModalShow, setContingencyMeasuresModalShow] = useState(false);
  const [isOpenMapPreferences, setIsOpenMapPreferences] = useState(false);
  const [isSystemShow, setIsSystemShow] = useState(false);
  const [mapPreferenceGeoJsonloading, setMapPreferenceGeoJsonloading] = useState(false);
  const [selectedParamLegendData, setSelectedParamLegendData] = useState([]);
  const [compareParamLegendData, setCompareParamLegendData] = useState([]);
  const [selectedMapPreferLegendData, setSelectedMapPreferLegendData] = useState([]);
  const [mapPreferencesData, setMapPreferencesData] = useState([]);
  const [geoJsonData, setGeoJsonData] = useState([]);
  const [isOpenMap, setIsOpenMap] = useState(true);
  const [contingencyMeasureClicked, setContingencyMeasureClicked] = useState(false);
  const [hideGeoJsonData, setHideGeoJsonData] = useState(false);
  const [indexLayers, setIndexLayers] = useState([]);
  const [contingencyMeasuresFilterData, setContingencyMeasuresFilterData] = useState({
    breadCrumbsData: '',
    cropTypeData: '',
    selectedCrop: '',
    isCropTypeLoading: '',
  });
  const [breadCrumbsData, setBreadCrumbsData] = useState({
    system: '',
    droughtStage: '',
    timePeriod: '',
  });
  const [isOpenRainfallModal, setIsOpenRainfallModal] = useState(false);
  const [districtRainfallData, setDistrictRainfallData] = useState([]);
  const [mapLayerOpacity, setMapLayerOpacity] = useState({ layer: '' });
  const [geoJsonAllData, setGeoJsonAllData] = useState([]);
  const [geoJsonLayersData, setGeoJsonLayerData] = useState([]);
  const [mapPreferRefs] = useState([]);
  const [opacityControl, setOpacityControl] = useState('');
  const [mapControl, setMapControl] = useState({});
  const [mapData, setMapData] = useState('');
  const mapRef = useRef(null);
  const [layerRef] = useState([]);
  const [tileLayerRef] = useState([]);
  const [mapPrefereTileLayerRef] = useState([]);
  const [precipitationThreshold, setPrecipitationThreshold] = useState(2.5);
  const [defaultDates, setDefaultDates] = useState('');
  const [filterFormData, setFilterFormData] = useState({
    country: { value: '', label: '', id: '' },
    endDate: moment().format('YYYY-MM-DD'),
    startDate: moment(new Date())
      .subtract(30, 'days')
      .format('YYYY-MM-DD'),
  });

  const [searchLocation, setSearchLocation] = useState('');
  const [location, setLocation] = useState('');
  const [formData, setFormData] = useState({
    country: {},
    state: {},
    district: {},
    risk: {},
    monsoon: {},
    system: {},
  });
  const [layerControl, setLayerControl] = useState({
    firstLayer: {},
    secondLayer: {},
  });

  const [cordinateAndZoom, setCordinateAndZoom] = useState({
    cordinates: [23.4073, 79.446],
    locationCordinates: [23.4073, 79.446],
    markerCordinates: [23.4073, 79.446],
    zoom: 4.4,
    searchMarkerCoordinates: '',
  });

  const [mapUrl, setMapUrl] = useState({
    name: '',
    url: '',
    layer: '',
  });
  const [selectedRow, setSelectedRow] = useState();
  const [columnHeader, setColumnHeader] = useState();

  const [editValues, setEditValues] = useState({});

  const [mapPreferUrl, setMapPreferUrl] = useState('');

  const [districtProfileCategoryId, setdistrictProfileCategoryId] = useState('');
  const [userPermissions, setUserPermissions] = useState([]);

  const {
    Location,
    ContingencyPlan,
    boundries,
    DroughtMonitor,
    userPermissionsByIdData,
    contgencyLogsData,
  } = useSelector(state => ({
    Location: state.Location,
    ContingencyPlan: state.ContingencyPlan,
    contgencyLogsData: state?.ContingencyPlan?.contgencyLogsData,
    boundries: state.Map_Preference,
    DroughtMonitor: state.DroughtMonitor,
    userPermissionsByIdData: state.userList.userPermissionsByIdData || null,
  }));
  const userId = getUserId();
  useEffect(() => {
    if (!isLogedIn()) {
      let path = window.location.pathname;
      history.push(`/login?prevPath=${path.substring(1, path.length)}`);
    }
    let search = window.location.search;
    let URLParams = new URLSearchParams(search);
    let state_id = URLParams.get('s_id');
    let district_id = URLParams.get('d_id');
    dispatch(userPermissionsByIdRequest({ id: userId }));
    if (!_.isEmpty(state_id) && !_.isEmpty(district_id)) {
      setSearchLocation({
        state_id,
        district_id,
      });
    } else if (!_.isEmpty(state_id)) {
      setSearchLocation({
        state_id,
      });
    }
    let month = URLParams.get('month');
    let year = URLParams.get('year');
    let days = URLParams.get('days');
    if (!_.isEmpty(month) && !_.isEmpty(year) && !_.isEmpty(days)) {
      let startDate = `${year}-${month}-01`;
      let endDate = `${year}-${month}-${days}`;
      setDefaultDates(prev => {
        return {
          startDate: startDate,
          endDate: endDate,
        };
      });
    }

    dispatch(getCountryRequest({ location_type: LOCATION.Country, parent_id: '' }));
    setIsOpenMap(false);

    dispatch(getDroughtMapSuccess(''));
    setMapData('');
    dispatch(getMapPreferenceSuccess(''));

    return () => {
      dispatch(contingencyPlanRiskSuccess({}));
      let data = removeLegend(selectedParamLegendData);
      let compareData = removeLegend(compareParamLegendData);
      setSelectedParamLegendData(data);
      setCompareParamLegendData(compareData);
      if (selectedParamLegendData?.every(item => item.add === false))
        setSelectedParamLegendData('');
      if (compareParamLegendData?.every(item => item.add === false)) setCompareParamLegendData('');
      dispatch(getMapPreferenceBoundariesSuccess(''));
      dispatch(getDroughtMapSuccess({}));
      dispatch(getStateByCountrySuccess({}));
      dispatch(getDistrictByStateSuccess({}));
      dispatch(contingencyPlanRiskSuccess({}));
      dispatch(contingencyPlanMonsoonSuccess({}));
      dispatch(contingencyPlanSystemSuccess({}));
      dispatch(contingencyPlanStrategySuccess({}));
      dispatch(contingencyPlanDroughtStageTimePeriodSuccess({}));
      dispatch(contingencyPlanStageSuccess({}));
      
    };
  }, []);

  useEffect(() => {
    if (geoJsonLayersData?.length) {
      const countryData123 = geoJsonLayersData?.find(each => each?.config?.name === 'country');
      setCountryJson(countryData123?.data);
    }
  }, [geoJsonLayersData]);

  useEffect(() => {
    if (contgencyLogsData?.data?.length) {
      const data = contgencyLogsData?.data;

      // Step 2: Create a Blob
      const blob = new Blob([data], { type: 'text/plain' });

      // Step 3: Create a URL for the Blob
      const url = URL.createObjectURL(blob);

      // Step 4: Create an anchor element and trigger the download
      const a = document.createElement('a');
      a.href = url;
      const currentDate = moment().format('D/M/YYYY'); // Format the date as 20/5/2024
      a.download = `contigencylogs_${currentDate}.txt`;

      document.body.appendChild(a);
      a.click();

      // Clean up and remove the anchor element
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  }, [contgencyLogsData]);

  // const onHandleLogs = () => {
  //   dispatch(contingencyPlanLogsRequest());
  // };

  useEffect(() => {
    if (!_.isEmpty(defaultDates)) {
      setFilterFormData(prev => {
        return {
          ...prev,
          startDate: defaultDates.startDate,
          endDate: defaultDates.endDate,
        };
      });
      setFormData(prev => {
        return {
          ...prev,
          startDate: defaultDates.startDate,
          endDate: defaultDates.endDate,
        };
      });
    }
  }, [defaultDates]);

  const userPermissionsRef = useRef(true);
  useEffect(() => {
    if (userPermissionsRef.current) {
      userPermissionsRef.current = false;
      return;
    }
    if (userPermissionsByIdData?.data?.result?.length) {
      setUserPermissions(userPermissionsByIdData?.data?.result);
    }
  }, [userPermissionsByIdData]);

  const firstRun = useRef(true);
  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    if (boundries?.mapPreferenceData) {
      setGetBoundariesData(boundries?.mapPreferenceData?.data?.result);
    }
  }, [boundries?.mapPreferenceData]);

  useEffect(() => {
    if (getBoundariesData && getBoundariesData?.length) {
      let API_URL = [];
      mapPreferRefs.current = [];
      mapPrefereTileLayerRef.current = [];
      getBoundariesData?.map(item => {
        if (item?.data && item?.data.length) {
          item.data.map(boundary => {
            if (boundary?.boundary_data_url.length) {
              setGeoJsonData([]);
              setGeoJsonAllData([]);
              let mapPreferKeys = Object.keys(mapPreferences);
              if (mapPreferKeys) {
                mapPreferKeys.map(item => {
                  let selectedLayer = mapPreferences[item];
                  if (selectedLayer?.layer && selectedLayer?.layer?.current) {
                    selectedLayer?.layer?.current?.clearLayers();
                  }
                  selectedLayer.color = '';
                  setMapPreferences(prev => {
                    return {
                      ...prev,
                      [item]: selectedLayer,
                    };
                  });
                });
              }
              setMapPreferenceGeoJsonloading(true);
              API_URL = mapPreferenceGeoJsonAPICall(boundary, API_URL);
            } else {
              mapPreferRefs.current = mapPreferCreateRef(boundary, mapPreferRefs);
            }
          });
        }
      });
      Promise.all(API_URL).then(response => {
        let res = JSON.parse(JSON.stringify(response));
        setMapPreferenceGeoJsonloading(false);
        setGeoJsonLayerData(res);
      });
    }
  }, [getBoundariesData]);

  const removeLegend = data => {
    let legendData = JSON.parse(JSON.stringify(data));
    let updatedData = legendData?.map(item => {
      if (item.add) item.add = false;
      return item;
    });
    return updatedData;
  };

  useEffect(() => {
    if (DroughtMonitor.isGetDroughtMapRequesting) {
      setIsLoading(true);
    }
    if (DroughtMonitor.getDroughtMapData) {
      if (!_.isEmpty(layerControl?.firstLayer)) {
        map?.removeControl(layerControl?.firstLayer);
      }
      if (!_.isEmpty(layerControl?.secondLayer)) {
        map?.removeControl(layerControl?.secondLayer);
      }
      if (!_.isEmpty(mapControl)) {
        map?.removeControl(mapControl);
      }
      setMapControl('');
      setLayerControl({
        firstLayer: {},
        secondLayer: {},
      });
      setSelectedParamLegendData(removeLegend(selectedParamLegendData));
      setCompareParamLegendData(removeLegend(compareParamLegendData));

      setMapUrl({ name: '', url: '' });
      const { response } = DroughtMonitor.getDroughtMapData;
      if (Array.isArray(response?.data)) {
        if (!response?.isDroughtModule) {
          setMapData(response?.data);
        }
      } else {
        const { result } = !response?.isDroughtModule
          ? response?.data
            ? response.data
            : { result: '' }
          : { result: '' };
        setMapData(result);
      }
      setIsLoading(false);
    } else if (DroughtMonitor.getDroughtMapDataError) {
      const {
        data: { response },
      } = DroughtMonitor.getDroughtMapDataError?.response;
      if (response?.data?.code === 400) {
        if (_.isEmpty(response?.data?.errors)) {
          showError(response?.data?.message);
        }
      }
      let errorsObj = response?.data?.errors;
      if (!_.isEmpty(errorsObj)) {
        const values = Object.values(errorsObj);
        showError(values[0]);
      }
      setIsLoading(false);
    } else if (DroughtMonitor.getDroughtMapDataNetworkError) {
      //setIsLoading(false);
    }
  }, [
    DroughtMonitor.getDroughtMapData,
    DroughtMonitor.isGetDroughtMapRequesting,
    DroughtMonitor.getDroughtMapDataError,
  ]);

  useEffect(() => {
    if (!_.isEmpty(formData.district) && !_.isEmpty(formData.risk) && !_.isEmpty(formData.system)) {
      setStageList([]);
      setDroughtIndicesList([]);
      dispatch(
        contingencyPlanStageRequest({
          district_id: formData.district.id,
          risk_id: formData.risk.id,
          system_id: formData.system.id,
        }),
      );
    }
  }, [formData.district, formData.risk, formData.system]);

  useEffect(() => {
    if (!_.isEmpty(formData.measureDroughtStage)) {
      if (formData?.district?.id) {
        dispatch(
          contingencyPlanDroughtStageTimePeriodRequest({
            district_id: formData.district.id,
            risk_id: formData.risk.id,
            system_id: formData.system.id,
            drought_stage_id: formData.measureDroughtStage.id,
          }),
        );
      }
    }
  }, [formData.measureDroughtStage]);

  useEffect(() => {
    if (
      !_.isEmpty(formData.state) &&
      !_.isEmpty(formData.district) &&
      !_.isEmpty(formData.risk) &&
      !_.isEmpty(formData.strategy)
    ) {
      setIsContingencyMeasuresDisabled(false);
      let reqData = {
        district_id: formData?.district?.id,
        risk_id: formData?.risk?.id,
        system_id: formData?.system?.id ? formData?.system?.id : '',
        strategy_id: formData?.strategy?.id,
        drought_stage_id: formData?.measureDroughtStage?.id
          ? formData?.measureDroughtStage?.id
          : '',
        crop_type_name:
          formData?.cropType?.label && formData?.cropType?.label !== 'select all'
            ? formData?.cropType?.label
            : '',
        time_period_id: formData?.timePeriod?.id ? formData?.timePeriod?.id : '',
      };

      if (contingencyMeasureClicked) {
        setContingencyMeasuresTable({ columns: [], rowData: [] });
        let breadCrumbsUpdatedData = _.cloneDeep(breadCrumbsData);
        let cropTypeUpdatedData = _.cloneDeep(cropTypeData);
        let updatedselectedCrop = _.cloneDeep(formData?.cropType);
        let isUpdatedCropTypeLoading = _.cloneDeep(
          ContingencyPlan.isContingencyPlanCropTypeRequesting,
        );
        setContingencyMeasuresFilterData(prev => {
          return {
            ...prev,
            breadCrumbsData: breadCrumbsUpdatedData,
            cropTypeData: cropTypeUpdatedData,
            cropType: updatedselectedCrop,
            isCropTypeLoading: isUpdatedCropTypeLoading,
          };
        });
        dispatch(contingencyPlanMeasuresDataRequest(reqData));
      }
    } else {
      setIsContingencyMeasuresDisabled(true);
    }
  }, [
    formData.state,
    formData.district,
    formData.strategy,
    formData.cropType,
    formData.risk,
    formData.system,
    formData.measureDroughtStage,
    formData.timePeriod,
    contingencyMeasureClicked,
  ]);

  useEffect(() => {
    if (_.isEmpty(formData.state)) {
      delete filterFormData.state;
    } else {
      filterFormData.state = formData.state;
    }
    delete filterFormData.district;
    delete filterFormData.risk;
    delete filterFormData.monsoon;
    delete filterFormData.system;
    delete filterFormData.strategy;
    delete filterFormData.droughtMapStage;
    delete filterFormData.droughtMeasuresStage;
    delete filterFormData.chooseTimePeriod;
    setFilterFormData({ ...filterFormData });
  }, [formData.state]);

  useEffect(() => {
    if (_.isEmpty(formData.district)) {
      delete filterFormData.district;
    } else {
      filterFormData.district = formData.district;
    }
    delete filterFormData.risk;
    delete filterFormData.monsoon;
    delete filterFormData.system;
    delete filterFormData.strategy;
    delete filterFormData.droughtMapStage;
    delete filterFormData.droughtMeasuresStage;
    delete filterFormData.chooseTimePeriod;
    if (formData.startDate) {
      filterFormData.startDate = formData.startDate;
    }
    if (formData.endDate) {
      filterFormData.endDate = formData.endDate;
    }
    setFilterFormData({ ...filterFormData });
  }, [formData.district]);

  useEffect(() => {
    if (ContingencyPlan.isContingencyPlanRiskRequesting) {
      setDropdownDataLoading({ key: 'system', isLoading: true });
    }
    if (ContingencyPlan.contingencyPlanSystemListData) {
      const {
        data: { result },
      } = ContingencyPlan.contingencyPlanSystemListData;
      if (result.length) {
        let a = [];
        result.map(i => {
          return a.push({
            id: i.id,
            name: i.system_name,
          });
        });
        setIsSystemShow(true);
        setSystemList(a);
        setFilterFormData(prev => {
          return {
            ...prev,
            system: { id: a[1]?.id, value: a[1]?.name, label: a[1]?.name },
          };
        });
        setDropdownDataLoading({ key: 'system', isLoading: false });
      } else {
        delete filterFormData.system;
        setFilterFormData({ ...filterFormData });
        setIsSystemShow(false);
        setIsMapTabShow(false);
      }
    }
  }, [
    ContingencyPlan.contingencyPlanSystemListData,
    ContingencyPlan.isContingencyPlanSystemRequesting,
  ]);

  useEffect(() => {
    if (ContingencyPlan.contingencyDistrictGraphData) {
      if (ContingencyPlan.contingencyDistrictGraphData?.data) {
        const { result } = ContingencyPlan.contingencyDistrictGraphData?.data;
        setDistrictRainfallData(result);
      }
    } else if (ContingencyPlan.contingencyDistrictGraphDataError) {
      setDistrictRainfallData([]);
    }
  }, [
    ContingencyPlan.isContingencyDistrictGraphRequesting,
    ContingencyPlan.contingencyDistrictGraphData,
    ContingencyPlan.contingencyDistrictGraphDataError,
  ]);

  useEffect(() => {
    if (ContingencyPlan.isContingencyPlanTimePeriodRequesting) {
      setDropdownDataLoading({ key: 'time_period', isLoading: true });
    }
    if (ContingencyPlan.contingencyPlanTimePeriodData) {
      const {
        data: { result },
      } = ContingencyPlan.contingencyPlanTimePeriodData;
      if (result) {
        let a = [];
        result.map(i => {
          return a.push({
            id: i.id,
            name: i.name,
          });
        });
        setTimePeriodList(a);
        setFilterFormData(prev => {
          return {
            ...prev,
            chooseTimePeriod: { id: a[0]?.id, value: a[0]?.name, label: a[0]?.name },
          };
        });
        setDropdownDataLoading({ key: 'time_period', isLoading: false });
      }
    }
  }, [
    ContingencyPlan.contingencyPlanTimePeriodData,
    ContingencyPlan.isContingencyPlanTimePeriodRequesting,
  ]);

  useEffect(() => {
    if (ContingencyPlan.contingencyPlanCropTypeData) {
      const {
        data: { result },
      } = ContingencyPlan.contingencyPlanCropTypeData;
      if (result) {
        let a = [];
        result.map(i => {
          return a.push({
            id: i.id,
            name: i.name,
            value: i.name,
            label: i.name,
          });
        });
        setCropTypeData(a);
        formData.cropType = { value: '', label: 'select all', id: 'select all' };
        setContingencyMeasureClicked(false);
        setFormData({ ...formData });
      }
    }
  }, [ContingencyPlan.contingencyPlanCropTypeData]);

  useEffect(() => {
    if (ContingencyPlan.isContingencyPlanStageRequesting) {
      setDropdownDataLoading({ key: 'stage', isLoading: true });
    }
    if (ContingencyPlan.contingencyPlanStageListData) {
      const {
        data: { result },
      } = ContingencyPlan.contingencyPlanStageListData;
      if (result?.length) {
        let a = [];
        result.map(i => {
          return a.push({
            id: i.id,
            name: i.name,
          });
        });
        setStageList(a);
        setFilterFormData(prev => {
          return {
            ...prev,
            droughtMapStage: { id: a[0]?.id, value: a[0]?.name, label: a[0]?.name },
            droughtMeasuresStage: { id: a[0]?.id, value: a[0]?.name, label: a[0]?.name },
          };
        });
        setIsMapTabShow(true);
        setDropdownDataLoading({ key: 'stage', isLoading: false });
        setFilterActiveTab(CONTINGENCY_FILTER_TABS.MAP);
      } else {
        setIsMapTabShow(false);
        setFilterActiveTab(CONTINGENCY_FILTER_TABS.MEASURE);
      }
    }
  }, [
    ContingencyPlan.contingencyPlanStageListData,
    ContingencyPlan.isContingencyPlanStageRequesting,
  ]);

  useEffect(() => {
    if (ContingencyPlan.contingencyPlanSubCategoryData) {
      const {
        data: { result },
      } = ContingencyPlan.contingencyPlanSubCategoryData;
      if (result) {
        let a = [];
        result.map(i => {
          return a.push({
            id: i.id,
            name: i.name,
            value: i.name,
            label: i.name,
            slug: i.slug,
          });
        });
        setSubCategoryList(a);
        if (a?.length) handleSubCategoryChange(a[0]);
      }
    }
  }, [ContingencyPlan.contingencyPlanSubCategoryData]);

  useEffect(() => {
    if (ContingencyPlan.isContingencyPlanStrategyRequesting) {
      setDropdownDataLoading({ key: 'strategy', isLoading: true });
    }
    if (ContingencyPlan.contingencyPlanStrategyListData) {
      const {
        data: { result },
      } = ContingencyPlan.contingencyPlanStrategyListData;
      if (result) {
        let a = [];
        result.map(i => {
          return a.push({
            id: i.id,
            name: i.name,
          });
        });
        setStrategyList(a);
        setFilterFormData(prev => {
          return {
            ...prev,
            strategy: { id: a[0]?.id, value: a[0]?.name, label: a[0]?.name },
          };
        });
        setDropdownDataLoading({ key: 'strategy', isLoading: false });
      }
    }
  }, [
    ContingencyPlan.contingencyPlanStrategyListData,
    ContingencyPlan.isContingencyPlanStrategyRequesting,
  ]);

  const renderNestedValues = values => {
    return [
      { key: 'Latitude', value: values.Latitude },
      { key: 'Longitude', value: values.Longitude },
      { key: 'Altitude', value: values.Altitude },
    ];
  };

  useEffect(() => {
    if (ContingencyPlan.contingencyPlanDistrictProfileData) {
      const {
        data: { result, type },
      } = ContingencyPlan.contingencyPlanDistrictProfileData;
      if (result?.length) {
        let list = [];
        if (type === 'list') {
          result.map(i => {
            return list.push({
              id: i.id,
              key: i.key,
              value: typeof i.values === 'object' ? renderNestedValues(i.values) : i.values,
            });
          });
          setDistrictProfileView('list');
          setDistrictProfileList(list);
        } else if (type === 'table') {
          let table = {
            columns: {},
            rowData: [],
          };
          let arr = [];
          let filtered_column_list = {};
          arr = JSON.parse(JSON.stringify(result[0]));
          Object.entries(arr).forEach(([key, value]) => {
            if (key !== 'col_type' && key !== 'district_id' && key !== 'id') {
              filtered_column_list[key] = value;
            }
          });
          table.columns = filtered_column_list;
          result.map(i => {
            return table.rowData.push(i);
          });
          let a = [];

          Object.keys(table.columns).map(i => {
            a.push({
              Header: convertToPascalCase(i),
              accessor: i,
              sticky: 'top',
            });
          });
          table.columns = a;
          setDistrictProfileView('table');
          setDistrictProfileTable(table);
        }
      } else {
        let table = {
          columns: [],
          rowData: [],
        };
        setDistrictProfileTable(table);
      }
    }
  }, [ContingencyPlan.contingencyPlanDistrictProfileData]);

  useEffect(() => {
    if (ContingencyPlan?.contgencyDpEditData) {
      dispatch(
        contingencyPlanDistrictProfileRequest({
          district_id: formData.district.id,
          district_profile_category_id: districtProfileCategoryId?.id,
        }),
      );
    }
  }, [ContingencyPlan?.contgencyDpEditData]);
  // when edit measure is success
  useEffect(() => {
    if (ContingencyPlan?.contgencyMeasureEditData) {
      let reqData = {
        district_id: formData?.district?.id,
        risk_id: formData?.risk?.id,
        system_id: formData?.system?.id ? formData?.system?.id : '',
        strategy_id: formData?.strategy?.id,
        drought_stage_id: formData?.measureDroughtStage?.id
          ? formData?.measureDroughtStage?.id
          : '',
        crop_type_name:
          formData?.cropType?.label && formData?.cropType?.label !== 'select all'
            ? formData?.cropType?.label
            : '',
        time_period_id: formData?.timePeriod?.id ? formData?.timePeriod?.id : '',
      };

      dispatch(contingencyPlanMeasuresDataRequest(reqData));
    }
  }, [ContingencyPlan?.contgencyMeasureEditData]);

  useEffect(() => {
    if (ContingencyPlan.contingencyPlanMeasuresData) {
      const {
        data: { result, type },
      } = ContingencyPlan.contingencyPlanMeasuresData;
      if (result?.length) {
        let list = [];

        if (type === 'list') {
          result.map(i => {
            return list.push({
              id: i.id,
              key: i.key,
              value: typeof i.values === 'object' ? renderNestedValues(i.values) : i.values,
            });
          });
          setContingencyMeasuresView('list');
        } else {
          let table = {
            columns: {},
            rowData: [],
          };
          let arr = [];
          let filtered_column_list = {};
          arr = JSON.parse(JSON.stringify(result[0]));
          Object.entries(arr).forEach(([key, value]) => {
            if (
              key !== 'col_type' &&
              key !== 'district_id' &&
              key !== 'id' &&
              key !== 'risk_id' &&
              key !== 'system_id' &&
              key !== 'crop_type_id' &&
              key !== 'data_type' &&
              key !== 'drought_stage_id' &&
              key !== 'created_date' &&
              key !== 'updated_date' &&
              key !== 'time_period_id'
            ) {
              filtered_column_list[key] = value;
            }
          });
          table.columns = filtered_column_list;
          result.map(i => {
            return table.rowData.push(i);
          });
          let a = [];
          Object.keys(table.columns).map(i => {
            a.push({
              Header: convertToPascalCase(i),
              accessor: i,
              sticky: 'top',
            });
          });
          table.columns = a;
          setContingencyMeasuresView('table');
          setContingencyMeasuresTable(table);
        }
      } else {
        let table = {
          columns: [],
          rowData: [],
        };
        setContingencyMeasuresTable(table);
      }
    }
  }, [ContingencyPlan.contingencyPlanMeasuresData]);

  useEffect(() => {
    if (ContingencyPlan.contingencyPlanDistrictProfileDataError) {
      let table = {
        columns: [],
        rowData: [],
      };
      setDistrictProfileTable(table);
    }
  }, [ContingencyPlan.contingencyPlanDistrictProfileDataError]);

  useEffect(() => {
    if (ContingencyPlan.contingencyPlanDroughtIndicesListData) {
      const {
        data: { result },
      } = ContingencyPlan.contingencyPlanDroughtIndicesListData;
      if (result) {
        let a = [];
        result.map((i, index) => {
          return a.push({
            id: i.id,
            label: i.name,
            checked: false,
            name: i.name,
            api_url: i.api_url,
            disabled: false,
            paramIndex: index,
            min_date: i?.min_date,
            max_date: i?.max_date,
          });
        });
        handleDroughtIndicesList(a);
        setDroughtIndicesList(a);
        setCheckedIndicesList([]);
      }
    }
  }, [ContingencyPlan.contingencyPlanDroughtIndicesListData]);

  useEffect(() => {
    if (Location.stateList) {
      const { data } = Location.stateList;
      if (data?.result?.length) {
        if (selectedDropdown === 'state') {
          let arr = [];
          data?.result.map(i => {
            return arr.push({
              id: i.id,
              name: i.name,
              value: i.name,
              label: i.name,
              region_id: i.country_id,
              latitude: i.latitude,
              longitude: i.longitude,
              zoom_level: i.zoom_level,
            });
          });
          setStateList(arr);
          setDropdownDataLoading({ key: 'state', isLoading: false });
        }
      }
    }
  }, [Location.stateList]);

  useEffect(() => {
    if (
      !_.isEmpty(stateList) &&
      !_.isEmpty(searchLocation?.state_id) &&
      _.isEmpty(formData.state) &&
      _.isEmpty(formData.district)
    ) {
      if (searchLocation?.state_id === 'select') {
        formData.state = {};
        formData.district = {};
        formData.risk = {};
        formData.monsoon = {};
        formData.category = {};
        formData.subCategory = {};
        handleCordinates([INDIA_COORDINATE.lat, INDIA_COORDINATE.long], 5.5);
      } else {
        let selectedState = '';
        if (!_.isEmpty(stateList)) {
          stateList.map(i => {
            if (i.id === searchLocation?.state_id) {
              selectedState = i;
              handleCordinates(
                [
                  i.latitude ? i.latitude : INDIA_COORDINATE.lat,
                  i.longitude ? i.longitude : INDIA_COORDINATE.long,
                ],
                i.zoom_level ? i.zoom_level : 5.5,
              );
            }
          });

          formData.state = selectedState;
          setSelectedDropdown('district');
          setDropdownDataLoading({ key: 'district', isLoading: true });
          setDistrictList([]);
          dispatch(
            getDistrictByStateRequest({
              requestObj: { location_type: LOCATION.District, parent_id: searchLocation?.state_id },
            }),
          );
        }
      }
      setFormData({ ...formData });
    }
  }, [stateList, searchLocation?.state]);

  useEffect(() => {
    if (
      !_.isEmpty(districtList) &&
      !_.isEmpty(searchLocation?.district_id) &&
      _.isEmpty(formData.district) &&
      !_.isEmpty(formData.state)
    ) {
      if (searchLocation?.district_id === 'select') {
        setDistrictProfileButtonShow(false);
        formData.district = {};
        stateList.map(i => {
          if (i.id === formData.state.id) {
            handleCordinates([i.latitude, i.longitude], i.zoom_level);
          }
        });
      } else {
        let selectedDistrict = '';
        setDistrictProfileButtonShow(true);
        dispatch(contingencyPlanCateoryRequest({ district_id: searchLocation?.district_id }));
        if (!_.isEmpty(districtList)) {
          districtList.map(i => {
            if (i.id === searchLocation?.district_id) {
              selectedDistrict = i;
              handleCordinates(
                [
                  i.latitude ? i.latitude : INDIA_COORDINATE.lat,
                  i.longitude ? i.longitude : INDIA_COORDINATE.long,
                ],
                i.zoom_level ? i.zoom_level : 5.5,
              );
            }
          });
          formData.district = selectedDistrict;
          // setIsLoading(true);
          dispatch(contingencyPlanRiskRequest({ district_id: searchLocation?.district_id }));
          dispatch(contingencyPlanMonsoonRequest({ district_id: searchLocation?.district_id }));
          dispatch(contingencyPlanStrategyRequest({ district_id: searchLocation?.district_id }));
          dispatch(contingencyDistrictGraphRequest({ district_id: searchLocation?.district_id }));
        }
      }
      setFormData({ ...formData });
    }
  }, [districtList, searchLocation?.district]);

  useEffect(() => {
    if (Location.districtList) {
      const { data } = Location.districtList;
      if (data?.result?.length) {
        let arr = [];
        data?.result?.map(i => {
          return arr.push({
            id: i.id,
            name: i.name,
            value: i.name,
            label: i.name,
            region_id: i.country_id,
            latitude: i.latitude,
            longitude: i.longitude,
            zoom_level: i.zoom_level,
          });
        });
        setDistrictList(arr);
        setDropdownDataLoading({ key: 'district', isLoading: false });
      }
    }
  }, [Location.districtList]);

  useEffect(() => {
    if (Location.countryList) {
      const { result } = Location.countryList;
      if (result) {
        if (selectedDropdown === 'country') {
          let defaultCountry = result?.find(data => data.id === DEFAULT_COUNTRY.id);
          if (defaultCountry) {
            defaultCountry.label = defaultCountry.name;
            defaultCountry.value = defaultCountry.name;
            setSelectedCountryOption(defaultCountry);
            handleCordinates(
              [defaultCountry.latitude, defaultCountry.longitude],
              defaultCountry.zoom_level,
            );
          }
        }
      }
      if (result?.[0]) {
        if (selectedDropdown === 'country') {
          let arr = [];
          result.map(i => {
            return arr.push({
              id: i.id,
              name: i.name,
              value: i.name,
              label: i.name,
              region_id: i.region_id,
              latitude: i.latitude,
              longitude: i.longitude,
              zoom_level: i.zoom_level,
            });
          });
          setCountryList(arr);
        }
      }
    }
  }, [Location.countryList]);

  useEffect(() => {
    if (ContingencyPlan.isContingencyPlanRiskRequesting) {
      setDropdownDataLoading({ key: 'risk', isLoading: true });
    }
    if (ContingencyPlan.contingencyPlanRiskListData) {
      const {
        data: { result },
      } = ContingencyPlan.contingencyPlanRiskListData;
      if (result) {
        let a = [];
        result.map(i => {
          return a.push({
            id: i.id,
            name: i.col1,
            district_id: i.district_id,
          });
        });
        setRiskList(a);
        setFilterFormData(prev => {
          return {
            ...prev,
            risk: { id: a[0]?.id, value: a[0]?.name, label: a[0]?.name },
          };
        });
        setDropdownDataLoading({ key: 'risk', isLoading: false });
      }
    }
    if (
      ContingencyPlan?.contingencyPlanRiskListData &&
      !ContingencyPlan.isContingencyPlanRiskRequesting
    ) {
      const {
        data: { result },
      } = ContingencyPlan.contingencyPlanRiskListData;
      if (_.isEmpty(result)) {
        showError(t('CONTINGENCY_PLAN.EMPTY_RISK_ERROR_MSG'));
      }
    }
  }, [
    ContingencyPlan.contingencyPlanRiskListData,
    ContingencyPlan.isContingencyPlanRiskRequesting,
  ]);

  useEffect(() => {
    if (ContingencyPlan.contingencyPlanMonsoonListData) {
      setDropdownDataLoading({ key: 'monsoon', isLoading: true });
    }
    if (ContingencyPlan.contingencyPlanMonsoonListData) {
      const {
        data: { result },
      } = ContingencyPlan.contingencyPlanMonsoonListData;
      if (result) {
        let a = [];
        result.map(i => {
          return a.push({
            id: i.id,
            name: i.col1,
            district_id: i.district_id,
          });
        });
        setMonsoonList(a);
        setFilterFormData(prev => {
          return {
            ...prev,
            monsoon: { id: a[0]?.id, value: a[0]?.name, label: a[0]?.name },
          };
        });
        setDropdownDataLoading({ key: 'monsoon', isLoading: false });
      }
    }
  }, [
    ContingencyPlan.contingencyPlanMonsoonListData,
    ContingencyPlan.isContingencyPlanMonsoonRequesting,
  ]);

  useEffect(() => {
    if (ContingencyPlan.contingencyPlanCategoryData) {
      const {
        data: { result },
      } = ContingencyPlan.contingencyPlanCategoryData;
      if (result) {
        let a = [];
        result.map(i => {
          return a.push({
            id: i.id,
            name: i.name,
            value: i.name,
            label: i.name,
          });
        });
        setCategoryList(a);
        if (a?.length) handleCategoryChange(a[0]);
      }
    }
  }, [ContingencyPlan.contingencyPlanCategoryData]);

  useEffect(() => {
    if (selectedCountryOption) {
      let filterInitialValues = {
        country: {
          value: selectedCountryOption?.value,
          label: selectedCountryOption?.label,
          id: selectedCountryOption?.id,
        },
      };
      formData.country = {
        value: selectedCountryOption?.value,
        label: selectedCountryOption?.label,
        id: selectedCountryOption?.id,
      };
      setFormData({ ...formData });
      setFilterFormData(prev => {
        return {
          ...prev,
          country: filterInitialValues.country,
        };
      });
      dispatch(getMapPreferenceRequest({ country_id: selectedCountryOption?.id }));
    }
  }, [selectedCountryOption]);

  // useEffect(()=>{
  //   if(mapUrl?.url){
  //     if(map.hasLayer(mapUrl?.layer)){
  //       mapUrl?.layer?.bringToFront();
  //     }
  //   }
  // },[mapUrl])

  useEffect(() => {
    if (
      (selectedMapPreferLegendData?.length && selectedMapPreferLegendData?.every(i => !i.add)) ||
      !selectedMapPreferLegendData?.length
    ) {
      if (!_.isEmpty(layerControl?.firstLayer) && !_.isEmpty(layerControl?.secondLayer)) {
        let array = [];
        array.push(layerControl?.firstLayer);
        array.push(layerControl?.secondLayer);
        setIndexLayers(array);
      } else if (mapUrl?.url) {
        setMapLayerOpacity({ layer: mapUrl?.layer });
        let array = [];
        array.push(mapUrl?.layer);
        setIndexLayers(array);
      }
    } else if (selectedMapPreferLegendData?.length) {
      if (mapPreferUrl?.url) {
        let array = [];
        array.push(mapPreferUrl?.layer);
        setIndexLayers(array);
        setMapLayerOpacity({ layer: mapPreferUrl?.layer });
      }
    }
  }, [selectedMapPreferLegendData]);

  useEffect(() => {
    if (
      (selectedParamLegendData?.length && selectedParamLegendData?.every(i => !i.add)) ||
      !selectedParamLegendData?.length
    ) {
      if (mapPreferUrl?.url) {
        let array = [];
        array.push(mapPreferUrl?.layer);
        setIndexLayers(array);
        setMapLayerOpacity({ layer: mapPreferUrl?.layer });
      }
    } else if (
      selectedParamLegendData?.length &&
      ((compareParamLegendData?.length && compareParamLegendData?.every(i => !i.add)) ||
        !compareParamLegendData?.length)
    ) {
      if (mapUrl?.url) {
        let array = [];
        array.push(mapUrl?.layer);
        if (indexLayers.every(i => i._url !== mapUrl?.layer?._url)) {
          setIndexLayers(array);
        }
        setMapLayerOpacity({ layer: mapUrl?.layer });
      }
    }
  }, [selectedParamLegendData]);

  useEffect(() => {
    if (
      (compareParamLegendData?.length && compareParamLegendData?.every(i => !i.add)) ||
      !compareParamLegendData?.length
    ) {
      if (mapPreferUrl?.url) {
        let array = [];
        array.push(mapPreferUrl?.layer);
        setIndexLayers(array);
        setMapLayerOpacity({ layer: mapPreferUrl?.layer });
      }
    } else if (compareParamLegendData?.length) {
      if (!_.isEmpty(layerControl?.firstLayer) && !_.isEmpty(layerControl?.secondLayer)) {
        let array = [];
        array.push(layerControl?.firstLayer);
        array.push(layerControl?.secondLayer);
        setIndexLayers(array);
      }
    }
  }, [compareParamLegendData]);

  useEffect(() => {
    if (!_.isEmpty(indexLayers)) {
      addOpacitySlider();
      if (indexLayers?.length) {
        indexLayers.map(l => l.bringToFront());
      }
    }
  }, [indexLayers]);

  // useEffect(()=>{
  //   if(mapPreferUrl?.url){
  //     if(map.hasLayer(mapPreferUrl?.layer)){
  //       mapPreferUrl?.layer?.bringToFront();
  //     }
  //   }
  // },[mapPreferUrl])

  const handleCordinates = (cordinates, zoom) => {
    setCordinateAndZoom(prev => {
      return {
        ...prev,
        locationCordinates: cordinates,
        cordinates,
        zoom,
        searchMarkerCoordinates: '',
      };
    });
  };

  //function to set map reference
  const setMapReference = mapInstance => {
    mapRef.current = mapInstance;
    setMap(mapInstance);
    setHideGeoJsonData(false);
  };

  //useeffect to reset all the map preferences states data when country gets changed
  useEffect(() => {
    if (formData?.country) {
      setMapPreferencesData([]);
    }
  }, [formData?.country]);

  // compare maps drag feature disabling stopped
  // useEffect(() => {
  //   if (!_.isEmpty(mapControl)) {
  //     mapControl?.on('dividermove', map.dragging.disable());
  //   } else if (!_.isEmpty(map)) {
  //     map?.dragging?.enable();
  //   }
  // }, [mapControl]);

  //useeffect for adding drought Indices layers and legend into the map
  useEffect(() => {
    if (!_.isEmpty(mapData)) {
      let currentParamRef = '';
      if (layerRef && layerRef.current && layerRef.current.length) {
        layerRef?.current?.forEach(item => removeLayers(item, mapRef));
      }
      if (layerRef && layerRef.current && layerRef.current?.length)
        currentParamRef = layerRef.current[formData?.indicesList[0]?.paramIndex];
      let map_data = '';

      let opacityControl = '';
      let legendArray = _.cloneDeep(selectedParamLegendData);
      let updatedLegendArray = [];
      let compareLegendArray = [...compareParamLegendData];
      let legendObj = {};
      let compareLegendObj = {};

      if (Array.isArray(mapData)) {
        let data1, data2;

        if (mapData[0]?.index < mapData[1]?.index) {
          data1 = mapData[0];
          data2 = mapData[1];
        } else {
          data1 = mapData[1];
          data2 = mapData[0];
        }

        if (data1?.map_data.code === 400 || data2?.map_data.code === 400) {
          const filteredMapData = mapData.find(i => i?.map_data?.code === 400);
          showError(filteredMapData?.map_data?.message);
          return false;
        }
        compareLegendObj = { ...data1.map_data };
        compareLegendObj.id = data1.name;
        compareLegendObj.add = true;
        compareLegendObj.dataSource = '';
        compareLegendObj.parameter = data1.name;
        compareLegendObj.measure = data1?.map_data.units || '';
        compareLegendObj.fromMapPreferences = false;

        legendObj = { ...data2.map_data };
        legendObj.id = data2.name;
        legendObj.add = true;
        legendObj.dataSource = '';
        legendObj.parameter = data2.name;
        legendObj.measure = data2?.map_data.units || '';
        legendObj.fromMapPreferences = false;

        if (!_.isEmpty(currentParamRef) && !_.isEmpty(currentParamRef?.current)) {
          currentParamRef.current._url = data1.map_data.map_url;
          currentParamRef.current._url1 = data2.map_data.map_url;
          // TODO: need to change looping through layers
          currentParamRef?.current?.eachLayer(layer => {
            layer.setUrl(data2.map_url);
            setMapUrl({
              name: formData?.indicesList[0].name,
              url: data1.map_data.map_url,
              url1: data2.map_data.map_url,
              layer,
            });
          });

          if (currentParamRef?.current?._url1) {
            const firstLayer = L.tileLayer(currentParamRef.current._url);
            firstLayer.addTo(map);
            const secondLayer = L.tileLayer(currentParamRef.current._url1);
            secondLayer.addTo(map);

            let updatedIndexLayer = [...indexLayers];
            updatedIndexLayer.push(firstLayer);
            updatedIndexLayer.push(secondLayer);
            setIndexLayers(updatedIndexLayer);

            const control = L.control.sideBySide(firstLayer, secondLayer);
            setLayerControl({ firstLayer, secondLayer });
            setMapControl(control);
            control.addTo(map);
          }
        }
        compareLegendArray.push(compareLegendObj);
        setCompareParamLegendData(compareLegendArray);
      } else {
        if (mapData?.map_data && !_.isEmpty(mapData.map_data)) {
          map_data = mapData?.map_data;
        }
        if (map_data.code === 400) {
          showError('No record found . Please select different date range');
          return false;
        }
        legendObj = { ...map_data };
        legendObj.id = formData?.indicesList?.length ? formData?.indicesList[0]?.name : '';
        legendObj.add = true;
        legendObj.dataSource = '';
        legendObj.parameter = formData?.indicesList?.length ? formData?.indicesList[0]?.name : '';
        legendObj.measure = map_data?.units || '';
        legendObj.fromMapPreferences = false;

        // TODO: need to change looping through layers
        if (!_.isEmpty(currentParamRef) && !_.isEmpty(currentParamRef?.current)) {
          currentParamRef.current?.eachLayer(layer => {
            if (map_data) {
              layer.setUrl(map_data?.map_url);
              let updatedIndexLayer = [...indexLayers];
              updatedIndexLayer.push(layer);
              setMapUrl({ name: formData?.indicesList[0].name, url: map_data?.map_url, layer });
              setMapLayerOpacity({
                layer,
              });
              setIndexLayers(updatedIndexLayer);
            }
          });
          let previousParamRef = '';
          previousParamRef = currentParamRef;
        }
        if (currentParamRef && currentParamRef.current !== null) {
          addLayers(currentParamRef, mapRef);
        }
      }

      if (selectedParamLegendData.length) {
        updatedLegendArray = legendArray.map((value, i) => {
          if (value.add) {
            value.add = false;
          }
          return value;
        });
      }

      if (map?.opacityControl) {
        map.removeControl(opacityControl);
      }
      updatedLegendArray.push(legendObj);
      setSelectedParamLegendData(updatedLegendArray);
    }
  }, [mapData]);

  useEffect(() => {
    let selectedGeoJsonData = filterSelectedGeoJsonData(geoJsonLayersData, geoJsonData);
    setGeoJsonAllData(selectedGeoJsonData);
  }, [geoJsonLayersData, geoJsonData]);

  //use effect for adding the opacity control slider in map
  // useEffect(() => {
  //   if (!_.isEmpty(mapLayerOpacity) && !_.isEmpty(mapLayerOpacity?.layer) && !_.isEmpty(map) && !_.isEmpty(map?._layers)) {
  //     if (!_.isEmpty(opacityControl)) map.removeControl(opacityControl);
  //     let customOpacityControl = '';
  //     customOpacityControl = L.control.opacity(mapLayerOpacity, {
  //       collapsed: false,
  //       position: 'topright',
  //     });
  //     setOpacityControl(customOpacityControl);
  //     customOpacityControl?.addTo(map);
  //   }
  // }, [mapLayerOpacity]);

  // use effect for setting the initial state that is used in transparency slider whenever parameter overlay gets added
  useEffect(() => {
    if (layerRef?.current?.length && !_.isEmpty(layerRef?.current[0])) {
      const currentParamRef = layerRef?.current[0];
      if (mapUrl?.url && tileLayerRef.current?._url) {
        if (compareParamLegendData.some(item => item.add === true)) {
          currentParamRef.current._url = mapUrl.url;
          currentParamRef.current._url1 = mapUrl.url1;
          if (currentParamRef.current._url1) {
            const firstLayer = L.tileLayer(currentParamRef.current._url);
            firstLayer.addTo(map);
            const secondLayer = L.tileLayer(currentParamRef.current._url1);
            secondLayer.addTo(map);

            const control = L.control.sideBySide(firstLayer, secondLayer);
            setLayerControl({ firstLayer, secondLayer });
            setMapControl(control);
            control.addTo(map);
          }
        } else if (selectedParamLegendData.some(item => item.add === true)) {
          currentParamRef?.current?.eachLayer(mapLayer => {
            mapLayer.setUrl(mapUrl.url);
            setMapLayerOpacity({ layer: mapLayer });
          });
          if (currentParamRef && currentParamRef.current !== null) {
            addLayers(currentParamRef, mapRef);
          }
        }
      }
      if (!_.isEmpty(currentParamRef) && !_.isEmpty(currentParamRef?.current)) {
        currentParamRef?.current?.eachLayer(layer => {
          setMapLayerOpacity({ layer });
        });
      }
    }
  }, [layerRef?.current, isOpenMap, tileLayerRef.current]);

  useEffect(() => {
    if (mapPreferencesData?.length) {
      let MapPrefereArr = [];
      mapPreferencesData?.map(item => {
        let legendItem = JSON.parse(JSON.stringify(item.result));
        legendItem.parameter =
          item.slug === MAP_PREFERENCES_lABELS.JRC
            ? t('WEATHER_FORECAST.MAP_PREFERENCES.WATER_OCCURRENCE')
            : item.slug === MAP_PREFERENCES_lABELS.MOD44
            ? t('WEATHER_FORECAST.MAP_PREFERENCES.PERMANENT_WATER')
            : item?.legendName;
        legendItem.dataSource = item?.category;
        legendItem.add = true;
        legendItem.measure = legendItem.units;
        legendItem.id = item?.dataSource;
        legendItem.legendId = item.id;
        legendItem.fromMapPreferences = true;
        if (selectedMapPreferLegendData?.length) {
          let isDuplicate = selectedMapPreferLegendData?.find(
            x => x.legendId === item.id && item.add === true,
          );
          if (_.isEmpty(isDuplicate)) {
            MapPrefereArr.push(legendItem);
          }
        } else {
          MapPrefereArr.push(legendItem);
        }
      });
      setSelectedMapPreferLegendData(MapPrefereArr);
    }
  }, [mapPreferencesData]);

  useEffect(() => {
    if (geoJsonAllData?.length && !hideGeoJsonData) {
      geoJsonAllData.map(item => {
        let mapGeojsonItem = Object.keys(mapPreferences).find(x => x === item.config.name);
        let selectedLayer = mapPreferences[mapGeojsonItem];
        if (
          selectedLayer?.layer &&
          selectedLayer?.layer?.current &&
          !_.isEmpty(formData?.district)
        ) {
          let data = JSON.parse(JSON.stringify(item.data));
          if (
            (item.config.name === MAP_PREFERENCES_lABELS.TALUKAS ||
              item.config.name === MAP_PREFERENCES_lABELS.DISTRICTS) &&
            !_.isEmpty(formData?.district)
          ) {
            data.features = item.data?.features.filter(item => {
              if (
                item?.properties?.dtname === formData.district.value ||
                item?.properties?.NAME === formData.district.value
              ) {
                selectedLayer?.layer?.current?.clearLayers();
                return item;
              }
            });
            selectedLayer.layer.current.addData(data);
          }
        } else if (_.isEmpty(formData?.district)) {
          selectedLayer?.layer?.current?.clearLayers();
        }
        selectedLayer.color = item.config.color;
        setMapPreferences(prev => {
          return {
            ...prev,
            [mapGeojsonItem]: selectedLayer,
          };
        });
      });
      // setIsLoading(false);
    }
  }, [geoJsonAllData, hideGeoJsonData]);

  useEffect(() => {
    if (geoJsonAllData?.length && !hideGeoJsonData) {
      geoJsonAllData.map(item => {
        let mapGeojsonItem = Object.keys(mapPreferences).find(x => x === item.config.name);
        let selectedLayer = mapPreferences[mapGeojsonItem];
        if (
          selectedLayer?.layer &&
          selectedLayer?.layer?.current &&
          item.fromMapPreferences &&
          ((item.config.name !== MAP_PREFERENCES_lABELS.TALUKAS &&
            item.config.name !== MAP_PREFERENCES_lABELS.DISTRICTS) ||
            (_.isEmpty(formData?.district) &&
              (item.config.name === MAP_PREFERENCES_lABELS.TALUKAS ||
                item.config.name === MAP_PREFERENCES_lABELS.DISTRICTS)))
        ) {
          let data = JSON.parse(JSON.stringify(item.data));
          selectedLayer?.layer?.current?.clearLayers();
          selectedLayer.layer.current.addData(data);
          selectedLayer.color = item.config.color;
          setMapPreferences(prev => {
            return {
              ...prev,
              [mapGeojsonItem]: selectedLayer,
            };
          });
        }
      });
      // setIsLoading(false);
    }
  }, [geoJsonAllData, hideGeoJsonData]);

  useEffect(() => {
    if (layerRef && layerRef.current && layerRef.current.length) {
      layerRef?.current?.forEach(item => removeLayers(item, mapRef));
    }
    if (!_.isEmpty(layerControl?.firstLayer)) {
      map?.removeControl(layerControl?.firstLayer);
    }
    if (!_.isEmpty(layerControl?.secondLayer)) {
      map?.removeControl(layerControl?.secondLayer);
    }
    if (!_.isEmpty(mapControl)) {
      map?.removeControl(mapControl);
    }
    setMapControl('');
    setLayerControl({
      firstLayer: {},
      secondLayer: {},
    });

    setSelectedParamLegendData(removeLegend(selectedParamLegendData));
    setCompareParamLegendData(removeLegend(compareParamLegendData));
  }, [
    formData.country,
    formData.state,
    formData.district,
    formData.mapDroughtStage,
    formData.risk,
    formData.system,
  ]);

  useEffect(() => {
    if (
      (selectedMapPreferLegendData?.length && selectedMapPreferLegendData?.every(i => !i.add)) ||
      !selectedMapPreferLegendData?.length
    ) {
      if (mapUrl?.url) {
        setMapLayerOpacity({ layer: mapUrl?.layer });
      }
    } else if (selectedMapPreferLegendData?.length) {
      if (mapPreferUrl?.url) setMapLayerOpacity({ layer: mapPreferUrl?.layer });
    }
  }, [selectedMapPreferLegendData]);

  useEffect(() => {
    if (
      (selectedParamLegendData?.length && selectedParamLegendData?.every(i => !i.add)) ||
      !selectedParamLegendData?.length
    ) {
      if (mapPreferUrl?.url) {
        setMapLayerOpacity({ layer: mapPreferUrl?.layer });
      }
    } else if (selectedParamLegendData?.length) {
      if (mapUrl?.url) setMapLayerOpacity({ layer: mapUrl?.layer });
    }
  }, [selectedParamLegendData]);

  useEffect(() => {
    if (mapPreferencesData?.length) {
      if (mapPreferRefs?.current?.length) {
        mapPreferencesData?.map(item => {
          let selectedMapPreferences = mapPreferRefs.current.find(
            x => x.current.boundary_id === item.id,
          );
          let currentParamRef = selectedMapPreferences.current?.layer;
          currentParamRef?.current?.eachLayer(mapLayer => {
            mapLayer.setUrl(item?.result?.map_url);
            let updatedIndexLayer = [...indexLayers];
            updatedIndexLayer.push(mapLayer);
            setIndexLayers(updatedIndexLayer);
            setMapPreferUrl({
              name: item?.dataSource,
              url: item?.result?.map_url,
              layer: mapLayer,
            });
            setMapLayerOpacity({ layer: mapLayer });
          });
          if (currentParamRef && currentParamRef?.current) {
            addLayers(currentParamRef, mapRef);
          }
        });
      }
    }
  }, [mapPreferencesData, mapPreferRefs.current, mapPrefereTileLayerRef.current]);

  function ChangeView({ center, zoom }) {
    const mapEvents = useMapEvents({
      zoomend: () => {
        setCordinateAndZoom(prev => {
          return {
            ...prev,
            zoom: mapEvents.getZoom(),
            cordinates: mapEvents.getCenter(),
          };
        });
      },
      dragend: () => {
        setCordinateAndZoom(prev => {
          return {
            ...prev,
            zoom: mapEvents.getZoom(),
            cordinates: mapEvents.getCenter(),
          };
        });
      },
    });

    const map = useMap();
    setInterval(function() {
      if (!_.isEmpty(map) && !_.isEmpty(map?._layers)) map.invalidateSize();
    }, 500);
    map.setView(center, zoom);
    return null;
  }

  const handleCountry = value => {
    if (value.id === 'select') {
      formData.country = {};
      setFormData({ ...formData });
    } else {
      setSelectedDropdown('state');
      formData.country = value;
      setStateList([]);
      dispatch(
        getStateByCountryRequest({
          requestObj: { location_type: LOCATION.State, parent_id: value.id },
        }),
      );
      setDropdownDataLoading({ key: 'state', isLoading: true });
    }
  };

  // add layer opacity slider to the map
  function addOpacitySlider() {
    // if (!window.map) console.log('not ready');
    if (indexLayers?.length) {
      L.Control.OpacitySlider = L.Control.extend({
        sliding: false,
        updateLayerOpacity: function(opacity) {
          indexLayers.forEach((layer, i) => {
            layer.setOpacity(opacity / 100);
          });
        },
        onAdd: function(map) {
          if (opacityControl) {
            map?.removeControl(opacityControl);
          }
          var container = L.DomUtil.create('div', 'opacityContainer');
          container.innerHTML =
            '<div id=layer-opacity-control><input type="range" min="1" max="100" value="100" defaultValue="100" class="opacityslider"></div>';
          if (!L.Browser.touch) {
            L.DomEvent.disableClickPropagation(container);
            L.DomEvent.on(container, 'mousewheel', L?.DomEvent?.stopPropagation);
          } else {
            L.DomEvent.on(container, 'mousedrag click', L?.DomEvent?.stopPropagation);
          }
          let slider = container.querySelector('.opacityslider');
          slider.onclick = e => {
            this.updateLayerOpacity(e.target.value);
          };
          slider.onmousedown = e => {
            this.sliding = true;
            this.updateLayerOpacity(e.target.value);
          };
          slider.onmousemove = e => {
            e?.stopPropagation();
            if (this.sliding) this.updateLayerOpacity(e.target.value);
          };
          slider.onmouseup = e => {
            this.sliding = false;
          };
          return container;
        },
      });
      L.control.opacitySlider = function(opts) {
        return new L.Control.OpacitySlider(opts);
      };
      let customOpacityControl = L.control.opacitySlider({ position: 'topright' });
      customOpacityControl.addTo(map);
      setOpacityControl(customOpacityControl);
    }
  }

  const handleState = value => {
    setCropTypeData([]);
    setDistrictList([]);
    setIsSystemShow(false);
    setRiskList([]);
    setMonsoonList([]);
    setStageList([]);
    setDroughtIndicesList([]);
    setTimePeriodList([]);
    setStrategyList([]);
    setSystemList([]);
    setDistrictProfileTable({});
    setDistrictProfileList([]);
    setDistrictProfileModalShow(false);
    setContingencyMeasuresModalShow(false);
    setDistrictProfileButtonShow(false);
    setSearchLocation({ state_id: '', district_id: '' });
    setCheckedIndicesList([]);
    if (value.id === 'select') {
      formData.state = {};
      formData.district = {};
      formData.risk = {};
      formData.monsoon = {};
      formData.category = {};
      formData.subCategory = {};
      handleCordinates([INDIA_COORDINATE.lat, INDIA_COORDINATE.long], 5.5);
    } else {
      if (!_.isEmpty(stateList)) {
        stateList.map(i => {
          if (i.id === value.id) {
            handleCordinates(
              [
                i.latitude ? i.latitude : INDIA_COORDINATE.lat,
                i.longitude ? i.longitude : INDIA_COORDINATE.long,
              ],
              i.zoom_level ? i.zoom_level : 5.5,
            );
          }
        });

        formData.state = value;
        formData.district = {};
        formData.risk = {};
        formData.monsoon = {};
        formData.mapDroughtStage = {};
        formData.measureDroughtStage = {};
        formData.indicesList = [];
        formData.strategy = {};
        formData.timePeriod = {};
        delete formData.category;
        delete formData.subCategory;
        setSelectedDropdown('district');
        setDropdownDataLoading({ key: 'district', isLoading: true });
        setDistrictList([]);
        dispatch(
          getDistrictByStateRequest({
            requestObj: { location_type: LOCATION.District, parent_id: value.id },
          }),
        );
      }
    }
    setFormData({ ...formData });
    setContingencyMeasureClicked(false);
    breadCrumbsData.system = '';
    breadCrumbsData.timePeriod = '';
    breadCrumbsData.droughtStage = '';
    setBreadCrumbsData({ ...breadCrumbsData });
  };

  const handleDistrict = value => {
    setRiskList([]);
    setMonsoonList([]);
    setSystemList([]);
    setStageList([]);
    setDroughtIndicesList([]);
    setTimePeriodList([]);
    setStrategyList([]);
    setDistrictProfileList([]);
    setDistrictProfileTable({});
    setContingencyMeasuresModalShow(false);
    setDistrictProfileModalShow(false);
    setIsSystemShow(false);
    setCropTypeData([]);
    setCheckedIndicesList([]);
    if (_.isEmpty(searchLocation?.state_id)) {
      setSearchLocation({ state_id: '', district_id: '' });
    }
    if (value.id === 'select') {
      setDistrictProfileButtonShow(false);
      formData.district = {};
      stateList.map(i => {
        if (i.id === formData.state.id) {
          handleCordinates([i.latitude, i.longitude], i.zoom_level);
        }
      });
    } else {
      // setIsLoading(true);
      setDistrictProfileButtonShow(true);
      dispatch(contingencyPlanCateoryRequest({ district_id: value.id }));
      if (!_.isEmpty(districtList)) {
        districtList.map(i => {
          if (i.id === value.id) {
            handleCordinates(
              [
                i.latitude ? i.latitude : INDIA_COORDINATE.lat,
                i.longitude ? i.longitude : INDIA_COORDINATE.long,
              ],
              i.zoom_level ? i.zoom_level : 5.5,
            );
          }
        });
        formData.district = value;
        dispatch(contingencyPlanRiskRequest({ district_id: value.id }));
        dispatch(contingencyPlanMonsoonRequest({ district_id: value.id }));
        dispatch(contingencyPlanStrategyRequest({ district_id: value.id }));
        dispatch(contingencyDistrictGraphRequest({ district_id: value.id }));
      }
    }
    formData.risk = {};
    formData.monsoon = {};
    formData.mapDroughtStage = {};
    formData.measureDroughtStage = {};
    formData.indicesList = [];
    formData.strategy = {};
    formData.timePeriod = {};
    delete formData.cropType;
    delete formData.category;
    delete formData.subCategory;
    setFormData({ ...formData });
    breadCrumbsData.system = '';
    breadCrumbsData.timePeriod = '';
    breadCrumbsData.droughtStage = '';
    setContingencyMeasureClicked(false);
    setBreadCrumbsData({ ...breadCrumbsData });
  };

  const handleRisk = value => {
    formData.risk = value;
    formData.system = {};
    formData.measureDroughtStage = {};
    formData.timePeriod = {};
    setFormData({ ...formData });
    filterFormData.risk = value;
    setFilterFormData({ ...filterFormData });
    breadCrumbsData.system = '';
    breadCrumbsData.droughtStage = '';
    breadCrumbsData.timePeriod = '';
    setContingencyMeasureClicked(false);
    setFilterActiveTab(CONTINGENCY_FILTER_TABS.MEASURE);
    setBreadCrumbsData({ ...breadCrumbsData });
    setSystemList([]);
    setStageList([]);
    setTimePeriodList([]);
    dispatch(
      contingencyPlanSystemRequest({ district_id: formData.district.id, risk_id: value.id }),
    );
  };

  const handleMonsoon = value => {
    formData.monsoon = value;
    setFormData({ ...formData });
  };

  const handleSystem = value => {
    breadCrumbsData.droughtStage = '';
    breadCrumbsData.timePeriod = '';
    formData.system = value;
    formData.measureDroughtStage = {};
    formData.timePeriod = {};
    delete formData.cropType;
    setFormData({ ...formData });
    setContingencyMeasureClicked(false);
    setTimePeriodList([]);
    setStageList([]);
    breadCrumbsData.system = value.label;
    setBreadCrumbsData({ ...breadCrumbsData });
  };

  const handleMapDrought = value => {
    formData.mapDroughtStage = value;
    dispatch(
      contingencyPlanDroughtIndicesRequest({
        district_id: formData.district.id,
        risk_id: formData.risk.id,
        drought_stage_id: value.id,
      }),
    );
    setFormData({ ...formData });
  };

  const handleMeasureDrought = value => {
    breadCrumbsData.droughtStage = value.label;
    breadCrumbsData.timePeriod = '';
    setBreadCrumbsData({ ...breadCrumbsData });
    formData.measureDroughtStage = value;
    formData.timePeriod = {};
    setFormData({ ...formData });
    setContingencyMeasureClicked(false);
    filterFormData.droughtMeasuresStage = value;
    delete filterFormData.chooseTimePeriod;
  };

  //function to handle map preferences model toggling
  const handleMapModel = () => {
    setIsOpenMapPreferences(!isOpenMapPreferences);
  };

  const getMapPreferencesData = mapPreferencesLayerData => {
    setIndexLayers([]);
    let { arr, paramArr } = getGenericMapPreferencesData(
      mapPreferRefs,
      mapPreferencesData,
      mapRef,
      mapPreferencesLayerData,
      selectedMapPreferLegendData,
    );
    setSelectedMapPreferLegendData(paramArr);
    setMapPreferencesData(arr);
  };

  const getMapPreferencesGeojsonData = geoJsonList => {
    let data = JSON.parse(JSON.stringify(geoJsonList));
    let mapPreferKeys = Object.keys(mapPreferences);
    if (mapPreferKeys) {
      mapPreferKeys.map(item => {
        let selectedLayer = mapPreferences[item];
        if (selectedLayer?.layer && selectedLayer?.layer?.current) {
          selectedLayer?.layer?.current?.clearLayers();
          selectedLayer.color = '';
          setMapPreferences(prev => {
            return {
              ...prev,
              [item]: selectedLayer,
            };
          });
        }
      });
    }
    setGeoJsonData(data);
  };

  const handleStrategy = value => {
    setCropTypeData([]);
    delete formData.cropType;
    formData.strategy = value;
    setFormData({ ...formData });
    setContingencyMeasureClicked(false);
    dispatch(
      contingencyPlanCropTypeRequest({
        district_id: formData.district.id,
        strategy_id: formData.strategy.id,
        drought_stage_id: formData.measureDroughtStage?.id,
        risk_id: formData.risk?.id,
        system_id: formData.system?.id,
        time_period_id: formData.timePeriod?.id,
      }),
    );
  };

  const handleActiveFilterTab = value => {
    setFilterActiveTab(value);
  };

  const handleStartDate = value => {
    formData.startDate = value;
    setFormData({ ...formData });
  };

  const handleEndDate = value => {
    formData.endDate = value;
    setFormData({ ...formData });
  };
  useEffect(() => {
    let updatedDroughtList = droughtIndicesList?.map(i => {
      if (checkedIndicesList?.length === 2) {
        if (!i.checked) i.disabled = true;
      } else i.disabled = false;
      return i;
    });
    setDroughtIndicesList(updatedDroughtList);
  }, [checkedIndicesList]);

  useEffect(() => {
    if (
      ContingencyPlan.isContingencyPlanMeasuresDataRequesting ||
      ContingencyPlan.isContingencyPlanDistrictProfileRequesting ||
      districtProfileModalShow
    ) {
      setSelectedRow();
      setEditValues({});
    }
  }, [
    ContingencyPlan.isContingencyPlanMeasuresDataRequesting,
    ContingencyPlan.isContingencyPlanDistrictProfileRequesting,
    districtProfileModalShow,
  ]);

  const handleCategoryChange = value => {
    formData.category = value;
    formData.subCategoryList = [];
    formData.subCategory = {};
    setFormData({ ...formData });
    setDistrictProfileList([]);
    setDistrictProfileTable([]);
    dispatch(
      contingencyPlanSubCateoryRequest({
        parent_id: value.id,
        district_id: formData.district.id,
      }),
    );
  };

  useEffect(() => {
    if (checkedIndicesList.length === 2) {
      if (compareParamLegendData?.length && compareParamLegendData?.some(i => i.add)) {
        const leafletEle = document.getElementsByClassName('left-legend-style');

        if (leafletEle && leafletEle[0]) {
          leafletEle[0].classList.add('left-legend-pos-st');
        }
      }
      if (
        selectedMapPreferLegendData?.length &&
        selectedMapPreferLegendData?.some(i => i.add) &&
        compareParamLegendData?.length &&
        compareParamLegendData?.some(i => i.add) &&
        compareParamLegendData?.every(i => !i.descret_legend)
      ) {
        const discreteLeafletEle = document.getElementsByClassName('left-discrete-legend-style');
        if (discreteLeafletEle && discreteLeafletEle[0]) {
          discreteLeafletEle[0].classList.add('left-discrete-legend-pos-st');
        }
      } else if (
        selectedMapPreferLegendData?.length &&
        selectedMapPreferLegendData?.every(i => !i.add)
      ) {
        const leafletEle = document.getElementsByClassName('left-legend-style');
        const discreteLeafletEle = document.getElementsByClassName('left-discrete-legend-style');
        if (leafletEle && leafletEle[0]) {
          leafletEle[0].classList.remove('left-legend-pos-st');
        }
        if (discreteLeafletEle && discreteLeafletEle[0]) {
          discreteLeafletEle[0].classList.remove('left-discrete-legend-pos-st');
        }
      }
    } else if (compareParamLegendData?.every(i => !i.add) && checkedIndicesList.length === 1) {
      const leafletEle = document.getElementsByClassName('left-legend-style');
      const discreteLeafletEle = document.getElementsByClassName('left-discrete-legend-style');
      if (leafletEle && leafletEle[0]) {
        leafletEle[0].classList.remove('left-legend-pos-st');
      }
      if (discreteLeafletEle && discreteLeafletEle[0]) {
        discreteLeafletEle[0].classList.remove('left-discrete-legend-pos-st');
      }
    }
  }, [compareParamLegendData, selectedMapPreferLegendData, checkedIndicesList]);

  const handleSubCategoryChange = value => {
    setdistrictProfileCategoryId(value);
    formData.subCategory = value;
    setFormData({ ...formData });
    setDistrictProfileList([]);
    setDistrictProfileTable([]);
    if (formData.district.id) {
      dispatch(
        contingencyPlanDistrictProfileRequest({
          district_id: formData.district.id,
          district_profile_category_id: value.id,
        }),
      );
    }
  };

  const handleDistrictProfileModal = value => {
    setDistrictProfileModalShow(value);
    setHideGeoJsonData(true);
  };

  const handleRainfallModalToggle = () => {
    setIsOpenRainfallModal(!isOpenRainfallModal);
  };

  const handleContingencyMeasuresModal = () => {
    setDistrictProfileModalShow(false);
    setContingencyMeasuresModalShow(true);
    setContingencyMeasureClicked(true);
    setHideGeoJsonData(true);
  };

  const handleTimePeriodChange = value => {
    formData.timePeriod = value;
    setFormData({ ...formData });

    setContingencyMeasureClicked(false);
    breadCrumbsData.timePeriod = value.label;
    setBreadCrumbsData({ ...breadCrumbsData });
    dispatch(
      contingencyPlanCropTypeRequest({
        district_id: formData?.district?.id,
        strategy_id: formData?.strategy?.id,
        drought_stage_id: formData?.measureDroughtStage?.id,
        risk_id: formData?.risk?.id,
        system_id: formData?.system?.id,
        time_period_id: formData?.timePeriod?.id,
      }),
    );
  };

  const handleDistrictProfileCloseModal = () => {
    setDistrictProfileModalShow(false);
    if (formData?.district?.id !== 'select')
      if (isMapTabShow === true) {
        // setIsLoading(true);
        setFilterActiveTab(CONTINGENCY_FILTER_TABS.MAP);
      } else {
        setFilterActiveTab(CONTINGENCY_FILTER_TABS.MEASURE);
      }
    setIsOpenMap(true);
  };

  const handleContingencyMeasuresCloseModal = () => {
    setContingencyMeasuresModalShow(false);
    if (formData?.district?.id !== 'select')
      if (isMapTabShow === true) {
        // setIsLoading(true);
        setFilterActiveTab(CONTINGENCY_FILTER_TABS.MAP);
      } else {
        setFilterActiveTab(CONTINGENCY_FILTER_TABS.MEASURE);
      }
    setIsOpenMap(true);
  };

  const handleCropTypeChange = value => {
    formData.cropType = value;
    setFormData({ ...formData });
    setContingencyMeasureClicked(true);
    setContingencyMeasuresFilterData(prev => {
      return {
        ...prev,
        cropType: value,
      };
    });
  };

  const handleDroughtIndicesList = list => {
    let arr = JSON.parse(JSON.stringify(list));
    if (layerRef && layerRef.current && layerRef.current.length) {
      layerRef?.current?.map(item => removeLayers(item, mapRef));
    }
    setSelectedParamLegendData(removeLegend(selectedParamLegendData));
    setCompareParamLegendData(removeLegend(compareParamLegendData));

    if (!_.isEmpty(layerControl?.firstLayer)) {
      map?.removeControl(layerControl?.firstLayer);
    }
    if (!_.isEmpty(layerControl?.secondLayer)) {
      map?.removeControl(layerControl?.secondLayer);
    }
    if (!_.isEmpty(mapControl)) {
      map?.removeControl(mapControl);
    }
    setMapControl('');
    setLayerControl({
      firstLayer: {},
      secondLayer: {},
    });

    layerRef.current = [];
    tileLayerRef.current = [];
    arr?.map(item => {
      const ref = React.createRef();
      layerRef.current.push(ref);
    });
  };

  const handleSelectedDroughtIndices = (value, bool_value) => {
    let arr = JSON.parse(JSON.stringify(checkedIndicesList));
    let droughtArr = droughtIndicesList.map((i, index) => {
      if (i.id === value.id) {
        if (checkedIndicesList.some(x => x.id === i.id)) {
          i.checked = false;
          arr = arr.filter(item => item.id !== i.id);
        } else {
          i.checked = true;
          arr.push(i);
        }
      }
      return i;
    });
    formData.indicesList = arr;
    setFormData({ ...formData });
    setCheckedIndicesList(arr);
    setDroughtIndicesList(droughtArr);
  };

  const handlePrecipitationThreshold = precipitationThreshold => {
    if (precipitationThreshold) setPrecipitationThreshold(precipitationThreshold);
  };

  const handleEachFeatureChange = (labelprop, feat, layer) => {
    if (labelprop && feat.properties[labelprop])
      layer.bindTooltip(feat.properties[labelprop] + '', { sticky: true, direction: 'auto' });

    /** TODO: alternative solution */

    // if(!_.isEmpty(layer) && !_.isEmpty(feat?.properties)){
    //   layer?.on({
    //     'mousemove': (e) => {
    //       const name = e.target.feature.properties['MJ_BASIN__'] ? e.target.feature.properties['MJ_BASIN__'] : '';
    //       layer?.bindTooltip(name)
    //       layer?.openTooltip(e?.latlng);
    //     },
    //     // 'mouseout': () => {
    //     //   layer?.unbindTooltip();
    //     //   layer?.closeTooltip();
    //     // },
    //   });
    // }
  };

  const iconHtmlString = ReactDOMServer.renderToString(
    <CustomMarkerIcon Icon={FaMapMarkerAlt} className="custom-hydrostation-cons" />,
  );

  const myIcon = L.divIcon({
    iconAnchor: [0, 0],
    className: 'custom-div-icon',
    html: iconHtmlString,
  });

  const renderParamLayer = indicesList => {
    if (indicesList && indicesList?.length) {
      return indicesList?.map((item, index) => {
        return (
          <>
            {/* TODO: need to create separate component */}
            <Overlay name={item.name} key={index}>
              <LayerGroup ref={layerRef.current[item?.paramIndex]}>
                <TileLayer
                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url={mapUrl.url}
                  id={item.name}
                  ref={tileLayerRef}
                />
              </LayerGroup>
            </Overlay>
          </>
        );
      });
    }
  };

  const handleApplyLayers = applyLayers => {
    setContingencyMeasuresModalShow(false);
    setDistrictProfileModalShow(false);
    setIndexLayers([]);
    let req_data = {
      country_name: formData?.country?.value,
      state_name: formData?.state?.value,
      district_name: formData?.district?.value,
      start_date: formData?.startDate,
      end_date: formData?.endDate,
      temporal_aggregation: 'mean',
    };
    if (checkedIndicesList?.length === 1) {
      setIsLoading(true);
      const isDroughtModule = false;
      if (formData?.indicesList[0]?.id === INDICES_PARAMS.CONTINGENCY_DRY_SPELL.id) {
        req_data.precipitation_threshold = precipitationThreshold.toString();
      }
      dispatch(
        getDroughtMapRequest(`${formData?.indicesList[0]?.api_url}/`, req_data, isDroughtModule),
      );
    } else if (checkedIndicesList?.length === 2) {
      let config1, config2, index1, index2;

      droughtIndicesList.map((item, index) => {
        if (formData?.indicesList[0]?.id === item.id) {
          index1 = index;
        }
        if (formData?.indicesList[1]?.id === item.id) {
          index2 = index;
        }
      });

      config1 = {
        indexId: formData?.indicesList[0]?.id,
        index: index1,
        name: formData?.indicesList[0]?.name,
      };
      config2 = {
        indexId: formData?.indicesList[1]?.id,
        index: index2,
        name: formData?.indicesList[1]?.name,
      };

      let req_data1 = _.cloneDeep(req_data);
      let req_data2 = _.cloneDeep(req_data);

      if (formData?.indicesList[0]?.id === INDICES_PARAMS.CONTINGENCY_DRY_SPELL.id) {
        req_data1.precipitation_threshold = precipitationThreshold.toString();
      }
      if (formData?.indicesList[1]?.id === INDICES_PARAMS.CONTINGENCY_DRY_SPELL.id) {
        req_data2.precipitation_threshold = precipitationThreshold.toString();
      }

      let initialRequestResponse = AXIOS_INSTANCE.post(
        `${API_BASE_URL}/${formData?.indicesList[0]?.api_url}/map`,
        req_data1,
        config1,
      );
      let finalRequestResponse = AXIOS_INSTANCE.post(
        `${API_BASE_URL}/${formData?.indicesList[1]?.api_url}/map`,
        req_data2,
        config2,
      );
      setIsLoading(true);
      Promise.all([initialRequestResponse, finalRequestResponse])
        .then(responses => {
          let responsesData = [];
          setIsLoading(false);
          responses?.map(item => {
            const { data, config } = item;
            if (data.code === 200) {
              let res = data.result;
              res.id = config.indexId;
              res.index = config.index;
              res.name = config.name;
              responsesData.push(res);
            } else {
              showError(data?.message);
            }
          });
          dispatch(
            getDroughtMapSuccess({ response: { data: responsesData, isDroughtModule: false } }),
          );
        })
        .catch(error => {
          setIsLoading(false);
          if (error?.response) {
            let errorRes = error.response;
            showError(errorRes?.data?.message);
          } else {
            showError('No records found. please try again');
          }
        });
    }
  };

  const renderMapContainer = () => {
    return (
      <>
        {districtProfileModalShow ? (
          <TransitionModal
            districtProfileView={districtProfileView}
            districtProfileList={districtProfileList}
            districtProfileTable={districtProfileTable}
            districtRainfallData={districtRainfallData}
            districtData={formData?.district}
            stateData={formData?.state}
            districtProfileModalShow={districtProfileModalShow}
            isCategoryLoader={ContingencyPlan.isContingencyPlanCategoryRequesting}
            isSubCategoryLoader={ContingencyPlan.isContingencyPlanSubCategoryRequesting}
            showRainfallGraphModal={isOpenRainfallModal}
            handleDistrictProfileCloseModal={handleDistrictProfileCloseModal}
            from="district_profile"
            categoryList={categoryList}
            selectedCategory={formData.category}
            selectedSubCategory={formData.subCategory}
            handleCategoryChange={handleCategoryChange}
            subCategoryList={subCategoryList}
            handleSubCategoryChange={handleSubCategoryChange}
            setdistrictProfileCategoryId={setdistrictProfileCategoryId}
            districtProfileCategoryId={districtProfileCategoryId}
            formData={formData}
            setDistrictProfileTable={setDistrictProfileTable}
            handleRainfallModalToggle={handleRainfallModalToggle}
            isDistrictProfileRequesting={
              ContingencyPlan.isContingencyPlanDistrictProfileRequesting ||
              ContingencyPlan?.isContgencyDPEditLoading
            }
            setSelectedRow={setSelectedRow}
            setEditValues={setEditValues}
            selectedRow={selectedRow}
            editValues={editValues}
            setColumnHeader={setColumnHeader}
            columnHeader={columnHeader}
            userPermissions={userPermissions}
            // onHandleLogs={onHandleLogs}
          />
        ) : contingencyMeasuresModalShow ? (
          <TransitionModal
            breadCrumbsData={contingencyMeasuresFilterData?.breadCrumbsData}
            contingencyMeasuresModalShow={contingencyMeasuresModalShow}
            handleContingencyMeasuresCloseModal={handleContingencyMeasuresCloseModal}
            from="contingency_measures"
            cropTypeData={contingencyMeasuresFilterData?.cropTypeData}
            selectedCrop={contingencyMeasuresFilterData?.cropType}
            isCropTypeLoading={contingencyMeasuresFilterData?.isCropTypeLoading}
            handleCropTypeChange={handleCropTypeChange}
            contingencyMeasuresView={contingencyMeasuresView}
            contingencyMeasuresTable={contingencyMeasuresTable}
            formData={formData}
            setContingencyMeasuresTable={setContingencyMeasuresTable}
            isContingencyPlanMeasuresDataRequesting={
              ContingencyPlan.isContingencyPlanMeasuresDataRequesting
            }
            setDistrictProfileTable={setDistrictProfileTable}
            setSelectedRow={setSelectedRow}
            setEditValues={setEditValues}
            selectedRow={selectedRow}
            editValues={editValues}
            setColumnHeader={setColumnHeader}
            columnHeader={columnHeader}
            userPermissions={userPermissions}
            // onHandleLogs={onHandleLogs}
          />
        ) : (
          <MapContainer
            center={cordinateAndZoom.cordinates}
            id="contingency-leaflet-map"
            className="contingency-map-container"
            zoomControl={false}
            whenCreated={setMapReference}
            scrollWheelZoom={true}
            zoom={cordinateAndZoom.zoom}
          >
            <ChangeView center={cordinateAndZoom.cordinates} zoom={cordinateAndZoom.zoom} />
            <ZoomControl position="topright" className="map-zoom-control" />
            <LayersControl position="topright">
              <LayersControl.BaseLayer name="Satellite View">
                <TileLayer url={myConstClass.SATELLITE_TILE_LAYER_URL} />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer checked name="Streets View">
                <TileLayer url={myConstClass.TITLE_LAYER_URL} />
              </LayersControl.BaseLayer>
            </LayersControl>
            {districtProfileButtonShow && (
              <LayerControl
                position="topleft"
                className="layer-control-style"
                handleDistrictProfileModal={handleDistrictProfileModal}
                isHideBar={isHideBar}
              />
            )}

            {location && cordinateAndZoom?.searchMarkerCoordinates !== '' ? (
              <Marker position={cordinateAndZoom.searchMarkerCoordinates} icon={myIcon}>
                <Tooltip permanent direction="top" className="custom-tooltip-1" offset={[17, -17]}>
                  <div className="tooltip-container-1">
                    <span className="custom-marker-text">
                      ({cordinateAndZoom.searchMarkerCoordinates[0].toFixed(2)},
                      {cordinateAndZoom.searchMarkerCoordinates[1].toFixed(2)})
                    </span>
                  </div>
                </Tooltip>
              </Marker>
            ) : (
              ''
            )}

            <GeoJSON
              ref={mapPreferences['country'].layer}
              style={{ color: mapPreferences['country'].color, fillColor: '#0000', weight: 1 }}
            />

            <GeoJSON
              ref={mapPreferences['states_or_provinces'].layer}
              style={{
                color: mapPreferences['states_or_provinces'].color,
                fillColor: '#0000',
                weight: 2,
              }}
              onEachFeature={(feat, layer) => handleEachFeatureChange('stname', feat, layer)}
            />

            <GeoJSON
              ref={mapPreferences['districts_or_cities'].layer}
              style={{
                color: _.isEmpty(formData?.district)
                  ? mapPreferences['districts_or_cities'].color
                  : '#040404',

                weight: _.isEmpty(formData?.district) ? 1.5 : 3,
                fillColor: '#0000',
              }}
              onEachFeature={(feat, layer) => handleEachFeatureChange('dtname', feat, layer)}
            />

            <GeoJSON
              ref={mapPreferences['meteorological_locations'].layer}
              style={{
                color: mapPreferences['meteorological_locations'].color,
                fillColor: '#0000',
                weight: 1,
              }}
              onEachFeature={(feat, layer) => handleEachFeatureChange('', feat, layer)}
            />

            <GeoJSON
              ref={mapPreferences['basins'].layer}
              style={{
                color: mapPreferences['basins'].color,
                fillColor: '#0000',
                weight: 1,
              }}
              onEachFeature={(feat, layer) => handleEachFeatureChange('MAJ_NAME', feat, layer)}
            />

            <GeoJSON
              ref={mapPreferences['rivers_or_reservoirs'].layer}
              style={{
                color: mapPreferences['rivers_or_reservoirs'].color,
                fillColor: '#0000',
                weight: 1,
              }}
              onEachFeature={(feat, layer) => handleEachFeatureChange('', feat, layer)}
            />

            <GeoJSON
              ref={mapPreferences['talukas'].layer}
              style={{
                color: mapPreferences['talukas'].color,
                fillColor: '#0000',
                weight: 1,
              }}
              onEachFeature={(feat, layer) => handleEachFeatureChange('sdtname', feat, layer)}
            />

            <LayersControl position="topleft">
              {mapPreferRefs?.current?.map((mapLayerRef, index) => {
                if (!_.isEmpty(mapLayerRef)) {
                  return (
                    <>
                      <Overlay>
                        <LayerGroup ref={mapLayerRef.current.layer} key={index}>
                          <TileLayer
                            ref={mapPrefereTileLayerRef}
                            attribution='Weather from <a href="https://openweathermap.org/" alt="World Map and worldwide Weather Forecast online">OpenWeatherMap</a>'
                            url=""
                          />
                        </LayerGroup>
                      </Overlay>
                    </>
                  );
                }
              })}
            </LayersControl>
            {layerRef && layerRef?.current && layerRef?.current?.length ? (
              <LayersControl position="topleft">
                {renderParamLayer(droughtIndicesList)}
              </LayersControl>
            ) : (
              ''
            )}

            {!_.isEmpty(map) && selectedParamLegendData?.length
              ? selectedParamLegendData?.map((item, index) => {
                  return (
                    <>
                      <LayersControl position="topleft" key={index}>
                        {item?.descret_legend ? (
                          <DiscreteLegend
                            map={map}
                            add={item.add}
                            layer={item}
                            position="bottomright"
                          />
                        ) : (
                          <CustomLegend
                            map={map}
                            add={item.add}
                            layer={item}
                            position="bottomright"
                          />
                        )}
                      </LayersControl>
                    </>
                  );
                })
              : ''}
            {!_.isEmpty(map) && compareParamLegendData?.length
              ? compareParamLegendData?.map((item, index) => {
                  return (
                    <>
                      <LayersControl position="topleft" key={index}>
                        {item?.descret_legend ? (
                          <DiscreteLegend
                            map={map}
                            add={item.add}
                            layer={item}
                            position="bottomleft"
                          />
                        ) : (
                          <CustomLegend
                            map={map}
                            add={item.add}
                            layer={item}
                            position="bottomleft"
                          />
                        )}
                      </LayersControl>
                    </>
                  );
                })
              : ''}
            {!_.isEmpty(map) &&
              (selectedMapPreferLegendData.length
                ? selectedMapPreferLegendData.map((item, index) => {
                    return (
                      <>
                        <LayersControl position="topleft" key={index}>
                          {item?.descret_legend ? (
                            <DiscreteLegend
                              map={map}
                              add={item.add}
                              layer={item}
                              position="bottomleft"
                            />
                          ) : (
                            <CustomLegend
                              map={map}
                              add={item.add}
                              layer={item}
                              position="bottomright"
                            />
                          )}
                        </LayersControl>
                      </>
                    );
                  })
                : '')}

            <LayersControl position="topleft">
              <div className="logoContainer1">
                <Button className="map-preferences-button" onClick={handleMapModel}>
                  <img src={mapPreferenceIcon} alt="map preference" width={15} />
                </Button>
              </div>
            </LayersControl>
          </MapContainer>
        )}
      </>
    );
  };
  return (
    <div className="global-top-new">
      <div className="w-100">
        {isLoading && <Loader />}
        <div className={isHideBar ? `sideBar-collapse col-lg-2` : `sideBar col-lg-2`}>
          <ContingencyPlanFilter
            isHideBar={isHideBar}
            setBar={setBar}
            isSystemShow={isSystemShow}
            countryList={countryList}
            isContingencyMeasuresDisabled={isContingencyMeasuresDisabled}
            stateList={stateList}
            districtList={districtList}
            riskList={riskList}
            monsoonList={monsoonList}
            systemList={systemList}
            stageList={stageList}
            strategyList={strategyList}
            timePeriodList={timePeriodList}
            checkedIndicesList={checkedIndicesList}
            droughtIndicesList={droughtIndicesList}
            initialValues={filterFormData}
            filterActiveTab={filterActiveTab}
            isMapTabShow={isMapTabShow}
            dropdownDataLoading={dropdownDataLoading}
            mapPreferencesData={mapPreferencesData}
            precipitationThreshold={precipitationThreshold}
            defaultDates={defaultDates}
            handleActiveFilterTab={handleActiveFilterTab}
            handleCountry={handleCountry}
            handleState={handleState}
            handleDistrict={handleDistrict}
            handleRisk={handleRisk}
            handleMonsoon={handleMonsoon}
            handleSystem={handleSystem}
            handleMapDrought={handleMapDrought}
            handleMeasureDrought={handleMeasureDrought}
            handleDroughtIndices={handleSelectedDroughtIndices}
            handleStrategy={handleStrategy}
            setPrecipitationThreshold={handlePrecipitationThreshold}
            handleStartDate={handleStartDate}
            handleEndDate={handleEndDate}
            handleApplyLayers={handleApplyLayers}
            handleContingencyMeasuresModal={handleContingencyMeasuresModal}
            handleTimePeriodChange={handleTimePeriodChange}
            setLocation={setLocation}
            location={location}
            setCountryJson={setCountryJson}
            countryJson={countryJson}
            setCordinateAndZoom={setCordinateAndZoom}
          />
        </div>
        <Row>
          <Col lg={isHideBar ? 12 : 10} className="map-position">
            <div className="map-space-div">
              <div id="transition-modal" className="transition-modal">
                {renderMapContainer()}
              </div>
            </div>
          </Col>
        </Row>
        <MapPreferences
          isToggle={isOpenMapPreferences}
          handleToggle={handleMapModel}
          loc={formData?.country?.value}
          selectedLocation={{
            country: { ...formData?.country, name: formData?.country?.value },
            state: { ...formData?.state, name: formData?.state?.value },
            district: { ...formData?.district, name: formData?.district?.value },
          }}
          selectedParamLegendData={selectedParamLegendData}
          isMapPerferGeoJsonLoading={mapPreferenceGeoJsonloading}
          getMapPreferencesData={getMapPreferencesData}
          getMapPreferencesGeojsonData={getMapPreferencesGeojsonData}
          geoJsonData={geoJsonData}
          mapPreferencesAllData={mapPreferencesData}
          stateData={formData?.state}
          districtData={formData?.district}
          countryData={selectedCountryOption}
          cordinateAndZoom={cordinateAndZoom}
          fromContingencyPlan={true}
        />
      </div>
    </div>
  );
};

export default ContingencyPlan;

import DROUGHT_CONST from './droughtMonitorConsts';

const DroughtMonitor = (state = {}, action) => {
    switch (action.type) {
      case DROUGHT_CONST.GET_DROUGHT_MAP_REQUEST:
        return {
          ...state,
          isGetDroughtMapDataRequesting: true,
          getDroughtMapData: false,
          getDroughtMapDataError: false,
          getDroughtMapDataNetworkError: false,
        };
      case DROUGHT_CONST.GET_DROUGHT_MAP_SUCCESS:
        return {
          ...state,
          isGetDroughtMapDataRequesting: false,
          getDroughtMapData: action.payload ? action.payload : '',
          getDroughtMapDataError: false,
          getDroughtMapDataNetworkError: false,
        };
      case DROUGHT_CONST.GET_DROUGHT_MAP_FAILED:
        return {
          ...state,
          isGetDroughtMapDataRequesting: false,
          getDroughtMapData: false,
          getDroughtMapDataError: action.payload ? action.payload : false,
          getDroughtMapDataNetworkError: action.payload.netWorkError
            ? action.payload.statusText
            : false,
        };
      case DROUGHT_CONST.GET_DROUGHT_TIMESERIES_REQUEST:
        return {
          ...state,
          isGetDroughtTimeseriesRequesting: true,
          getDroughtTimeseriesData: false,
          getDroughtTimeseriesDataError: false,
          getDroughtTimeseriesDataNetworkError: false,
        };
      case DROUGHT_CONST.GET_DROUGHT_TIMESERIES_SUCCESS:
        return {
          ...state,
          isGetDroughtTimeseriesRequesting: false,
          getDroughtTimeseriesData: action.payload.response,
          getDroughtTimeseriesDataError: false,
          getDroughtTimeseriesDataNetworkError: false,
        };
      case DROUGHT_CONST.GET_DROUGHT_TIMESERIES_FAILED:
        return {
          ...state,
          isGetDroughtTimeseriesRequesting: false,
          getDroughtTimeseriesData: false,
          getDroughtTimeseriesDataError: action.payload ? action.payload : false,
          getDroughtTimeseriesDataNetworkError: action.payload.netWorkError
            ? action.payload.statusText
            : false,
        };
      case DROUGHT_CONST.GET_DROUGHT_ALL_SEASON_REQUEST:
        return {
          ...state,
          isGetDroughtAllSeasonRequesting: true,
          getDroughtAllSeasonData: false,
          getDroughtAllSeasonDataError: false,
          getDroughtAllSeasonDataNetworkError: false,
        };
      case DROUGHT_CONST.GET_DROUGHT_ALL_SEASON_SUCCESS:
        return {
          ...state,
          isGetDroughtAllSeasonRequesting: false,
          getDroughtAllSeasonData: action.payload.response,
          getDroughtAllSeasonDataError: false,
          getDroughtAllSeasonDataNetworkError: false,
        };
      case DROUGHT_CONST.GET_DROUGHT_ALL_SEASON_FAILED:
        return {
          ...state,
          isGetDroughtAllSeasonRequesting: false,
          getDroughtAllSeasonData: false,
          getDroughtAllSeasonDataError: action.payload ? action.payload : false,
          getDroughtAllSeasonDataNetworkError: action.payload.netWorkError
            ? action.payload.statusText
            : false,
        };
      case DROUGHT_CONST.GET_ALERT_ACTIVE_YEAR_MONTHS_REQUEST:
        return {
          ...state,
          isGetAlertActiveYearMonthsRequesting: true,
          getAlertActiveYearMonthsData: false,
          getAlertActiveYearMonthsDataError: false,
          getAlertActiveYearMonthsDataNetworkError: false,
        };
      case DROUGHT_CONST.GET_ALERT_ACTIVE_YEAR_MONTHS_SUCCESS:
        return {
          ...state,
          isGetAlertActiveYearMonthsRequesting: false,
          getAlertActiveYearMonthsData: action.payload.response,
          getAlertActiveYearMonthsDataError: false,
          getAlertActiveYearMonthsDataNetworkError: false,
        };
      case DROUGHT_CONST.GET_ALERT_ACTIVE_YEAR_MONTHS_FAILED:
        return {
          ...state,
          isGetAlertActiveYearMonthsRequesting: false,
          getAlertActiveYearMonthsData: false,
          getAlertActiveYearMonthsDataError: action.payload ? action.payload : false,
          getAlertActiveYearMonthsDataNetworkError: action.payload.netWorkError
            ? action.payload.statusText
            : false,
        };
      case DROUGHT_CONST.GET_FINAL_DROUGHT_MAP_REQUEST:
        return {
          ...state,
          isGetFinalDroughtMapRequesting: true,
          getFinalDroughtMapData: false,
          getFinalDroughtMapDataError: false,
          getFinalDroughtMapDataNetworkError: false,
        };
      case DROUGHT_CONST.GET_FINAL_DROUGHT_MAP_SUCCESS:
        return {
          ...state,
          isGetFinalDroughtMapRequesting: false,
          getFinalDroughtMapData: action.payload.response,
          getFinalDroughtMapDataError: false,
          getFinalDroughtMapDataNetworkError: false,
        };
      case DROUGHT_CONST.GET_FINAL_DROUGHT_MAP_FAILED:
        return {
          ...state,
          isGetFinalDroughtMapRequesting: false,
          getFinalDroughtMapData: false,
          getFinalDroughtMapDataError: action.payload ? action.payload : false,
          getFinalDroughtMapDataNetworkError: action.payload.netWorkError
            ? action.payload.statusText
            : false,
        };
      case DROUGHT_CONST.GET_DSS_THRESHOLD_DATA_REQUEST:
        return {
          ...state,
          getDssThresholdDataRequesting: true,
          getDssThresholdData: false,
          getDssThresholdDataError: false,
          getDssThresholdDataNetworkError: false,
        };
      case DROUGHT_CONST.GET_DSS_THRESHOLD_DATA_SUCCESS:
        return {
          ...state,
          getDssThresholdDataRequesting: false,
          getDssThresholdData: action.payload.response,
          getDssThresholdDataError: false,
          getDssThresholdDataNetworkError: false,
        };
      case DROUGHT_CONST.GET_DSS_THRESHOLD_DATA_FAILED:
        return {
          ...state,
          getDssThresholdDataRequesting: false,
          getDssThresholdData: false,
          getDssThresholdDataError: action.payload ? action.payload : false,
          getDssThresholdDataNetworkError: action.payload.netWorkError
            ? action.payload.statusText
            : false,
        };
      case DROUGHT_CONST.GET_DSS_INDICES_REQUEST:
        return {
          ...state,
          isGetDSSIndicesRequesting: true,
          getDSSIndicesData: false,
          getDSSIndicesDataError: false,
          getDSSIndicesDataNetworkError: false,
        };
      case DROUGHT_CONST.GET_DSS_INDICES_SUCCESS:
        return {
          ...state,
          isGetDSSIndicesRequesting: false,
          getDSSIndicesData: action.payload.response,
          getDSSIndicesDataError: false,
          getDSSIndicesDataNetworkError: false,
        };
      case DROUGHT_CONST.GET_DSS_INDICES_FAILED:
        return {
          ...state,
          isGetDSSIndicesRequesting: false,
          getDSSIndicesData: false,
          getDSSIndicesDataError: action.payload ? action.payload : false,
          getDSSIndicesDataNetworkError: action.payload.netWorkError
            ? action.payload.statusText
            : false,
        };
        case DROUGHT_CONST.GET_DROUGHT_PREDICTION_INDICES_MONTHS:
          return {
            ...state,
            isGetDroughtPredictionIndicesMonthsRequesting: true,
            getDroughtPredictionIndicesMonthsData: false,
            getDroughtPredictionIndicesMonthsDataError: false,
            getDroughtPredictionIndicesMonthsDataNetworkError: false,
          };
        case DROUGHT_CONST.GET_DROUGHT_PREDICTION_INDICES_MONTHS_SUCCESS:
          return {
            ...state,
            isGetDroughtPredictionIndicesMonthsRequesting: false,
            getDroughtPredictionIndicesMonthsData: action.payload.response,
            getDroughtPredictionIndicesMonthsDataError: false,
            getDroughtPredictionIndicesMonthsDataNetworkError: false,
          };
        case DROUGHT_CONST.GET_DROUGHT_PREDICTION_INDICES_MONTHS_FAILED:
          return {
            ...state,
            isGetDroughtPredictionIndicesMonthsRequesting: false,
            getDroughtPredictionIndicesMonthsData: false,
            getDroughtPredictionIndicesMonthsDataError: action.payload ? action.payload : false,
            getDroughtPredictionIndicesMonthsDataNetworkError: action.payload.netWorkError
              ? action.payload.statusText
              : false,
          };
      default:
        return state;
    }
};

export default DroughtMonitor;
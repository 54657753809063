import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  AXIOS_INSTANCE,
  AXIOS_INSTANCE_MASTER_SERVER,
  API_BASE_URL,
  MASTER_SERVER_BASE_URL,
  DROUGHT_DECISION_SUPPORT_URL,
} from '../api/apiEndPoint';
import DROUGHT_CONST from './droughtMonitorConsts';
import {
  getDroughtMapSuccess,
  getDroughtMapFailed,
  getDroughtTimeSeriesSuccess,
  getDroughtTimeSeriesFailed,
  getDroughtAllSeasonSuccess,
  getDroughtAllSeasonFailed,
  getAlertActiveYearsMonthsSuccess,
  getFinalDroughtMapSuccess,
  getFinalDroughtMapFailed,
  getAlertActiveYearsMonthsFailed,
  getDSSIndicesSuccess,
  getDSSIndicesFailed,
  getDSSThresholdDataSuccess,
  getDSSThresholdDataFailed,
  getDroughtPredictionIndicesMonthsSuccess,
  getDroughtPredictionIndicesMonthsFailed,
} from './droughtMonitorActions';

const getDroughtMapApiRequest = async payload => {
  let response = {};
  try {
    const config = {
      headers: {
        Authorization: `${process.env.REACT_APP_APIKEY_MASTER_SERVER}`,
      },
    };
    const responseData = payload?.isMasterServer
      ? await AXIOS_INSTANCE_MASTER_SERVER.post(
          `${MASTER_SERVER_BASE_URL}/${payload.apiURL}map`,
          payload.requestObj,
          config,
        )
      : await AXIOS_INSTANCE.post(
          `${API_BASE_URL}/${payload.apiURL}map`,
          payload.requestObj,
          config,
        );
    response = {
      data: responseData.data,
      isDroughtModule: payload.isDroughtModule,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      isDroughtModule: payload.isDroughtModule,
      status: 400,
    };
  }
  return response;
};

function* getDroughtMap(action) {
  try {
    const apiResponse = yield call(getDroughtMapApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
          isDroughtModule: apiResponse.isDroughtModule,
        },
      };
      yield put(getDroughtMapSuccess(responseData));
    } else if (apiResponse.status === 400) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getDroughtMapFailed(responseData));
    }
  } catch (error) {
    if (error.response.status === 400) {
      const responseData = {
        data: error.response.data,
      };
      yield put(getDroughtMapFailed(responseData));
    } else {
      const errorData = {
        error: {
          statusText: error,
          netWorkError: true,
        },
      };
      yield put(getDroughtMapFailed(errorData));
    }
  }
}

const getDroughtTimeseriesApiRequest = async payload => {
  let response = {};
  try {
    const config = {
      headers: {
        Authorization: `${process.env.REACT_APP_APIKEY_MASTER_SERVER}`,
      },
    };

    const responseData = payload?.isMasterServer
      ? await AXIOS_INSTANCE_MASTER_SERVER.post(
          `${MASTER_SERVER_BASE_URL}/${payload.apiURL}timeseries`,
          payload.requestObj,
          config,
        )
      : await AXIOS_INSTANCE.post(
          `${API_BASE_URL}/${payload.apiURL}timeseries`,
          payload.requestObj,
          config,
        );
    response = {
      data: responseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getDroughtTimeseriesDataRequest(action) {
  try {
    const apiResponse = yield call(getDroughtTimeseriesApiRequest, action.payload);
    if (apiResponse?.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getDroughtTimeSeriesSuccess(responseData));
    } else if (apiResponse.status === 400) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getDroughtTimeSeriesFailed(responseData));
    }
  } catch (error) {
    if (error.response.status === 400) {
      const responseData = {
        data: error.response.data,
      };
      yield put(getDroughtTimeSeriesFailed(responseData));
    } else {
      const errorData = {
        error: {
          statusText: error,
          netWorkError: true,
        },
      };
      yield put(getDroughtTimeSeriesFailed(errorData));
    }
  }
}

const getDroughtAllSeasonDataApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.post(
      `${DROUGHT_DECISION_SUPPORT_URL}/getAllSeason`,
      payload,
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getDroughtAllSeasonData(action) {
  try {
    const apiResponse = yield call(getDroughtAllSeasonDataApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getDroughtAllSeasonSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(getDroughtAllSeasonFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getDroughtAllSeasonFailed(errorData));
  }
}

const getAlertActiveYearMonthsApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.post(
      `${DROUGHT_DECISION_SUPPORT_URL}/getAlertActiveYearsMonths`,
      payload,
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getAlertActiveYearMonths(action) {
  try {
    const apiResponse = yield call(getAlertActiveYearMonthsApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getAlertActiveYearsMonthsSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(getAlertActiveYearsMonthsFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getAlertActiveYearsMonthsFailed(errorData));
  }
}

const getFinalDroughtMapDataApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.post(
      `${DROUGHT_DECISION_SUPPORT_URL}/finalDroughtMap`,
      payload,
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getFinalDroughtMapData(action) {
  try {
    const apiResponse = yield call(getFinalDroughtMapDataApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getFinalDroughtMapSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(getFinalDroughtMapFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getFinalDroughtMapFailed(errorData));
  }
}

const getDSSThresholdDataApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.get(
      `${DROUGHT_DECISION_SUPPORT_URL}/getThreshold/${payload.location_type},${payload.location_id}`,
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getDSSThresholdData(action) {
  try {
    const apiResponse = yield call(getDSSThresholdDataApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getDSSThresholdDataSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(getDSSThresholdDataFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getDSSThresholdDataFailed(errorData));
  }
}

const getDSSIndicesDataApiRequest = async () => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.get(`${DROUGHT_DECISION_SUPPORT_URL}/getIndices`);
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getDSSIndicesData() {
  try {
    const apiResponse = yield call(getDSSIndicesDataApiRequest);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getDSSIndicesSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(getDSSIndicesFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getDSSIndicesFailed(errorData));
  }
}

const getDPIndicesMonthsApiRequest = async () => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.get(
      `${API_BASE_URL}/drought-prediction/getDroughtIndicesMonths`,
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getDPIndicesMonths() {
  try {
    const apiResponse = yield call(getDPIndicesMonthsApiRequest);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getDroughtPredictionIndicesMonthsSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(getDroughtPredictionIndicesMonthsFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getDroughtPredictionIndicesMonthsFailed(errorData));
  }
}

export function* watchGetDroughtMap() {
  yield takeEvery(DROUGHT_CONST.GET_DROUGHT_MAP_REQUEST, getDroughtMap);
}

export function* watchGetDroughtTimeseries() {
  yield takeEvery(DROUGHT_CONST.GET_DROUGHT_TIMESERIES_REQUEST, getDroughtTimeseriesDataRequest);
}

export function* watchGetDroughtAllSeason() {
  yield takeEvery(DROUGHT_CONST.GET_DROUGHT_ALL_SEASON_REQUEST, getDroughtAllSeasonData);
}

export function* watchGetAlertActiveYearMonths() {
  yield takeEvery(DROUGHT_CONST.GET_ALERT_ACTIVE_YEAR_MONTHS_REQUEST, getAlertActiveYearMonths);
}

export function* watchGetFinalDroughtMap() {
  yield takeEvery(DROUGHT_CONST.GET_FINAL_DROUGHT_MAP_REQUEST, getFinalDroughtMapData);
}

export function* watchGetDSSThresholdData() {
  yield takeEvery(DROUGHT_CONST.GET_DSS_THRESHOLD_DATA_REQUEST, getDSSThresholdData);
}

export function* watchGetDSSDroughtIndices() {
  yield takeEvery(DROUGHT_CONST.GET_DSS_INDICES_REQUEST, getDSSIndicesData);
}

export function* watchGetDPIndicesMonths() {
  yield takeEvery(DROUGHT_CONST.GET_DROUGHT_PREDICTION_INDICES_MONTHS, getDPIndicesMonths);
}

function* DroughtMonitorSaga() {
  yield all([
    fork(watchGetDroughtMap),
    fork(watchGetDroughtTimeseries),
    fork(watchGetDroughtAllSeason),
    fork(watchGetAlertActiveYearMonths),
    fork(watchGetFinalDroughtMap),
    fork(watchGetDSSThresholdData),
    fork(watchGetDSSDroughtIndices),
    fork(watchGetDPIndicesMonths),
  ]);
}

export default DroughtMonitorSaga;

import React, { useEffect, useState } from 'react';
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri';
import { Collapse, Input } from 'reactstrap';
import Select from 'react-select';
import _ from 'lodash';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import IndicesInfoIcons from './IndicesInfoIcons';
import moment from 'moment';
import { DATE_FORMAT } from '../../Components/constants';

export const DroughtOtherIndicatorsCollapse = props => {
  const {
    setMinStartDate,
    setSelectedParam,
    setCompareParam,
    isCompare,
    precipitationThreshold,
    setPrecipitationThreshold,
    selectedModule,
    selectedParam,
    setInitialSelectedDate,
    setFinalSelectedDate,
  } = props?.data;

  const checkboxStyle = {
    color: '#2A547C',
  };
  const [DroughtIndicators, setDroughtIndicators] = useState('');
  const [CompareDroughtIndicators, setCompareDroughtIndicators] = useState('');

  useEffect(() => {
    if (!_.isEmpty(props.DroughtIndicesData)) {
      const { data } = props.DroughtIndicesData;
      let count = 0;

      let updatedData = data?.result?.map(item => {
        let x = _.clone(item);
        x.isShowCategory = true;
        x.parameter = x?.parameter?.map(i => {
          i.checked = i?.id === selectedParam?.id ? true : false;
          count = count + 1;
          i.paramIndex = count;
          return i;
        });
        return x;
      });
      setDroughtIndicators(updatedData);
      setCompareDroughtIndicators(updatedData);
    } else {
      setDroughtIndicators([]);
    }
  }, [props.DroughtIndicesData]);

  const handleCategoryToggle = (categoryArr, categoryItem, isCompare) => {
    let categoryList = JSON.parse(JSON.stringify(categoryArr));
    let updatedItem = JSON.parse(JSON.stringify(categoryItem));
    let updatedList = categoryList?.map(item => {
      if (item.name === updatedItem.name) {
        item.isShowCategory = !updatedItem.isShowCategory;
      }
      return item;
    });
    if (isCompare) setCompareDroughtIndicators(updatedList);
    else setDroughtIndicators(updatedList);
  };

  const renderCheckboxes = ({ DroughtIndicators, isCompare }) => {
    return DroughtIndicators.map((item, index) => {
      return (
        <>
          <span
            onClick={() => handleCategoryToggle(DroughtIndicators, item, isCompare)}
            className="drought-category-style"
          >
            <p className={'drought-category-labels'} style={checkboxStyle}>
              {item.name}
              <span className="category-span">
                <span className="category-span">
                  {item?.isShowCategory ? (
                    <RiArrowDropUpLine size={25} />
                  ) : (
                    <RiArrowDropDownLine size={25} />
                  )}
                </span>
              </span>
            </p>
          </span>
          <Collapse
            id={'collapse' + index}
            isOpen={item?.isShowCategory}
            className="drought-collapse-moadal"
          >
            {returnCheckbox(DroughtIndicators, item, item.parameter, index, isCompare)}
          </Collapse>
        </>
      );
    });
  };

  const handleCheckbox = (DroughtIndicators, categoryItem, param, isCompare) => {
    let indicesData = JSON.parse(JSON.stringify(categoryItem));
    let updatedData = indicesData;
    if (!_.isEmpty(indicesData)) {
      updatedData.parameter = indicesData?.parameter?.map((value, i) => {
        if (value.id === param.id) {
          value.checked = true;
        } else {
          value.checked = false;
        }
        return value;
      });
    }
    let updatedIndicatorsList = DroughtIndicators.map((item, index) => {
      if (item.name === indicesData.name) {
        return updatedData;
      } else {
        let x = JSON.parse(JSON.stringify(item));
        x.parameter = x?.parameter?.map(i => {
          i.checked = false;
          return i;
        });
        return x;
      }
    });
    if (isCompare) {
      setCompareDroughtIndicators(updatedIndicatorsList);
      setCompareParam(param);
      setFinalSelectedDate(prev => ({
        ...prev,
        endDate: moment(param?.max_date).format(DATE_FORMAT),
        startDate: moment(param?.max_date)
          .subtract(30, 'days')
          .format(DATE_FORMAT),
      }));
    } else {
      setDroughtIndicators(updatedIndicatorsList);
      setMinStartDate(param?.min_date);
      setCompareParam('');
      setSelectedParam(param);
      setInitialSelectedDate(prev => ({
        ...prev,
        endDate: moment(param?.max_date).format(DATE_FORMAT),
        startDate: moment(param?.max_date)
          .subtract(30, 'days')
          .format(DATE_FORMAT),
      }));
    }
  };

  const returnCheckbox = (DroughtIndicators, item, parameters, index, isCompare) => {
    return (
      parameters?.length &&
      parameters?.map((param, index) => {
        return (
          <>
            <ul className="drought-param-style position-relative" key={index}>
              <li
                className={
                  param?.checked
                    ? 'drought-li-checked d-inline-block'
                    : 'drought-submenu-li d-inline-block'
                }
                onClick={() => handleCheckbox(DroughtIndicators, item, param, isCompare)}
              >
                {param?.checked ? <AiFillEye size={20} /> : <AiFillEyeInvisible size={20} />}
                <span style={{ paddingLeft: '10px' }}>{param?.name || ''}</span>
              </li>
              <IndicesInfoIcons
                checked={param?.checked}
                indicesName={param?.name}
                selectedModule={selectedModule}
              ></IndicesInfoIcons>

              {param?.name === 'Extreme Rainfall' && param?.checked ? (
                <div className="mx-3 mt-3 mb-3">
                  <label htmlFor="quantity">Precipitation Threshold (mm)</label>
                  <Input
                    type="number"
                    id="quantity"
                    name="quantity"
                    min="0"
                    value={precipitationThreshold}
                    onChange={e => setPrecipitationThreshold(e.target.value)}
                  />
                </div>
              ) : (
                ''
              )}
            </ul>
          </>
        );
      })
    );
  };

  return (
    <>
      <div className="drought-sidebar-dropdown">
        {!_.isEmpty(DroughtIndicators) ? (
          renderCheckboxes({
            DroughtIndicators: isCompare ? CompareDroughtIndicators : DroughtIndicators,
            isCompare: isCompare,
          })
        ) : (
          <>
            <div>
              <p>No Indicators are available</p>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export const DroughtPredictionCollapse = props => {
  const {
    DroughtIndicesData,
    setMinStartDate,
    setSelectedParam,
    setCompareParam,
    isCompare,
    droughtPredictionIndicesMonths,
    selectedDPIndicesMonth,
    setSelectedDPIndicesMonth,
    selectedModule,
    selectedParam,
  } = props.data;

  const [DroughtIndicators, setDroughtIndicators] = useState('');
  const [CompareDroughtIndicators, setCompareDroughtIndicators] = useState('');

  useEffect(() => {
    if (!_.isEmpty(DroughtIndicesData)) {
      const { data } = DroughtIndicesData;
      let parameterArr = [];
      let count = 0;
      data?.result?.map(item => {
        let x = _.clone(item);
        x?.parameter?.map(i => {
          if (i?.id === selectedParam?.id) {
            i.checked = true;
          } else {
            i.checked = false;
          }
          i.paramIndex = count;
          count = count + 1;
          parameterArr?.push(i);
        });
      });
      setDroughtIndicators(parameterArr || []);
      setCompareDroughtIndicators(parameterArr || []);
    } else {
      setDroughtIndicators([]);
    }
  }, [DroughtIndicesData]);

  const handleCheckbox = (DroughtIndicators, param, isCompare) => {
    let indicesData = JSON.parse(JSON.stringify(DroughtIndicators));
    let updatedData = indicesData;
    if (!_.isEmpty(indicesData)) {
      updatedData = indicesData?.map((value, i) => {
        if (value.id === param?.id) {
          value.checked = true;
        } else {
          value.checked = false;
        }
        return value;
      });
    }
    if (isCompare) {
      setCompareDroughtIndicators(updatedData);
      setCompareParam(param);
    } else {
      setDroughtIndicators(updatedData);
      setMinStartDate(param?.min_date);
      setSelectedParam({ ...param, checked: true });
    }
  };

  const ReturnCheckbox = ({ DroughtIndicators, isCompare }) => {
    return (
      DroughtIndicators?.length &&
      DroughtIndicators?.map((param, index) => {
        return (
          <>
            <ul className="drought-param-style position-relative" key={index}>
              <li
                className={
                  param?.checked
                    ? 'drought-li-checked d-inline-block'
                    : 'drought-submenu-li d-inline-block'
                }
                onClick={() => handleCheckbox(DroughtIndicators, param, isCompare)}
              >
                {param?.checked ? <AiFillEye size={20} /> : <AiFillEyeInvisible size={20} />}
                <span style={{ paddingLeft: '10px' }}>{param?.name || ''}</span>
              </li>
              <IndicesInfoIcons
                checked={param?.checked}
                indicesName={param?.name}
                selectedModule={selectedModule}
              ></IndicesInfoIcons>
              {(param?.name === 'SPI' || param?.name === 'SRI') && param?.checked ? (
                <div className="mt-3 mb-3 mx-2">
                  <label htmlFor="quantity">Select Frequency (months)</label>
                  <Select
                    id="multi-select"
                    name="region"
                    placeholder="Select Frequency"
                    options={droughtPredictionIndicesMonths}
                    onChange={setSelectedDPIndicesMonth}
                    isMulti={false}
                    value={selectedDPIndicesMonth}
                    required={true}
                    className="frequency"
                  />
                </div>
              ) : (
                ''
              )}
            </ul>
          </>
        );
      })
    );
  };

  return (
    <>
      <div className="drought-sidebar-dropdown">
        {!_.isEmpty(DroughtIndicators) ? (
          ReturnCheckbox({
            DroughtIndicators: isCompare ? CompareDroughtIndicators : DroughtIndicators,
            isCompare: isCompare,
          })
        ) : (
          <>
            <div>
              <p>No Indicators are available</p>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const DroughtMonitorOtherIndicesCollapse = props => {
  const { selectedModule, isCompare } = props;
  let DroughtIndicesData = '';
  if (isCompare) {
    DroughtIndicesData = props?.compareDroughtIndicesData;
  } else DroughtIndicesData = props?.DroughtIndicesData;
  return (
    <>
      {selectedModule.api_slug === 'drought-monitor-v2' ||
      selectedModule.api_slug === 'drought-prediction' ? (
        <>
          {selectedModule.api_slug === 'drought-monitor-v2' ? (
            <>
              <DroughtOtherIndicatorsCollapse
                data={props}
                DroughtIndicesData={DroughtIndicesData}
              />
            </>
          ) : (
            <DroughtPredictionCollapse data={props} />
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default DroughtMonitorOtherIndicesCollapse;

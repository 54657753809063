export const USER_LIST_CONST = {
    USER_LIST_REQUEST: 'USER_LIST_REQUEST',
    USER_LIST_SUCCESS: 'USER_LIST_SUCCESS',
    USER_LIST_FAILED: 'USER_LIST_FAILED',
  }

export const USER_BY_ID_CONST = {
  USER_BY_ID_REQUEST: 'USER_BY_ID_REQUEST',
  USER_BY_ID_SUCCESS: 'USER_BY_ID_SUCCESS',
  USER_BY_ID_FAILED: 'USER_BY_ID_FAILED',
  }

export const USER_DELETE_CONST = {
    USER_DELETE_REQUEST: 'USER_DELETE_REQUEST',
    USER_DELETE_SUCCESS: 'USER_DELETE_SUCCESS',
    USER_DELETE_FAILED: 'USER_DELETE_FAILED',
  }

export const USER_PERMISSIONS_CONST = {
    USER_PERMISSION_REQUEST: 'USER_PERMISSION_REQUEST',
    USER_PERMISSION_SUCCESS: 'USER_PERMISSION_SUCCESS',
    USER_PERMISSION_FAILED: 'USER_PERMISSION_FAILED',
  }

export const UPDATE_PERMISSIONS_CONST = {
  UPDATE_PERMISSION_REQUEST: 'UPDATE_PERMISSION_REQUEST',
  UPDATE_PERMISSION_SUCCESS: 'UPDATE_PERMISSION_SUCCESS',
  UPDATE_PERMISSION_FAILED: 'UPDATE_PERMISSION_FAILED',
  }
  
export const USER_PERMISSIONS_BY_ID_CONST = {
  USER_PERMISSIONS_BY_ID_REQUEST: 'USER_PERMISSIONS_BY_ID_REQUEST',
  USER_PERMISSIONS_BY_ID_SUCCESS: 'USER_PERMISSIONS_BY_ID_SUCCESS',
  USER_PERMISSIONS_BY_ID_FAILED: 'USER_PERMISSIONS_BY_ID_FAILED',
  }
  
export const USER_DETAIL_CONST = {
    USER_DETAIL_REQUEST: ' USER_DETAIL_REQUEST',
    USER_DETAIL_SUCCESS: ' USER_DETAIL_SUCCESS',
    USER_DETAIL_FAILED: ' USER_DETAIL_FAILED',
  }
const HOME_CONST = {
  GET_BANNERS_REQUEST: 'GET_BANNERS_REQUEST',
  GET_BANNERS_SUCCESS: 'GET_BANNERS_SUCCESS',
  GET_BANNERS_FAILED: 'GET_BANNERS_FAILED',

  GET_PARAMETERS_REQUEST: 'GET_PARAMETERS_REQUEST',
  GET_PARAMETERS_SUCCESS: 'GET_PARAMETERS_SUCCESS',
  GET_PARAMETERS_FAILED: 'GET_PARAMETERS_FAILED',

  GET_DMS_EVENTS_REQUEST: 'GET_DMS_EVENTS_REQUEST',
  GET_DMS_EVENTS_SUCCESS: 'GET_DMS_EVENTS_SUCCESS',
  GET_DMS_EVENTS_FAILED: 'GET_DMS_EVENTS_FAILED',

  GET_DMS_STATISTICS_REQUEST: 'GET_DMS_STATISTICS_REQUEST',
  GET_DMS_STATISTICS_SUCCESS: 'GET_DMS_STATISTICS_SUCCESS',
  GET_DMS_STATISTICS_FAILED: 'GET_DMS_STATISTICS_FAILED',

  GET_DMS_TESTIMONIALS_REQUEST: 'GET_DMS_TESTIMONIALS_REQUEST',
  GET_DMS_TESTIMONIALS_SUCCESS: 'GET_DMS_TESTIMONIALS_SUCCESS',
  GET_DMS_TESTIMONIALS_FAILED: 'GET_DMS_TESTIMONIALS_FAILED',

  GET_DMS_PARTNERS_DONORS_REQUEST: 'GET_DMS_PARTNERS_DONORS_REQUEST',
  GET_DMS_PARTNERS_DONORS_SUCCESS: 'GET_DMS_PARTNERS_DONORS_SUCCESS',
  GET_DMS_PARTNERS_DONORS_FAILED: 'GET_DMS_PARTNERS_DONORS_FAILED',

  GET_DMS_NEWSFEED_REQUEST: 'GET_DMS_NEWSFEED_REQUEST',
  GET_DMS_NEWSFEED_SUCCESS: 'GET_DMS_NEWSFEED_SUCCESS',
  GET_DMS_NEWSFEED_FAILED: 'GET_DMS_NEWSFEED_FAILED',

  GET_DMS_PILLARS_REQUEST: 'GET_DMS_PILLARS_REQUEST',
  GET_DMS_PILLARS_SUCCESS: 'GET_DMS_PILLARS_SUCCESS',
  GET_DMS_PILLARS_FAILED: 'GET_DMS_PILLARS_FAILED',

  ADD_LANDING_PAGE_REQUEST: 'ADD_LANDING_PAGE_REQUEST',
  ADD_LANDING_PAGE_SUCCESS: 'ADD_LANDING_PAGE_SUCCESS',
  ADD_LANDING_PAGE_FAILURE: 'ADD_LANDING_PAGE_FAILURE',

  DELETE_LANDING_PAGE_REQUEST: 'DELETE_LANDING_PAGE_REQUEST',
  DELETE_LANDING_PAGE_SUCCESS: 'DELETE_LANDING_PAGE_SUCCESS',
  DELETE_LANDING_PAGE_FAILURE: 'DELETE_LANDING_PAGE_FAILURE',

  EDIT_LANDING_PAGE_REQUEST: 'EDIT_LANDING_PAGE_REQUEST',
  EDIT_LANDING_PAGE_SUCCESS: 'EDIT_LANDING_PAGE_SUCCESS',
  EDIT_LANDING_PAGE_FAILURE: 'EDIT_LANDING_PAGE_FAILURE',

  CONTACT_US_REQUEST: 'CONTACT_US_REQUEST',
  CONTACT_US_SUCCESS: 'CONTACT_US_SUCCESS',
  CONTACT_US_FAILURE: 'CONTACT_US_FAILURE',
};

export default HOME_CONST;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Row,
  Col,
  Input,
  Label,
} from 'reactstrap';
import Select from 'react-select';
import { useTranslation } from 'react-multi-lang';
import PropTypes from 'prop-types';
import { getParametersRequest } from '../../redux/accessKey/accessKeyActions';
import ReasonModal from './ReasonModal';
import ConfirmationModal from './Confirmation';
import { getCountryRequest, getCountryBoRequest } from '../../redux/actions';
import { LOCATION } from '../../Components/constants';
import _ from 'lodash';

const ClientDetailmodal = (props) => {
  const { modalOpen, handleModalToggle, req_data, handleLatestData, fromEditDetails, activeTab } =
    props;
  const expiry_duration_options = [3, 4, 12, 15, 18, 24];
  const status_options = ['select', 'approve', 'reject'];
  const [modal, setModal] = useState(modalOpen);
  const [indicesList, setIndicesList] = useState();
  const [isRejectModalOpen, setRejectModalOpen] = useState(false);
  const [isApproveModalOpen, setApproveModalOpen] = useState(false);
  const [rejectModalData, setRejectModalData] = useState();
  const [approveModalData, setApproveModalData] = useState();
  const [selectedAll, setSelectedAll] = useState(false);
  const [selectedIndices, setSelectedIndices] = useState([]);
  const [initialSelectedIndices, setInitialSelectedIndices] = useState([]);
  const [expiryDuration, setExpiryDuration] = useState();
  const [initExpiryDuration, setInitExpiryDuration] = useState();
  const [status, setStatus] = useState();
  const [initStatus, setInitStatus] = useState();
  const [accessCountryList, setAccessCountryList] = useState();
  const [selectedAccessCountries, setSelectedAccessCountries] = useState();
  const [initselectedAccessCountries, setInitSelectedAccessCountries] = useState();
  const[countriesListDisableEnable,setCountriesListEnableDisable] = useState(activeTab == "approve"? false : true)
  const [expirationTimeEnableDisable,setExpirationTimeEnableDisable] = useState(activeTab == "approve"? false : true)
  const dispatch = useDispatch();
  const t = useTranslation();
  const nextProps = useSelector((state) => ({
    IndicesData: state.AccessKey.getParametersData || null,
    changeStatusData: state.AccessKey.changeStatusData || null,
    countryList: state.Location.countryList || null,
  }));
  //const toggleDropDown = () => setDropdownOpen(!dropdownOpen);
  useEffect(() => {
    if (req_data && req_data.api_ids) {
      setSelectedIndices(req_data.api_ids);
      setInitialSelectedIndices(req_data.api_ids);
      setExpiryDuration(req_data.expiry_duration ? req_data.expiry_duration : 3);
      setInitExpiryDuration(req_data.expiry_duration ? req_data.expiry_duration : 3);
      setStatus(req_data.request_status);
      setInitStatus(req_data.request_status);
      let options = [];
      if (accessCountryList?.length) {
        let a;
        req_data.approved_access_countries.map((d) => {
          a = accessCountryList.filter((i) => i.id === d);
          let data;
          if (a && a[0]) {
            data = {
              value: a[0].name,
              label: `${a[0].name}`,
              id: a[0].id,
            };
          }
          options.push(data);
        });
        setSelectedAccessCountries(options);
        setInitSelectedAccessCountries(options);
      }
    }
  }, [req_data, accessCountryList]);

  useEffect(() => {
    if (fromEditDetails) {
      dispatch(getCountryBoRequest({ location_type: LOCATION.Country, parent_id: '' }));
    } else {
      dispatch(getCountryRequest({ location_type: LOCATION.Country, parent_id: '' }));
    }
  }, []);
  useEffect(() => {
    if (nextProps.countryList) {
      if (nextProps.countryList.result) {
        setAccessCountryList(nextProps.countryList.result);
      }
    }
  }, [nextProps.countryList]);

  const viewtoggle = (value) => {
    if (value === 'update') {
      let data;
      if (status === 'approve') {
        if (selectedAccessCountries) {
          let b = [];
          selectedAccessCountries?.map((i) => {
            b.push(i?.id);
          });
          data = {
            id: req_data && req_data.id,
            request_status: 'approve',
            api_ids: selectedIndices,
            expiry_duration: expiryDuration,
            approved_access_countries: b,
          };
          setApproveModalOpen(true);
          setApproveModalData(data);
        }
      } else if (status === 'reject') {
        if (selectedAccessCountries) {
          let b = [];
          selectedAccessCountries?.map((i) => {
            b.push(i?.id);
          });
          data = {
            id: req_data && req_data.id,
            request_status: 'reject',
            reject_response: '',
            api_ids: selectedIndices,
            approved_access_countries: b,
          };
          setRejectModalOpen(true);
          setRejectModalData(data);
        }
      }
    } else {
      setModal(false);
      handleModalToggle(false);
    }
  };
  useEffect(() => {
    if (modalOpen) {
      dispatch(getParametersRequest('drought'));
    }
  }, []);

  useEffect(() => {
    if (nextProps.IndicesData) {
      const {
        data: { result },
      } = nextProps.IndicesData;
      if (result) {
        setIndicesList(result);
      }
    }
  }, [nextProps.IndicesData]);

  useEffect(() => {
    if (selectedIndices && indicesList) {
      let a = [];
      indicesList.map((i) => {
        a.push(i.id);
      });
      let b = selectedIndices.sort().join(',') === a.sort().join(',');
      if (b === true) {
        setSelectedAll(true);
      } else {
        setSelectedAll(false);
      }
    }
  }, [selectedIndices, indicesList]);

  useEffect(()=>{
    if (fromEditDetails && status == 'approve'){
      setCountriesListEnableDisable(false)
      setExpirationTimeEnableDisable(false)
    }
    else{
      setCountriesListEnableDisable(true)
      setExpirationTimeEnableDisable(true)
    }
  },[status,activeTab])

  const handleOnchangeIndices = (e, id) => {
    if (selectedIndices.includes(id)) {
      let b = [];
      b = selectedIndices;
      setSelectedIndices(b.filter((i) => i !== id));
    } else {
      setSelectedIndices([...selectedIndices, id]);
    }
  };

  const handleSelectAllIndices = (e) => {
    let a = [];
    if (e.target.checked) {
      indicesList.map((i) => {
        a.push(i.id);
      });
      setSelectedIndices(a);
    } else {
      setSelectedIndices([]);
    }
  };
  const handleStatusChange = (value) => {
    setStatus(value);
  };

  const handleApprovedModalToggle = (value) => {
    setApproveModalOpen(value);
    setModal(false);
    handleModalToggle(false);
  };

  const handleRejectModal = (value) => {
    setRejectModalOpen(value);
  };
  const renderAPIParameterOptions = (list, value) => {
    if (list) {
      let params = [...list];
      let options = [];
      if (params.length) {
        params.map((param) => {
          let data = {
            value: param.name,
            label: `${param.name}`,
            id: param.id,
          };
          options.push(data);
          return true;
        });
        return options;
      }
      setSelectedAccessCountries(options);
    }
  };
  const handleAccessCountryChange = (selectedOption) => {
    setSelectedAccessCountries(selectedOption);
  };

  const areEqual = (array1, array2) => {
    if (array1.length === array2.length) {
      return array1.every((element) => {
        if (array2.includes(element)) {
          return true;
        }
        return false;
      });
    }

    return false;
  };

  const areEqualCountries = (array1, array2) => {
    if (array1 && array2) {
      if (array1.length === array2.length && array1.length != 0) {
        let init = array1.map((item) => {
          return item?.id;
        });
        let updated = array2.map((item) => {
          return item?.id;
        });
        if (init.length === updated.length) {
          return init.every((element) => {
            if (updated.includes(element)) {
              return true;
            }

            return false;
          });
        }
      }
    }
    return false;
  };
  return (
    <>
      <div className="client-modal">
        <Modal
          isOpen={modal}
          toggle={viewtoggle}
          className="modal-view-details modal-lg"
          backdrop="static"
        >
          <ModalHeader className="client-detail-header" toggle={viewtoggle}>
            {t('PUBLIC_API_ADMIN.CLIENT_DETAILS')}
          </ModalHeader>
          <ModalBody>
            <Table>
              <tr className="col-lg-12">
                <td className="col-lg-3 col-md-3 col-sm-3 client-deatils">
                  <h4>{t('PUBLIC_API_ADMIN.NAME')}</h4>
                  <p>{req_data && req_data.name ? req_data.name : 'N/A'}</p>
                </td>
                <td className="col-lg-3 col-md-3 col-sm-3 client-deatils">
                  <h4>{t('PUBLIC_API_ADMIN.EMAIL')}</h4>
                  <p>{req_data && req_data.email ? req_data.email : 'N/A'}</p>
                </td>
                <td className="col-lg-3 col-md-3 col-sm-3 client-deatils">
                  <h4>{t('PUBLIC_API_ADMIN.ORGANIZATION')}</h4>
                  <p>{req_data && req_data.organization ? req_data.organization : 'N/A'}</p>
                </td>
                <td className="col-lg-3 col-md-3 col-sm-3 client-deatils">
                  <h4>{t('PUBLIC_API_ADMIN.COUNTRY')}</h4>
                  <p>{req_data && req_data.country_name ? req_data.country_name : 'N/A'}</p>
                </td>
                </tr>
                <tr className="col-lg-12">
                <td className="col-lg-3 col-md-3 col-sm-3 client-deatils">
                  <h4>{t('PUBLIC_API_ADMIN.DESIGNATION')}</h4>
                  <p>{req_data && req_data.designation ? req_data.designation : 'N/A'}</p>
                </td>
                <td className="col-lg-3 col-md-3 col-sm-3 client-deatils">
                  <h4>{t('PUBLIC_API_ADMIN.APPLICATION_URL')}</h4>
                  <p>{req_data && req_data.application_url ? req_data.application_url : 'N/A'}</p>
                </td>
                <td className="col-lg-3 col-md-3 col-sm-3 client-deatils">
                  <h4>{t('PUBLIC_API_ADMIN.EXPIRATION_TIME')}</h4>
                  <select
                    value={expiryDuration ? expiryDuration : 'N/A'}
                    onChange={(e) => setExpiryDuration(e.target.value)}
                    className="page-count"
                    disabled= {expirationTimeEnableDisable}
                  >
                    {expiry_duration_options.map((expiryDuration) => (
                      <option
                        key={expiryDuration}
                        value={expiryDuration}
                      >{`${expiryDuration} months`}</option>
                    ))}
                  </select>
                </td>
                <td className="col-lg-3 col-md-3 col-sm-3 client-deatils">
                  <h4>{t('PUBLIC_API_ADMIN.STATUS')}</h4>
                  <select
                    value={status ? status : 'N/A'}
                    onChange={(e) => handleStatusChange(e.target.value)}
                    className="page-count"
                    disabled={!fromEditDetails}
                  >
                    {status_options.map((status) => (
                      <option key={status} value={status}>
                        {status.charAt(0).toUpperCase() + status.slice(1)}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            </Table>
            <div className='col-lg-12'>
                <div className="access-country client-deatils">
                  <h4>Access Country</h4>
                  <>
                    <Select
                      name="access_country_id"
                      className="input-style-css"
                      placeholder=""
                      isClearable={true}
                      options={renderAPIParameterOptions(accessCountryList)}
                      value={selectedAccessCountries}
                      onChange={handleAccessCountryChange}
                      isDisabled= {countriesListDisableEnable}
                      isMulti={true}
                      required={true}
                    />
                  </>
                </div>
              </div>
            <div className="col-lg-12 col-md-12 col-sm-12 client-deatils purpose-section">
              <h4>{t('PUBLIC_API_ADMIN.PURPOSE')}</h4>
              <p>{req_data && req_data.purpose ? req_data.purpose : 'N/A'}</p>
            </div>
            <Row lg="12" className="view-detail-border" />
            <div className="mt-4 view-detail-select p-3 pb-4">
              <Row className="col-lg-12 view-row">
                <Col lg="6">
                  <h4 className="view-row-heading mb-4">{t('PUBLIC_API_ADMIN.INDICES_REQUEST')}</h4>
                </Col>
                <Col lg="6">
                  <div>
                    <Label check className="view-select-option">
                      <Input
                        type="checkbox"
                        disabled={!status ||
                          status === status_options[0]||status === 'reject' ? true : !fromEditDetails}
                        onClick={(e) => handleSelectAllIndices(e)}
                        checked={selectedAll}
                      ></Input>
                      {t('PUBLIC_API_ADMIN.SELECT_ALL')}
                    </Label>
                  </div>
                </Col>
              </Row>
              <Row className="col-lg-12 view-row">
                {indicesList &&
                  indicesList.map((i) => {
                    return (
                      <Col lg="3">
                        <Label check className="md-filter-option">
                          <Input
                            type="checkbox"
                            disabled={!status ||
                              status === status_options[0]||status === 'reject' ? true : !fromEditDetails}
                            checked={
                              selectedIndices && selectedIndices.includes(i.id) ? true : false
                            }
                            onClick={(e) => handleOnchangeIndices(e, i.id)}
                          ></Input>
                          {i.name}
                        </Label>
                      </Col>
                    );
                  })}
              </Row>
            </div>
          </ModalBody>
          <ModalFooter className="modal-viewdeatil-footer">
            {fromEditDetails ? (
              <>
                <button onClick={() => viewtoggle('cancel')} className="reject-button">
                  {t('PUBLIC_API_ADMIN.CANCEL')}
                </button>{' '}
                <button
                  onClick={() => viewtoggle('update')}
                  className="approve-button"
                  disabled={
                    (areEqual(selectedIndices, initialSelectedIndices) &&
                      initStatus == status &&
                      areEqualCountries(initselectedAccessCountries, selectedAccessCountries) &&
                      initExpiryDuration == expiryDuration) ||
                    selectedIndices.length === 0 ||
                    !status ||
                    status === status_options[0]
                  }
                >
                  {t('PUBLIC_API_ADMIN.UPDATE')}
                </button>
              </>
            ) : (
              <button onClick={() => viewtoggle('cancel')} className="reject-button">
                {t('PUBLIC_API_ADMIN.CANCEL')}
              </button>
            )}
          </ModalFooter>
        </Modal>
      </div>
      {isRejectModalOpen && (
        <ReasonModal
          modalData={rejectModalData}
          isOpen={isRejectModalOpen}
          handleLatestData={handleLatestData}
          handleRejectModal={handleRejectModal}
          handleModalToggle={handleModalToggle}
        />
      )}
      {isApproveModalOpen && (
        <ConfirmationModal
          fromModal={true}
          activeTab={activeTab}
          modalData={approveModalData}
          isOpen={isApproveModalOpen}
          handleApprovedModalToggle={handleApprovedModalToggle}
          handleLatestData={handleLatestData}
        />
      )}
    </>
  );
};

export default ClientDetailmodal;
ClientDetailmodal.propTypes = {
  modalOpen: PropTypes.bool,
  handleModalToggle: PropTypes.func,
  req_data: PropTypes.object,
  handleLatestData: PropTypes.func,
  fromEditDetails: PropTypes.bool,
  activeTab: PropTypes.string,
};

import React from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import Modal from 'react-modal';
import '../../assets/CSS/Transition.css';
import DistrictProfile from './DistrictProfile/index';
import ContingencyMeasures from './ContingencyMeasures/index';

class TransitionModal extends React.Component {
  state = {
    isOpen: false,
  };

  toggleModal = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  };
  render() {
    const modalStyles = {
      overlay: {
        backgroundColor: '#ffffff',
      },
    };

    return (
      <div>
        <CSSTransition
          in={
            this.props.from === 'district_profile'
              ? this.props.districtProfileModalShow
              : this.props.contingencyMeasuresModalShow
          }
          timeout={300}
          classNames="dialog"
        >
          <Modal
            closeTimeoutMS={500}
            isOpen={
              this.props.from === 'district_profile'
                ? this.props.districtProfileModalShow
                : this.props.contingencyMeasuresModalShow
            }
            style={modalStyles}
          >
            {this.props.from === 'district_profile' ? (
              <DistrictProfile
                handleDistrictProfileCloseModal={this.props.handleDistrictProfileCloseModal}
                categoryList={this.props.categoryList}
                districtData={this.props.districtData}
                
                stateData={this.props.stateData}
                districtRainfallData={this.props.districtRainfallData}
                handleCategoryChange={this.props.handleCategoryChange}
                subCategoryList={this.props.subCategoryList}
                handleSubCategoryChange={this.props.handleSubCategoryChange}
                setdistrictProfileCategoryId={this.props.setdistrictProfileCategoryId}
                districtProfileCategoryId={this.props?.districtProfileCategoryId}
                formData={this?.props?.formData}
                districtProfileList={this.props.districtProfileList}
                districtProfileTable={this.props.districtProfileTable}
                districtProfileView={this.props.districtProfileView}
                isDistrictProfileRequesting={this.props.isDistrictProfileRequesting}
                selectedCategory={this.props.selectedCategory}
                selectedSubCategory={this.props.selectedSubCategory}
                isCategoryLoader={this.props.isCategoryLoader}
                isSubCategoryLoader={this.props.isSubCategoryLoader}
                showRainfallGraphModal={this.props.showRainfallGraphModal}
                handleRainfallModalToggle={this.props.handleRainfallModalToggle}
                setDistrictProfileTable={this.props.setDistrictProfileTable}
                setSelectedRow={this.props.setSelectedRow}
                setEditValues={this.props.setEditValues}
                selectedRow={this.props.selectedRow}
                editValues={this.props.editValues}
                setColumnHeader={this.props.setColumnHeader}
                columnHeader={this.props.columnHeader}
                userPermissions={this.props.userPermissions}
              />
            ) : (
              <ContingencyMeasures
                breadCrumbsData={this.props.breadCrumbsData}
                handleContingencyMeasuresCloseModal={this.props.handleContingencyMeasuresCloseModal}
                cropTypeData={this.props.cropTypeData}
                selectedCrop={this.props.selectedCrop}
                handleCropTypeChange={this.props.handleCropTypeChange}
                contingencyMeasuresView={this.props.contingencyMeasuresView}
                contingencyMeasuresTable={this.props.contingencyMeasuresTable}
                isCropTypeLoading={this.props.isCropTypeLoading}
                isContingencyPlanMeasuresDataRequesting={
                  this.props.isContingencyPlanMeasuresDataRequesting
                }
                // onHandleLogs={this.props?.onHandleLogs}
                formData={this.props.formData}
                setContingencyMeasuresTable={this.props.setContingencyMeasuresTable}
                setSelectedRow={this.props.setSelectedRow}
                setEditValues={this.props.setEditValues}
                selectedRow={this.props.selectedRow}
                editValues={this.props.editValues}
                setColumnHeader={this.props.setColumnHeader}
                columnHeader={this.props.columnHeader}
                userPermissions={this.props.userPermissions}
              />
            )}
          </Modal>
        </CSSTransition>
      </div>
    );
  }
}
const someElement = document.getElementById('transition-modal');
if (someElement) {
  ReactDOM.render(<TransitionModal />, someElement);
}

export default TransitionModal;

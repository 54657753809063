import React, { useState, useEffect, useRef } from 'react';
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  useMap,
  useMapEvents,
  ZoomControl,
  LayersControl,
  LayerGroup,
  Tooltip,
  Marker,
} from 'react-leaflet';
import _ from 'lodash';
import moment from 'moment';
import L from 'leaflet';
import 'leaflet-side-by-side';
import 'leaflet.control.opacity/dist/L.Control.Opacity.css';
import 'leaflet.control.opacity';
import { Button, Row, Col, FormGroup, Label, Input, Alert } from 'reactstrap';
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';
import PropTypes from 'prop-types';
import GraphLoader from '../../Components/common/GraphLoader';
import Loader from '../../Components/common/TextLoader';
import 'leaflet/dist/leaflet.css';
import MapPreferences from './MapPreferences';
import '../../assets/CSS/LeafletWrapper.css';
import '../../assets/CSS/Header.css';
import myConstClass from '../../Constants';
import {
  BASE_TIME_ECMWF,
  FORECAST_LIST_ECMWF,
  SHOW_VALIDTIME_AND_LAYERS,
  PARAMETER_SLUGS,
  SOIL_MOISTURE_LAYER_ECMWF,
  SHOW_DAY_AND_QUANTILE,
} from '../../Components/constants';
import LeftFilter from '../../Components/common/Filter';
import { t } from 'react-multi-lang';
import LayerControl from './CustomLayerControl/LayerControl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getWeatherForecastData,
  getWeatherForecastDataSuccess,
  getNoaaTimeSeries,
  getMapPreferencesSuccess,
  getErpasTimeSeries,
  getIRITimeSeries,
  getIRIAvailableDatesDataRequest,
  getEcmwfProductsRequest,
  getEcmwfControlsRequest,
  getEcmwfDataByControlsRequest,
} from '../../redux/actions';
import {
  staticResourceYearRequest,
  staticResourceRequest,
  staticResourceAllDateRequest,
} from '../../redux/staticResource/staticResourceAction';
import {
  DATA_SOURCES,
  ERPAS_PARAMETER_SLUG,
  MAP_PREFERENCES_lABELS,
  NOAA_PARAMETER_SLUG,
  OPEN_WEATHER_CHART_PARAMS,
  OPEN_WEATHER_PARAMETERS_CONST,
  IRI_PARAMETER_SLUG,
  SOUTH_ASIA_CONST,
  STATIC_RESOURCE_GET_CATEGORY_LIST,
  GEOJSON_COUNTRY_TOOLTIP_DATA,
  GEOJSON_COUNTRY_DATA,
  DEFAULT_COUNTRY,
} from '../../Components/constants/index';
import {
  AXIOS_INSTANCE,
  AXIOS_INSTANCE_MASTER_SERVER,
  ERPAS_TIMESERIES,
  NOAA_TIMESERIES,
  IRI_TIMESERIES,
  WEATHER_FORECAST_DATA_URL,
  MASTER_SERVER_BASE_URL,
} from '../../redux/api/apiEndPoint';
import CustomLegend from './CustomLayerControl/CustomLegend';
import DiscreteLegend from './CustomLayerControl/DiscreteLegend';
import { StaticResource } from './StaticResource';
import Charts from '../../Components/Charts';
import {
  handleOpenWeatherCompareRes,
  renderGenericDateFormat,
  renderGeometricTimeSeries,
  renderOpenWeatherResponse,
  renderParamList,
  setWeatherForecastDefaultParam,
  isLogedIn,
} from '../../Components/common/utils';
import { showError } from '../../Components/Notifications.js';
import 'leaflet-draw/dist/leaflet.draw.css';
import DrawShape from '../../pages/drawShape';
import { CgCloseO } from 'react-icons/cg';
import GeometryCharts from '../../Components/common/GeometryCharts';
import mapPreferenceIcon from '../../assets/Images/mapPreferenceIcon.svg';
import Cookies from 'js-cookie';
import { FORECAST_LEAD_TIME_LIST } from '../../Components/constants/index';
import { iriDate, renderStaticResourceImageApi } from '../../Components/helpers/index.js';
import CustomMarkerIcon from '../../Components/common/CustomIcon.js';
import * as ReactDOMServer from 'react-dom/server';
import { FaMapMarkerAlt } from 'react-icons/fa';

export default function LeafletWrapper(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { Overlay } = LayersControl;
  const [map, setMap] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const layerRef = useRef([]);
  const [stateData, setStateData] = useState();
  const [mapPreferRefs, setMapPreferRefs] = useState([]);
  const [isOpenWeather, setIsOpenWeather] = useState(false);
  const [mapLayerOpacity, setMapLayerOpacity] = useState({ layer: '' });
  const [opacityControl, setOpacityControl] = useState('');
  const [mapUrl, setMapUrl] = useState('');
  const [mapPreferUrl, setMapPreferUrl] = useState('');
  const [isOpenMapModel, setIsOpenMapModel] = useState(false);
  const [isHideBar, setBar] = useState(false);
  const [selectedParams, setSelectedParams] = useState([]);
  const [selectedYear, setSelectedYear] = useState({});
  const [params, setParams] = useState('');
  const [initialParams, setInitialParams] = useState('');
  const [compareParams, setCompareParams] = useState('');
  const [selectedParam, setSelectedParam] = useState('');
  const [country, setCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [region, setRegion] = useState('');
  const [countryJson, setCountryJson] = useState('');
  const [dataSources, setDataSources] = useState([]);
  const [compareDataSources, setCompareDataSources] = useState([]);
  const [initialSelectedDate, setInitialSelectedDate] = useState([]);
  const [finalSelectedDate, setFinalSelectedDate] = useState([]);
  const [dateSourceWeatherData, setWeatherData] = useState({});
  const [forecastData, setForecastData] = useState([]);
  const [parameterData, setParameterData] = useState([]);
  const [hasQueryParam, setHasQueryParam] = useState(false);
  const [showEcmwf, setShowEcmwf] = useState(false);
  const [regionId, setRegionId] = useState();
  //const [selectedForecastLeadTime, setSelectedForecastLeadTime] = useState(null);
  const [selectedDataSourceId, setSelectedDataSourceId] = useState();
  const [staticResourceParams, setStaticResourceParams] = useState([]);
  const [staticResourceDates, setStaticResourceDates] = useState();
  const [staticResourceImage, setStaticResourceImage] = useState();
  const [staticResourceTitle, setStaticResourceTitle] = useState();
  const [mapPreferencesData, setMapPreferencesData] = useState([]);
  const [geoJsonData, setGeoJsonData] = useState([]);
  const [geoJsonAllData, setGeoJsonAllData] = useState([]);
  const [geoJsonLayersData, setGeoJsonLayerData] = useState([]);
  const [getBoundariesData, setGetBoundariesData] = useState([]);
  const [selectedParamDataSourceId, setSelectedParamDataSourceId] = useState();
  const [compareParamLegendData, setCompareParamLegendData] = useState([]);
  const [selectedParamLegendData, setSelectedParamLegendData] = useState([]);
  const [selectedMapPreferLegendData, setSelectedMapPreferLegendData] = useState([]);
  const [showCharts, setShowCharts] = useState(false);
  const [selectedChartTimeRange, setSelectedChartTimeRange] = useState('This week');
  const [chartSeriesData, setChartSeriesData] = useState([]);
  const [isDefaultDataSource, setIsDefaultDataSource] = useState(false);
  const [graphData, setGraphData] = useState('');
  const [selectedDate, setSelectedDate] = useState();
  const [staticResourceWeekData, setStaticResourceWeekData] = useState(null);
  // const [selectedFinalForercastLeadTime, setSelectedFinalForercastLeadTime] = useState(null);
  const [mapPreferenceGeoJsonloading, setMapPreferenceGeoJsonloading] = useState(false);
  const [compareView, setCompareView] = useState([]);
  const [mapPreferences, setMapPreferences] = useState({
    country: { layer: useRef(), color: '', data: '', country: '' },
    states_or_provinces: { layer: useRef(), color: '', data: '', country: '' },
    districts_or_cities: { layer: useRef(), color: '', data: '', country: '' },
    meteorological_locations: { layer: useRef(), color: '', data: '', country: '' },
    basins: { layer: useRef(), color: '', data: '', country: '' },
    rivers_or_reservoirs: { layer: useRef(), color: '', data: '', country: '' },
    talukas: { layer: useRef(), color: '', data: '', country: '' },
  });
  const [requestBody, setRequestBody] = useState({
    forecast_data_source_id: [],
    parameter_id: [],
    group_date: '',
    order_by: 'start_date',
    page: 1,
  });
  const [urlDataSourceId, setUrlDataSourceId] = useState('');
  const [isDefaultSelectedSourceId, setIsDefaultSelectedSourceId] = useState(false);
  const [staticResourceSelectedLocation, setStaticResourceSelectedLocation] = useState(null);
  const mapRef = useRef(null);
  const [mapControl, setMapControl] = useState({});
  const [categoryParams, setCategoryParams] = useState([]);
  const [categorizedCompareParams, setCategoryCompareParams] = useState([]);
  const [selectedParamLegend, setSelectedParamLegend] = useState([]);
  const [temporalAggregation, setTemporalAggregation] = useState([]);
  const [errorData, setErrorData] = useState({ showError: false, data: '' });
  const splitterRef = useRef(null);
  const [forecastLeadTimeList, setForecastLeadTimeList] = useState([]);
  const [currentUserLocation, setCurrenUserLocation] = useState();
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedLocationEcmwf, setSelectedLocationEcmwf] = useState('');
  const [range, setRange] = useState({
    min: null,
    max: null,
  });
  const [applyLayers, setApplyLayers] = useState(false);
  const [updateGraph, setUpdateGraph] = useState(false);
  const [isGraphLoading, setIsGraphLoading] = useState();
  //state for timeseries data
  const [noaaTimeSeriesData, setNoaaTimeSeriesData] = useState(null);
  const [erpasTimeSeriesData, setErpasTimeSeriesData] = useState(null);
  const [iriTimeSeriesData, setIRITimeSeriesData] = useState(null);
  const [noaaRegionTimeSeries, setNoaaRegionTimeSeries] = useState([]);
  const [compareSearch, setCompareSearch] = useState({});
  const [forecastAvailableDates, setForecastAvailableDates] = useState([]);
  const [IRIAvailableYearList, setIRIAvailableYearList] = useState([]);
  const [compareIRIAvailableYearList, setCompareIRIAvailableYearList] = useState([]);
  const [IRIAvailableMonthList, setIRIAvailableMonthList] = useState([]);
  const [compareIRIAvailableMonthList, setCompareIRIAvailableMonthList] = useState([]);
  const [IRIAvailableLeadTimeList, setIRIAvailableLeadTimeList] = useState([]);
  const [compareIRIAvailableLeadTimeList, setCompareIRIAvailableLeadTimeList] = useState([]);
  const [IRIForecastTime, setIRIForecastTime] = useState({
    year: null,
    month: null,
    lead_time: null,
  });
  const [compareIRIForecastTime, setCompareIRIForecastTime] = useState({
    year: null,
    month: null,
    lead_time: null,
  });
  const [isHideToolBar, setIsHideToolBar] = useState(false);
  const [isCompareClicked, setIsCompareClicked] = useState(false);
  const [compareCordinates, setCompareCordinates] = useState([]);
  const [selectedRegionId, setSelectedRegionId] = useState('');
  const [forecastCompareLeadTimeList, setForecastCompareLeadTimeList] = useState([]);
  const [yearsList, setYearsList] = useState([]);
  const [indexLayers, setIndexLayers] = useState([]);
  const [layerControl, setLayerControl] = useState({
    firstLayer: {},
    secondLayer: {},
  });

  const [selectedDataSource, setSelectedDataSource] = useState({
    firstDataSource: [],
    secondDataSource: [],
  });

  const [cordinateAndZoom, setCordinateAndZoom] = useState({
    cordinates: [28.259, 83.9461],
    locationCordinates: [28.259, 83.9461],
    markerCordinates: [28.259, 83.9461],
    zoom: 4.4,
  });
  const [ecmwfProducts, setEcmwfProducts] = useState([]);
  const [selectedEcmwfProducts, setSelectedEcmwfProducts] = useState('');

  const [selectedForecastYearMonth, setSelectedForecastYearMonth] = useState({
    graph: {
      forecastYearMonth: moment().format('YYYY-MM'),
    },
    map: {
      forecastYearMonth: moment().format('YYYY-MM'),
    },
  });
  const [selectedForecastCompareYearMonth, setSelectedForecastCompareYearMonth] = useState({
    graph: {
      forecastYearMonth: moment().format('YYYY-MM'),
    },
    map: {
      forecastYearMonth: moment().format('YYYY-MM'),
    },
  });
  const [searchMarker, setSearchMarker] = useState('');

  const [staticResourceBaseTimeData, setStatiResourceBaseTimeData] = useState([]);
  const [validTimeOptions, setValidTimeOptions] = useState([]);
  const [validLayerOptions, setValidLayerOptions] = useState([]);
  const [selectedValidTime, setSelectedValidTime] = useState({});
  const [selectedValidLayer, setSelectedValidLayer] = useState([]);

  const [daysData, setDaysData] = useState({
    daysOptions: [],
    selectedDay: null,
  });
  const [quantileData, setQuantileData] = useState({
    quantileOptions: [],
    selectedQuantile: null,
  });

  const [forecastType, setForecastType] = useState({
    forecastTypeOptions: [],
    selectedForecastType: null,
  });

  const [selectedBasetime, setSelectedBaseTime] = useState({});

  const [staticResourceData, setStatiResourceData] = useState([]);

  const selectedParam_current = selectedParam;
  const selectedParam_prev = usePrevious(selectedParam_current);

  // state for noaa timeseries data
  const {
    Weather,
    boundries,
    ecmwfProductData,
    ecmwfControlData,
    ecmwfDataByControlData,
  } = useSelector(state => ({
    Weather: state.Weather,
    DroughtMonitor: state.DroughtMonitor,
    boundries: state.Map_Preference,
    ecmwfProductData: state?.Weather?.isEcmwfProductData?.data,
    ecmwfControlData: state?.Weather?.isEcmwfControlData,
    ecmwfDataByControlData: state?.Weather?.isEcmwfDataByControlData,
  }));

  const nextProps = useSelector(state => ({
    isStaticResourceRequesting: state.staticResource.isStaticResourceRequesting,
    StaticResource: state.staticResource.staticResourceData || null,
    staticResourceDataError: state.staticResource.staticResourceDataError || null,
    id: state.staticResource.id,
    StaticResourceAllDateData: state.staticResource.staticResourceAllDateData,
    staticResourceYearData: state.staticResource.staticResourceYearData || null,
  }));

  //useeffect for getting map layers data from redux
  useEffect(() => {
    if (!isLogedIn()) {
      let path = window.location.pathname;
      let search1 = window.location.search;
      let URLParams1 = new URLSearchParams(search1);
      let countryName = URLParams1?.get('Country');
      countryName && countryName !== 'null'
        ? history.push(`/login?prevPath=${path.substring(1, path.length)}&Country=${countryName}`)
        : history.push(`/login?prevPath=${path.substring(1, path.length)}`);
    }
    dispatch(getEcmwfProductsRequest({}));
    return () => {
      dispatch(getMapPreferencesSuccess(''));
      dispatch(getWeatherForecastDataSuccess(''));
      Cookies.remove('weather-forecast-default-param', { path: '/' });
      setShowCharts(false);
    };
  }, []);

  useEffect(() => {
    if (!_.isEmpty(selectedParam)) {
      if (!hasQueryParam) {
        if (selectedParam?.forecast_data_source_id === DATA_SOURCES.IRI.id) {
          dispatch(getIRIAvailableDatesDataRequest());
        }
      }
    }
  }, [selectedParam]);

  useEffect(() => {
    if (ecmwfControlData?.statusCode === 200) {
      setStatiResourceData(ecmwfControlData?.data?.result);
      const baseTimeArrayData = ecmwfControlData?.data?.result?.find(
        each => each?.name === BASE_TIME_ECMWF,
      );

      const ForecastListData = ecmwfControlData?.data?.result?.find(
        each => each?.title === FORECAST_LIST_ECMWF,
      );

      const baseTimearr = baseTimeArrayData?.values?.map(item => {
        if (item?.label) {
          return {
            value: item.value,
            label: item?.label,
            validTime: item?.linked_values,
          };
        }

        return {
          value: item.base_time,
          label: item?.valid_time?.[0]?.forecast_type?.[0]?.ecmwf_base_time_label,
        };
      });

      setStatiResourceBaseTimeData(baseTimearr);
      setSelectedBaseTime(baseTimearr?.[0]);
      if (SHOW_VALIDTIME_AND_LAYERS.includes(selectedEcmwfProducts?.slug)) {
        const validTimeArr = baseTimearr?.[0]?.validTime;

        setValidTimeOptions(validTimeArr); // by default selecting first element as options
        setSelectedValidTime(validTimeArr[0]); // by default selecting first element
        if (selectedEcmwfProducts?.slug === PARAMETER_SLUGS.SOIL_MOISTURE) {
          let sortedLayerOptions = [];
          sortedLayerOptions = ecmwfControlData?.data?.result?.find(
            each => each?.title === SOIL_MOISTURE_LAYER_ECMWF,
          );

          setValidLayerOptions(sortedLayerOptions?.values);
          setSelectedValidLayer(sortedLayerOptions?.values?.[0]);
        }
        if (selectedEcmwfProducts?.slug === PARAMETER_SLUGS.PRECEIPITATION_SEAS5) {
          let foreCastTypeOptions = [];
          if (ForecastListData) {
            foreCastTypeOptions = ForecastListData?.values;
          }
          setForecastType({
            forecastTypeOptions: foreCastTypeOptions,
            selectedForecastType: foreCastTypeOptions?.[0],
          });
        }
      }

      if (SHOW_DAY_AND_QUANTILE.includes(selectedEcmwfProducts?.slug)) {
        const ecmwfDayData = ecmwfControlData?.data?.result?.find(each => each.title === 'Day');

        const ecmwfQuantileData = ecmwfControlData?.data?.result?.find(
          each => each?.title === 'Quantile',
        );

        if (ecmwfQuantileData) {
          setDaysData({
            daysOptions: ecmwfDayData?.values,
            selectedDay: ecmwfDayData?.values?.[0],
          });
        }

        setQuantileData({
          quantileOptions: ecmwfQuantileData?.values,
          selectedQuantile: ecmwfQuantileData?.values?.[0],
        });
      }
    } else {
      setStatiResourceBaseTimeData([]);
    }
  }, [ecmwfControlData]);

  useEffect(() => {
    if (!_.isEmpty(compareDataSources)) {
      if (!hasQueryParam) {
        if (compareDataSources[0].id === DATA_SOURCES.IRI.id) {
          dispatch(getIRIAvailableDatesDataRequest());
        }
      }
    }
  }, [compareDataSources]);

  useEffect(() => {
    if (Weather.iriAvailableDatesData) {
      const {
        data: { result },
      } = Weather.iriAvailableDatesData;
      if (result) {
        setForecastAvailableDates(result);
      }
    }
  }, [Weather.iriAvailableDatesData]);

  useEffect(() => {
    if (!_.isEmpty(forecastAvailableDates)) {
      let list = [];
      forecastAvailableDates?.map(i => {
        list.push({
          id: i?.category,
          name: i?.category,
          value: i?.category,
          label: i?.category,
          data: i?.data,
        });
      });
      setIRIAvailableYearList(list);
      setCompareIRIAvailableYearList(list);
    }
  }, [forecastAvailableDates]);

  useEffect(() => {
    if (!_.isEmpty(ecmwfProductData)) {
      let list = [];
      ecmwfProductData?.result?.map(i => {
        list.push({
          ...i,
          value: i.slug,
          label: i.name,
        });
      });
      setEcmwfProducts(list);
      setSelectedEcmwfProducts(list[0]);
    }
  }, [ecmwfProductData]);

  useEffect(() => {
    if (selectedEcmwfProducts) {
      dispatch(getEcmwfControlsRequest({ slug: selectedEcmwfProducts?.slug }));
    }
  }, [selectedEcmwfProducts]);

  useEffect(() => {
    if (!_.isEmpty(IRIForecastTime.year)) {
      let data;
      let list = [];
      IRIAvailableYearList?.map(i => {
        if (i?.value === IRIForecastTime?.year?.value) {
          data = i?.data;
        }
      });
      data?.map(a => {
        list.push(handleForecastLeadMonthFormat(a));
      });
      setIRIAvailableMonthList(list);
    }
  }, [IRIForecastTime.year]);

  useEffect(() => {
    if (!_.isEmpty(compareIRIForecastTime.year)) {
      let data;
      let list = [];
      compareIRIAvailableYearList?.map(i => {
        if (i?.value === compareIRIForecastTime?.year?.value) {
          data = i?.data;
        }
      });
      data?.map(a => {
        list.push(handleForecastLeadMonthFormat(a));
      });
      setCompareIRIAvailableMonthList(list);
    }
  }, [compareIRIForecastTime.year]);

  useEffect(() => {
    if (!_.isEmpty(IRIForecastTime.month)) {
      let data;
      let list = [];
      IRIAvailableMonthList.map(i => {
        if (i?.value === IRIForecastTime.month?.value) {
          data = i?.data;
        }
      });
      data?.map(a => {
        list.push(handleForecastLeadTimeFormat(a));
      });
      setIRIAvailableLeadTimeList(list);
    }
  }, [IRIForecastTime.month]);

  useEffect(() => {
    if (!_.isEmpty(compareIRIForecastTime.month)) {
      let data;
      let list = [];
      compareIRIAvailableMonthList.map(i => {
        if (i?.value === compareIRIForecastTime.month?.value) {
          data = i?.data;
        }
      });
      data?.map(a => {
        list.push(handleForecastLeadTimeFormat(a));
      });
      setCompareIRIAvailableLeadTimeList(list);
    }
  }, [compareIRIForecastTime.month]);

  const handleForecastLeadMonthFormat = a => {
    let b;
    FORECAST_LEAD_TIME_LIST.map(i => {
      if (parseInt(i?.value) === parseInt(a?.category)) {
        b = {
          name: i?.name,
          value: i?.name,
          label: i?.name,
          id: i?.id,
          data: a?.data,
        };
      }
    });
    return b;
  };

  const handleForecastLeadTimeFormat = a => {
    let latest_list;
    let list = JSON.parse(JSON.stringify(FORECAST_LEAD_TIME_LIST));
    let option_list = list?.splice(parseInt(a?.forecast_time_month), 4);
    let new_list = JSON.parse(JSON.stringify(FORECAST_LEAD_TIME_LIST));
    let new_arr = new_list?.splice(parseInt(0), 4 - option_list.length);
    latest_list = option_list.concat(new_arr);
    latest_list?.map((i, index) => {
      i.name = i.name + '(' + `${index + 1} Month` + ')';
      i.indexValue = index + 1;
    });
    return latest_list[parseInt(a?.forecast_lead_time) - 1];
  };

  useEffect(() => {
    if (boundries?.mapPreferenceData) {
      setGetBoundariesData(boundries?.mapPreferenceData?.data?.result);
    }
  }, [boundries?.mapPreferenceData]);

  //handle time series data for iri
  useEffect(() => {
    if (Weather.iriTimeSeriesData) {
      if (Weather.iriTimeSeriesData?.data) {
        let { code } = Weather.iriTimeSeriesData?.data;
        if (code === 200) {
          let { result } = Weather.iriTimeSeriesData?.data;
          const keys = Object.keys(result);
          const parameter = IRI_PARAMETER_SLUG.find(item => item.api_slug === keys[0]);
          if (keys.length === 1 && !_.isEmpty(parameter)) {
            let attribute = keys[0];
            setIRITimeSeriesData(prev => {
              return {
                ...prev,
                [parameter.name]: result[attribute],
              };
            });
          }
          //setIRITimeSeriesData(result)
        } else {
          setIsGraphLoading(false);
          setErrorData({ showError: true, data: 'No records found. please try again' });
        }
      }
    }
    if (Weather.iriTimeSeriesDataError) {
      setIsGraphLoading(false);
      if (Weather.iriTimeSeriesDataError?.data) {
        if (Weather.iriTimeSeriesDataError?.data?.response) {
          let {
            data: { message },
          } = Weather.iriTimeSeriesDataError?.data?.response;
          setErrorData({ showError: true, data: message });
        }
      }
    }
  }, [Weather.iriTimeSeriesData, Weather.iriTimeSeriesDataError]);

  useEffect(() => {
    // let iriParameters = IRI_PARAMETER_SLUG.map(item => item.name);
    // let arr = [];
    if (!_.isEmpty(iriTimeSeriesData)) {
      // arr = iriParameters.map(item => iriTimeSeriesData.hasOwnProperty(item));
      // if (!arr.includes(false)) {
      setIsGraphLoading(false);
      setChartSeriesData(iriTimeSeriesData);
      //}
    }
  }, [iriTimeSeriesData]);

  useEffect(() => {
    if (selectedForecastCompareYearMonth) {
      //setSelectedFinalForercastLeadTime(null);
      let a = selectedForecastCompareYearMonth?.map?.forecastYearMonth.split('-')[1];
      let c = JSON.parse(JSON.stringify(FORECAST_LEAD_TIME_LIST));
      let b = c?.splice(parseInt(a), 4);
      setForecastCompareLeadTimeList(b);
    }
  }, [selectedForecastCompareYearMonth]);

  useEffect(() => {
    if (splitterRef && splitterRef.current) {
      if (errorData?.showError) {
        splitterRef.current.setState({ secondaryPaneSize: '30%' });
      } else {
        splitterRef.current.setState({ secondaryPaneSize: '70%' });
      }
    }
  }, [errorData]);

  useEffect(() => {
    if (getBoundariesData && getBoundariesData?.length) {
      let API_URL = [];
      mapPreferRefs.current = [];
      getBoundariesData?.map(item => {
        if (item?.data && item?.data.length) {
          item.data.map(boundary => {
            if (boundary?.boundary_data_url.length) {
              setGeoJsonData([]);
              setGeoJsonAllData([]);
              let mapPreferKeys = Object.keys(mapPreferences);
              if (mapPreferKeys) {
                mapPreferKeys.map(item => {
                  let selectedLayer = mapPreferences[item];
                  if (selectedLayer?.layer && selectedLayer?.layer?.current)
                    selectedLayer?.layer?.current?.clearLayers();
                  selectedLayer.color = '';
                  setMapPreferences(prev => {
                    return {
                      ...prev,
                      [item]: selectedLayer,
                    };
                  });
                });
              }
              setMapPreferenceGeoJsonloading(true);
              let config = {
                name: boundary?.boundarytype?.slug,
                id: boundary?.boundarytype_id,
                color: boundary?.boundarytype?.color?.code,
                category: boundary?.category,
                country_id: boundary?.country_id,
              };
              if (
                currentUserLocation === DEFAULT_COUNTRY.name &&
                selectedLocation?.country?.name === SOUTH_ASIA_CONST.name &&
                boundary?.boundarytype?.slug === MAP_PREFERENCES_lABELS.COUNTRY
              ) {
                API_URL.push(AXIOS_INSTANCE.get(boundary?.boundary_data_india_url, config));
              } else {
                API_URL.push(AXIOS_INSTANCE.get(boundary?.boundary_data_url, config));
              }
            } else {
              let ref = React.createRef();
              ref.current = {
                boundary_id: boundary?.boundarytype_id,
                layer: React.createRef(),
              };
              mapPreferRefs.current.push(ref);
            }
          });
        }
      });
      Promise.all(API_URL).then(response => {
        let res = JSON.parse(JSON.stringify(response));
        setMapPreferenceGeoJsonloading(false);
        setGeoJsonLayerData(res);
      });
    }
  }, [getBoundariesData]);

  useEffect(() => {
    if (geoJsonLayersData?.length) {
      const countryData123 = geoJsonLayersData?.find(each => each?.config?.name === 'country');

      setCountryJson(countryData123?.data);
    }
  }, [geoJsonLayersData]);

  useEffect(() => {
    let selectedGeoJsonData = [];
    if (geoJsonLayersData?.length && geoJsonData?.length) {
      geoJsonData?.map(item => {
        let filteredObj = geoJsonLayersData?.find(
          layer => layer.config.id === item.id && layer.config.country_id === item.country_id,
        );
        if (!_.isEmpty(filteredObj)) {
          filteredObj.fromMapPreferences = item.fromMapPreferences;
          selectedGeoJsonData.push(filteredObj);
        }
      });
    } else {
      selectedGeoJsonData = [];
    }
    setGeoJsonAllData(selectedGeoJsonData);
  }, [geoJsonLayersData, geoJsonData]);

  useEffect(() => {
    if (selectedForecastYearMonth) {
      //setSelectedForecastLeadTime(null)
      let month = selectedForecastYearMonth?.map?.forecastYearMonth.split('-')[1];
      let list = JSON.parse(JSON.stringify(FORECAST_LEAD_TIME_LIST));
      let option_list = list?.splice(parseInt(month), 4);
      let latest_list;
      if (month >= 9) {
        let new_list = JSON.parse(JSON.stringify(FORECAST_LEAD_TIME_LIST));
        let new_arr = new_list?.splice(parseInt(0), 4 - option_list.length);
        latest_list = option_list.concat(new_arr);
      } else {
        latest_list = option_list;
      }
      latest_list?.map((i, index) => {
        i.name = i.name + '(' + `${index + 1}.0` + ')';
      });
      setForecastLeadTimeList(latest_list);
    }
  }, [selectedForecastYearMonth]);

  useEffect(() => {
    if (selectedForecastCompareYearMonth) {
      //  setSelectedFinalForercastLeadTime(null)
      let month = selectedForecastCompareYearMonth?.map?.forecastYearMonth.split('-')[1];
      let list = JSON.parse(JSON.stringify(FORECAST_LEAD_TIME_LIST));
      let option_list = list?.splice(parseInt(month), 4);
      let latest_list;
      if (month >= 9) {
        let new_list = JSON.parse(JSON.stringify(FORECAST_LEAD_TIME_LIST));
        let new_arr = new_list?.splice(parseInt(0), 4 - option_list.length);
        latest_list = option_list.concat(new_arr);
      } else {
        latest_list = option_list;
      }
      latest_list?.map((i, index) => {
        i.name = i.name + '(' + `${index + 1}.0` + ')';
      });
      setForecastCompareLeadTimeList(latest_list);
    }
  }, [selectedForecastCompareYearMonth]);

  useEffect(() => {
    let search = window.location.search;
    let URLParams = new URLSearchParams(search);
    let paramId;
    let isDefaultSourceId = URLParams.get('isDefaultSelectedSourceId');
    setIsDefaultSelectedSourceId(isDefaultSourceId);
    paramId = URLParams.get('dataSourceId');
    STATIC_RESOURCE_GET_CATEGORY_LIST.map(item => {
      item.data.map(i => {
        if (i.forecast_data_source.id === paramId) {
          if (i.forecast_data_source.type === 'static') {
            setSelectedDataSourceId(paramId);
            setUrlDataSourceId(paramId);
            setHasQueryParam(true);
            // i.forecast_data_source?.name === 'ECMWF (Static)' &&  setStaticResourceSelectedLocation('South Asia')
            setShowEcmwf(!(i.forecast_data_source?.name === 'ECMWF (Static)'));
          } else {
            setHasQueryParam(false);
            setShowEcmwf(true);
            setUrlDataSourceId('');
            setSelectedDataSourceId(paramId);
          }
        }
      });
    });
  }, [window.location.href]);

  useEffect(() => {
    if (nextProps.staticResourceYearData) {
      const {
        data: { result },
      } = nextProps.staticResourceYearData;
      if (result?.length !== 0) {
        let arr = [];
        result?.map(i => {
          arr.push({
            value: i,
            label: i,
          });
        });
        setYearsList(arr);
      }
    }
  }, [nextProps.staticResourceYearData]);

  //global function to compare props
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  useEffect(() => {
    requestBody.group_date = '';
    setRequestBody({ ...requestBody });
    if (hasQueryParam) {
      let req_body = {
        forecast_data_source_id: urlDataSourceId,
      };
      dispatch(staticResourceYearRequest(req_body));
    }
  }, [urlDataSourceId]);

  useEffect(() => {
    if (hasQueryParam) {
      if (
        !_.isEmpty(requestBody?.parameter_id) &&
        !_.isEmpty(requestBody?.forecast_data_source_id) &&
        !_.isEmpty(requestBody?.group_date)
      ) {
        dispatch(staticResourceRequest(requestBody));
      }
    }
  }, [requestBody]);

  useEffect(() => {
    if (hasQueryParam) {
      if (
        JSON.stringify(selectedParam_current?.parameter_id) !==
        JSON.stringify(selectedParam_prev?.parameter_id)
      ) {
        if (
          selectedDataSourceId &&
          selectedParam_current?.parameter_id &&
          requestBody?.group_date
        ) {
          setRequestBody({
            forecast_data_source_id: [selectedDataSourceId],
            parameter_id: [selectedParam_current?.parameter_id],
            group_date: requestBody.group_date,
            order_by: 'start_date',
            page: 1,
          });
        }
      }
    }
  }, [selectedParam_current?.parameter_id, selectedParam_prev?.parameter_id]);

  useEffect(() => {
    if (selectedLocation?.country) {
      setMapPreferencesData([]);
      removeGeoJsonLayer(mapPreferences);
      let selectedLegend = JSON.parse(JSON.stringify(selectedMapPreferLegendData));
      let updatedLegend = [];
      if (selectedLegend.length) {
        updatedLegend = selectedLegend?.map((value, i) => {
          if (value.add) value.add = false;
          return value;
        });

        setSelectedMapPreferLegendData(updatedLegend);
      }
    }
  }, [selectedLocation?.country]);

  //useeffect to reset all the map preferences states data when country gets changed
  useEffect(() => {
    removeMapPreferenceLayer(mapPreferRefs, mapPreferencesData);
    setMapPreferencesData([]);
    let selectedLegend = JSON.parse(JSON.stringify(selectedMapPreferLegendData));
    let compareArr = [];
    // let updatedLegendArray = [];

    let updatedLegend = [];
    if (selectedLegend.length) {
      updatedLegend = selectedLegend?.map((value, i) => {
        if (value.add) value.add = false;
        return value;
      });
      setSelectedMapPreferLegendData(updatedLegend);
    }
    let previousParamRef = '';
    if (selectedParams.length) {
      previousParamRef = layerRef.current[selectedParams[0]?.parameter?.paramIndex];
    }
    if (previousParamRef && previousParamRef.current) {
      removeLayers(previousParamRef);
    }
    let legendArray = [...selectedParamLegendData];
    let compareLegendArray = [...compareParamLegendData];
    if (selectedParamLegendData.length) {
      // updatedLegendArray = legendArray?.map((value,i)=>{
      //   if(value.add) value.add = false;
      //   return value;
      // })
      legendArray.forEach((value, i) => {
        if (value.add) {
          value.add = false;
        }
      });
    }
    if (compareParamLegendData.length) {
      compareArr = compareLegendArray?.map(value => {
        if (value.add) value.add = false;
        return value;
      });
    }
    if (!_.isEmpty(layerControl?.firstLayer)) {
      map?.removeControl(layerControl?.firstLayer);
    }
    if (!_.isEmpty(layerControl?.secondLayer)) {
      map?.removeControl(layerControl?.secondLayer);
    }
    if (!_.isEmpty(mapControl)) {
      map?.removeControl(mapControl);
    }
    setMapControl('');
    setLayerControl({
      firstLayer: {},
      secondLayer: {},
    });
    // setSelectedParamLegendData(updatedLegendArray);
    setSelectedParamLegendData(legendArray);
    setCompareParamLegendData(compareArr);
  }, [selectedRegionId]);

  useEffect(() => {
    if (isOpenWeather) {
      const leafletEle = document.getElementById('leaflet-map');
      if (leafletEle) {
        leafletEle.classList.add('open-weather-polygon-style');
      }
    } else {
      const leafletEle = document.getElementById('leaflet-map');
      if (leafletEle) {
        leafletEle.classList.remove('open-weather-polygon-style');
      }
    }
  }, [isOpenWeather]);

  useEffect(() => {
    if (isCompareClicked && compareDataSources?.length) {
      if (compareParamLegendData?.length && compareParamLegendData?.some(i => i.add)) {
        const leafletEle = document.getElementsByClassName('left-legend-style');

        if (leafletEle && leafletEle[0]) {
          leafletEle[0].classList.add('left-legend-pos-st');
        }
      }
      if (
        selectedMapPreferLegendData?.length &&
        selectedMapPreferLegendData?.some(i => i.add) &&
        compareParamLegendData?.length &&
        compareParamLegendData?.some(i => i.add)
      ) {
        const discreteLeafletEle = document.getElementsByClassName('left-discrete-legend-style');
        if (discreteLeafletEle && discreteLeafletEle[0]) {
          discreteLeafletEle[0].classList.add('left-discrete-legend-pos-st');
        }
      }
    } else if (compareParamLegendData?.every(i => !i.add) && !isCompareClicked) {
      const leafletEle = document.getElementsByClassName('left-legend-style');
      const discreteLeafletEle = document.getElementsByClassName('left-discrete-legend-style');
      if (leafletEle && leafletEle[0]) {
        leafletEle[0].classList.remove('left-legend-pos-st');
      }
      if (discreteLeafletEle && discreteLeafletEle[0]) {
        discreteLeafletEle[0].classList.remove('left-discrete-legend-pos-st');
      }
    }
  }, [isCompareClicked, compareDataSources, compareParamLegendData, selectedMapPreferLegendData]);

  useEffect(() => {
    if (forecastData) {
      forecastData.map(item => {
        let val = {};
        for (let [key, value] of Object.entries(item)) {
          val = value;
          if (val) {
            if (Array.isArray(val)) {
              val[0].name = key;
            } else {
              val.name = key;
            }
          }
        }
        setSelectedParamLegendData([]);
        setWeatherData({
          ...dateSourceWeatherData,
          data: Array.isArray(val) ? val[0] : val,
        });
      });
    }
  }, [forecastData]);

  useEffect(() => {
    if (nextProps.isStaticResourceRequesting) {
      setStaticResourceWeekData(null);
    }
    if (nextProps.StaticResource) {
      const {
        data: { result },
      } = nextProps.StaticResource;
      if (result && result.results && result.results[0]) {
        setStaticResourceWeekData(result.results);
        setStaticResourceSelectedLocation(
          result.results[0].region_name
            ? result.results[0].region_name
            : result.results[0].country_name,
        );
        setStaticResourceImage(result.results[0].upload_image);
        setStaticResourceTitle(result.results[0].forecast_data_source_name);
      } else {
        setStaticResourceImage('');
        setStaticResourceWeekData([]);
        setStaticResourceTitle('');
      }
    } else if (nextProps.staticResourceDataError) {
      setStaticResourceImage('');
      setStaticResourceWeekData([]);
      setStaticResourceTitle('');
    }
  }, [
    nextProps.StaticResource,
    nextProps.staticResourceDataError,
    nextProps.isStaticResourceRequesting,
  ]);

  useEffect(() => {
    if (nextProps.StaticResourceAllDateData) {
      setIsLoading(false);
      const {
        data: { result },
      } = nextProps.StaticResourceAllDateData;
      if (result?.length) {
        setStaticResourceDates(result);
      } else {
        setStaticResourceWeekData([]);
        setStaticResourceDates([]);
      }
    } else {
      setStaticResourceDates([]);
      setIsLoading(false);
    }
  }, [nextProps.StaticResourceAllDateData]);

  // Handle timeseries data for NOAA GFS
  useEffect(() => {
    if (Weather.noaaTimeSeriesDataRequesting) {
      setIsGraphLoading(true);
      setChartSeriesData([]);
    }
    if (Weather.noaaTimeSeriesData) {
      if (Weather.noaaTimeSeriesData.data) {
        let { code } = Weather.noaaTimeSeriesData?.data;
        if (code === 200) {
          let { result } = Weather.noaaTimeSeriesData?.data;
          const keys = Object.keys(result);
          const parameter = NOAA_PARAMETER_SLUG.find(item => item.api_slug === keys[0]);
          if (keys.length === 1 && !_.isEmpty(parameter)) {
            let attribute = keys[0];
            setNoaaTimeSeriesData(prev => {
              return {
                ...prev,
                [parameter.name]: result[attribute],
              };
            });
          }
        } else {
          setIsGraphLoading(false);
          setErrorData({ showError: true, data: 'No records found. please try again' });
        }
      }
    } else if (Weather.noaaTimeSeriesDataError) {
      if (Weather?.noaaTimeSeriesDataError?.data) {
        let noaaData = Weather.noaaTimeSeriesDataError?.data || '';
        if (noaaData?.response) {
          if (noaaData?.response?.data) {
            setErrorData({ showError: true, data: noaaData?.response?.data.message });
          }
        } else {
          setErrorData({ showError: true, data: t('DROUGHT_MONITORING.API_FAILURE_ERROR') });
        }
      }
      setIsGraphLoading(false);
    }
  }, [
    Weather.noaaTimeSeriesDataRequesting,
    Weather.noaaTimeSeriesData,
    Weather.noaaTimeSeriesDataError,
  ]);

  //handle time series data for erpas
  useEffect(() => {
    if (Weather.erpasTimeSeriesData) {
      if (Weather.erpasTimeSeriesData?.data) {
        let { code } = Weather.erpasTimeSeriesData?.data;
        if (code === 200) {
          let { result } = Weather.erpasTimeSeriesData?.data;
          const keys = Object.keys(result);
          const parameter = ERPAS_PARAMETER_SLUG.find(item => item.api_slug === keys[0]);
          if (keys.length === 1 && !_.isEmpty(parameter)) {
            let attribute = keys[0];
            setErpasTimeSeriesData(prev => {
              return {
                ...prev,
                [parameter.name]: result[attribute],
              };
            });
          }
        } else {
          setIsGraphLoading(false);
          setErrorData({ showError: true, data: 'No records found. please try again' });
        }
      }
    }
    if (Weather.erpasTimeSeriesDataError) {
      setIsGraphLoading(false);
      if (Weather.erpasTimeSeriesDataError.data) {
        if (Weather.erpasTimeSeriesDataError.data.response) {
          let {
            data: { message },
          } = Weather.erpasTimeSeriesDataError.data.response;
          setErrorData({ showError: true, data: message });
        } else {
          setErrorData({ showError: true, data: t('DROUGHT_MONITORING.API_FAILURE_ERROR') });
        }
      }
    }
  }, [Weather.erpasTimeSeriesData, Weather.erpasTimeSeriesDataError]);

  useEffect(() => {
    if (
      noaaTimeSeriesData?.Temperature &&
      noaaTimeSeriesData?.Humidity &&
      noaaTimeSeriesData?.Precipitation &&
      noaaTimeSeriesData?.Wind_speed
    ) {
      setIsGraphLoading(false);
      setChartSeriesData(noaaTimeSeriesData);
    }
  }, [noaaTimeSeriesData]);

  useEffect(() => {
    let erpasParameters = ERPAS_PARAMETER_SLUG.map(item => item.name);
    let arr = [];
    if (!_.isEmpty(erpasTimeSeriesData)) {
      arr = erpasParameters.map(item => erpasTimeSeriesData.hasOwnProperty(item));
      if (!arr.includes(false)) {
        setIsGraphLoading(false);
        setChartSeriesData(erpasTimeSeriesData);
      }
    }
  }, [erpasTimeSeriesData]);

  useEffect(() => {
    if (Weather.weatherForecastData) {
      if (Weather.weatherForecastData.data) {
        setIsLoading(false);
        const { result } = Weather.weatherForecastData.data;

        if (!isOpenWeather) {
          setForecastData(result);
        }
        setCompareParamLegendData([]);

        result.map(item => {
          if (item.Open_Weather) {
            const OpenWeather = item.Open_Weather;
            if (Array.isArray(OpenWeather)) {
              if (OpenWeather.some(x => x.cod === '400')) {
                const errorCode = OpenWeather.find(x => x.cod === '400');
                setIsGraphLoading(false);
                //showError(`No record found.${errorCode.message}`);
                setErrorData({ showError: true, data: errorCode?.message });
                setGraphData({
                  ...graphData,
                  OPEN_WEATHER_API: [],
                });
                setChartSeriesData([]);
                return false;
              }
            } else if (OpenWeather?.code) {
              setIsGraphLoading(false);
              //showError(`No record found.${errorCode.message}`);
              setErrorData({ showError: true, data: OpenWeather?.message });
              setGraphData({
                ...graphData,
                OPEN_WEATHER_API: [],
              });
              setChartSeriesData([]);
              return false;
            } else {
              setIsGraphLoading(false);
              setGraphData({
                ...graphData,
                OPEN_WEATHER_API: OpenWeather,
              });
              setChartSeriesData(item.Open_Weather?.hourly);
            }
          }
        });
      }
    } else if (Weather.weatherForecastDataError) {
      if (Weather.weatherForecastDataError) {
        let {
          data: { response },
        } = Weather.weatherForecastDataError;
        if (!_.isEmpty(response)) {
          if (!_.isEmpty(response?.data?.errors)) {
            showError(Object.values(response?.data?.errors)[0]);
          } else {
            showError(response?.data?.message);
          }
        } else {
          setIsGraphLoading(false);
          setErrorData({ showError: true, data: t('DROUGHT_MONITORING.API_FAILURE_ERROR') });
        }
      } else {
        showError('No records found. please try again');
      }

      removeMapLayers(layerControl, compareParamLegendData, selectedParamLegendData);
      setIsLoading(false);
    } else if (Weather.weatherForecastDataNetworkError) {
      setIsLoading(false);
    }
  }, [
    Weather.weatherForecastData,
    Weather.isWeatherForecastRequesting,
    Weather.weatherForecastDataError,
    Weather.weatherForecastDataNetworkError,
  ]);

  useEffect(() => {
    setShowCharts(false);
  }, [
    selectedParam,
    country,
    dataSources,
    initialSelectedDate,
    temporalAggregation,
    range,
    region,
  ]);

  useEffect(() => {
    let selectedLegend = [...selectedParamLegendData];
    let compareLegend = [...compareParamLegendData];
    let previousParamRef = '';
    let updatedCompareLegend = [];
    if (selectedParams.length) {
      previousParamRef = layerRef.current[selectedParams[0]?.parameter?.paramIndex];
    }
    if (selectedLegend.length) {
      if (selectedLegend.length) {
        selectedLegend.forEach(function(value, i) {
          if (value.add) {
            value.add = false;
          }
        });
        removeLegend(selectedParamLegendData);
        const defaultChartTimeRange = renderGenericDateFormat(new Date());
        setSelectedChartTimeRange(defaultChartTimeRange);
      }
    }

    updatedCompareLegend = compareLegend?.map(value => {
      if (value.add) value.add = false;
      return value;
    });
    setCompareParamLegendData(updatedCompareLegend);

    removeLayers(previousParamRef);
    if (!_.isEmpty(layerControl?.firstLayer)) {
      map?.removeControl(layerControl?.firstLayer);
    }
    if (!_.isEmpty(layerControl?.secondLayer)) {
      map?.removeControl(layerControl?.secondLayer);
    }
    if (!_.isEmpty(mapControl)) {
      map?.removeControl(mapControl);
    }
    setMapControl('');
    setLayerControl({
      firstLayer: {},
      secondLayer: {},
    });
    dispatch(getWeatherForecastDataSuccess(''));
    setWeatherData('');
    setParameterData([]);
  }, [dataSources]);

  useEffect(() => {
    if (initialParams && initialParams?.length) {
      if (compareDataSources?.length) {
        if (compareParams && compareParams?.length) {
          handleCommonParameters(compareDataSources, initialParams, compareParams);
        }
      }
    }
  }, [dataSources, initialParams]);

  // compare maps drag feature disabling stopped
  // useEffect(() => {
  //   if (!_.isEmpty(mapControl)) {
  //     mapControl?.on('dividermove', map.dragging.disable());
  //   } else if (!_.isEmpty(map)) {
  //     map?.dragging?.enable();
  //   }
  // }, [mapControl]);

  useEffect(() => {
    if (mapPreferencesData?.length) {
      let MapPrefereArr = [];
      mapPreferencesData?.map(item => {
        let legendItem = JSON.parse(JSON.stringify(item.result));
        legendItem.id = item?.dataSource;
        legendItem.dataSource =
          item.slug === MAP_PREFERENCES_lABELS.JRC
            ? t('WEATHER_FORECAST.MAP_PREFERENCES.WATER_OCCURRENCE')
            : item.slug === MAP_PREFERENCES_lABELS.MOD44
            ? t('WEATHER_FORECAST.MAP_PREFERENCES.PERMANENT_WATER')
            : item?.category;
        legendItem.parameter = item?.legendName;
        legendItem.add = true;
        legendItem.measure = legendItem.units;
        legendItem.isCompare = false;
        legendItem.id = item?.dataSource;
        legendItem.legendId = item.id;
        legendItem.fromMapPreferences = true;
        if (selectedMapPreferLegendData?.length) {
          let isDuplicate = selectedMapPreferLegendData?.find(
            x => x.legendId === item.id && item.add === true,
          );
          if (_.isEmpty(isDuplicate)) {
            MapPrefereArr.push(legendItem);
          }
        } else {
          MapPrefereArr.push(legendItem);
        }
      });
      setSelectedMapPreferLegendData(MapPrefereArr);
    }
  }, [mapPreferencesData]);

  useEffect(() => {
    if (compareDataSources?.length) {
      if (compareParams && compareParams?.length) {
        if (initialParams && initialParams?.length) {
          handleCommonParameters(compareDataSources, compareParams, initialParams);
        }
      }
    }
  }, [compareDataSources, compareParams]);

  useEffect(() => {
    if (layerRef?.current?.length) {
      const currentParamRef = layerRef.current[selectedParam?.parameter?.paramIndex];
      if (!_.isEmpty(currentParamRef) && !_.isEmpty(currentParamRef?.current)) {
        currentParamRef?.current?.eachLayer(layer => {
          setMapLayerOpacity({ layer });
        });
      }
    }
  }, [layerRef?.current]);
  // useEffect(() => {
  //   if (!_.isEmpty(mapLayerOpacity) && !_.isEmpty(mapLayerOpacity?.layer)) {
  //     if (!_.isEmpty(opacityControl)) map?.removeControl(opacityControl);
  //     let customOpacityControl = '';
  //     customOpacityControl = L?.control?.opacity(mapLayerOpacity, {
  //       collapsed: false,
  //       position: 'topright',
  //     });
  //     setOpacityControl(customOpacityControl);
  //     if (!_.isEmpty(map) && !_.isEmpty(map?._layers)) {
  //       if (!_.isEmpty(customOpacityControl)) {
  //         customOpacityControl?.addTo(map);
  //       }
  //     }
  //   }
  // }, [mapLayerOpacity]);

  const removeGeoJsonLayer = mapPreferences => {
    let mapPreferKeys = Object.keys(mapPreferences);
    if (mapPreferKeys) {
      mapPreferKeys.map(item => {
        let selectedLayer = mapPreferences[item];
        if (selectedLayer?.layer && selectedLayer?.layer?.current)
          selectedLayer?.layer?.current?.clearLayers();
        selectedLayer.color = '';
        setMapPreferences(prev => {
          return {
            ...prev,
            [item]: selectedLayer,
          };
        });
      });
    }
  };

  const getMapPreferencesGeojsonData = geoJsonList => {
    let data = JSON.parse(JSON.stringify(geoJsonList));
    removeGeoJsonLayer(mapPreferences);
    setGeoJsonData(data);
  };

  const handleCommonParameters = (compareSource, comparePar, param) => {
    if (compareSource?.length) {
      if (comparePar && comparePar?.length) {
        if (param && param?.length) {
          let paramArr = [];
          let commonParam = JSON.parse(JSON.stringify(param[0]));
          commonParam.parameterData = [];
          param[0]?.parameterData?.map(item => {
            comparePar[0]?.parameterData?.map(compareItem => {
              let filteredObj = JSON.parse(JSON.stringify(item));
              const filteredParams = item?.parameter.filter(o1 =>
                compareItem?.parameter.some(o2 => o1.parameter_id === o2.parameter_id),
              );
              if (filteredParams?.length) {
                filteredObj.parameter = filteredParams;
                commonParam?.parameterData?.push(filteredObj);
              }
            });
          });
          paramArr.push(commonParam);
          setParams(paramArr);
        }
      }
    }
  };

  const handleMapModel = () => {
    setIsOpenMapModel(!isOpenMapModel);
  };

  useEffect(() => {
    let selectedParamArr = selectedParams;
    let legendArray = selectedParamLegendData;
    let compareLegendArray = _.cloneDeep(compareParamLegendData);
    if (dateSourceWeatherData?.data) {
      const { data } = dateSourceWeatherData;
      let legendObj = {};
      let compareObj = {};
      let selectedParamItem = '';
      let opacityControl = '';
      if (selectedParamArr[0] && !_.isEmpty(selectedParamArr[0])) {
        selectedParamItem = selectedParamArr[0]?.parameter;
      }
      let previousParamRef = layerRef.current[selectedParamItem?.paramIndex];
      const currentParamRef = layerRef.current[selectedParam?.parameter?.paramIndex];
      let item = _.cloneDeep(selectedParam);
      if (item?.parameter?.parameter_type) {
        let arr1 = [];
        let updatedName = '';
        const [first, ...rest] = item?.parameter?.name?.split(' ');
        arr1?.push(first);

        if (arr1?.length) {
          arr1?.push(item?.parameter?.parameter_type);
        }
        updatedName = arr1?.concat(rest)?.join(' ');
        item.parameter.name = updatedName;
      }
      const paramName = item?.parameter?.name;
      let legendData = {};
      if (Array.isArray(data)) {
        if (data[0]?.map_data?.code === 400 || data[1]?.map_data?.code === 400) {
          const filteredMapData = data.find(i => i?.map_data?.code === 400);
          showError(filteredMapData?.map_data?.message);
          return false;
        }
        compareObj = data[0]?.map_data;
        compareObj.measure = data[0]?.map_data?.units;
        compareObj.dataSource = dataSources[0]?.name;
        compareObj.name = paramName;
        compareObj.parameter =
          DATA_SOURCES?.IRI.id === dataSources[0]?.id ? DATA_SOURCES?.IRI.parameterName : paramName;
        compareObj.add = true;
        compareObj.isCompare = true;
        compareObj.id = paramName;
        compareObj.fromMapPreferences = false;
        if (DATA_SOURCES?.IRI.id === dataSources[0]?.id) {
          const iriDateRanger = iriDate(IRIForecastTime, IRIAvailableLeadTimeList);
          compareObj.selectedDates = {
            startDate: iriDateRanger?.startDate,
            endDate: iriDateRanger?.endDate,
          };
          compareObj.forecastMonth = IRIForecastTime?.month?.value;
        } else {
          compareObj.selectedDates = {
            startDate: moment(initialSelectedDate.map.startDate).format('DD-MM-YYYY'),
            endDate: moment(initialSelectedDate.map.endDate).format('DD-MM-YYYY'),
          };
        }
        legendObj = data[1]?.map_data;
        legendObj.measure = data[1]?.map_data?.units;
        legendObj.dataSource = compareDataSources[0]?.name;
        legendObj.name = paramName;
        legendObj.parameter =
          DATA_SOURCES?.IRI.id === compareDataSources[0]?.id
            ? DATA_SOURCES?.IRI.parameterName
            : paramName;
        legendObj.isCompare = false;
        legendObj.id = paramName;
        legendObj.fromMapPreferences = false;

        if (currentParamRef.current) {
          currentParamRef.current._url = data[0].map_data.map_url;
          currentParamRef.current._url1 = data[1].map_data.map_url;
          if (previousParamRef?.current?._url1?.length) {
            if (!_.isEmpty(layerControl?.firstLayer)) {
              map?.removeControl(layerControl?.firstLayer);
            }
            if (!_.isEmpty(layerControl?.secondLayer)) {
              map?.removeControl(layerControl?.secondLayer);
            }
            if (!_.isEmpty(mapControl)) {
              map?.removeControl(mapControl);
            }
            legendArray.forEach((value, i) => {
              if (value.add) value.add = false;
            });
          }
          if (currentParamRef.current._url1) {
            const firstLayer = L.tileLayer(currentParamRef.current._url);
            firstLayer.addTo(map);
            const secondLayer = L.tileLayer(currentParamRef.current._url1);
            secondLayer.addTo(map);

            let updatedIndexLayer = [...indexLayers];
            updatedIndexLayer.push(firstLayer);
            updatedIndexLayer.push(secondLayer);
            setIndexLayers(updatedIndexLayer);

            const control = L.control.sideBySide(firstLayer, secondLayer);
            setLayerControl({ firstLayer, secondLayer });
            setMapControl(control);
            control.addTo(map);
          }
        }
      } else {
        let map_data = '';
        if (data?.hasOwnProperty('map_url')) {
          map_data = data;
        } else if (data?.map_data && !_.isEmpty(data.map_data)) {
          map_data = data?.map_data;
        } else if (data?.code === 400) {
          showError(data?.message);
          return false;
        }
        if (map_data.code === 400) {
          if (map_data?.message) {
            showError(map_data.message);
          } else {
            showError('No record found . Please select different date range');
          }
          return false;
        }
        legendObj = { ...map_data };
        legendObj.name = paramName;
        legendObj.parameter =
          DATA_SOURCES?.IRI.id === dataSources[0]?.id ? DATA_SOURCES?.IRI.parameterName : paramName;
        legendObj.measure = map_data?.units;
        legendObj.dataSource = dataSources[0]?.name;
        legendObj.isCompare = false;
        legendObj.id = paramName;
        legendObj.fromMapPreferences = false;

        if (DATA_SOURCES?.IRI.id === dataSources[0]?.id) {
          const iriDateRanger = iriDate(IRIForecastTime, IRIAvailableLeadTimeList);
          legendObj.selectedDates = {
            startDate: iriDateRanger?.startDate,
            endDate: iriDateRanger?.endDate,
          };
          legendObj.forecastMonth = IRIForecastTime?.month?.value;
        } else {
          legendObj.selectedDates = {
            startDate: moment(initialSelectedDate.map.startDate).format('DD-MM-YYYY'),
            endDate: moment(initialSelectedDate.map.endDate).format('DD-MM-YYYY'),
          };
        }

        if (map?.opacityControl) {
          map.removeControl(opacityControl);
        }
        // TODO: need to change looping through layers
        if (currentParamRef && currentParamRef.current) {
          currentParamRef.current?.eachLayer(layer => {
            if (map_data) {
              layer.setUrl(map_data?.map_url);
              let updatedIndexLayer = [...indexLayers];
              updatedIndexLayer.push(layer);
              setMapUrl({ name: selectedParam?.parameter?.name, url: map_data?.map_url, layer });
              setMapLayerOpacity({
                layer,
              });
              setIndexLayers(updatedIndexLayer);
            }
          });
        }
      }
      //need to optimize this redundant part
      if (!_.isEmpty(selectedParam)) {
        const index = legendArray.findIndex(
          item => item.name === paramName && item.dataSource === legendObj.dataSource,
        );
        if (!_.isEmpty(legendObj)) {
          if (!selectedParamArr.length || index === -1) {
            legendData = legendObj;
            legendData.add = true;
          } else if (index !== -1) {
            for (let x of legendArray) {
              if (x.name === paramName) {
                if (x.hasOwnProperty('add') && x.add === false) {
                  if (x.dataSource === legendObj.dataSource) {
                    x.add = true;
                  }
                }
              }
            }
          }

          if (selectedParamArr.length) {
            if (
              (compareDataSources.length && compareDataSources[0].name === legendObj.dataSources) ||
              selectedParamItem?.dataSource === legendObj.dataSource
            )
              if (legendArray.length) {
                for (let x of legendArray) {
                  if (x.name !== paramName) {
                    x.add = false;
                  }
                }
              }
            selectedParamArr = [];
          }
        }
        removeLayers(previousParamRef);
        if (Array.isArray(data)) {
          if (selectedParamArr[0]?.dataSource === legendObj.dataSource) {
            addLayers(currentParamRef);
          }
        } else {
          if (selectedParam?.dataSource === legendObj.dataSource) {
            addLayers(currentParamRef);
          }
        }

        selectedParamArr.push(selectedParam);

        if (!_.isEmpty(legendData)) {
          legendArray.push(legendData);
          // setSelectedParamLegendData(legendArray);
        }
        if (!_.isEmpty(compareObj)) {
          compareLegendArray.push(compareObj);
          // setCompareParamLegendData(compareLegendArray);
        }
        if (!_.isEmpty(compareParams)) {
          legendArray.map(item => {
            if (item.add == true) {
              if (DATA_SOURCES?.IRI.id === compareDataSources[0]?.id) {
                const iriDateRanger = iriDate(
                  compareIRIForecastTime,
                  compareIRIAvailableLeadTimeList,
                );
                item.selectedDates = {
                  startDate: iriDateRanger?.startDate,
                  endDate: iriDateRanger?.endDate,
                };
                item.forecastMonth = compareIRIForecastTime?.month?.value;
              } else {
                item.selectedDates = {
                  startDate: moment(finalSelectedDate.startDate).format('DD-MM-YYYY'),
                  endDate: moment(finalSelectedDate.endDate).format('DD-MM-YYYY'),
                };
              }
            }
          });
        }

        setSelectedParamLegendData(legendArray);
        setCompareParamLegendData(compareLegendArray);
        setSelectedParamLegend(legendArray);
        setSelectedParams(selectedParamArr);
      }
    }
  }, [dateSourceWeatherData]);

  const clearDatesHandler = () => {
    setStaticResourceDates([]);
  };

  const removeMapLayers = (layerControl, compareParamLegendData, selectedParamLegendData) => {
    if (!_.isEmpty(layerControl?.firstLayer)) {
      map?.removeControl(layerControl?.firstLayer);
    }
    if (!_.isEmpty(layerControl?.secondLayer)) {
      map?.removeControl(layerControl?.secondLayer);
    }
    if (!_.isEmpty(mapControl)) {
      map?.removeControl(mapControl);

      setMapControl('');
      setLayerControl({
        firstLayer: {},
        secondLayer: {},
      });
    }

    let previousParamRef = '';
    if (selectedParams.length) {
      previousParamRef = layerRef.current[selectedParams[0]?.parameter?.paramIndex];
    }
    if (previousParamRef && previousParamRef.current) {
      removeLayers(previousParamRef);
    }

    let compareArray = [...compareParamLegendData];
    let updatedCompareArray = [];
    if (compareArray?.length) {
      updatedCompareArray = compareArray?.map(value => {
        if (value.add) value.add = false;
        return value;
      });
    }
    setCompareParamLegendData(updatedCompareArray);
    removeLegend(selectedParamLegendData);
  };

  const handleDataSourceId = id => {
    setSelectedParamDataSourceId(id);
  };

  const handleParameters = paramsList => {
    let selectedParamArr = selectedParams;
    let legendArray = selectedParamLegendData;
    let compareArray = _.cloneDeep(compareParamLegendData);
    let updatedCompareArray = [];
    let selectedParamItem = '';
    if (selectedParamArr[0] && !_.isEmpty(selectedParamArr[0])) {
      selectedParamItem = selectedParamArr[0]?.parameter;
    }
    const previousParamRef = layerRef.current[selectedParamItem?.paramIndex];

    removeLayers(previousParamRef);
    if (legendArray.length) {
      // updatedParamArray = legendArray?.map((value,i)=>{
      //   value.add = false;
      //   return value;
      // })
      for (let x of legendArray) {
        //if (x.name !== paramName) {
        x.add = false;
        //}
      }
    }
    if (compareArray.length) {
      updatedCompareArray = compareArray?.map(value => {
        if (value.add) value.add = false;
        return value;
      });
    }
    if (!_.isEmpty(layerControl?.firstLayer)) {
      map?.removeControl(layerControl?.firstLayer);
    }
    if (!_.isEmpty(layerControl?.secondLayer)) {
      map?.removeControl(layerControl?.secondLayer);
    }
    if (!_.isEmpty(mapControl)) {
      map?.removeControl(mapControl);
      setMapControl('');
      setLayerControl({
        firstLayer: {},
        secondLayer: {},
      });
    }
    if (!_.isEmpty(paramsList)) {
      selectedParamArr.push(paramsList);
      setSelectedParam(paramsList);
      setSelectedParams(selectedParamArr);
      // setSelectedParamLegend(updatedParamArray);
      // setSelectedParamLegendData(updatedParamArray);
      setSelectedParamLegend(legendArray);
      setSelectedParamLegendData(legendArray);
      setCompareParamLegendData(updatedCompareArray);
    } else {
      setSelectedParam({});
      setSelectedParams([]);
    }
  };

  function ChangeView({ center, zoom }) {
    const mapEvents = useMapEvents({
      zoomend: () => {
        setCordinateAndZoom(prev => {
          return {
            ...prev,
            zoom: mapEvents.getZoom(),
            cordinates: mapEvents.getCenter(),
          };
        });
      },
      dragend: () => {
        setCordinateAndZoom(prev => {
          return {
            ...prev,
            zoom: mapEvents.getZoom(),
            cordinates: mapEvents.getCenter(),
          };
        });
      },
    });

    const map = useMap();
    setInterval(function() {
      if (!_.isEmpty(map) && !_.isEmpty(map?._layers)) map.invalidateSize();
    }, 500);
    if (zoom && center) map.setView(center, zoom);
    return null;
  }

  const handleCordinates = (cordinates, zoom) => {
    let zoom_level = zoom || cordinateAndZoom?.zoom;
    // setSearchMarker([cordinates?.[0], cordinates?.[1]]);
    setCordinateAndZoom(prev => {
      return {
        ...prev,
        locationCordinates: cordinates,
        cordinates,
        zoom: zoom_level,
      };
    });
  };

  const handleSelectedDefaultYear = selectedValue => {
    if (selectedValue !== selectedYear) {
      setSelectedYear(selectedValue);
      setSelectedDate();
      setStaticResourceWeekData();
    }
    setSelectedYear(selectedValue);
    if (hasQueryParam) {
      if (selectedParam?.forecast_data_source_id === selectedDataSourceId) {
        if (selectedParam?.parameter_id && selectedDataSourceId && selectedValue) {
          dispatch(
            staticResourceAllDateRequest({
              forecast_data_source_id: selectedDataSourceId,
              year: selectedValue,
            }),
          );
        }
      }
    }
  };

  const setParamData = ({ values, fromCompare }) => {
    layerRef.current = [];
    let arr = [];
    arr.push(values);
    if (!_.isEmpty(arr)) {
      let categoriedArr = renderParamList(arr);
      let updatedCategoriedArr = _.cloneDeep(categoriedArr);
      if (dataSources?.length && dataSources[0]?.name === DATA_SOURCES.ERPAS.name) {
        updatedCategoriedArr = categoriedArr?.map(i => {
          let item = _.cloneDeep(i);
          if (i?.parameter?.parameter_type) {
            let arr1 = [];
            let updatedName = '';
            const [first, ...rest] = i?.parameter?.name?.split(' ');
            arr1?.push(first);

            if (arr1?.length) {
              arr1?.push(i?.parameter?.parameter_type);
            }
            updatedName = arr1?.concat(rest)?.join(' ');
            item.parameter.name = updatedName;
          }
          return item;
        });
      }
      setCategoryParams(updatedCategoriedArr);
      categoriedArr?.map(item => {
        const ref = React.createRef();
        layerRef.current.push(ref);
      });
    }
    if (hasQueryParam && arr?.[0]?.value !== 'ECMWF (Static)') {
      setStaticResourceParams(arr);
    } else {
      setStaticResourceParams([]);
    }
    if (!_.isEmpty(values) && arr?.[0]?.value !== 'ECMWF (Static)') {
      setInitialParams(arr);
      if (fromCompare || _.isEmpty(params) || params[0]?.id !== arr[0]?.id) {
        setParams(arr);
      }
    } else {
      setInitialParams([]);
      setParams([]);
    }
  };
  const setCompareParam = (values, firstDataSource) => {
    let arr = [];
    if (!_.isEmpty(values)) {
      arr.push(values);
    }
    if (!_.isEmpty(arr)) {
      let categoriedArr = renderParamList(arr);
      setCategoryCompareParams(categoriedArr);
    } else {
      setCategoryCompareParams([]);
      setCompareParams([]);
      setParamData({ values: firstDataSource, fromCompare: true });
    }
    setCompareParams(arr);
  };

  const forecastLeadTimeHandler = e => {
    IRIForecastTime.lead_time = e;
    setIRIForecastTime({ ...IRIForecastTime });
  };

  const handleSelectedFilterRegion = id => {
    setSelectedRegionId(id);
  };

  const removeMapPreferenceLayer = (mapPreferRefs, mapPreferencesData) => {
    if (mapPreferRefs?.current?.length && mapPreferencesData?.length) {
      mapPreferencesData?.map(item => {
        let selectedMapIndex = _.findIndex(
          mapPreferRefs.current,
          list => list.current.boundary_id === item.id,
        );
        let previousParamRef = mapPreferRefs?.current[selectedMapIndex].current.layer;
        if (previousParamRef && previousParamRef?.current) {
          removeLayers(previousParamRef);
        }
      });
    }
  };

  const handleCountry = name => {
    removeMapPreferenceLayer(mapPreferRefs, mapPreferencesData);
    removeMapLayers(layerControl, compareParamLegendData, selectedParamLegendData);
    setCountry(name);
    setSelectedState('');
    setSelectedDistrict('');
  };

  const handleSelectedDate = data => {
    setSelectedDate(data);
    let reqBody = {
      forecast_data_source_id: [],
      parameter_id: [],
      group_date: '',
      order_by: 'start_date',
      page: 1,
    };
    if (
      selectedDataSourceId === 'e2f886a7-3299-4c82-af2e-123fe92faf88' ||
      selectedDataSourceId === 'e2f886a7-3299-4c82-af2e-123fe92faf87'
    ) {
      reqBody.group_date = data;
    } else {
      reqBody.group_date = data ? moment(data, 'DD-MM-YYYY').format('YYYY-MM-DD') : '';
    }
    if (selectedParam?.forecast_data_source_id === selectedDataSourceId) {
      if (selectedParam?.parameter_id && selectedDataSourceId && selectedYear) {
        setRequestBody({
          forecast_data_source_id: [selectedDataSourceId],
          parameter_id: [selectedParam?.parameter_id],
          group_date: reqBody.group_date,
          order_by: 'start_date',
          page: 1,
        });
      }
    }
  };

  const handleProps = props => {
    if (props?.temporalAggregation) {
      setTemporalAggregation(props?.temporalAggregation);
    }
    if (props?.range) {
      if (props?.range.min !== null) setRange(props?.range);
    }
    if (props?.compareDataSource) {
      setCompareDataSources(props?.compareDataSource);
    } else {
      setCompareDataSources([]);
    }
    if (props?.dataSources?.length) {
      if (props?.dataSources[0]?.name !== dataSources[0]?.name) {
        setDataSources(props?.dataSources);
      }
    }
    if (props?.initialSelectedDate) {
      setInitialSelectedDate(props?.initialSelectedDate);
    }
    if (props?.finalSelectedDate) {
      setFinalSelectedDate(props?.finalSelectedDate);
    }
  };

  const handleRegionId = value => {
    setRegionId(value);
  };

  const handleSelectedDataSourceId = value => {
    setSelectedDataSourceId(value);
  };

  const handleChartDisplay = () => {
    setShowCharts(!showCharts);
  };

  const setMapReference = mapInstance => {
    mapRef.current = mapInstance;
    setMap(mapInstance);
  };

  const addLegend = paramLegendData => {
    if (paramLegendData?.length) {
      return paramLegendData?.map((item, index) => {
        return (
          <>
            {item?.descret_legend ? (
              <DiscreteLegend
                key={index}
                map={map}
                add={item.add}
                layer={item}
                position="bottomleft"
              />
            ) : (
              <CustomLegend
                map={map}
                add={item.add}
                layer={item}
                position={item?.isCompare ? 'bottomleft' : 'bottomright'}
              />
            )}
          </>
        );
      });
    }
  };

  const removeLegend = paramLegend => {
    let selectedLegend = JSON.parse(JSON.stringify(paramLegend));
    if (selectedLegend.length) {
      if (selectedLegend.length) {
        selectedLegend.forEach(function(value) {
          if (value.add) {
            value.add = false;
          }
        });
        setSelectedParamLegendData(selectedLegend);
      }
    }
  };

  const addLayers = selectedParamRef => {
    if (mapRef && selectedParamRef) {
      if (mapRef && mapRef.current && selectedParamRef.current) {
        const leafletMapRef = mapRef.current;
        const firstLayer = selectedParamRef.current;
        [firstLayer].forEach(layer => {
          leafletMapRef.addLayer(layer);
        });
      }
    }
    return false;
  };

  const removeLayers = selectedParamRef => {
    if (mapRef && mapRef?.current && selectedParamRef && selectedParamRef?.current) {
      const map = mapRef.current;
      const firstLayer = selectedParamRef.current;
      [firstLayer].forEach(layer => map.removeLayer(layer));
    }
  };

  const getMapPreferencesData = mapPreferencesLayerData => {
    /** removing previously added map preferences layers before setting the state */
    removeMapPreferenceLayer(mapPreferRefs, mapPreferencesData);
    setIndexLayers([]);
    /** setting map preferences state*/
    let arr = JSON.parse(JSON.stringify(mapPreferencesLayerData));
    let paramData = '';
    let paramArr = '';
    if (selectedMapPreferLegendData?.length) {
      paramData = JSON.parse(JSON.stringify(selectedMapPreferLegendData));
      paramData?.map((item, index) => {
        if (item.add) {
          let legendItem = { ...item };
          legendItem.add = false;
          paramData[index] = legendItem;
        }
      });
      paramArr = [...paramData];
    }

    setSelectedMapPreferLegendData(paramArr);
    setMapPreferencesData(arr);
  };

  const handleAdvancedSearch = props => {
    if (props.hasOwnProperty('country')) {
      removeMapPreferenceLayer(mapPreferRefs, mapPreferencesData);
      setCountry(props.country);
    }
    if (props.hasOwnProperty('state')) {
      setSelectedState(props.state);
    }
    if (props.hasOwnProperty('district')) {
      setSelectedDistrict(props.district);
    }
  };

  const handleCompareSearch = props => {
    let country = '';
    let state = '';
    let district = '';
    if (props.hasOwnProperty('state')) {
      state = props?.state;
      setCompareSearch(prev => {
        return {
          ...prev,
          state,
        };
      });
    }
    if (props.hasOwnProperty('district')) {
      district = props?.district;
      setCompareSearch(prev => {
        return {
          ...prev,
          district,
        };
      });
    }
    if (props.hasOwnProperty('country')) {
      country = props?.country;
      setCompareSearch(prev => {
        return {
          ...prev,
          country,
        };
      });
    }
  };

  const stateValue = data => {
    setStateData(data);
  };

  const handleDefaultDataSource = isDefault => {
    if (isDefault !== isDefaultDataSource) {
      setIsDefaultDataSource(isDefault);
    }
  };

  const handleSelectedLocation = location => {
    setSelectedLocation(location);
  };

  const handleSelectedRegion = reg => {
    let mapLayerData = [];
    let polyCount = 1;
    let mapData = JSON.parse(JSON.stringify(reg));
    if (mapData?.length) {
      mapData.map(item => {
        let x = Object.assign(item, {});
        if (item.layerType === 'polygon') {
          x.latlngs = item.latlngs.map(v => [v[1], v[0]]);
          x?.latlngs?.push(item.latlngs[0]);
          if (!x.hasOwnProperty('polygonCount')) {
            x.polygonCount = polyCount;
            polyCount += 1;
          }
          mapLayerData.push(x);
        } else {
          x.latlngs = [item.latlngs[1], item.latlngs[0]];
          mapLayerData.push(x);
        }
      });
    }
    if (reg?.length) {
      if (reg?.length === 1 && reg[0].layerType === 'marker') {
        let cordinates = reg[0]?.latlngs;
        setCordinateAndZoom(prev => {
          return {
            ...prev,
            markerCordinates: cordinates,
          };
        });
      } else {
        setCordinateAndZoom(prev => {
          return {
            ...prev,
            markerCordinates: [],
          };
        });
      }
    } else {
      setCordinateAndZoom(prev => {
        return {
          ...prev,
          markerCordinates: [],
        };
      });
    }
    setRegion(mapLayerData);
  };

  const renderCordinates = cordinates => {
    let arr = [];
    if (Array.isArray(cordinates)) {
      arr = cordinates;
    } else {
      arr[0] = cordinates?.lat;
      arr[1] = cordinates?.lng;
    }
    return arr;
  };

  const handleForecastData = () => {
    let requestObj = {};
    if (
      initialSelectedDate['map'].startDate &&
      initialSelectedDate['map'].endDate &&
      dataSources.length &&
      !_.isEmpty(selectedParam)
    ) {
      const cordinates = renderCordinates(cordinateAndZoom?.locationCordinates);
      requestObj.latitude = cordinates[0];
      requestObj.longitude = cordinates[1];

      const forecastData = dataSources?.map(item => item.id);

      requestObj.place_name =
        selectedDistrict || selectedState || (country !== SOUTH_ASIA_CONST.name ? country : '');

      if (
        selectedRegionId &&
        !_.isEmpty(country) &&
        _.isEmpty(selectedState) &&
        _.isEmpty(selectedDistrict)
      )
        requestObj.region_id = selectedRegionId;

      if (!_.isEmpty(country) && country !== SOUTH_ASIA_CONST.name)
        requestObj.country_name = country;

      if (!_.isEmpty(selectedState)) requestObj.state_name = selectedState;

      if (!_.isEmpty(selectedDistrict)) requestObj.district_name = selectedDistrict;

      requestObj.parameter_id = selectedParam.parameter_id;
      requestObj.parameter_name = selectedParam.parameter?.name?.toLowerCase();

      requestObj.data_sources = forecastData;

      let initialRequestObj = Object.assign({}, requestObj);
      let finalRequestObj = Object.assign({}, requestObj);

      if (compareDataSources?.length && finalSelectedDate.startDate) {
        const compareData = compareDataSources?.map(item => item.id);

        initialRequestObj.start_date = initialSelectedDate['map'].startDate;
        initialRequestObj.end_date = initialSelectedDate['map'].endDate;
        initialRequestObj.data_sources = forecastData;

        finalRequestObj.start_date = finalSelectedDate?.startDate;
        finalRequestObj.end_date = finalSelectedDate?.endDate;
        finalRequestObj.data_sources = compareData;

        let layer = OPEN_WEATHER_PARAMETERS_CONST.find(
          layerObj => layerObj.name === selectedParam?.parameter?.name,
        );
        let compareArray = [...compareParamLegendData];
        let updatedCompareArray = [];
        if (compareArray?.length) {
          updatedCompareArray = compareArray?.map(value => {
            if (value.add) value.add = false;
            return value;
          });
        }
        setCompareParamLegendData(updatedCompareArray);
        removeLegend(selectedParamLegendData);
        let initialRequestResponse;
        let finalRequestResponse;
        if (dataSources[0]?.id === DATA_SOURCES.OPEN_WEATHER.id) {
          initialRequestResponse = handleOpenWeatherCompareRes(layer);
        } else {
          removeLegend(selectedParamLegendData);
          initialRequestObj.temporal_aggregation = temporalAggregation.value;
          if (range.min) initialRequestObj.min = range.min;
          if (range.max) initialRequestObj.max = range.max;
          if (dataSources[0]?.id === DATA_SOURCES.IRI.id) {
            if (IRIForecastTime?.year && IRIForecastTime?.month) {
              initialRequestObj.forecast_time_year = IRIForecastTime?.year?.value;
              initialRequestObj.forecast_time_month =
                IRIForecastTime?.month?.id < 9
                  ? '0' + IRIForecastTime?.month?.id
                  : IRIForecastTime?.month?.id;
            }
            if (IRIForecastTime?.lead_time) {
              initialRequestObj.forecast_lead_time = parseInt(
                IRIForecastTime?.lead_time?.value?.substr(
                  IRIForecastTime?.lead_time?.value?.indexOf('(') + 1,
                  IRIForecastTime?.lead_time?.value?.indexOf(')') -
                    IRIForecastTime?.lead_time?.value?.indexOf('(') -
                    1,
                ),
              );
            }
          }

          setIsLoading(true); // AXIOS_INSTANCE_MASTER_SERVER
          initialRequestResponse = dataSources[0]?.master_server
            ? AXIOS_INSTANCE_MASTER_SERVER.post(
                `${MASTER_SERVER_BASE_URL}/${dataSources[0]?.api_url}/map`,

                initialRequestObj,
              )
            : AXIOS_INSTANCE.post(WEATHER_FORECAST_DATA_URL, initialRequestObj);
        }

        if (compareDataSources[0].id === DATA_SOURCES.OPEN_WEATHER.id) {
          finalRequestResponse = handleOpenWeatherCompareRes(layer);
        } else {
          finalRequestObj.temporal_aggregation = temporalAggregation.value;
          if (range.min) finalRequestObj.min = range.min;
          if (range.max) finalRequestObj.max = range.max;
          setIsLoading(true);
          if (compareDataSources[0].id === DATA_SOURCES.IRI.id) {
            if (compareIRIForecastTime?.year && compareIRIForecastTime?.month) {
              finalRequestObj.forecast_time_year = compareIRIForecastTime?.year?.value;
              finalRequestObj.forecast_time_month =
                compareIRIForecastTime?.month?.id < 9
                  ? '0' + compareIRIForecastTime?.month?.id
                  : compareIRIForecastTime?.month?.id;
            }
            if (compareIRIForecastTime?.lead_time) {
              finalRequestObj.forecast_lead_time = parseInt(
                compareIRIForecastTime?.lead_time?.value?.substr(
                  compareIRIForecastTime?.lead_time?.value?.indexOf('(') + 1,
                  compareIRIForecastTime?.lead_time?.value?.indexOf(')') -
                    compareIRIForecastTime?.lead_time?.value?.indexOf('(') -
                    1,
                ),
              );
            }
          }

          finalRequestResponse = compareDataSources[0]?.master_server
            ? AXIOS_INSTANCE_MASTER_SERVER.post(
                `${MASTER_SERVER_BASE_URL}/${compareDataSources[0]?.api_url}/map`,

                finalRequestObj,
              )
            : AXIOS_INSTANCE.post(WEATHER_FORECAST_DATA_URL, finalRequestObj);
        }
        const responseData = [];
        let isError = false;
        Promise.all([initialRequestResponse, finalRequestResponse])
          .then(responses => {
            setIsLoading(false);
            responses.map(item => {
              const { data } = item;
              data?.result.map(res => {
                let val = {};
                for (let [key, value] of Object.entries(res)) {
                  if (value && Array.isArray(value)) {
                    if (value[0]?.map_data.code === 200) {
                      val = value;
                      val[0].name = key;
                    } else if (value[0]?.map_data.code === 400) {
                      showError(value[0]?.map_data?.message);
                      isError = true;
                      return false;
                    }
                  } else {
                    if (value?.map_data.code === 200) {
                      val = value;
                      val.name = key;
                    } else if (value?.map_data.code === 400) {
                      showError(value?.map_data?.message);
                      isError = true;
                      return false;
                    }
                  }
                }
                if (!isError) responseData.push(val[0]);
              });
            });
            if (!isError) {
              setWeatherData({
                ...dateSourceWeatherData,
                data: responseData,
              });
            }
          })
          .catch(error => {
            setIsLoading(false);
            if (error?.response) {
              let errorRes = error.response;
              if (!_.isEmpty(errorRes?.data?.errors)) {
                showError(Object.values(errorRes?.data?.errors)[0]);
              } else {
                showError(errorRes?.data?.message);
              }
            } else {
              showError('No records found. please try again');
            }
            return;
          });
      } else {
        requestObj.start_date = initialSelectedDate['map'].startDate;
        requestObj.end_date = initialSelectedDate['map'].endDate;
        if (dataSources[0]?.id !== DATA_SOURCES.OPEN_WEATHER.id) {
          if (range.min) requestObj.min = range.min;
          if (range.max) requestObj.max = range.max;
          removeLegend(selectedParamLegendData);
          /** dispatching map request for GEE dataSources */
          requestObj.temporal_aggregation = temporalAggregation.value;
          if (dataSources[0]?.id === DATA_SOURCES.IRI.id) {
            requestObj.forecast_time_year = IRIForecastTime?.year?.value;
            requestObj.forecast_time_month =
              IRIForecastTime?.month?.id < 9
                ? '0' + IRIForecastTime?.month?.id
                : IRIForecastTime?.month?.id;
            requestObj.forecast_lead_time = parseInt(
              IRIForecastTime?.lead_time?.value?.substr(
                IRIForecastTime?.lead_time?.value?.indexOf('(') + 1,
                IRIForecastTime?.lead_time?.value?.indexOf(')') -
                  IRIForecastTime?.lead_time?.value?.indexOf('(') -
                  1,
              ),
            );
          }

          setIsLoading(true);

          dispatch(
            getWeatherForecastData({
              obj: requestObj,
              isMasterServer: dataSources?.[0]?.master_server,
              apiUrl: `${dataSources?.[0]?.api_url}/map`,
            }),
          );
          setIsDefaultDataSource(false);
        }
      }
      setApplyLayers(true);
    }
  };

  const handleUpdateTimeSeries = async () => {
    let requestObj = {};
    if (
      !_.isEmpty(cordinateAndZoom.locationCordinates) &&
      initialSelectedDate['graph'].startDate &&
      initialSelectedDate['graph'].endDate &&
      dataSources.length &&
      !_.isEmpty(selectedParam)
    ) {
      setErrorData({ showError: false, data: '' });
      const forecastData = dataSources?.map(item => item.id);
      const compareForecastData = compareDataSources?.map(item => item.id);
      const cordinates = renderCordinates(cordinateAndZoom.locationCordinates);
      const markerCordinates = cordinateAndZoom.markerCordinates;
      const latitude = markerCordinates.length ? markerCordinates[0] : cordinates[0];
      const longitude = markerCordinates.length ? markerCordinates[1] : cordinates[1];

      if (
        selectedRegionId &&
        !_.isEmpty(country) &&
        _.isEmpty(selectedState) &&
        _.isEmpty(selectedDistrict)
      )
        requestObj.region_id = selectedRegionId;

      if (!_.isEmpty(country) && country !== SOUTH_ASIA_CONST.name)
        requestObj.country_name = country;

      if (!_.isEmpty(selectedState)) requestObj.state_name = selectedState;

      if (!_.isEmpty(selectedDistrict)) requestObj.district_name = selectedDistrict;

      requestObj.parameter_id = !_.isEmpty(selectedParam) ? selectedParam.parameter_id : '';
      requestObj.parameter_name = selectedParam.parameter?.name?.toLowerCase();

      requestObj.start_date = initialSelectedDate['graph'].startDate;
      requestObj.end_date = initialSelectedDate['graph'].endDate;

      let initialRequestObj = Object.assign({}, requestObj);
      let finalRequestObj = Object.assign({}, requestObj);
      if (compareDataSources?.length && !region?.length) {
        let layer = OPEN_WEATHER_PARAMETERS_CONST.find(
          layerObj => layerObj.name === selectedParam?.parameter?.name,
        );
        let initialRequestResponse;
        let finalRequestResponse;
        let initialDataSourceAPI;
        if (dataSources[0].id === DATA_SOURCES.GFS_NOAA.id) {
          initialDataSourceAPI = NOAA_TIMESERIES;
        } else if (dataSources[0].id === DATA_SOURCES.ERPAS.id) {
          initialDataSourceAPI = ERPAS_TIMESERIES;
        } else if (dataSources[0].id === DATA_SOURCES.IRI.id) {
          initialDataSourceAPI = IRI_TIMESERIES;
        }
        let finalDataSourceAPI;
        if (compareDataSources[0].id === DATA_SOURCES.GFS_NOAA.id) {
          finalDataSourceAPI = NOAA_TIMESERIES;
        } else if (compareDataSources[0].id === DATA_SOURCES.ERPAS.id) {
          finalDataSourceAPI = ERPAS_TIMESERIES;
        } else if (compareDataSources[0].id === DATA_SOURCES.IRI.id) {
          finalDataSourceAPI = IRI_TIMESERIES;
        }
        if (dataSources[0]?.id === DATA_SOURCES.OPEN_WEATHER.id) {
          initialRequestObj.latitude = latitude;
          initialRequestObj.longitude = longitude;
          initialRequestObj.data_sources = forecastData;

          initialRequestObj.spatial_aggregation = 'mean';

          let config = {
            id: dataSources[0]?.id,
            layerType: DATA_SOURCES.OPEN_WEATHER.name,
            location: selectedDistrict || selectedState || country || SOUTH_ASIA_CONST.name,
          };

          initialRequestResponse = dataSources[0]?.master_server
            ? AXIOS_INSTANCE_MASTER_SERVER.post(
                `${MASTER_SERVER_BASE_URL}/${dataSources[0]?.api_url}`,

                initialRequestObj,
                config,
              )
            : AXIOS_INSTANCE.post(WEATHER_FORECAST_DATA_URL, initialRequestObj, config);
        } else {
          initialRequestObj.spatial_aggregation = temporalAggregation.value;
          if (range.min) initialRequestObj.min = range.min;
          if (range.max) initialRequestObj.max = range.max;
          if (dataSources[0].id === DATA_SOURCES.IRI.id) {
            if (IRIForecastTime?.year?.value && IRIForecastTime?.month?.id) {
              initialRequestObj.forecast_time_year = IRIForecastTime?.year?.value;
              initialRequestObj.forecast_time_month =
                IRIForecastTime?.month?.id < 9
                  ? '0' + IRIForecastTime?.month?.id
                  : IRIForecastTime?.month?.id;
            }
            if (IRIForecastTime?.lead_time) {
              initialRequestObj.forecast_lead_time = parseInt(
                IRIForecastTime?.lead_time?.value?.substr(
                  IRIForecastTime?.lead_time?.value?.indexOf('(') + 1,
                  IRIForecastTime?.lead_time?.value?.indexOf(')') -
                    IRIForecastTime?.lead_time?.value?.indexOf('(') -
                    1,
                ),
              );
            }
          }
          setIsGraphLoading(true);
          let config = {
            id: dataSources[0]?.id,
            layerType:
              dataSources[0].id === DATA_SOURCES.GFS_NOAA.id
                ? DATA_SOURCES.GFS_NOAA.name
                : dataSources[0].id === DATA_SOURCES.ERPAS.id
                ? DATA_SOURCES.ERPAS.name
                : DATA_SOURCES.IRI.name,
            location: selectedDistrict || selectedState || country || SOUTH_ASIA_CONST.name,
          };

          initialRequestResponse = dataSources[0]?.master_server
            ? AXIOS_INSTANCE_MASTER_SERVER.post(
                `${MASTER_SERVER_BASE_URL}/${dataSources[0]?.api_url}/timeseries`,

                initialRequestObj,
                config,
              )
            : AXIOS_INSTANCE.post(initialDataSourceAPI, initialRequestObj, config);
        }
        if (
          !_.isEmpty(compareSearch?.country) &&
          compareSearch?.country === SOUTH_ASIA_CONST.name &&
          _.isEmpty(compareSearch?.state) &&
          _.isEmpty(compareSearch?.district)
        ) {
          finalRequestObj.region_id = SOUTH_ASIA_CONST.id;
        }
        if (
          !_.isEmpty(compareSearch?.country) &&
          compareSearch?.country !== SOUTH_ASIA_CONST.name
        ) {
          finalRequestObj.country_name = compareSearch?.country;
        }
        finalRequestObj.state_name = compareSearch?.state;
        finalRequestObj.district_name = compareSearch?.district;
        const compareLatitude = compareCordinates[0];
        const compareLongitude = compareCordinates[1];
        let finalConfig = {
          id: compareDataSources[0]?.id,
          layerType:
            compareDataSources[0].id === DATA_SOURCES.OPEN_WEATHER.id
              ? DATA_SOURCES.OPEN_WEATHER.name
              : compareDataSources[0].id === DATA_SOURCES.GFS_NOAA.id
              ? DATA_SOURCES.GFS_NOAA.name
              : compareDataSources[0].id === DATA_SOURCES.IRI.id
              ? DATA_SOURCES.IRI.name
              : DATA_SOURCES.ERPAS.name,
          location:
            compareSearch?.district ||
            compareSearch?.state ||
            compareSearch?.country ||
            SOUTH_ASIA_CONST.name,
        };
        if (compareDataSources[0].id === DATA_SOURCES.OPEN_WEATHER.id) {
          finalRequestObj.latitude = compareLatitude;
          finalRequestObj.longitude = compareLongitude;
          finalRequestObj.data_sources = compareForecastData;
          finalRequestObj.spatial_aggregation = 'mean';

          finalRequestResponse = compareDataSources[0]?.master_server
            ? AXIOS_INSTANCE_MASTER_SERVER.post(
                `${MASTER_SERVER_BASE_URL}/${compareDataSources[0]?.api_url}`,
                finalRequestObj,
                finalConfig,
              )
            : AXIOS_INSTANCE.post(WEATHER_FORECAST_DATA_URL, finalRequestObj, finalConfig);

          setIsGraphLoading(true);
        } else {
          setIsGraphLoading(true);
          finalRequestObj.spatial_aggregation = temporalAggregation.value;
          if (range.min) finalRequestObj.min = range.min;
          if (range.max) finalRequestObj.max = range.max;
          if (dataSources[0].id === DATA_SOURCES.IRI.id) {
            if (compareDataSources[0].id === DATA_SOURCES.IRI.id) {
              if (IRIForecastTime?.year?.value && IRIForecastTime?.month?.id) {
                finalRequestObj.forecast_time_year = IRIForecastTime?.year?.value;
                finalRequestObj.forecast_time_month =
                  IRIForecastTime?.month?.id < 9
                    ? '0' + IRIForecastTime?.month?.id
                    : IRIForecastTime?.month?.id;
              }
              if (IRIForecastTime?.lead_time) {
                finalRequestObj.forecast_lead_time = parseInt(
                  IRIForecastTime?.lead_time?.value?.substr(
                    IRIForecastTime?.lead_time?.value?.indexOf('(') + 1,
                    IRIForecastTime?.lead_time?.value?.indexOf(')') -
                      IRIForecastTime?.lead_time?.value?.indexOf('(') -
                      1,
                  ),
                );
              }
            }
          } else {
            if (compareDataSources[0].id === DATA_SOURCES.IRI.id) {
              if (compareIRIForecastTime?.year?.value && compareIRIForecastTime?.month?.id) {
                finalRequestObj.forecast_time_year = compareIRIForecastTime?.year?.value;
                finalRequestObj.forecast_time_month =
                  compareIRIForecastTime?.month?.id < 9
                    ? '0' + compareIRIForecastTime?.month?.id
                    : compareIRIForecastTime?.month?.id;
              }
              if (compareIRIForecastTime?.lead_time) {
                finalRequestObj.forecast_lead_time = parseInt(
                  compareIRIForecastTime?.lead_time?.value?.substr(
                    compareIRIForecastTime?.lead_time?.value?.indexOf('(') + 1,
                    compareIRIForecastTime?.lead_time?.value?.indexOf(')') -
                      compareIRIForecastTime?.lead_time?.value?.indexOf('(') -
                      1,
                  ),
                );
              }
            }
          }

          finalRequestResponse = compareDataSources[0]?.master_server
            ? AXIOS_INSTANCE_MASTER_SERVER.post(
                `${MASTER_SERVER_BASE_URL}/${compareDataSources[0]?.api_url}/timeseries`,

                finalRequestObj,
                finalConfig,
              )
            : AXIOS_INSTANCE.post(finalDataSourceAPI, finalRequestObj, finalConfig);
        }
        const responseData = [];
        Promise.all([initialRequestResponse, finalRequestResponse])
          .then(responses => {
            setIsGraphLoading(false);
            responses.map(item => {
              const { data: itemData } = item;
              if (Array.isArray(itemData?.result)) {
                let obj = renderOpenWeatherResponse(itemData?.result, item.config, selectedParam);
                responseData.push(obj);
              } else {
                for (let [key, value] of Object.entries(itemData?.result)) {
                  let val = value;
                  val.layerType = item.config.layerType;
                  val.location = item.config.location;
                  responseData.push(val);
                }
              }
            });
            setNoaaRegionTimeSeries(responseData);
          })
          .catch(error => {
            setIsGraphLoading(false);
            if (error?.response) {
              let errorRes = error.response;
              if (!_.isEmpty(errorRes?.data?.errors)) {
                setErrorData({ showError: true, data: Object.values(errorRes?.data?.errors)[0] });
              } else {
                setErrorData({ showError: true, data: errorRes?.data?.message });
              }
            } else {
              setErrorData({ showError: true, data: 'No records found. please try again' });
            }
          });
      } else {
        /** timesereis call  with out compare*/

        if (dataSources?.some(item => item.id === DATA_SOURCES.OPEN_WEATHER.id)) {
          requestObj.latitude = latitude;
          requestObj.longitude = longitude;
          requestObj.data_sources = forecastData;
          setIsGraphLoading(true);
          let isRegionLength =
            region?.length === 2
              ? true
              : region?.length === 1 && region[0]?.layerType === 'polygon'
              ? true
              : false;
          if (isRegionLength) {
            await renderGeometricStateData(region, requestObj);
          } else {
            if (region?.length === 1 && region[0]?.layerType === 'marker') {
              requestObj.latitude = region[0].latlngs[1];
              requestObj.longitude = region[0].latlngs[0];
            }
            requestObj.spatial_aggregation = 'mean';

            dispatch(
              getWeatherForecastData({
                obj: requestObj,
                isMasterServer: dataSources?.[0]?.master_server,
                apiUrl: dataSources?.[0]?.api_url,
              }),
            );
          }
        } else {
          setIsDefaultDataSource(false);
          setIsGraphLoading(true);
          if (range.min) requestObj.min = range.min;
          if (range.max) requestObj.max = range.max;
          /** dispatching time series request for GEE data sources  */
          requestObj.spatial_aggregation = temporalAggregation.value;
          if (IRIForecastTime?.year && IRIForecastTime?.month) {
            requestObj.forecast_time_year = IRIForecastTime?.year?.value;
            requestObj.forecast_time_month =
              IRIForecastTime?.month?.id < 9
                ? '0' + IRIForecastTime?.month?.id
                : IRIForecastTime?.month?.id;
          }
          if (IRIForecastTime?.lead_time) {
            requestObj.forecast_lead_time = parseInt(
              IRIForecastTime?.lead_time?.value?.substr(
                IRIForecastTime?.lead_time?.value?.indexOf('(') + 1,
                IRIForecastTime?.lead_time?.value?.indexOf(')') -
                  IRIForecastTime?.lead_time?.value?.indexOf('(') -
                  1,
              ),
            );
          }
          let isRegionLength =
            region?.length === 2
              ? true
              : region?.length === 1 && region[0]?.layerType === 'polygon'
              ? true
              : false;
          if (isRegionLength) {
            await renderGeometricStateData(region, requestObj);
          } else {
            setChartSeriesData([]);
            for (let param of categoryParams) {
              requestObj.parameter_id = param.parameter_id;
              requestObj.parameter_name = param?.parameter?.name?.toLowerCase();

              if (region?.length === 1 && region[0]?.layerType === 'marker') {
                requestObj.geometry = [region[0]?.latlngs];
              }
              let actionDispatch;
              if (dataSources[0]?.id === DATA_SOURCES.GFS_NOAA.id) {
                actionDispatch = getNoaaTimeSeries;
              } else if (dataSources[0]?.id === DATA_SOURCES.ERPAS.id) {
                actionDispatch = getErpasTimeSeries;
              } else if (dataSources[0]?.id === DATA_SOURCES.IRI.id) {
                actionDispatch = getIRITimeSeries;
              }
              dispatch(
                actionDispatch({
                  requestObj,
                  apiUrl: `${dataSources[0]?.api_url}/timeseries`,
                  isMasterServer: dataSources[0]?.master_server,
                }),
              );
            }
          }
        }
        /** */
      }
    }
    setUpdateGraph(true);
  };
  // grpah end

  const renderGeometricStateData = async (region, requestObj) => {
    setIsGraphLoading(true);
    setErrorData({ showError: false, data: '' });
    const data = await renderGeometricTimeSeries(
      requestObj,
      region,
      dataSources,
      isOpenWeather,
      selectedParam,
    );
    if (data?.response) {
      setNoaaRegionTimeSeries(data?.noaaResponse);
    } else {
      setErrorData(data?.errorData);
    }
    setIsGraphLoading(false);
  };

  window.onunload = function() {
    Cookies.remove('weather-forecast-default-param', { path: '/' });
  };

  useEffect(() => {
    if (isOpenWeather && !_.isEmpty(selectedParam)) {
      let paramCookie = Cookies.get('weather-forecast-default-param');
      if (paramCookie === undefined) {
        setWeatherForecastDefaultParam();
        handleApplyLayers(true);
      } else {
        return;
      }
    }
  }, [isOpenWeather, selectedParam]);

  const clearForecastData = () => {
    setIRIForecastTime({ year: null, month: null, lead_time: null });
    setIRIAvailableMonthList([]);
    setIRIAvailableLeadTimeList([]);
  };

  const handleApplyLayers = applyLayers => {
    if (applyLayers) {
      if (!_.isEmpty(layerControl?.firstLayer)) {
        map?.removeControl(layerControl?.firstLayer);
      }
      if (!_.isEmpty(layerControl?.secondLayer)) {
        map?.removeControl(layerControl?.secondLayer);
      }
      if (!_.isEmpty(mapControl)) {
        map?.removeControl(mapControl);

        setMapControl('');
        setLayerControl({
          firstLayer: {},
          secondLayer: {},
        });
      }
      setIndexLayers([]);
      let compareArray = [...compareParamLegendData];
      let updatedCompareArray = [];
      if (compareArray?.length) {
        updatedCompareArray = compareArray?.map((value, i) => {
          if (value.add) value.add = false;
          return value;
        });
      }
      setCompareParamLegendData(updatedCompareArray);
      removeLegend(selectedParamLegendData);

      if (isOpenWeather && !isCompareClicked && !compareDataSources?.length) {
        setIsLoading(true);
        removeLegend(selectedParamLegendData);
        let layer = OPEN_WEATHER_PARAMETERS_CONST.find(
          layerObj => layerObj.name === selectedParam?.parameter?.name,
        );
        let response = handleOpenWeatherCompareRes(layer);
        if (!_.isEmpty(response)) {
          const { data } = response;
          setForecastData(data?.result);
          setIsLoading(false);
        }
      } else {
        handleForecastData();
      }
    }
  };

  const handleGraph = () => {
    setNoaaRegionTimeSeries([]);
    setChartSeriesData([]);
    setParameterData([]);
    handleUpdateTimeSeries();
  };

  const handleCompareCordinates = cordinates => {
    setCompareCordinates(cordinates);
  };

  const returnFilteredGeoJsonData = (selectedLayer, item, location, propertyName, locationName) => {
    if (selectedLayer?.layer && selectedLayer?.layer?.current) {
      let data = JSON.parse(JSON.stringify(item.data));
      let selectedCountryData = GEOJSON_COUNTRY_DATA?.find(
        item => item.selectedCountry === selectedLocation?.country?.name,
      );
      let labelprop = selectedCountryData ? selectedCountryData[propertyName] : '';
      if (item.config.name === locationName && !_.isEmpty(location)) {
        data.features = item.data?.features.filter(x => {
          if (_.startCase(_.lowerCase(x?.properties[labelprop])) === location?.name) {
            selectedLayer.country = selectedLocation?.country;
            selectedLayer?.layer?.current?.clearLayers();
            return x;
          }
        });
        selectedLayer.layer.current.addData(data);
        selectedLayer.color = item.config.color;
      }
      return selectedLayer;
    }
  };

  useEffect(() => {
    if (geoJsonAllData?.length) {
      geoJsonAllData.map(item => {
        let mapGeojsonItem = Object.keys(mapPreferences).find(x => x === item.config.name);
        let selectedLayer = mapPreferences[mapGeojsonItem];
        if (selectedLayer?.layer && selectedLayer?.layer?.current) {
          let data = JSON.parse(JSON.stringify(item.data));
          if (
            item.config.name !== MAP_PREFERENCES_lABELS.DISTRICTS &&
            item.config.name !== MAP_PREFERENCES_lABELS.TALUKAS
          ) {
            selectedLayer.country = selectedLocation?.country;
            selectedLayer?.layer?.current?.clearLayers();
            selectedLayer.layer.current.addData(data);
            selectedLayer.color = item.config.color;
            setMapPreferences(prev => {
              return {
                ...prev,
                [mapGeojsonItem]: selectedLayer,
              };
            });
          }
        }
      });
    }
  }, [geoJsonAllData, mapPreferences?.state?.layer?.current, selectedLocation?.country]);

  useEffect(() => {
    if (geoJsonAllData?.length) {
      geoJsonAllData.map(item => {
        let mapGeojsonItem = Object.keys(mapPreferences).find(x => x === item.config.name);
        let selectedLayer = mapPreferences[mapGeojsonItem];
        if (selectedLayer?.layer && selectedLayer?.layer?.current) {
          if (
            !_.isEmpty(selectedLocation?.district) ||
            selectedLocation?.district?.name !== 'select'
          ) {
            let filteredLayer = returnFilteredGeoJsonData(
              selectedLayer,
              item,
              selectedLocation?.district,
              MAP_PREFERENCES_lABELS.DISTRICTS,
              MAP_PREFERENCES_lABELS.TALUKAS,
              selectedLocation?.country,
            );
            if (filteredLayer) {
              setMapPreferences(prev => {
                return {
                  ...prev,
                  [mapGeojsonItem]: filteredLayer,
                };
              });
            }
          }
          if (!_.isEmpty(selectedLocation?.state) || selectedLocation?.state?.name !== 'select') {
            let filteredLayer = returnFilteredGeoJsonData(
              selectedLayer,
              item,
              selectedLocation?.state,
              MAP_PREFERENCES_lABELS.STATES,
              MAP_PREFERENCES_lABELS.DISTRICTS,
              selectedLocation?.country,
            );
            if (filteredLayer) {
              setMapPreferences(prev => {
                return {
                  ...prev,
                  [mapGeojsonItem]: filteredLayer,
                };
              });
            }
          }
        }
      });
    }
  }, [geoJsonAllData]);

  useEffect(() => {
    if (geoJsonAllData?.length) {
      geoJsonAllData.map(item => {
        let mapGeojsonItem = Object.keys(mapPreferences).find(x => x === item.config.name);
        let selectedLayer = mapPreferences[mapGeojsonItem];
        if (
          selectedLayer?.layer &&
          selectedLayer?.layer?.current &&
          item.fromMapPreferences &&
          (_.isEmpty(selectedLocation?.state) || selectedLocation?.state?.name === 'select') &&
          item.config.name === MAP_PREFERENCES_lABELS.DISTRICTS
        ) {
          selectedLayer.country = selectedLocation?.country;
          selectedLayer?.layer?.current?.clearLayers();
          selectedLayer.layer.current.addData(item.data);
          selectedLayer.color = item.config.color;
          setMapPreferences(prev => {
            return {
              ...prev,
              [mapGeojsonItem]: selectedLayer,
            };
          });
        }
      });
    }
  }, [geoJsonAllData]);

  useEffect(() => {
    if (geoJsonAllData?.length) {
      geoJsonAllData.map(item => {
        let mapGeojsonItem = Object.keys(mapPreferences).find(x => x === item.config.name);
        let selectedLayer = mapPreferences[mapGeojsonItem];
        if (
          selectedLayer?.layer &&
          selectedLayer?.layer?.current &&
          item.fromMapPreferences &&
          (_.isEmpty(selectedLocation?.district) ||
            selectedLocation?.district?.name === 'select') &&
          item.config.name === MAP_PREFERENCES_lABELS.TALUKAS
        ) {
          selectedLayer.country = selectedLocation?.country;
          selectedLayer?.layer?.current?.clearLayers();
          selectedLayer.layer.current.addData(item.data);
          selectedLayer.color = item.config.color;
          setMapPreferences(prev => {
            return {
              ...prev,
              [mapGeojsonItem]: selectedLayer,
            };
          });
        }
      });
    }
  }, [geoJsonAllData]);

  // useEffect(()=>{
  //   if(mapUrl?.url){
  //     if(map.hasLayer(mapUrl?.layer)){
  //       mapUrl?.layer?.bringToFront();
  //     }
  //   }
  // },[mapUrl])

  useEffect(() => {
    if (
      (selectedMapPreferLegendData?.length && selectedMapPreferLegendData?.every(i => !i.add)) ||
      !selectedMapPreferLegendData?.length
    ) {
      if (!_.isEmpty(layerControl?.firstLayer) && !_.isEmpty(layerControl?.secondLayer)) {
        let array = [];
        array.push(layerControl?.firstLayer);
        array.push(layerControl?.secondLayer);
        setIndexLayers(array);
      } else if (mapUrl?.url) {
        setMapLayerOpacity({ layer: mapUrl?.layer });
        let array = [];
        array.push(mapUrl?.layer);
        setIndexLayers(array);
      }
    } else if (selectedMapPreferLegendData?.length) {
      if (mapPreferUrl?.url) {
        let array = [];
        array.push(mapPreferUrl?.layer);
        setIndexLayers(array);
        setMapLayerOpacity({ layer: mapPreferUrl?.layer });
      }
    }
  }, [selectedMapPreferLegendData]);

  useEffect(() => {
    if (
      (selectedParamLegendData?.length && selectedParamLegendData?.every(i => !i.add)) ||
      !selectedParamLegendData?.length
    ) {
      if (mapPreferUrl?.url) {
        let array = [];
        array.push(mapPreferUrl?.layer);
        setIndexLayers(array);
        setMapLayerOpacity({ layer: mapPreferUrl?.layer });
      }
    } else if (
      selectedParamLegendData?.length &&
      ((compareParamLegendData?.length && compareParamLegendData?.every(i => !i.add)) ||
        !compareParamLegendData?.length)
    ) {
      if (mapUrl?.url) {
        let array = [];
        array.push(mapUrl?.layer);
        if (indexLayers.every(i => i._url !== mapUrl?.layer?._url)) {
          setIndexLayers(array);
        }
        setMapLayerOpacity({ layer: mapUrl?.layer });
      }
    }
  }, [selectedParamLegendData]);

  useEffect(() => {
    if (
      (compareDataSources?.length &&
        compareParamLegendData?.length &&
        compareParamLegendData?.every(i => !i.add)) ||
      !compareParamLegendData?.length
    ) {
      if (mapPreferUrl?.url) {
        let array = [];
        array.push(mapPreferUrl?.layer);
        setIndexLayers(array);
        setMapLayerOpacity({ layer: mapPreferUrl?.layer });
      }
    } else if (compareParamLegendData?.length && compareDataSources?.length) {
      if (!_.isEmpty(layerControl?.firstLayer) && !_.isEmpty(layerControl?.secondLayer)) {
        let array = [];
        array.push(layerControl?.firstLayer);
        array.push(layerControl?.secondLayer);
        setIndexLayers(array);
      }
    }
  }, [compareParamLegendData, compareDataSources]);

  useEffect(() => {
    if (!_.isEmpty(indexLayers)) {
      addOpacitySlider();
      if (indexLayers?.length) {
        indexLayers.map(l => l.bringToFront());
      }
    }
  }, [indexLayers]);

  // useEffect(()=>{
  //   if(mapPreferUrl?.url){
  //     if(map.hasLayer(mapPreferUrl?.layer)){
  //       mapPreferUrl?.layer?.bringToFront();
  //     }
  //   }
  // },[mapPreferUrl])

  useEffect(() => {
    if (mapPreferencesData?.length) {
      if (mapPreferRefs?.current?.length) {
        mapPreferencesData?.map(item => {
          let selectedMapPreferences = mapPreferRefs.current.find(
            x => x.current.boundary_id === item.id,
          );
          let currentParamRef = selectedMapPreferences.current?.layer;
          currentParamRef?.current?.eachLayer(mapLayer => {
            mapLayer.setUrl(item?.result?.map_url);
            let updatedIndexLayer = [...indexLayers];
            updatedIndexLayer.push(mapLayer);

            setIndexLayers(updatedIndexLayer);
            setMapPreferUrl({
              name: selectedParam.name,
              url: item?.result?.map_url,
              layer: mapLayer,
            });
            setMapLayerOpacity({ layer: mapLayer });
          });
          if (currentParamRef && currentParamRef?.current) {
            addLayers(currentParamRef);
          }
        });
      }
    }
  }, [mapPreferencesData, mapPreferRefs.current]);

  // add layer opacity slider to the map
  function addOpacitySlider() {
    // if (!window.map) console.log('not ready');
    if (indexLayers?.length) {
      L.Control.OpacitySlider = L.Control.extend({
        sliding: false,
        updateLayerOpacity: function(opacity) {
          indexLayers.forEach((layer, i) => {
            layer.setOpacity(opacity / 100);
          });
        },
        onAdd: function(map) {
          if (opacityControl) {
            map?.removeControl(opacityControl);
          }
          var container = L.DomUtil.create('div', 'opacityContainer');
          container.innerHTML =
            '<div id=layer-opacity-control><input type="range" min="1" max="100" value="100" defaultValue="100" class="opacityslider"></div>';
          if (!L.Browser.touch) {
            L.DomEvent.disableClickPropagation(container);
            L.DomEvent.on(container, 'mousewheel', L?.DomEvent?.stopPropagation);
          } else {
            L.DomEvent.on(container, 'mousedrag click', L?.DomEvent?.stopPropagation);
          }
          let slider = container.querySelector('.opacityslider');
          slider.onclick = e => {
            this.updateLayerOpacity(e.target.value);
          };
          slider.onmousedown = e => {
            this.sliding = true;
            this.updateLayerOpacity(e.target.value);
          };
          slider.onmousemove = e => {
            e?.stopPropagation();
            if (this.sliding) this.updateLayerOpacity(e.target.value);
          };
          slider.onmouseup = e => {
            this.sliding = false;
          };
          return container;
        },
      });
      L.control.opacitySlider = function(opts) {
        return new L.Control.OpacitySlider(opts);
      };
      let customOpacityControl = L.control.opacitySlider({ position: 'topright' });
      customOpacityControl.addTo(map);
      setOpacityControl(customOpacityControl);
    }
  }

  const handleEachFeatureChange = (countryData, locationType, feat, layer) => {
    let selectedCountryData = GEOJSON_COUNTRY_TOOLTIP_DATA?.find(
      item => item.selectedCountry === countryData?.name,
    );
    let labelprop = selectedCountryData ? selectedCountryData[locationType] : '';
    if (labelprop && feat.properties[labelprop])
      layer.bindTooltip(feat.properties[labelprop] + '', { sticky: true, direction: 'auto' });

    /** TODO: alternative solution */

    // if(!_.isEmpty(layer) && !_.isEmpty(feat?.properties)){
    //   layer?.on({
    //     'mousemove': (e) => {
    //       const name = e.target.feature.properties['MJ_BASIN__'] ? e.target.feature.properties['MJ_BASIN__'] : '';
    //       layer?.bindTooltip(name)
    //       layer?.openTooltip(e?.latlng);
    //     },
    //     // 'mouseout': () => {
    //     //   layer?.unbindTooltip();
    //     //   layer?.closeTooltip();
    //     // },
    //   });
    // }
  };

  //added
  const renderParamLayer = paramList => {
    let isOpenWeather = '';
    if (paramList?.length) {
      return paramList.map((item, index) => {
        isOpenWeather = item.parameter.id === DATA_SOURCES.OPEN_WEATHER.id;
        const layer = OPEN_WEATHER_PARAMETERS_CONST.find(
          layer => layer.name === item?.parameter?.name,
        );
        if (
          item.parameter.name === 'Temperature' ||
          item.parameter.name === 'Precipitation' ||
          (!_.isEmpty(selectedParam) && item.parameter.name === selectedParam?.parameter?.name)
        ) {
          const data = OPEN_WEATHER_CHART_PARAMS.find(x => x.name === item.parameter.name);
          if (
            (!parameterData.length ||
              parameterData?.findIndex(paramItem => paramItem.name === item.parameter.name) ===
                -1) &&
            ((isOpenWeather && !_.isEmpty(data)) || !isOpenWeather)
          ) {
            parameterData.push({
              name: item.parameter.name,
              checked: true,
              measure: !_.isEmpty(data) ? data?.measure : '',
            });
          }
        }
        const addOverlay = layer?.id ? true : false;
        return (
          <>
            {/* TODO: need to create separate component */}
            {addOverlay || !isOpenWeather ? (
              <>
                <Overlay name={item.parameter.name}>
                  <LayerGroup ref={layerRef.current[index]}>
                    <TileLayer
                      attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url={
                        layer
                          ? layer.layerName
                            ? myConstClass.OPEN_WEATHER_TILE_LAYER_URL.replace(
                                '{layername}',
                                layer.layerName,
                              )
                            : ''
                          : ''
                      }
                      id={item.parameter.name}
                    />
                  </LayerGroup>
                </Overlay>
              </>
            ) : (
              ''
            )}
          </>
        );
      });
    } else {
      <></>;
    }
  };

  const forecastTimeYearHandler = e => {
    setIRIAvailableMonthList([]);
    setIRIAvailableLeadTimeList([]);
    IRIForecastTime.year = e;
    IRIForecastTime.month = null;
    IRIForecastTime.lead_time = null;
    setIRIForecastTime({ ...IRIForecastTime });
  };

  const compareForecastTimeYearHandler = e => {
    setCompareIRIAvailableMonthList([]);
    setCompareIRIAvailableLeadTimeList([]);
    compareIRIForecastTime.year = e;
    compareIRIForecastTime.month = null;
    compareIRIForecastTime.lead_time = null;
    setCompareIRIForecastTime({ ...compareIRIForecastTime });
  };

  const handleIsOpenWeather = selectedDataSource => {
    setIsOpenWeather(selectedDataSource);
  };

  const renderDefaultChecked = (name, parameterData) => {
    if (parameterData?.length === 2 || parameterData?.length === 3) {
      if (
        parameterData?.findIndex(i => i.name === name) >= 0 &&
        (name === 'Temperature' ||
          name === 'Precipitation' ||
          name === selectedParam?.parameter?.name)
      ) {
        return true;
      } else {
        return parameterData?.some(item => item.name === name && item.checked === true);
      }
    } else {
      return parameterData?.some(item => item.name === name && item.checked === true);
    }
  };

  const handleParamChart = e => {
    const data = OPEN_WEATHER_CHART_PARAMS.find(x => x.name === e.target.defaultValue);
    if (_.findIndex(parameterData, { name: e.target.defaultValue }) === -1) {
      if (e.target.checked) {
        setParameterData(prevData => [
          ...prevData,
          {
            name: e.target.defaultValue,
            checked: true,
            measure: !_.isEmpty(data) ? data?.measure : '',
          },
        ]);
      }
    } else if (_.findIndex(parameterData, { name: e.target.defaultValue }) >= 0) {
      let chartParamArr = [...parameterData];
      const index = _.findIndex(parameterData, { name: e.target.defaultValue });
      chartParamArr[index] = {
        name: e.target.defaultValue,
        checked: e.target.checked,
        measure: !_.isEmpty(data) ? data?.measure : '',
      };
      setParameterData(chartParamArr);
    }
  };
  const renderGeeTimeSeriesData = dataSources => {
    if (dataSources[0].id === DATA_SOURCES.GFS_NOAA.id) {
      return noaaTimeSeriesData;
    } else if (dataSources[0].id === DATA_SOURCES.ERPAS.id) {
      return erpasTimeSeriesData;
    } else if (dataSources[0].id === DATA_SOURCES.IRI.id) {
      return iriTimeSeriesData;
    } else {
      return [];
    }
  };

  const handleIsCompare = isCompare => {
    setIsCompareClicked(isCompare);
  };

  const handleCompareView = isCompare => {
    setCompareView(isCompare);
  };

  const forecastYearMonthHandler = e => {
    // setSelectedForecastLeadTime(null);
    setSelectedForecastYearMonth(prev => {
      return {
        ...prev,
        map: {
          forecastYearMonth: e,
        },
        graph: {
          forecastYearMonth: e,
        },
      };
    });
  };

  const forecastCompareLeadTimeHandler = e => {
    //setSelectedFinalForercastLeadTime(e);
    compareIRIForecastTime.lead_time = e;
    setCompareIRIForecastTime({ ...compareIRIForecastTime });
  };

  const forecastTimeMonthHandler = e => {
    setIRIAvailableLeadTimeList([]);
    IRIForecastTime.month = e;
    IRIForecastTime.lead_time = null;
    setIRIForecastTime({ ...IRIForecastTime });
  };

  const compareForecastTimeMonthHandler = e => {
    setCompareIRIAvailableLeadTimeList([]);
    compareIRIForecastTime.month = e;
    compareIRIForecastTime.lead_time = null;
    setCompareIRIForecastTime({ ...compareIRIForecastTime });
  };

  const forecastCompareYearMonthHandler = e => {
    //setSelectedFinalForercastLeadTime(null);
    setSelectedForecastCompareYearMonth(prev => {
      return {
        ...prev,
        map: {
          forecastYearMonth: e,
        },
        graph: {
          forecastYearMonth: e,
        },
      };
    });
  };
  const onHandleEcmwf = () => {
    setShowEcmwf(true);
    const data2 = renderStaticResourceImageApi(
      ecmwfControlData?.data?.result,
      selectedBasetime,
      selectedValidTime,
      selectedValidLayer,
      forecastType,
      daysData,
      quantileData,
      selectedEcmwfProducts,
      selectedLocation,
    );

    const stringified = Object.keys(data2)
      .map(key => `${key}=${data2[key]}`)
      .join('&');

    dispatch(getEcmwfDataByControlsRequest({ query: stringified }));
  };

  const renderParameterSelect = item => {
    return (
      <>
        <FormGroup check>
          <Label check>
            <Input
              type="checkbox"
              onChange={handleParamChart}
              value={item.parameter.name}
              defaultChecked={renderDefaultChecked(item.parameter.name, parameterData)}
            />{' '}
            {item.parameter.name}
          </Label>
        </FormGroup>
        &nbsp; &nbsp;
      </>
    );
  };

  const renderChartsUI = () => {
    if (showCharts) {
      return (
        <>
          <div className="footer panel">
            <div className="footerArea chart-design" style={{ flexGrow: 1 }}>
              <FormGroup row>
                {errorData?.showError ||
                (parameterData?.length && parameterData?.every(i => !i.checked)) ||
                isHideToolBar ? (
                  <Col sm={12} md={12} lg={12}>
                    <div className="error-close-icon" onClick={handleChartDisplay}>
                      <CgCloseO size={25} color="#2A547C" />
                    </div>
                  </Col>
                ) : (
                  ''
                )}
              </FormGroup>
              {!_.isEmpty(chartSeriesData) ? (
                <>
                  <Charts
                    renderCharts={handleChartDisplay}
                    chartData={chartSeriesData}
                    setIsHideToolBar={setIsHideToolBar}
                    parametersData={parameterData}
                    dataSource={dataSources}
                    isOpenWeather={isOpenWeather}
                    initialSelectedDate={initialSelectedDate}
                    geeTimeSeriesData={renderGeeTimeSeriesData(dataSources)}
                    country={country}
                    state={selectedState}
                    district={selectedDistrict}
                    region={region}
                  />
                  <FormGroup className="periodicity-style" row>
                    {region?.length === 1 && !_.isEmpty(region[0]) ? (
                      region[0]?.layerType === 'marker' ? (
                        <>
                          <p className="marker-style">
                            point({region[0]?.latlngs[1].toFixed(2)},
                            {region[0]?.latlngs[0].toFixed(2)})
                          </p>
                        </>
                      ) : (
                        ''
                      )
                    ) : (
                      ''
                    )}
                    <Col className="param-chart">
                      {categoryParams?.length
                        ? categoryParams.map(item => {
                            return (
                              <>
                                {(isOpenWeather &&
                                  OPEN_WEATHER_CHART_PARAMS.some(
                                    data => data.name === item?.parameter?.name,
                                  )) ||
                                !isOpenWeather ? (
                                  <>{renderParameterSelect(item)}</>
                                ) : (
                                  ''
                                )}
                              </>
                            );
                          })
                        : ''}
                    </Col>
                  </FormGroup>
                </>
              ) : (
                <>
                  {noaaRegionTimeSeries?.length ? (
                    <>
                      <GeometryCharts
                        renderCharts={handleChartDisplay}
                        setIsHideToolBar={setIsHideToolBar}
                        selectedParam={selectedParam}
                        dataSource={dataSources}
                        isOpenWeather={isOpenWeather}
                        initialSelectedDate={initialSelectedDate}
                        noaaRegionTimeSeries={noaaRegionTimeSeries}
                        region={region}
                        country={country}
                        state={selectedState}
                        district={selectedDistrict}
                      />
                    </>
                  ) : (
                    <>
                      {isGraphLoading ? <GraphLoader /> : ''}
                      {errorData?.showError ? (
                        <div className="drought-error-container">
                          <div className="drought-error-style">
                            <Alert color="warning">{errorData?.data}</Alert>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      );
    }
  };

  // Static coordinates for India

  const iconHtmlString = ReactDOMServer.renderToString(
    <CustomMarkerIcon Icon={FaMapMarkerAlt} className="custom-hydrostation-cons" />,
  );

  const myIcon = L.divIcon({
    iconAnchor: [0, 0],
    className: 'custom-div-icon',
    html: iconHtmlString,
  });

  const renderMapContainer = () => {
    return (
      <>
        <MapContainer
          center={cordinateAndZoom.cordinates}
          id="leaflet-map"
          zoom={cordinateAndZoom.zoom}
          className="map-container"
          zoomControl={false}
          whenCreated={setMapReference}
          scrollWheelZoom={true}
        >
          <LayerControl
            position="topleft"
            className="layer-control-style"
            params={params}
            dataSourceId={selectedDataSourceId}
            handleParameters={handleParameters}
            handleDataSourceId={handleDataSourceId}
            isHideBar={isHideBar}
            isOpenWeather={isOpenWeather}
            key="layer1"
          >
            <ChangeView center={cordinateAndZoom.cordinates} zoom={cordinateAndZoom.zoom} />
            <ZoomControl position="topright" className="map-zoom-control" />
            {/* <DraggableMarker
            renderCharts={handleChartDisplay}
            cordinates={cordinateAndZoom.locationCordinates}
            handleCordinates={getMarkerPosition}
            forecastData={graphData}
            dataSource={dataSources}
            initialSelectedDate={initialSelectedDate}
          /> */}

            {searchMarker ? (
              <Marker position={searchMarker} icon={myIcon}>
                <Tooltip permanent direction="top" className="custom-tooltip-1" offset={[17, -17]}>
                  <div className="tooltip-container-1">
                    <span className="custom-marker-text">
                      ({searchMarker[0].toFixed(2)},{searchMarker[1].toFixed(2)})
                    </span>
                    {/* <span className='tooltip-container-2'> x </span> */}
                  </div>
                </Tooltip>
              </Marker>
            ) : (
              ''
            )}

            {!hasQueryParam ? (
              <LayersControl position="topleft">
                <div className="logoContainer1">
                  <Button className="map-preferences-button" onClick={handleMapModel}>
                    <img src={mapPreferenceIcon} alt="map preference" width={15} />
                  </Button>
                </div>
              </LayersControl>
            ) : (
              ''
            )}
            <LayersControl position="topright">
              <LayersControl.BaseLayer name="Satellite View">
                <TileLayer url={myConstClass.SATELLITE_TILE_LAYER_URL} />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer checked name="Streets View">
                <TileLayer url={myConstClass.TITLE_LAYER_URL} options={{ tileSize: 256 }} />
              </LayersControl.BaseLayer>
            </LayersControl>
          </LayerControl>

          <DrawShape
            handleSelectedRegion={handleSelectedRegion}
            updateGraph={updateGraph}
            country={country}
            state={selectedState}
            district={selectedDistrict}
          />

          <GeoJSON
            key={selectedLocation.country + MAP_PREFERENCES_lABELS.COUNTRY}
            ref={mapPreferences['country'].layer}
            style={{ color: mapPreferences['country'].color, fillColor: '#0000', weight: 1 }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(mapPreferences['country'].country, 'country', feat, layer)
            }
          />

          <GeoJSON
            key={selectedLocation?.country + MAP_PREFERENCES_lABELS.STATES}
            ref={mapPreferences['states_or_provinces'].layer}
            style={{
              color: mapPreferences['states_or_provinces'].color,
              fillColor: '#0000',
              weight: 1.6,
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(
                mapPreferences['states_or_provinces'].country,
                'states_or_provinces',
                feat,
                layer,
              )
            }
          />

          <GeoJSON
            key={selectedLocation?.country + MAP_PREFERENCES_lABELS.DISTRICTS}
            ref={mapPreferences['districts_or_cities'].layer}
            style={{
              color: mapPreferences['districts_or_cities'].color,
              weight: 1,
              fillColor: '#0000',
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(
                mapPreferences['districts_or_cities'].country,
                'districts_or_cities',
                feat,
                layer,
              )
            }
          />

          <GeoJSON
            key={selectedLocation?.country + MAP_PREFERENCES_lABELS.METEOROLOGICAL_LOCATIONS}
            ref={mapPreferences['meteorological_locations'].layer}
            style={{
              color: mapPreferences['meteorological_locations'].color,
              fillColor: '#0000',
              weight: 1,
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(
                mapPreferences['meteorological_locations'].country,
                'meteorological_locations',
                feat,
                layer,
              )
            }
          />

          <GeoJSON
            key={selectedLocation?.country + MAP_PREFERENCES_lABELS.BASINS}
            ref={mapPreferences['basins'].layer}
            style={{
              color: mapPreferences['basins'].color,
              fillColor: '#0000',
              weight: 1,
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(mapPreferences['basins'].country, 'basins', feat, layer)
            }
          />

          <GeoJSON
            key={selectedLocation?.country + MAP_PREFERENCES_lABELS.RIVERS}
            ref={mapPreferences['rivers_or_reservoirs'].layer}
            style={{
              color: mapPreferences['rivers_or_reservoirs'].color,
              fillColor: '#0000',
              weight: 1,
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(
                mapPreferences['rivers_or_reservoirs'].country,
                'rivers_or_reservoirs',
                feat,
                layer,
              )
            }
          />

          <GeoJSON
            key={selectedLocation?.country + MAP_PREFERENCES_lABELS.TALUKAS}
            ref={mapPreferences['talukas'].layer}
            style={{
              color: mapPreferences['talukas'].color,
              fillColor: '#0000',
              weight: 1,
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(mapPreferences['talukas'].country, 'talukas', feat, layer)
            }
          />

          <LayersControl key="layer2" position="topleft">
            {renderParamLayer(categoryParams, selectedParam)}

            {mapPreferRefs?.current?.map((mapLayerRef, index) => {
              if (!_.isEmpty(mapLayerRef)) {
                return (
                  <>
                    <Overlay>
                      <LayerGroup ref={mapLayerRef.current.layer} key={index}>
                        <TileLayer
                          attribution='Weather from <a href="https://openweathermap.org/" alt="World Map and worldwide Weather Forecast online">OpenWeatherMap</a>'
                          url=""
                        />
                      </LayerGroup>
                    </Overlay>
                  </>
                );
              }
            })}
          </LayersControl>

          {/* {compareParamLegendData?.length ? (
            <>
              <LayersControl key="layer3" position="topright">{addLegend(compareParamLegendData)}</LayersControl>
            </>
          ) : (
            ''
          )} */}

          {compareParamLegendData.length
            ? compareParamLegendData.map(item => {
                return (
                  <>
                    <LayersControl key="layer3" position="topleft">
                      {item?.descret_legend ? (
                        <DiscreteLegend
                          map={map}
                          add={item.add}
                          layer={item}
                          position="bottomleft"
                        />
                      ) : (
                        <CustomLegend map={map} add={item.add} layer={item} position="bottomleft" />
                      )}
                    </LayersControl>
                  </>
                );
              })
            : ''}

          {/* {selectedParamLegendData?.length ? (
            <>
              <LayersControl key="layer4">
                {addLegend(selectedParamLegendData)}
              </LayersControl>

            </>
          ) : (
            ''
          )} */}
          {selectedParamLegendData.length
            ? selectedParamLegendData.map(item => {
                return (
                  <>
                    <LayersControl position="topleft" key="layer4">
                      {item?.descret_legend ? (
                        <DiscreteLegend
                          map={map}
                          add={item.add}
                          layer={item}
                          position="bottomright"
                        />
                      ) : (
                        <CustomLegend
                          map={map}
                          add={item.add}
                          layer={item}
                          position="bottomright"
                        />
                      )}
                    </LayersControl>
                  </>
                );
              })
            : ''}

          {selectedMapPreferLegendData?.length ? (
            <>
              <LayersControl key="layer5" position="topleft">
                {addLegend(selectedMapPreferLegendData)}
              </LayersControl>
            </>
          ) : (
            ''
          )}
        </MapContainer>
      </>
    );
  };

  return (
    // <div className="global-top">
    <div className="global-top-new">
      <div className="w-100">
        {isLoading ? <Loader /> : ''}
        <div className={isHideBar ? `sideBar-collapse col-lg-2` : `sideBar col-lg-2`}>
          <LeftFilter
            initialValues={{
              isHideBar: isHideBar,
              selectedParam: selectedParam,
              staticResourceSelectedLocation: staticResourceSelectedLocation,
              fromStaticResources: hasQueryParam,
              dataSourceId: selectedDataSourceId,
              dates: staticResourceDates || '',
              years: yearsList,
              isDefaultSelectedSourceId: isDefaultSelectedSourceId,
              region: region,
              mapPreferencesData: mapPreferencesData,
            }}
            setCurrenUserLocation={setCurrenUserLocation}
            currentUserLocation={currentUserLocation}
            clearForecastData={clearForecastData}
            clearDatesHandler={clearDatesHandler}
            setBar={setBar}
            selectedForecastYearMonth={selectedForecastYearMonth}
            selectedForecastCompareYearMonth={selectedForecastCompareYearMonth}
            forecastYearMonthHandler={forecastYearMonthHandler}
            forecastLeadTimeHandler={forecastLeadTimeHandler}
            forecastCompareYearMonthHandler={forecastCompareYearMonthHandler}
            forecastCompareLeadTimeHandler={forecastCompareLeadTimeHandler}
            renderCharts={handleChartDisplay}
            showCharts={setShowCharts}
            handleSelectedYear={handleSelectedDefaultYear}
            handleSelectedDate={handleSelectedDate}
            handleCordinates={handleCordinates}
            handleSelectedRegion={handleSelectedFilterRegion}
            handleCountry={handleCountry}
            handleAdvancedSearch={handleAdvancedSearch}
            handleCompareSearch={handleCompareSearch}
            handleProps={handleProps}
            handleDefaultDataSource={handleDefaultDataSource}
            handleParamData={setParamData}
            handleSelectedLocation={handleSelectedLocation}
            handleCompareParamsData={setCompareParam}
            handleRegionId={handleRegionId}
            handleSelectedDataSourceId={handleSelectedDataSourceId}
            setImage={setStaticResourceImage}
            setTitle={setStaticResourceTitle}
            handleIsOpenWeather={handleIsOpenWeather}
            handleApplyLayers={handleApplyLayers}
            handleTimeSeries={handleGraph}
            handleCompareView={handleCompareView}
            handleIsCompare={handleIsCompare}
            handleCompareCordinates={handleCompareCordinates}
            forecastLeadTimeList={forecastLeadTimeList}
            // selectedForecastLeadTime={selectedForecastLeadTime}
            //selectedFinalForercastLeadTime={selectedFinalForercastLeadTime}
            forecastCompareLeadTimeList={forecastCompareLeadTimeList}
            // forecastLeadTimeList={forecastLeadTimeList}
            // forecastCompareLeadTimeList={forecastCompareLeadTimeList}
            IRIAvailableYearList={IRIAvailableYearList}
            compareIRIAvailableYearList={compareIRIAvailableYearList}
            forecastTimeYearHandler={forecastTimeYearHandler}
            compareForecastTimeYearHandler={compareForecastTimeYearHandler}
            IRIAvailableMonthList={IRIAvailableMonthList}
            compareIRIAvailableMonthList={compareIRIAvailableMonthList}
            IRIForecastTime={IRIForecastTime}
            compareIRIForecastTime={compareIRIForecastTime}
            forecastTimeMonthHandler={forecastTimeMonthHandler}
            compareForecastTimeMonthHandler={compareForecastTimeMonthHandler}
            IRIAvailableLeadTimeList={IRIAvailableLeadTimeList}
            compareIRIAvailableLeadTimeList={compareIRIAvailableLeadTimeList}
            stateValue={stateValue}
            setCompareIRIForecastTime={setCompareIRIForecastTime}
            ecmwfProducts={ecmwfProducts}
            selectedEcmwfProducts={selectedEcmwfProducts}
            setSelectedEcmwfProducts={setSelectedEcmwfProducts}
            staticResourceBaseTimeData={staticResourceBaseTimeData}
            validTimeOptions={validTimeOptions}
            validLayerOptions={validLayerOptions}
            selectedValidTime={selectedValidTime}
            selectedValidLayer={selectedValidLayer}
            daysData={daysData}
            quantileData={quantileData}
            forecastType={forecastType}
            selectedBasetime={selectedBasetime}
            setSelectedBaseTime={setSelectedBaseTime}
            setValidTimeOptions={setValidTimeOptions}
            setSelectedValidTime={setSelectedValidTime}
            setValidLayerOptions={setValidLayerOptions}
            setSelectedValidLayer={setSelectedValidLayer}
            setForecastType={setForecastType}
            setDaysData={setDaysData}
            setQuantileData={setQuantileData}
            staticResourceData={staticResourceData}
            onHandleEcmwf={onHandleEcmwf}
            setSelectedDataSource={setSelectedDataSource}
            selectedDataSource={selectedDataSource}
            setSelectedLocationEcmwf={setSelectedLocationEcmwf}
            selectedLocationEcmwf={selectedLocationEcmwf}
            setSearchMarker={setSearchMarker}
            countryJson={countryJson}
          />
        </div>
        {(!hasQueryParam && selectedDataSource?.firstDataSource?.[0]?.name !== 'ECMWF (Static)') ||
        (!showEcmwf && selectedDataSource?.firstDataSource?.[0]?.name === 'ECMWF (Static)') ? (
          <>
            <Row>
              <Col lg={isHideBar ? 12 : 10} className="map-position">
                <div
                  // style={{
                  //   height:"83.4vh",
                  //   display: 'flex',
                  //   flexDirection: 'column',
                  // }}
                  className="map-space-div"
                >
                  <>
                    <SplitterLayout
                      ref={splitterRef}
                      primaryIndex={0}
                      vertical
                      percentage={true}
                      primaryMinSize={30}
                      secondaryInitialSize={70}
                    >
                      <div style={{ height: '100%' }}>{renderMapContainer()}</div>
                      {renderChartsUI()}
                    </SplitterLayout>
                  </>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <StaticResource
            dataSourceId={selectedDataSourceId}
            params={staticResourceParams}
            handleParameters={handleParameters}
            handleDataSourceId={handleDataSourceId}
            dataSource={dataSources}
            staticResourceWeekData={staticResourceWeekData ? staticResourceWeekData : ''}
            setStaticResourceSelectedLocation={setStaticResourceSelectedLocation}
          />
        )}
        {!nextProps.staticResourceData ? (
          <MapPreferences
            isToggle={isOpenMapModel}
            handleToggle={handleMapModel}
            loc={country}
            selectedLocation={selectedLocation}
            regionId={selectedRegionId}
            selectedParamLegendData={selectedParamLegendData}
            isMapPerferGeoJsonLoading={mapPreferenceGeoJsonloading}
            getMapPreferencesData={getMapPreferencesData}
            getMapPreferencesGeojsonData={getMapPreferencesGeojsonData}
            geoJsonData={geoJsonData}
            mapPreferencesAllData={mapPreferencesData}
            countryData={selectedLocation?.country}
            stateData={selectedLocation?.state}
            districtData={selectedLocation?.district}
            fromDroughtMonitor={true}
            fromContingencyPlan={false}
            weatherForecastStateData={stateData}
            cordinateAndZoom={cordinateAndZoom}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

LeafletWrapper.propTypes = {
  cordinates: PropTypes.array,
  zoom: PropTypes.number,
  parameters: PropTypes.array,
};

import React, { useState, useEffect, useRef } from 'react';
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  useMap,
  useMapEvents,
  ZoomControl,
  LayersControl,
  LayerGroup,
  Marker,
  Tooltip,
} from 'react-leaflet';
import L from 'leaflet';
import _ from 'lodash';
import moment from 'moment';
import 'leaflet-side-by-side';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { Row, Col, Button, FormGroup, Alert } from 'reactstrap';
import CustomMarkerIcon from '../../Components/common/CustomIcon.js';
import * as ReactDOMServer from 'react-dom/server';
import { FaMapMarkerAlt } from 'react-icons/fa';
import 'leaflet/dist/leaflet.css';
import 'leaflet.control.opacity/dist/L.Control.Opacity.css';
import 'leaflet.control.opacity';
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';
import '../../assets/CSS/LeafletWrapper.css';
import '../../assets/CSS/Header.css';
import myConstClass from '../../Constants';
import {
  IDSI_AND_VWI,
  INDICES_PARAMS,
  OTHER_INDICES_API_INDICES_SLUGS,
  DEFAULT_COUNTRY,
  DRY_SPELL,
  DDSKEY,
} from '../../Components/constants/index';
import { t } from 'react-multi-lang';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDroughtMapRequest,
  getDroughtTimeSeriesRequest,
  getDroughtMapSuccess,
  getMapPreferenceSuccess,
  getMapPreferenceBoundariesSuccess,
  getWeatherForecastData,
  getFinalDroughtMapRequest,
  getWeatherForecastDataSuccess,
  getFinalDroughtMapSuccess,
  getFinalDroughtMapFailed,
} from '../../redux/actions';
import DroughtSidebar from './sidebar';
import Loader from '../../Components/common/TextLoader';
import GraphLoader from '../../Components/common/GraphLoader';
import { showError } from '../../Components/Notifications.js';
import CustomLegend from '../WeatherForecast/CustomLayerControl/CustomLegend';
import DiscreteLegend from '../WeatherForecast/CustomLayerControl/DiscreteLegend';
import MapPreferences from '../WeatherForecast/MapPreferences';
import DroughtChart from './DroughtChart';
import DrawShape from './DrawShape';
import {
  API_BASE_URL,
  MASTER_SERVER_BASE_URL,
  AXIOS_INSTANCE,
  AXIOS_INSTANCE_MASTER_SERVER,
} from '../../redux/api/apiEndPoint';
import { CgCloseO } from 'react-icons/cg';
import mapPreferenceIcon from '../../assets/Images/mapPreferenceIcon.svg';
import point_location from '../../assets/Images/point_location.png';
import {
  SOUTH_ASIA_CONST,
  MAP_PREFERENCES_lABELS,
  DATA_SOURCES,
  GEOJSON_COUNTRY_TOOLTIP_DATA,
  GEOJSON_COUNTRY_DATA,
  DROUGHT_MODULES_CONST,
} from '../../Components/constants';
import { returnDSSDateFormat, isLogedIn } from '../../Components/common/utils';
import { useHistory } from 'react-router-dom';
import PointDataModal from '../../Components/common/PointDataModal.js';

const DroughtMap = props => {
  const { popUpSelectedModule, modal } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { Overlay } = LayersControl;
  const [isOpenMapModel, setIsOpenMapModel] = useState(false);
  const [isOpenAlertIndicesTable, setIsOpenAlertIndicesTable] = useState(false);
  const [map, setMap] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const layerRef = useRef([]);
  const splitterRef = useRef(null);
  const featureGroupRef = useRef();
  const [mapPreferRefs, setMapPreferRefs] = useState([]);
  const [isHideBar, setBar] = useState(false);
  const [params, setParams] = useState('');
  const [precipitationThreshold, setPrecipitationThreshold] = useState(124);
  const [comparePrecipitationThreshold, setComparePrecipitationThreshold] = useState(124);
  const [drySpellPrecipitationThreshold, setDrySpellPrecipitationThreshold] = useState(2.5);
  const [compareDrySpellPrecipitationThresold, setCompareDrySpellPrecipitationThresold] = useState(
    2.5,
  );
  const [selectedParam, setSelectedParam] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [previousParam, setPreviousParam] = useState('');
  const [compareSelectedParam, setCompareSelectedParam] = useState('');
  const [initialSelectedDate, setInitialSelectedDate] = useState([]);
  const [finalSelectedDate, setFinalSelectedDate] = useState([]);
  const [thresholdData, setThresholdData] = useState([]);
  const [DroughtIndicators, setDroughtIndicators] = useState([]);
  const [DSSTabularDate, setDSSTabularDate] = useState({
    startDate: '',
    endDate: '',
  });
  const [mapPreferencesData, setMapPreferencesData] = useState([]);
  const [geoJsonData, setGeoJsonData] = useState([]);
  const [geoJsonAllData, setGeoJsonAllData] = useState([]);
  const [geoJsonLayersData, setGeoJsonLayerData] = useState([]);
  const [getBoundariesData, setGetBoundariesData] = useState([]);
  const [selectedParamLegendData, setSelectedParamLegendData] = useState([]);
  const [compareParamLegendData, setCompareParamLegendData] = useState([]);
  const [selectedMapPreferLegendData, setSelectedMapPreferLegendData] = useState([]);
  const [showCharts, setShowCharts] = useState(false);
  const [graphData, setGraphData] = useState('');
  const mapRef = useRef(null);
  const [mapControl, setMapControl] = useState({});
  const [mapPreferUrl, setMapPreferUrl] = useState('');
  const [mapLayerOpacity, setMapLayerOpacity] = useState({ layer: '' });
  const [opacityControl, setOpacityControl] = useState('');
  const [compareView, setCompareView] = useState([]);
  const [isCompareClicked, setIsCompareClicked] = useState(false);
  const [mapPreferenceGeoJsonloading, setMapPreferenceGeoJsonloading] = useState(false);
  const [errorData, setErrorData] = useState({ showError: false, data: '' });
  const [isGraphLoading, setIsGraphLoading] = useState();
  const [selectedRegionId, setSelectedRegionId] = useState('');
  const [isOpenWeatherForecastDataModal, setIsOpenWeatherForecastDataModal] = useState(false);
  const [selectedDroughtPhase, setSelectedDroughtPhase] = useState('');
  const [weatherModuleData, setWeatherModuleData] = useState('');
  const [DSSTimeSeriesData, setDSSTimeSeriesData] = useState('');
  const [isShowDroughtCheckModal, setIsShowDroughtCheckModal] = useState(false);
  const [mapPrefereTileLayerRef, setMapPrefereTileLayerRef] = useState([]);
  const [isThresholdDataLoading, setIsThresholdDataLoading] = useState(false);
  const [indexLayers, setIndexLayers] = useState([]);
  const [manuleMarker, setManuleMarker] = useState([]);
  const [mapLayers, setMapLayers] = useState([]);
  const [selectedModule, setSelectedModule] = useState({
    value: 'drought monitor',
    label: 'Drought Monitor',
    api_slug: 'drought-monitor',
  });
  const [finalDroughtParameterMonthsList, setFinalDroughtParameterMonthsList] = useState([
    {
      id: 'smci',
      slug: 'smci',
      name: 'SMCI',
      category: 'Mid Season',
      options: [],
      selectedOption: {},
    },
    {
      id: 'vhi',
      slug: 'vhi',
      name: 'VHI',
      category: 'Mid Season',
      options: [],
      selectedOption: {},
    },
    {
      id: 'idsi',
      slug: 'idsi',
      name: 'IDSI',
      category: 'Terminal',
      options: [],
      selectedOption: {},
    },
  ]);
  const [tableData, setTableData] = useState([]);
  const [chartParamsData, setChartParamsData] = useState({
    temporalAggregation: '',
    selectedParam: '',
    compareParam: '',
    region: '',
    initialSelectedDate: '',
    location: '',
  });
  const [mapPreferences, setMapPreferences] = useState({
    country: { layer: useRef(), color: '', data: '', country: '' },
    states_or_provinces: { layer: useRef(), color: '', data: '', country: '' },
    districts_or_cities: { layer: useRef(), color: '', data: '', country: '' },
    meteorological_locations: { layer: useRef(), color: '', data: '', country: '' },
    basins: { layer: useRef(), color: '', data: '', country: '' },
    rivers_or_reservoirs: { layer: useRef(), color: '', data: '', country: '' },
    talukas: { layer: useRef(), color: '', data: '', country: '' },
  });
  const [currentUserLocation, setCurrenUserLocation] = useState('India');
  const [selectedLocation, setSelectedLocation] = useState({
    country: '',
    state: '',
    district: '',
    basin: '',
    region: '',
    markerPointData: '',
  });
  const [compareLocation, setCompareLocation] = useState({
    country: '',
    state: '',
    district: '',
    basin: '',
    region: '',
    markerPointData: '',
  });
  const [cordinateAndZoom, setCordinateAndZoom] = useState({
    cordinates: [23.4073, 79.446],
    locationCordinates: [23.4073, 79.446],
    markerCordinates: [23.4073, 79.446],
    zoom: 5.5,
    searchMarkerCoordinates: '',
  });
  const [searchLocation, setSearchLocation] = useState('');
  // const [countryJson , setCountryJson] = useState("")
  const [compareCordinates, setCompareCordinates] = useState([]);
  const [isPreviewIndicesData, setIsPreviewIndicesData] = useState(false);
  const [mapData, setMapData] = useState('');
  const [mapUrl, setMapUrl] = useState({
    name: '',
    url: '',
  });

  const [state, setState] = useState({
    min: 0,
    max: 0,
  });

  const [layerControl, setLayerControl] = useState({
    firstLayer: {},
    secondLayer: {},
  });
  const [countryJson, setCountryJson] = useState('');
  const [temporalAggregation, setTemporalAggregation] = useState('');
  const [isFinalDroughtMap, setIsFinalDroughtMap] = useState(false);
  const [applyLayers, setApplyLayers] = useState(false);
  const [isHideViewGraph, setIsHideViewGraph] = useState(false);
  const [selectedDPIndicesMonth, setSelectedDPIndicesMonth] = useState({
    month: '3',
    value: '3',
    id: '3',
    label: '3',
    frequency: '03',
  });
  const [compareSelectedDPIndicesMonth, setCompareSelectedDPIndicesMonth] = useState({
    month: '3',
    value: '3',
    id: '3',
    label: '3',
    frequency: '03',
  });

  const [rainfallAnamolyTime, setRainfallAnamolyTime] = useState({
    start_year: '',
    end_year: '',
    month: '',
  });

  const [compareRainfallAnamolyTime, setCompareRainfallAnamolyTime] = useState({
    start_year: '',
    end_year: '',
    month: '',
  });

  const urlParams = new URLSearchParams(window.location.search);
  const selectedCountry = urlParams.get('Country');

  const pointsRef = useRef();

  const [isOpenPointModal, setIsOpenPointModal] = useState(false);

  const { Weather, DroughtMonitor, boundries } = useSelector(state => ({
    Weather: state.Weather,
    DroughtMonitor: state.DroughtMonitor,
    boundries: state.Map_Preference,
  }));

  const [stateNameFromLocation, setStateNameFromLocation] = useState('');
  //useeffect for getting map layers data from redux
  useEffect(() => {
    if (!isLogedIn()) {
      let path = window.location.pathname;
      if (window.location.search == DDSKEY) {
        let queryParam = window.location.search;
        history.push(
          `/login?prevPath=${path.substring(1, path.length)}&${queryParam.substring(
            1,
            queryParam.length,
          )}`,
        );
      } else {
        history.push(
          `/login?prevPath=${path.substring(1, path.length)}&Country=${selectedCountry}`,
        );
      }
    }
    // dispatch(getDroughtMapSuccess(''));
    dispatch(getMapPreferenceSuccess(''));
    setMapData('');
    setIsOpenWeatherForecastDataModal(false);
    dispatch(getWeatherForecastDataSuccess(''));
    return () => {
      setSelectedParamLegendData('');
      setCompareParamLegendData('');
      dispatch(getMapPreferenceBoundariesSuccess(''));
      dispatch(getWeatherForecastDataSuccess(''));
      dispatch(getFinalDroughtMapSuccess({}));
      dispatch(getFinalDroughtMapFailed({}));
      dispatch(getDroughtMapSuccess({}));
      setShowCharts(false);
    };
  }, []);

  // useEffect (()=>{
  //   if(modal === false){
  //    setIsOpenMapModel(true)
  //   }else{
  //     setIsOpenMapModel(false)
  //   }
  // },[modal])

  const firstRun = useRef(true);
  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    if (boundries?.mapPreferenceData) {
      setGetBoundariesData(boundries?.mapPreferenceData?.data?.result);
    }
  }, [boundries?.mapPreferenceData]);
  useEffect(() => {
    if (getBoundariesData && getBoundariesData?.length) {
      let API_URL = [];
      mapPreferRefs.current = [];
      mapPrefereTileLayerRef.current = [];
      getBoundariesData?.map(item => {
        if (item?.data && item?.data.length) {
          item.data.map(boundary => {
            if (boundary?.boundary_data_url.length) {
              setGeoJsonData([]);
              setGeoJsonAllData([]);
              let mapPreferKeys = Object.keys(mapPreferences);
              if (mapPreferKeys) {
                mapPreferKeys.map(item => {
                  let selectedLayer = mapPreferences[item];
                  if (selectedLayer?.layer && selectedLayer?.layer?.current)
                    selectedLayer?.layer?.current?.clearLayers();
                  selectedLayer.color = '';
                  setMapPreferences(prev => {
                    return {
                      ...prev,
                      [item]: selectedLayer,
                    };
                  });
                });
              }
              setMapPreferenceGeoJsonloading(true);
              let config = {
                name: boundary?.boundarytype?.slug,
                id: boundary?.boundarytype_id,
                color: boundary?.boundarytype?.color?.code,
                category: boundary?.category,
                country_id: boundary?.country_id,
              };
              if (
                currentUserLocation === DEFAULT_COUNTRY.name &&
                selectedLocation?.country?.name === SOUTH_ASIA_CONST.name &&
                boundary?.boundarytype?.slug === MAP_PREFERENCES_lABELS.COUNTRY
              ) {
                API_URL.push(AXIOS_INSTANCE.get(boundary?.boundary_data_india_url, config));
              } else {
                API_URL.push(AXIOS_INSTANCE.get(boundary?.boundary_data_url, config));
              }
            } else {
              let ref = React.createRef();
              ref.current = {
                boundary_id: boundary?.boundarytype_id,
                name: boundary?.boundarytype?.boundarytype,
                layer: React.createRef(),
              };
              mapPreferRefs.current.push(ref);
            }
          });
        }
      });
      Promise.all(API_URL).then(response => {
        let res = JSON.parse(JSON.stringify(response));
        setMapPreferenceGeoJsonloading(false);
        setGeoJsonLayerData(res);
      });
    }
  }, [getBoundariesData]);

  useEffect(() => {
    if (geoJsonLayersData?.length) {
      const countryData123 = geoJsonLayersData?.find(each => each?.config?.name === 'country');

      setCountryJson(countryData123?.data);
    }
  }, [geoJsonLayersData]);

  // useEffect(() => {
  //   if (DroughtMonitor.getDssThresholdData) {
  //     if (DroughtMonitor.getDssThresholdData?.data) {
  //       let data = DroughtMonitor.getDssThresholdData.data;
  //       if (data?.result?.length) {
  //         setThresholdData(data?.result[0]);
  //       }
  //     }
  //   } else {
  //     setThresholdData([]);
  //   }
  // }, [DroughtMonitor.getDssThresholdData]);

  useEffect(() => {
    let selectedGeoJsonData = [];
    if (geoJsonLayersData?.length && geoJsonData?.length) {
      geoJsonData?.map(item => {
        let filteredObj = geoJsonLayersData?.find(
          layer => layer.config.id === item.id && layer.config.country_id === item.country_id,
        );
        if (!_.isEmpty(filteredObj)) {
          filteredObj.fromMapPreferences = item.fromMapPreferences;
          selectedGeoJsonData.push(filteredObj);
        }
      });
    } else {
      selectedGeoJsonData = [];
    }
    setGeoJsonAllData(selectedGeoJsonData);
  }, [geoJsonLayersData, geoJsonData]);

  useEffect(() => {
    if (Weather.weatherForecastData) {
      if (Weather.weatherForecastData) {
        setIsLoading(false);
        setIsPreviewIndicesData(false);
        const { result } = Weather?.weatherForecastData?.data;
        setIsOpenWeatherForecastDataModal(!isOpenWeatherForecastDataModal);
        setWeatherModuleData(result);
      }
    } else {
      setIsOpenWeatherForecastDataModal(false);
      setWeatherModuleData([]);
    }
  }, [Weather.weatherForecastData]);

  useEffect(() => {
    if (!_.isEmpty(selectedModule) && selectedModule?.api_slug === 'drought-decision-support') {
      setShowCharts(false);
    }
    setShowCharts(false);
    dispatch(getWeatherForecastDataSuccess({}));
    setSelectedParam('');
    setCompareSelectedParam('');
    setIsCompareClicked(false);
    setInitialSelectedDate([]);
    setFinalSelectedDate([]);
    setIsFinalDroughtMap(false);
    setPrecipitationThreshold(124);
    setComparePrecipitationThreshold(124);
    setDrySpellPrecipitationThreshold(2.5);
    setCompareDrySpellPrecipitationThresold(2.5);
    setRainfallAnamolyTime({
      start_year: '',
      end_year: '',
      month: '',
    });
    setCompareRainfallAnamolyTime({
      start_year: '',
      end_year: '',
      month: '',
    });
  }, [selectedModule]);

  useEffect(() => {
    if (!_.isEmpty(selectedLocation?.country)) {
      if (selectedLocation?.country?.name !== DEFAULT_COUNTRY?.name) {
        removeGeoJsonLayer(mapPreferences, { layerName: '' });
      } else {
        removeGeoJsonLayer(mapPreferences, { layerName: MAP_PREFERENCES_lABELS.STATES });
      }
    }
  }, [selectedModule, selectedLocation?.country]);

  useEffect(() => {
    setShowCharts(false);
  }, [selectedParam, compareSelectedParam, selectedLocation.country]);

  useEffect(() => {
    if (selectedLocation?.county) {
      setCountryJson('');
    }
  }, [selectedLocation?.country]);
  useEffect(() => {
    if (
      selectedParam.api_slug === OTHER_INDICES_API_INDICES_SLUGS.CROP_HARVEST ||
      selectedParam.api_slug === OTHER_INDICES_API_INDICES_SLUGS.MODIS_FLOOD ||
      selectedParam.api_slug === OTHER_INDICES_API_INDICES_SLUGS.FLOOD
    ) {
      setIsHideViewGraph(true);
    } else {
      setIsHideViewGraph(false);
    }
  }, [selectedParam]);

  useEffect(() => {
    if (splitterRef && splitterRef.current) {
      if (errorData?.showError) {
        splitterRef.current.setState({ secondaryPaneSize: '30%' });
      } else {
        splitterRef.current.setState({ secondaryPaneSize: '70%' });
      }
    }
  }, [errorData]);

  useEffect(() => {
    if (DroughtMonitor.isGetFinalDroughtMapRequesting) {
      setIsLoading(true);
    } else if (DroughtMonitor.getFinalDroughtMapData) {
      setIsLoading(false);
      setIsPreviewIndicesData(false);
    } else if (DroughtMonitor.getFinalDroughtMapDataError) {
      if (DroughtMonitor.getFinalDroughtMapDataError?.response) {
        const {
          data: {
            response: { data },
          },
        } = DroughtMonitor?.getFinalDroughtMapDataError?.response;
        if (!_.isEmpty(data) && data?.message) {
          showError(data?.message);
        } else {
          if (data?.exception) {
            showError(data?.exception);
          }
        }
      }
      setIsLoading(false);
      setIsPreviewIndicesData(false);
    }
  }, [
    DroughtMonitor.isGetFinalDroughtMapRequesting,
    DroughtMonitor.getFinalDroughtMapData,
    DroughtMonitor.getFinalDroughtMapDataError,
  ]);

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (DroughtMonitor.isGetDroughtMapRequesting) {
      setIsLoading(true);
    }
    if (DroughtMonitor.getDroughtMapData) {
      removeMapLayers(selectedParamLegendData, compareParamLegendData, layerControl);
      const { response } = DroughtMonitor.getDroughtMapData;
      if (Array.isArray(response?.data)) {
        if (response?.isDroughtModule) {
          setMapData(response?.data);
        }
      } else {
        const { result } = response?.isDroughtModule ? response?.data : { result: '' };
        setMapData(result);
      }
      setIsLoading(false);
      setIsPreviewIndicesData(false);
    } else if (DroughtMonitor.getDroughtMapDataError) {
      const {
        data: { response },
      } = DroughtMonitor.getDroughtMapDataError?.response;
      if (
        response === undefined &&
        DroughtMonitor.getDroughtMapDataError?.response?.data?.message === 'Network Error'
      ) {
        showError(t('DROUGHT_MONITORING.API_FAILURE_ERROR'));
      }
      if (response?.data?.exception) {
        showError(response?.data?.exception);
      } else if (response?.data?.code === 400) {
        removeMapLayers(selectedParamLegendData, compareParamLegendData, layerControl);
        if (!_.isEmpty(response?.data?.errors)) {
          let errorsObj = response?.data?.errors;
          if (!_.isEmpty(errorsObj)) {
            const values = Object.values(errorsObj);
            showError(values[0]);
          }
        } else {
          showError(response?.data?.message);
        }
      }

      setIsLoading(false);
      setIsPreviewIndicesData(false);
    } else if (DroughtMonitor.getDroughtMapDataNetworkError) {
      //setIsLoading(false);
    }
  }, [
    DroughtMonitor.getDroughtMapData,
    DroughtMonitor.isGetDroughtMapRequesting,
    DroughtMonitor.getDroughtMapDataError,
    DroughtMonitor.getDroughtMapDataNetworkError,
  ]);

  useEffect(() => {
    if (DroughtMonitor.getFinalDroughtMapData) {
      if (DroughtMonitor?.getFinalDroughtMapData?.data) {
        removeMapLayers(selectedParamLegendData, compareParamLegendData, layerControl);
        let { result } = DroughtMonitor?.getFinalDroughtMapData?.data;
        if (result) {
          setMapData(result);
        }
      }
    } else if (DroughtMonitor.getFinalDroughtMapDataError) {
      if (DroughtMonitor.getFinalDroughtMapDataError?.response?.data) {
        let { response } = DroughtMonitor.getFinalDroughtMapDataError?.response?.data;
        if (response?.data?.code === 400) {
          removeMapLayers(selectedParamLegendData, compareParamLegendData, layerControl);
          if (!_.isEmpty(response?.data?.errors)) {
            let errorsObj = response?.data?.errors;
            if (!_.isEmpty(errorsObj)) {
              const values = Object.values(errorsObj);
              showError(values[0]);
            }
          } else {
            showError(response?.data?.message);
          }
        }
        setIsLoading(false);
        setIsPreviewIndicesData(false);
      }
    }
  }, [DroughtMonitor.getFinalDroughtMapData, DroughtMonitor.getFinalDroughtMapDataError]);

  useEffect(() => {
    if (DroughtMonitor.isCheckDroughtCriteriaRequesting) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
      setIsPreviewIndicesData(false);
    }
  }, [DroughtMonitor.isCheckDroughtCriteriaRequesting]);

  //useeffect for getting graph data from redux
  useEffect(() => {
    if (DroughtMonitor.isGetDroughtTimeseriesRequesting) {
      setIsGraphLoading(true);
      setGraphData([]);
    }
    if (DroughtMonitor.getDroughtTimeseriesData) {
      const { result } = DroughtMonitor.getDroughtTimeseriesData.data;
      if (selectedModule?.api_slug === 'drought-decision-support') {
        setIsLoading(false);
        setIsPreviewIndicesData(false);
      }
      let mapSeriesdata = {};
      let temporaryArrayData = [];
      temporaryArrayData.push(result.graph_data);
      mapSeriesdata.graph_data = temporaryArrayData;

      setGraphData(mapSeriesdata);
      setIsGraphLoading(false);
      setIsOpenAlertIndicesTable(!isOpenAlertIndicesTable);
      let aggregation = _.cloneDeep(temporalAggregation);
      let paramData = _.cloneDeep(selectedParam);
      let compareParamData = _.cloneDeep(compareSelectedParam);
      let regionData = _.cloneDeep(selectedLocation?.region);
      let startDate = _.cloneDeep(initialSelectedDate);
      let location = _.cloneDeep(selectedLocation);
      setChartParamsData({
        temporalAggregation: aggregation,
        selectedParam: paramData,
        compareParam: compareParamData,
        region: regionData,
        initialSelectedDate: startDate,
        location: location,
      });
    } else if (DroughtMonitor.getDroughtTimeseriesDataError) {
      setGraphData([]);
      const {
        data: { response },
      } = DroughtMonitor.getDroughtTimeseriesDataError?.response;
      if (
        response === undefined &&
        DroughtMonitor.getDroughtTimeseriesDataError?.response?.data?.message === 'Network Error'
      ) {
        setErrorData({ showError: true, data: t('DROUGHT_MONITORING.API_FAILURE_ERROR') });
      }
      if (selectedModule?.api_slug === 'drought-decision-support') {
        setIsLoading(false);
        setIsPreviewIndicesData(false);
      }
      if (response?.data?.code === 400) {
        if (!_.isEmpty(response?.data?.errors)) {
          let errorsObj = response?.data?.errors;
          if (!_.isEmpty(errorsObj)) {
            const values = Object.values(errorsObj);
            setErrorData({ showError: true, data: values[0] });
          }
        } else {
          if (selectedModule?.api_slug === 'drought-decision-support') {
            showError(response?.data?.message);
          }
          //setErrorData({ showError: true, data: response?.data?.message });
          // showError(response?.data?.message)
          setErrorData({ showError: true, data: response?.data?.message });
        }
      }
      setIsGraphLoading(false);
    } else if (DroughtMonitor.getDroughtTimeseriesDataNetworkError) {
      if (selectedModule?.api_slug === 'drought-decision-support') {
        setIsLoading(false);
        setIsPreviewIndicesData(false);
      }
      setIsGraphLoading(false);
    }
  }, [
    DroughtMonitor.isGetDroughtTimeseriesRequesting,
    DroughtMonitor.getDroughtTimeseriesData,
    DroughtMonitor.getDroughtTimeseriesDataError,
    DroughtMonitor.getDroughtTimeseriesDataNetworkError,
  ]);

  //useeffect to reset all the map preferences states data when country gets changed
  useEffect(() => {
    removeMapPreferenceLayer(mapPreferRefs, mapPreferencesData);
    setMapPreferencesData([]);
    let selectedLegend = JSON.parse(JSON.stringify(selectedMapPreferLegendData));

    if (selectedLegend.length) {
      selectedLegend.forEach(function(value, i) {
        if (value.add) {
          value.add = false;
        }
      });
      setSelectedMapPreferLegendData(selectedLegend);
    }
    removeMapLayers(selectedParamLegendData, compareParamLegendData, layerControl);
  }, [selectedRegionId, selectedDroughtPhase]);

  useEffect(() => {
    removeMapLayers(selectedParamLegendData, compareParamLegendData, layerControl);
  }, [selectedLocation?.country, selectedLocation?.state, selectedLocation?.district]);

  useEffect(() => {
    removeMapPreferenceLayer(mapPreferRefs, mapPreferencesData);
    setMapPreferencesData([]);
    let selectedLegend = JSON.parse(JSON.stringify(selectedMapPreferLegendData));

    if (selectedLegend.length) {
      selectedLegend.forEach(function(value, i) {
        if (value.add) {
          value.add = false;
        }
      });
      setSelectedMapPreferLegendData(selectedLegend);
    }
  }, [selectedLocation?.country]);

  // compare maps drag feature disabling stopped
  // useEffect(() => {
  //   if (!_.isEmpty(mapControl)) {
  //     mapControl?.on('dividermove', map.dragging.disable());
  //   } else if (!_.isEmpty(map)) {
  //     map?.dragging?.enable();
  //   }
  // }, [mapControl]);

  // useEffect(()=>{
  //   if(mapUrl?.url){
  //     if(map.hasLayer(mapUrl?.layer)){
  //       mapUrl?.layer?.bringToFront();
  //     }
  //   }
  // },[mapUrl])

  useEffect(() => {
    if (
      (selectedMapPreferLegendData?.length && selectedMapPreferLegendData?.every(i => !i.add)) ||
      !selectedMapPreferLegendData?.length
    ) {
      if (!_.isEmpty(layerControl?.firstLayer) && !_.isEmpty(layerControl?.secondLayer)) {
        let array = [];
        array.push(layerControl?.firstLayer);
        array.push(layerControl?.secondLayer);
        setIndexLayers(array);
      } else if (mapUrl?.url) {
        setMapLayerOpacity({ layer: mapUrl?.layer });
        let array = [];
        array.push(mapUrl?.layer);
        setIndexLayers(array);
      }
    } else if (selectedMapPreferLegendData?.length) {
      if (mapPreferUrl?.url) {
        let array = [];
        array.push(mapPreferUrl?.layer);
        setIndexLayers(array);
        setMapLayerOpacity({ layer: mapPreferUrl?.layer });
      }
    }
  }, [selectedMapPreferLegendData]);

  useEffect(() => {
    if (
      (selectedParamLegendData?.length && selectedParamLegendData?.every(i => !i.add)) ||
      !selectedParamLegendData?.length
    ) {
      if (mapPreferUrl?.url) {
        let array = [];
        array.push(mapPreferUrl?.layer);
        setIndexLayers(array);
        setMapLayerOpacity({ layer: mapPreferUrl?.layer });
      }
    } else if (
      selectedParamLegendData?.length &&
      ((compareParamLegendData?.length && compareParamLegendData?.every(i => !i.add)) ||
        !compareParamLegendData?.length)
    ) {
      if (mapUrl?.url) {
        let array = [];
        array.push(mapUrl?.layer);
        setIndexLayers(array);
        setMapLayerOpacity({ layer: mapUrl?.layer });
      }
    }
  }, [selectedParamLegendData]);

  useEffect(() => {
    if (
      (compareParamLegendData?.length && compareParamLegendData?.every(i => !i.add)) ||
      !compareParamLegendData?.length
    ) {
      if (mapPreferUrl?.url) {
        let array = [];
        array.push(mapPreferUrl?.layer);
        setIndexLayers(array);
        setMapLayerOpacity({ layer: mapPreferUrl?.layer });
      }
    } else if (compareParamLegendData?.length) {
      if (!_.isEmpty(layerControl?.firstLayer) && !_.isEmpty(layerControl?.secondLayer)) {
        let array = [];
        array.push(layerControl?.firstLayer);
        array.push(layerControl?.secondLayer);
        setIndexLayers(array);
      }
    }
  }, [compareParamLegendData]);

  useEffect(() => {
    if (!_.isEmpty(indexLayers)) {
      addOpacitySlider();
      if (indexLayers?.length) {
        indexLayers.map(l => l.bringToFront());
      }
    }
  }, [indexLayers]);

  // useEffect(()=>{
  //   if(mapPreferUrl?.url){
  //     if(map.hasLayer(mapPreferUrl?.layer)){
  //       mapPreferUrl?.layer?.bringToFront();
  //     }
  //   }
  // },[mapPreferUrl])

  //useeffect for adding drought Indices layers and legend into the map
  useEffect(() => {
    if (!_.isEmpty(mapData)) {
      const currentParamRef = layerRef.current[selectedParam?.paramIndex];
      let map_data = '';
      let opacityControl = '';
      let legendArray = [...selectedParamLegendData];
      let compareLegendArray = [...compareParamLegendData];
      let legendObj = {};
      let compareLegendObj = {};

      if (Array.isArray(mapData)) {
        let data1 = mapData[0]?.map_data;
        let data2 = mapData[1]?.map_data;

        if (data1?.code === 400 || data2?.code === 400) {
          const filteredMapData = mapData.find(i => i?.map_data?.code === 400);
          showError(filteredMapData?.map_data?.message);
          return false;
        }
        compareLegendObj = { ...data1 };
        compareLegendObj.id = selectedParam.name;
        compareLegendObj.add = true;
        compareLegendObj.parameter = selectedParam.name;
        compareLegendObj.dataSource = selectedParam.drought_indices_category_name;
        compareLegendObj.measure = data1?.units || '';
        compareLegendObj.fromMapPreferences = false;
        if (selectedParam.api_slug == INDICES_PARAMS.RAINFALL_ANAMOLY.api_slug) {
          compareLegendObj.selectedDates = {
            startDate: `01-${rainfallAnamolyTime?.month?.value}-${rainfallAnamolyTime.end_year?.value}`,
            endDate: `${rainfallAnamolyTime?.month?.days}-${rainfallAnamolyTime?.month?.value}-${rainfallAnamolyTime.end_year?.value}`,
          };
        } else if (selectedModule?.api_slug === DROUGHT_MODULES_CONST[2].api_slug) {
          compareLegendObj.selectedDates = {
            startDate: ``,
            endDate: ``,
          };
        } else {
          compareLegendObj.selectedDates = {
            startDate: moment(initialSelectedDate.startDate).format('DD-MM-YYYY'),
            endDate: moment(initialSelectedDate.endDate).format('DD-MM-YYYY'),
          };
        }

        legendObj = { ...data2 };
        legendObj.id = compareSelectedParam.name;
        legendObj.add = true;
        legendObj.parameter = compareSelectedParam.name;
        legendObj.dataSource = compareSelectedParam.drought_indices_category_name;
        legendObj.measure = data2?.units || '';
        legendObj.fromMapPreferences = false;
        if (compareSelectedParam.api_slug == INDICES_PARAMS.RAINFALL_ANAMOLY.api_slug) {
          legendObj.selectedDates = {
            startDate: `01-${compareRainfallAnamolyTime?.month?.value}-${compareRainfallAnamolyTime.end_year?.value}`,
            endDate: `${compareRainfallAnamolyTime?.month?.days}-${compareRainfallAnamolyTime?.month?.value}-${compareRainfallAnamolyTime.end_year?.value}`,
          };
        } else if (selectedModule?.api_slug === DROUGHT_MODULES_CONST[2].api_slug) {
          legendObj.selectedDates = {
            startDate: ``,
            endDate: ``,
          };
        } else {
          legendObj.selectedDates = {
            startDate: moment(finalSelectedDate.startDate).format('DD-MM-YYYY'),
            endDate: moment(finalSelectedDate.endDate).format('DD-MM-YYYY'),
          };
        }
        if (!_.isEmpty(currentParamRef) && !_.isEmpty(currentParamRef?.current)) {
          currentParamRef.current._url = data1.map_url;
          currentParamRef.current._url1 = data2.map_url;
          removeMapLayers(selectedParamLegendData, compareParamLegendData, layerControl);

          if (currentParamRef.current._url1) {
            const firstLayer = L.tileLayer(currentParamRef.current._url);
            firstLayer?.addTo(map);
            const secondLayer = L.tileLayer(currentParamRef.current._url1);
            secondLayer?.addTo(map);
            let updatedIndexLayer = [...indexLayers];
            updatedIndexLayer.push(firstLayer);
            updatedIndexLayer.push(secondLayer);
            setIndexLayers(updatedIndexLayer);
            const control = L.control.sideBySide(firstLayer, secondLayer);
            setLayerControl({ firstLayer, secondLayer });
            setMapControl(control);
            control?.addTo(map);
          }
        }
        compareLegendArray.push(compareLegendObj);
        setCompareParamLegendData(compareLegendArray);
      } else {
        if (mapData?.map_data && !_.isEmpty(mapData.map_data)) {
          map_data = mapData?.map_data;
        }
        if (map_data.code === 400) {
          showError('No record found . Please select different date range');
          return false;
        }
        legendObj = { ...map_data };
        legendObj.id = selectedParam.name;
        legendObj.add = true;
        legendObj.parameter = isFinalDroughtMap ? 'Drought Map' : selectedParam.name;
        legendObj.dataSource = selectedParam.drought_indices_category_name;
        legendObj.measure = map_data?.units || '';
        legendObj.fromMapPreferences = false;
        if (selectedParam.api_slug == INDICES_PARAMS.RAINFALL_ANAMOLY.api_slug) {
          legendObj.selectedDates = {
            startDate: `01-${rainfallAnamolyTime?.month?.value}-${rainfallAnamolyTime.end_year?.value}`,
            endDate: `${rainfallAnamolyTime?.month?.days}-${rainfallAnamolyTime?.month?.value}-${rainfallAnamolyTime.end_year?.value}`,
          };
        } else if (selectedModule?.api_slug === DROUGHT_MODULES_CONST[2].api_slug) {
          legendObj.selectedDates = {
            startDate: '',
            endDate: '',
          };
        } else {
          legendObj.selectedDates = {
            startDate: moment(initialSelectedDate.startDate).format('DD-MM-YYYY'),
            endDate: moment(initialSelectedDate.endDate).format('DD-MM-YYYY'),
          };
        }

        // TODO: need to change looping through layers
        if (!_.isEmpty(currentParamRef) && !_.isEmpty(currentParamRef?.current)) {
          currentParamRef.current?.eachLayer(layer => {
            if (map_data) {
              layer.setUrl(map_data?.map_url);
              setMapUrl({ name: selectedParam.name, url: map_data?.map_url, layer });
              let updatedIndexLayer = [...indexLayers];
              updatedIndexLayer.push(layer);
              setIndexLayers(updatedIndexLayer);
              setMapLayerOpacity({
                layer,
              });
            }
          });
          if (layerRef?.current?.length) {
            layerRef.current.map((i, index) => {
              removeLayers(layerRef.current[index]);
            });
          }
        }
        if (!_.isEmpty(currentParamRef) && currentParamRef?.current !== null) {
          addLayers(currentParamRef);
        }
      }

      if (selectedParamLegendData.length) {
        legendArray.forEach((value, i) => {
          if (value.add) {
            value.add = false;
          }
        });
      }

      if (map?.opacityControl) {
        map.removeControl(opacityControl);
      }
      legendArray.push(legendObj);
      setSelectedParamLegendData(legendArray);
    }
  }, [mapData]);

  //use effect for adding the opacity control slider in map
  // useEffect(() => {
  //   if (!_.isEmpty(mapLayerOpacity) && !_.isEmpty(mapLayerOpacity?.layer)) {
  //     if (!_.isEmpty(opacityControl)) map.removeControl(opacityControl);
  //     let customOpacityControl = '';
  //     customOpacityControl = L.control.opacity(mapLayerOpacity, {
  //       collapsed: false,
  //       position: 'topright',
  //     });
  //     setOpacityControl(customOpacityControl);
  //     customOpacityControl?.addTo(map);
  //   }
  // }, [mapLayerOpacity]);

  useEffect(() => {
    if (mapPreferencesData?.length) {
      let MapPrefereArr = [];
      mapPreferencesData?.map(item => {
        let legendItem = JSON.parse(JSON.stringify(item.result));
        legendItem.parameter =
          item.slug === MAP_PREFERENCES_lABELS.JRC
            ? t('WEATHER_FORECAST.MAP_PREFERENCES.WATER_OCCURRENCE')
            : item.slug === MAP_PREFERENCES_lABELS.MOD44
            ? t('WEATHER_FORECAST.MAP_PREFERENCES.PERMANENT_WATER')
            : item?.legendName;
        legendItem.dataSource = item?.category;
        legendItem.add = true;
        legendItem.measure = legendItem.units;
        legendItem.id = item?.dataSource;
        legendItem.legendId = item.id;
        legendItem.fromMapPreferences = true;
        if (selectedMapPreferLegendData?.length) {
          let isDuplicate = selectedMapPreferLegendData?.find(
            x => x.legendId === item.id && item.add === true,
          );
          if (_.isEmpty(isDuplicate)) {
            MapPrefereArr.push(legendItem);
          }
        } else {
          MapPrefereArr.push(legendItem);
        }
      });
      setSelectedMapPreferLegendData(MapPrefereArr);
    }
  }, [mapPreferencesData]);

  useEffect(() => {
    if (geoJsonAllData?.length) {
      geoJsonAllData.map(item => {
        let mapGeojsonItem = Object.keys(mapPreferences).find(x => x === item.config.name);
        let selectedLayer = mapPreferences[mapGeojsonItem];
        if (selectedLayer?.layer && selectedLayer?.layer?.current) {
          let data = JSON.parse(JSON.stringify(item.data));
          if (
            item.config.name !== MAP_PREFERENCES_lABELS.DISTRICTS &&
            item.config.name !== MAP_PREFERENCES_lABELS.TALUKAS
          ) {
            selectedLayer.country = selectedLocation?.country;
            selectedLayer?.layer?.current?.clearLayers();

            selectedLayer.layer.current.addData(data);
            selectedLayer.color = item.config.color;
            selectedLayer.data = data;
            setMapPreferences(prev => {
              return {
                ...prev,
                [mapGeojsonItem]: selectedLayer,
              };
            });
          }
        }
      });
    }
  }, [geoJsonAllData, mapPreferences?.state?.layer?.current, selectedLocation?.country]);

  useEffect(() => {
    if (geoJsonAllData?.length) {
      geoJsonAllData.map(item => {
        let mapGeojsonItem = Object.keys(mapPreferences).find(x => x === item.config.name);
        let selectedLayer = mapPreferences[mapGeojsonItem];
        if (selectedLayer?.layer && selectedLayer?.layer?.current) {
          if (
            !_.isEmpty(selectedLocation?.district) ||
            selectedLocation?.district?.name !== 'select'
          ) {
            let filteredLayer = returnFilteredGeoJsonData(
              selectedLayer,
              item,
              selectedLocation?.district,
              MAP_PREFERENCES_lABELS.DISTRICTS,
              MAP_PREFERENCES_lABELS.TALUKAS,
              selectedLocation?.country,
            );
            if (filteredLayer) {
              setMapPreferences(prev => {
                return {
                  ...prev,
                  [mapGeojsonItem]: filteredLayer,
                };
              });
            }
          }

          if (!_.isEmpty(selectedLocation?.state) || selectedLocation?.state?.name !== 'select') {
            let filteredLayer = returnFilteredGeoJsonData(
              selectedLayer,
              item,
              selectedLocation?.state,
              MAP_PREFERENCES_lABELS.STATES,
              MAP_PREFERENCES_lABELS.DISTRICTS,
              selectedLocation?.country,
            );
            if (filteredLayer) {
              setMapPreferences(prev => {
                return {
                  ...prev,
                  [mapGeojsonItem]: filteredLayer,
                };
              });
            }
          }
        }
      });
    }
  }, [geoJsonAllData]);

  useEffect(() => {
    if (geoJsonAllData?.length) {
      geoJsonAllData.map(item => {
        let mapGeojsonItem = Object.keys(mapPreferences).find(x => x === item.config.name);
        let selectedLayer = mapPreferences[mapGeojsonItem];
        if (
          selectedLayer?.layer &&
          selectedLayer?.layer?.current &&
          item.fromMapPreferences &&
          (_.isEmpty(selectedLocation?.state) || selectedLocation?.state?.name === 'select') &&
          item.config.name === MAP_PREFERENCES_lABELS.DISTRICTS
        ) {
          selectedLayer.country = selectedLocation?.country;
          selectedLayer?.layer?.current?.clearLayers();
          selectedLayer.layer.current.addData(item.data);
          selectedLayer.color = item.config.color;
          setMapPreferences(prev => {
            return {
              ...prev,
              [mapGeojsonItem]: selectedLayer,
            };
          });
        }
      });
    }
  }, [geoJsonAllData]);

  useEffect(() => {
    if (geoJsonAllData?.length) {
      geoJsonAllData.map(item => {
        let mapGeojsonItem = Object.keys(mapPreferences).find(x => x === item.config.name);
        let selectedLayer = mapPreferences[mapGeojsonItem];
        if (
          selectedLayer?.layer &&
          selectedLayer?.layer?.current &&
          item.fromMapPreferences &&
          (_.isEmpty(selectedLocation?.district) ||
            selectedLocation?.district?.name === 'select') &&
          item.config.name === MAP_PREFERENCES_lABELS.TALUKAS
        ) {
          selectedLayer.country = selectedLocation?.country;
          selectedLayer?.layer?.current?.clearLayers();
          selectedLayer.layer.current.addData(item.data);
          selectedLayer.color = item.config.color;
          setMapPreferences(prev => {
            return {
              ...prev,
              [mapGeojsonItem]: selectedLayer,
            };
          });
        }
      });
    }
  }, [geoJsonAllData]);

  useEffect(() => {
    if (mapPreferencesData?.length) {
      if (mapPreferRefs?.current?.length) {
        mapPreferencesData?.map(item => {
          let selectedMapPreferences = mapPreferRefs.current.find(
            x => x.current.boundary_id === item.id,
          );
          let currentParamRef = selectedMapPreferences.current?.layer;
          currentParamRef?.current?.eachLayer(mapLayer => {
            let updatedIndexLayer = [...indexLayers];
            updatedIndexLayer.push(mapLayer);
            setIndexLayers(updatedIndexLayer);
            mapLayer.setUrl(item?.result?.map_url);
            setMapPreferUrl({
              name: item?.dataSource,
              url: item?.result?.map_url,
              layer: mapLayer,
            });
            setMapLayerOpacity({
              layer: mapLayer,
            });
          });
          if (currentParamRef && currentParamRef?.current) {
            addLayers(currentParamRef);
          }
        });
      }
    }
  }, [mapPreferencesData, mapPreferRefs.current]);

  const returnFilteredGeoJsonData = (
    selectedLayer,
    item,
    location,
    propertyName,
    locationName,
    countryData,
  ) => {
    if (selectedLayer?.layer && selectedLayer?.layer?.current) {
      let data = JSON.parse(JSON.stringify(item.data));
      let selectedCountryData = GEOJSON_COUNTRY_DATA?.find(
        item => item.selectedCountry === selectedLocation?.country?.name,
      );
      let labelprop = selectedCountryData ? selectedCountryData[propertyName] : '';
      if (item.config.name === locationName && !_.isEmpty(location)) {
        if (locationName === 'districts_or_cities') {
          setStateNameFromLocation(location?.name);
        }
        data.features = item.data?.features.filter(x => {
          if (x?.properties[labelprop]?.toUpperCase() === location?.name?.toUpperCase()) {
            if (
              locationName === 'talukas' &&
              countryData?.name === 'India' &&
              stateNameFromLocation?.toUpperCase() === x?.properties['stname']
            ) {
              selectedLayer.country = selectedLocation?.country;
              selectedLayer?.layer?.current?.clearLayers();
              return x;
            } else if (
              stateNameFromLocation?.toUpperCase() !== 'BIHAR' &&
              stateNameFromLocation?.toUpperCase() !== 'MAHARASHTRA'
            ) {
              selectedLayer.country = selectedLocation?.country;
              selectedLayer?.layer?.current?.clearLayers();
              return x;
            } else {
              if (locationName === 'districts_or_cities') {
                selectedLayer.country = selectedLocation?.country;
                selectedLayer?.layer?.current?.clearLayers();
                return x;
              }
            }
          }
        });
        selectedLayer.layer.current.addData(data);
        selectedLayer.color = item.config.color;
      }
      return selectedLayer;
    }
  };

  const removeGeoJsonLayer = (mapPreferences, { layerName }) => {
    let mapPreferKeys = Object.keys(mapPreferences);
    if (mapPreferKeys) {
      mapPreferKeys.map(item => {
        let selectedLayer = mapPreferences[item];
        if (
          selectedLayer?.layer &&
          selectedLayer?.layer?.current &&
          (_.isEmpty(layerName) || (!_.isEmpty(layerName) && layerName !== item))
        ) {
          selectedLayer?.layer?.current?.clearLayers();
          selectedLayer.color = '';
          setMapPreferences(prev => {
            return {
              ...prev,
              [item]: selectedLayer,
            };
          });
        }
      });
    }
  };

  const getMapPreferencesGeojsonData = geoJsonList => {
    let data = JSON.parse(JSON.stringify(geoJsonList));
    let mapPreferKeys = Object.keys(mapPreferences);
    if (mapPreferKeys) {
      mapPreferKeys.map(item => {
        let selectedLayer = mapPreferences[item];
        if (selectedLayer?.layer && selectedLayer?.layer?.current)
          selectedLayer?.layer?.current?.clearLayers();
        selectedLayer.color = '';
        setMapPreferences(prev => {
          return {
            ...prev,
            [item]: selectedLayer,
          };
        });
      });
    }
    setGeoJsonData(data);
  };

  const removeMapLayers = (selectedParamLegendData, compareParamLegendData, layerControl) => {
    let previousParamRef = '';
    if (layerRef?.current?.length) {
      layerRef.current.map((i, index) => {
        previousParamRef = layerRef.current[index];
        removeLayers(previousParamRef);
      });
    }
    let legendArray = [...selectedParamLegendData];
    let compareLegendArray = [...compareParamLegendData];
    if (selectedParamLegendData.length) {
      legendArray.forEach((value, i) => {
        if (value.add) {
          value.add = false;
        }
      });
    }
    if (compareParamLegendData.length) {
      compareLegendArray.forEach((value, i) => {
        if (value.add) {
          value.add = false;
        }
      });
    }
    if (!_.isEmpty(layerControl?.firstLayer)) {
      map?.removeControl(layerControl?.firstLayer);
    }
    if (!_.isEmpty(layerControl?.secondLayer)) {
      map?.removeControl(layerControl?.secondLayer);
    }
    if (!_.isEmpty(mapControl)) {
      map?.removeControl(mapControl);
    }
    setMapControl('');
    setLayerControl({
      firstLayer: {},
      secondLayer: {},
    });
    setSelectedParamLegendData(legendArray);
    setCompareParamLegendData(compareLegendArray);
  };

  // function to change the map attributes inside the map container
  function ChangeView({ center, zoom }) {
    const mapEvents = useMapEvents({
      zoomend: () => {
        setCordinateAndZoom(prev => {
          return {
            ...prev,
            cordinates: mapEvents.getCenter(),
            zoom: mapEvents.getZoom(),
          };
        });
      },
      dragend: () => {
        setCordinateAndZoom(prev => {
          return {
            ...prev,
            zoom: mapEvents.getZoom(),
            cordinates: mapEvents.getCenter(),
          };
        });
      },
    });

    const map = useMap();
    setInterval(function() {
      if (!_.isEmpty(map) && !_.isEmpty(map?._layers)) map.invalidateSize();
    }, 500);
    map.setView(center, zoom);
    return null;
  }

  //function to handle map preferences model toggling
  const handleMapModel = () => {
    setIsOpenMapModel(!isOpenMapModel);
  };

  //function to set map reference
  const setMapReference = mapInstance => {
    mapRef.current = mapInstance;
    // Initialize featureGroupRef with a new Leaflet FeatureGroup instance
    featureGroupRef.current = L.featureGroup().addTo(mapInstance);

    setMap(mapInstance);
  };

  //function to set cordinates from side filter file
  const handleCordinates = (cordinates, zoom) => {
    if (applyLayers) {
      setApplyLayers(false);
    }
    setCordinateAndZoom(prev => {
      return {
        ...prev,
        locationCordinates: cordinates,
        cordinates,
        zoom,
        searchMarkerCoordinates: '',
      };
    });
  };

  const handleIsCompare = isClicked => {
    setIsCompareClicked(isClicked);
  };

  // add layer opacity slider to the map
  function addOpacitySlider() {
    // if (!window.map) console.log('not ready');
    if (indexLayers?.length) {
      L.Control.OpacitySlider = L.Control.extend({
        sliding: false,
        updateLayerOpacity: function(opacity) {
          indexLayers.forEach((layer, i) => {
            layer.setOpacity(opacity / 100);
          });
        },
        onAdd: function(map) {
          if (opacityControl) {
            map?.removeControl(opacityControl);
          }
          var container = L.DomUtil.create('div', 'opacityContainer');
          container.innerHTML =
            '<div id=layer-opacity-control><input type="range" min="1" max="100" value="100" defaultValue="100" class="opacityslider"></div>';
          if (!L.Browser.touch) {
            L.DomEvent.disableClickPropagation(container);
            L.DomEvent.on(container, 'mousewheel', L?.DomEvent?.stopPropagation);
          } else {
            L.DomEvent.on(container, 'mousedrag click', L?.DomEvent?.stopPropagation);
          }
          let slider = container.querySelector('.opacityslider');
          slider.onclick = e => {
            this.updateLayerOpacity(e.target.value);
          };
          slider.onmousedown = e => {
            this.sliding = true;
            this.updateLayerOpacity(e.target.value);
          };
          slider.onmousemove = e => {
            e?.stopPropagation();
            if (this.sliding) this.updateLayerOpacity(e.target.value);
          };
          slider.onmouseup = e => {
            this.sliding = false;
          };
          return container;
        },
      });
      L.control.opacitySlider = function(opts) {
        return new L.Control.OpacitySlider(opts);
      };
      let customOpacityControl = L.control.opacitySlider({ position: 'topright' });
      customOpacityControl.addTo(map);
      setOpacityControl(customOpacityControl);
    }
  }

  //function to add layers in the map
  const addLayers = selectedParamRef => {
    if (mapRef.current && selectedParamRef.current) {
      const leafletMapRef = mapRef.current;
      const firstLayer = selectedParamRef.current;
      [firstLayer].forEach((layer, index) => {
        leafletMapRef.addLayer(layer);
      });
    }
    return false;
  };

  //function to remove layers from map
  const removeLayers = selectedParamRef => {
    if (mapRef && mapRef?.current && selectedParamRef && selectedParamRef?.current) {
      const map = mapRef.current;
      const firstLayer = selectedParamRef.current;
      [firstLayer].forEach(layer => map.removeLayer(layer));
    }
  };

  const removeMapPreferenceLayer = (mapPreferRefs, mapPreferencesData) => {
    if (mapPreferRefs?.current?.length && mapPreferencesData?.length) {
      mapPreferencesData?.map(item => {
        let selectedMapIndex = _.findIndex(
          mapPreferRefs.current,
          list => list.current.boundary_id === item.id,
        );
        let previousParamRef = mapPreferRefs?.current[selectedMapIndex].current.layer;
        if (previousParamRef && previousParamRef?.current) {
          removeLayers(previousParamRef);
        }
      });
    }
  };

  const getMapPreferencesData = mapPreferencesLayerData => {
    /** removing previously added map preferences layers before setting the state */
    removeMapPreferenceLayer(mapPreferRefs, mapPreferencesData);
    setIndexLayers([]);
    /** setting map preferences state*/
    let arr = JSON.parse(JSON.stringify(mapPreferencesLayerData));
    let paramData = '';
    let paramArr = '';
    if (selectedMapPreferLegendData?.length) {
      paramData = JSON.parse(JSON.stringify(selectedMapPreferLegendData));
      paramData?.map((item, index) => {
        if (item.add) {
          let legendItem = { ...item };
          legendItem.add = false;
          paramData[index] = legendItem;
        }
      });
      paramArr = [...paramData];
    }
    setSelectedMapPreferLegendData(paramArr);
    setMapPreferencesData(arr);
  };

  const handleSelectedFilterRegion = id => {
    removeMapLayers(selectedParamLegendData, compareParamLegendData, layerControl);
    setSelectedRegionId(id);
  };

  const handleProps = props => {
    if (applyLayers) {
      setApplyLayers(false);
    }
    setTemporalAggregation(props.temporalAggregation);
    setState(props.state);
    setInitialSelectedDate(props.initialSelectedDate);
    // setSelectedParam(props.selectedParam);
  };

  //function for compare props
  const handleCompareProps = props => {
    setIsCompareClicked(props?.isCompareClicked);
    setFinalSelectedDate(props.finalSelectedDate);
    setCompareSelectedParam(props.compareSelectedParam);
    setCompareRainfallAnamolyTime({
      start_year: '',
      end_year: '',
      month: '',
    });
  };

  //function to set all indices
  const handleParams = param => {
    let selectedLegend = JSON.parse(JSON.stringify(selectedMapPreferLegendData));

    if (selectedLegend.length) {
      selectedLegend.forEach(function(value, i) {
        if (value.add) {
          value.add = false;
        }
      });
      setSelectedMapPreferLegendData(selectedLegend);
    }
    removeMapPreferenceLayer(mapPreferRefs, mapPreferencesData);
    setMapPreferencesData([]);
    removeMapLayers(selectedParamLegendData, compareParamLegendData, layerControl);

    layerRef.current = [];
    if (param?.length) {
      param?.map(item => {
        const ref = React.createRef();
        layerRef.current.push(ref);
      });
      setParams(param);
    }
  };

  const handleEachFeatureChange = (countryData, locationType, feat, layer) => {
    let selectedCountryData = GEOJSON_COUNTRY_TOOLTIP_DATA?.find(
      item => item.selectedCountry === countryData?.name,
    );
    let labelprop = selectedCountryData ? selectedCountryData[locationType] : '';
    if (labelprop && feat.properties[labelprop])
      layer.bindTooltip(feat.properties[labelprop] + '', { sticky: true, direction: 'auto' });

    /** TODO: alternative solution */

    // if(!_.isEmpty(layer) && !_.isEmpty(feat?.properties)){
    //   layer?.on({
    //     'mousemove': (e) => {
    //       const name = e.target.feature.properties['MJ_BASIN__'] ? e.target.feature.properties['MJ_BASIN__'] : '';
    //       layer?.bindTooltip(name)
    //       layer?.openTooltip(e?.latlng);
    //     },
    //     // 'mouseout': () => {
    //     //   layer?.unbindTooltip();
    //     //   layer?.closeTooltip();
    //     // },
    //   });
    // }
  };

  //function to set selected indices
  const handleSelectedParam = param => {
    if (applyLayers) {
      setApplyLayers(false);
    }
    if (!_.isEmpty(selectedParam)) setPreviousParam(selectedParam);
    setSelectedParam(param);
    if (_.isEmpty(param)) {
      let selectedLegend = JSON.parse(JSON.stringify(selectedMapPreferLegendData));

      if (selectedLegend.length) {
        selectedLegend.forEach(function(value, i) {
          if (value.add) {
            value.add = false;
          }
        });
        setSelectedMapPreferLegendData(selectedLegend);
      }
      removeMapPreferenceLayer(mapPreferRefs, mapPreferencesData);
      setMapPreferencesData([]);
      removeMapLayers(selectedParamLegendData, compareParamLegendData, layerControl);
    }
    setRainfallAnamolyTime({
      start_year: '',
      end_year: '',
      month: '',
    });
  };

  //function to set location
  const handleLocation = (country, state, district, basin, region) => {
    if (applyLayers) {
      setApplyLayers(false);
    }
    setSelectedLocation(prev => {
      return {
        ...prev,
        country,
        state: !_.isEmpty(state) ? state : '',
        district: !_.isEmpty(district) ? district : '',
        basin,
        region,
      };
    });
  };

  //function to set compare location
  const handleCompareLocation = (country, state, district) => {
    setCompareLocation(prev => {
      return {
        ...prev,
        country,
        state: !_.isEmpty(state) ? state : '',
        district: !_.isEmpty(district) ? district : '',
      };
    });
  };

  const handlePrecipitationThreshold = precipitationThreshold => {
    if (precipitationThreshold) setPrecipitationThreshold(precipitationThreshold);
  };
  const handleComparePrecipitationThreshold = precipitationThreshold => {
    if (precipitationThreshold) setComparePrecipitationThreshold(precipitationThreshold);
  };

  const handleDrySpellPrecipitationThresold = precipitationThreshold => {
    if (precipitationThreshold) setDrySpellPrecipitationThreshold(precipitationThreshold);
  };

  const handleCompareDrySpellPrecipitationThresold = precipitationThreshold => {
    if (precipitationThreshold) setCompareDrySpellPrecipitationThresold(precipitationThreshold);
  };

  //function to set region
  const handleSelectedRegion = region => {
    let mapLayerData = [];
    let polyCount = 1;
    let mapData = JSON.parse(JSON.stringify(region));
    if (mapData?.length) {
      mapData.map(item => {
        let x = Object.assign(item, {});
        if (item.layerType === 'polygon') {
          x.latlngs = item.latlngs.map(v => [v[1], v[0]]);
          x?.latlngs?.push(item.latlngs[0]);
          if (!x.hasOwnProperty('polygonCount')) {
            x.polygonCount = polyCount;
            polyCount += 1;
          }
          mapLayerData.push(x);
        } else {
          if (item?.origin === 'manule') {
            x.latlngs = [item.longitude, item.latitude];
          } else {
            x.latlngs = [item.latlngs[1], item.latlngs[0]];
          }

          mapLayerData.push(x);
        }
      });
    }
    setSelectedLocation(prev => {
      return {
        ...prev,
        region: mapLayerData,
      };
    });
  };

  const handleCompareView = compareView => {
    setCompareView(compareView);
  };

  const handleCompareCordinates = cordinates => {
    setCompareCordinates(cordinates);
  };

  const handleCompareRainfallAnamolyStartYearChange = start => {
    if (start) {
      setCompareRainfallAnamolyTime(prev => {
        return {
          ...prev,
          start_year: start,
        };
      });
    }
  };

  const handleCompareRainfallAnamolyEndYearChange = end => {
    if (end) {
      setCompareRainfallAnamolyTime(prev => {
        return {
          ...prev,
          end_year: end,
        };
      });
    }
  };

  const handleCompareRainfallAnamolyMonthChange = month => {
    if (month) {
      setCompareRainfallAnamolyTime(prev => {
        return {
          ...prev,
          month: month,
        };
      });
    }
  };

  const handleRainfallAnamolyStartYearChange = start => {
    if (start) {
      setRainfallAnamolyTime(prev => {
        return {
          ...prev,
          start_year: start,
        };
      });
    }
  };

  const handleRainfallAnamolyEndYearChange = end => {
    if (end) {
      setRainfallAnamolyTime(prev => {
        return {
          ...prev,
          end_year: end,
        };
      });
    }
  };

  const handleRainfallAnamolyMonthChange = month => {
    if (month) {
      setRainfallAnamolyTime(prev => {
        return {
          ...prev,
          month: month,
        };
      });
    }
  };

  useEffect(() => {
    if (
      (!_.isEmpty(selectedModule) && selectedModule?.api_slug === 'drought-decision-support') ||
      isHideViewGraph
    ) {
      const getLeafletDrawEle = document.getElementsByClassName('leaflet-draw');
      const getPointLocationicon = document?.getElementById('point_data_icon');
      if (getLeafletDrawEle && getLeafletDrawEle[0]) {
        getLeafletDrawEle[0]?.classList.add('dds-leaflet-draw-css');
        getPointLocationicon?.classList?.add('location-icon-hide');
      }
    } else if (selectedModule?.api_slug !== 'drought-decision-support' || !isHideViewGraph) {
      const getLeafletDrawEle = document.getElementsByClassName('leaflet-draw');
      const getPointLocationicon = document?.getElementById('point_data_icon');
      if (getLeafletDrawEle && getLeafletDrawEle[0]) {
        getLeafletDrawEle[0]?.classList.remove('dds-leaflet-draw-css');

        getPointLocationicon?.classList?.remove('location-icon-hide');
      }
    }
  }, [selectedModule, isHideViewGraph]);

  //function to call map and time series api
  const handleApplyLayers = applyLayers => {
    if (applyLayers) {
      if (!_.isEmpty(temporalAggregation) && !_.isEmpty(selectedParam)) {
        const requestObj = {};
        let paramSelected = Object.assign({}, selectedParam);
        if (
          selectedRegionId &&
          !_.isEmpty(selectedLocation.country?.value) &&
          _.isEmpty(selectedLocation?.state?.name) &&
          _.isEmpty(selectedLocation?.district?.name)
        )
          requestObj.region_id = selectedRegionId;
        if (selectedModule.api_slug === 'drought-prediction') {
          requestObj.parameter_id = selectedParam?.id;
        }
        if (
          selectedModule?.api_slug === 'drought-monitor-v2' &&
          selectedParam?.id === INDICES_PARAMS?.RAINFALL_ANAMOLY?.id
        ) {
          requestObj.start_year = rainfallAnamolyTime?.start_year?.label.toString() || '';
          requestObj.end_year = rainfallAnamolyTime?.end_year?.label.toString() || '';
          requestObj.month = rainfallAnamolyTime?.month?.value || '';
        }

        if (selectedModule?.api_slug === 'drought-monitor') {
          if (selectedParam?.slug === INDICES_PARAMS.DRY_SPELL.slug) {
            requestObj.precipitation_threshold = drySpellPrecipitationThreshold.toString();
          }
        }

        if (selectedModule?.api_slug === 'drought-decision-support') {
          if (selectedParam?.slug === DRY_SPELL.DRY_SPELL_SLUG) {
            requestObj.precipitation_threshold = drySpellPrecipitationThreshold.toString();
          }
        }

        if (selectedLocation.country?.name !== SOUTH_ASIA_CONST.name) {
          requestObj.country_name = selectedLocation?.country?.name || '';
        }
        if (selectedLocation?.state?.value) {
          requestObj.state_name = selectedLocation?.state?.name || '';
        }
        if (selectedLocation?.district?.value) {
          requestObj.district_name = selectedLocation?.district?.name || '';
        }
        if (selectedLocation?.basin) {
          requestObj.basin_name = selectedLocation?.basin || '';
        }

        requestObj.temporal_aggregation = temporalAggregation.value;
        if (state.min && !isCompareClicked) requestObj.min = Number(state.min);
        if (state.max && !isCompareClicked) requestObj.max = Number(state.max);

        if (!isFinalDroughtMap && isCompareClicked) {
          let initialSelectedObj = JSON.parse(JSON.stringify(requestObj));
          let finalSelectedObj = JSON.parse(JSON.stringify(requestObj));
          let initialRequestResponse, finalRequestResponse;
          let initialSelectedParam = '';
          let compareParamSelected = '';
          initialSelectedParam = Object.assign({}, selectedParam);
          compareParamSelected = Object.assign({}, compareSelectedParam);

          if (compareParamSelected?.id !== INDICES_PARAMS?.RAINFALL_ANAMOLY?.id) {
            finalSelectedObj.start_date = finalSelectedDate.startDate;
            finalSelectedObj.end_date = finalSelectedDate.endDate;
          }

          if (initialSelectedParam?.id !== INDICES_PARAMS?.RAINFALL_ANAMOLY?.id) {
            initialSelectedObj.start_date = initialSelectedDate.startDate;
            initialSelectedObj.end_date = initialSelectedDate.endDate;
          }

          if (selectedModule?.api_slug === 'drought-monitor-v2') {
            if (initialSelectedParam?.id === INDICES_PARAMS.EXTREME_RAINFALL.id) {
              initialSelectedObj.precipitation_threshold = precipitationThreshold.toString();
            }

            if (compareParamSelected?.id === INDICES_PARAMS?.RAINFALL_ANAMOLY?.id) {
              finalSelectedObj.start_year =
                compareRainfallAnamolyTime?.start_year?.label.toString() || '';
              finalSelectedObj.end_year =
                compareRainfallAnamolyTime?.end_year?.label.toString() || '';
              finalSelectedObj.month = compareRainfallAnamolyTime?.month?.value || '';
            }

            if (compareParamSelected?.id === INDICES_PARAMS.EXTREME_RAINFALL.id) {
              finalSelectedObj.precipitation_threshold = comparePrecipitationThreshold.toString();
            }
          }

          if (
            selectedModule?.api_slug === 'drought-monitor' ||
            selectedModule?.api_slug === 'drought-decision-support'
          ) {
            if (compareParamSelected?.id === INDICES_PARAMS.DRY_SPELL.id) {
              finalSelectedObj.precipitation_threshold = compareDrySpellPrecipitationThresold.toString();
            }
          }

          if (selectedModule?.api_slug === 'drought-prediction') {
            finalSelectedObj.parameter_id = compareSelectedParam?.id;
            if (initialSelectedParam?.name === 'SPI' || initialSelectedParam?.name === 'SRI')
              initialSelectedObj.month = selectedDPIndicesMonth?.frequency;
            if (compareParamSelected?.name === 'SPI' || compareParamSelected?.name === 'SRI') {
              finalSelectedObj.month = compareSelectedDPIndicesMonth?.frequency;
            }
          }

          setIsLoading(true);
          // for compare
          if (!_.isEmpty(initialSelectedParam) && !_.isEmpty(compareParamSelected)) {
            initialRequestResponse = initialSelectedParam?.master_server
              ? AXIOS_INSTANCE_MASTER_SERVER.post(
                  `${MASTER_SERVER_BASE_URL}/${initialSelectedParam?.api_url}map`,
                  initialSelectedObj,
                )
              : AXIOS_INSTANCE.post(
                  `${API_BASE_URL}/${initialSelectedParam?.api_url}map`,
                  initialSelectedObj,
                );
            finalRequestResponse = compareParamSelected?.master_server
              ? AXIOS_INSTANCE_MASTER_SERVER.post(
                  `${MASTER_SERVER_BASE_URL}/${compareParamSelected?.api_url}map`,
                  finalSelectedObj,
                )
              : AXIOS_INSTANCE.post(
                  `${API_BASE_URL}/${compareParamSelected?.api_url}map`,
                  finalSelectedObj,
                );
            Promise.all([initialRequestResponse, finalRequestResponse])
              .then(responses => {
                let responsesData = [];
                setIsLoading(false);
                setIsPreviewIndicesData(false);
                responses?.map(item => {
                  const { data } = item;
                  if (data.code === 200) {
                    responsesData.push(data.result);
                  } else {
                    showError(data?.message);
                  }
                });
                dispatch(
                  getDroughtMapSuccess({
                    response: { data: responsesData, isDroughtModule: true },
                  }),
                );
              })
              .catch(error => {
                setIsLoading(false);
                setIsPreviewIndicesData(false);
                if (error?.response) {
                  let errorRes = error.response;
                  if (!_.isEmpty(errorRes?.data?.errors)) {
                    showError(Object.values(errorRes?.data?.errors)[0]);
                  } else if (errorRes?.data?.exception) {
                    showError(errorRes?.data?.exception);
                  } else {
                    showError(errorRes?.data?.message);
                  }
                } else {
                  showError('No records found. please try again');
                }
                removeMapLayers(selectedParamLegendData, compareParamLegendData, layerControl);
                return;
              });
          }
        } else {
          if (isFinalDroughtMap) {
            // let indices_list = [];
            // DroughtIndicators?.impact?.map(item => {
            //   if (item.name !== DSS_MODULE_CONSTS.FINAL_DROUGHT_MAP) {
            //     item?.parameter?.map(p => {
            //       indices_list.push(p.name);
            //     });
            //   }
            // });
            finalDroughtParameterMonthsList?.map(item => {
              if (item.selectedOption) {
                const dates = returnDSSDateFormat(selectedYear, item.selectedOption);
                if (dates) {
                  requestObj[`${item.slug}_start_date`] = dates?.startDate;
                  requestObj[`${item.slug}_end_date`] = dates.endDate;
                }
              }
            });

            // requestObj.indices_list = indices_list;
            // requestObj.start_date = initialSelectedDate.startDate;
            // requestObj.end_date = initialSelectedDate.endDate;
            setIsLoading(true);

            dispatch(getFinalDroughtMapRequest(requestObj));
          } else {
            if (!isFinalDroughtMap) {
              paramSelected = selectedParam;
            }
            if (
              selectedModule?.api_slug === 'drought-prediction' &&
              (paramSelected?.name === 'SPI' || paramSelected?.name === 'SRI')
            ) {
              requestObj.month = selectedDPIndicesMonth?.frequency;
            }

            if (
              selectedModule?.api_slug === 'drought-monitor-v2' &&
              paramSelected?.id === INDICES_PARAMS.EXTREME_RAINFALL.id
            ) {
              requestObj.precipitation_threshold = precipitationThreshold.toString();
            }
            if (paramSelected?.id !== INDICES_PARAMS?.RAINFALL_ANAMOLY?.id) {
              requestObj.start_date = initialSelectedDate.startDate;
              requestObj.end_date = initialSelectedDate.endDate;
            }
            let isDroughtModule = true;
            setIsLoading(true);
            dispatch(
              getDroughtMapRequest(
                paramSelected?.api_url,
                requestObj,
                isDroughtModule,
                paramSelected?.master_server,
              ),
            );
          }
        }
      }
      setApplyLayers(true);
      setShowCharts(false);
      setIndexLayers([]);
    }
  };

  const handleDroughtIndicators = data => {
    setDroughtIndicators(data);
  };

  //function to create overlays for the selected indices/params
  const renderParamLayer = paramList => {
    if (paramList && paramList?.length) {
      return paramList?.map((item, index) => {
        return (
          <>
            {/* TODO: need to create separate component */}
            <Overlay name={item.name}>
              <LayerGroup ref={layerRef.current[item.paramIndex]}>
                <TileLayer
                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url={''}
                  id={item.name}
                />
              </LayerGroup>
            </Overlay>
          </>
        );
      });
    }
  };

  const handleChartDisplay = () => {
    setShowCharts(!showCharts);
  };
  const handleSelectedModule = module => {
    setSelectedModule(module);
  };
  const handleWeatherModal = () => {
    let requestObj = {};
    requestObj.latitude = cordinateAndZoom.locationCordinates[0];
    requestObj.longitude = cordinateAndZoom.locationCordinates[1];
    // requestObj.place_name =
    //   selectedLocation?.district?.name ||
    //   selectedLocation?.state?.name ||
    //   selectedLocation?.country?.name;
    if (state.min) requestObj.min = Number(state.min);
    if (state.max) requestObj.max = Number(state.max);
    requestObj.temporal_aggregation = temporalAggregation.value;
    requestObj.region_id = selectedRegionId;
    requestObj.start_date = moment(new Date())
      .local()
      .format('YYYY-MM-DD');
    requestObj.end_date = moment(new Date())
      .add(30, 'days')
      .format('YYYY-MM-DD');
    requestObj.data_sources = [DATA_SOURCES.OPEN_WEATHER.id];
    requestObj.spatial_aggregation = 'mean';
    requestObj.parameter_name = DATA_SOURCES.OPEN_WEATHER?.parameter_name?.toLowerCase();

    if (selectedLocation?.country?.name !== SOUTH_ASIA_CONST.name)
      requestObj.country_name = selectedLocation?.country?.name;

    if (selectedLocation?.state?.name) requestObj.state_name = selectedLocation?.state?.name;

    if (selectedLocation?.district?.name)
      requestObj.district_name = selectedLocation?.district?.name;

    setIsLoading(true);
    dispatch(
      getWeatherForecastData({
        obj: requestObj,
        isMasterServer: DATA_SOURCES.OPEN_WEATHER?.master_server,
        apiUrl: DATA_SOURCES.OPEN_WEATHER?.api_url,
      }),
    );
  };
  const handleDroughtPhase = phase => {
    setSelectedDroughtPhase(phase);
  };
  //function to toggle the chart at the bottom of map
  const handleUpdateGraph = (status, tableObjData) => {
    if (tableObjData) {
      if (tableObjData?.isPreviewIndicesData) {
        setIsPreviewIndicesData(true);
      } else {
        setIsPreviewIndicesData(false);
      }
    }
    if (selectedModule?.api_slug !== 'drought-decision-support') setShowCharts(status);
    if (
      !_.isEmpty(temporalAggregation) &&
      (!_.isEmpty(selectedParam) || !_.isEmpty(tableObjData))
    ) {
      setIsGraphLoading(true);
      setErrorData({ showError: false, data: '' });
      setGraphData('');
      const requestObj = {};
      let paramSelected = _.isEmpty(tableObjData?.selectedParam)
        ? Object.assign({}, selectedParam)
        : Object.assign({}, tableObjData.selectedParam);

      let aggregation = _.cloneDeep(temporalAggregation);
      let paramData = _.cloneDeep(selectedParam);
      let compareParamData = _.cloneDeep(compareSelectedParam);
      let regionData = _.cloneDeep(selectedLocation?.region);
      let startDate = _.cloneDeep(initialSelectedDate);
      let location = _.cloneDeep(selectedLocation);

      requestObj.spatial_aggregation = temporalAggregation.value;
      if (state.min && !isCompareClicked) requestObj.min = Number(state.min);
      if (state.max && !isCompareClicked) requestObj.max = Number(state.max);
      if (!_.isEmpty(tableObjData)) {
        if (tableObjData?.isPreviewIndicesData) {
          requestObj.check_drought_conditon = '';
        } else if (!tableObjData?.isPreviewIndicesData) {
          requestObj.check_drought_conditon = true;
        }
      }

      if (selectedModule?.api_slug === 'drought-monitor') {
        if (paramSelected?.id === INDICES_PARAMS.DRY_SPELL.id) {
          requestObj.precipitation_threshold = drySpellPrecipitationThreshold.toString();
        }
      }

      if (
        selectedModule?.api_slug === 'drought-monitor-v2' &&
        selectedParam?.id === INDICES_PARAMS?.RAINFALL_ANAMOLY?.id
      ) {
        requestObj.start_year = rainfallAnamolyTime?.start_year?.label.toString() || '';
        requestObj.end_year = rainfallAnamolyTime?.end_year?.label.toString() || '';
        requestObj.month = rainfallAnamolyTime?.month?.value || '';
      }

      if (selectedModule?.api_slug === 'drought-decision-support') {
        requestObj.start_date = tableObjData?.start_date
          ? tableObjData?.start_date
          : DSSTabularDate.startDate;
        requestObj.end_date = tableObjData?.end_date
          ? tableObjData?.end_date
          : DSSTabularDate.endDate;
        if (paramSelected?.slug === DRY_SPELL.DRY_SPELL_SLUG) {
          requestObj.precipitation_threshold = drySpellPrecipitationThreshold.toString();
        }
      } else if (selectedParam?.id !== INDICES_PARAMS?.RAINFALL_ANAMOLY?.id) {
        requestObj.start_date = initialSelectedDate.startDate;
        requestObj.end_date = initialSelectedDate.endDate;
      }
      let compareInitialObj = JSON.parse(JSON.stringify(requestObj));
      let compareFinalObj = JSON.parse(JSON.stringify(requestObj));

      if (
        selectedRegionId &&
        !_.isEmpty(selectedLocation.country?.value) &&
        _.isEmpty(selectedLocation?.state?.name) &&
        _.isEmpty(selectedLocation?.district?.name)
      )
        requestObj.region_id = selectedRegionId;

      if (selectedModule.api_slug === 'drought-prediction') {
        requestObj.parameter_id = selectedParam?.id;
      }
      if (selectedLocation?.country?.name !== SOUTH_ASIA_CONST?.name) {
        requestObj.country_name = selectedLocation?.country?.name || '';
      }
      if (selectedLocation?.state?.value) {
        requestObj.state_name = selectedLocation?.state?.name || '';
      }
      if (selectedLocation?.district?.value) {
        requestObj.district_name = selectedLocation?.district?.name || '';
      }
      if (selectedLocation?.basin) {
        requestObj.basin_name = selectedLocation?.basin || '';
      }

      if (isCompareClicked || (!_.isEmpty(tableObjData) && tableObjData?.isCompare)) {
        let initialSelectedParam = !_.isEmpty(tableObjData?.selectedParam)
          ? Object.assign({}, tableObjData.selectedParam)
          : Object.assign({}, selectedParam);
        let compareParamSelected = !_.isEmpty(tableObjData?.compareSelectedParam)
          ? Object.assign({}, tableObjData.compareSelectedParam)
          : Object.assign({}, compareSelectedParam);

        compareInitialObj = { ...requestObj };
        if (
          !_.isEmpty(compareLocation.country?.value) &&
          compareLocation?.country?.name === SOUTH_ASIA_CONST?.name &&
          _.isEmpty(compareLocation?.state?.name) &&
          _.isEmpty(compareLocation?.district?.name)
        ) {
          compareFinalObj.region_id = compareLocation.country.value;
        }
        if (selectedModule.api_slug === 'drought-prediction') {
          compareFinalObj.parameter_id = compareParamSelected?.id;
        }
        if (compareLocation?.country?.name !== SOUTH_ASIA_CONST?.name) {
          compareFinalObj.country_name = compareLocation?.country?.name || '';
        }
        if (compareLocation?.state?.value) {
          compareFinalObj.state_name = compareLocation?.state?.name || '';
        }
        if (compareLocation?.district?.value) {
          compareFinalObj.district_name = compareLocation?.district?.name || '';
        }

        if (selectedModule?.api_slug === 'drought-monitor-v2') {
          if (initialSelectedParam?.id === INDICES_PARAMS.EXTREME_RAINFALL.id) {
            compareInitialObj.precipitation_threshold = precipitationThreshold.toString();
          }

          if (compareParamSelected?.id === INDICES_PARAMS.EXTREME_RAINFALL.id) {
            compareFinalObj.precipitation_threshold = comparePrecipitationThreshold.toString();
          }

          if (compareParamSelected?.id === INDICES_PARAMS?.RAINFALL_ANAMOLY?.id) {
            compareFinalObj.start_year =
              compareRainfallAnamolyTime?.start_year?.label.toString() || '';
            compareFinalObj.end_year = compareRainfallAnamolyTime?.end_year?.label.toString() || '';
            compareFinalObj.month = compareRainfallAnamolyTime?.month?.value || '';
          } else {
            compareFinalObj.start_date = initialSelectedDate.startDate;
            compareFinalObj.end_date = initialSelectedDate.endDate;
          }
        }

        if (
          selectedModule?.api_slug === 'drought-monitor' ||
          selectedModule?.api_slug === 'drought-decision-support'
        ) {
          if (compareParamSelected?.id === INDICES_PARAMS.DRY_SPELL.id) {
            compareFinalObj.precipitation_threshold = compareDrySpellPrecipitationThresold.toString();
          }
        }

        if (selectedModule?.api_slug === 'drought-prediction') {
          if (initialSelectedParam?.name === 'SPI' || initialSelectedParam?.name === 'SRI')
            compareInitialObj.month = selectedDPIndicesMonth?.frequency;
          if (compareParamSelected?.name === 'SPI' || compareParamSelected?.name === 'SRI') {
            compareFinalObj.month = compareSelectedDPIndicesMonth?.frequency;
            compareFinalObj.parameter_id = compareParamSelected?.id;
          }
        }

        let initialRequestResponse, finalRequestResponse;

        let initalConfig = {};
        initalConfig.layerType = initialSelectedParam?.name;
        initalConfig.slug = initialSelectedParam?.slug;
        initalConfig.location =
          compareInitialObj?.district_name ||
          compareInitialObj?.state_name ||
          compareInitialObj?.country_name ||
          SOUTH_ASIA_CONST.name;

        let finalConfig = {};
        finalConfig.layerType = compareParamSelected?.name;
        finalConfig.slug = compareParamSelected?.slug;
        finalConfig.location =
          compareFinalObj?.district_name ||
          compareFinalObj?.state_name ||
          compareFinalObj?.country_name ||
          SOUTH_ASIA_CONST.name;
        if (selectedModule?.api_slug === 'drought-decision-support') {
          setIsLoading(true);
        }
        initialRequestResponse = initialSelectedParam?.master_server
          ? AXIOS_INSTANCE_MASTER_SERVER.post(
              `${MASTER_SERVER_BASE_URL}/${initialSelectedParam?.api_url}timeseries`,
              compareInitialObj,
              initalConfig,
            )
          : AXIOS_INSTANCE.post(
              `${API_BASE_URL}/${initialSelectedParam?.api_url}timeseries`,
              compareInitialObj,
              initalConfig,
            );
        finalRequestResponse = compareParamSelected?.master_server
          ? AXIOS_INSTANCE_MASTER_SERVER.post(
              `${MASTER_SERVER_BASE_URL}/${compareParamSelected?.api_url}timeseries`,
              compareFinalObj,
              finalConfig,
            )
          : AXIOS_INSTANCE.post(
              `${API_BASE_URL}/${compareParamSelected?.api_url}timeseries`,
              compareFinalObj,
              finalConfig,
            );
        Promise.all([initialRequestResponse, finalRequestResponse])
          .then(responses => {
            if (selectedModule?.api_slug === 'drought-decision-support') {
              setIsLoading(false);
              setIsPreviewIndicesData(false);
              if (!tableObjData?.isDroughtCheck) {
                setTableData(responses);
                setIsOpenAlertIndicesTable(!isOpenAlertIndicesTable);
              } else {
                setDSSTimeSeriesData(responses);
              }
            } else {
              setIsGraphLoading(false);
              let tempData = {};
              tempData.graph_data = responses.map(item => {
                let graph_data = item?.data?.result?.graph_data;
                graph_data.layerType = item?.config?.layerType;
                graph_data.location = item?.config?.location;
                return graph_data;
              });
              setChartParamsData({
                temporalAggregation: aggregation,
                selectedParam: paramData,
                compareParam: compareParamData,
                region: regionData,
                initialSelectedDate: startDate,
                location: location,
              });
              setGraphData(JSON.parse(JSON.stringify(tempData)));
            }
          })
          .catch(error => {
            if (!_.isEmpty(tableObjData)) {
              setIsLoading(false);
              setIsPreviewIndicesData(false);
            }
            setIsGraphLoading(false);
            if (error?.response) {
              let errorRes = error.response;
              if (!_.isEmpty(errorRes?.data?.errors)) {
                setErrorData({ showError: true, data: Object.values(errorRes?.data?.errors)[0] });
                if (!_.isEmpty(tableObjData)) {
                  showError(Object.values(errorRes?.data?.errors)[0]);
                }
              } else if (errorRes?.data?.exception) {
                setErrorData({ showError: true, data: errorRes?.data?.exception });
              } else {
                setErrorData({ showError: true, data: errorRes?.data?.message });
                if (!_.isEmpty(tableObjData)) {
                  showError(errorRes?.data?.message);
                }
              }
            } else {
              if (selectedModule?.api_slug === 'drought-decision-support') {
                showError(t('DROUGHT_MONITORING.API_FAILURE_ERROR'));
              }
              setErrorData({ showError: true, data: 'No records found. please try again' });
            }
          });
      } else {
        let timeSeriesObj = Object.assign({}, requestObj);
        if (
          selectedModule?.api_slug === 'drought-decision-support' ||
          selectedModule?.api_slug === 'drought-monitor-v2'
        ) {
          if (selectedModule?.api_slug === 'drought-decision-support') {
            setIsLoading(true);
          }
          if (paramSelected?.id === INDICES_PARAMS.EXTREME_RAINFALL.id) {
            timeSeriesObj.precipitation_threshold = precipitationThreshold.toString();
          }
        }
        if (
          selectedModule?.api_slug === 'drought-prediction' &&
          (paramSelected?.name === 'SPI' || paramSelected?.name === 'SRI')
        ) {
          timeSeriesObj.month = selectedDPIndicesMonth?.frequency;
        }
        if (selectedLocation?.region && selectedLocation?.region?.length) {
          let API_URL = [];
          setIsGraphLoading(true);
          selectedLocation?.region.map(item => {
            let arr = [];
            arr.push(item?.latlngs);
            timeSeriesObj.geometry = item.layerType === 'marker' ? arr : arr[0];
            let config = {
              layerType: item.layerType,
              latlng: item.layerType === 'marker' ? item.latlngs : '',
              polygonCount: item.layerType === 'polygon' ? item.polygonCount : '',
            };

            API_URL.push(
              paramSelected?.master_server
                ? AXIOS_INSTANCE_MASTER_SERVER.post(
                    `${MASTER_SERVER_BASE_URL}/${paramSelected?.api_url}timeseries`,
                    timeSeriesObj,
                    config,
                  )
                : AXIOS_INSTANCE.post(
                    `${API_BASE_URL}/${paramSelected?.api_url}timeseries`,
                    timeSeriesObj,
                    config,
                  ),
            );
          });
          setApplyLayers(true);

          Promise.allSettled(API_URL)
            .then(response => {
              if (selectedModule?.api_slug === 'drought-decision-support') {
                setIsLoading(false);
                setIsPreviewIndicesData(false);

                if (!tableObjData?.isDroughtCheck) {
                  const successfulResponses = response.filter(item => item.status === 'fulfilled');
                  setTableData(successfulResponses);
                  setIsOpenAlertIndicesTable(!isOpenAlertIndicesTable);
                } else {
                  const successfulResponses = response.filter(item => item.status === 'fulfilled');
                  setDSSTimeSeriesData(successfulResponses);
                }
              } else {
                setIsGraphLoading(false);

                let tempData = {
                  graph_data: [],
                  errors: [],
                };

                response.forEach(item => {
                  if (item.status === 'fulfilled') {
                    let graph_data = item?.value?.data?.result?.graph_data;
                    if (graph_data) {
                      graph_data.layerType = item?.value?.config?.layerType;
                      graph_data.latlng = item?.value?.config?.latlng;
                      graph_data.polygonCount = item?.value?.config?.polygonCount;
                      tempData.graph_data.push(graph_data);
                    }
                  } else {
                    tempData.errors.push(item.reason?.config?.latlng);
                  }
                });

                setChartParamsData({
                  temporalAggregation: aggregation,
                  selectedParam: paramData,
                  compareParam: compareParamData,
                  region: regionData,
                  initialSelectedDate: startDate,
                  location: location,
                });
                if (tempData?.graph_data?.length) {
                  setGraphData(JSON.parse(JSON.stringify(tempData)));
                } else {
                  setGraphData('');
                  setErrorData({ showError: true, data: 'No records found. Please try again' });
                }
                if (tempData?.errors?.length && tempData?.graph_data?.length) {
                  // console.log(tempData?.errors , "enterrrrrrrrrrrrrrrrrrrr")
                  // const dataNotAvailablePoints = tempData?.errors.map(([lat, lng]) => `(${lat}, ${lng})`)
                  // .join(', ')

                  // showError(`Data is not available for ${dataNotAvailablePoints}`)
                  showError(`Data is unavailable for some latitude and longitude`);
                }
              }
            })
            .catch(error => {
              setIsGraphLoading(false);
              if (selectedModule?.api_slug === 'drought-decision-support') {
                setIsLoading(false);
                setIsPreviewIndicesData(false);
              }
              if (error?.response) {
                let errorRes = error.response;
                if (!_.isEmpty(errorRes?.data?.errors)) {
                  setErrorData({ showError: true, data: Object.values(errorRes?.data?.errors)[0] });
                } else {
                  setErrorData({ showError: true, data: errorRes?.data?.message });
                }
              } else {
                if (selectedModule?.api_slug === 'drought-decision-support') {
                  showError(t('DROUGHT_MONITORING.API_FAILURE_ERROR'));
                }
                setErrorData({ showError: true, data: 'No records found. Please try again' });
              }
            });

          return;
        }
        if (!selectedLocation?.region?.length) {
          dispatch(
            getDroughtTimeSeriesRequest(
              paramSelected?.api_url,
              timeSeriesObj,
              paramSelected?.master_server,
            ),
          );
        }
      }
      // }
    }
  };

  //function to call drought chart map
  const renderChartsUI = () => {
    if (showCharts) {
      return (
        <>
          <div className="footer panel">
            <div className="drought-footer-area chart-design" style={{ flexGrow: 1 }}>
              <FormGroup row>
                {errorData?.showError && (
                  <Col sm={12} md={12} lg={12}>
                    <div className="error-close-icon" onClick={handleChartDisplay}>
                      <CgCloseO size={25} color="#2A547C" />
                    </div>
                  </Col>
                )}
              </FormGroup>
              {!_.isEmpty(graphData) ? (
                <DroughtChart
                  renderCharts={handleChartDisplay}
                  chartData={graphData}
                  isCompareClicked={isCompareClicked}
                  selectedParam={chartParamsData?.selectedParam || ''}
                  compareParam={chartParamsData?.compareParam || ' '}
                  temporalAggregation={chartParamsData?.temporalAggregation || ''}
                  region={chartParamsData?.region || ''}
                  initialSelectedDate={chartParamsData?.initialSelectedDate || ''}
                  location={chartParamsData?.location || ''}
                  isHideBar={isHideBar}
                  selectedModule={selectedModule}
                  mapLayers={mapLayers}
                />
              ) : (
                <>
                  {isGraphLoading && <GraphLoader />}
                  {errorData?.showError && (
                    <div className="drought-error-container">
                      <div className="drought-error-style">
                        <Alert color="warning">{errorData?.data}</Alert>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      );
    }
  };

  // Static coordinates for India

  const iconHtmlString = ReactDOMServer.renderToString(
    <CustomMarkerIcon Icon={FaMapMarkerAlt} className="custom-hydrostation-cons" />,
  );

  const myIcon = L.divIcon({
    iconAnchor: [0, 0],
    className: 'custom-div-icon',
    html: iconHtmlString,
  });

  // function parseCoordinate(coordinate) {
  //   // Remove degree symbol and direction letters (N, S, E, W) in a case-insensitive manner
  //   const numericValue = coordinate.replace(/[°nsweNSWE]/gi, '').trim();
  //   return parseFloat(numericValue);
  // }

  //function to render map
  const renderMapContainer = () => {
    return (
      <>
        <MapContainer
          center={cordinateAndZoom.cordinates}
          id="drought-leaflet-map"
          zoom={cordinateAndZoom.zoom}
          className="drought-map-container"
          zoomControl={false}
          scrollWheelZoom={true}
          whenCreated={setMapReference}
        >
          {selectedDroughtPhase?.id === 'Alert' &&
          selectedModule?.api_slug === 'drought-decision-support' ? (
            <LayersControl position="topleft">
              <div className="logoContainer">
                <Button
                  className="weather-preferences-button wf-btn-margin"
                  onClick={handleWeatherModal}
                >
                  <MdKeyboardArrowLeft />
                  Weather Forecast
                </Button>
              </div>
            </LayersControl>
          ) : (
            ''
          )}
          <ChangeView center={cordinateAndZoom.cordinates} zoom={cordinateAndZoom.zoom} />

          <ZoomControl position="topright" className="drought-map-zoom-control" />
          <LayersControl position="topright">
            <LayersControl.BaseLayer name="Satellite View">
              <TileLayer url={myConstClass.SATELLITE_TILE_LAYER_URL} />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer checked name="Streets View">
              <TileLayer url={myConstClass.TITLE_LAYER_URL} />
            </LayersControl.BaseLayer>
          </LayersControl>

          {searchLocation && cordinateAndZoom?.searchMarkerCoordinates !== '' ? (
            <Marker position={cordinateAndZoom.searchMarkerCoordinates} icon={myIcon}>
              <Tooltip permanent direction="top" className="custom-tooltip-1" offset={[17, -17]}>
                <div className="tooltip-container-1">
                  <span className="custom-marker-text">
                    {/* ({parseCoordinate(cordinateAndZoom.searchMarkerCoordinates[0]).toFixed(2)},
                    {parseCoordinate(cordinateAndZoom.searchMarkerCoordinates[1]).toFixed(2)}) */}
                    ({cordinateAndZoom.searchMarkerCoordinates?.[0]},
                    {cordinateAndZoom.searchMarkerCoordinates?.[1]})
                  </span>
                </div>
              </Tooltip>
            </Marker>
          ) : (
            ''
          )}

          <DrawShape
            handleSelectedRegion={handleSelectedRegion}
            applyLayers={applyLayers}
            country={selectedLocation?.country}
            state={selectedLocation?.state}
            district={selectedLocation?.district}
            selectedModule={selectedModule}
            isHideViewGraph={isHideViewGraph}
            setManuleMarker={setManuleMarker}
            manuleMarker={manuleMarker}
            setMapLayers={setMapLayers}
            mapLayers={mapLayers}
            pointsRef={pointsRef}
            featureGroupRef={featureGroupRef}
          />

          <GeoJSON
            key={selectedLocation.country + MAP_PREFERENCES_lABELS.COUNTRY}
            ref={mapPreferences['country'].layer}
            style={{ color: mapPreferences['country'].color, fillColor: '#0000', weight: 1 }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(mapPreferences['country'].country, 'country', feat, layer)
            }
          />

          <GeoJSON
            key={selectedLocation?.country + MAP_PREFERENCES_lABELS.STATES}
            ref={mapPreferences['states_or_provinces'].layer}
            style={{
              color: mapPreferences['states_or_provinces'].color,
              fillColor: '#0000',
              weight: 1.6,
            }}
            onEachFeature={(feat, layer) => {
              handleEachFeatureChange(
                mapPreferences['states_or_provinces'].country,
                'states_or_provinces',
                feat,
                layer,
              );
            }}
          />
          <GeoJSON
            key={selectedLocation?.country + MAP_PREFERENCES_lABELS.DISTRICTS}
            ref={mapPreferences['districts_or_cities'].layer}
            style={{
              color: _.isEmpty(selectedLocation?.district)
                ? mapPreferences['districts_or_cities'].color
                : '#040404',
              weight: _.isEmpty(selectedLocation?.district) ? 1.5 : 1,
              fillColor: '#0000',
            }}
            onEachFeature={(feat, layer) => {
              handleEachFeatureChange(
                mapPreferences['districts_or_cities'].country,
                'districts_or_cities',
                feat,
                layer,
              );
            }}
          />

          <GeoJSON
            key={selectedLocation?.country + MAP_PREFERENCES_lABELS.METEOROLOGICAL_LOCATIONS}
            ref={mapPreferences['meteorological_locations'].layer}
            style={{
              color: mapPreferences['meteorological_locations'].color,
              fillColor: '#0000',
              weight: 1,
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(
                mapPreferences['meteorological_locations'].country,
                'meteorological_locations',
                feat,
                layer,
              )
            }
          />

          <GeoJSON
            key={selectedLocation?.country + MAP_PREFERENCES_lABELS.BASINS}
            ref={mapPreferences['basins'].layer}
            style={{
              color: mapPreferences['basins'].color,
              fillColor: '#0000',
              weight: 1,
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(mapPreferences['basins'].country, 'basins', feat, layer)
            }
          />

          <GeoJSON
            key={selectedLocation?.country + MAP_PREFERENCES_lABELS.RIVERS}
            ref={mapPreferences['rivers_or_reservoirs'].layer}
            style={{
              color: mapPreferences['rivers_or_reservoirs'].color,
              fillColor: '#0000',
              weight: 1,
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(
                mapPreferences['rivers_or_reservoirs'].country,
                'rivers_or_reservoirs',
                feat,
                layer,
              )
            }
          />

          <GeoJSON
            key={selectedLocation?.country + MAP_PREFERENCES_lABELS.TALUKAS}
            ref={mapPreferences['talukas'].layer}
            style={{
              color: mapPreferences['talukas'].color,
              fillColor: '#0000',
              weight: 0.8,
            }}
            onEachFeature={(feat, layer) =>
              handleEachFeatureChange(mapPreferences['talukas'].country, 'talukas', feat, layer)
            }
          />

          <LayersControl position="topleft">
            {mapPreferRefs?.current?.map((mapLayerRef, index) => {
              if (!_.isEmpty(mapLayerRef)) {
                return (
                  <>
                    <Overlay>
                      <LayerGroup ref={mapLayerRef.current.layer}>
                        <TileLayer
                          ref={mapPrefereTileLayerRef}
                          attribution='Weather from <a href="https://openweathermap.org/" alt="World Map and worldwide Weather Forecast online">OpenWeatherMap</a>'
                          url=""
                        />
                      </LayerGroup>
                    </Overlay>
                  </>
                );
              }
            })}
          </LayersControl>
          <LayersControl position="topleft">{renderParamLayer(params)}</LayersControl>
          {selectedParamLegendData.length
            ? selectedParamLegendData.map(item => {
                return (
                  <>
                    <LayersControl position="topleft">
                      {item?.descret_legend ? (
                        <DiscreteLegend
                          map={map}
                          add={item.add}
                          layer={item}
                          position="bottomright"
                        />
                      ) : (
                        <CustomLegend
                          map={map}
                          add={item.add}
                          layer={item}
                          position="bottomright"
                        />
                      )}
                    </LayersControl>
                  </>
                );
              })
            : ''}
          {compareParamLegendData.length
            ? compareParamLegendData.map(item => {
                return (
                  <>
                    <LayersControl position="topleft">
                      {item?.descret_legend ? (
                        <DiscreteLegend
                          map={map}
                          add={item.add}
                          layer={item}
                          position="bottomleft"
                        />
                      ) : (
                        <CustomLegend map={map} add={item.add} layer={item} position="bottomleft" />
                      )}
                    </LayersControl>
                  </>
                );
              })
            : ''}
          {selectedMapPreferLegendData?.length
            ? selectedMapPreferLegendData.map(item => {
                return (
                  <LayersControl position="topleft">
                    {item?.descret_legend ? (
                      <DiscreteLegend map={map} add={item.add} layer={item} position="bottomleft" />
                    ) : (
                      <CustomLegend map={map} add={item.add} layer={item} position="bottomright" />
                    )}
                  </LayersControl>
                );
              })
            : ''}
          <LayersControl position="topleft">
            <div className="logoContainer2">
              <Button className="drought-map-preferences-button" onClick={handleMapModel}>
                <img src={mapPreferenceIcon} alt="map preference" width={15} />
              </Button>
            </div>
          </LayersControl>

          <LayersControl position="topleft">
            <div className="logoContainer3">
              <Button
                className={
                  countryJson
                    ? 'drought-map-preferences-button'
                    : 'drought-map-preferences-button location-point-opacity'
                }
                id="point_data_icon"
                onClick={() => (countryJson ? setIsOpenPointModal(!isOpenPointModal) : {})}
              >
                <img src={point_location} alt="map preference" width={15} />
              </Button>
            </div>
          </LayersControl>
        </MapContainer>
      </>
    );
  };
  return (
    <>
      <div className="global-top-new">
        {isLoading && (
          <Loader
            showText={
              selectedParam.name == IDSI_AND_VWI.IDSI ||
              selectedParam.name == IDSI_AND_VWI.VWI ||
              isPreviewIndicesData
                ? true
                : false
            }
          />
        )}
        <div className="w-100">
          <div className={isHideBar ? `sideBar-collapse col-lg-2` : `sideBar col-lg-2`}>
            <DroughtSidebar
              popUpSelectedModule={popUpSelectedModule}
              thresholdData={thresholdData}
              countryJson={countryJson}
              setCordinateAndZoom={setCordinateAndZoom}
              setSearchLocation={setSearchLocation}
              searchLocation={searchLocation}
              isHideBar={isHideBar}
              tableData={tableData}
              selectedParam={selectedParam}
              cordinateAndZoom={cordinateAndZoom}
              isShowDroughtCheckModal={isShowDroughtCheckModal}
              dssTimeSeriesData={DSSTimeSeriesData}
              isOpenAlertIndicesTable={isOpenAlertIndicesTable}
              selectedModule={selectedModule}
              isOpenWeatherForecastDataModal={isOpenWeatherForecastDataModal}
              weatherForecastData={weatherModuleData}
              precipitationThreshold={precipitationThreshold}
              comparePrecipitationThreshold={comparePrecipitationThreshold}
              drySpellPrecipitationThreshold={drySpellPrecipitationThreshold}
              compareDrySpellPrecipitationThresold={compareDrySpellPrecipitationThresold}
              selectedDPIndicesMonth={selectedDPIndicesMonth}
              compareSelectedDPIndicesMonth={compareSelectedDPIndicesMonth}
              mapPreferencesData={mapPreferencesData}
              rainfallAnamolyTime={rainfallAnamolyTime}
              compareRainfallAnamolyTime={compareRainfallAnamolyTime}
              isThresholdDataLoading={isThresholdDataLoading}
              finalDroughtParameterMonthsList={finalDroughtParameterMonthsList}
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
              setFinalDroughtParameterMonthsList={setFinalDroughtParameterMonthsList}
              setCurrenUserLocation={setCurrenUserLocation}
              setIsThresholdDataLoading={setIsThresholdDataLoading}
              handleCompareRainfallAnamolyEndYearChange={handleCompareRainfallAnamolyEndYearChange}
              handleCompareRainfallAnamolyStartYearChange={
                handleCompareRainfallAnamolyStartYearChange
              }
              handleCompareRainfallAnamolyMonthChange={handleCompareRainfallAnamolyMonthChange}
              setBar={setBar}
              handleRainfallAnamolyStartYearChange={handleRainfallAnamolyStartYearChange}
              handleRainfallAnamolyEndYearChange={handleRainfallAnamolyEndYearChange}
              handleRainfallAnamolyMonthChange={handleRainfallAnamolyMonthChange}
              setCompareSelectedDPIndicesMonth={setCompareSelectedDPIndicesMonth}
              setSelectedDPIndicesMonth={setSelectedDPIndicesMonth}
              setPrecipitationThreshold={handlePrecipitationThreshold}
              setComparePrecipitationThreshold={handleComparePrecipitationThreshold}
              setDrySpellPrecipitationThreshold={handleDrySpellPrecipitationThresold}
              setCompareDrySpellPrecipitationThresold={handleCompareDrySpellPrecipitationThresold}
              handleDroughtIndicators={handleDroughtIndicators}
              setTableData={setTableData}
              setDSSTimeSeriesData={setDSSTimeSeriesData}
              setIsShowDroughtCheckModal={setIsShowDroughtCheckModal}
              setIsOpenWeatherForecastDataModal={setIsOpenWeatherForecastDataModal}
              setIsOpenAlertIndicesTable={setIsOpenAlertIndicesTable}
              setDSSTabularDate={setDSSTabularDate}
              handleCordinates={handleCordinates}
              handleProps={handleProps}
              handleCompareProps={handleCompareProps}
              handleSelectedParam={handleSelectedParam}
              handleParams={handleParams}
              handleLocation={handleLocation}
              handleCompareLocation={handleCompareLocation}
              setIsFinalDroughtMap={setIsFinalDroughtMap}
              handleApplyLayers={handleApplyLayers}
              handleDroughtPhase={handleDroughtPhase}
              isSelectedRegion={selectedLocation}
              renderCharts={handleUpdateGraph}
              handleIsCompare={handleIsCompare}
              handleCompareView={handleCompareView}
              handleCompareCordinates={handleCompareCordinates}
              handleSelectedRegion={handleSelectedFilterRegion}
              handleSelectedModule={handleSelectedModule}
            />
          </div>
          <Row>
            <Col lg={isHideBar ? 12 : 10} className="right-map map-position">
              <div
                // style={{
                //   height: '79.4vh',
                //   display: 'flex',
                //   flexDirection: 'column',
                // }}
                className="map-space-div"
              >
                <>
                  <SplitterLayout
                    ref={splitterRef}
                    primaryIndex={0}
                    vertical
                    percentage={true}
                    primaryMinSize={30}
                    secondaryInitialSize={70}
                  >
                    <div style={{ height: '100%' }}>{renderMapContainer()}</div>
                    {renderChartsUI()}
                  </SplitterLayout>
                </>
              </div>
            </Col>
          </Row>
          <MapPreferences
            isToggle={isOpenMapModel}
            handleToggle={handleMapModel}
            loc={selectedLocation?.country?.name}
            selectedLocation={selectedLocation}
            regionId={selectedRegionId}
            isMapPerferGeoJsonLoading={mapPreferenceGeoJsonloading}
            selectedParamLegendData={selectedParamLegendData}
            getMapPreferencesData={getMapPreferencesData}
            getMapPreferencesGeojsonData={getMapPreferencesGeojsonData}
            geoJsonData={geoJsonData}
            mapPreferencesAllData={mapPreferencesData}
            stateData={selectedLocation?.state}
            districtData={selectedLocation?.district}
            countryData={selectedLocation?.country}
            cordinateAndZoom={cordinateAndZoom}
            fromContingencyPlan={false}
            fromDroughtMonitor={true}
          />
        </div>
      </div>
      {isOpenPointModal ? (
        <PointDataModal
          isOpen
          toggle={() => setIsOpenPointModal(!isOpenPointModal)}
          countryJsonData={mapPreferences['country'].layer}
          countryJson={countryJson}
          setManuleMarker={setManuleMarker}
          setMapLayers={setMapLayers}
          mapLayers={mapLayers}
          pointsRef={pointsRef}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default DroughtMap;

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { AXIOS_INSTANCE, STATIC_RESOURCE_URL } from '../api/apiEndPoint';
import {STATIC_RESOURCE_CONST,STATIC_RESOURCE_YEAR_CONST, STATIC_RESOURCE_ADD_CONST, UPDATE_STATIC_RESOURCE_CONST, DELETE_STATIC_RESOURCE_CONST,STATIC_RESOURCE_ALL_DATE_CONST } from './staticResourceConst';
import { staticResourceSuccess, staticResourceFailed,staticResourceYearFailed,staticResourceYearSuccess, staticResourceAddSuccess,staticResourceAddFailed, updateStaticResourceSuccess, updateStaticResourceFailed, deleteStaticResourceSuccess, deleteStaticResourceFailed,staticResourceAllDateSuccess,staticResourceAllDateFailed } from './staticResourceAction';
import { showError } from '../../Components/Notifications';

require('dotenv').config();

const staticResourceApiRequest = async (payload) => {
  let response = {};
  try {
   const data = await AXIOS_INSTANCE.post(`${STATIC_RESOURCE_URL}/getAllByCriteria?page=${payload.page}`,payload);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};


const staticResourceAllDateApiRequest = async (payload) => {
  let response = {};
  try {
   const data = await AXIOS_INSTANCE.post(`${STATIC_RESOURCE_URL}/getAllDate`,payload);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};


const staticResourceYearApiRequest = async (payload) => {
  let response = {};
  try {
   const data = await AXIOS_INSTANCE.post(`${STATIC_RESOURCE_URL}/getYears`,payload);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};


const staticResourceAddApiRequest = async (payload) => {
  let response = {};
  const config = {
    headers: {
        'content-type': 'multipart/form-data'
    }
  }
  try {
    const data = await AXIOS_INSTANCE.post(`${STATIC_RESOURCE_URL}/add`,payload,config);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    if(error.response.data.errors){
      showError(error.response.data.message);
    }
    else if(error.response.data.exception){
      showError("Internal server error.Please try later");
    }
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};



const updateStaticResourceApiRequest = async (payload) => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.put(`${STATIC_RESOURCE_URL}/update`,payload);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    if (error?.response && error?.response?.data?.message) showError(error.response.data.message);
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};


const deleteStaticResourceApiRequest = async (id) => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.delete(`${STATIC_RESOURCE_URL}/delete/${id}`);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    if (error?.response && error?.response?.data?.message) showError(error.response.data.message);
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};



function* staticResourceRequest(action) {
    
  try {
    const apiResponse = yield call(staticResourceApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(staticResourceSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(staticResourceFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(staticResourceFailed(errorData));
  }
}


function* staticResourceAllDateRequest(action) {
    
  try {
    const apiResponse = yield call(staticResourceAllDateApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(staticResourceAllDateSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(staticResourceAllDateFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(staticResourceFailed(errorData));
  }
}

function* staticResourceYearRequest(action) {
    
  try {
    const apiResponse = yield call(staticResourceYearApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(staticResourceYearSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(staticResourceYearFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(staticResourceYearFailed(errorData));
  }
}

function* staticResourceAddRequest(action) {
    
  try {
    const apiResponse = yield call(staticResourceAddApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(staticResourceAddSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(staticResourceAddFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(staticResourceAddFailed(errorData));
  }
}




function* updateStaticResourceRequest(action) {
    
  try {
    const apiResponse = yield call(updateStaticResourceApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(updateStaticResourceSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(updateStaticResourceFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(updateStaticResourceFailed(errorData));
  }
}


function* deleteStaticResourceRequest(action) {
    
  try {
    const apiResponse = yield call(deleteStaticResourceApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(deleteStaticResourceSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(deleteStaticResourceFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(deleteStaticResourceFailed(errorData));
  }
}

export function* watchStaticResource() {
  yield takeEvery(STATIC_RESOURCE_CONST.STATIC_RESOURCE_REQUEST, staticResourceRequest);
  yield takeEvery(STATIC_RESOURCE_ADD_CONST.STATIC_RESOURCE_ADD_REQUEST, staticResourceAddRequest);
  yield takeEvery(UPDATE_STATIC_RESOURCE_CONST.UPDATE_STATIC_RESOURCE_REQUEST, updateStaticResourceRequest);
  yield takeEvery(DELETE_STATIC_RESOURCE_CONST.DELETE_STATIC_RESOURCE_REQUEST, deleteStaticResourceRequest);
  yield takeEvery(STATIC_RESOURCE_ALL_DATE_CONST.STATIC_RESOURCE_ALL_DATE_REQUEST, staticResourceAllDateRequest);
  yield takeEvery(STATIC_RESOURCE_YEAR_CONST.STATIC_RESOURCE_YEAR_REQUEST, staticResourceYearRequest);
}

function* staticResourceSaga() {
  yield all([fork(watchStaticResource)]);
}

export default staticResourceSaga;

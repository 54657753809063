import React from 'react';
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri';
import { Collapse } from 'reactstrap';
import _ from 'lodash';
import Select from 'react-select';
import {
  DSS_MODULE_CONSTS,
} from '../../../Components/constants';
import DroughtStageSwitch from './DroughtStageOptions';

const DSSDroughtIndicatorsCollapse = props => {
  const {
    isCompare,
    checkedIndicesList,
    handleCategory,
    droughtModuleIndicators,
    impactMonthsDropdownList,
    selectedModule,
    DroughtIndicators,
    CompareDroughtIndicators,
    handleCompareIndices,
    handleIndices,
    handleCompareCategory,
    handleCategoryToggle,
    handleImpactDropdownChange,
    handleDroughtIndices,
    thresholdDataList,
    setThresholdDataList,
  } = props;

  const checkboxStyle = {
    color: '#2A547C',
  };



const renderImpactDropdown = data => {
  return (
    <>
      <div className="mt-2 mb-3 impact-dropdown-style">
        <Select
          id="month"
          name="month"
          placeholder="Select Month"
          options={data?.options}
          onChange={val => handleImpactDropdownChange(val, data)}
          isMulti={false}
          required={true}
          value={data?.selectedOption}
        />
      </div>
    </>
  );
};

const renderDSSCheckboxes = ({
  item,
  index,
  data,
  handleCategory,
  isSubCategory,
  isCategory,
  isCompare,
}) => {
  const selectedItem = handleCategory?.find(
    x => x.name === item.name && x.category === item.category,
  );
  if (!_.isEmpty(item)) {
    
    return (
      <>
        <span
          onClick={() => handleCategoryToggle(item, handleCategory, isCompare)}
          className="drought-category-style"
        >
          <p
            className={
              isSubCategory && item.name !== 'Final Drought Map'
                ? 'dss-drought-sub-category-labels'
                : 'dss-drought-category-labels'
            }
            style={checkboxStyle}
          >
            {item.name || ''}
            <span className="category-span">
              {selectedItem?.checked ? (
                <RiArrowDropUpLine size={25} />
              ) : (
                <RiArrowDropDownLine size={25} />
              )}
            </span>
          </p>
        </span>
        <Collapse
          id={'collapse' + index}
          isOpen={selectedItem?.checked}
          className="drought-collapse-moadal"
        >
          {isCategory ? (
            data.length ? (
              data?.map((category, index) => {
                let x = index;
                return (
                  <>
                    {renderDSSCheckboxes({
                      item: category,
                      index: x,
                      data: category?.parameter,
                      handleCategory,
                      isSubCategory: true,
                      isCategory: false,
                      isCompare,
                    })}
                  </>
                );
              })
            ) : (
              <>
                <div>
                  <p>No Indicators are available</p>
                </div>
              </>
            )
          ) : (
            returnCheckbox(
              item,
              data,
              isCompare ? handleCompareIndices : handleIndices,
              index,
              isCompare,
            )
          )}
        </Collapse>
      </>
    );
  } else {
    return <></>;
  }
};

const returnCheckbox = (categoryList, list) => {
  let filteredCategory = impactMonthsDropdownList?.find(x => x.id === categoryList?.name);
  return (
    <>
      {!_.isEmpty(filteredCategory) && categoryList?.category === DSS_MODULE_CONSTS.IMPACT
        ? renderImpactDropdown(filteredCategory)
        : ''}
      {list?.length !== 0 && (
        <DroughtStageSwitch
          droughtIndicesList={list}
          checkedIndicesList={checkedIndicesList}
          handleDroughtIndices={handleDroughtIndices}
          drySpellPrecipitationThreshold={props.drySpellPrecipitationThreshold}
          setDrySpellPrecipitationThreshold={props.setDrySpellPrecipitationThreshold}
          selectedModule={selectedModule}
          thresholdDataList={thresholdDataList}
          setThresholdDataList={setThresholdDataList}
          handleDryspellThreshold={props.handleDryspellThreshold}
          finalDroughtParameterMonthsList={props.finalDroughtParameterMonthsList}
          handleFinalDroughtParamMonthChange={props.handleFinalDroughtParamMonthChange}
          handleSPIThreshold={props.handleSPIThreshold}
        />
      )}
    </>
  );
};

  return (
    <>
      <div className="drought-sidebar-dropdown">
        {droughtModuleIndicators?.map((item, index) => {
          let data = '';
          if (item.name === DSS_MODULE_CONSTS.MANDATORY) {
            data = isCompare ? CompareDroughtIndicators.mandatory : DroughtIndicators.mandatory;
          } else if (item.name === DSS_MODULE_CONSTS.IMPACT) {
            data = isCompare ? CompareDroughtIndicators.impact : DroughtIndicators.impact;
          }

          return (
            <>
              {renderDSSCheckboxes({
                item,
                index,
                data,
                handleCategory: isCompare ? handleCompareCategory : handleCategory,
                isSubCategory: false,
                isCategory: true,
                isCompare: isCompare,
              })}
            </>
          );
        })}
      </div>
    </>
  );
};

export default DSSDroughtIndicatorsCollapse;

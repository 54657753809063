/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import Chart from 'react-apexcharts';
import { renderChartTime, renderGenericDateFormat } from '../common/utils';
import { CHART_LABEL_COLORS,POLYGON, DATA_SOURCES } from '../constants/index';
import closeIcon from '../../assets/Images/closeIcon.svg';

function GeometryCharts(props) {
  const {
    renderCharts,
    dataSource,
    initialSelectedDate,
    noaaRegionTimeSeries,
    selectedParam,
    region,
  } = props;
  const [series, setSeries] = useState([]);
  const [compareCountries,setCompareCountries] = useState([])
  const [compareDataSource,setCompareDataSource] = useState([])

  const [YAxisOptions, setYAxisOptions] = useState([
    {
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: (CHART_LABEL_COLORS && CHART_LABEL_COLORS[0]) || '#008FFB',
      },
      labels: {
        style: {
          colors: (CHART_LABEL_COLORS && CHART_LABEL_COLORS[0]) || '#008FFB',
          fontSize: '12px',
          fontFamily: 'National',
        },
        formatter: function(value) {
          if (!value == null || !isNaN(value)) {
            if (value % 1 != 0) {
              return value.toFixed(2);
            } else {
              return value;
            }
          }
        },
      },
      title: {
        style: {
          color: (CHART_LABEL_COLORS && CHART_LABEL_COLORS[0]) || '#008FFB',
        },
      },
      tooltip: {
        enabled: true,
      },
      scaleLabel: {
        display: true,
      },
      ticks: {
        callback: function(value) {
          return value;
        },
      },
    },
  ]);

  const [options, setOptions] = useState({
    chart: {
      type: 'column',
      stacked: false,
      zoom: {
        enabled: true,
        type: 'x',
      },
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [0, 1],
    },
    stroke: {
      // width: [1, 1, 4],
      width: 4,
    },
    title: {
      text: 'Data Sources',
      align: 'left',
      offsetX: 110,
      style: {
        fontSize: '14px',
        fontWeight: 'normal',
        fontFamily: 'National',
        color: '#2A547C',
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '55%',
        barHeight: '70%',
      },
    },
    xaxis: {
      categories: [],
      labels: {
        showDuplicates: false,
        style: {
          fontSize: '12px',
          fontFamily: 'National',
        },
        crossShair: {
          stroke: {
            curve: 'straight',
          },
        },
      },
    },
    yaxis: YAxisOptions,
    tooltip: {
      fixed: {
        enabled: true,
        position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
        offsetY: 30,
        offsetX: 60,
      },
    },
    legend: {
      horizontalAlign: 'left',
      offsetX: 40,
    },
  });
  
  useEffect(() => {
    if (dataSource.length && initialSelectedDate) {
      const startDate = renderChartTime(initialSelectedDate?.graph?.startDate);
      const endDate = renderChartTime(initialSelectedDate?.graph?.endDate);
      let titleText = '';
      if (!region?.length) {
        titleText = `Parameter: ${selectedParam?.parameter?.name}`;
      } else {
        titleText = `Data Source: ${dataSource[0]?.name}( ${selectedParam?.parameter?.name})`;
      }

      let a;
      if (region.length && region){
        if(region[0].polygonCount == 1){
            a = POLYGON
        }
      }
      /**
       * changed by: sai krishna thalla
       * reason: compare data source has not been adding to the downloaded file name
       * date : 7-sep-2022
       */
      let countriesAndCompareDataSrc = "(" + compareCountries[0]+ ")"  + compareDataSource + "("  + compareCountries[1]+ ")"
      if(dataSource[0].id === DATA_SOURCES.IRI.id){
      setOptions(prev => ({
        ...prev,
        chart:{
          ...options.chart,
          toolbar: {
            show: true,
            export: {
              csv: {
                filename: a?  (compareCountries.length ? (dataSource[0]?.name + countriesAndCompareDataSrc + a ): (dataSource[0]?.name + "-" + a)) : (compareCountries.length ? (dataSource[0]?.name +countriesAndCompareDataSrc) : (dataSource[0]?.name)),
                columnDelimiter: ',',
                headerCategory: 'Date',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString();
                },
              },
              svg: {
                filename: a?  (compareCountries.length ? (dataSource[0]?.name + countriesAndCompareDataSrc + a ): (dataSource[0]?.name + "-" + a)) : (compareCountries.length ? (dataSource[0]?.name +countriesAndCompareDataSrc) : (dataSource[0]?.name)),
                columnDelimiter: ',',
                headerCategory: 'Date',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString();
                },
              },
              png: {
                filename: a?  (compareCountries.length ? (dataSource[0]?.name + countriesAndCompareDataSrc + a ): (dataSource[0]?.name + "-" + a)) : (compareCountries.length ? (dataSource[0]?.name +countriesAndCompareDataSrc) : (dataSource[0]?.name)),
                columnDelimiter: ',',
                headerCategory: 'Date',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString();
                },
              },
            },
            tools: {
              customIcons: [
                {
                  icon: `<img src=${closeIcon} width="20">`,
                  index: 6,
                  title: 'Close',
                  class: 'chart-close-icon',
                  click: function (chart, options, e) {
                    renderCharts();
                  },
                },
              ],
            },
          },
        },
        title: {
          ...options.title,
          text: titleText,
          align: 'middle',
          floating: false,
          offsetX: 20
        },
        subtitle: {
          text: `Forecast Lead Time: ${handleSubTitle()}`,
          align: 'left',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '16px',
            fontWeight: 'normal',
            fontFamily: 'National',
            color: '#363636',
          },
        },
      }));
    }
    else{
      setOptions(prev => ({
        ...prev,
        chart:{
          ...options.chart,
          toolbar: {
            show: true,
            export: {
              csv: {
                filename: a?  (compareCountries.length ? (dataSource[0]?.name + countriesAndCompareDataSrc + a ): (dataSource[0]?.name + "-" + a)) : (compareCountries.length ? (dataSource[0]?.name +countriesAndCompareDataSrc) : (dataSource[0]?.name)),
                columnDelimiter: ',',
                headerCategory: 'Date',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString();
                },
              },
              svg: {
                filename: a?  (compareCountries.length ? (dataSource[0]?.name + countriesAndCompareDataSrc + a ): (dataSource[0]?.name + "-" + a)) : (compareCountries.length ? (dataSource[0]?.name +countriesAndCompareDataSrc) : (dataSource[0]?.name)),
                columnDelimiter: ',',
                headerCategory: 'Date',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString();
                },
              },
              png: {
                filename: a?  (compareCountries.length ? (dataSource[0]?.name + countriesAndCompareDataSrc + a ): (dataSource[0]?.name + "-" + a)) : (compareCountries.length ? (dataSource[0]?.name +countriesAndCompareDataSrc) : (dataSource[0]?.name)),
                columnDelimiter: ',',
                headerCategory: 'Date',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString();
                },
              },
            },
            tools: {
              customIcons: [
                {
                  icon: `<img src=${closeIcon} width="20">`,
                  index: 6,
                  title: 'Close',
                  class: 'chart-close-icon',
                  click: function (chart, options, e) {
                    renderCharts();
                  },
                },
              ],
            },
          },
        },
        title: {
          ...options.title,
          text: titleText,
          align: 'middle',
          floating: false,
          offsetX: 20
        },
        subtitle: {
          text: `Time: ${startDate} to ${endDate}`,
          align: 'left',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '16px',
            fontWeight: 'normal',
            fontFamily: 'National',
            color: '#363636',
          },
        },
      }));
    }
    }
  }, [dataSource, initialSelectedDate,region,dataSource[0].name,compareCountries]);

  const handleSubTitle = () => {
    let dates;
      if(selectedParam?.forecast_data_source_id === DATA_SOURCES.IRI.id){
        dates = noaaRegionTimeSeries[0]?.millis?.map(item => renderGenericDateFormat(item));
      }
      return dates?.[0]
  }
  useEffect(() => {
    let seriesData = [];
    let YAxisoptionsData = YAxisOptions;
    let isFloat = false;
    if (noaaRegionTimeSeries && noaaRegionTimeSeries?.length) {
      let dates;
      if(selectedParam?.forecast_data_source_id === DATA_SOURCES.IRI.id){
        dates = noaaRegionTimeSeries[0]?.millis?.map(item => renderGenericDateFormat(item));
      }
      else{
        dates = noaaRegionTimeSeries[0].Timestamp?.map(item => renderGenericDateFormat(item));
      }

      noaaRegionTimeSeries.map((item, index) => {
        let data = item?.data?.map((x) => (x ? x.toFixed(2) : 0)) || [];
        let type = '';
        if (!region?.length) {
          type = `${item.layerType} (${item?.location})`;
          setCompareCountries(prev => [...prev,item?.location])
          setCompareDataSource(item.layerType)
        } else {
          type = item.layerType
            ? item.layerType === 'marker'
              ? `point(${item?.latlng[1]?.toFixed(2)},${item?.latlng[0].toFixed(2)})`
              : item.layerType + '-' + item?.polygonCount
            : '';
        }

        seriesData.push({ name: type, data });
        const duplicateYAxis = YAxisoptionsData?.findIndex((item)=> item?.seriesName?.includes(type) || item?.name?.includes(type));
     if(dataSource[0].id === DATA_SOURCES.IRI.id && noaaRegionTimeSeries[0]?.data?.every(i => i < 0)){
      YAxisoptionsData[0].max =  0
    }
        if (index === 0) {
          YAxisoptionsData[0] = {
            ...YAxisoptionsData[0],
            labels: {
              style: {
                colors: CHART_LABEL_COLORS[index],
                fontSize: '12px',
                fontFamily: 'National',
              },
              formatter: function(value) {
                if (!value == null || !isNaN(value)) {
                  if (value % 1 != 0) {
                    isFloat=true
                    return value.toFixed(2)
                  } else {
                    return value
                  }
                }
              },
            },
            title: {
              text: type,
              style: {
                color: CHART_LABEL_COLORS[index],
              },
            },
            scaleLabel: {
              display: true,
              labelString: type + item?.Units[0],
            },
          };
        }

        if (index > 0 && duplicateYAxis < 0) {
          YAxisoptionsData.push({
            seriesName: type,
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: CHART_LABEL_COLORS[index],
            },
            labels: {
              style: {
                colors: CHART_LABEL_COLORS[index],
                fontSize: '12px',
                fontFamily: 'National',
              },
              formatter: function(value) {
                if (!value == null || !isNaN(value)) {
                  if (value % 1 != 0) {
                    return value.toFixed(2)
                  } else {
                    if (isFloat){
                      return value.toFixed(2)
                    }else{
                      return value
                    }
                  }
                }
              },
            },
            title: {
              text: type,
              style: {
                color: CHART_LABEL_COLORS[index],
              },
            },
            scaleLabel: {
              display: true,
              labelString: type + item?.Units[0],
            },
          });
        }
      });

      setOptions((prev) => ({
        ...prev,
        xaxis: {
          ...options.xaxis,
          type: 'datetime',
          categories: dates,
          tickPlacement: 'on',
          min: dates && dates[0],
          max: dates && dates[dates.length - 1],
          labels: {
            showDuplicates: false,
            hideOverlappingLabels: true,
            format: 'M/DD @ hA',
          },
          //range: 30,
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [0, 1],
        },
      }));
      setYAxisOptions(YAxisoptionsData);
      setSeries(seriesData);
    }
  }, [noaaRegionTimeSeries]);

  useEffect(() => {
    setOptions(prev => ({
      ...prev,
      yaxis: YAxisOptions,
    }));
  }, [YAxisOptions]);


  return (
    <div>
      <div className="row">
        <div className="chart">
          {series.length || !_.isEmpty(options) ? (
            <>
              <Chart
                id="chart"
                options={options}
                series={series}
                type="line"
                width="100%"
                height="300px"
              />
            </>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <h3>No Chart Data Found</h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default GeometryCharts;


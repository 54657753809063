import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {USER_LIST_CONST, USER_BY_ID_CONST, USER_DELETE_CONST,USER_PERMISSIONS_CONST,UPDATE_PERMISSIONS_CONST,USER_PERMISSIONS_BY_ID_CONST,USER_DETAIL_CONST} from './userListConst';
import { userListSuccess, userListFailed, userByIdSuccess,userByIdFailed,userDetailFailed,userDetailSuccess, deleteUserFailed, deleteUserSuccess, userPermissionsFailed,userPermissionsSuccess,updatePermissionsSuccess,updatePermissionsFailed,userPermissionsByIdSuccess,userPermissionsByIdFailed} from './userListAction';
import {USER_URL,AXIOS_INSTANCE,PERMISSIONS_URL } from "../api/apiEndPoint";

const userListApiRequest = async (payload) => {
  let response = {};
  let data;
  try {
    if(payload.page && payload.limit){
      data = await AXIOS_INSTANCE.post(`${USER_URL}/getAll?page=${payload.page}&limit=${payload.limit}`,payload.data);
    }
    else{
      data = await AXIOS_INSTANCE.post(`${USER_URL}/getAll`,payload.data);
    }
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const userByIdApiRequest = async (payload) => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.get(`${USER_URL}/getDetailById/${payload.id}`,payload.data);
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const deleteUserApiRequest = async (payload) => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.delete(`${USER_URL}/delete/${payload.id}`,payload);
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};


const userPermissionsApiRequest = async (payload) => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.get(`${PERMISSIONS_URL}/getAllPermission`,payload);
    response = {
      data: data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};




const updatePermissionsApiRequest = async (payload) => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.post(`${PERMISSIONS_URL}/assign`,payload);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};


const userPermissionsByIdApiRequest = async (payload) => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.get(`${PERMISSIONS_URL}/getUserPermissions/${payload.id}`,payload);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};



const userDetailApiRequest = async (payload) => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.get(`${USER_URL}/getDetail`,payload);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};


function* userListRequest(action) {
  try {
    const response = yield call(userListApiRequest, action.payload);
    if (response) {
   
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data.data,
        },
      };
      yield put(userListSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(userListFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(userListFailed(errorData));
  }
}


function* userByIdRequest(action) {
  try {
    const response = yield call(userByIdApiRequest, action.payload);
    if (response) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data.data,
        },
      };
      yield put(userByIdSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(userByIdFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(userListFailed(errorData));
  }
}



function* deleteUserRequest(action) {
  try {
    const response = yield call(deleteUserApiRequest, action.payload);
    if (response) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data.data,
        },
      };
      yield put(deleteUserSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(deleteUserFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(deleteUserFailed(errorData));
  }
}


function* userPermissionsRequest(action) {
  try {
    const response = yield call(userPermissionsApiRequest, action.payload);
    if (response) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data.data,
        },
      };
      yield put(userPermissionsSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(userPermissionsFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(userPermissionsFailed(errorData));
  }
}


function* updatePermissionsRequest(action) {
  try {
    const response = yield call(updatePermissionsApiRequest, action.payload);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data,
        },
      };
      yield put(updatePermissionsSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(updatePermissionsFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(updatePermissionsFailed(errorData));
  }
}


function* userPermissionsByIdRequest(action) {
  try {
    const response = yield call(userPermissionsByIdApiRequest, action.payload);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data,
        },
      };
      yield put(userPermissionsByIdSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(userPermissionsByIdFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(userPermissionsByIdFailed(errorData));
  }
}

function* userDetailRequest(action) {
  try {
    const response = yield call(userDetailApiRequest, action.payload);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data,
        },
      };
      yield put(userDetailSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(userDetailFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(userDetailFailed(errorData));
  }
}


















export function* watchUserList() {
    yield takeEvery(USER_LIST_CONST.USER_LIST_REQUEST, userListRequest);
    yield takeEvery(USER_BY_ID_CONST.USER_BY_ID_REQUEST, userByIdRequest);
    yield takeEvery(USER_DELETE_CONST.USER_DELETE_REQUEST, deleteUserRequest);
    yield takeEvery(USER_PERMISSIONS_CONST.USER_PERMISSION_REQUEST, userPermissionsRequest);
    yield takeEvery(UPDATE_PERMISSIONS_CONST.UPDATE_PERMISSION_REQUEST, updatePermissionsRequest);
    yield takeEvery(USER_PERMISSIONS_BY_ID_CONST.USER_PERMISSIONS_BY_ID_REQUEST, userPermissionsByIdRequest);
    yield takeEvery(USER_DETAIL_CONST.USER_DETAIL_REQUEST, userDetailRequest);
  }
  
  function* userListSaga() {
    yield all([fork(watchUserList)]);
  }
  
  export default userListSaga;

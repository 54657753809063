import React, { useEffect, useRef, useState } from 'react';
import DraggableList from 'react-draggable-list';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from 'reactstrap';
import { t } from 'react-multi-lang';

import { showError, showSuccess } from '../../Components/Notifications';
import Loader from '../../Components/common/TextLoader';
import { isSuperAdmin } from '../../Components/common/utils';
import { ReactComponent as AddIcon } from '../../assets/Images/add-icon.svg';
import OrderIcon from '../../assets/Images/drag-icon.svg';
import NoResultIcon from '../../assets/Images/no-results-icon.svg';
import {
  addLandingPageRequest,
  addLandingPageSuccess,
  deleteLandingPageRequest,
  deleteLandingPageSuccess,
  editLandingPageRequest,
  editLandingPageSuccess,
  getDmsTestimonialsRequest,
  getDmsTestimonialsSuccess,
} from '../../redux/home/homeActions';
import DeleteModal from './DeleteModal';
import NewTestimonialModal from './NewTestimonialModal';

function ManageTestimonials({ title, userPermisions }) {
  const containerRef = useRef();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [testimonialsData, setTestimonialsData] = useState([]);
  const [selectedTestominals, setSelectedTestimonials] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeDropdownId, setActiveDropdownId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    role: '',
    description: '',
    profile_image: null,
  });

  const { LandingPageData } = useSelector(state => ({
    LandingPageData: state.Home,
  }));

  useEffect(() => {
    dispatch(getDmsTestimonialsRequest({}));

    return () => {
      dispatch(getDmsTestimonialsSuccess({}));
      dispatch(editLandingPageSuccess({}));
      dispatch(addLandingPageSuccess({}));
      dispatch(deleteLandingPageSuccess({}));
    };
  }, [dispatch]);

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (LandingPageData.isGetDmsTestimonialsRequesting) {
      setIsLoading(true);
    } else if (LandingPageData.getDmsTestimonialsData) {
      const data = LandingPageData.getDmsTestimonialsData.data;
      if (data && Array.isArray(data.result)) {
        setTestimonialsData(data.result);
      }
      setIsLoading(false);
    } else if (
      LandingPageData.getDmsTestimonialsDataError ||
      LandingPageData.getDmsTestimonialsDataNetworkError
    ) {
      showError('Something went wrong');
      setIsLoading(false);
    }
  }, [
    LandingPageData.getDmsTestimonialsData,
    LandingPageData.getDmsTestimonialsDataError,
    LandingPageData.getDmsTestimonialsDataNetworkError,
    LandingPageData.isGetDmsTestimonialsRequesting,
  ]);

  const toggleDropdown = id => {
    setActiveDropdownId(prevId => (prevId === id ? null : id));
  };

  const handleEdit = editData => {
    const userData = {
      name: editData.name,
      role: editData.role,
      description: editData.description,
      profile_image: editData.file_url,
      id: editData.id,
    };
    setFormData(userData);
    setIsModalOpen(true);
    setIsEdit(true);
  };

  const handleDelete = data => {
    setSelectedTestimonials(data);
    setDeleteModal(true);
  };

  const onHandleDelete = () => {
    setDeleteModal(false);
    dispatch(
      deleteLandingPageRequest({
        id: selectedTestominals?.id,
        end_point: 'delete_testimonials',
        query: 'testimonial_id',
      }),
    );
  };

  const onHandleTestimonials = data => {
    const submissionData = new FormData();

    // Append form fields to FormData
    if (data.name) submissionData.append('name', data.name);
    if (data.role) submissionData.append('role', data.role);
    if (data.description) submissionData.append('description', data.description);

    if (data.profile_image) {
      submissionData.append('profile_image', data.profile_image);
    }
    if (isEdit) {
      // * MAKE SURE TO SEND IT AS submissionData
      submissionData.append('testimonial_id', formData.id);
      dispatch(editLandingPageRequest({ submissionData, end_point: 'update_testimonials' }));
      setIsEdit(false);
    } else {
      dispatch(addLandingPageRequest({ submissionData, end_point: 'add_testimonials' }));
    }
  };

  const isAddRef = useRef(true);
  useEffect(() => {
    if (isAddRef.current) {
      isAddRef.current = false;
      return;
    }
    if (LandingPageData.isAddLandingPageRequesting) {
      setIsLoading(true);
    } else if (LandingPageData?.addLandingPageData?.data) {
      showSuccess(LandingPageData?.addLandingPageData?.data?.result);
      dispatch(getDmsTestimonialsRequest({}));
      setIsLoading(false);
      setFormData({
        name: '',
        role: '',
        description: '',
        profile_image: null,
      });
    } else if (
      LandingPageData.addLandingPageDataError ||
      LandingPageData.addLandingPageDataNetworkError
    ) {
      const errorMessageArray =
        LandingPageData.addLandingPageDataError?.response?.data?.response?.data?.message || [];
      const [errorMessage] = errorMessageArray;

      showError(errorMessage);
      setIsLoading(false);
    }
  }, [
    LandingPageData?.addLandingPageData,
    LandingPageData.isAddLandingPageRequesting,
    LandingPageData.addLandingPageDataError,
    LandingPageData.addLandingPageDataNetworkError,
    dispatch,
  ]);

  const isEditRef = useRef(true);
  useEffect(() => {
    if (isEditRef.current) {
      isEditRef.current = false;
      return;
    }
    if (LandingPageData.iseditLandingPageRequesting) {
      setIsLoading(true);
      setIsEdit(false);
    } else if (LandingPageData?.editLandingPageData?.data) {
      showSuccess(LandingPageData?.editLandingPageData?.data?.result);
      dispatch(getDmsTestimonialsRequest({}));
      setIsLoading(false);
      setFormData({
        name: '',
        role: '',
        description: '',
        profile_image: null,
      });
      setIsEdit(false);
    } else if (
      LandingPageData.editLandingPageDataError ||
      LandingPageData.editLandingPageDataNetworkError
    ) {
      const errorMessageArray =
        LandingPageData.editLandingPageDataError?.response?.data?.response?.data?.message || [];
      const [errorMessage] = errorMessageArray;

      showError(errorMessage);
      setIsLoading(false);
      setIsEdit(false);
    }
  }, [
    LandingPageData.editLandingPageData,
    LandingPageData.iseditLandingPageRequesting,
    LandingPageData.editLandingPageDataError,
    LandingPageData.editLandingPageDataNetworkError,
    dispatch,
  ]);

  const isDeleteRef = useRef(true);
  useEffect(() => {
    if (isDeleteRef.current) {
      isDeleteRef.current = false;
      return;
    }
    if (LandingPageData.isDeleteLandingPageRequesting) {
      setIsLoading(true);
    } else if (LandingPageData?.deleteLandingPageData?.data) {
      showSuccess(LandingPageData?.deleteLandingPageData?.data?.result);
      dispatch(getDmsTestimonialsRequest({}));
      setIsLoading(false);
      setFormData({
        name: '',
        role: '',
        description: '',
        profile_image: null,
      });
    } else if (
      LandingPageData.deleteLandingPageDataError ||
      LandingPageData.deleteLandingPageDataNetworkError
    ) {
      showError(LandingPageData.deleteLandingPageDataError?.response?.data?.message);
      setIsLoading(false);
    }
  }, [
    LandingPageData?.deleteLandingPageData,
    LandingPageData.isDeleteLandingPageRequesting,
    LandingPageData.deleteLandingPageDataError,
    LandingPageData.deleteLandingPageDataNetworkError,
    dispatch,
  ]);

  const _onListChange = (newList, e, old, newIndex) => {
    const submissionData = {
      order: newIndex + 1,
      testimonial_id: e.id,
    };

    setTimeout(() => {
      dispatch(editLandingPageRequest({ submissionData, end_point: 'update_testimonials' }));
    }, 1000);
    setTestimonialsData(newList);
  };

  const Item = ({ item, dragHandleProps }) => {
    const { onMouseDown, onTouchStart } = dragHandleProps;

    return (
      <Col
        xs={12}
        key={item.id}
        className="mb-3"
        style={{ width: '100%', display: 'flex', alignItems: 'flex-start' }}
      >
        <img
          src={OrderIcon}
          alt="order-icon"
          className="dms-drag-icon"
          onTouchStart={e => {
            onTouchStart(e);
          }}
          onMouseDown={e => {
            onMouseDown(e);
          }}
        />
        <Card className="testimonial-card w-100">
          <CardBody className="d-flex flex-column py-0">
            <Row className="align-items-center my-3">
              <Col xs="auto">
                <img src={item.file_url} alt="Avatar" className="avatar" />
              </Col>
              <Col>
                <CardTitle className="person-name mb-1">{item.name}</CardTitle>
                <CardText className="person-title">{item.role}</CardText>
              </Col>
              {isSuperAdmin() ||
              userPermisions.some(permission =>
                ['change_dmstestimonials', 'delete_dmstestimonials'].includes(permission),
              ) ? (
                <Dropdown
                  isOpen={activeDropdownId === item.id}
                  toggle={() => toggleDropdown(item.id)}
                  direction="left"
                  className=" d-inline w-auto"
                >
                  <DropdownToggle
                    tag="span"
                    data-toggle="dropdown"
                    aria-expanded={activeDropdownId === item.id}
                    className="dms-pillar-list-dropdown-toggle"
                  >
                    <i className="fa fa-ellipsis-v dms-toggle-size" aria-hidden="true"></i>
                  </DropdownToggle>
                  <DropdownMenu className=" p-0">
                    {isSuperAdmin() || userPermisions.includes('change_dmstestimonials') ? (
                      <DropdownItem
                        onClick={() => handleEdit(item)}
                        className="dms-dropdown-options dms-dropdown-options-border"
                      >
                        {t('LANDING_PAGE.EDIT_DETAILS')}
                      </DropdownItem>
                    ) : null}
                    {isSuperAdmin() || userPermisions.includes('delete_dmstestimonials') ? (
                      <DropdownItem
                        onClick={() => handleDelete(item)}
                        className="dms-dropdown-options dms-dropdown-options-delete"
                      >
                        {t('LANDING_PAGE.DELETE')}
                      </DropdownItem>
                    ) : null}
                  </DropdownMenu>
                </Dropdown>
              ) : null}
            </Row>
            <CardText className="testimonial-text">{item.description}</CardText>
          </CardBody>
        </Card>
      </Col>
    );
  };

  return (
    <>
      {isLoading && <Loader />}
      {isModalOpen ? (
        <NewTestimonialModal
          modal={isModalOpen}
          setModal={setIsModalOpen}
          onHandleTestimonials={onHandleTestimonials}
          setFormData={setFormData}
          formData={formData}
          isEditable={isEdit}
        />
      ) : null}
      {deleteModal ? (
        <DeleteModal
          isOpen={deleteModal}
          toggle={() => setDeleteModal(!deleteModal)}
          message={`This will delete the testimonial from “${selectedTestominals?.name}” and will not be able to see it.`}
          onHandleDelete={onHandleDelete}
        />
      ) : null}
      <div className="dms-title-container">
        <h1 className="dms-title">{title.label}</h1>
        {isSuperAdmin() || userPermisions.includes('add_dmstestimonials') ? (
          <Button
            className="dms-add-edit-btn"
            onClick={() => {
              setIsModalOpen(!isModalOpen);
              setIsEdit(false);
            }}
          >
            {t('LANDING_PAGE.ADD_NEW')} <AddIcon height={20} width={20} />
          </Button>
        ) : null}
      </div>
      <Container fluid>
        <Row>
          {!isLoading ? (
            testimonialsData?.length ? (
              <div ref={containerRef} className="scrollable-list-container">
                <DraggableList
                  itemKey="id"
                  template={Item}
                  list={testimonialsData}
                  onMoveEnd={(newList, e, oldIndex, newIndex) =>
                    _onListChange(newList, e, oldIndex, newIndex)
                  }
                  container={() => containerRef.current}
                />
              </div>
            ) : (
              <div className=" h-100 d-flex justify-content-center align-items-center flex-column p-5">
                <img src={NoResultIcon} alt="No results to show" className=" d-block" />
                <p className="no-result-text">{t('LANDING_PAGE.NO_RESULTS_FOUND')}</p>
              </div>
            )
          ) : null}
        </Row>
      </Container>
    </>
  );
}

export default ManageTestimonials;

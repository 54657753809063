import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { AXIOS_INSTANCE, USER_URL } from '../api/apiEndPoint';
import {
  LOGIN_CONST,
  FORGOT_PASSWORD,
  CHANGE_PASSWORD,
  RESET_PASSWORD,
  RESET_LINK_VERIFICATION,
} from './loginConst';
import {
  loginUserSuccess,
  loginUserFailed,
  resetLinkVerificationSuccess,
  resetLinkVerificationFailed,
  forgotPasswordSuccess,
  forgotPasswordFailed,
  changePasswordSuccess,
  changePasswordFailed,
  resetPasswordSuccess,
  resetPasswordFailed,
} from './loginActions';
import { showError } from '../../Components/Notifications';
import _ from 'lodash';

require('dotenv').config();

const loginUserApiRequest = async payload => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.post(`${USER_URL}/login`, payload);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const forgotPasswordApiRequest = async payload => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.post(`${USER_URL}/forgotPassword`, payload);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    if (error.response.data.errors) {
      showError(error.response.data.errors.email[0]);
    } else if (error.response.data.exception) {
      showError('Internal server error.Please try later');
    }
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const changePasswordApiRequest = async payload => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.post(`${USER_URL}/changePassword`, payload);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    if (!_.isEmpty(error.response.data.errors)) {
      showError(Object.values(error.response.data.errors)[0]);
    }
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const resetPasswordApiRequest = async payload => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.post(`${USER_URL}/resetPassword`, payload);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    showError(error.response.data.message);
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const resetLinkVerificationApiRequest = async payload => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.get(
      `${USER_URL}/resetLinkVerification/${payload.id}`,
      payload,
    );
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    // showError(error.response.data.message);
    response = {
      error: data,
      status: 400,
    };
  }

  return response;
};

function* loginUser(action) {
  try {
    const apiResponse = yield call(loginUserApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(loginUserSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(loginUserFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(loginUserFailed(errorData));
  }
}

function* forgotPassword(action) {
  try {
    const apiResponse = yield call(forgotPasswordApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(forgotPasswordSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(forgotPasswordFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(forgotPasswordFailed(errorData));
  }
}

function* changePassword(action) {
  try {
    const apiResponse = yield call(changePasswordApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(changePasswordSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(changePasswordFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(changePasswordFailed(errorData));
  }
}

function* resetPassword(action) {
  try {
    const apiResponse = yield call(resetPasswordApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(resetPasswordSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(resetPasswordFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(resetPasswordFailed(errorData));
  }
}

function* resetLinkVerificationRequest(action) {
  try {
    const apiResponse = yield call(resetLinkVerificationApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(resetLinkVerificationSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(resetLinkVerificationFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(resetLinkVerificationFailed(errorData));
  }
}

/**
 *  Login carousel
 */

export function* watchLoginUser() {
  yield takeEvery(LOGIN_CONST.LOGIN_REQUEST, loginUser);
  yield takeEvery(FORGOT_PASSWORD.FORGOT_PASSWORD_REQUEST, forgotPassword);
  yield takeEvery(CHANGE_PASSWORD.CHANGE_PASSWORD_REQUEST, changePassword);
  yield takeEvery(RESET_PASSWORD.RESET_PASSWORD_REQUEST, resetPassword);
  yield takeEvery(
    RESET_LINK_VERIFICATION.RESET_LINK_VERIFICATION_REQUEST,
    resetLinkVerificationRequest,
  );
}

function* loginSaga() {
  yield all([fork(watchLoginUser)]);
}

export default loginSaga;

import {
  NEWS_FEED_CATEGORIES_CONST,
  NEWS_FEED_SUB_CATEGORIES_CONST,
  NEWS_FEED_ADD_CONST,
  GET_ALL_NEWS_FEED_CONST,
  NEWS_FEED_DELETE_CONST,
  NEWS_FEED_UPDATE_CONST,
  GET_BY_ID_NEWS_FEED_CONST,
  NEWS_FEED_SUBSCRIBE_CONST,
  NEWS_FEED_CHANGE_STATUS_CONST
} from './newsFeedConsts';

export const newsFeedCategoriesRequest = (data) => ({
  type: NEWS_FEED_CATEGORIES_CONST.NEWS_FEED_CATEGORIES_REQUEST,
  payload: data,
});

export const newsFeedCategoriesSuccess = (response) => ({
  type: NEWS_FEED_CATEGORIES_CONST.NEWS_FEED_CATEGORIES_SUCCESS,
  payload: response,
});

export const newsFeedCategoriesFailed = (error) => ({
  type: NEWS_FEED_CATEGORIES_CONST.NEWS_FEED_CATEGORIES_FAILED,
  payload: error,
});

export const newsFeedSubCategoriesRequest = (data) => ({
  type: NEWS_FEED_SUB_CATEGORIES_CONST.NEWS_FEED_SUB_CATEGORIES_REQUEST,
  payload: data,
});

export const newsFeedSubCategoriesSuccess = (response) => ({
  type: NEWS_FEED_SUB_CATEGORIES_CONST.NEWS_FEED_SUB_CATEGORIES_SUCCESS,
  payload: response,
});

export const newsFeedSubCategoriesFailed = (error) => ({
  type: NEWS_FEED_SUB_CATEGORIES_CONST.NEWS_FEED_SUB_CATEGORIES_FAILED,
  payload: error,
});

export const newsFeedAddRequest = (data) => ({
  type: NEWS_FEED_ADD_CONST.NEWS_FEED_ADD_REQUEST,
  payload: data,
});

export const newsFeedAddSuccess = (response) => ({
  type: NEWS_FEED_ADD_CONST.NEWS_FEED_ADD_SUCCESS,
  payload: response,
});

export const newsFeedAddFailed = (error) => ({
  type: NEWS_FEED_ADD_CONST.NEWS_FEED_ADD_FAILED,
  payload: error,
});

export const getAllNewsFeedRequest = (data) => ({
  type: GET_ALL_NEWS_FEED_CONST.GET_ALL_NEWS_FEED_REQUEST,
  payload: data,
});

export const getAllNewsFeedSuccess = (response) => ({
  type: GET_ALL_NEWS_FEED_CONST.GET_ALL_NEWS_FEED_SUCCESS,
  payload: response,
});

export const getAllNewsFeedFailed = (error) => ({
  type: GET_ALL_NEWS_FEED_CONST.GET_ALL_NEWS_FEED_FAILED,
  payload: error,
});

export const newsFeedDeleteRequest = (data) => ({
  type: NEWS_FEED_DELETE_CONST.NEWS_FEED_DELETE_REQUEST,
  payload: data,
});

export const newsFeedDeleteSuccess = (response) => ({
  type: NEWS_FEED_DELETE_CONST.NEWS_FEED_DELETE_SUCCESS,
  payload: response,
});

export const newsFeedDeleteFailed = (error) => ({
  type: NEWS_FEED_DELETE_CONST.NEWS_FEED_DELETE_FAILED,
  payload: error,
});

export const newsFeedUpdateRequest = (data) => ({
  type: NEWS_FEED_UPDATE_CONST.NEWS_FEED_UPDATE_REQUEST,
  payload: data,
});

export const newsFeedUpdateSuccess = (response) => ({
  type: NEWS_FEED_UPDATE_CONST.NEWS_FEED_UPDATE_SUCCESS,
  payload: response,
});

export const newsFeedUpdateFailed = (error) => ({
  type: NEWS_FEED_UPDATE_CONST.NEWS_FEED_UPDATE_FAILED,
  payload: error,
});

export const getByIdNewsFeedRequest = (data) => ({
  type: GET_BY_ID_NEWS_FEED_CONST.GET_BY_ID_NEWS_FEED_REQUEST,
  payload: data,
});

export const getByIdNewsFeedSuccess = (response) => ({
  type: GET_BY_ID_NEWS_FEED_CONST.GET_BY_ID_NEWS_FEED_SUCCESS,
  payload: response,
});

export const getByIdNewsFeedFailed = (error) => ({
  type: GET_BY_ID_NEWS_FEED_CONST.GET_BY_ID_NEWS_FEED_FAILED,
  payload: error,
});


export const subscribeNewsFeedRequest = (data) => ({
  type: NEWS_FEED_SUBSCRIBE_CONST.NEWS_FEED_SUBSCRIBE_REQUEST,
  payload: data,
});

export const subscribeNewsFeedSuccess = (response) => ({
  type: NEWS_FEED_SUBSCRIBE_CONST.NEWS_FEED_SUBSCRIBE_SUCCESS,
  payload: response,
});

export const subscribeNewsFeedFailed = (error) => ({
  type: NEWS_FEED_SUBSCRIBE_CONST.NEWS_FEED_SUBSCRIBE_FAILED,
  payload: error,
});

export const newsFeedChangeStatusRequest = (data) => ({
  type: NEWS_FEED_CHANGE_STATUS_CONST.NEWS_FEED_CHANGE_STATUS_REQUEST,
  payload: data
})

export const newsFeedChangeStatusSuccess = (response) => ({
  type: NEWS_FEED_CHANGE_STATUS_CONST.NEWS_FEED_CHANGE_STATUS_SUCCESS,
  payload: response
})

export const newsFeedChangeStatusFailed = (error) => ({
  type: NEWS_FEED_CHANGE_STATUS_CONST.NEWS_FEED_CHANGE_STATUS_FAILED,
  payload: error
})


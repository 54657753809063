import {LOGIN_CONST,FORGOT_PASSWORD, CHANGE_PASSWORD, RESET_PASSWORD, RESET_LINK_VERIFICATION } from './loginConst';

const Login = (state = {}, action) => {
  switch (action.type) {
    case LOGIN_CONST.LOGIN_REQUEST:
      return {
        ...state,
        isLoginRequesting: true,
        loginData: false,
        loginDataError: false,
        loginDataNetworkError: false,
      };
    case LOGIN_CONST.LOGIN_SUCCESS:
      return {
        ...state,
        isLoginRequesting: false,
        loginData: action.payload.response,
        loginDataError: false,
        loginDataNetworkError: false,
      };
    case LOGIN_CONST.LOGIN_FAILED:
      return {
        ...state,
        isLoginRequesting: false,
        loginData: false,
        loginDataError: action.payload.error ? false : action.payload.response,
        loginDataNetworkError: action.payload.error ? action.payload.error : false,
      };
    case LOGIN_CONST.LOGIN_CAROUSEL_REQUEST:
      return {
        ...state,
        isLoginCarouselRequesting: true,
        loginCarouselData: false,
        loginCarouselError: false,
        loginCarouselNetworkError: false,
      };
    case LOGIN_CONST.LOGIN_CAROUSEL_SUCCESS:
      return {
        ...state,
        isLoginCarouselRequesting: false,
        loginCarouselData: action.payload.response,
        loginCarouselError: false,
        loginCarouselNetworkError: false,
      };
    case LOGIN_CONST.LOGIN_CAROUSEL_FAILED:
      return {
        ...state,
        isLoginCarouselRequesting: false,
        loginCarouselData: false,
        loginCarouselError: action.payload.error ? false : action.payload.response,
        loginCarouselNetworkError: action.payload.error ? action.payload.error : false,
      };

      case FORGOT_PASSWORD.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        isForgotPasswordRequesting: true,
        forgotPasswordData: false,
        forgotPasswordError: false,
        forgotPasswordNetworkError: false,
      };
    case FORGOT_PASSWORD.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isForgotPasswordRequesting: false,
        forgotPasswordData: action.payload.response,
        forgotPasswordError: false,
        forgotPasswordNetworkError: false,
      };
    case FORGOT_PASSWORD.FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        isForgotPasswordRequesting: false,
        forgotPasswordData: false,
        forgotPasswordError: action.payload.error ? false : action.payload.response,
        forgotPasswordNetworkError: action.payload.error ? action.payload.error : false,
      };



      case CHANGE_PASSWORD.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isChangePasswordRequesting: true,
        changePasswordData: false,
        changePasswordError: false,
        changePasswordNetworkError: false,
      };
    case CHANGE_PASSWORD.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isChangePasswordRequesting: false,
        changePasswordData: action.payload.response,
        changePasswordError: false,
        changePasswordNetworkError: false,
      };
    case CHANGE_PASSWORD.CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        isChangePasswordRequesting: false,
        changePasswordData: false,
        changePasswordError: action.payload.error ? false : action.payload.response,
        changePasswordNetworkError: action.payload.error ? action.payload.error : false,
      };


      case RESET_PASSWORD.RESET_PASSWORD_REQUEST:
        return {
          ...state,
          isResetPasswordRequesting: true,
          resetPasswordData: false,
          resetPasswordError: false,
          resetPasswordNetworkError: false,
        };
      case RESET_PASSWORD.RESET_PASSWORD_SUCCESS:
        return {
          ...state,
          isResetPasswordRequesting: false,
          resetPasswordData: action.payload.response,
          resetPasswordError: false,
          resetPasswordNetworkError: false,
        };
      case RESET_PASSWORD.RESET_PASSWORD_FAILED:
        return {
          ...state,
          isResetPasswordRequesting: false,
          resetPasswordData: false,
          resetPasswordError: action.payload.error ? false : action.payload.response,
          resetPasswordNetworkError: action.payload.error ? action.payload.error : false,
        };
  



        case RESET_LINK_VERIFICATION.RESET_LINK_VERIFICATION_REQUEST:
          return {
            ...state,
            isResetLinkVerificationRequesting: true,
            resetLinkVerificationData: false,
            resetLinkVerificationError: false,
            resetLinkVerificationNetworkError: false,
          };
        case RESET_LINK_VERIFICATION.RESET_LINK_VERIFICATION_SUCCESS:
          return {
            ...state,
            isResetLinkVerificationRequesting: false,
            resetLinkVerificationData: action.payload.response,
            resetLinkVerificationError: false,
            resetLinkVerificationNetworkError: false,
          };
        case RESET_LINK_VERIFICATION.RESET_LINK_VERIFICATION_FAILED:
          return {
            ...state,
            isResetLinkVerificationRequesting: false,
            resetLinkVerificationData: false,
            resetLinkVerificationError: action.payload.error ? false : action.payload.response,
            resetLinkVerificationNetworkError: action.payload.error ? action.payload.error : false,
          };


    default:
      return { ...state };
  }
};

export default Login;

import {LOGIN_CONST, FORGOT_PASSWORD, CHANGE_PASSWORD, RESET_PASSWORD, RESET_LINK_VERIFICATION} from './loginConst';

/**
 *  User Login actions
 */
export const loginUser = (data) => ({
  type: LOGIN_CONST.LOGIN_REQUEST,
  payload: data,
});

export const loginUserSuccess = (response) => ({
  type: LOGIN_CONST.LOGIN_SUCCESS,
  payload: response,
});

export const loginUserFailed = (error) => ({
  type: LOGIN_CONST.LOGIN_FAILED,
  payload: error,
});

/**
 *  User carousel actions
 */
export const loginCarousel = () => ({
  type: LOGIN_CONST.LOGIN_CAROUSEL_REQUEST,
  payload: null,
});

export const loginCarouselSuccess = (response) => ({
  type: LOGIN_CONST.LOGIN_CAROUSEL_SUCCESS,
  payload: response,
});

export const loginCarouselFailed = (error) => ({
  type: LOGIN_CONST.LOGIN_CAROUSEL_FAILED,
  payload: error,
});


export const forgotPassword = (data) => ({
  type: FORGOT_PASSWORD.FORGOT_PASSWORD_REQUEST,
  payload: data,
});

export const forgotPasswordSuccess = (response) => ({
  type: FORGOT_PASSWORD.FORGOT_PASSWORD_SUCCESS,
  payload: response,
});

export const forgotPasswordFailed = (error) => ({
  type: FORGOT_PASSWORD.FORGOT_PASSWORD_FAILED,
  payload: error,
});





export const changePassword = (data) => ({
  type: CHANGE_PASSWORD.CHANGE_PASSWORD_REQUEST,
  payload: data,
});

export const changePasswordSuccess = (response) => ({
  type: CHANGE_PASSWORD.CHANGE_PASSWORD_SUCCESS,
  payload: response,
});

export const changePasswordFailed = (error) => ({
  type: CHANGE_PASSWORD.CHANGE_PASSWORD_FAILED,
  payload: error,
});





export const resetPassword = (data) => ({
  type: RESET_PASSWORD.RESET_PASSWORD_REQUEST,
  payload: data,
});

export const resetPasswordSuccess = (response) => ({
  type: RESET_PASSWORD.RESET_PASSWORD_SUCCESS,
  payload: response,
});

export const resetPasswordFailed = (error) => ({
  type: RESET_PASSWORD.RESET_PASSWORD_FAILED,
  payload: error,
});



export const resetLinkVerificationRequest = (data) => ({
  type: RESET_LINK_VERIFICATION.RESET_LINK_VERIFICATION_REQUEST,
  payload: data,
});

export const resetLinkVerificationSuccess = (response) => ({
  type: RESET_LINK_VERIFICATION.RESET_LINK_VERIFICATION_SUCCESS,
  payload: response,
});

export const resetLinkVerificationFailed = (error) => ({
  type: RESET_LINK_VERIFICATION.RESET_LINK_VERIFICATION_FAILED,
  payload: error,
});
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { t } from 'react-multi-lang';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Col, Container, Row } from 'reactstrap';

import Loader from '../../Components/common/loader';
import { LOCATION } from '../../Components/constants';
import { showSuccess } from '../../Components/Notifications';
import {
  getAllBulletinRequest,
  getAllTemplateListRequest,
  getBulletinIndicatorsRequest,
  getCountryRequest,
  getStateByCountryRequest,
} from '../../redux/actions';
import Footer from '../new-landing-page/Footer';
import CreateBulletinModal from './CreateBulletinModal';
import OnlineBulletInList from './OnlineBulletInList';

function OnlineBulletin() {
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [categoryFilter, setCategoryFilter] = useState([
    {
      id: '',
      name: 'All',
    },
  ]);
  const [selectedCategory, setSelectedCategory] = useState({
    id: '',
    name: 'All',
  });
  const [bulletinList, setBulletinList] = useState([]);
  const [yearsList, setYearsList] = useState([]);
  const [selectedYear, setSelectedYear] = useState('2024');
  const [isOpenBulletin, setIsOpenBulletin] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [indicatorList, setIndicatorList] = useState([]);
  const [countryData, setCountryData] = useState({});

  const { Bulletin, Location, PublishData } = useSelector(state => ({
    Bulletin: state.Bulletin,
    Location: state.Location,
    PublishData: state.Bulletin?.bulletinPublishData,
  }));

  useEffect(() => {
    const countryInfo = localStorage.getItem('countryData');
    // Convert the JSON string back to an object
    const countrySpecificData = JSON.parse(countryInfo);

    setCountryData({
      ...countrySpecificData,
      value: countrySpecificData.country_name,
      label: countrySpecificData.country_name,
    });
    dispatch(getCountryRequest({ location_type: LOCATION.Country, parent_id: '' }));
    dispatch(getAllTemplateListRequest({}));
    dispatch(getBulletinIndicatorsRequest({}));
    dispatch(
      getAllBulletinRequest({
        page: pageNumber,
        data: { year: selectedYear, is_publish: true },
      }),
    );
    const currentYear = new Date().getFullYear();
    const listOfYears = Array.from({ length: 5 }, (_, index) => currentYear - index);

    setYearsList(listOfYears);
    return () => {
      setCategoryFilter([
        {
          id: '',
          name: 'All',
        },
      ]);
    };
  }, []);

  const isPublishref = useRef(true);
  useEffect(() => {
    if (isPublishref.current) {
      isPublishref.current = false;
      return;
    }
    if (PublishData?.data?.status === 200) {
      showSuccess(PublishData?.data?.data?.result);
    }
  }, [PublishData]);

  useEffect(() => {
    if (!_.isEmpty(countryData)) {
      if (countryData?.country_slug !== 'southasia') {
        dispatch(
          getStateByCountryRequest({
            requestObj: {
              location_type: LOCATION.State,
              parent_id: countryData?.country_id,
            },
            isCompareLocation: false,
          }),
        );
      }
    }
  }, [countryData]);

  useEffect(() => {
    if (Bulletin.isGetAllTemplateListRequesting) {
      setCategoryFilter([
        {
          id: '',
          name: 'All',
        },
      ]);
    } else if (Bulletin?.getAllTemplateListData?.data) {
      if (
        Bulletin?.getAllTemplateListData?.data?.status === 200 &&
        Bulletin?.getAllTemplateListData?.data?.data?.result
      ) {
        // Modifying for setting them as options when creating bulletin
        const modifiedBulletinTypes = Bulletin?.getAllTemplateListData?.data?.data?.result.map(
          bulletin => ({
            ...bulletin,
            label: bulletin.name, // Adding 'label' with value from 'name'
            value: bulletin.name, // Adding 'value' with value from 'name'
          }),
        );
        setCategoryFilter(prev => [...prev, ...modifiedBulletinTypes]);
      } else {
        setCategoryFilter([]);
      }
    }
  }, [Bulletin.getAllTemplateListData, Bulletin.isGetAllTemplateListRequesting]);

  useEffect(() => {
    if (Bulletin?.getAllBulletinData?.data) {
      let new_list = [];
      const {
        data: { result },
      } = Bulletin?.getAllBulletinData;
      if (result) {
        if (bulletinList) {
          if (pageNumber > 1) {
            new_list = bulletinList?.concat(result?.results);
          } else {
            new_list = result?.results;
          }
        } else {
          new_list = result?.results;
        }
        setBulletinList(new_list);
        setPageCount(result?.count);
      } else {
        setBulletinList([]);
      }
    }
  }, [Bulletin?.getAllBulletinData]);

  useEffect(() => {
    if (Location.countryList) {
      const { result } = Location.countryList;
      if (result?.[0]) {
        let arr = [];
        result.map(i => {
          return arr.push({
            id: i.id,
            name: i.name,
            value: i.name,
            label: i.name,
            region_id: i.region_id,
            latitude: i.latitude,
            longitude: i.longitude,
            zoom_level: i.zoom_level,
          });
        });
        setCountryList(arr);
      }
    }
  }, [Location.countryList]);

  useEffect(() => {
    let arr = [];
    if (Location.stateList) {
      let result = '';
      if (Location?.stateList?.data) result = Location?.stateList?.data?.result;
      if (result) {
        result.map(i => {
          return arr.push({
            id: i.id,
            name: i.name,
            value: i.name,
            label: i.name,
            country_id: i.country_id,
            latitude: i.latitude,
            longitude: i.longitude,
            zoom_level: i.zoom_level,
          });
        });
      }
      setStateList(arr);
    }
  }, [Location.stateList]);

  useEffect(() => {
    let arr = [];
    if (Location.districtList) {
      let result = '';
      if (Location?.districtList?.data) result = Location?.districtList?.data?.result;
      if (result) {
        result?.map(i => {
          return arr.push({
            id: i.id,
            name: i.name,
            value: i.name,
            label: i.name,
            state_id: i.state_id,
            latitude: i.latitude,
            longitude: i.longitude,
            zoom_level: i.zoom_level,
          });
        });
      }
      setDistrictList(arr);
    }
  }, [Location.districtList]);

  useEffect(() => {
    if (!_.isEmpty(Bulletin.getBulletinIndicatorsData)) {
      const { data } = Bulletin.getBulletinIndicatorsData;
      const { result } = data;
      if (result?.length) {
        // Formating Data into required format for settings as options for Select Field
        const formattedOptions = result.map(category => ({
          label: category.category,
          options: category.indices.map(indicesData => ({
            ...indicesData,
            value: indicesData.name,
            label: indicesData.name,
          })),
        }));
        setIndicatorList(formattedOptions);
      }
    }
  }, [Bulletin?.getBulletinIndicatorsData]);

  // Fetch bulletins based on page, category, and year
  const fetchBulletins = useCallback(
    (page, payload) => {
      dispatch(getAllBulletinRequest({ page, data: payload }));
    },
    [dispatch],
  );

  const handleCreateBulletin = () => {
    setIsOpenBulletin(!isOpenBulletin);
  };

  const handleSelectCategory = data => {
    setPageNumber(1);
    setSelectedCategory(data);
    const payload = {
      category_id: data.id,
      is_publish: true,
    };

    if (selectedYear) {
      payload.year = selectedYear;
    }
    fetchBulletins(1, payload);
  };

  const handleSelectYear = e => {
    setPageNumber(1);
    setSelectedYear(e.target.value);
    const payload = {
      year: e.target.value,
      is_publish: true,
    };

    if (selectedCategory.id) {
      payload.category_id = selectedCategory.id;
    }
    fetchBulletins(1, payload);
  };

  const fetchNextTemplateData = () => {
    setPageNumber(pageNumber + 1);
    const payload = { is_publish: true };

    if (selectedCategory.id) {
      payload.category_id = selectedCategory.id;
    }
    if (selectedYear) {
      payload.year = selectedYear;
    }

    fetchBulletins(pageNumber + 1, payload);
  };

  return (
    <>
      {isOpenBulletin && (
        <CreateBulletinModal
          setIsOpenBulletin={setIsOpenBulletin}
          isOpenBulletin={isOpenBulletin}
          toggleModal={handleCreateBulletin}
          countryList={countryList}
          stateList={stateList}
          districtList={districtList}
          LOCATION={LOCATION}
          dispatch={dispatch}
          bulletinTypes={categoryFilter}
          indicatorList={indicatorList}
          countryData={countryData}
          mapIndex={0}
          isEditTitle={true}
        />
      )}
      <div className="loader-div">{Bulletin.isGetAllBulletinRequesting && <Loader />}</div>
      <Container fluid className=" spacing-online-bulletin news-feed-header border-0">
        <Row className="">
          <Col xs={12} className=" d-flex justify-content-between align-items-center">
            <h1 className="online-bulletin-header m-0">Online Bulletin</h1>{' '}
            <Button
              className="view-application-btn float-end"
              onClick={() => handleCreateBulletin()}
            >
              Create Bulletin
            </Button>
          </Col>
          <Col xs={6} className=" mt-3">
            {categoryFilter?.map(category => (
              <span
                className={
                  category.id === selectedCategory.id
                    ? 'online-bulletin-category-btn-selected online-bulletin-category-btn'
                    : 'online-bulletin-category-btn'
                }
                onClick={() => handleSelectCategory(category)}
                key={category.name}
              >
                {category.name}
              </span>
            ))}
          </Col>
          <Col xs={6} className=" mt-3">
            <select
              name="years"
              id="years-select"
              className="online-bulletin-year-selector"
              onChange={e => handleSelectYear(e)}
            >
              {yearsList?.map(year => (
                <option value={year} key={year}>
                  {year}
                </option>
              ))}
            </select>
          </Col>
        </Row>
        <Row id="scrollableDiv" className="online-bulletin-list-container my-3">
          {bulletinList?.length ? (
            <InfiniteScroll
              dataLength={pageNumber * 10 < pageCount ? pageNumber * 10 : ''}
              next={fetchNextTemplateData}
              scrollableTarget="scrollableDiv"
              hasMore={pageNumber * 10 < pageCount ? true : false}
              loader={
                Bulletin.isGetAllBulletinRequesting && (
                  <center>
                    <h4>{t('BULLET_IN.LOADING')} </h4>
                  </center>
                )
              }
              className="flex flex-wrap"
            >
              <div className="mt-3">
                <div className="online-bulletin-grid">
                  {bulletinList?.length
                    ? bulletinList?.map(data => {
                        return <OnlineBulletInList bulletinData={data} key={data?.id} />;
                      })
                    : null}
                </div>
              </div>
            </InfiniteScroll>
          ) : (
            _.isEmpty(bulletinList) &&
            !Bulletin.isGetAllBulletinRequesting && (
              <Alert color="warning" className="online-bulletin-alert">
                <h2 className="no-record-found-font-size">{t('BULLET_IN.NO_RECORDS_FOUND')}</h2>
              </Alert>
            )
          )}
        </Row>
      </Container>
      <Footer backgroundColor="bg-white" />
    </>
  );
}

export default OnlineBulletin;

import HOME_CONST from './homeConsts';

export const getBannersRequest = () => ({
  type: HOME_CONST.GET_BANNERS_REQUEST,
  payload: null,
});

export const getBannersSuccess = response => ({
  type: HOME_CONST.GET_BANNERS_SUCCESS,
  payload: response,
});

export const getBannersFailed = error => ({
  type: HOME_CONST.GET_BANNERS_FAILED,
  payload: error,
});

export const getParametersRequest = type => ({
  type: HOME_CONST.GET_PARAMETERS_REQUEST,
  payload: type,
});

export const getParametersSuccess = response => ({
  type: HOME_CONST.GET_PARAMETERS_SUCCESS,
  payload: response,
});

export const getParametersFailed = error => ({
  type: HOME_CONST.GET_PARAMETERS_FAILED,
  payload: error,
});

// EVENTS
export const getDmsEventsRequest = type => ({
  type: HOME_CONST.GET_DMS_EVENTS_REQUEST,
  payload: type,
});

export const getDmsEventsSuccess = response => ({
  type: HOME_CONST.GET_DMS_EVENTS_SUCCESS,
  payload: response,
});

export const getDmsEventsFailed = error => ({
  type: HOME_CONST.GET_DMS_EVENTS_FAILED,
  payload: error,
});

// STATISTICS
export const getDmsStatisticsRequest = () => ({
  type: HOME_CONST.GET_DMS_STATISTICS_REQUEST,
  payload: null,
});

export const getDmsStatisticsSuccess = response => ({
  type: HOME_CONST.GET_DMS_STATISTICS_SUCCESS,
  payload: response,
});

export const getDmsStatisticsFailed = error => ({
  type: HOME_CONST.GET_DMS_STATISTICS_FAILED,
  payload: error,
});

// Testimonials
export const getDmsTestimonialsRequest = () => ({
  type: HOME_CONST.GET_DMS_TESTIMONIALS_REQUEST,
  payload: null,
});

export const getDmsTestimonialsSuccess = response => ({
  type: HOME_CONST.GET_DMS_TESTIMONIALS_SUCCESS,
  payload: response,
});

export const getDmsTestimonialsFailed = error => ({
  type: HOME_CONST.GET_DMS_TESTIMONIALS_FAILED,
  payload: error,
});

// PARTNERS & DONORS
export const getDmsPartnersDonorsRequest = () => ({
  type: HOME_CONST.GET_DMS_PARTNERS_DONORS_REQUEST,
  payload: null,
});

export const getDmsPartnersDonorsSuccess = response => ({
  type: HOME_CONST.GET_DMS_PARTNERS_DONORS_SUCCESS,
  payload: response,
});

export const getDmsPartnersDonorsFailed = error => ({
  type: HOME_CONST.GET_DMS_PARTNERS_DONORS_FAILED,
  payload: error,
});

// NEWS FEED
export const getDmsNewsfeedRequest = data => ({
  type: HOME_CONST.GET_DMS_NEWSFEED_REQUEST,
  payload: data,
});

export const getDmsNewsfeedSuccess = response => ({
  type: HOME_CONST.GET_DMS_NEWSFEED_SUCCESS,
  payload: response,
});

export const getDmsNewsfeedFailed = error => ({
  type: HOME_CONST.GET_DMS_NEWSFEED_FAILED,
  payload: error,
});

// Pillars
export const getDmsPillarsRequest = data => ({
  type: HOME_CONST.GET_DMS_PILLARS_REQUEST,
  payload: data,
});

export const getDmsPillarsSuccess = response => ({
  type: HOME_CONST.GET_DMS_PILLARS_SUCCESS,
  payload: response,
});

export const getDmsPillarsFailed = error => ({
  type: HOME_CONST.GET_DMS_PILLARS_FAILED,
  payload: error,
});

// USING IT AS COMMON
export const addLandingPageRequest = data => ({
  type: HOME_CONST.ADD_LANDING_PAGE_REQUEST,
  payload: data,
});

export const addLandingPageSuccess = response => ({
  type: HOME_CONST.ADD_LANDING_PAGE_SUCCESS,
  payload: response,
});

export const addLandingPageFailed = error => ({
  type: HOME_CONST.ADD_LANDING_PAGE_FAILURE,
  payload: error,
});

// DELETE TESTIONNIALS and partners & donors
export const deleteLandingPageRequest = data => ({
  type: HOME_CONST.DELETE_LANDING_PAGE_REQUEST,
  payload: data,
});

export const deleteLandingPageSuccess = response => ({
  type: HOME_CONST.DELETE_LANDING_PAGE_SUCCESS,
  payload: response,
});

export const deleteLandingPageFailed = error => ({
  type: HOME_CONST.DELETE_LANDING_PAGE_FAILURE,
  payload: error,
});

// EDIT LANDING PAGE DETAILS
export const editLandingPageRequest = data => ({
  type: HOME_CONST.EDIT_LANDING_PAGE_REQUEST,
  payload: data,
});

export const editLandingPageSuccess = response => ({
  type: HOME_CONST.EDIT_LANDING_PAGE_SUCCESS,
  payload: response,
});

export const editLandingPageFailed = error => ({
  type: HOME_CONST.EDIT_LANDING_PAGE_FAILURE,
  payload: error,
});

// EDIT LANDING PAGE DETAILS
export const contactUsRequest = data => ({
  type: HOME_CONST.CONTACT_US_REQUEST,
  payload: data,
});

export const contactUsSuccess = response => ({
  type: HOME_CONST.CONTACT_US_SUCCESS,
  payload: response,
});

export const contactUsFailed = error => ({
  type: HOME_CONST.CONTACT_US_FAILURE,
  payload: error,
});

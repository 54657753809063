import React, { useEffect, useState } from 'react';
import { t } from 'react-multi-lang';
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';

import { ReactComponent as DeleteIcon } from '../../assets/Images/edit-icon.svg';
import { ReactComponent as UploadIcon } from '../../assets/Images/upload-image.svg';
import { showError } from '../../Components/Notifications';

const NewTestimonialModal = ({
  modal,
  setModal,
  setFormData,
  formData,
  onHandleTestimonials,
  isEditable,
}) => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [initialData, setInitialData] = useState({});
  const [errors, setErrors] = useState({});

  const toggleModal = () => {
    setModal(!modal);
    setFormData({
      name: '',
      role: '',
      description: '',
      profile_image: null,
    });
  };

  const handleChange = e => {
    const { name, value, files } = e.target;
    let updatedFormData = { ...formData };

    if (name === 'profile_image' && files[0]) {
      const file = files[0];
      const img = new Image();
      const objectUrl = URL.createObjectURL(file);

      img.onload = () => {
        const minWidth = 128;
        const minHeight = 128;
        const maxWidth = 512;
        const maxHeight = 512;

        if (
          img.width >= minWidth &&
          img.width <= maxWidth &&
          img.height >= minHeight &&
          img.height <= maxHeight
        ) {
          updatedFormData[name] = file;
          setImagePreview(objectUrl);
          setFormData(updatedFormData); // Set image in formData
          validateField(name, updatedFormData); // Validate the field again after image is set
        } else {
          showError(
            `Please upload an image with dimensions between ${minWidth}x${minHeight} and ${maxWidth}x${maxHeight} pixels.`,
          );
        }

        URL.revokeObjectURL(objectUrl);
      };

      img.onerror = () => {
        showError('Invalid image file. Please try again.');
      };

      img.src = objectUrl;
      e.target.value = null;
    } else {
      updatedFormData[name] = value;
      setFormData(updatedFormData);
      validateField(name, updatedFormData);
    }
  };

  const validateField = (name, data) => {
    let errorMessages = {};

    switch (name) {
      case 'description':
        if (data.description.length > 450) {
          errorMessages.description = 'Limit exceeded: Maximum 450 characters.';
        } else {
          errorMessages.description = '';
        }
        break;
      case 'name':
        if (data.name.length > 100) {
          errorMessages.name = 'Limit exceeded: Maximum 100 characters.';
        } else {
          errorMessages.name = '';
        }
        break;
      case 'role':
        if (data.role.length > 120) {
          errorMessages.role = 'Limit exceeded: Maximum 120 characters.';
        } else {
          errorMessages.role = '';
        }
        break;

      default:
        break;
    }

    setErrors(prevErrors => ({
      ...prevErrors,
      ...errorMessages,
    }));
  };

  const handleImageRemove = () => {
    setFormData({
      ...formData,
      profile_image: null,
    });
    setImagePreview(null); // Remove the image preview
  };

  useEffect(() => {
    if (isEditable) {
      setInitialData(formData); // Store the original data when editing
    }
  }, []);

  useEffect(() => {
    // Check if all required fields are filled and no errors are present
    const isValid =
      formData.name.trim() !== '' &&
      formData.description.trim() !== '' &&
      formData.role.trim() !== '' &&
      formData.profile_image && // Ensure an image is uploaded
      !Object.values(errors).some(error => error !== ''); // Ensure no errors

    setIsFormValid(isValid);
  }, [formData, errors]);

  const getChangedFields = () => {
    const changedFields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== initialData[key]) {
        changedFields[key] = formData[key];
      }
    });

    return changedFields;
  };

  const handleSubmit = e => {
    e.preventDefault();
    const dataToSubmit = isEditable ? getChangedFields() : formData;
    onHandleTestimonials(dataToSubmit);
    toggleModal();
    setInitialData({});
  };

  return (
    <div className="client-modal">
      <Modal
        isOpen={modal}
        toggle={toggleModal}
        className="dms-landing-modal"
        backdrop="static"
        keyboard={false}
      >
        <Form onSubmit={handleSubmit}>
          <ModalHeader
            className="client-detail-header justify-content-between"
            toggle={toggleModal}
          >
            {isEditable
              ? t('LANDING_PAGE.EDIT_TESTIMONIAL')
              : t('LANDING_PAGE.ADD_NEW_TESTIMONIAL')}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col xs={12}>
                <FormGroup>
                  <Label for="name" className="dms-pillar-label mb-1">
                    {t('LANDING_PAGE.TESTIMONIAL_GIVEN')}
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    className="dms-pillar-select"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Write your text here..."
                    required
                    invalid={!!errors.name}
                  />
                  <FormFeedback tooltip>{errors.name}</FormFeedback>
                </FormGroup>
              </Col>

              <Col xs={12}>
                <FormGroup>
                  <Label for="role" className="dms-pillar-label  mb-1">
                    {t('LANDING_PAGE.DESIGNATION')}
                  </Label>
                  <Input
                    type="text"
                    name="role"
                    id="role"
                    className="dms-pillar-select"
                    value={formData.role}
                    onChange={handleChange}
                    placeholder="Write your text here..."
                    required
                    invalid={!!errors.role}
                  />
                  <FormFeedback tooltip>{errors.role}</FormFeedback>
                </FormGroup>
              </Col>

              <Col xs={12}>
                <FormGroup>
                  <Label for="description" className="dms-pillar-label  mb-1">
                    {t('LANDING_PAGE.TESTIMONIAL')}
                  </Label>
                  <Input
                    type="textarea"
                    name="description"
                    id="description"
                    className="dms-pillar-select"
                    value={formData.description}
                    onChange={handleChange}
                    placeholder="Write your text here...(Max: 450 Characters)"
                    required
                    invalid={!!errors.description}
                  />
                  <FormFeedback tooltip>{errors.description}</FormFeedback>
                </FormGroup>
              </Col>

              <Col xs={12}>
                <FormGroup>
                  <Label for="profile_image" className="dms-pillar-label  mb-1">
                    {t('LANDING_PAGE.UPLOAD_PROFILE_IMAGE')}
                  </Label>
                  <div className="custom-input-file">
                    {imagePreview || formData.profile_image ? (
                      <div className="image-preview-container">
                        <img
                          src={imagePreview || formData.profile_image}
                          alt="Profile Preview"
                          className="image-preview"
                        />
                        <div className="image-overlay"></div>
                        <button
                          type="button"
                          className="dms-delete-file"
                          onClick={handleImageRemove}
                        >
                          <DeleteIcon width={20} height={20} />
                        </button>
                      </div>
                    ) : (
                      <Label htmlFor="profile_image" className="custom-input-file-label">
                        <UploadIcon width={36} height={36} />
                        <p>{t('LANDING_PAGE.UPLOAD_IMAGE')}</p>
                        <span>Upload an image of max 512x512 dimensions is recommended</span>
                      </Label>
                    )}
                    <Input
                      type="file"
                      name="profile_image"
                      id="profile_image"
                      accept=".jpg,.jpeg,.png"
                      onChange={handleChange}
                      hidden
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="modal-viewdeatil-footer">
            <button onClick={() => toggleModal()} className="reject-button">
              {t('LANDING_PAGE.CANCEL')}
            </button>{' '}
            <button onClick={handleSubmit} disabled={!isFormValid} className="approve-button">
              {isEditable ? t('LANDING_PAGE.SAVE') : t('LANDING_PAGE.ADD')}
            </button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default NewTestimonialModal;

export const MANAGE_CROPS_CONST = {
    MANAGE_CROPS_REQUEST: 'MANAGE_CROPS_REQUEST',
    MANAGE_CROPS_SUCCESS: 'MANAGE_CROPS_SUCCESS',
    MANAGE_CROPS_FAILED: 'MANAGE_CROPS_FAILED',
  };

  export const MANAGE_CROPS_LIST_BY_ID_CONST = {
    MANAGE_CROPS_LIST_BY_ID_REQUEST: 'MANAGE_CROPS_LIST_BY_ID_REQUEST',
    MANAGE_CROPS_LIST_BY_ID_SUCCESS: 'MANAGE_CROPS_LIST_BY_ID_SUCCESS',
    MANAGE_CROPS_LIST_BY_ID_FAILED: 'MANAGE_CROPS_LIST_BY_ID_FAILED',
  };

export const MANAGE_CROPS_ADD_CONST = {
    MANAGE_CROPS_ADD_REQUEST: 'MANAGE_CROPS_ADD_REQUEST',
    MANAGE_CROPS_ADD_SUCCESS: 'MANAGE_CROPS_ADD_SUCCESS',
    MANAGE_CROPS_ADD_FAILED: 'MANAGE_CROPS_ADD_FAILED',
  };

export const MANAGE_CROPS_DELETE_CONST = {
    MANAGE_CROPS_DELETE_REQUEST: 'MANAGE_CROPS_DELETE_REQUEST',
    MANAGE_CROPS_DELETE_SUCCESS: 'MANAGE_CROPS_DELETE_SUCCESS',
    MANAGE_CROPS_DELETE_FAILED: 'MANAGE_CROPS_DELETE_FAILED',
  };

  
export const MANAGE_CROPS_UPDATE_CONST = {
  MANAGE_CROPS_UPDATE_REQUEST: 'MANAGE_CROPS_UPDATE_REQUEST',
  MANAGE_CROPS_UPDATE_SUCCESS: 'MANAGE_CROPS_UPDATE_SUCCESS',
  MANAGE_CROPS_UPDATE_FAILED: 'MANAGE_CROPS_UPDATE_FAILED',
};


export const MANAGE_CROPS_SEASONS_CONST = {
  MANAGE_CROPS_SEASONS_REQUEST: 'MANAGE_CROPS_SEASONS_REQUEST',
  MANAGE_CROPS_SEASONS_SUCCESS: 'MANAGE_CROPS_SEASONS_SUCCESS',
  MANAGE_CROPS_SEASONS_FAILED: 'MANAGE_CROPS_SEASONS_FAILED',
};


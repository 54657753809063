import React, { useEffect } from 'react';
import {
  LayerGroup,
  LayersControl,
  MapContainer,
  ScaleControl,
  TileLayer,
  useMapEvents,
  ZoomControl,
} from 'react-leaflet';

import myConstClass from '../../../Constants';
import CustomLandingPageLegend from '../../new-landing-page/CountrySpecificTool/CustomLandingPageLegend';
import DiscreteLandingPageLegend from '../../new-landing-page/CountrySpecificTool/DiscreteLandingPageLegend';
import {
  getBulletinDateFormatwithOutSubScript,
  getBulletinMonthFormat,
} from '../../../Components/helpers';

function RenderMapContainer({
  indicatorData,
  indicatorIndex,
  cordinateAndZoom,
  setCordinateAndZoom,
  setMapInstances,
  layerRef,
  indicatorKey,
  mapInstances,
  bulletinData,
}) {
  const handleUpdateMapSettings = (center, zoom) => {
    setCordinateAndZoom({ center, zoom });
  };

  function ChangeView({ center, zoom, updateMapSettings }) {
    const map = useMapEvents({
      zoomend: () => {
        updateMapSettings(map.getCenter(), map.getZoom());
      },
      dragend: () => {
        updateMapSettings(map.getCenter(), map.getZoom());
      },
    });

    useEffect(() => {
      map.setView(center, zoom);
    }, [center, zoom, map]);

    return null;
  }

  const setMapReference = (mapInstance, keyIndex, index) => {
    setMapInstances(prev => ({
      ...prev,
      [`mapInstance${keyIndex}${index}`]: mapInstance,
    }));
  };

  return (
    <div className="map-border">
      <div className="mapcontainerlayout">
        <MapContainer
          center={cordinateAndZoom.center}
          zoom={cordinateAndZoom.zoom}
          id={`leaflet-map${indicatorKey}-${indicatorIndex}`}
          minZoom={6}
          maxZoom={10}
          className="bulletin-map-container"
          zoomControl={false}
          scrollWheelZoom
          whenReady={mapInstance =>
            setMapReference(mapInstance.target, indicatorKey, indicatorIndex)
          }
        >
          <ChangeView
            center={cordinateAndZoom.center}
            zoom={cordinateAndZoom.zoom}
            updateMapSettings={handleUpdateMapSettings}
          />

          <ScaleControl position="bottomright" />
          <ZoomControl position="topright" className="map-zoom-control" />

          <LayersControl>
            <LayersControl.BaseLayer checked name="Street">
              <TileLayer
                options={{ tileSize: 256 }}
                attribution={myConstClass.TITLE_LAYER_ATTRIBUTE}
                url={myConstClass.TITLE_LAYER_URL}
              />
            </LayersControl.BaseLayer>
          </LayersControl>
          <LayerGroup
            ref={layerRef.current[`${indicatorKey}-${indicatorIndex}`]}
            style={{ zIndex: 10001 }}
          >
            <TileLayer
              attribution='Weather from <a href="https://openweathermap.org/" alt="World Map and worldwide Weather Forecast online">OpenWeatherMap</a>'
              url=""
            />
          </LayerGroup>
          {mapInstances[`legendObj${indicatorKey}${indicatorIndex}`]?.descret_legend ? (
            <DiscreteLandingPageLegend
              map={mapInstances[`mapInstance${indicatorKey}${indicatorIndex}`]}
              add={mapInstances[`legendObj${indicatorKey}${indicatorIndex}`]?.add}
              layer={mapInstances[`legendObj${indicatorKey}${indicatorIndex}`]}
              position="bottomleft"
            />
          ) : (
            <CustomLandingPageLegend
              map={mapInstances[`mapInstance${indicatorKey}${indicatorIndex}`]}
              add={mapInstances[`legendObj${indicatorKey}${indicatorIndex}`]?.add}
              layer={mapInstances[`legendObj${indicatorKey}${indicatorIndex}`]}
              position="bottomleft"
            />
          )}
        </MapContainer>
      </div>
      <div className=" p-3">
        <p className="indicator-details">Indicator Name : {indicatorData?.name}</p>
        <p className="indicator-details">
          <span>Region: </span>
          <br />
          <span className=" mx-2">
            {bulletinData?.country?.country_name}
            {bulletinData?.state ? `${bulletinData?.state?.name}, ` : ''}
            {bulletinData?.district ? `${bulletinData?.district?.name}, ` : ''}
            {`(${
              bulletinData?.bulletin_type?.value === 'Monthly Bulletin'
                ? getBulletinMonthFormat(bulletinData.startDate)
                : `${getBulletinDateFormatwithOutSubScript(bulletinData?.startDate)} - 
           ${getBulletinDateFormatwithOutSubScript(bulletinData?.endDate)}`
            })`}
          </span>
        </p>
      </div>
    </div>
  );
}

export default RenderMapContainer;

import React from 'react';
import ReactTooltip from 'react-tooltip';
import TooltipImageIcon from '../../../assets/Images/tooltip.svg';

const BreadCrumbsData = (props) => {
  return (
    <>
      <p className="contingency-plan-table-title">
        {props.breadCrumbsData.system}{' '}
        {props?.breadCrumbsData?.droughtStage && '|' + props?.breadCrumbsData?.droughtStage}{' '}
        {props?.breadCrumbsData?.timePeriod && '|' + props.breadCrumbsData.timePeriod}
        <span data-tip data-for="registerTip" className="tool-tip-icon">
          <img src={TooltipImageIcon} alt="tool-tip" />
        </span>
      </p>
      <ReactTooltip
        id="registerTip"
        place="top"
        effect="solid"
        className="contingency-measures-tooltip"
      >
        <p className="title"> Early season drought</p>
        <p className="content"></p>(Normal onset, followed by 15-20 days dry spell after sowing
        leading to poor germination/crop stand etc.)
      </ReactTooltip>
    </>
  );
};
export default BreadCrumbsData;

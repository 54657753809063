import React from 'react';
import { Col, Row } from 'reactstrap';
import Header from './Header';
import { useDispatch } from 'react-redux';
import ContingencyMeasuresTable from './Table';
import { IoMdDownload } from 'react-icons/io';
import { useTranslation } from 'react-multi-lang';
import Loader from '../../../Components/common/loader';
import BreadCrumbsData from './BreadCrumbsData';
import EditModal from '../EditModal';
import { contingencyPlanMeasuresEditDataRequest } from '../../../redux/contingencyPlan/contingencyPlanAction';
import {isSuperAdmin} from "../../../Components/common/utils"

const Index = props => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const onHandleSaveEdit = () => {
    const changedValues = Object.keys(props.editValues).reduce((acc, key) => {
      if (props.editValues[key] !== props?.selectedRow?.values?.[key]) {
        acc[key] = props.editValues[key];
      }
      return acc;
    }, {});
    // Implement save functionality here
    props?.setContingencyMeasuresTable([]);
    dispatch(
      contingencyPlanMeasuresEditDataRequest({
        id: props?.selectedRow?.original?.id,
        data: changedValues,
      }),
    );

    props.setSelectedRow(null);
  };

  return (
    <>
      <Header
        handleContingencyMeasuresCloseModal={props.handleContingencyMeasuresCloseModal}
        cropTypeData={props.cropTypeData}
        handleCropTypeChange={props.handleCropTypeChange}
        selectedCrop={props.selectedCrop}
        isCropTypeLoading={props.isCropTypeLoading}
      ></Header>
      <Row>
        <Col sm={3} xs={3} lg={3} md={3}>
          <h5 className="contingency-plan-table-title">
            {t('CONTINGENCY_PLAN.CONTINGENCY_MEASURES_TABLE_TITLE')}
          </h5>
        </Col>
        <Col sm={8} xs={9} lg={6} md={6}>
          <BreadCrumbsData breadCrumbsData={props.breadCrumbsData} />
        </Col>
     
      </Row>
      {props.isContingencyPlanMeasuresDataRequesting && <Loader />}
      {props.contingencyMeasuresView === 'list' ? (
        // <List />
        <p>list</p>
      ) : props?.contingencyMeasuresTable?.rowData?.length ? (
        <ContingencyMeasuresTable
          contingencyMeasuresTable={props.contingencyMeasuresTable}
          formData={props.formData}
          setContingencyMeasuresTable={props.setContingencyMeasuresTable}
          setSelectedRow={props.setSelectedRow}
          setEditValues={props.setEditValues}
          selectedRow={props.selectedRow}
          editValues={props.editValues}
          setColumnHeader={props.setColumnHeader}
          columnHeader={props.columnHeader}
          onHandleSaveEdit={onHandleSaveEdit}
          userPermissions={props?.userPermissions}
        ></ContingencyMeasuresTable>
      ) : (
        <div className="contingency-plan-section">
          <p>{t('NEWS_FEED.NO_RECORDS_FOUND')}</p>
        </div>
      )}

      {props.selectedRow ? (
        <>
          <div>
            <EditModal
              setSelectedRow={props.setSelectedRow}
              setEditValues={props.setEditValues}
              selectedRow={props.selectedRow}
              editValues={props.editValues}
              setColumnHeader={props.setColumnHeader}
              columnHeader={props.columnHeader}
              onHandleSaveEdit={onHandleSaveEdit}
            />
          </div>
        </>
      ) : (
        ''
      )}
    </>
  );
};
export default Index;

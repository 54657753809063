import {
  CONTINGENCY_PLAN_RISK_CONST,
  CONTINGENCY_MEASURES_DATA_CONST,
  CONTINGENCY_PLAN_MONSOON_CONST,
  CONTINGENCY_MEASURES_CROP_TYPE_CONST,
  SUB_CATEGORY_CONST,
  DISTRICT_PROFILE_CONST,
  STAGE_CONST,
  CATEGORY_CONST,
  SYSTEM_CONST,
  DROUGHT_INDICES_CONST,
  STRATEGY_CONST,
  DROUGHT_STAGES_TIME_PERIOD_CONST,
  CONTINGENCY_DISTRICT_GRAPH_CONST,
  CONTINGENCY_PLAN_EDIT_DISTRICT_PROFILE,
  CONTINGENCY_MEASURES_EDIT_DATA_CONST,
  CONTINGENCY_PLAN_EDIT_LOGS
} from './contingencyPlanConsts';

export const contingencyPlanRiskRequest = (data) => ({
  type: CONTINGENCY_PLAN_RISK_CONST.CONTINGENCY_PLAN_RISK_REQUEST,
  payload: data,
});

export const contingencyPlanRiskSuccess = (response) => ({
  type: CONTINGENCY_PLAN_RISK_CONST.CONTINGENCY_PLAN_RISK_SUCCESS,
  payload: response,
});

export const contingencyPlanRiskFailed = (error) => ({
  type: CONTINGENCY_PLAN_RISK_CONST.CONTINGENCY_PLAN_RISK_FAILED,
  payload: error,
});

export const contingencyPlanMonsoonRequest = (data) => ({
  type: CONTINGENCY_PLAN_MONSOON_CONST.CONTINGENCY_PLAN_MONSOON_REQUEST,
  payload: data,
});

export const contingencyPlanMonsoonSuccess = (response) => ({
  type: CONTINGENCY_PLAN_MONSOON_CONST.CONTINGENCY_PLAN_MONSOON_SUCCESS,
  payload: response,
});

export const contingencyPlanMonsoonFailed = (error) => ({
  type: CONTINGENCY_PLAN_MONSOON_CONST.CONTINGENCY_PLAN_MONSOON_FAILED,
  payload: error,
});

export const contingencyPlanStageRequest = (data) => ({
  type: STAGE_CONST.STAGE_REQUEST,
  payload: data,
});

export const contingencyPlanStageSuccess = (response) => ({
  type: STAGE_CONST.STAGE_SUCCESS,
  payload: response,
});

export const contingencyPlanStageFailed = (error) => ({
  type: STAGE_CONST.STAGE_FAILED,
  payload: error,
});

export const contingencyPlanSystemRequest = (data) => ({
  type: SYSTEM_CONST.SYSTEM_REQUEST,
  payload: data,
});

export const contingencyPlanSystemSuccess = (response) => ({
  type: SYSTEM_CONST.SYSTEM_SUCCESS,
  payload: response,
});

export const contingencyPlanSystemFailed = (error) => ({
  type: SYSTEM_CONST.SYSTEM_FAILED,
  payload: error,
});

export const contingencyPlanDroughtIndicesRequest = (data) => ({
  type: DROUGHT_INDICES_CONST.DROUGHT_INDICES_REQUEST,
  payload: data,
});

export const contingencyPlanDroughtIndicesSuccess = (response) => ({
  type: DROUGHT_INDICES_CONST.DROUGHT_INDICES_SUCCESS,
  payload: response,
});

export const contingencyPlanDroughtIndicesFailed = (error) => ({
  type: DROUGHT_INDICES_CONST.DROUGHT_INDICES_FAILED,
  payload: error,
});

export const contingencyPlanStrategyRequest = (data) => ({
  type: STRATEGY_CONST.STRATEGY_REQUEST,
  payload: data,
});

export const contingencyPlanStrategySuccess = (response) => ({
  type: STRATEGY_CONST.STRATEGY_SUCCESS,
  payload: response,
});

export const contingencyPlanStrategyFailed = (error) => ({
  type: STRATEGY_CONST.STRATEGY_FAILED,
  payload: error,
});

export const contingencyPlanDroughtStageTimePeriodRequest = (data) => ({
  type: DROUGHT_STAGES_TIME_PERIOD_CONST.DROUGHT_STAGES_TIME_PERIOD_REQUEST,
  payload: data,
});

export const contingencyPlanDroughtStageTimePeriodSuccess = (response) => ({
  type: DROUGHT_STAGES_TIME_PERIOD_CONST.DROUGHT_STAGES_TIME_PERIOD_SUCCESS,
  payload: response,
});

export const contingencyPlanDroughtStageTimePeriodFailed = (error) => ({
  type: DROUGHT_STAGES_TIME_PERIOD_CONST.DROUGHT_STAGES_TIME_PERIOD_FAILED,
  payload: error,
});

export const contingencyPlanCateoryRequest = (data) => ({
  type: CATEGORY_CONST.CATEGORY_REQUEST,
  payload: data,
});

export const contingencyPlanCategorySuccess = (response) => ({
  type: CATEGORY_CONST.CATEGORY_SUCCESS,
  payload: response,
});

export const contingencyPlanCategoryFailed = (error) => ({
  type: CATEGORY_CONST.CATEGORY_FAILED,
  payload: error,
});

export const contingencyPlanSubCateoryRequest = (data) => ({
  type: SUB_CATEGORY_CONST.SUB_CATEGORY_REQUEST,
  payload: data,
});

export const contingencyPlanSubCategorySuccess = (response) => ({
  type: SUB_CATEGORY_CONST.SUB_CATEGORY_SUCCESS,
  payload: response,
});

export const contingencyPlanSubCategoryFailed = (error) => ({
  type: SUB_CATEGORY_CONST.SUB_CATEGORY_FAILED,
  payload: error,
});

export const contingencyPlanDistrictProfileRequest = (data) => ({
  type: DISTRICT_PROFILE_CONST.DISTRICT_PROFILE_REQUEST,
  payload: data,
});

export const contingencyPlanDistrictProfileSuccess = (response) => ({
  type: DISTRICT_PROFILE_CONST.DISTRICT_PROFILE_SUCCESS,
  payload: response,
});

export const contingencyPlanDistrictProfileFailed = (error) => ({
  type: DISTRICT_PROFILE_CONST.DISTRICT_PROFILE_FAILED,
  payload: error,
});

export const contingencyPlanCropTypeRequest = (data) => ({
  type: CONTINGENCY_MEASURES_CROP_TYPE_CONST.CONTINGENCY_MEASURES_CROP_TYPE_REQUEST,
  payload: data,
});

export const contingencyPlanCropTypeSuccess = (response) => ({
  type: CONTINGENCY_MEASURES_CROP_TYPE_CONST.CONTINGENCY_MEASURES_CROP_TYPE_SUCCESS,
  payload: response,
});

export const contingencyPlanCropTypeFailed = (error) => ({
  type: CONTINGENCY_MEASURES_CROP_TYPE_CONST.CONTINGENCY_MEASURES_CROP_TYPE_FAILED,
  payload: error,
});

export const contingencyPlanMeasuresDataRequest = (data) => ({
  type: CONTINGENCY_MEASURES_DATA_CONST.CONTINGENCY_MEASURES_DATA_REQUEST,
  payload: data,
});

export const contingencyPlanMeasuresDataSuccess = (response) => ({
  type: CONTINGENCY_MEASURES_DATA_CONST.CONTINGENCY_MEASURES_DATA_SUCCESS,
  payload: response,
});

export const contingencyPlanMeasuresDataFailed = (error) => ({
  type: CONTINGENCY_MEASURES_DATA_CONST.CONTINGENCY_MEASURES_DATA_FAILED,
  payload: error,
});

// EDIT MESURES DATA 
export const contingencyPlanMeasuresEditDataRequest = (data) => ({
  type: CONTINGENCY_MEASURES_EDIT_DATA_CONST.CONTINGENCY_MEASURES_EDIT_DATA_REQUEST,
  payload: data,
});

export const contingencyPlanMeasuresEditDataSuccess = (response) => ({
  type: CONTINGENCY_MEASURES_EDIT_DATA_CONST.CONTINGENCY_MEASURES_EDIT_DATA_SUCCESS,
  payload: response,
});

export const contingencyPlanMeasuresEditDataFailed = (error) => ({
  type: CONTINGENCY_MEASURES_EDIT_DATA_CONST.CONTINGENCY_MEASURES_EDIT_DATA_FAILED,
  payload: error,
});


export const contingencyDistrictGraphRequest = (id) => ({
  type: CONTINGENCY_DISTRICT_GRAPH_CONST.CONTINGENCY_DISTRICT_GRAPH_REQUEST,
  payload: id
})

export const contingencyDistrictGraphSuccess = (response) => ({
  type: CONTINGENCY_DISTRICT_GRAPH_CONST.CONTINGENCY_DISTRICT_GRAPH_SUCCESS,
  payload: response
})

export const contingencyDistrictGraphFailed = (error) => ({
  type: CONTINGENCY_DISTRICT_GRAPH_CONST.CONTINGENCY_DISTRICT_GRAPH_FAILED,
  payload: error
})


// EDIT CONTIGENCY PLAN 
export const contingencyPlanDPEditRequest = (id) => ({
  type: CONTINGENCY_PLAN_EDIT_DISTRICT_PROFILE.CONTINGENCY_PLAN_DP_EDIT_REQUEST,
  payload: id
})

export const contingencyPlanDPEditSuccess = (response) => ({
  type: CONTINGENCY_PLAN_EDIT_DISTRICT_PROFILE.CONTINGENCY_PLAN_DP_EDIT_SUCCESS,
  payload: response
})

export const contingencyPlanDPEditFailed = (error) => ({
  type: CONTINGENCY_PLAN_EDIT_DISTRICT_PROFILE.CONTINGENCY_PLAN_DP_EDIT_FAILED,
  payload: error
})



// LOGS CONTIGENCY PLAN 
export const contingencyPlanLogsRequest = (id) => ({
  type: CONTINGENCY_PLAN_EDIT_LOGS.CONTINGENCY_PLAN_LOGS_REQUEST,
  payload: id
})

export const contingencyPlanLogsSuccess = (response) => ({
  type: CONTINGENCY_PLAN_EDIT_LOGS.CONTINGENCY_PLAN_LOGS_SUCCESS,
  payload: response
})

export const contingencyPlanLogsFailed = (error) => ({
  type: CONTINGENCY_PLAN_EDIT_LOGS.CONTINGENCY_PLAN_LOGS_FAILED,
  payload: error
})

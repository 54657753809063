import moment from 'moment';
import React from 'react';
import { Card, CardImg, CardSubtitle, CardTitle } from 'reactstrap';

function OnlineBulletInList({ bulletinData }) {
  const handlePreviewPdf = (e, data) => {
    e?.stopPropagation();
    if (data?.template_id) {
      window.open(data?.content_file, '_blank');
    } else {
      window.open(data?.bulletin_file, '_blank');
    }
  };

  return (
    <Card
      className="online-bulletin-card"
      key={bulletinData.id}
      onClick={e => {
        handlePreviewPdf(e, bulletinData);
      }}
    >
      <CardImg src={bulletinData?.content_file || ''} top className="online-bulletin-card-image" />
      <div className="online-bulletin-details">
        <CardSubtitle className="mb-1 online-bulletin-cateogory-text">
          {bulletinData?.category_name}
        </CardSubtitle>
        <CardTitle className=" mb-1 online-bulletin-card-header">
          {bulletinData?.topic?.length > 29
            ? bulletinData?.topic?.substring(0, 29) + '...'
            : bulletinData?.topic}
        </CardTitle>
        {bulletinData?.is_publish ? (
          <CardSubtitle className="mb-1 online-bulletin-cateogory-text">
            Published on: {moment(bulletinData.updated_date).format('DD-MM-YYYY')}
          </CardSubtitle>
        ) : null}
      </div>{' '}
    </Card>
  );
}

export default OnlineBulletInList;

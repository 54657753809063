import {
  NEWS_FEED_CATEGORIES_CONST,
  NEWS_FEED_SUB_CATEGORIES_CONST,
  NEWS_FEED_ADD_CONST,
  GET_ALL_NEWS_FEED_CONST,
  NEWS_FEED_DELETE_CONST,
  GET_BY_ID_NEWS_FEED_CONST,
  NEWS_FEED_UPDATE_CONST,
  NEWS_FEED_SUBSCRIBE_CONST,
  NEWS_FEED_CHANGE_STATUS_CONST
} from './newsFeedConsts';

const NewsFeed = (state = {}, action) => {
  switch (action.type) {
    case NEWS_FEED_CATEGORIES_CONST.NEWS_FEED_CATEGORIES_REQUEST:
      return {
        ...state,
        isNewsFeedCategoriesRequesting: true,
        newsFeedCategoriesListData: false,
        newsFeedCategoriesListDataError: false,
        newsFeedCategoriesListDataNetworkError: false,
      };
    case NEWS_FEED_CATEGORIES_CONST.NEWS_FEED_CATEGORIES_SUCCESS:
      return {
        ...state,
        isNewsFeedCategoriesRequesting: false,
        newsFeedCategoriesListData: action.payload.response,
        newsFeedCategoriesListDataError: false,
        newsFeedCategoriesListDataNetworkError: false,
      };
    case NEWS_FEED_CATEGORIES_CONST.NEWS_FEED_CATEGORIES_FAILED:
      return {
        ...state,
        isNewsFeedCategoriesRequesting: false,
        newsFeedCategoriesListData: false,
        newsFeedCategoriesListDataError: action.payload.error ? false : action.payload.response,
        newsFeedCategoriesListDataNetworkError: action.payload.error ? action.payload.error : false,
      };

    case NEWS_FEED_SUB_CATEGORIES_CONST.NEWS_FEED_SUB_CATEGORIES_REQUEST:
      return {
        ...state,
        isNewsFeedSubCategoriesRequesting: true,
        newsFeedSubCategoriesListData: false,
        newsFeedSubCategoriesListDataError: false,
        newsFeedSubCategoriesListDataNetworkError: false,
      };
    case NEWS_FEED_SUB_CATEGORIES_CONST.NEWS_FEED_SUB_CATEGORIES_SUCCESS:
      return {
        ...state,
        isNewsFeedSubCategoriesRequesting: false,
        newsFeedSubCategoriesListData: action.payload.response,
        newsFeedSubCategoriesListDataError: false,
        newsFeedSubCategoriesListDataNetworkError: false,
      };
    case NEWS_FEED_SUB_CATEGORIES_CONST.NEWS_FEED_SUB_CATEGORIES_FAILED:
      return {
        ...state,
        isNewsFeedSubCategoriesRequesting: false,
        newsFeedSubCategoriesListData: false,
        newsFeedSubCategoriesListDataError: action.payload.error ? false : action.payload.response,
        newsFeedSubCategoriesListDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };

    case NEWS_FEED_ADD_CONST.NEWS_FEED_ADD_REQUEST:
      return {
        ...state,
        isNewsFeedAddRequesting: true,
        newsFeedAddData: false,
        newsFeedAddDataError: false,
        newsFeedAddDataNetworkError: false,
      };
    case NEWS_FEED_ADD_CONST.NEWS_FEED_ADD_SUCCESS:
      return {
        ...state,
        isNewsFeedAddRequesting: false,
        newsFeedAddData: action.payload.response,
        newsFeedAddDataError: false,
        newsFeedAddDataNetworkError: false,
      };
    case NEWS_FEED_ADD_CONST.NEWS_FEED_ADD_FAILED:
      return {
        ...state,
        isNewsFeedAddRequesting: false,
        newsFeedAddData: false,
        newsFeedAddDataError: action.payload.error ? false : action.payload.response,
        newsFeedAddDataNetworkError: action.payload.error ? action.payload.error : false,
      };

    case GET_ALL_NEWS_FEED_CONST.GET_ALL_NEWS_FEED_REQUEST:
      return {
        ...state,
        isGetAllNewsFeedRequesting: true,
        getAllNewsFeedData: false,
        getAllNewsFeedDataError: false,
        getAllNewsFeedDataNetworkError: false,
      };
    case GET_ALL_NEWS_FEED_CONST.GET_ALL_NEWS_FEED_SUCCESS:
      return {
        ...state,
        isGetAllNewsFeedRequesting: false,
        getAllNewsFeedData: action.payload.response,
        getAllNewsFeedDataError: false,
        getAllNewsFeedDataNetworkError: false,
      };
    case GET_ALL_NEWS_FEED_CONST.GET_ALL_NEWS_FEED_FAILED:
      return {
        ...state,
        isGetAllNewsFeedRequesting: false,
        getAllNewsFeedData: false,
        getAllNewsFeedDataError: action.payload.error ? false : action.payload.response,
        getAllNewsFeedDataNetworkError: action.payload.error ? action.payload.error : false,
      };

    case NEWS_FEED_DELETE_CONST.NEWS_FEED_DELETE_REQUEST:
      return {
        ...state,
        isNewsFeedDeleteRequesting: true,
        newsFeedDeleteData: false,
        newsFeedDeleteDataError: false,
        newsFeedDeleteDataNetworkError: false,
      };
    case NEWS_FEED_DELETE_CONST.NEWS_FEED_DELETE_SUCCESS:
      return {
        ...state,
        isNewsFeedDeleteRequesting: false,
        newsFeedDeleteData: action.payload.response,
        newsFeedDeleteDataError: false,
        newsFeedDeleteDataNetworkError: false,
      };
    case NEWS_FEED_DELETE_CONST.NEWS_FEED_DELETE_FAILED:
      return {
        ...state,
        isNewsFeedDeleteRequesting: false,
        newsFeedDeleteData: false,
        newsFeedDeleteDataError: action.payload.error ? false : action.payload.response,
        newsFeedDeleteDataNetworkError: action.payload.error ? action.payload.error : false,
      };

    case GET_BY_ID_NEWS_FEED_CONST.GET_BY_ID_NEWS_FEED_REQUEST:
      return {
        ...state,
        isGetByIdNewsFeedRequesting: true,
        getByIdNewsFeedData: false,
        getByIdNewsFeedDataError: false,
        getByIdNewsFeedDataNetworkError: false,
      };
    case GET_BY_ID_NEWS_FEED_CONST.GET_BY_ID_NEWS_FEED_SUCCESS:
      return {
        ...state,
        isGetByIdNewsFeedRequesting: false,
        getByIdNewsFeedData: action.payload.response,
        getByIdNewsFeedDataError: false,
        getByIdNewsFeedDataNetworkError: false,
      };
    case GET_BY_ID_NEWS_FEED_CONST.GET_BY_ID_NEWS_FEED_FAILED:
      return {
        ...state,
        isGetByIdNewsFeedRequesting: false,
        getByIdNewsFeedData: false,
        getByIdNewsFeedDataError: action.payload.error ? false : action.payload.response,
        getByIdNewsFeedDataNetworkError: action.payload.error ? action.payload.error : false,
      };

    case NEWS_FEED_UPDATE_CONST.NEWS_FEED_UPDATE_REQUEST:
      return {
        ...state,
        isNewsFeedUpdateRequesting: true,
        newsFeedUpdateData: false,
        newsFeedUpdateDataError: false,
        newsFeedUpdateDataNetworkError: false,
      };
    case NEWS_FEED_UPDATE_CONST.NEWS_FEED_UPDATE_SUCCESS:
      return {
        ...state,
        isNewsFeedUpdateRequesting: false,
        newsFeedUpdateData: action.payload.response,
        newsFeedUpdateDataError: false,
        newsFeedUpdateDataNetworkError: false,
      };
    case NEWS_FEED_UPDATE_CONST.NEWS_FEED_UPDATE_FAILED:
      return {
        ...state,
        isNewsFeedUpdateRequesting: false,
        newsFeedUpdateData: false,
        newsFeedUpdateDataError: action.payload.error ? false : action.payload.response,
        newsFeedUpdateDataNetworkError: action.payload.error ? action.payload.error : false,
      };

    case NEWS_FEED_SUBSCRIBE_CONST.NEWS_FEED_SUBSCRIBE_REQUEST:
      return {
        ...state,
        isNewsFeedSubscribeRequesting: true,
        newsFeedSubscribeData: false,
        newsFeedSubscribeDataError: false,
        newsFeedSubscribeDataNetworkError: false,
      };
    case NEWS_FEED_SUBSCRIBE_CONST.NEWS_FEED_SUBSCRIBE_SUCCESS:
      return {
        ...state,
        isNewsFeedSubscribeRequesting: false,
        newsFeedSubscribeData: action.payload.response,
        newsFeedSubscribeDataError: false,
        newsFeedSubscribeDataNetworkError: false,
      };
    case NEWS_FEED_SUBSCRIBE_CONST.NEWS_FEED_SUBSCRIBE_FAILED:
      return {
        ...state,
        isNewsFeedSubscribeRequesting: false,
        newsFeedSubscribeData: false,
        newsFeedSubscribeDataError: action.payload.error ? false : action.payload.response,
        newsFeedSubscribeDataNetworkError: action.payload.error ? action.payload.error : false,
      };

    case NEWS_FEED_CHANGE_STATUS_CONST.NEWS_FEED_CHANGE_STATUS_REQUEST:
      return {
        ...state,
        isNewsFeedChangeStatusRequesting: true,
        newsFeedChangeStatusData: false,
        newsFeedChangeStatusDataError: false,
        newsFeedChangeStatusDataNetworkError: false
      };
    case NEWS_FEED_CHANGE_STATUS_CONST.NEWS_FEED_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        isNewsFeedChangeStatusRequesting: false,
        newsFeedChangeStatusData: action.payload.response,
        newsFeedChangeStatusDataError: false,
        newsFeedChangeStatusDataNetworkError: false
      };
    case NEWS_FEED_CHANGE_STATUS_CONST.NEWS_FEED_CHANGE_STATUS_FAILED:
      return {
        ...state,
        isNewsFeedChangeStatusRequesting: false,
        newsFeedChangeStatusData: false,
        newsFeedChangeStatusDataError: action.payload.error ? false : action.payload.response,
        newsFeedChangeStatusDataNetworkError: action.payload.error ? action.payload.error : false,
      };
    default:
      return { ...state };
  }
};

export default NewsFeed;

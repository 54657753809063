import React from 'react';
import { t } from 'react-multi-lang';
import Footer from './footer';

const TermsConditions = () => {
  return (
    <>
      <div className="right-img-wrapper">
        <div className="terms-conditions">
          <h2>{t('ABOUT_US.TERMS_AND_CONDITIONS')}</h2>
          <p className="terms-conditions-body">{t('ABOUT_US.TERMS_AND_CONDITIONS_ONE')}</p>
          <p className="terms-conditions-body">{t('ABOUT_US.TERMS_AND_CONDITIONS_TWO')}</p>
          <br />
          <h5>{t('ABOUT_US.SADMS_INFO_COLLECTION')}</h5>
          <br />
          <h6>{t('ABOUT_US.PERSONAL_INFO')}</h6>
          <p className="terms-conditions-body">{t('ABOUT_US.PERSONAL_INFO_DESC')}</p>
          <h6>{t('ABOUT_US.USE_OF_COOKIES')}</h6>
          <p className="terms-conditions-body">{t('ABOUT_US.USE_OF_COOKIES_DESC_ONE')}</p>
          <p className="terms-conditions-body">{t('ABOUT_US.USE_OF_COOKIES_DESC_TWO')}</p>
          <h6>{t('ABOUT_US.IP_ADDRESSES')}</h6>
          <p className="terms-conditions-body">{t('ABOUT_US.IP_ADDRESSES_DESC')}</p>
          <h6>{t('ABOUT_US.THIRD_PARTY_SITES')}</h6>
          <p className="terms-conditions-body">{t('ABOUT_US.THIRD_PARTY_SITES_DESC')}</p>
          <h6>{t('ABOUT_US.HOW_TO_CONTACT_US')}</h6>
          <p className="terms-conditions-body">{t('ABOUT_US.HOW_TO_CONTACT_US_DESC')}</p>
          <h6>{t('ABOUT_US.CHANGES')}</h6>
          <p className="terms-conditions-body">{t('ABOUT_US.CHANGES_DESC')}</p>
        </div>
      </div>
      <div className="terms-conditions-footer">
        <Footer publicFooter={false} />
      </div>
    </>
  );
};
export default TermsConditions;

import React from 'react';

import 'leaflet-side-by-side';
import 'leaflet/dist/leaflet.css';

import '../../../assets/CSS/LeafletWrapper.css';
import { LayersControlProvider } from './LayerControlContext';
import ParameterList from '../../../Components/Parameters';

// Classes used by Leaflet to position controls
// const POSITION_CLASSES = {
//   bottomleft: 'leaflet-bottom leaflet-left',
//   bottomright: 'leaflet-bottom leaflet-right',
//   topleft: 'leaflet-top leaflet-left',
//   topright: 'leaflet-top leaflet-right',
// };

function LayerControl({
 // position,
  children,
  isOpenWeather,
  handleParameters,
  handleDataSourceId,
  params,
  dataSourceId,
  isHideBar,
}) {
 // const positionClass = (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;
  return (
    <LayersControlProvider>
      <div className={isHideBar ? "leaflet-left-full" : "leaflet-left"}>
        <div className="leaflet-control leaflet-bar">
          <>
            <ParameterList
              initialParams={params}
              handleParameters={handleParameters}
              handleDataSourceId={handleDataSourceId}
              dataSourceId={dataSourceId}
              isOpenWeather={isOpenWeather}
            />
          </>
        </div>
        {children}
      </div>
    </LayersControlProvider>
  );
}

export default LayerControl;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-multi-lang';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Button from "reactstrap-button-loader";
import {deleteUserRequest, deleteUserSuccess} from "../../redux/user-list/userListAction";
import { showSuccess } from '../../Components/Notifications';

const DeleteConfirmationModal = (props) => {
  const {
    className,
    isOpen,
    handleDeleteConfirm,
    request_Id
  } = props;
  const dispatch = useDispatch();
  const t = useTranslation();
  const nextProps = useSelector((state) => ({
    isDeleteUserRequesting: state.userList.isDeleteUserRequesting,
    deleteData: state.userList.deleteUser || null,
  }));

    useEffect(() => {
    if(nextProps.deleteData){
    const {
      data: { result },
    } = nextProps.deleteData;
    if(result){
      showSuccess(result);
      handleDeleteConfirm(false);
    }
  }
  },[nextProps.deleteData])

  useEffect(() => {
    return () => {
      dispatch(deleteUserSuccess({}));
    };
  }, []);
  
    const toggle = (value) => {
      if(value === 'no'){
        handleDeleteConfirm(false,'no');
      }
      else if(value === 'yes') {
       dispatch(deleteUserRequest({id:request_Id}));
      }
    }
    const handleClose = () => {
        handleDeleteConfirm(false,'no');
      }
 
    return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} className={className}>
        <ModalHeader toggle={() => handleClose()} className="reason-modal-header">{t('USER_LIST.CONFIRMATION')}</ModalHeader>
        <ModalBody>
            <p className="confirmation-modal-text">{t('USER_LIST.CONFIRMATION_TITLE')}</p>
        </ModalBody>
        <ModalFooter className="reason-modal">
          <Button  color="secondary" onClick={() =>toggle('no')} className="cancel-button">{t('PUBLIC_API_ADMIN.NO')}</Button>{' '}
          <Button loading={nextProps.isDeleteUserRequesting} color="primary" onClick={() => toggle('yes')} className="modal-reject-button">{t('PUBLIC_API_ADMIN.YES')}</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default DeleteConfirmationModal;
DeleteConfirmationModal.propTypes = {
    isOpen: PropTypes.bool,
    modalData: PropTypes.object,
    handleLatestData: PropTypes.func,
    handleApprovedModalToggle: PropTypes.bool,
    fromModal: PropTypes.bool,
    activeTab: PropTypes.string,
    request_Id: PropTypes.string
  };
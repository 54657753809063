import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import StarIcon from '../../../assets/Images/star_icon.png';
import HandIcon from '../../../assets/Images/hand_icon.png';
import './Achievements.css';

const Achievements = () => {
  return (
    <div className="achievements-section">
      <Container fluid>
        <Row className="justify-content-center">
          <Col xs="12" className="text-center mb-4">
            <h1 className="achievements-title">Key Achievements</h1>
          </Col>
        </Row>
        <Row className="sub-container justify-content-center">
          <Col className="achievement-card">
            <div className="achievement-icon-container">
              <img src={StarIcon} alt="Award Icon" className="icon" />
            </div>
            <h2 className="achievement-card-title">Geospatial World Excellence Award 2020</h2>
            <p className="achievement-card-description">
              IWMI's SADMS received this prestigious award, recognizing its positive impact in
              mitigating drought.
            </p>
          </Col>
          <Col className="achievement-card mb-4 ">
            <div className="achievement-icon-container">
              <img src={HandIcon} alt="Empower Icon" className="icon" />
            </div>
            <h2 className="achievement-card-title">Empowering Decision-Makers</h2>
            <p className="achievement-card-description">
              IWMI's tools and data have informed policies and interventions at various levels,
              helping to build resilience in drought-prone communities.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Achievements;

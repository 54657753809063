// @flow
import { combineReducers } from 'redux';
import Login from './login/loginReducer';
import Register from './register/registerReducer';
import Weather from './weather/weatherReducer';
import Map_Preference from './map-preferences/mapPreferenceReducer';
import Location from './location/reducer';
import Home from './home/homeReducer';
import AccessKey from './accessKey/accessKeyReducer';
import staticResource from './staticResource/staticResourceReducer';
import DroughtMonitor from './droughtMonitor/droughtMonitorReducer';
import { reducer as formReducer } from 'redux-form';
import userList from '../redux/user-list/userListReducer';
import ManageCrops from '../redux/manageCrops/manageCropsReducer';
import ContingencyPlan from '../redux/contingencyPlan/contingencyPlanReducer';
import NewsFeed from '../redux/newsFeed/newsFeedReducer';
import UserGuide from './userGuide/userGuideReducer';
import Bulletin from './bulletin/bulletinReducers';

export default combineReducers({
  AccessKey,
  DroughtMonitor,
  Home,
  Login,
  Register,
  Weather,
  Map_Preference,
  Location,
  staticResource,
  userList,
  ManageCrops,
  ContingencyPlan,
  NewsFeed,
  UserGuide,
  form: formReducer,
  Bulletin,
});

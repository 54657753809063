import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { t } from 'react-multi-lang';
import Slider from 'react-slick';
import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from 'reactstrap';

import dateIcon from '../../assets/Images/date-icon.svg';
import leftArrow from '../../assets/Images/LeftArrow.svg';
import locationIcon from '../../assets/Images/location-icon.svg';
import rightArrow from '../../assets/Images/RightArrow.svg';
import reportsImage from '../../assets/Images/sadms-reports-logo.svg';
import CoverOptionalImage from '../../assets/Images/sadmsCoverOptionalImage.png';
import timeIcon from '../../assets/Images/timeline-icon.svg';
import { EventDateFormatter, EventTimeFormatter } from '../../Components/helpers';
// Import css files
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const NewsCarousel = ({ backgroundColor, cardData, dataType, title, handleSelectedEvent }) => {
  const sliderRef = useRef(null);
  let settings = {
    dots: false,
    infinite: cardData.length > 3, // Disable infinite scroll if there are fewer cards than slidesToShow
    slidesToShow: cardData.length >= 3 ? 3 : cardData.length, // Show only the number of cards available if fewer than 3
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    easing: 'ease-in-out',
    variableWidth: cardData.length < 3,
    responsive: [
      {
        breakpoint: 768, // Medium
        settings: {
          slidesToShow: cardData.length >= 2 ? 2 : cardData.length, // Show only 2 or the number of cards available
          slidesToScroll: 1,
          infinite: cardData.length > 2, // Only infinite scroll if more than 2 cards
          variableWidth: cardData.length < 3,
        },
      },
      {
        breakpoint: 576, // Small
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: cardData.length > 1, // Only infinite scroll if more than 1 card
          variableWidth: false,
        },
      },
    ],
  };

  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev(); // Call slickPrev method from react-slick
    }
  };

  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext(); // Call slickNext method from react-slick
    }
  };

  const handleOpenNewsfeed = data => {
    window.open(data.link, '_blank').focus();
  };

  const handleOpenEvents = data => {
    window.open(data.event_url, '_blank').focus();
  };

  const handleOpenReports = data => {
    window.open(data.link, '_blank').focus();
  };

  const renderCard = dataItem => {
    if (dataType === 'news') {
      /**
       * Separting the image and p tags for rendering them separatly
       */
      const imgTagRegex = /<img[^>]*>/;
      const imgTagMatch = dataItem?.content.match(imgTagRegex);
      const imgTag = imgTagMatch ? imgTagMatch[0] : '';
      // Remove img tag and empty p tags
      let remainingString = dataItem?.content.replace(imgTagRegex, '');
      remainingString = remainingString.replace(/<p>\s*<\/p>/g, '');
      return (
        <Card
          className="news-card news-card-truncate hover-card"
          key={dataItem.title}
          onClick={() => handleOpenNewsfeed(dataItem)}
        >
          {imgTag ? (
            <div dangerouslySetInnerHTML={{ __html: imgTag }} />
          ) : (
            <img src={CoverOptionalImage} alt="optional-image" />
          )}
          <CardBody>
            <CardTitle tag="h5" title={dataItem.title}>
              {dataItem.title}
            </CardTitle>
            <CardText>
              <div dangerouslySetInnerHTML={{ __html: remainingString }} />
            </CardText>
            <CardText>
              <small className="text-muted">{EventDateFormatter(dataItem.pubDate)}</small>
            </CardText>
          </CardBody>
        </Card>
      );
    }

    if (dataType === 'upcoming-events') {
      return (
        <Card
          className="news-card events-img-container hover-card news-card-truncate"
          key={dataItem.name}
          onClick={() => handleOpenEvents(dataItem)}
        >
          <CardImg top src={dataItem.file_url} alt={dataItem.name} />
          <CardBody>
            <CardTitle tag="h5">{dataItem.name}</CardTitle>
            <CardText className=" d-flex align-items-center">
              <img src={dateIcon} alt="Date" className="icon" />{' '}
              <p className=" m-0">
                {EventDateFormatter(dataItem.from_date)} - {EventDateFormatter(dataItem.to_date)}
              </p>
            </CardText>
            <CardText className=" d-flex align-items-center">
              <img src={timeIcon} alt="Time" className="icon" />{' '}
              <p className=" m-0">
                {EventTimeFormatter(dataItem.start_time)} - {EventTimeFormatter(dataItem.end_time)}
              </p>{' '}
              {dataItem.time}
            </CardText>
            <CardText className=" d-flex align-items-center">
              <img src={locationIcon} alt="Location" className="icon" />{' '}
              <p className=" m-0">{dataItem.location}</p>
            </CardText>
          </CardBody>
        </Card>
      );
    }

    if (dataType === 'reports') {
      return (
        <Card className="report-card news-card-truncate" key={dataItem.title}>
          <CardBody>
            <CardTitle tag="h5" className="d-flex justify-content-center">
              <img src={reportsImage} alt={dataItem.title} className="card-image" />
              <div className="d-flex flex-column">
                <span className="card-title">DMS</span>
                <span className="card-text">Drought Monitoring System</span>
              </div>
            </CardTitle>
            <hr className="hori-divider m-0" />
            <CardText className="description text-center mb-2  dms-partners-link-hide">
              <span className="trucate-link-2-lines" title={dataItem.title}>
                {dataItem.title}
              </span>
            </CardText>
            <Button className="card-button" onClick={() => handleOpenReports(dataItem)}>
              Click here
            </Button>
          </CardBody>
        </Card>
      );
    }
  };

  const getHeading = (dataType, title) => {
    if (dataType === 'upcoming-events') {
      return title === 'Events' ? t('LANDING_PAGE.EVENTS') : t('LANDING_PAGE.UPCOMING_EVENTS');
    }
    return title;
  };

  return (
    <Container fluid className={`news-container ${backgroundColor}`}>
      <Row className="justify-content-between align-items-center">
        <Col xs={6} md={9} className="d-flex align-items-center">
          <h2>{getHeading(dataType, title)}</h2>{' '}
          {title === 'Events' ? (
            <div
              className="d-flex align-items-center justify-content-between events-btn-conatiner d-none d-md-flex"
              onClick={e => handleSelectedEvent(e)}
            >
              <Button className="event-btns" data-name="today">
                {t('LANDING_PAGE.TODAY')}
              </Button>
              <Button className="event-btns event-btns-active" data-name="upcoming">
                {t('LANDING_PAGE.UPCOMING')}
              </Button>
              <Button className="event-btns" data-name="past">
                {t('LANDING_PAGE.PAST_EVENTS')}
              </Button>
            </div>
          ) : null}
        </Col>
        <Col xs={6} md="auto" className="card-arrows-container">
          <Button color="link" onClick={handlePrev}>
            <img src={leftArrow} alt="Previous" width={48} height={48} className="cards-arrow" />
          </Button>
          <Button color="link" onClick={handleNext}>
            <img src={rightArrow} alt="Next" width={48} height={48} className="cards-arrow" />
          </Button>
        </Col>
        {/* Mobile view */}
        {title === 'Events' ? (
          <Col xs={12} className="d-block d-md-none">
            <div
              className="d-flex align-items-center justify-content-between events-btn-conatiner-sm"
              onClick={e => handleSelectedEvent(e)}
            >
              <Button className="event-btns" data-name="today">
                {t('LANDING_PAGE.TODAY')}
              </Button>
              <Button className="event-btns event-btns-active" data-name="upcoming">
                {t('LANDING_PAGE.UPCOMING')}
              </Button>
              <Button className="event-btns" data-name="past">
                {t('LANDING_PAGE.PAST_EVENTS')}
              </Button>
            </div>
          </Col>
        ) : null}
      </Row>
      <Row className="cards-container">
        <Slider {...settings} ref={sliderRef}>
          {cardData.map((dataItem, index) => {
            return (
              <Col key={`${dataType}-${index}`} xs={12} md={4}>
                {renderCard(dataItem)}
              </Col>
            );
          })}
        </Slider>
      </Row>
    </Container>
  );
};

NewsCarousel.propTypes = {
  cardData: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataType: PropTypes.oneOf(['news', 'upcoming-events', 'reports']).isRequired,
  title: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  handleSelectedEvent: PropTypes.func,
};

export default NewsCarousel;

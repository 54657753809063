import React, { useState, useEffect } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import moment from 'moment';
import _, { isNumber } from 'lodash';
import { showError } from '../../Components/Notifications';
import { BiCurrentLocation } from 'react-icons/bi';
import * as turf from '@turf/turf';
import Geocode from 'react-geocode';
import Autocomplete from 'react-google-autocomplete';
import { parseCoordinate } from '../../Components/helpers';
import {
  Card,
  Col,
  Input,
  Row,
  Button,
  FormGroup,
  Label,
  CardBody,
  Form,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import Select from 'react-select';
import { BiMinus, BiPlus } from 'react-icons/bi';
import TRANSLATIONS from '../../translations/en.json';
import { BsSearch } from 'react-icons/bs';
import { RxCross2 } from 'react-icons/rx';
import {
  DATE_FORMAT,
  DEFAULT_COUNTRY,
  DROUGHT_MODULES_CONST,
  DROUGHT_PREDICTION_TEMPORAL_AGGREGATION,
  DROUGHT_TEMPORAL_AGGREGATION,
  ACCUMULATED_RAINFALL_TEMPORAL_AGGREGATION,
  INDIA_COORDINATE,
  INDICES_PARAMS,
  LOCATION,
  MONTHS,
  SOUTH_ASIA_CONST,
  OTHER_INDICES_API_INDICES_SLUGS,
  DROUGHT_PREDICTION_API_SLUGS,
  DSS_DROUGHT_MODULE_INDICATORS,
  DSS_INITAL_PARAMS_LIST,
  DSS_MODULE_CONSTS,
  ALERT_TABLE_INDICES,
  DRY_SPELL,
  ADDRESS_CONSTS,
  DROUGHT_COMPARE_OPTIONS,
  DDS_DRYSPELL_THRESHOLD_LIST,
  DSS_DROUGHT_PHASES_CONST,
  UNDEFINED,
  SPI_GPM_CHIRPS_MIN_DATES,
  SPI_GPM_CHIRPS_API_SLUG,
  DM_DRY_SPELL_SLUG,
  SOUTH_ASIA_COUNTRY,
} from '../../Components/constants';
// import SAJson from '../../Components/JsonFiles/SA.json';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCountryRequest,
  getStateByCountryRequest,
  getDistrictByStateRequest,
  getIndicesRequest,
  getMapPreferenceRequest,
  getIndicesSuccess,
  getDSSIndicesRequest,
  getDroughtPredictionIndicesMonths,
  getDroughtTimeSeriesSuccess,
  getDroughtAllSeasonRequest,
} from '../../redux/actions';
import { t } from 'react-multi-lang';
import DSSDroughtSidebar from './DSSModule/DSSFilter';
import DroughtIndicatorsCollapse from './DroughtIndicators';
import DroughtMonitorOtherIndicesCollapse from './OtherIndicesIndicators';
import ReactTooltip from 'react-tooltip';
import {
  checkDroughtConditon,
  getYearsListFormat,
  getUserLocationBasedOnIP,
  renderDSSAlertTableDateFormat,
  renderReactSelectOptionFormat,
  returnDSSDateFormat,
  isUserLocationExists,
} from '../../Components/common/utils';
import axios from 'axios';
import myConstClass from '../../Constants';

function DroughtSidebar(props) {
  const {
    popUpSelectedModule,
    selectedModule,
    setDSSTabularDate,
    tableData,
    isOpenWeatherForecastDataModal,
    setIsOpenWeatherForecastDataModal,
    weatherForecastData,
    selectedYear,
    setSelectedYear,
    setTableData,
    mapPreferencesData,
    rainfallAnamolyTime,
    drySpellPrecipitationThreshold,
    handleRainfallAnamolyStartYearChange,
    handleRainfallAnamolyEndYearChange,
    handleRainfallAnamolyMonthChange,
    compareRainfallAnamolyTime,
    handleCompareRainfallAnamolyEndYearChange,
    handleCompareRainfallAnamolyStartYearChange,
    handleCompareRainfallAnamolyMonthChange,
    setPrecipitationThreshold,
    setDrySpellPrecipitationThreshold,
    setCordinateAndZoom,
    isThresholdDataLoading,
    setIsThresholdDataLoading,
    setCurrenUserLocation,
    finalDroughtParameterMonthsList,
    setFinalDroughtParameterMonthsList,
    setSearchLocation,
    searchLocation,
    countryJson,
  } = props;
  const dispatch = useDispatch();
  const [temporalAggregation, setTemporalAggregation] = useState(DROUGHT_TEMPORAL_AGGREGATION);
  const [temporalAggreagationOptions, setTemporalAggregationOptions] = useState(
    DROUGHT_TEMPORAL_AGGREGATION,
  );
  const [isClickedOnPlus, setIsClickedOnPlus] = useState(false);
  const [isCompare, setIsCompare] = useState(DROUGHT_COMPARE_OPTIONS);
  const [tempState, setTempState] = useState({
    min: null,
    max: null,
  });
  const [initialSelectedDate, setInitialSelectedDate] = useState({
    startDate: moment()
      .subtract(30, 'days')
      .format(DATE_FORMAT),
    endDate: moment().format(DATE_FORMAT),
    maxEndDate: moment().format(DATE_FORMAT),
    maxStartDate: moment().format(DATE_FORMAT),
    minStartDate: '',
    minEndDate: '',
  });
  const [finalSelectedDate, setFinalSelectedDate] = useState({
    startDate: moment()
      .subtract(30, 'days')
      .format(DATE_FORMAT),
    endDate: moment().format(DATE_FORMAT),
    minStartDate: '',
    maxStartDate: moment().format(DATE_FORMAT),
    maxEndDate: moment().format(DATE_FORMAT),
    minEndDate: '',
  });
  const [regionOptions] = useState({
    name: SOUTH_ASIA_CONST.name,
    label: SOUTH_ASIA_CONST.name,
    value: SOUTH_ASIA_CONST.id,
    id: SOUTH_ASIA_CONST.id,
  });
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedParam, setSelectedParam] = useState({});
  const [compareParam, setCompareParam] = useState({});
  const [selectedLocation, setSelectedLocation] = useState({
    country: {},
    state: {},
    district: {},
  });
  const [defaultDistrict, setDefaultDistrict] = useState('');
  const [compareLocation, setCompareLocation] = useState({
    country: {},
    state: {},
    district: {},
  });
  const [thresholdDataList, setThresholdDataList] = useState({
    dryspellList: DDS_DRYSPELL_THRESHOLD_LIST,
    spiData: { min: -3, max: 0 },
    selectedDryspellThreshold: { id: 3, value: 4, label: '>=15' },
    selectedSpiThreshold: -1,
  });
  const [isCheckedDroughtCondition, setIsCheckedDroughtCondition] = useState(false);
  const [countryList, setCountryList] = useState();
  const [stateList, setStateList] = useState([]);
  const [compareStateList, setCompareStateList] = useState([]);
  const [country, setCountry] = useState('');
  const [compareCountry, setCompareCountry] = useState('');
  const [state, setState] = useState();
  const [compareState, setCompareState] = useState();
  const [selectedOption, setSelectedOption] = useState({});
  const [compareSelectedOption, setCompareSelectedOption] = useState({});
  // const [defaultCompareSelectedOption, setDefaultCompareSelectedOption] = useState({});
  const [districtList, setDistrictList] = useState();
  const [compareDistrictList, setCompareDistrictList] = useState();
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedCompareDistrict, setSelectedCompareDistrict] = useState(null);
  const [droughtPredictionIndicesMonths, setDroughtPredictionIndicesMonths] = useState('');
  const [compareDroughtIndicesData, setCompareDroughtIndicesData] = useState('');
  const [basin] = useState('');
  const [region] = useState();
  const [minStartDate, setMinStartDate] = useState();
  const [maxStartDate, setMaxStartDate] = useState();
  const [minEndDate, setMinEndDate] = useState();
  const [maxEndDate, setMaxEndDate] = useState();
  const [applyLayers, setApplyLayers] = useState(true);
  const [updateGraph, setUpdateGraph] = useState(true);
  const [error, setError] = useState(false);
  // const [finalDroughtData, setFinalDroughtData] = useState('');
  const [errorMessage, setErrorMessage] = useState();
  const [compareError, setCompareError] = useState({ showError: false, msg: '' });
  const [minError, setMinError] = useState(false);
  const [showMapPreferenceTooltip, setShowMapPreferenceTooltip] = useState(false);
  const [moduleOptions] = useState(DROUGHT_MODULES_CONST);
  const [minMaxYearError, setMinMaxYearError] = useState(false);
  const [compareMinMaxYearError, setCompareMinMaxYearError] = useState(false);
  const [showRegionTooltip, setShowRegionTooltip] = useState(false);

  //dss states
  const [selectedDroughtPhase, setSelectedDroughtPhase] = useState('');
  const [isShowDroughtPhaseModal, setIsShowDroughtPhaseModal] = useState(false);
  const [isShowImpactIndicators, setIsShowImpactIndicators] = useState(false);
  const [DroughtModuleConsts, setDroughtModuleConsts] = useState(DSS_DROUGHT_MODULE_INDICATORS);
  const [DSSDroughtIndicators, setDSSDroughtIndicators] = useState('');

  const [handleCategory, setHandleCategory] = useState([
    { name: 'Mandatory', id: 'Mandatory', category: 'Mandatory', checked: true },
    { name: 'Impact', id: 'Impact', category: 'Impact', checked: false },
  ]);
  const [checkedIndicesList, setCheckedIndicesList] = useState(DSS_INITAL_PARAMS_LIST);

  const [impactMonthsDropdownList, setImpactMonthsDropdownList] = useState([
    { id: 'Mid Season', category: 'Impact', options: [], selectedOption: {} },
    { id: 'Terminal', category: 'Impact', options: [], selectedOption: {} },
    { id: 'Final Drought Map', category: 'Impact', options: [], selectedOption: {} },
  ]);

  const [alertTableData, setAlertTableData] = useState([]);
  const [finalDroughtMap, setFinalDroughtMap] = useState([]);
  const [checkDroughtConditonToggle, setCheckDroughtConditionToggle] = useState([]);

  const [monsoonList, setMonsoonList] = useState([]);
  const [monthsList, setMonthsList] = useState({
    active_phase: [],
    alert_phase: [],
  });
  const [selectedMonthList, setSelectedMonthList] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState([]);
  const [selectedMonsoon, setSelectedMonsoon] = useState([]);
  const [yearsList, setYearsList] = useState([]);
  const [isDrought, setIsDrought] = useState(false);
  const [mandatoryIndices, setMandatoryIndices] = useState([]);
  const [isApplyLayers, setIsApplyLayers] = useState(false);
  const [showPreviewData, setShowPreviewData] = useState(false);
  const [DroughtIndicators, setDroughtIndicators] = useState({
    mandatory: '',
    impact: '',
  });
  const [CompareDroughtIndicators, setCompareDroughtIndicators] = useState({
    mandatory: '',
    impact: '',
  });
  const [handleCompareCategory, setCompareCategory] = useState([]);
  const [handleIndices, setHandleIndices] = useState();
  const [handleCompareIndices, setHandleCompareIndices] = useState();
  const [rainfallAnamolyTimeList, setRainfallAnamolyTimeList] = useState({
    yearList: '',
    compareYearList: '',
    monthList: '',
    compareMonthList: '',
  });
  const [countryData, setCountryData] = useState({});

  Geocode.setApiKey(process.env.REACT_APP_LOCATION_API_KEY);
  Geocode.setLanguage('en');
  Geocode.setLocationType('ROOFTOP');
  Geocode.enableDebug();

  //getting all country ,state ,district from store
  const {
    DroughtMonitor,
    DroughtIndicesData,
    Location,
    isGetDroughtAllSeasonRequesting,
    getDroughtAllSeasonData,
    getDroughtAllSeasonDataError,
    getDSSIndicesData,
    getDroughtTimeseriesData,
  } = useSelector(state => ({
    Location: state.Location,
    DroughtIndicesData: state.AccessKey.getIndicesData,
    DroughtMonitor: state.DroughtMonitor,
    isGetDroughtAllSeasonRequesting: state.DroughtMonitor.isGetDroughtAllSeasonRequesting,
    getDroughtAllSeasonData: state.DroughtMonitor.getDroughtAllSeasonData,
    getDroughtAllSeasonDataError: state.DroughtMonitor.getDroughtAllSeasonDataError,
    getDSSIndicesData: state.DroughtMonitor.getDSSIndicesData,
    getDSSIndicesDataError: state.DroughtMonitor.getDSSIndicesDataError,
    checkDroughtCriteriaData: state.DroughtMonitor.checkDroughtCriteriaData,
    checkDroughtCriteriaDataError: state.DroughtMonitor.checkDroughtCriteriaDataError,
    getDroughtTimeseriesData: state.DroughtMonitor.getDroughtTimeseriesData,
  }));

  useEffect(() => {
    let data = isUserLocationExists();
    if (data) {
      setCurrenUserLocation(data);
    } else {
      getUserLocationData();
    }
    const countryInfo = localStorage.getItem('countryData');
    // Convert the JSON string back to an object
    const countrySpecificData = JSON.parse(countryInfo);
    if (countrySpecificData?.country_slug === 'southasia') {
      setSelectedRegion(regionOptions);
    }

    setCountryData(countrySpecificData);
  }, []);

  useEffect(() => {
    if (popUpSelectedModule) {
      props.handleSelectedModule(popUpSelectedModule);
    }
  }, [popUpSelectedModule]);

  useEffect(() => {
    if (props.isHideBar) {
      setIsThresholdDataLoading(false);
    }
  }, [props.isHideBar]);

  useEffect(() => {
    if (!props.isShowDroughtCheckModal) {
      setIsCheckedDroughtCondition(false);
    }
  }, [props.isShowDroughtCheckModal]);

  useEffect(() => {
    if (!_.isEmpty(DroughtIndicesData)) {
      const { data } = DroughtIndicesData;
      let parameterArr = [];
      let count = 0;
      data?.result?.map(item => {
        let x = _.clone(item);
        x?.parameter?.map(i => {
          i.checked = false;
          i.paramIndex = count;
          count = count + 1;
          parameterArr.push(i);
        });
      });
      props.handleParams(parameterArr);
    } else {
      props.handleParams([]);
    }
  }, [DroughtIndicesData]);

  useEffect(() => {
    if (DroughtIndicesData && selectedModule?.api_slug === 'drought-monitor-v2') {
      let compareDroughtIndicesData = JSON.parse(JSON.stringify(DroughtIndicesData));
      if (isCompare?.some(i => i.name === 'map' && i.selected)) {
        setCompareDroughtIndicesData(compareDroughtIndicesData);
      } else if (isCompare?.some(i => i.name === 'graph' && i.selected)) {
        const { data } = compareDroughtIndicesData;
        let itemData = '';
        let parameterData = [];
        let result = [];
        if (!_.isEmpty(selectedParam)) {
          data?.result?.map(item => {
            itemData = '';
            parameterData = [];
            if (
              item.parameter?.map(i => {
                if (selectedParam?.id === INDICES_PARAMS.RAINFALL_ANAMOLY.id) {
                  if (i.name === INDICES_PARAMS.RAINFALL_ANAMOLY.name) {
                    parameterData?.push(i);
                    itemData = item;
                  }
                } else {
                  if (i.id !== INDICES_PARAMS.RAINFALL_ANAMOLY.id) {
                    parameterData?.push(i);
                    itemData = item;
                  }
                }
              })
            )
              if (itemData) {
                itemData.parameter = parameterData;
                result.push(itemData);
              }
          });
          if (compareDroughtIndicesData) {
            compareDroughtIndicesData.data.result = result;
            setCompareDroughtIndicesData(compareDroughtIndicesData);
          }
        } else {
          setCompareDroughtIndicesData(compareDroughtIndicesData);
        }
      }
    }
    if (selectedParam.api_slug === OTHER_INDICES_API_INDICES_SLUGS.EXTREME_RAINFALL) {
      handleTemporalAggregation(DROUGHT_TEMPORAL_AGGREGATION[0]);
    }
    if (selectedParam.api_slug === DM_DRY_SPELL_SLUG.DRY_SPELL) {
      handleTemporalAggregation(DROUGHT_TEMPORAL_AGGREGATION[0]);
    }
    if (compareParam?.api_slug === OTHER_INDICES_API_INDICES_SLUGS.EXTREME_RAINFALL) {
      handleTemporalAggregation(DROUGHT_TEMPORAL_AGGREGATION[0]);
    }
    if (compareParam?.api_slug === DM_DRY_SPELL_SLUG.DRY_SPELL) {
      handleTemporalAggregation(DROUGHT_TEMPORAL_AGGREGATION[0]);
    }
  }, [selectedParam, DroughtIndicesData, isCompare]);

  useEffect(() => {
    if (mapPreferencesData?.length === 2) setShowMapPreferenceTooltip(true);
    else setShowMapPreferenceTooltip(false);
  }, [mapPreferencesData]);

  useEffect(() => {
    if (selectedParam.api_slug === OTHER_INDICES_API_INDICES_SLUGS.ACCUMULATED_RAINFALL) {
      setTemporalAggregationOptions(ACCUMULATED_RAINFALL_TEMPORAL_AGGREGATION);
      setTemporalAggregation(ACCUMULATED_RAINFALL_TEMPORAL_AGGREGATION[0]);
    } else {
      setTemporalAggregation(DROUGHT_TEMPORAL_AGGREGATION[0]);
      setTemporalAggregationOptions(DROUGHT_TEMPORAL_AGGREGATION);
    }
  }, [selectedParam]);

  useEffect(() => {
    if (!_.isEmpty(selectedModule)) {
      setSelectedMonsoon('');
      setSelectedDroughtPhase('');
      setSelectedYear('');
      setSelectedMonth('');
      if (selectedModule.api_slug !== 'drought-decision-support' && selectedLocation?.country?.id) {
        dispatch(getIndicesSuccess(''));
        dispatch(
          getIndicesRequest({
            requestObj: {
              type: 'country',
              // type_id: selectedLocation.country?.id,
              type_id:
                selectedLocation.country?.name !== 'South Asia'
                  ? selectedLocation.country?.id
                  : 'e2f886a7-3299-4c82-af2e-987fe92fab39', // ID of India
            },
            module: selectedModule.api_slug,
          }),
        );
      }
      if (selectedModule.api_slug === 'drought-prediction') {
        setTemporalAggregationOptions(DROUGHT_PREDICTION_TEMPORAL_AGGREGATION);
        dispatch(getDroughtPredictionIndicesMonths());

        setInitialSelectedDate(prev => {
          return {
            ...prev,
            startDate: moment().format(DATE_FORMAT),
            minStartDate: moment().format(DATE_FORMAT),
            maxStartDate: moment()
              .add(9, 'days')
              .format(DATE_FORMAT),
            minEndDate: moment().format(DATE_FORMAT),
            endDate: moment()
              .add(9, 'days')
              .format(DATE_FORMAT),
            maxEndDate: moment()
              .add(9, 'days')
              .format(DATE_FORMAT),
          };
        });
        setFinalSelectedDate(prev => {
          return {
            ...prev,
            startDate: moment().format(DATE_FORMAT),
            minStartDate: moment().format(DATE_FORMAT),
            maxStartDate: moment()
              .add(9, 'days')
              .format(DATE_FORMAT),
            minEndDate: moment().format(DATE_FORMAT),
            endDate: moment()
              .add(9, 'days')
              .format(DATE_FORMAT),
            maxEndDate: moment()
              .add(9, 'days')
              .format(DATE_FORMAT),
          };
        });
        // setMinEndDate(moment().format(DATE_FORMAT));
        setMaxEndDate(
          moment()
            .add(9, 'days')
            .format(DATE_FORMAT),
        );
      } else {
        setTemporalAggregationOptions(DROUGHT_TEMPORAL_AGGREGATION);
        setMaxEndDate(moment().format(DATE_FORMAT));
        setInitialSelectedDate(prev => {
          return {
            ...prev,
            startDate: moment()
              .subtract(30, 'days')
              .format(DATE_FORMAT),
            endDate: moment().format(DATE_FORMAT),
            minStartDate: ' ',
            maxStartDate: moment().format(DATE_FORMAT),
            minEndDate: ' ',
            maxEndDate: moment().format(DATE_FORMAT),
          };
        });
        setFinalSelectedDate(prev => {
          return {
            ...prev,
            startDate: moment()
              .subtract(30, 'days')
              .format(DATE_FORMAT),
            endDate: moment().format(DATE_FORMAT),
            minStartDate: ' ',
            maxStartDate: moment().format(DATE_FORMAT),
            minEndDate: ' ',
            maxEndDate: moment().format(DATE_FORMAT),
          };
        });
      }
    }
  }, [selectedModule]);

  //for getting country
  useEffect(() => {
    dispatch(getCountryRequest({ location_type: LOCATION.Country, parent_id: '' }));
    let defaultSelectedAggregation = DROUGHT_TEMPORAL_AGGREGATION?.find(
      item => item.name === 'Mean',
    );
    if (!_.isEmpty(defaultSelectedAggregation)) {
      setTemporalAggregation(defaultSelectedAggregation);
    }
    setMaxStartDate(moment(new Date()).format('YYYY-MM-DD'));
    setMaxEndDate(moment(new Date()).format('YYYY-MM-DD'));
    setMinEndDate(moment(new Date()).format('YYYY-MM-DD'));
  }, []);

  //for getting state by country-id
  useEffect(() => {
    if (!_.isEmpty(selectedLocation.country.id)) {
      if (selectedLocation.country.name !== SOUTH_ASIA_CONST.name) {
        setStateList([]);
        dispatch(
          getStateByCountryRequest({
            requestObj: {
              location_type: LOCATION.State,
              parent_id: selectedLocation.country?.id,
            },
            isCompareLocation: false,
          }),
        );
        dispatch(getMapPreferenceRequest({ country_id: selectedLocation.country.id }));
      } else {
        dispatch(
          getMapPreferenceRequest({
            region_id: 'e2f886a7-3299-4c82-af2e-987fe92fab39', // ID of India
          }),
        );
      }
    }
  }, [selectedLocation.country]);

  useEffect(() => {
    if (
      selectedModule &&
      selectedModule.api_slug.length !== 0 &&
      (_.isEmpty(selectedLocation?.state) || selectedLocation?.state?.name === 'select')
    ) {
      if (selectedLocation?.country.name === SOUTH_ASIA_CONST.name) {
        // setSelectedParam('');
        // setCompareParam('');
        if (selectedModule.api_slug !== 'drought-decision-support') {
          dispatch(getDroughtTimeSeriesSuccess(''));
        } else {
          props.setDSSTimeSeriesData([]);
          // dispatch(
          //   getDSSThresholdData({
          //     location_type: LOCATION.Region,
          //     location_id: selectedLocation?.country.id,
          //   }),
          // );
          dispatch(getDSSIndicesRequest());
        }
      } else if (
        !_.isEmpty(selectedLocation.country) &&
        selectedLocation.country?.name !== SOUTH_ASIA_CONST.name
      ) {
        // setSelectedParam('');
        // setCompareParam('');
        if (selectedModule.api_slug !== 'drought-decision-support') {
          // indices api call
        } else {
          props.setDSSTimeSeriesData([]);
          // dispatch(
          //   getDSSThresholdData({
          //     location_type: _.lowerCase(LOCATION.Country),
          //     location_id: selectedLocation.country?.id,
          //   }),
          // );
          dispatch(getDSSIndicesRequest());
        }
      }
    }
  }, [selectedLocation.country, selectedModule, selectedLocation?.state]);

  useEffect(() => {
    /**
     * added by: sai krishna thalla
     * reason: on module change the check drought toggle is not disabling
     * date : 6-sep-2022
     */
    if (!_.isEmpty(checkDroughtConditonToggle)) {
      setCheckDroughtConditionToggle(prev => {
        return (prev[0].checked = false);
      });
    }
  }, [selectedModule]);

  useEffect(() => {
    if (
      !_.isEmpty(selectedLocation?.state?.id) &&
      selectedLocation.state.name !== 'select' &&
      (_.isEmpty(selectedLocation?.district) || selectedLocation?.district?.name === 'select')
    ) {
      props.setDSSTimeSeriesData([]);
      // dispatch(
      //   getDSSThresholdData({
      //     location_type: _.lowerCase(LOCATION.State),
      //     location_id: selectedLocation.state?.id,
      //   }),
      // );
    }
  }, [selectedLocation.state, selectedLocation.district]);

  useEffect(() => {
    if (!_.isEmpty(selectedLocation?.state?.id) && selectedLocation.state.name !== 'select') {
      setDistrictList([]);
      dispatch(
        getDistrictByStateRequest({
          requestObj: {
            location_type: LOCATION.District,
            parent_id: selectedLocation.state?.id,
          },
          isCompareLocation: false,
        }),
      );
      /* TODO: commented this: because state based indicators will be added in future from api side*/

      //dispatch(getIndicesSuccess(''));
      // dispatch(
      //   getIndicesRequest({
      //     type: 'state',
      //     type_id: selectedLocation.state?.id,
      //   }),
      // );
    }
  }, [selectedLocation.state]);

  //for getting state by country-id
  useEffect(() => {
    if (
      !_.isEmpty(compareLocation.country?.id) &&
      compareLocation.country.id !== 'select' &&
      compareLocation?.country?.name !== SOUTH_ASIA_CONST.name
    ) {
      setCompareStateList([]);
      dispatch(
        getStateByCountryRequest({
          requestObj: {
            location_type: LOCATION.State,
            parent_id: compareLocation.country?.id,
          },
          isCompareLocation: true,
        }),
      );
    }
  }, [compareLocation.country]);

  useEffect(() => {
    if (!_.isEmpty(compareLocation.state?.id) && compareLocation.state.name !== 'select') {
      setCompareDistrictList([]);
      dispatch(
        getDistrictByStateRequest({
          requestObj: {
            location_type: LOCATION.District,
            parent_id: compareLocation.state?.id,
          },
          isCompareLocation: true,
        }),
      );
    }
  }, [compareLocation.state]);

  useEffect(() => {
    if (
      !_.isEmpty(selectedLocation?.district) &&
      selectedLocation?.district?.id &&
      selectedLocation?.district?.name !== 'select'
    ) {
      /* TODO: commented this: because state based indicators will be added in future from api side*/
      //dispatch(getIndicesSuccess(''));
      // dispatch(
      //   getIndicesRequest({
      //     type: 'district',
      //     type_id: selectedLocation.district?.id,
      //   }),
      // );
      if (selectedModule.api_slug === 'drought-decision-support') {
        props.setDSSTimeSeriesData([]);
        // dispatch(
        //   getDSSThresholdData({
        //     location_type: _.lowerCase(LOCATION.District),
        //     location_id: selectedLocation.district?.id,
        //   }),
        // );
      }
    }
  }, [selectedLocation.district, selectedModule]);

  useEffect(() => {
    if (DroughtMonitor.getDroughtPredictionIndicesMonthsData) {
      const { data } = DroughtMonitor.getDroughtPredictionIndicesMonthsData;
      if (data?.result) {
        let monthsData = data?.result[0];
        if (monthsData?.parameter?.length) {
          let arr = [];
          arr = monthsData?.parameter?.map(i => {
            return {
              ...i,
              id: i.month,
              label: i.month,
              value: i.month,
              frequency: i.value,
            };
          });
          setDroughtPredictionIndicesMonths(arr);
        }
      }
    }
  }, [
    DroughtMonitor.getDroughtPredictionIndicesMonthsData,
    DroughtMonitor.getDroughtPredictionIndicesMonthsDataError,
  ]);

  useEffect(() => {
    if (Location.countryList) {
      const { result } = Location.countryList;
      if (result?.[0]) {
        let arr = [];
        result.map(i => {
          return arr.push({
            id: i.id,
            name: i.name,
            value: i.name,
            label: i.name,
            region_id: i.region_id,
            latitude: i.latitude,
            longitude: i.longitude,
            zoom_level: i.zoom_level,
          });
        });
        setCountryList(arr);
      }
    }
  }, [Location.countryList]);

  useEffect(() => {
    if (countryList && countryList?.length) {
      const defaultCountry = countryList?.find(item => item.id === countryData?.country_id);
      if (!_.isEmpty(defaultCountry)) {
        setCountry(defaultCountry);
        setCompareCountry(defaultCountry);
        setState(null);
        setSelectedOption(defaultCountry);
        setCompareState(null);
        setSelectedDistrict(null);
        setSelectedCompareDistrict(null);
        setSelectedLocation(prev => {
          return {
            ...prev,
            country: defaultCountry,
          };
        });
        setCompareLocation(prev => {
          return {
            ...prev,
            country: defaultCountry,
          };
        });

        dispatch(
          getIndicesRequest({
            requestObj: {
              type: 'country',
              type_id:
                defaultCountry?.name !== 'South Asia'
                  ? defaultCountry?.id
                  : 'e2f886a7-3299-4c82-af2e-987fe92fab39',
            },
            module: selectedModule.api_slug,
          }),
        );
      }
    }
  }, [countryList]);

  useEffect(() => {
    let arr = [];
    if (Location.stateList) {
      let result = '';
      let config = Location?.stateList?.config;
      if (Location?.stateList?.data) result = Location?.stateList?.data?.result;
      if (result) {
        result.map(i => {
          return arr.push({
            id: i.id,
            name: i.name,
            value: i.name,
            label: i.name,
            country_id: i.country_id,
            latitude: i.latitude,
            longitude: i.longitude,
            zoom_level: i.zoom_level,
          });
        });
      }
      if (!config?.isCompareLocation) {
        setStateList(arr);
      } else {
        setCompareStateList(arr);
      }
    }
  }, [Location.stateList]);

  useEffect(() => {
    let arr = [];
    if (Location.districtList) {
      let result = '';
      let config = Location?.districtList?.config;
      if (Location?.districtList?.data) result = Location?.districtList?.data?.result;
      if (result) {
        result?.map(i => {
          return arr.push({
            id: i.id,
            name: i.name,
            value: i.name,
            label: i.name,
            state_id: i.state_id,
            latitude: i.latitude,
            longitude: i.longitude,
            zoom_level: i.zoom_level,
          });
        });
      }
      if (!config?.isCompareLocation) {
        setDistrictList(arr);
      } else {
        setCompareDistrictList(arr);
      }
    }
  }, [Location.districtList]);

  /**
   * added by: sai krishna thalla
   * reason: when  cp opened through dds and default 1st district should be selected for cp when no district is selected in dds.
   * date: 12-sep-2022
   */
  useEffect(() => {
    if (!_.isEmpty(districtList)) {
      setDefaultDistrict(districtList[0]);
    }
  }, [districtList]);

  useEffect(() => {
    props.handleCompareView(isCompare);

    if (
      isCompare.some(i => (i?.name === 'map' || i?.name === 'graph') && i?.selected) &&
      selectedModule?.api_slug === 'drought-monitor-v2'
    ) {
      setCompareParam('');
    }
  }, [isCompare]);

  useEffect(() => {
    if (Object.keys(selectedOption).length) {
      const { latitude, longitude, zoom_level } = selectedOption;
      return props.handleCordinates(
        [latitude ? latitude : INDIA_COORDINATE.lat, longitude ? longitude : INDIA_COORDINATE.long],
        zoom_level ? zoom_level : INDIA_COORDINATE.zoom_level,
      );
    } else {
      return props.handleCordinates(
        [INDIA_COORDINATE.lat, INDIA_COORDINATE.long],
        SOUTH_ASIA_CONST.zoom_level,
      );
    }
  }, [selectedOption]);

  useEffect(() => {
    if (Object.keys(compareSelectedOption).length) {
      const { latitude, longitude, zoom_level } = compareSelectedOption;
      return props.handleCompareCordinates(
        [latitude ? latitude : INDIA_COORDINATE.lat, longitude ? longitude : INDIA_COORDINATE.long],
        zoom_level ? zoom_level : INDIA_COORDINATE.zoom_level,
      );
    }
  }, [compareSelectedOption]);

  useEffect(() => {
    if (!_.isEmpty(temporalAggregation)) {
      props.handleProps({
        temporalAggregation,
        state: tempState,
        initialSelectedDate,
      });
    }
  }, [temporalAggregation, tempState, initialSelectedDate]);

  useEffect(() => {
    if (!_.isEmpty(selectedRegion) && selectedLocation.country?.name === SOUTH_ASIA_CONST.name) {
      props.handleSelectedRegion(selectedRegion?.value);
    } else {
      props.handleSelectedRegion('');
    }
  }, [selectedRegion, selectedLocation.country]);

  useEffect(() => {
    let compareArr = [];
    if (!_.isEmpty(compareParam)) {
      compareArr.push(compareParam);
    }
    props.handleIsCompare(isClickedOnPlus);
    props.handleCompareProps({
      compareParams: compareArr || '',
      compareSelectedParam: compareParam,
      finalSelectedDate,
      isCompareClicked: isClickedOnPlus,
    });
  }, [compareParam, finalSelectedDate, isClickedOnPlus]);

  useEffect(() => {
    let arr = [];
    arr.push(selectedParam);
    props.handleSelectedParam(selectedParam);
  }, [selectedParam]);

  /**
   * added by : sai krishna thalla
   * reason : min date added for spigpm and spichirps
   * reason2 : added before 1:30 yesterdays date and after 1:30 todays date for spi and sri
   * date: 14-sep-2022
   */

  /**
   * added by saikrishna thalla
   * reason: dds on checking switches crash issue fix fe
   * date: 16-sep-2022
   */

  useEffect(() => {
    let isGraph = isCompare?.some(item => item.name === 'graph' && item.selected);
    if (!_.isEmpty(selectedParam) && !_.isEmpty(selectedParam?.min_date)) {
      setInitialSelectedDate(prev => {
        return {
          ...prev,
          minStartDate: selectedParam?.min_date,
        };
      });
    }
    if (isClickedOnPlus) {
      if (isGraph && !_.isEmpty(compareParam) && !_.isEmpty(selectedParam)) {
        let d1 = new Date(selectedParam?.min_date);
        let d2 = new Date(compareParam?.min_date);
        if (d1 >= d2 && !_.isEmpty(selectedParam?.min_date)) {
          setInitialSelectedDate(prev => {
            return {
              ...prev,
              minStartDate: selectedParam?.min_date,
            };
          });
        } else if (!_.isEmpty(compareParam?.min_date)) {
          setInitialSelectedDate(prev => {
            return {
              ...prev,
              minStartDate: compareParam?.min_date,
            };
          });
        }
      }
    }
  }, [isCompare, compareParam, selectedParam, isClickedOnPlus]);

  useEffect(() => {
    if (!_.isEmpty(compareParam) && !_.isEmpty(compareParam?.min_date)) {
      setFinalSelectedDate(prev => {
        return {
          ...prev,
          minStartDate: compareParam?.min_date,
          minEndDate: compareParam?.min_date,
        };
      });
    }
  }, [compareParam]);

  useEffect(() => {
    props.handleLocation(country, state, selectedDistrict, basin, region);
  }, [country, state, basin, region, selectedDistrict]);

  useEffect(() => {
    props.handleCompareLocation(compareCountry, compareState, selectedCompareDistrict);
  }, [compareCountry, compareState, selectedCompareDistrict]);

  //dds methods
  useEffect(() => {
    if (!_.isEmpty(getDSSIndicesData)) {
      let indicesData = JSON.parse(JSON.stringify(getDSSIndicesData));
      const { data } = getDSSIndicesData;
      let parameterArr = [];
      let count = 0;
      let droughtMapSwitch = {
        api_slug: '',
        checked: false,
        disabled: false,
        drought_indices_category_name: DSS_MODULE_CONSTS.FINAL_DROUGHT_MAP,
        id: DSS_MODULE_CONSTS.FINAL_DROUGHT_MAP,
        impact: true,
        label: 'Drought Map',
        mandatory: false,
        min_date: '',
        name: 'Final Drought Map',
        slug: 'finaldroughtmap',
        paramIndex: '',
        status: true,
      };
      let FinalDroughtObj = {
        name: DSS_MODULE_CONSTS.FINAL_DROUGHT_MAP,
        category: DSS_MODULE_CONSTS.IMPACT,
        parameter: [],
      };
      indicesData['data'].result = data?.result?.map(item => {
        let x = _.clone(item);
        if (item?.parameter?.length) {
          x.parameter = item?.parameter?.map((i, index) => {
            i.checked = false;
            i.paramIndex = count;
            count = count + 1;
            parameterArr.push(i);
            return i;
          });
          return x;
        }
      });
      if (parameterArr.length) {
        droughtMapSwitch.paramIndex = count;
        parameterArr.push(droughtMapSwitch);
        FinalDroughtObj?.parameter?.push(droughtMapSwitch);
        // CheckDroughtObj?.parameter?.push(checkDroughtSwitch);
      }
      if (indicesData?.data?.result) {
        indicesData?.data?.result?.push(FinalDroughtObj);
        // indicesData?.data?.result?.push(CheckDroughtObj);
      }
      //setFinalDroughtData(droughtMapSwitch);
      setDSSDroughtIndicators(indicesData);
      props.handleParams(parameterArr);
    } else {
      props.handleParams([]);
      setDSSDroughtIndicators([]);
    }
  }, [getDSSIndicesData]);

  useEffect(() => {
    let requestObj = {};
    if (selectedLocation?.country?.id && selectedLocation?.country?.id !== 'select') {
      requestObj = {
        type: 'country',
        type_id:
          selectedLocation.country?.name !== 'South Asia'
            ? selectedLocation.country?.id
            : 'e2f886a7-3299-4c82-af2e-987fe92fab39',
      };
    } else if (!_.isEmpty(selectedRegion) && selectedLocation?.country?.value === '') {
      requestObj = { type: 'region', type_id: selectedRegion?.value };
    }

    if (!_.isEmpty(requestObj)) {
      setSelectedMonsoon('');
      dispatch(getDroughtAllSeasonRequest(requestObj));
    }
  }, [selectedLocation?.country, selectedRegion]);

  useEffect(() => {
    if (getDroughtAllSeasonData) {
      if (getDroughtAllSeasonData?.data) {
        const {
          data: { result },
        } = getDroughtAllSeasonData;
        if (result) {
          let arr = [];
          result?.map(i => {
            i.value = i.season_name;
            i.label = i.season_name;
            arr.push(i);
          });
          setMonsoonList(arr);
        }
      }
    } else if (getDroughtAllSeasonDataError) {
      setMonsoonList([]);
    }
  }, [getDroughtAllSeasonData, getDroughtAllSeasonDataError]);

  useEffect(() => {
    if (!_.isEmpty(props.dssTimeSeriesData) && isCheckedDroughtCondition) {
      let threshold = {
        spi: parseInt(thresholdDataList?.selectedSpiThreshold),
        dryspell: parseInt(thresholdDataList?.selectedDryspellThreshold?.value),
      };
      let value = {};
      props.dssTimeSeriesData?.map(item => {
        if (item.config.layerType === 'Dry Spell') {
          value.dryspell = item.data?.result?.graph_data?.data[0];
        } else if (item.config.layerType === 'SPI') {
          value.spi = item.data?.result?.graph_data?.data[0];
        }
      });
      let isDrought = checkDroughtConditon(threshold, value);
      setIsDrought(isDrought);
      setIsShowImpactIndicators(true);
      if (isThresholdDataLoading) props.setIsShowDroughtCheckModal(true);
    } else if (_.isEmpty(props.dssTimeSeriesData)) {
      setIsDrought(false);
      setIsShowImpactIndicators(false);
      props.setIsShowDroughtCheckModal(false);
    }
  }, [
    thresholdDataList?.selectedSpiThreshold,
    thresholdDataList?.selectedDryspellThreshold,
    props.dssTimeSeriesData,
    isThresholdDataLoading,
    isCheckedDroughtCondition,
  ]);

  useEffect(() => {
    if (getDroughtTimeseriesData) {
      let indicesList = checkedIndicesList?.find(item => item.name === DSS_MODULE_CONSTS.MANDATORY)
        ?.indicesList;
      if (indicesList?.length) {
        setTableData(getDroughtTimeseriesData);
      }
    }
  }, [getDroughtTimeseriesData]);

  useEffect(() => {
    if (!_.isEmpty(tableData)) {
      let arr = [];
      arr.push(tableData);
      let timeseriesTableData = Array.isArray(tableData) ? tableData : arr;
      let table = {
        columns: [],
        rowData: [],
      };
      //header appending
      if (timeseriesTableData?.length === 1) {
        let {
          data: {
            result: { graph_data: graphData },
          },
        } = timeseriesTableData[0];
        let headerObj = {
          Header: 'Indices',
          accessor: 'indices',
        };
        table.columns.push(headerObj);

        graphData?.millis
          .slice(0)
          .reverse()
          ?.map((x, index) => {
            let obj = {
              Header: moment(new Date(x))
                .local()
                .format('YYYY'),
              columns: [],
            };
            if (index === 0) {
              [...new Array(4)].forEach((data, m) => {
                let innerColumnObj = {};
                innerColumnObj = {
                  Header: moment(new Date(x))
                    .subtract(m, 'month')
                    .format('MMM'),
                  accessor: `${moment(new Date(x))
                    .subtract(m, 'month')
                    .format('MMM')}_${moment(new Date(x))
                    .subtract(m, 'month')
                    .format('YYYY')}`,
                };
                obj.columns.push(innerColumnObj);
              });
            } else {
              let innerColumnObj = {};
              innerColumnObj = {
                Header: moment(new Date(x)).format('MMM'),
                accessor: `${moment(new Date(x)).format('MMM')}_${moment(new Date(x))
                  .local()
                  .format('YYYY')}`,
              };
              obj.columns.push(innerColumnObj);
            }

            table.columns.push(obj);
          });
      } else if (
        timeseriesTableData?.length === 2 &&
        timeseriesTableData[1]?.data?.result?.graph_data?.millis?.length !== 0
      ) {
        let {
          data: {
            result: { graph_data: graphData },
          },
        } = timeseriesTableData[1];
        let headerObj = {
          Header: 'Indices',
          accessor: 'indices',
        };
        table.columns.push(headerObj);

        graphData?.millis
          .slice(0)
          .reverse()
          ?.map((x, index) => {
            let obj = {
              Header: moment(new Date(x))
                .local()
                .format('YYYY'),
              columns: [],
            };
            if (index === 0) {
              [...new Array(4)].forEach((data, m) => {
                let innerColumnObj = {};
                innerColumnObj = {
                  Header: moment(new Date(x))
                    .subtract(m, 'month')
                    .format('MMM'),
                  accessor: `${moment(new Date(x))
                    .subtract(m, 'month')
                    .format('MMM')}_${moment(new Date(x))
                    .subtract(m, 'month')
                    .format('YYYY')}`,
                };
                obj.columns.push(innerColumnObj);
              });
            } else {
              let innerColumnObj = {};
              innerColumnObj = {
                Header: moment(new Date(x)).format('MMM'),
                accessor: `${moment(new Date(x)).format('MMM')}_${moment(new Date(x))
                  .local()
                  .format('YYYY')}`,
              };
              obj.columns.push(innerColumnObj);
            }

            table.columns.push(obj);
          });
      } else if (
        timeseriesTableData?.length === 2 &&
        timeseriesTableData[0]?.data?.result?.graph_data?.millis?.length !== 0
      ) {
        let {
          data: {
            result: { graph_data: graphData },
          },
        } = timeseriesTableData[0];
        let headerObj = {
          Header: 'Indices',
          accessor: 'indices',
        };
        table.columns.push(headerObj);

        graphData?.millis
          .slice(0)
          .reverse()
          ?.map((x, index) => {
            let obj = {
              Header: moment(new Date(x))
                .local()
                .format('YYYY'),
              columns: [],
            };
            if (index === 0) {
              [...new Array(4)].forEach((data, m) => {
                let innerColumnObj = {};
                innerColumnObj = {
                  Header: moment(new Date(x))
                    .subtract(m, 'month')
                    .format('MMM'),
                  accessor: `${moment(new Date(x))
                    .subtract(m, 'month')
                    .format('MMM')}_${moment(new Date(x))
                    .subtract(m, 'month')
                    .format('YYYY')}`,
                };
                obj.columns.push(innerColumnObj);
              });
            } else {
              let innerColumnObj = {};
              innerColumnObj = {
                Header: moment(new Date(x)).format('MMM'),
                accessor: `${moment(new Date(x)).format('MMM')}_${moment(new Date(x))
                  .local()
                  .format('YYYY')}`,
              };
              obj.columns.push(innerColumnObj);
            }

            table.columns.push(obj);
          });
      }
      //data appending
      timeseriesTableData?.map((item, index) => {
        let {
          data: {
            result: { graph_data: graphData },
          },
        } = item;
        let data = {};
        let label =
          timeseriesTableData?.length === 1 ? props?.selectedParam?.name : item?.config?.layerType;
        data['indices'] =
          label === DRY_SPELL.DRY_SPELL ? `${_.startCase(_.toLower(label))} (Days)` : label;
        let slug =
          timeseriesTableData?.length === 1 ? props?.selectedParam?.slug : item?.config?.slug;
        data['slug'] = slug;
        graphData?.data
          ?.slice(0)
          .reverse()
          ?.map((x, i) => {
            if (i === 0) {
              [...new Array(4)].forEach((d, m) => {
                let year = graphData?.millis?.slice(0).reverse()[i];
                let property = `${moment(new Date(year))
                  .local()
                  .format('MMM')}_${moment(new Date(year))
                  .local()
                  .format('YYYY')}`;
                if (m === 0) {
                  data[property] = Math.round(x);
                } else {
                  graphData?.past_3_months_data?.map((pastData, pastIndex) => {
                    let date = graphData?.past_3_months_millis[pastIndex];
                    let property = `${moment(new Date(date))
                      .local()
                      .format('MMM')}_${moment(new Date(date))
                      .local()
                      .format('YYYY')}`;
                    data[property] = Math.round(pastData);
                  });
                }
              });
            } else {
              let year = graphData?.millis?.slice(0).reverse()[i];
              let property = `${moment(new Date(year))
                .local()
                .format('MMM')}_${moment(new Date(year))
                .local()
                .format('YYYY')}`;
              data[property] = Math.round(x);
            }
          });
        data['col_type'] = 'values';
        data['indices_name'] =
          timeseriesTableData?.length === 1 ? props?.selectedParam?.name : item.config.layerType;
        table?.rowData?.push(data);
      });
      if (table && table?.rowData?.length) {
        if (
          table?.rowData[0]?.indices === ALERT_TABLE_INDICES.SPI &&
          table?.rowData[1]?.indices === ALERT_TABLE_INDICES.DRY_SPELL
        ) {
          table.rowData = table?.rowData?.reverse();
        }
      }
      setAlertTableData(table);
    } else {
      setAlertTableData([]);
    }
  }, [tableData, getDroughtTimeseriesData]);

  useEffect(() => {
    if (
      !_.isEmpty(checkDroughtConditonToggle) &&
      checkDroughtConditonToggle[0]?.checked &&
      isShowImpactIndicators
    ) {
      setIsDrought(false);
      setIsShowImpactIndicators(false);
    }
  }, [checkDroughtConditonToggle]);

  useEffect(() => {
    if (!_.isEmpty(selectedMonth) && !_.isEmpty(selectedYear)) {
      let indicesList = checkedIndicesList?.find(item => item.name === DSS_MODULE_CONSTS.MANDATORY)
        ?.indicesList;
      let impactIndicesList = checkedIndicesList?.find(
        item => item.name === DSS_MODULE_CONSTS.IMPACT,
      )?.indicesList;
      if (
        indicesList?.length &&
        !impactIndicesList?.length &&
        (_.isEmpty(finalDroughtMap) || !finalDroughtMap[0]?.checked) &&
        (_.isEmpty(checkDroughtConditonToggle) || !checkDroughtConditonToggle[0]?.checked)
      ) {
        setShowPreviewData(true);
      } else {
        setShowPreviewData(false);
      }

      if (
        !checkDroughtConditonToggle?.length ||
        (checkDroughtConditonToggle?.length &&
          (!checkDroughtConditonToggle[0]?.checked ||
            (isShowImpactIndicators && checkDroughtConditonToggle[0]?.checked)))
      ) {
        if (indicesList?.length || impactIndicesList?.length) {
          setIsApplyLayers(true);
        } else {
          setIsApplyLayers(false);
        }

        if (finalDroughtMap?.length && finalDroughtMap[0]?.checked) {
          setIsApplyLayers(true);
        }
      } else {
        setIsApplyLayers(false);
      }
      setTableData([]);
      setAlertTableData([]);
    } else {
      setShowPreviewData(false);
      setIsApplyLayers(false);
    }
  }, [
    selectedMonth,
    selectedYear,
    checkedIndicesList,
    checkDroughtConditonToggle,
    finalDroughtMap,
    props.drySpellPrecipitationThreshold,
    props.isSelectedRegion?.region,
    selectedDroughtPhase,
    selectedMonsoon,
    selectedLocation?.country,
    selectedLocation?.state,
    selectedLocation?.district,
  ]);

  useEffect(() => {
    if (!_.isEmpty(selectedMonsoon)) {
      let active_phase_months = renderReactSelectOptionFormat(
        selectedMonsoon?.active_phase?.months,
      );
      let alert_phase_months = renderReactSelectOptionFormat(selectedMonsoon?.alert_phase?.months);
      setMonthsList({ active_phase: active_phase_months, alert_phase: alert_phase_months });
      setSelectedMonth([]);
      setSelectedMonthList([]);
      setYearsList([]);
      setSelectedYear('');
      setSelectedDroughtPhase(DSS_DROUGHT_PHASES_CONST[0]);
    } else {
      setMonthsList({ active_phase: [], alert_phase: [] });
    }
  }, [selectedMonsoon]);

  useEffect(() => {
    if (!_.isEmpty(selectedDroughtPhase) && !_.isEmpty(selectedMonsoon)) {
      props.handleDroughtPhase(selectedDroughtPhase);
    }
  }, [selectedDroughtPhase, selectedMonsoon]);

  useEffect(() => {
    if (!_.isEmpty(selectedDroughtPhase) && !_.isEmpty(selectedMonsoon)) {
      setIsShowImpactIndicators(false);
      setIsDrought(false);
    }
  }, [selectedDroughtPhase, selectedMonsoon, selectedYear, selectedMonth]);

  useEffect(() => {
    if (isDrought) {
      let impactData = { id: 'Impact', name: 'Impact', category: 'Impact' };
      let droughtConsts = JSON.parse(JSON.stringify(DroughtModuleConsts));
      droughtConsts.push(impactData);
      setDroughtModuleConsts(droughtConsts);
    } else {
      setDroughtModuleConsts(DSS_DROUGHT_MODULE_INDICATORS);
    }
  }, [isDrought]);

  useEffect(() => {
    if (!_.isEmpty(selectedDroughtPhase)) {
      if (selectedDroughtPhase?.id === 'Alert') {
        setSelectedMonthList(monthsList.alert_phase);
      } else {
        setSelectedMonthList(monthsList.active_phase);
      }
      setSelectedMonth([]);
      setYearsList([]);
      setSelectedYear('');
    }
  }, [selectedDroughtPhase, monthsList]);

  useEffect(() => {
    if (selectedMonthList?.length) {
      setSelectedMonth(selectedMonthList[0]);
    }
  }, [selectedMonthList]);

  useEffect(() => {
    if (!_.isEmpty(selectedMonth)) {
      let currentMonth = new Date().getMonth() + 1;
      let filteredSelectedMonth = MONTHS.filter(x => x.label === selectedMonth.label);
      if (filteredSelectedMonth[0]?.value < currentMonth) {
        setYearsList(getYearsListFormat(2000, new Date().getFullYear()));
      } else {
        setYearsList(getYearsListFormat(2000, new Date().getFullYear() - 1));
      }
      setSelectedYear('');
    }
  }, [selectedMonth]);

  useEffect(() => {
    if (yearsList?.length) {
      setSelectedYear(yearsList[0]);
    }
  }, [yearsList]);

  const handleDroughtCheck = () => {
    let selectedParam = '';
    let compareSelectedParam = '';
    let start_date = returnDSSDateFormat(selectedYear, selectedMonth)?.startDate;
    let end_date = returnDSSDateFormat(selectedYear, selectedMonth)?.endDate;
    mandatoryIndices?.map(item => {
      if (item?.name !== 'Check Drought') {
        item?.parameter?.map((x, index) => {
          if (!_.isEmpty(x) && index === 0) selectedParam = x;
          if (!_.isEmpty(x) && index === 1) compareSelectedParam = x;
        });
      }
    });
    setIsThresholdDataLoading(true);
    props.setDSSTimeSeriesData([]);
    setIsCheckedDroughtCondition(true);
    handleChartDisplay({
      selectedParam,
      compareSelectedParam,
      start_date,
      end_date,
      isCompare: true,
      isDroughtCheck: true,
    });
  };

  const getUserLocationData = async () => {
    const response = await axios.post(myConstClass.GEO_LOCATION_USER_LOCATION_URL, {
      considerIp: true,
    });
    if (response?.status === 200) {
      const cordinates = response?.data?.location;
      getAddress(cordinates?.lat, cordinates?.lng, { fromCurrentLocation: false });
    }
  };

  // const getAddress = (lat, lng) => {
  //   Geocode.fromLatLng(lat, lng).then(
  //     response => {
  //       let country;
  //       const { address_components: components } = response.results[0];
  //       for (let i = 0; i < components.length; i++) {
  //         for (let j = 0; j < components[i].types.length; j++) {
  //           if (components[i].types[j] === ADDRESS_CONSTS.COUNTRY) {
  //             country = components[i].long_name;
  //             setCurrenUserLocation(country);
  //             getUserLocationBasedOnIP(country);
  //           }
  //         }
  //       }
  //     },
  //     error => {
  //       console.error(error);
  //     },
  //   );
  // };

  const getAddress = (lat, lng, { fromCurrentLocation }) => {
    if (isNumber(lat) && isNumber(lng) && fromCurrentLocation) {
      // setCordinates({ lat, lng });

      setCordinateAndZoom(prev => {
        return {
          ...prev,
          cordinates: [lat, lng],
          zoom: 10,
          searchMarkerCoordinates: [lat, lng],
        };
      });
    }
    Geocode.fromLatLng(lat, lng).then(
      response => {
        let country, state, district;
        const { address_components: components } = response.results[0];
        for (let i = 0; i < components.length; i++) {
          for (let j = 0; j < components[i].types.length; j++) {
            if (components[i].types[j] === ADDRESS_CONSTS.COUNTRY) {
              country = components[i].long_name;
              // if (fromCurrentLocation) props.handleCountry(country);
              // else {
              //   setCurrenUserLocation(country);
              //   getUserLocationBasedOnIP(country);
              // }
            }
            if (components[i].types[j] === ADDRESS_CONSTS.STATE) {
              state = components[i].long_name;
            }
            if (components[i].types[j] === ADDRESS_CONSTS.DISTRICT) {
              district = components[i].long_name;
            }
          }
        }
        if (fromCurrentLocation) {
          setSearchLocation(`${district}, ${state}, ${country}`);
          // props.handleAdvancedSearch({ state: state });
          // props.handleAdvancedSearch({ district: district });
        }
      },
      error => {
        console.error(error);
      },
    );
  };

  const handleMonsoonChange = monsoon => {
    setSelectedMonsoon(monsoon);
  };

  const handleSelectedDroughtPhase = phase => {
    setSelectedDroughtPhase(phase);
  };

  const handleModalToggle = () => {
    if (showPreviewData) {
      if (_.isEmpty(alertTableData)) {
        handleChartDisplay({ isDroughtCheck: false, isPreviewIndicesData: true });
      } else {
        props.setIsOpenAlertIndicesTable(!props.isOpenAlertIndicesTable);
      }
    }
  };

  const handleSelectedMonth = month => {
    setSelectedMonth(month);
  };

  const handleOnYearChange = year => {
    setSelectedYear(year);
  };

  const handleDroughtPhaseModal = () => {
    setIsShowDroughtPhaseModal(!isShowDroughtPhaseModal);
  };

  const handleAlertToggle = () => {
    props.setIsOpenAlertIndicesTable(false);
  };

  const handleMandatoryIndices = data => {
    setMandatoryIndices(data);
  };

  //dds indicators data
  useEffect(() => {
    if (!_.isEmpty(selectedMonsoon)) {
      let active_months = renderReactSelectOptionFormat(selectedMonsoon?.active_phase?.months);
      let monthsDropdown = JSON.parse(JSON.stringify(impactMonthsDropdownList));
      let updatedMonthsDropdown = monthsDropdown?.map((item, index) => {
        let x = JSON.parse(JSON.stringify(item));
        if (index == 2) {
          x.options = [active_months[active_months.length - 1]];
        } else if (index == 1) {
          x.options =
            active_months.length > 2
              ? [active_months[1], active_months[2]]
              : [active_months[0], active_months[1]];
        } else {
          x.options = [active_months[0], active_months[1]];
        }

        x.selectedOption =
          active_months.length - 1 < index
            ? active_months[active_months.length - 1]
            : active_months[index];
        return x;
      });
      if (updatedMonthsDropdown?.length) {
        setImpactMonthsDropdownList(updatedMonthsDropdown);
      }
    }
  }, [selectedMonsoon]);

  useEffect(() => {
    if (impactMonthsDropdownList?.every(i => i.options?.length)) {
      let data = _.cloneDeep(finalDroughtParameterMonthsList);
      let updatedData = '';
      let active_months = renderReactSelectOptionFormat(selectedMonsoon?.active_phase?.months);
      let alert_months = renderReactSelectOptionFormat(selectedMonsoon?.alert_phase?.months);
      if (data?.length) {
        updatedData = data?.map(item => {
          let itemData = _.cloneDeep(item);
          let selectedImpactData = impactMonthsDropdownList?.find(i => i.id === item.category);
          if (item?.slug === 'smci') {
            let monthsList = [];
            monthsList.push(alert_months[alert_months?.length - 1]);
            monthsList.push(active_months[0]);
            if (monthsList?.length) {
              itemData.options = monthsList;
              itemData.selectedOption = monthsList[0];
            }
          } else if (selectedImpactData) {
            itemData.options = selectedImpactData?.options;
            itemData.selectedOption = selectedImpactData?.options[0];
          }
          return itemData;
        });
      }
      if (updatedData) setFinalDroughtParameterMonthsList(updatedData);
    }
  }, [impactMonthsDropdownList]);

  useEffect(() => {
    if (!_.isEmpty(DSSDroughtIndicators)) {
      const { data } = DSSDroughtIndicators;
      let mandatoryData = [];
      let impactData = [];
      let paramsList = [];
      let count = 0;
      let categoriesList = JSON.parse(JSON.stringify(handleCategory));

      data?.result?.map(item => {
        let mandatoryCategoryData = Object.assign({}, item);
        mandatoryCategoryData.category = DSS_MODULE_CONSTS.MANDATORY;
        let impactCategoryData = Object.assign({}, item);
        impactCategoryData.category = DSS_MODULE_CONSTS.IMPACT;

        let mandatoryParamData = item?.parameter?.filter(
          x => x.mandatory === true && x.status === true,
        );
        let updatedMandatoryParamData = mandatoryParamData?.map(i => {
          i.label = i.name;
          i.checked = false;
          i.disabled = true;
          return i;
        });
        if (mandatoryParamData?.length) {
          categoriesList.push({
            name: item.name,
            id: item.name,
            category: DSS_MODULE_CONSTS.MANDATORY,
            checked: true,
          });
          mandatoryCategoryData.parameter = updatedMandatoryParamData;
          mandatoryData.push(mandatoryCategoryData);
        }

        let impactParamData = item?.parameter?.filter(x => x.impact === true && x.status === true);
        let updatedImpactParamData = impactParamData?.map(i => {
          i.label = i.name;
          i.checked = false;
          i.disabled = true;
          return i;
        });
        if (impactParamData?.length) {
          categoriesList.push({
            name: item.name,
            id: item.name,
            category: DSS_MODULE_CONSTS.IMPACT,
            checked: true,
          });
          impactCategoryData.parameter = updatedImpactParamData;
          impactData.push(impactCategoryData);
        }

        item?.parameter?.map(param => {
          if ((param.impact || param.mandatory) && param.status) {
            paramsList.push({ name: param.name, id: param.id, checked: false });
          }
        });
      });
      setDroughtIndicators({
        mandatory: mandatoryData,
        impact: impactData,
      });
      props.handleDroughtIndicators(DroughtIndicators);
      setCompareDroughtIndicators({
        mandatory: mandatoryData,
        impact: impactData,
      });
      setHandleCategory(categoriesList);
      setCompareCategory(categoriesList);
      setHandleCompareIndices(paramsList);
      setHandleIndices(paramsList);
      handleMandatoryIndices(mandatoryData);
      setCheckedIndicesList(DSS_INITAL_PARAMS_LIST);
    } else {
      setDroughtIndicators({
        mandatory: [],
        impact: [],
      });
      setCompareDroughtIndicators({
        mandatory: [],
        impact: [],
      });
      handleMandatoryIndices([]);
      setCheckedIndicesList(DSS_INITAL_PARAMS_LIST);
    }
  }, [DSSDroughtIndicators, selectedDroughtPhase]);

  /**
   * changed by: drakshayani and sai krishna
   * reason: to deactivate check drought on alert phase and activate on active phase
   * date: 10-sep-2022
   */
  useEffect(() => {
    if (!_.isEmpty(DroughtIndicators)) {
      let mandatoryList = _.cloneDeep(DroughtIndicators?.mandatory);
      let updatedMandatoryList = [];
      if (
        selectedDroughtPhase?.id === 'Alert' &&
        mandatoryList?.some(item => item.name === DSS_MODULE_CONSTS.CHECK_DROUGHT_CONDITION)
      ) {
        if (mandatoryList?.length) {
          updatedMandatoryList = mandatoryList?.filter(
            item => item.name !== DSS_MODULE_CONSTS.CHECK_DROUGHT_CONDITION,
          );
          if (updatedMandatoryList?.length) {
            setDroughtIndicators(prev => {
              return {
                ...prev,
                mandatory: updatedMandatoryList,
              };
            });
          }
        }
      } else if (
        selectedDroughtPhase?.id === 'Active' &&
        mandatoryList?.every(item => item.name !== DSS_MODULE_CONSTS.CHECK_DROUGHT_CONDITION)
      ) {
        if (!_.isEmpty(checkDroughtConditonToggle)) {
          let clonedCheckDroughtToggle = _.cloneDeep(checkDroughtConditonToggle);
          clonedCheckDroughtToggle[0].checked = false;
          setCheckDroughtConditionToggle(clonedCheckDroughtToggle);
        }
        let checkDroughtSwitch = {
          api_slug: '',
          checked: false,
          disabled: false,
          drought_indices_category_name: DSS_MODULE_CONSTS.CHECK_DROUGHT_CONDITION,
          id: DSS_MODULE_CONSTS.CHECK_DROUGHT_CONDITION,
          impact: false,
          label: DSS_MODULE_CONSTS.CHECK_DROUGHT_CONDITION,
          mandatory: true,
          min_date: '',
          name: 'Check Drought',
          slug: 'checkdroughtcondition',
          paramIndex: '',
          status: true,
        };
        let CheckDroughtObj = {
          name: DSS_MODULE_CONSTS.CHECK_DROUGHT_CONDITION,
          category: DSS_MODULE_CONSTS.MANDATORY,
          parameter: [],
        };
        CheckDroughtObj?.parameter?.push(checkDroughtSwitch);
        if (CheckDroughtObj) {
          mandatoryList?.push(CheckDroughtObj);
          setDroughtIndicators(prev => {
            return {
              ...prev,
              mandatory: mandatoryList,
            };
          });
        }

        const handleCategoryList = _.cloneDeep(handleCategory);
        if (
          handleCategoryList?.findIndex(
            item => item.name === DSS_MODULE_CONSTS.CHECK_DROUGHT_CONDITION,
          ) < 0
        ) {
          handleCategoryList?.push({
            category: 'Mandatory',
            checked: true,
            id: DSS_MODULE_CONSTS.CHECK_DROUGHT_CONDITION,
            name: DSS_MODULE_CONSTS.CHECK_DROUGHT_CONDITION,
          });
          setHandleCategory(handleCategoryList);
        }
      }
    }
  }, [DroughtIndicators, selectedDroughtPhase]);
  useEffect(() => {
    if (!_.isEmpty(finalDroughtMap)) {
      handleFinalDroughtMap(finalDroughtMap);
    }
  }, [finalDroughtMap]);

  useEffect(() => {
    if (!_.isEmpty(DroughtIndicators?.impact) && !_.isEmpty(DroughtIndicators?.mandatory)) {
      handleDroughtIndicatorsUpdate(
        DroughtIndicators?.impact,
        DroughtIndicators?.mandatory,
        checkedIndicesList,
        finalDroughtMap,
        checkDroughtConditonToggle,
        selectedYear,
        selectedMonth,
        { indices: {} },
        isShowImpactIndicators,
      );
    }
  }, [selectedMonth, selectedYear]);

  useEffect(() => {
    if (!_.isEmpty(DroughtIndicators.mandatory) && checkedIndicesList?.length) {
      let checkedList = checkedIndicesList?.find(item => item.name === DSS_MODULE_CONSTS.MANDATORY);
      let finalCheckedList = checkedList;
      if (checkedList?.indicesList?.length > 1) {
        let dates = returnDSSDateFormat(selectedYear, selectedMonth);
        handleDates(dates.startDate, dates.endDate);
        handleCompareDates(dates.startDate, dates.endDate);
      } else if (checkedList?.indicesList?.length) {
        let updatedFinalCheckList = { ...checkedList, indicesList: [] };
        let updatedIndicesList = [];
        let checkedImpactList = checkedIndicesList?.find(
          item => item.name === DSS_MODULE_CONSTS.IMPACT,
        );
        if (
          checkedImpactList?.indicesList?.length &&
          checkedImpactList?.indicesList?.every(i => !i.disabled)
        ) {
          let firstParam = checkedImpactList?.indicesList?.findIndex(i => i.checked);
          if (firstParam >= 0) {
            updatedIndicesList[0] = checkedImpactList?.indicesList[firstParam];
            let firstIndices = impactMonthsDropdownList.find(
              item =>
                item.id ===
                checkedImpactList?.indicesList[firstParam]?.drought_indices_category_name,
            );
            if (firstIndices) {
              let initialDates = returnDSSDateFormat(selectedYear, firstIndices?.selectedOption);
              if (initialDates) handleDates(initialDates.startDate, initialDates.endDate);
            }
            updatedIndicesList[1] = checkedList?.indicesList[0];
            let dates = returnDSSDateFormat(selectedYear, selectedMonth);
            handleCompareDates(dates.startDate, dates.endDate);
            updatedFinalCheckList['indicesList'] = updatedIndicesList;
            if (updatedFinalCheckList?.indicesList?.length)
              finalCheckedList = updatedFinalCheckList;
          }
        } else {
          let dates = returnDSSDateFormat(selectedYear, selectedMonth);
          handleDates(dates.startDate, dates.endDate);
        }
      }
      handleSelectedDssParams(finalCheckedList, selectedMonth, selectedYear);
      const timeseriesDates = renderDSSAlertTableDateFormat(selectedYear, selectedMonth);
      setDSSTabularDate(prev => {
        return {
          ...prev,
          startDate: timeseriesDates.startDate,
          endDate: timeseriesDates?.endDate,
        };
      });
    }
  }, [DroughtIndicators.mandatory, DroughtIndicators.impact, selectedMonth, selectedYear]);

  useEffect(() => {
    if (!_.isEmpty(DroughtIndicators.impact) && checkedIndicesList?.length) {
      let checkedList = checkedIndicesList?.find(item => item.name === DSS_MODULE_CONSTS.IMPACT);
      let initialDates = '';
      let finalDates = '';
      let finalCheckedList = checkedList;
      if (checkedList?.indicesList?.length > 1) {
        let firstIndices = impactMonthsDropdownList.find(
          item => item.id === checkedList?.indicesList[0]?.drought_indices_category_name,
        );
        let secondIndices = impactMonthsDropdownList.find(
          item => item.id === checkedList?.indicesList[1]?.drought_indices_category_name,
        );
        if (firstIndices) {
          initialDates = returnDSSDateFormat(selectedYear, firstIndices?.selectedOption);
          if (initialDates) handleDates(initialDates.startDate, initialDates.endDate);
        }
        if (secondIndices) {
          finalDates = returnDSSDateFormat(selectedYear, secondIndices?.selectedOption);
          if (finalDates) handleCompareDates(finalDates.startDate, finalDates.endDate);
        }
      } else {
        if (finalDroughtMap?.length && finalDroughtMap[0]?.checked) {
          let firstIndices = impactMonthsDropdownList.find(
            item => item.id === DSS_MODULE_CONSTS.FINAL_DROUGHT_MAP,
          );
          if (firstIndices) {
            initialDates = returnDSSDateFormat(selectedYear, firstIndices?.selectedOption);
            if (initialDates) handleDates(initialDates.startDate, initialDates.endDate);
          }
        } else if (checkedList?.indicesList?.length) {
          let firstIndices = impactMonthsDropdownList.find(
            item => item.id === checkedList?.indicesList[0]?.drought_indices_category_name,
          );
          let updatedFinalCheckList = { ...checkedList, indicesList: [] };
          let updatedIndicesList = [];
          let checkedMandatoryList = checkedIndicesList?.find(
            item => item.name === DSS_MODULE_CONSTS.MANDATORY,
          );
          if (
            checkedMandatoryList?.indicesList?.length &&
            checkedMandatoryList?.indicesList?.every(i => !i.disabled)
          ) {
            let firstParam = checkedMandatoryList?.indicesList?.findIndex(i => i.checked);
            if (firstParam >= 0) {
              updatedIndicesList.push(checkedMandatoryList?.indicesList[firstParam]);
              let dates = returnDSSDateFormat(selectedYear, selectedMonth);
              handleDates(dates.startDate, dates.endDate);
              if (firstIndices) {
                initialDates = returnDSSDateFormat(selectedYear, firstIndices?.selectedOption);
                if (initialDates) handleCompareDates(initialDates.startDate, initialDates.endDate);
                updatedIndicesList.push(checkedList?.indicesList[0]);
              }
              updatedFinalCheckList['indicesList'] = updatedIndicesList;
              if (updatedFinalCheckList?.indicesList?.length)
                finalCheckedList = updatedFinalCheckList;
            }
          } else if (firstIndices) {
            initialDates = returnDSSDateFormat(selectedYear, firstIndices?.selectedOption);
            if (initialDates) handleDates(initialDates.startDate, initialDates.endDate);
          }
        }
      }
      handleSelectedDssParams(finalCheckedList, selectedMonth, selectedYear);
    }
  }, [
    DroughtIndicators.impact,
    DroughtIndicators.mandatory,
    selectedYear,
    selectedMonth,
    impactMonthsDropdownList,
    finalDroughtMap,
  ]);

  const handleSelectedDssParams = (checkedList, selectedMonth, selectedYear) => {
    let dssSelectedParam, dssCompareParam;
    if (checkedList?.indicesList?.length === 1) {
      dssSelectedParam = checkedList?.indicesList[0]?.disabled ? '' : checkedList?.indicesList[0];
    } else if (checkedList?.indicesList?.length > 1) {
      dssSelectedParam = checkedList?.indicesList[0]?.disabled ? '' : checkedList?.indicesList[0];
      dssCompareParam = checkedList?.indicesList[1]?.disabled ? '' : checkedList?.indicesList[1];
    }
    if (checkedList?.indicesList?.length && !_.isEmpty(selectedMonth) && !_.isEmpty(selectedYear)) {
      props.setIsFinalDroughtMap(false);
      setIsClickedOnPlus(checkedList?.indicesList?.length > 1 ? true : false);
      setSelectedParam(dssSelectedParam);
      setCompareParam(dssCompareParam);
    }
    handleFinalDroughtMap(finalDroughtMap);
  };

  useEffect(() => {
    if (isShowImpactIndicators) {
      if (DroughtIndicators && DroughtIndicators?.impact?.length) {
        let categoriesList = JSON.parse(JSON.stringify(handleCategory));
        let updatedList = categoriesList?.map(x => {
          if (x.name === DSS_MODULE_CONSTS.IMPACT && !x.checked) x.checked = true;
          return x;
        });
        if (updatedList?.length) {
          setHandleCategory(updatedList);
        }
      }
    }
  }, [isShowImpactIndicators]);

  useEffect(() => {
    if (isShowImpactIndicators) {
      let mandatoryList = _.cloneDeep(DroughtIndicators?.mandatory);
      let updatedMandatoryList = DroughtIndicators?.mandatory;
      updatedMandatoryList = mandatoryList?.map(item => {
        let itemData = _.cloneDeep(item);
        let parameter = item?.parameter?.map(val => {
          if (item.name === DSS_MODULE_CONSTS.CHECK_DROUGHT_CONDITION) {
            if (val.checked) {
              val.checked = false;
            }
          }
          return val;
        });
        itemData.parameter = parameter;
        return itemData;
      });
      const droughtCondition = checkDroughtConditonToggle?.map(obj => {
        if (obj.checked) {
          return { ...obj, checked: false };
        }
        return obj;
      });
      if (droughtCondition) setCheckDroughtConditionToggle(droughtCondition);
      setDroughtIndicators(prev => {
        return {
          ...prev,
          mandatory: updatedMandatoryList,
        };
      });
    }
  }, [isShowImpactIndicators]);

  useEffect(() => {
    if (DroughtIndicators?.mandatory) {
      let isMandatoryIndicesChecked = checkedIndicesList?.find(
        item => item.name === DSS_MODULE_CONSTS.MANDATORY,
      );
      let impactList = _.cloneDeep(DroughtIndicators?.impact);
      let updatedImpactList = DroughtIndicators?.impact;
      if (
        isMandatoryIndicesChecked?.indicesList?.length === 2 &&
        isMandatoryIndicesChecked?.indicesList?.every(i => !i.disabled)
      ) {
        updatedImpactList = impactList?.map(item => {
          let itemData = _.cloneDeep(item);
          let parameter = item?.parameter?.map(val => {
            if (!val.disabled) {
              val.disabled = true;
            }
            return val;
          });
          itemData.parameter = parameter;
          return itemData;
        });
        if (updatedImpactList?.length) {
          setDroughtIndicators(prev => {
            return {
              ...prev,
              impact: updatedImpactList,
            };
          });
        }
      }
    }
  }, [DroughtIndicators?.mandatory, checkedIndicesList]);

  useEffect(() => {
    if (DroughtIndicators?.impact) {
      let isImpactIndicesChecked = checkedIndicesList?.find(
        item => item.name === DSS_MODULE_CONSTS.IMPACT,
      );
      let mandatoryList = _.cloneDeep(DroughtIndicators?.mandatory);
      let updatedMandatoryList = DroughtIndicators?.mandatory;
      let impactList = _.cloneDeep(DroughtIndicators?.impact);
      let updatedImpactList = DroughtIndicators?.impact;
      if (
        isImpactIndicesChecked?.indicesList?.length === 2 &&
        isImpactIndicesChecked?.indicesList?.every(i => !i.disabled)
      ) {
        updatedMandatoryList = mandatoryList?.map(item => {
          let itemData = _.cloneDeep(item);
          let parameter = item?.parameter?.map(val => {
            if (item.name !== DSS_MODULE_CONSTS.CHECK_DROUGHT_CONDITION) {
              if (!val.disabled) {
                val.disabled = true;
              }
            }
            return val;
          });
          itemData.parameter = parameter;
          return itemData;
        });
        updatedImpactList = impactList?.map(item => {
          let itemData = _.cloneDeep(item);
          let parameter = item?.parameter?.map(val => {
            if (!val.disabled && !val.checked) {
              val.disabled = true;
            }
            return val;
          });
          itemData.parameter = parameter;
          return itemData;
        });
        setDroughtIndicators(prev => {
          return {
            ...prev,
            mandatory: updatedMandatoryList,
            impact: updatedImpactList,
          };
        });
      }
    }
  }, [checkedIndicesList]);

  useEffect(() => {
    if (DroughtIndicators?.impact) {
      let isImpactIndicesChecked = checkedIndicesList?.find(
        item => item.name === DSS_MODULE_CONSTS.IMPACT,
      );
      let isMandatoryIndicesChecked = checkedIndicesList?.find(
        item => item.name === DSS_MODULE_CONSTS.MANDATORY,
      );

      let mandatoryList = _.cloneDeep(DroughtIndicators?.mandatory);
      let updatedMandatoryList = DroughtIndicators?.mandatory;
      let impactList = _.cloneDeep(DroughtIndicators?.impact);
      let updatedImpactList = DroughtIndicators?.impact;
      if (
        isImpactIndicesChecked?.indicesList?.length === 1 &&
        isImpactIndicesChecked?.indicesList?.every(i => !i.disabled) &&
        isMandatoryIndicesChecked?.indicesList?.length === 1 &&
        isMandatoryIndicesChecked?.indicesList?.every(i => !i.disabled)
      ) {
        updatedMandatoryList = mandatoryList?.map(item => {
          let itemData = _.cloneDeep(item);
          let parameter = item?.parameter?.map(val => {
            if (item.name !== DSS_MODULE_CONSTS.CHECK_DROUGHT_CONDITION) {
              if (!val.disabled && !val.checked) {
                val.disabled = true;
              }
            }
            return val;
          });
          itemData.parameter = parameter;
          return itemData;
        });
        updatedImpactList = impactList?.map(item => {
          let itemData = _.cloneDeep(item);
          let parameter = item?.parameter?.map(val => {
            if (!val.disabled && !val.checked) {
              val.disabled = true;
            }
            return val;
          });
          itemData.parameter = parameter;
          return itemData;
        });
        setDroughtIndicators(prev => {
          return {
            ...prev,
            mandatory: updatedMandatoryList,
            impact: updatedImpactList,
          };
        });
      }
    }
  }, [checkedIndicesList]);

  useEffect(() => {
    if (finalDroughtMap?.length && finalDroughtMap[0]?.checked && !finalDroughtMap[0]?.disabled) {
      let mandatoryList = _.cloneDeep(DroughtIndicators?.mandatory);
      let updatedMandatoryList = DroughtIndicators?.mandatory;
      let impactList = _.cloneDeep(DroughtIndicators?.impact);
      let updatedImpactList = DroughtIndicators?.impact;
      updatedMandatoryList = mandatoryList?.map(item => {
        let itemData = _.cloneDeep(item);
        let parameter = item?.parameter?.map(val => {
          if (item.name !== DSS_MODULE_CONSTS.CHECK_DROUGHT_CONDITION) {
            if (!val.disabled) {
              val.disabled = true;
            }
          }
          return val;
        });
        itemData.parameter = parameter;
        return itemData;
      });
      updatedImpactList = impactList?.map(item => {
        let itemData = _.cloneDeep(item);
        let parameter = item?.parameter?.map(val => {
          if (item.name !== DSS_MODULE_CONSTS.FINAL_DROUGHT_MAP) {
            if (!val.disabled) {
              val.disabled = true;
            }
          }
          return val;
        });
        itemData.parameter = parameter;
        return itemData;
      });
      setDroughtIndicators(prev => {
        return {
          ...prev,
          mandatory: updatedMandatoryList,
          impact: updatedImpactList,
        };
      });
    }
  }, [finalDroughtMap]);

  useEffect(() => {
    if (selectedModule?.api_slug !== 'drought-decision-support') {
      if (selectedParam?.api_slug == SPI_GPM_CHIRPS_API_SLUG.SPI_CHIRPS_API_SLUG) {
        setInitialSelectedDate(prev => {
          return {
            ...prev,
            minStartDate: SPI_GPM_CHIRPS_MIN_DATES.SPI_CHIRPS,
            minEndDate: SPI_GPM_CHIRPS_MIN_DATES.SPI_CHIRPS,
          };
        });
      } else if (selectedParam?.api_slug == SPI_GPM_CHIRPS_API_SLUG.SPI_GPM_API_SLUG) {
        setInitialSelectedDate(prev => {
          return {
            ...prev,
            minStartDate: SPI_GPM_CHIRPS_MIN_DATES.SPI_GPM,
            minEndDate: SPI_GPM_CHIRPS_MIN_DATES.SPI_GPM,
          };
        });
      }
      if (selectedModule?.api_slug == 'drought-prediction') {
        let today = new Date();
        let minutes = today.getMinutes() + today.getHours() * 60;
        if (
          selectedParam?.api_slug === DROUGHT_PREDICTION_API_SLUGS.SPI ||
          selectedParam.api_slug === DROUGHT_PREDICTION_API_SLUGS.SRI
        ) {
          let spiSriMinDate = new Date(
            minutes > 810
              ? moment().format(DATE_FORMAT)
              : moment()
                  .subtract(1, 'days')
                  .format(DATE_FORMAT),
          );
          setInitialSelectedDate(prev => {
            return {
              ...prev,
              minStartDate:
                minutes > 810
                  ? moment().format(DATE_FORMAT)
                  : moment()
                      .subtract(1, 'days')
                      .format(DATE_FORMAT),
              minEndDate:
                minutes > 810
                  ? moment().format(DATE_FORMAT)
                  : moment()
                      .subtract(1, 'days')
                      .format(DATE_FORMAT),
              startDate:
                minutes > 810
                  ? moment().format(DATE_FORMAT)
                  : moment()
                      .subtract(1, 'days')
                      .format(DATE_FORMAT),
              maxStartDate: moment(spiSriMinDate)
                .add(9, 'days')
                .format(DATE_FORMAT),
              maxEndDate: moment(spiSriMinDate)
                .add(9, 'days')
                .format(DATE_FORMAT),
              endDate: moment(spiSriMinDate)
                .add(9, 'days')
                .format(DATE_FORMAT),
            };
          });
        } else {
          let npsmiMinDate = new Date(
            minutes > 690
              ? moment().format(DATE_FORMAT)
              : moment()
                  .subtract(1, 'days')
                  .format(DATE_FORMAT),
          );
          setInitialSelectedDate(prev => {
            return {
              ...prev,
              // minStartDate:moment().format(DATE_FORMAT)  ,
              // minEndDate:moment().format(DATE_FORMAT) ,
              // startDate:moment().format(DATE_FORMAT),
              minStartDate:
                minutes > 690
                  ? moment().format(DATE_FORMAT)
                  : moment()
                      .subtract(1, 'days')
                      .format(DATE_FORMAT),
              minEndDate:
                minutes > 690
                  ? moment().format(DATE_FORMAT)
                  : moment()
                      .subtract(1, 'days')
                      .format(DATE_FORMAT),
              startDate:
                minutes > 690
                  ? moment().format(DATE_FORMAT)
                  : moment()
                      .subtract(1, 'days')
                      .format(DATE_FORMAT),
              maxStartDate: moment(npsmiMinDate)
                .add(9, 'days')
                .format(DATE_FORMAT),
              maxEndDate: moment(npsmiMinDate)
                .add(9, 'days')
                .format(DATE_FORMAT),
              endDate: moment(npsmiMinDate)
                .add(9, 'days')
                .format(DATE_FORMAT),
            };
          });
        }
      }
    }
  }, [selectedParam]);

  useEffect(() => {
    if (selectedModule?.api_slug !== 'drought-decision-support') {
      if (compareParam?.api_slug == SPI_GPM_CHIRPS_API_SLUG.SPI_CHIRPS_API_SLUG) {
        setFinalSelectedDate(prev => {
          return {
            ...prev,
            minStartDate: SPI_GPM_CHIRPS_MIN_DATES.SPI_CHIRPS,
            minEndDate: SPI_GPM_CHIRPS_MIN_DATES.SPI_CHIRPS,
          };
        });
      } else if (compareParam?.api_slug == SPI_GPM_CHIRPS_API_SLUG.SPI_GPM_API_SLUG) {
        setFinalSelectedDate(prev => {
          return {
            ...prev,
            minStartDate: SPI_GPM_CHIRPS_MIN_DATES.SPI_GPM,
            minEndDate: SPI_GPM_CHIRPS_MIN_DATES.SPI_GPM,
          };
        });
      }
      if (selectedModule?.api_slug == 'drought-prediction') {
        let today = new Date();
        let minutes = today.getMinutes() + today.getHours() * 60;
        if (
          compareParam.api_slug === DROUGHT_PREDICTION_API_SLUGS.SPI ||
          compareParam.api_slug === DROUGHT_PREDICTION_API_SLUGS.SRI
        ) {
          let compareSpiSriMinDate = new Date(
            minutes > 810
              ? moment().format(DATE_FORMAT)
              : moment()
                  .subtract(1, 'days')
                  .format(DATE_FORMAT),
          );
          setFinalSelectedDate(prev => {
            return {
              ...prev,
              minStartDate:
                minutes > 810
                  ? moment().format(DATE_FORMAT)
                  : moment()
                      .subtract(1, 'days')
                      .format(DATE_FORMAT),
              minEndDate:
                minutes > 810
                  ? moment().format(DATE_FORMAT)
                  : moment()
                      .subtract(1, 'days')
                      .format(DATE_FORMAT),
              startDate:
                minutes > 810
                  ? moment().format(DATE_FORMAT)
                  : moment()
                      .subtract(1, 'days')
                      .format(DATE_FORMAT),
              maxStartDate: moment(compareSpiSriMinDate)
                .add(9, 'days')
                .format(DATE_FORMAT),
              maxEndDate: moment(compareSpiSriMinDate)
                .add(9, 'days')
                .format(DATE_FORMAT),
              endDate: moment(compareSpiSriMinDate)
                .add(9, 'days')
                .format(DATE_FORMAT),
            };
          });
        } else {
          let compareNpsmiMinDate = new Date(
            minutes > 690
              ? moment().format(DATE_FORMAT)
              : moment()
                  .subtract(1, 'days')
                  .format(DATE_FORMAT),
          );
          setFinalSelectedDate(prev => {
            return {
              ...prev,
              // minStartDate:moment().format(DATE_FORMAT)  ,
              // minEndDate:moment().format(DATE_FORMAT) ,
              // startDate:moment().format(DATE_FORMAT),
              minStartDate:
                minutes > 690
                  ? moment().format(DATE_FORMAT)
                  : moment()
                      .subtract(1, 'days')
                      .format(DATE_FORMAT),
              minEndDate:
                minutes > 690
                  ? moment().format(DATE_FORMAT)
                  : moment()
                      .subtract(1, 'days')
                      .format(DATE_FORMAT),
              startDate:
                minutes > 690
                  ? moment().format(DATE_FORMAT)
                  : moment()
                      .subtract(1, 'days')
                      .format(DATE_FORMAT),
              maxStartDate: moment(compareNpsmiMinDate)
                .add(9, 'days')
                .format(DATE_FORMAT),
              maxEndDate: moment(compareNpsmiMinDate)
                .add(9, 'days')
                .format(DATE_FORMAT),
              endDate: moment(compareNpsmiMinDate)
                .add(9, 'days')
                .format(DATE_FORMAT),
            };
          });
        }
      }
    }
  }, [compareParam]);

  const handleDroughtIndicatorsUpdate = (
    impactList,
    mandatoryList,
    checkedIndicesList,
    finalDroughtMap,
    checkDroughtConditonToggle,
    year,
    month,
    { indices },
    isShowImpactIndicators,
  ) => {
    let droughtList = JSON.parse(JSON.stringify(impactList));
    let checkedList = checkedIndicesList?.find(item => item.name === DSS_MODULE_CONSTS.IMPACT);
    let mandatoryCheckedList = checkedIndicesList?.find(
      item => item.name === DSS_MODULE_CONSTS.MANDATORY,
    );
    let updatedDroughtList = droughtList?.map(item => {
      let itemData = JSON.parse(JSON.stringify(item));
      let updatedData = JSON.parse(JSON.stringify(item));
      let parameter = itemData?.parameter?.map(x => {
        if (
          checkedList?.indicesList?.length === 1 &&
          checkedList?.indicesList?.every(i => !i.disabled) &&
          (!mandatoryCheckedList?.indicesList?.length ||
            (mandatoryCheckedList?.indicesList?.length &&
              mandatoryCheckedList?.indicesList?.every(i => i.disabled)))
        ) {
          if (x.disabled) x.disabled = false;
        } else if (!_.isEmpty(year) && !_.isEmpty(month)) {
          x.disabled = false;
        }

        if (
          item.name === DSS_MODULE_CONSTS.FINAL_DROUGHT_MAP &&
          (_.isEmpty(indices) ||
            (indices?.id !== DSS_MODULE_CONSTS.FINAL_DROUGHT_MAP &&
              indices?.id !== DSS_MODULE_CONSTS.CHECK_DROUGHT_CONDITION))
        ) {
          if (x.checked) {
            x.checked = false;
            finalDroughtMap[0].checked = false;
            setFinalDroughtMap(finalDroughtMap);
          }
        }
        if (
          item.name === DSS_MODULE_CONSTS.FINAL_DROUGHT_MAP &&
          !_.isEmpty(indices) &&
          indices?.id === DSS_MODULE_CONSTS.FINAL_DROUGHT_MAP
        ) {
          if (!_.isEmpty(finalDroughtMap)) {
            x.checked = finalDroughtMap[0]?.checked;
            setFinalDroughtMap(finalDroughtMap);
          }
        }
        return x;
      });
      updatedData.parameter = parameter;
      return updatedData;
    });

    let droughtMandatoryList = JSON.parse(JSON.stringify(mandatoryList));
    let updatedMandatoryList = droughtMandatoryList?.map(item => {
      let itemData = JSON.parse(JSON.stringify(item));
      let updatedItemData = JSON.parse(JSON.stringify(item));
      let parameter = itemData?.parameter?.map(x => {
        let checkedList = checkedIndicesList?.find(item => item.name === DSS_MODULE_CONSTS.IMPACT);
        if (
          !mandatoryCheckedList?.indicesList?.length &&
          (!checkedList?.indicesList?.length ||
            (checkedList?.indicesList?.length <= 1 &&
              checkedList?.indicesList?.every(i => !i.disabled)))
        ) {
          if (x.disabled) x.disabled = false;
        }
        if (
          item.name === DSS_MODULE_CONSTS.CHECK_DROUGHT_CONDITION &&
          (_.isEmpty(indices) || indices?.id === DSS_MODULE_CONSTS.CHECK_DROUGHT_CONDITION)
        ) {
          if (!_.isEmpty(checkDroughtConditonToggle)) {
            x.checked = checkDroughtConditonToggle[0]?.checked;
            setCheckDroughtConditionToggle(checkDroughtConditonToggle);
          }
        }
        if (checkedList?.indicesList?.length) {
          //  if (!x.disabled) x.disabled = true;
          updatedDroughtList = updatedDroughtList?.map(item => {
            let itemData = JSON.parse(JSON.stringify(item));
            itemData.parameter = item?.parameter?.map(x => {
              if (
                item.name === DSS_MODULE_CONSTS.FINAL_DROUGHT_MAP &&
                !_.isEmpty(indices) &&
                indices?.id === DSS_MODULE_CONSTS.FINAL_DROUGHT_MAP
              ) {
                if (!_.isEmpty(finalDroughtMap)) {
                  x.checked = finalDroughtMap[0]?.checked;
                  // x.disabled = finalDroughtMap[0]?.disabled;
                  setFinalDroughtMap(finalDroughtMap);
                }
              }
              return x;
            });

            return itemData;
          });
        } else if (!_.isEmpty(year) && !_.isEmpty(month)) {
          x.disabled = false;
        }
        // else {
        //   x.disabled = true;
        // }
        return x;
      });
      updatedItemData.parameter = parameter;
      return updatedItemData;
    });

    if (updatedDroughtList?.length) {
      setDroughtIndicators(prev => {
        return {
          ...prev,
          impact: updatedDroughtList,
          mandatory: updatedMandatoryList,
        };
      });
    }
  };
  const handleFinalDroughtMap = finalDroughtMap => {
    if (!_.isEmpty(finalDroughtMap)) {
      if (finalDroughtMap[0]?.checked && !finalDroughtMap[0]?.disabled) {
        setSelectedParam(finalDroughtMap[0]);
        props.setIsFinalDroughtMap(true);
      } else {
        props.setIsFinalDroughtMap(false);
      }
    }
  };

  const handleCategoryToggle = (indicatorItem, categoryArr, isCompare) => {
    let categoryList = JSON.parse(JSON.stringify(categoryArr));
    let indicatorList;
    const index = _.findIndex(categoryList, function(list) {
      if (list.name === indicatorItem.name && list.category === indicatorItem.category) {
        return true;
      } else {
        return false;
      }
    });

    if (
      indicatorItem.name === DSS_MODULE_CONSTS.MANDATORY ||
      indicatorItem.name === DSS_MODULE_CONSTS.IMPACT
    ) {
      indicatorList = handleDefaultCategoryToggle(
        indicatorItem.category,
        categoryList[index].checked,
        categoryList,
      );
    } else {
      if (index >= 0) {
        categoryList[index] = {
          ...categoryList[index],
          checked: !categoryList[index].checked,
        };
      }
      indicatorList = categoryList;
    }
    if (isCompare) {
      setCompareCategory(indicatorList);
    } else {
      setHandleCategory(indicatorList);
    }
  };
  const handleDroughtIndices = indices => {
    let checkedList = JSON.parse(JSON.stringify(checkedIndicesList));
    let updatedList = '';
    let droughtData = '';
    let mandatory = indices?.mandatory ? true : indices?.impact ? false : '';
    let updatedCheckedList = '';
    let updatedFinalDataList = [];

    if (mandatory) {
      droughtData = _.cloneDeep(DroughtIndicators.mandatory);
    } else {
      droughtData = _.cloneDeep(DroughtIndicators.impact);
    }

    if (indices?.id === DSS_MODULE_CONSTS.FINAL_DROUGHT_MAP) {
      updatedList = finalDroughtMap?.length ? JSON.parse(JSON.stringify(finalDroughtMap)) : [];
    } else if (indices?.id === DSS_MODULE_CONSTS.CHECK_DROUGHT_CONDITION) {
      updatedList = checkDroughtConditonToggle?.length
        ? JSON.parse(JSON.stringify(checkDroughtConditonToggle))
        : [];
    } else {
      updatedList = checkedList?.find(x => x.mandatory === mandatory)?.indicesList;
    }

    let droughtArr = droughtData.map((x, index) => {
      let data = JSON.parse(JSON.stringify(x));
      let updatedData = JSON.parse(JSON.stringify(x));
      let parameter = data?.parameter?.map(i => {
        if (i.id === indices.id) {
          if (updatedList?.length && updatedList?.some(x => x.id === i.id)) {
            if (
              indices?.id === DSS_MODULE_CONSTS.FINAL_DROUGHT_MAP ||
              indices?.id === DSS_MODULE_CONSTS.CHECK_DROUGHT_CONDITION
            ) {
              updatedFinalDataList = _.cloneDeep(updatedList);
              updatedFinalDataList = updatedList?.map(item => {
                let itemData = _.cloneDeep(item);
                itemData.checked = !indices.checked;
                i.checked = !indices.checked;
                return itemData;
              });
            } else {
              i.checked = false;
              updatedList = updatedList.filter(item => item.id !== i.id);
            }
          } else {
            if (
              indices?.id === DSS_MODULE_CONSTS.FINAL_DROUGHT_MAP ||
              indices?.id === DSS_MODULE_CONSTS.CHECK_DROUGHT_CONDITION
            ) {
              i.checked = true;
              updatedFinalDataList.push(i);
            } else {
              i.checked = true;
              updatedList.push(i);
            }
          }
        }
        return i;
      });
      updatedData.parameter = parameter;
      return updatedData;
    });

    if (
      indices?.id !== DSS_MODULE_CONSTS.FINAL_DROUGHT_MAP &&
      indices?.id !== DSS_MODULE_CONSTS.CHECK_DROUGHT_CONDITION
    ) {
      updatedCheckedList = checkedList?.map(item => {
        let x = JSON.parse(JSON.stringify(item));
        if (x.mandatory === mandatory) {
          x.indicesList = updatedList;
        }
        return x;
      });
      if (updatedCheckedList?.length) {
        setCheckedIndicesList(updatedCheckedList);
      }
    } else if (indices?.id === DSS_MODULE_CONSTS.CHECK_DROUGHT_CONDITION) {
      setCheckDroughtConditionToggle(updatedFinalDataList);
    } else {
      setFinalDroughtMap(updatedFinalDataList);
    }
    if (indices?.id === DSS_MODULE_CONSTS.FINAL_DROUGHT_MAP) {
      handleDroughtIndicatorsUpdate(
        mandatory ? DroughtIndicators.impact : droughtArr,
        mandatory ? droughtArr : DroughtIndicators.mandatory,
        checkedIndicesList,
        updatedFinalDataList,
        checkDroughtConditonToggle,
        selectedYear,
        selectedMonth,
        { indices },
        isShowImpactIndicators,
      );
    } else if (indices?.id === DSS_MODULE_CONSTS.CHECK_DROUGHT_CONDITION) {
      handleDroughtIndicatorsUpdate(
        mandatory ? DroughtIndicators.impact : droughtArr,
        mandatory ? droughtArr : DroughtIndicators.mandatory,
        checkedIndicesList,
        finalDroughtMap,
        updatedFinalDataList,
        selectedYear,
        selectedMonth,
        { indices },
        isShowImpactIndicators,
      );
    } else {
      handleDroughtIndicatorsUpdate(
        mandatory ? DroughtIndicators.impact : droughtArr,
        mandatory ? droughtArr : DroughtIndicators.mandatory,
        updatedCheckedList,
        finalDroughtMap,
        checkDroughtConditonToggle,
        selectedYear,
        selectedMonth,
        { indices },
        isShowImpactIndicators,
      );
    }
  };

  const handleDefaultCategoryToggle = (category, isChecked, categoryList) => {
    categoryList?.map((item, index) => {
      if (item.category === category) {
        categoryList[index] = {
          ...categoryList[index],
          checked: !isChecked,
        };
      }
    });
    return categoryList;
  };

  const handleImpactDropdownChange = (value, data) => {
    let dropdownList = JSON.parse(JSON.stringify(impactMonthsDropdownList));
    let updatedDropdownList = dropdownList?.map(item => {
      let x = JSON.parse(JSON.stringify(item));
      if (x.id === data.id) {
        x.selectedOption = value;
      }
      return x;
    });
    if (updatedDropdownList?.length) {
      setImpactMonthsDropdownList(updatedDropdownList);
    }
  };

  const handleTemporalAggregation = selectedOption => {
    setTemporalAggregation(selectedOption);
  };

  //handling selected data from selector and setting to state
  const handleSelectedItem = (newValue, action) => {
    setSearchLocation('');
    if (action.name === 'country') {
      handleOptions(countryList, newValue, action);
      setCountry(newValue);
      setCompareCountry(newValue);
      setState(null);
      setSelectedCompareDistrict(null);
      setSelectedDistrict(null);
      setCompareState(null);
      if (newValue.name === SOUTH_ASIA_CONST.name) {
        setSelectedRegion(regionOptions);
        setStateList([]);
      }
    } else if (action.name === 'state') {
      setState(newValue);
      setSelectedDistrict(null);
      if (compareCountry?.name === country?.name) {
        setCompareState(newValue);
        setSelectedCompareDistrict(null);
      }
      // setCompareState(newValue);
      // setSelectedCompareDistrict(null);
      if (newValue.value === '') {
        setDistrictList([]);
        setSelectedOption(selectedLocation?.country);
      }
      handleOptions(stateList, newValue, action);
    } else if (action.name === 'district') {
      handleOptions(districtList, newValue, action);
      setSelectedDistrict(newValue);
      if (newValue.value === '') setSelectedOption(selectedLocation?.state);
      if (compareCountry?.name === country?.name && compareState?.name === state?.name) {
        setSelectedCompareDistrict(newValue);
      }
      // setSelectedCompareDistrict(newValue);
    }
    setSelectedLocation(prev => {
      return {
        ...prev,
        [action.name]: newValue,
      };
    });
    if (country?.name === compareCountry?.name) {
      setCompareLocation(prev => {
        return {
          ...prev,
          [action.name]: newValue,
        };
      });
    }
  };

  //handling selected location for compare
  const handleCompareSelectedItem = (newValue, action) => {
    if (action.name === 'country') {
      //props.handleCompareSearch({ country: newValue.value });
      setCompareCountry(newValue);
      handleCompareOptions(countryList, newValue, action);
      setCompareState(null);
      setSelectedCompareDistrict(null);
      if (newValue.name === SOUTH_ASIA_CONST.name) {
        setCompareLocation(prev => {
          return {
            ...prev,
            [action.name]: newValue,
          };
        });
        setCompareStateList([]);
      }
      if (newValue.value === '') setCompareStateList([]);
    } else if (action.name === 'state') {
      setCompareState(newValue);
      setSelectedCompareDistrict(null);
      // props.handleCompareSearch({ state: newValue.value });
      if (newValue.value === '') setCompareDistrictList([]);
      handleCompareOptions(compareStateList, newValue, action);
    } else if (action.name === 'district') {
      // props.handleCompareSearch({ district: newValue.value });
      handleCompareOptions(compareDistrictList, newValue, action);
      setSelectedCompareDistrict(newValue);
    }
  };

  const handleStartDate = e => {
    let d1 = new Date(e.target.value);
    setInitialSelectedDate(prevdate => {
      return {
        ...prevdate,
        startDate: e.target.value,
        // minEndDate: moment(new Date(e.target.value)).add(1, 'days').format(DATE_FORMAT),
      };
    });
    /**TODO: Need to check dates validation later */
    if (d1.getTime() === new Date(initialSelectedDate.endDate).getTime()) {
      setError(true);
      setErrorMessage('start date and end date should not be equal ');
    } else {
      setError(false);
      setErrorMessage('');
    }
  };

  const handleEndDate = e => {
    setInitialSelectedDate(prevdate => {
      return {
        ...prevdate,
        // maxStartDate: moment(new Date(e.target.value)).subtract(1, 'days').format(DATE_FORMAT),
        endDate: e.target.value,
      };
    });
    let d1 = new Date(initialSelectedDate.startDate);
    let d2 = new Date(e.target.value);
    /**TODO: Need to check dates validation later */
    if (d1.getTime() === d2.getTime()) {
      setError(true);
      setErrorMessage(t('DROUGHT_MONITORING.DROUGHT_DATE_COMPARISON'));
    } else {
      setError(false);
      setErrorMessage('');
    }
  };

  const handleOptions = (options, newValue, action) => {
    if (options && options.length) {
      if (newValue?.value) {
        let selectedOption = options.find(item => item.name === newValue.value);
        if (selectedOption) {
          setSelectedOption(selectedOption);
        } else {
          setSelectedOption('');
        }
      }
    }
  };
  const handleApplyLayers = () => {
    if (parseInt(tempState?.max) < parseInt(tempState.min)) {
      setMinError(true);
      return;
    }
    setMinError(false);
    props.handleApplyLayers(true);
  };

  const setCompareToggle = () => {
    if (!props?.isSelectedRegion?.region?.length) {
      setIsClickedOnPlus(!isClickedOnPlus);
    }
  };

  const handleFinalStartDate = e => {
    let d1 = new Date(e.target.value);
    /**TODO: Need to check dates validation later */
    if (d1.getTime() === new Date(finalSelectedDate.endDate).getTime()) {
      setCompareError({ showError: true, msg: 'start date and end date should not be equal ' });
    } else {
      setCompareError({ showError: false, msg: '' });
    }
    setFinalSelectedDate(prevdate => {
      return {
        ...prevdate,
        startDate: e.target.value,
        //minEndDate: moment(e.target.value).add(1, 'days').format(DATE_FORMAT),
      };
    });
  };

  const handleFinalEndDate = e => {
    let d1 = new Date(e.target.value);
    /**TODO: Need to check dates validation later */
    if (d1.getTime() === new Date(finalSelectedDate.startDate).getTime()) {
      setCompareError({ showError: true, msg: 'start date and end date should not be equal ' });
    } else {
      setCompareError({ showError: false, msg: '' });
    }
    setFinalSelectedDate(prevdate => {
      return {
        ...prevdate,
        //maxStartDate: moment(e.target.value).subtract(1, 'days').format(DATE_FORMAT),
        endDate: e.target.value,
      };
    });
  };

  const renderCompareDates = isCompare => {
    let isGraphCompare = isCompare.find(item => item.name === 'graph');
    if (!isGraphCompare.selected) {
      return (
        <>
          <Input
            type="date"
            placeholder="Start Date"
            bsSize="sm"
            className="mt-3 my-3 input-style-width"
            /**newly selected dates are not updating because of value not changing*/
            // value={
            //   compareParam.api_slug === DROUGHT_PREDICTION_API_SLUGS.SPI ||
            //   compareParam.api_slug === DROUGHT_PREDICTION_API_SLUGS.SRI
            //     ? handleSpiSriDate()
            //     : finalSelectedDate?.startDate
            // }
            value={finalSelectedDate?.startDate}
            onChange={handleFinalStartDate}
            //min={compareParam?.min_date || finalSelectedDate.minStartDate}
            min={finalSelectedDate.minStartDate}
            max={finalSelectedDate.maxStartDate}
            disabled={_.isEmpty(compareParam)}
          />
          <Input
            type="date"
            placeholder="End Date"
            bsSize="sm"
            className="mt-1 my-3 input-style-width"
            value={finalSelectedDate.endDate}
            onChange={handleFinalEndDate}
            min={finalSelectedDate?.minEndDate}
            max={finalSelectedDate?.maxEndDate}
            disabled={_.isEmpty(compareParam)}
          />
          {compareError?.showError && (
            <div className="drought-monitor-error-msg">
              <span style={{ float: 'left' }}>{compareError?.msg}</span>
            </div>
          )}
        </>
      );
    }
  };

  const handleCompareOptions = (options, newValue, action) => {
    if (options && options.length) {
      if (newValue.value) {
        let selectedOption = options.find(item => item.name === newValue.value);
        if (selectedOption) {
          if (action.name === 'country') {
            //   setDefaultCompareSelectedOption(selectedOption);
            setCompareLocation(prev => {
              return {
                [action.name]: selectedOption,
              };
            });
          } else {
            setCompareLocation(prev => {
              return {
                ...prev,
                [action.name]: selectedOption,
              };
            });
          }
          setCompareSelectedOption(selectedOption);
        }
      } else {
        // setDefaultCompareSelectedOption('');
        setCompareLocation(prev => {
          return {
            [action.name]: '',
          };
        });
        setCompareSelectedOption('');
      }
    }
  };

  const handleCompareToggle = ({ target }) => {
    let compareData = isCompare;
    const nextCompare = compareData.map(item => {
      if (item.value === target.value) {
        return {
          ...item,
          selected: target.checked,
        };
      } else {
        return {
          ...item,
          selected: false,
        };
      }
    });
    setIsCompare(nextCompare);
  };
  const handleCompareLocationRender = () => {
    let isGraphCompare = isCompare.find(item => item.name === 'graph' && item.selected);
    if (isGraphCompare) {
      return (
        <>
          <div className="pt-2  mb-3">
            <span className="orangeColor mt-2">
              {!_.isEmpty(countryData) && countryData?.country_name !== SOUTH_ASIA_COUNTRY
                ? 'Select State'
                : 'Select Region'}
            </span>
            <div
              className="mt-2 mb-3 padding-dropdown dm-compare-country"
              style={
                !_.isEmpty(countryData) && countryData?.country_name !== SOUTH_ASIA_COUNTRY
                  ? { display: 'none' }
                  : {}
              }
            >
              <Select
                id="multi-select"
                name="country"
                placeholder={TRANSLATIONS.FILTER.SELECT_COUNTRY}
                options={renderAPIParameterOptions(countryList, 'country')}
                onChange={handleCompareSelectedItem}
                isMulti={false}
                required={true}
                value={compareLocation?.country}
                // defaultValue={compareLocation?.country}
                isDisabled={
                  !_.isEmpty(countryData) && countryData?.country_name !== SOUTH_ASIA_COUNTRY
                }
                // isDisabled={!_.isEmpty(countryData)}
              />
            </div>
            {compareCountry && compareCountry?.name !== SOUTH_ASIA_CONST.name && (
              <div className="mt-2 mb-3 padding-dropdown dm-compare-state">
                <Select
                  id="multi-select"
                  name="state"
                  placeholder="State"
                  options={renderAPIParameterOptions(compareStateList)}
                  onChange={handleCompareSelectedItem}
                  isMulti={false}
                  required={true}
                  value={compareState}
                />
              </div>
            )}
            {compareState && compareDistrictList?.length ? (
              <div className="mt-2 mb-3 padding-dropdown dm-compare-district">
                <Select
                  id="multi-select"
                  name="district"
                  placeholder="District"
                  options={renderAPIParameterOptions(compareDistrictList)}
                  onChange={handleCompareSelectedItem}
                  isMulti={false}
                  required={true}
                  value={selectedCompareDistrict}
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </>
      );
    }
  };

  //used to add more date field on click of + icon
  const addMoreDateFieldWhenClickedOnPlus = () => {
    return (
      <>
        <span
          className="cursor-ponter orangeColor date-style"
          onClick={() => setIsClickedOnPlus(!isClickedOnPlus)}
        >
          <BiMinus size={20} />
          <span className="mx-2">{TRANSLATIONS.FILTER.COMPARE}</span>
        </span>
        <span>
          <FormGroup check>
            <Row className="compare-style">
              {isCompare.map(item => {
                return (
                  <>
                    <Col sm={4} lg={4} md={4} className="mt-2">
                      <Label className="label-item" check>
                        <Input
                          type="radio"
                          name="radio1"
                          value={item.value}
                          defaultChecked={item.selected}
                          onChange={handleCompareToggle}
                        />{' '}
                        {item.value}
                      </Label>
                    </Col>{' '}
                  </>
                );
              })}
            </Row>
          </FormGroup>
        </span>

        {handleCompareLocationRender()}
        <p className="orangeColor mt-3">Select Indicators</p>

        {selectedModule.api_slug === 'drought-monitor' ? (
          <DroughtIndicatorsCollapse
            DroughtIndicesData={DroughtIndicesData}
            drySpellPrecipitationThreshold={props.compareDrySpellPrecipitationThresold}
            setDrySpellPrecipitationThreshold={props.setCompareDrySpellPrecipitationThresold}
            setMinStartDate={setMinStartDate}
            initialSelectedDate={initialSelectedDate}
            setInitialSelectedDate={setInitialSelectedDate}
            setSelectedParam={setSelectedParam}
            setCompareParam={setCompareParam}
            isSubCategory={false}
            isCategory={true}
            isCompare={true}
            selectedModule={selectedModule}
            setFinalSelectedDate={setFinalSelectedDate}
          />
        ) : selectedModule.api_slug === 'drought-monitor-v2' ||
          selectedModule.api_slug === 'drought-prediction' ? (
          <DroughtMonitorOtherIndicesCollapse
            DroughtIndicesData={DroughtIndicesData}
            compareDroughtIndicesData={compareDroughtIndicesData}
            selectedModule={selectedModule}
            precipitationThreshold={props.comparePrecipitationThreshold}
            setPrecipitationThreshold={props.setComparePrecipitationThreshold}
            droughtPredictionIndicesMonths={droughtPredictionIndicesMonths}
            setSelectedDPIndicesMonth={props.setCompareSelectedDPIndicesMonth}
            selectedDPIndicesMonth={props.compareSelectedDPIndicesMonth}
            setMinStartDate={setMinStartDate}
            setSelectedParam={setSelectedParam}
            setCompareParam={setCompareParam}
            isCompare={true}
            setFinalSelectedDate={setFinalSelectedDate}
            setInitialSelectedDate={setInitialSelectedDate}
          />
        ) : (
          <div>
            <p>No Indicators available</p>
          </div>
        )}
        {compareParam?.id === INDICES_PARAMS?.RAINFALL_ANAMOLY?.id ? (
          <div>
            <div className="mx-3">
              <Select
                placeholder="Select Start Year"
                className="my-3 filter-select-placeholder"
                type="select"
                onChange={handleCompareRainfallAnamolyStartYearChange}
                required={true}
                isMulti={false}
                value={compareRainfallAnamolyTime?.start_year}
                options={rainfallAnamolyTimeList?.compareYearList || []}
                id="start_year"
              />
              <Select
                placeholder="Select End Year"
                className="my-3 filter-select-placeholder"
                type="select"
                onChange={handleCompareRainfallAnamolyEndYearChange}
                required={true}
                isMulti={false}
                value={compareRainfallAnamolyTime?.end_year}
                options={rainfallAnamolyTimeList?.compareYearList || []}
                id="forecast_category"
              />
              <Select
                placeholder="Select Month"
                className="my-3 filter-select-placeholder"
                type="select"
                onChange={handleCompareRainfallAnamolyMonthChange}
                required={true}
                isMulti={false}
                value={compareRainfallAnamolyTime?.month}
                options={rainfallAnamolyTimeList?.compareMonthList || []}
                id="forecast_category"
              />
            </div>
            {compareRainfallAnamolyTime?.start_year &&
            compareRainfallAnamolyTime?.end_year &&
            compareRainfallAnamolyTime?.start_year?.label >
              compareRainfallAnamolyTime?.end_year?.label ? (
              yearError()
            ) : compareMinMaxYearError ? (
              <p className="text-center min-max-error">
                {t('DROUGHT_MONITORING.MIN_MAX_YEAR_EQUAL_ERROR')}
              </p>
            ) : (
              ''
            )}
          </div>
        ) : (
          renderCompareDates(isCompare)
        )}
      </>
    );
  };

  useEffect(() => {
    let isMapSubmit, isGraphSubmit;
    let mapPreferenceCount = mapPreferencesData?.length;
    let regionData = props.isSelectedRegion?.region;
    let minMaxYearError = false;
    let compareMinMaxYearError = false;
    let rainfallAnamolyParamsSelected = true;
    let compareRainfallAnamolyParamsSelected = true;
    if (selectedParam?.name === INDICES_PARAMS.RAINFALL_ANAMOLY.name) {
      if (rainfallAnamolyTime?.start_year?.label >= rainfallAnamolyTime?.end_year?.label) {
        minMaxYearError = true;
      } else minMaxYearError = false;
      if (
        rainfallAnamolyTime?.start_year &&
        rainfallAnamolyTime?.end_year &&
        rainfallAnamolyTime?.month
      ) {
        rainfallAnamolyParamsSelected = true;
      } else {
        rainfallAnamolyParamsSelected = false;
      }
    }
    if (compareParam?.id === INDICES_PARAMS.RAINFALL_ANAMOLY.id) {
      if (
        compareRainfallAnamolyTime?.start_year?.label >= compareRainfallAnamolyTime?.end_year?.label
      ) {
        compareMinMaxYearError = true;
      } else compareMinMaxYearError = false;
      if (
        compareRainfallAnamolyTime?.start_year &&
        compareRainfallAnamolyTime?.end_year &&
        compareRainfallAnamolyTime?.month
      ) {
        compareRainfallAnamolyParamsSelected = true;
      } else {
        compareRainfallAnamolyParamsSelected = false;
      }
    }
    if (regionData?.length) setShowRegionTooltip(true);
    else setShowRegionTooltip(false);
    if (!_.isEmpty(selectedParam) && !error) {
      if (mapPreferenceCount < 2 && rainfallAnamolyParamsSelected && !minMaxYearError) {
        if (!regionData?.length) {
          isMapSubmit = true;
        }
        isGraphSubmit = true;
      } else {
        if (
          !_.isEmpty(selectedLocation?.country) &&
          mapPreferenceCount < 2 &&
          rainfallAnamolyParamsSelected &&
          !minMaxYearError
        ) {
          if (!regionData?.length) {
            isMapSubmit = true;
          } else isMapSubmit = false;
          isGraphSubmit = true;
        } else {
          isMapSubmit = false;
          isGraphSubmit = false;
        }
      }
      if (isClickedOnPlus) {
        let isMap = isCompare?.some(item => item.name === 'map' && item.selected);
        let isGraph = isCompare?.some(item => item.name === 'graph' && item.selected);
        if (isMap) {
          isGraphSubmit = false;
          if (
            _.isEmpty(compareParam) ||
            compareError?.showError ||
            mapPreferenceCount === 2 ||
            !compareRainfallAnamolyParamsSelected ||
            compareMinMaxYearError ||
            !rainfallAnamolyParamsSelected ||
            minMaxYearError ||
            regionData?.length
          ) {
            isMapSubmit = false;
          } else {
            isMapSubmit = true;
          }
        }
        if (isGraph) {
          isMapSubmit = false;
          if (
            _.isEmpty(compareParam) ||
            !compareRainfallAnamolyParamsSelected ||
            compareMinMaxYearError ||
            !rainfallAnamolyParamsSelected ||
            minMaxYearError
          )
            isGraphSubmit = false;
          else isGraphSubmit = true;
        }
      }
    } else {
      isMapSubmit = false;
      isGraphSubmit = false;
    }
    setCompareMinMaxYearError(compareMinMaxYearError);
    setMinMaxYearError(minMaxYearError);
    setApplyLayers(isMapSubmit);
    setUpdateGraph(isGraphSubmit);
  }, [
    selectedParam,
    temporalAggregation,
    props?.isSelectedRegion,
    selectedLocation.country,
    isCompare,
    compareParam,
    isClickedOnPlus,
    error,
    compareError.showError,
    mapPreferencesData,
    compareRainfallAnamolyTime,
    rainfallAnamolyTime,
  ]);

  useEffect(() => {
    props.handleIsCompare(isClickedOnPlus);
    if (!isClickedOnPlus) {
      setIsCompare(DROUGHT_COMPARE_OPTIONS);
    }
  }, [isClickedOnPlus]);

  useEffect(() => {
    if (props?.isSelectedRegion?.region?.length) {
      setIsClickedOnPlus(false);
    }
  }, [props.isSelectedRegion]);

  useEffect(() => {
    if (!_.isEmpty(selectedParam) && INDICES_PARAMS?.RAINFALL_ANAMOLY?.id === selectedParam?.id) {
      let list = renderRainfallAnamolyYearsList(selectedParam);
      // let monthsList = renderMonthsList(MONTHS, rainfallAnamolyTime);
      setRainfallAnamolyTimeList(prev => {
        return {
          ...prev,
          yearList: list,
        };
      });
    }
  }, [selectedParam]);

  useEffect(() => {
    if (rainfallAnamolyTimeList?.yearList?.length) {
      if (rainfallAnamolyTimeList?.yearList?.length === 1) {
        handleRainfallAnamolyStartYearChange(rainfallAnamolyTimeList?.yearList[0]);
        handleRainfallAnamolyEndYearChange(rainfallAnamolyTimeList?.yearList[0]);
      } else if (rainfallAnamolyTimeList?.yearList?.length >= 1) {
        handleRainfallAnamolyStartYearChange(rainfallAnamolyTimeList?.yearList[1]);
        handleRainfallAnamolyEndYearChange(rainfallAnamolyTimeList?.yearList[0]);
      }
    }
  }, [rainfallAnamolyTimeList?.yearList]);

  useEffect(() => {
    if (rainfallAnamolyTime?.end_year) {
      let monthsList = renderMonthsList(MONTHS, rainfallAnamolyTime);
      setRainfallAnamolyTimeList(prev => {
        return {
          ...prev,
          monthList: monthsList,
        };
      });
    }
  }, [rainfallAnamolyTime?.end_year]);

  useEffect(() => {
    if (rainfallAnamolyTimeList?.monthList?.length) {
      handleRainfallAnamolyMonthChange(rainfallAnamolyTimeList?.monthList[0]);
    }
  }, [rainfallAnamolyTimeList?.monthList]);

  useEffect(() => {
    if (rainfallAnamolyTimeList?.compareMonthList?.length) {
      handleCompareRainfallAnamolyMonthChange(rainfallAnamolyTimeList?.compareMonthList[0]);
    }
  }, [rainfallAnamolyTimeList?.compareMonthList]);

  useEffect(() => {
    if (compareRainfallAnamolyTime?.end_year) {
      let monthsList = renderMonthsList(MONTHS, compareRainfallAnamolyTime);
      setRainfallAnamolyTimeList(prev => {
        return {
          ...prev,
          compareMonthList: monthsList,
        };
      });
    }
  }, [compareRainfallAnamolyTime?.end_year]);

  useEffect(() => {
    if (rainfallAnamolyTimeList?.compareYearList?.length) {
      if (rainfallAnamolyTimeList?.compareYearList?.length === 1) {
        handleCompareRainfallAnamolyStartYearChange(rainfallAnamolyTimeList?.compareYearList[0]);
        handleCompareRainfallAnamolyEndYearChange(rainfallAnamolyTimeList?.compareYearList[0]);
      } else if (rainfallAnamolyTimeList?.compareYearList?.length >= 1) {
        handleCompareRainfallAnamolyStartYearChange(rainfallAnamolyTimeList?.compareYearList[1]);
        handleCompareRainfallAnamolyEndYearChange(rainfallAnamolyTimeList?.compareYearList[0]);
      }
    }
  }, [rainfallAnamolyTimeList?.compareYearList]);

  useEffect(() => {
    if (!_.isEmpty(compareParam) && INDICES_PARAMS?.RAINFALL_ANAMOLY?.id === compareParam?.id) {
      let list = renderRainfallAnamolyYearsList(compareParam);
      // let monthsList = renderMonthsList(MONTHS, compareRainfallAnamolyTime);
      setRainfallAnamolyTimeList(prev => {
        return {
          ...prev,
          compareYearList: list,
        };
      });
    }
  }, [compareParam]);

  useEffect(() => {
    props.handleSelectedRegion(selectedRegion.value);
  }, [selectedRegion]);

  const onSubmitHandler = e => {
    e.preventDefault();
  };

  const handleDryspellThreshold = data => {
    if (data) {
      setThresholdDataList(prev => {
        return {
          ...prev,
          selectedDryspellThreshold: data,
        };
      });
    }
  };

  const handleFinalDroughtParamMonthChange = (slug, value) => {
    let data = _.cloneDeep(finalDroughtParameterMonthsList);
    let updatedData = data?.map(item => {
      let itemData = _.cloneDeep(item);
      if (item.slug === slug) {
        itemData.selectedOption = value;
      }
      return itemData;
    });
    if (updatedData) setFinalDroughtParameterMonthsList(updatedData);
  };

  const handleSPIThreshold = value => {
    if (value) {
      setThresholdDataList(prev => {
        return {
          ...prev,
          selectedSpiThreshold: value,
        };
      });
    }
  };

  const renderAPIParameterOptions = (list, action) => {
    if (list) {
      let params = [...list];
      let options = [];
      if (params.length) {
        let default_option =
          action === 'country' ? regionOptions : { name: 'select', label: 'Select', value: '' };
        options.push(default_option);
        params.map(param => {
          options.push(param);
          return true;
        });
        return options;
      }
    }
  };

  const handleChartDisplay = obj => {
    props.renderCharts(true, obj);
  };

  const minmaxError = () => {
    return (
      <p className="text-center min-max-error">
        {t('DROUGHT_MONITORING.DROUGHT_MIN_MAX_ERROR_MESSAGE')}
      </p>
    );
  };

  const yearError = () => {
    return (
      <p className="text-center min-max-error">{t('DROUGHT_MONITORING.MIN_MAX_YEAR_ERROR')}</p>
    );
  };

  const handleDates = (start, end) => {
    /**
     * changed by: sai krishna thalla
     * reason : after applying layers and changeing modules inital dates are not applying
     * date: 6-sep-2022
     */
    if (!start.includes(UNDEFINED) && !end.includes(UNDEFINED)) {
      setInitialSelectedDate(prev => {
        return {
          ...prev,
          startDate: start,
          endDate: end,
        };
      });
    }
  };

  const handleCompareDates = (start, end) => {
    if (!start.includes(UNDEFINED) && !end.includes(UNDEFINED)) {
      setFinalSelectedDate(prev => {
        return {
          ...prev,
          startDate: start,
          endDate: end,
        };
      });
    }
  };

  const renderRainfallAnamolyYearsList = selectedParam => {
    let current_date = new Date()?.getFullYear();
    let start_date = new Date(selectedParam?.min_date)?.getFullYear();
    let yearsList = getYearsListFormat(start_date, current_date);
    if (yearsList) return yearsList;
    else return [];
  };

  const handleSelectedModule = selectedOption => {
    setTempState(prev => {
      return {
        ...prev,
        min: null,
        max: null,
      };
    });
    let defaultSelectedAggregation = DROUGHT_TEMPORAL_AGGREGATION?.find(
      item => item.name === 'Mean',
    );
    if (!_.isEmpty(defaultSelectedAggregation)) {
      setTemporalAggregation(defaultSelectedAggregation);
    }
    setState(null);
    setSelectedDistrict(null);
    setCompareState(null);
    setSelectedCompareDistrict(null);
    const defaultCountry = countryList?.find(item => item.id === countryData.country_id);
    setCountry(defaultCountry);
    setCompareCountry(defaultCountry);
    setSelectedLocation(prev => {
      return {
        ...prev,
        country: defaultCountry,
        state: {},
        district: {},
      };
    });
    setCompareLocation(prev => {
      return {
        ...prev,
        country: defaultCountry,
        state: {},
        district: {},
      };
    });
    setSelectedOption(defaultCountry);
    setIsClickedOnPlus(false);

    setSelectedParam('');
    setCompareParam('');
    props.handleSelectedModule(selectedOption);
  };

  const handleSearch = places => {
    if (!_.isEmpty(places)) {
      const { formatted_address, geometry, address_components } = places;
      const lat = geometry?.location?.lat();
      const lng = geometry?.location?.lng();
      // if (isNumber(lat) && isNumber(lng)) {
      //   setCordinateAndZoom(prev => {
      //     return {
      //       ...prev,
      //       cordinates: [lat, lng],
      //       zoom: 10,
      //       searchMarkerCoordinates: [lat, lng],
      //     };
      //   });
      // }
      setSearchLocation(formatted_address);
      // setLocation(formatted_address);
    } else {
      setSearchLocation('');
    }
  };

  const handleOnPlaceChange = event => {
    if (event && event?.target && event?.target?.value === '') {
      setSearchLocation('');
    } else {
      setSearchLocation(event.target.value);
    }
  };

  const isLatLng = input => {
    // Regular expression for standard numerical lat,lng format with optional spaces
    const standardLatLngPattern = /^\s*-?\d+(\.\d+)?\s*,\s*-?\d+(\.\d+)?\s*$/;
    // Regular expression for degrees and direction format with optional spaces
    const degreesLatLngPattern = /^\s*-?\d+(\.\d+)?\s*°?\s*[NS]\s*,\s*-?\d+(\.\d+)?\s*°?\s*[EW]\s*$/;

    return standardLatLngPattern.test(input) || degreesLatLngPattern.test(input);
  };

  const onHandleRemoveLocation = () => {
    setSearchLocation('');
    setCordinateAndZoom(prev => {
      return {
        ...prev,

        searchMarkerCoordinates: '',
      };
    });
  };

  const handleFilter = async loc => {
    const islatlng = isLatLng(loc);

    if (islatlng) {
      const locArray = loc.split(',');
      const lat = parseCoordinate(locArray?.[0]);
      const lng = parseCoordinate(locArray?.[1]);
      if (locArray?.length === 2 && isNumber(parseFloat(lat)) && isNumber(parseFloat(lng))) {
        const point = turf.point([parseFloat(lng), parseFloat(lat)]);
        const isInsideAnyPolygon = countryJson.features.some(feature => {
          return turf.booleanPointInPolygon(point, feature);
        });
        if (isInsideAnyPolygon) {
          // setSearchLocation([parseFloat(lat), parseFloat(lng)]);
          setCordinateAndZoom(prev => {
            return {
              ...prev,
              cordinates: [lat, lng],
              zoom: 10,
              searchMarkerCoordinates: [lat, lng],
            };
          });
          // props.handleCordinates([parseFloat(lat), parseFloat(lng)], 10);
        } else {
          setSearchLocation('');
          showError(
            `Please select location inside ${selectedLocation?.country?.name} country only.`,
          );
        }
      } else {
        setSearchLocation();
        showError('Please enter valid data .');
      }
    } else {
      const responseData = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          JSON.stringify(loc),
        )}&key=AIzaSyAzWsZ_PyD0lshvP-sCwWP6d_1wzE0dYA0`,
      );
      if (responseData?.data?.results?.length) {
        const point = turf.point([
          parseFloat(responseData?.data?.results?.[0]?.geometry?.location?.lng),
          parseFloat(responseData?.data?.results?.[0]?.geometry?.location?.lat),
        ]);
        const isInsideAnyPolygon1 = countryJson.features.some(feature => {
          return turf.booleanPointInPolygon(point, feature);
        });

        if (!isInsideAnyPolygon1) {
          setSearchLocation('');
          showError(
            `Please select location inside ${selectedLocation?.country?.name} country only.`,
          );
          // setLocation('');
        } else {
          setCordinateAndZoom(prev => {
            return {
              ...prev,
              cordinates: [
                responseData?.data?.results?.[0]?.geometry?.location?.lat,
                responseData?.data?.results?.[0]?.geometry?.location?.lng,
              ],
              zoom: 10,
              searchMarkerCoordinates: [
                responseData?.data?.results?.[0]?.geometry?.location?.lat,
                responseData?.data?.results?.[0]?.geometry?.location?.lng,
              ],
            };
          });
        }
      } else {
        setSearchLocation('');
        showError(`Please select location inside ${selectedLocation?.country?.name} country only.`);
      }
    }
  };

  //map marker on the basis of selected country
  //get current location
  const getCurrentLocation = () => {
    if ('geolocation' in navigator) {
      let latitude = '';
      let longitude = '';
      /* geolocation is available */
      navigator.geolocation.getCurrentPosition(position => {
        latitude = position.coords.latitude;
        longitude = position.coords.longitude;
        getAddress(latitude, longitude, { fromCurrentLocation: true });
        // if (latitude && longitude)
        //   props.handleCordinates([latitude, longitude], 10);
      });
    } else {
      /* geolocation IS NOT available */
    }
  };

  const renderMonthsList = (months, anomalyTime) => {
    let finalSelectedYear = anomalyTime?.end_year;
    if (finalSelectedYear?.label === new Date().getFullYear()) {
      let currentMonth = new Date()?.getMonth() + 1;
      let monthsList = months.filter(item => parseInt(item?.value) <= currentMonth);
      if (monthsList) return monthsList;
    } else {
      return months;
    }
  };

  //we need to show current date if time passed 13:30 PM else previous date
  const handleSpiSriDate = () => {
    let today = new Date();
    let minutes = today.getMinutes() + today.getHours() * 60;
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();
    let showDate = '';
    if (minutes > 810) {
      /**
       * changed by: sai krishna thalla
       * reason : before and after 1:30pm the dates are not changing due to dates becoming 0 or undefined
       * date: 7-sep-2022
       */
      //showDate = `${yyyy}-${mm}-${dd}`;
      showDate = moment().format(DATE_FORMAT);
    } else {
      //showDate = `${yyyy}-${mm}-${dd - 1}`;
      showDate = moment()
        .subtract(1, 'days')
        .format(DATE_FORMAT);
    }
    return showDate;
  };
  return (
    <>
      {props?.isHideBar ? (
        <div className="expand-bar">
          <span className="cursor-ponter navyBlueColor ">
            {t('FILTER.EXPAND_BAR')}
            <span>
              <MdKeyboardArrowLeft
                className="hide-icon expand-margin"
                onClick={() => props.setBar(!props.isHideBar)}
                size={25}
              />
            </span>
          </span>
        </div>
      ) : (
        <div>
          <div className="filter-sidebar-section-drought-monitor drought-filter-section">
            <div className="filter-div">
              <Card className="card-style">
                <CardBody>
                  <Form onSubmit={onSubmitHandler} className="filter-control-dp">
                    <div className="d-flex justify-content-end cursor-ponter navyBlueColor mb-3">
                      {' '}
                      <span className="hidebar-show">{TRANSLATIONS.FILTER.HIDE_BAR}</span>{' '}
                      <span>
                        <MdKeyboardArrowRight
                          className="hide-icon hide-margin"
                          onClick={() => props.setBar(!props.isHideBar)}
                          size={25}
                        />
                      </span>
                    </div>{' '}
                    <div className="search-tab-wather-forecast">
                      <InputGroup>
                        <Autocomplete
                          apiKey={process.env.REACT_APP_LOCATION_API_KEY}
                          id="search-input-drought"
                          className="static-search-input"
                          // className={fromStaticResources ? 'static-search-input' : 'search-input'}
                          // ref={autoCompleteRef}
                          value={searchLocation}
                          onPlaceSelected={handleSearch}
                          onChange={handleOnPlaceChange}
                          placeholder="Search for a location"
                        />
                        {/* {searchLocation && ( */}
                        <div className="wf-search-icon weather-forecat-icons-drought">
                          <InputGroupAddon addonType="append">
                            <BsSearch
                              id="inputIcon"
                              onClick={() =>
                                searchLocation && countryJson ? handleFilter(searchLocation) : {}
                              }
                              // className="cursor-ponter mr-1 weather-forecat-icons-margin"
                              className={
                                countryJson
                                  ? 'cursor-ponter mr-1 weather-forecat-icons-margin'
                                  : ' mr-1  serch-opacity weather-forecat-icons-margin'
                              }
                              size={19}
                              color="#2A547C"
                            />

                            {searchLocation && countryJson ? (
                              <RxCross2
                                className="cursor-ponter "
                                size={19}
                                color="#656565"
                                onClick={() => onHandleRemoveLocation()}
                              />
                            ) : (
                              ''
                            )}
                          </InputGroupAddon>
                        </div>
                      </InputGroup>
                    </div>
                    {countryJson === '' ? (
                      <div className="loading-message">
                        Please wait, data is loading
                        <div className="loading-dots">
                          <span>.</span>
                          <span>.</span>
                          <span>.</span>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    {countryJson !== '' ? (
                      <div className="mb-3 mt-3 d-flex">
                        <div
                          className="cursor-ponter orangeColor mx-3"
                          onClick={getCurrentLocation}
                        >
                          {' '}
                          <BiCurrentLocation size={20} />
                        </div>
                        <div
                          className="mx-1 d-inline-block orangeColor choose-current-location cursor-ponter"
                          onClick={getCurrentLocation}
                        >
                          {TRANSLATIONS.FILTER.CHOOSE_CURRENT_LOCATION}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    <div className="advance-module-fields mb-2">
                      <p className="sidebar-module">Select Module</p>
                      <div className="mx-3 mb-2">
                        <Select
                          id="multi-select"
                          name="country"
                          placeholder="Select Modules"
                          options={moduleOptions}
                          onChange={handleSelectedModule}
                          isMulti={false}
                          value={selectedModule}
                          defaultValue={selectedModule}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="advance-search-fields mb-2">
                      <p className="sidebar-location">
                        {' '}
                        {!_.isEmpty(countryData) && countryData?.country_name !== SOUTH_ASIA_COUNTRY
                          ? 'Select State'
                          : 'Select Region'}
                      </p>
                      {/* <div className="mx-3 mb-3">
                        <Select
                          id="multi-select"
                          name="region"
                          placeholder="region"
                          options={regionOptions}
                          onChange={setSelectedRegion}
                          isMulti={false}
                          value={selectedRegion}
                          required={true}
                          isDisabled={true}
                        />
                      </div> */}
                      <div
                        className="mx-3 mb-3 dm-country"
                        style={
                          !_.isEmpty(countryData) &&
                          countryData?.country_name !== SOUTH_ASIA_COUNTRY
                            ? { display: 'none' }
                            : {}
                        }
                      >
                        <Select
                          name="country"
                          placeholder="Select Country"
                          options={renderAPIParameterOptions(countryList, 'country')}
                          onChange={handleSelectedItem}
                          isMulti={false}
                          value={selectedLocation.country}
                          // defaultValue={selectedLocation.country}
                          required={true}
                          // isDisabled={!_.isEmpty(countryData)}
                          isDisabled={
                            !_.isEmpty(countryData) &&
                            countryData?.country_name !== SOUTH_ASIA_COUNTRY
                          }
                        />
                      </div>
                      {country && selectedLocation?.country?.name !== SOUTH_ASIA_CONST.name && (
                        <div className="mx-3 mt-2 mb-3 dm-state">
                          <Select
                            name="state"
                            placeholder="Select State"
                            options={renderAPIParameterOptions(stateList)}
                            onChange={handleSelectedItem}
                            value={state}
                            isMulti={false}
                            required={true}
                          />
                        </div>
                      )}
                      {state && districtList?.length ? (
                        <div className="mx-3 mt-2 mb-3 dm-district">
                          <Select
                            name="district"
                            placeholder="District"
                            options={renderAPIParameterOptions(districtList)}
                            onChange={handleSelectedItem}
                            isMulti={false}
                            required={true}
                            value={selectedDistrict}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    <DSSDroughtSidebar
                      selectedLocation={selectedLocation}
                      selectedParam={selectedParam}
                      drySpellPrecipitationThreshold={props.drySpellPrecipitationThreshold}
                      selectedModule={selectedModule}
                      selectedDroughtPhase={selectedDroughtPhase}
                      cordinateAndZoom={props.cordinateAndZoom}
                      setDrySpellPrecipitationThreshold={setDrySpellPrecipitationThreshold}
                      handleDroughtIndicators={props.handleDroughtIndicators}
                      isShowDroughtCheckModal={props.isShowDroughtCheckModal}
                      weatherForecastData={weatherForecastData}
                      setIsShowDroughtCheckModal={props.setIsShowDroughtCheckModal}
                      isOpenAlertIndicesTable={props.isOpenAlertIndicesTable}
                      isOpenWeatherForecastDataModal={isOpenWeatherForecastDataModal}
                      setIsOpenWeatherForecastDataModal={setIsOpenWeatherForecastDataModal}
                      handleApplyLayers={props.handleApplyLayers}
                      isShowDroughtPhaseModal={isShowDroughtPhaseModal}
                      handleCategory={handleCategory}
                      DroughtModuleConsts={DroughtModuleConsts}
                      checkedIndicesList={checkedIndicesList}
                      handleDroughtPhase={props.handleDroughtPhase}
                      impactMonthsDropdownList={impactMonthsDropdownList}
                      alertTableData={alertTableData}
                      finalDroughtMap={finalDroughtMap}
                      monsoonList={monsoonList}
                      monthsList={monthsList}
                      selectedMonthList={selectedMonthList}
                      selectedMonth={selectedMonth}
                      setSelectedMonth={setSelectedMonth}
                      selectedMonsoon={selectedMonsoon}
                      yearsList={yearsList}
                      isDrought={isDrought}
                      setIsDrought={setIsDrought}
                      setYearsList={setYearsList}
                      selectedYear={selectedYear}
                      isApplyLayers={isApplyLayers}
                      showPreviewData={showPreviewData}
                      thresholdDataList={thresholdDataList}
                      finalDroughtParameterMonthsList={finalDroughtParameterMonthsList}
                      setThresholdDataList={setThresholdDataList}
                      isGetDroughtAllSeasonRequesting={isGetDroughtAllSeasonRequesting}
                      setShowPreviewData={setShowPreviewData}
                      setIsApplyLayers={setIsApplyLayers}
                      setSelectedYear={setSelectedYear}
                      setSelectedMonthList={setSelectedMonthList}
                      setMonthsList={setMonthsList}
                      setMonsoonList={setMonsoonList}
                      setSelectedDroughtPhase={setSelectedDroughtPhase}
                      setDSSDroughtIndicators={setDSSDroughtIndicators}
                      setDroughtModuleConsts={setDroughtModuleConsts}
                      setIsShowDroughtPhaseModal={setIsShowDroughtPhaseModal}
                      handleParams={props.handleParams}
                      setIsShowImpactIndicators={setIsShowImpactIndicators}
                      setSelectedMonsoon={setSelectedMonsoon}
                      DroughtIndicators={DroughtIndicators}
                      CompareDroughtIndicators={CompareDroughtIndicators}
                      handleCompareCategory={handleCompareCategory}
                      checkDroughtConditonToggle={checkDroughtConditonToggle}
                      handleAlertToggle={handleAlertToggle}
                      handleDroughtPhaseModal={handleDroughtPhaseModal}
                      handleMonsoonChange={handleMonsoonChange}
                      handleSelectedDroughtPhase={handleSelectedDroughtPhase}
                      handleSelectedMonth={handleSelectedMonth}
                      handleOnYearChange={handleOnYearChange}
                      handleModalToggle={handleModalToggle}
                      handleDroughtCheck={handleDroughtCheck}
                      handleCategoryToggle={handleCategoryToggle}
                      handleImpactDropdownChange={handleImpactDropdownChange}
                      handleDroughtIndices={handleDroughtIndices}
                      handleIndices={handleIndices}
                      handleFinalDroughtParamMonthChange={handleFinalDroughtParamMonthChange}
                      handleCompareIndices={handleCompareIndices}
                      DSSDroughtIndicators={DSSDroughtIndicators}
                      isSubCategory={false}
                      isCategory={true}
                      isCompare={false}
                      country={selectedLocation.country}
                      state={state}
                      district={selectedDistrict}
                      handleDryspellThreshold={handleDryspellThreshold}
                      handleSPIThreshold={handleSPIThreshold}
                      defaultDistrict={defaultDistrict}
                    />
                    <>
                      {selectedModule.api_slug !== 'drought-decision-support' ? (
                        <>
                          <p className="sidebar-indicator">Select Indicators </p>
                          <DroughtIndicatorsCollapse
                            history={props.history}
                            DroughtIndicesData={DroughtIndicesData}
                            setDrySpellPrecipitationThreshold={setDrySpellPrecipitationThreshold}
                            drySpellPrecipitationThreshold={drySpellPrecipitationThreshold}
                            setMinStartDate={setMinStartDate}
                            setSelectedParam={setSelectedParam}
                            setCompareParam={setCompareParam}
                            selectedParam={selectedParam}
                            isSubCategory={false}
                            isCategory={true}
                            isCompare={false}
                            selectedModule={selectedModule}
                            initialSelectedDate={initialSelectedDate}
                            setInitialSelectedDate={setInitialSelectedDate}
                          />
                          <DroughtMonitorOtherIndicesCollapse
                            history={props.history}
                            DroughtIndicesData={DroughtIndicesData}
                            compareDroughtIndicesData={compareDroughtIndicesData}
                            droughtPredictionIndicesMonths={droughtPredictionIndicesMonths}
                            selectedModule={selectedModule}
                            precipitationThreshold={props.precipitationThreshold}
                            setPrecipitationThreshold={setPrecipitationThreshold}
                            selectedDPIndicesMonth={props.selectedDPIndicesMonth}
                            setSelectedDPIndicesMonth={props.setSelectedDPIndicesMonth}
                            compareParam={compareParam}
                            setMinStartDate={setMinStartDate}
                            setSelectedParam={setSelectedParam}
                            selectedParam={selectedParam}
                            setCompareParam={setCompareParam}
                            isCompare={false}
                            setInitialSelectedDate={setInitialSelectedDate}
                            setFinalSelectedDate={setFinalSelectedDate}
                          />
                          {INDICES_PARAMS?.RAINFALL_ANAMOLY?.id === selectedParam?.id ? (
                            <>
                              <div className="mx-3">
                                <Select
                                  placeholder="Select Start Year"
                                  className="my-3 filter-select-placeholder"
                                  type="select"
                                  onChange={handleRainfallAnamolyStartYearChange}
                                  required={true}
                                  isMulti={false}
                                  value={rainfallAnamolyTime?.start_year}
                                  options={rainfallAnamolyTimeList?.yearList}
                                  id="start_year"
                                />
                                <Select
                                  placeholder="Select End Year"
                                  className="my-3 filter-select-placeholder"
                                  type="select"
                                  onChange={handleRainfallAnamolyEndYearChange}
                                  required={true}
                                  isMulti={false}
                                  value={rainfallAnamolyTime?.end_year}
                                  options={rainfallAnamolyTimeList?.yearList}
                                  id="forecast_category"
                                />
                                <Select
                                  placeholder="Select Month"
                                  className="my-3 filter-select-placeholder"
                                  type="select"
                                  onChange={handleRainfallAnamolyMonthChange}
                                  required={true}
                                  isMulti={false}
                                  value={rainfallAnamolyTime?.month}
                                  options={rainfallAnamolyTimeList?.monthList}
                                  id="forecast_category"
                                />
                              </div>
                              {rainfallAnamolyTime?.start_year?.label >
                              rainfallAnamolyTime?.end_year?.label ? (
                                yearError()
                              ) : minMaxYearError ? (
                                <p className="text-center min-max-error">
                                  {t('DROUGHT_MONITORING.MIN_MAX_YEAR_EQUAL_ERROR')}
                                </p>
                              ) : (
                                ''
                              )}
                            </>
                          ) : (
                            <>
                              <div className="date-style">
                                <Input
                                  type="date"
                                  name="startDate"
                                  placeholder="Start Date"
                                  bsSize="sm"
                                  min={initialSelectedDate.minStartDate}
                                  max={initialSelectedDate.maxStartDate}
                                  className="my-3 input-style-width"
                                  // value={
                                  //   selectedParam.api_slug === DROUGHT_PREDICTION_API_SLUGS.SPI ||
                                  //   selectedParam.api_slug === DROUGHT_PREDICTION_API_SLUGS.SRI
                                  //     ? handleSpiSriDate()
                                  //     : initialSelectedDate?.startDate
                                  // }
                                  value={initialSelectedDate?.startDate}
                                  onChange={e => handleStartDate(e)}
                                  disabled={_.isEmpty(selectedParam)}
                                />

                                <Input
                                  type="date"
                                  name="endDate"
                                  placeholder="End Date"
                                  bsSize="sm"
                                  className="my-2 input-style-width"
                                  value={initialSelectedDate?.endDate}
                                  onChange={e => handleEndDate(e)}
                                  min={initialSelectedDate.minEndDate}
                                  max={initialSelectedDate.maxEndDate}
                                  disabled={_.isEmpty(selectedParam)}
                                />
                              </div>
                            </>
                          )}
                          <div className="date-style">
                            <div className="drought-monitor-error-msg">
                              <span style={{ float: 'left' }}>{errorMessage}</span>
                            </div>

                            <div>
                              {isClickedOnPlus && !props.isSelectedRegion?.region?.length ? (
                                addMoreDateFieldWhenClickedOnPlus()
                              ) : (
                                <span
                                  className={
                                    props.isSelectedRegion?.region?.length
                                      ? 'orangeColor orangeFadeColor'
                                      : 'blue-compare cursor-ponter orangeColor'
                                  }
                                  onClick={setCompareToggle}
                                >
                                  <BiPlus size={20} />
                                  <span className="mx-2">{TRANSLATIONS.FILTER.COMPARE}</span>
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="mt-3 temp-style">
                            <div className="mx-3 mt-2 mb-3">
                              {OTHER_INDICES_API_INDICES_SLUGS.EXTREME_RAINFALL !==
                                selectedParam.api_slug &&
                                DM_DRY_SPELL_SLUG.DRY_SPELL !== selectedParam.api_slug &&
                                OTHER_INDICES_API_INDICES_SLUGS.EXTREME_RAINFALL !==
                                  compareParam.api_slug &&
                                DM_DRY_SPELL_SLUG.DRY_SPELL !== compareParam.api_slug && (
                                  <Select
                                    id="multi-select"
                                    name="aggregation"
                                    placeholder={TRANSLATIONS.FILTER.SELECT_TEMPORAL_AGGREGATION}
                                    options={temporalAggreagationOptions}
                                    value={temporalAggregation}
                                    onChange={handleTemporalAggregation}
                                    isMulti={false}
                                    required={true}
                                  />
                                )}
                            </div>
                            {!isClickedOnPlus && (
                              <Row>
                                <Col className="mx-3">
                                  {' '}
                                  <Input
                                    type="number"
                                    placeholder="Min."
                                    bsSize="sm"
                                    className="mt-1 mb-3 p-2 temp-input"
                                    value={tempState.min || 'Min'}
                                    onChange={e => {
                                      setTempState(prev => {
                                        return {
                                          ...prev,
                                          min: e.target.value,
                                        };
                                      });
                                    }}
                                  />
                                </Col>
                                <Col className="mx-3">
                                  <Input
                                    type="number"
                                    placeholder="Max."
                                    bsSize="sm"
                                    className="mt-1 mb-3 p-2 temp-input"
                                    value={tempState.max || 'Max'}
                                    onChange={e => {
                                      setTempState(prev => {
                                        return {
                                          ...prev,
                                          max: e.target.value,
                                        };
                                      });
                                    }}
                                  />
                                </Col>
                              </Row>
                            )}
                            {minError ? minmaxError() : ''}
                          </div>
                          <div className="mx-2 mt-2 filter-submit">
                            <div
                              className="apply-layers"
                              id="apply_layers"
                              data-tip
                              data-for="apply_layers"
                            >
                              <Button
                                className="navyBlueBgColor m-auto"
                                onClick={handleApplyLayers}
                                disabled={!applyLayers}
                              >
                                {TRANSLATIONS.FILTER.APPLY_LAYERS}
                              </Button>
                            </div>
                            <div className="update-graph mt-3">
                              {!(
                                selectedParam.api_slug ===
                                  OTHER_INDICES_API_INDICES_SLUGS.CROP_HARVEST ||
                                selectedParam.api_slug ===
                                  OTHER_INDICES_API_INDICES_SLUGS.MODIS_FLOOD ||
                                selectedParam.api_slug === OTHER_INDICES_API_INDICES_SLUGS.FLOOD
                              ) && (
                                <Button
                                  outline
                                  className="navyBlueBgColor-outline m-auto"
                                  onClick={() => handleChartDisplay({})}
                                  disabled={!updateGraph}
                                >
                                  {TRANSLATIONS.FILTER.VIEW_GRAPH}
                                </Button>
                              )}
                              {showRegionTooltip && props.isSelectedRegion?.region?.length ? (
                                <ReactTooltip
                                  id="apply_layers"
                                  effect="solid"
                                  place="top"
                                  type="info"
                                  className="apply-layer-tooltip-st"
                                >
                                  {TRANSLATIONS.FILTER.PLEASE_REMOVE_MARKERS}
                                </ReactTooltip>
                              ) : (
                                ''
                              )}
                              {showMapPreferenceTooltip && !showRegionTooltip ? (
                                <ReactTooltip
                                  id="apply_layers"
                                  effect="solid"
                                  place="top"
                                  type="info"
                                  className="apply-layer-tooltip-st"
                                >
                                  {TRANSLATIONS.FILTER.PLEASE_REMOVE_MAP_PREFERENCE_LAYER}
                                </ReactTooltip>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DroughtSidebar;

import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Breadcrumb as ReactstrapBreadcrumb, BreadcrumbItem } from 'reactstrap';

const Breadcrumb = ({ items }) => {
  const history = useHistory();

  const handleGoHome = item => {
    if (item === 'Home') {
      history.push('/drought-management-system?Country=mali');
    }
  };

  return (
    <ReactstrapBreadcrumb className="breadcrumb-container">
      {items.map((item, index) => (
        <BreadcrumbItem
          key={index}
          active={index === items.length - 1}
          onClick={() => handleGoHome(item)}
          className=" m-0 cursor-ponter"
          style={{ margin: 0 }}
        >
          {item}
        </BreadcrumbItem>
      ))}
    </ReactstrapBreadcrumb>
  );
};

Breadcrumb.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Breadcrumb;

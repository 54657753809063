import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-multi-lang';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Button from "reactstrap-button-loader";

const DeleteConfirmationModal = (props) => {
  const {
    className,
    isDeleteModalOpen,
    handleDeleteConfirm,
  } = props;
  const t = useTranslation();

  const nextProps = useSelector((state) => ({
    isManageCropsDeleteRequesting: state.ManageCrops.isManageCropsDeleteRequesting,
  }));


  const toggle = (value) => {
      if(value === 'no'){
        handleDeleteConfirm(false,'no');
      }
      else if(value === 'yes') {
        handleDeleteConfirm(false,'yes');
      }
    }
    const handleClose = () => {
        handleDeleteConfirm(false);
      }
      
    return (
    <div>
      <Modal isOpen={isDeleteModalOpen} toggle={toggle} className={className}>
        <ModalHeader toggle={handleClose} className="reason-modal-header">{t('PUBLIC_API_ADMIN.CONFIRMATION')}</ModalHeader>
        <ModalBody>
            <p className="confirmation-modal-text">{t('MANAGE_CROP.DELETE_MANAGE_CROPS')}</p>
        </ModalBody>
        <ModalFooter className="reason-modal">
          <Button color="secondary" onClick={() =>toggle('no')} className="cancel-button">{t('PUBLIC_API_ADMIN.NO')}</Button>{' '}
          <Button loading={nextProps.isManageCropsDeleteRequesting} color="primary" onClick={() => toggle('yes')} className="modal-reject-button">{t('PUBLIC_API_ADMIN.YES')}</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default DeleteConfirmationModal;
DeleteConfirmationModal.propTypes = {
    isDeleteModalOpen: PropTypes.bool,
    handleDeleteConfirm: PropTypes.func,
  };
// Footer.js
import React from 'react';
import { t } from 'react-multi-lang';
import { useHistory } from 'react-router-dom';

import { Col, Container, Row } from 'reactstrap';
import CGIAR_NEW from '../../assets/Images/cgiar_neww.svg';
import Twitter from '../../assets/Images/icons/akar-icons_twitter-fill.svg';
import Facebook from '../../assets/Images/icons/bx_bxl-facebook-circle.svg';
import Flickr from '../../assets/Images/icons/bx_bxl-flickr.svg';
import LinkedIn from '../../assets/Images/icons/bx_bxl-linkedin.svg';
import Youtube from '../../assets/Images/icons/bx_bxl-youtube.svg';
import Mail from '../../assets/Images/icons/fluent_mail-16-filled.svg';
import RSSFeed from '../../assets/Images/icons/ic_twotone-rss-feed.svg';
import IWMI_NEW from '../../assets/Images/iwmi_neww.svg';
import PUBLIC_ICAR_NEW_LOGO from '../../assets/Images/publicICARLogo.svg';
import { getToken, isLogedIn } from '../../Components/common/utils';
import myConstClass from '../../Constants';

const Footer = ({ backgroundColor }) => {
  let history = useHistory();
  const URLParams = new URLSearchParams(window.location.search);
  const queryCountry = URLParams.get('Country');

  return (
    <>
      {!isLogedIn() ? (
        <div className={`pre-footer ${backgroundColor}`}>
          <div className="pre-footer-content">
            <p className="contact-text">{t('HOME_PAGE.KEEP_IN_TOUCH')}</p>
            <span>
              {t('HOME_PAGE.REGISTER')}
              {!getToken() ? (
                <button
                  className="pre-footer-register-btn"
                  onClick={() => history.push('/register')}
                >
                  {t('HOME_PAGE.REGISTER_NOW')}
                </button>
              ) : null}
            </span>
          </div>
        </div>
      ) : null}
      <div className="footer-section">
        <Container fluid className="footer-container">
          <Row className="align-items-center">
            <Col md="6" className="d-flex px-0">
              <img src={IWMI_NEW} alt="Logos" className="logos" />
              <img src={CGIAR_NEW} alt="Logos" className="logos" />
              {queryCountry === 'india' ? (
                <img src={PUBLIC_ICAR_NEW_LOGO} alt="Logos" className="logos ml-5" />
              ) : null}
            </Col>
            <Col md="6" className="">
              <h5 className="follow-us text-center">Follow Us</h5>
              <div className="social-icons d-flex justify-content-end">
                <a href={myConstClass.IWMI_FB_URL} target="_blank" rel="noreferrer">
                  <img src={Facebook} alt="Facebook" />
                </a>
                <a href={myConstClass.IWMI_FLICKR_URL} target="_blank" rel="noreferrer">
                  <img src={Flickr} alt="Flickr" />
                </a>
                <a href={myConstClass.IWMI_LINKEDIN_URL} target="_blank" rel="noreferrer">
                  <img src={LinkedIn} alt="LinkedIn" />
                </a>
                <a href={`mailto:${myConstClass.IWMI_EMAIL}?subject="subject text"`}>
                  <img src={Mail} alt="Email" />
                </a>
                <a href={myConstClass.IWMI_TWITTER_LINK} target="_blank" rel="noreferrer">
                  <img src={Twitter} alt="Twitter" />
                </a>
                <a href={myConstClass.IWMI_RSS_FEEDS} target="_blank" rel="noreferrer">
                  <img src={RSSFeed} alt="RSS" />
                </a>
                <a href={myConstClass.IWMI_YOUTUBE_LINK} target="_blank" rel="noreferrer">
                  <img src={Youtube} alt="YouTube" />
                </a>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md="6" className="text-md-left">
              <div className="copyright">
                <span className="copy-icon">&copy;</span>
                <span>2024 International Water Management Institute (IWMI)</span>
              </div>
            </Col>
            <Col md="6" className=" text-end">
              <span className="terms">Terms & Conditions</span>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Footer;

import {MANAGE_CROPS_CONST,MANAGE_CROPS_ADD_CONST,MANAGE_CROPS_UPDATE_CONST,MANAGE_CROPS_DELETE_CONST, MANAGE_CROPS_SEASONS_CONST, MANAGE_CROPS_LIST_BY_ID_CONST }  from './manageCropsConst';

const ManageCrops = (state = {}, action) => {
  switch (action.type) {
    case MANAGE_CROPS_CONST.MANAGE_CROPS_REQUEST:
      return {
        ...state,
        isManageCropsListRequesting: true,
        manageCropsListData: false,
        manageCropsListDataError: false,
        manageCropsListDataNetworkError: false,
      };
    case MANAGE_CROPS_CONST.MANAGE_CROPS_SUCCESS:
      return {
        ...state,
        isManageCropsListRequesting: false,
        manageCropsListData: action.payload.response,
        manageCropsListDataError: false,
        manageCropsListDataNetworkError: false,
      };
    case MANAGE_CROPS_CONST.MANAGE_CROPS_FAILED:
      return {
        ...state,
        isManageCropsListRequesting: false,
        manageCropsListData: false,
        manageCropsListDataError: action.payload.error ? false : action.payload.response,
        manageCropsListDataNetworkError: action.payload.error ? action.payload.error : false,
      };


      case MANAGE_CROPS_LIST_BY_ID_CONST.MANAGE_CROPS_LIST_BY_ID_REQUEST:
        return {
          ...state,
          isManageCropsListByIdRequesting: true,
          manageCropsListByIdData: false,
          manageCropsListByIdDataError: false,
          manageCropsListByIdDataNetworkError: false,
        };
      case MANAGE_CROPS_LIST_BY_ID_CONST.MANAGE_CROPS_LIST_BY_ID_SUCCESS:
        return {
          ...state,
          isManageCropsListByIdRequesting: false,
          manageCropsListByIdData: action.payload.response,
          manageCropsListByIdDataError: false,
          manageCropsListByIdDataNetworkError: false,
        };
      case MANAGE_CROPS_LIST_BY_ID_CONST.MANAGE_CROPS_LIST_BY_ID_FAILED:
        return {
          ...state,
          isManageCropsListByIdRequesting: false,
          manageCropsListByIdData: false,
          manageCropsListByIdDataError: action.payload.error ? false : action.payload.response,
          manageCropsListByIdDataNetworkError: action.payload.error ? action.payload.error : false,
        };

      case MANAGE_CROPS_ADD_CONST.MANAGE_CROPS_ADD_REQUEST:
      return {
        ...state,
        isManageCropsAddRequesting: true,
        manageCropsAddData: false,
        manageCropsAddDataError: false,
        manageCropsAddDataNetworkError: false,
      };
    case MANAGE_CROPS_ADD_CONST.MANAGE_CROPS_ADD_SUCCESS:
      return {
        ...state,
        isManageCropsAddRequesting: false,
        manageCropsAddData: action.payload.response,
        manageCropsAddDataError: false,
        manageCropsAddDataNetworkError: false,
      };
    case MANAGE_CROPS_ADD_CONST.MANAGE_CROPS_ADD_FAILED:
      return {
        ...state,
        isManageCropsAddRequesting: false,
        manageCropsAddData: false,
        manageCropsAddDataError: action.payload.error ? false : action.payload.response,
        manageCropsAddDataNetworkError: action.payload.error ? action.payload.error : false,
      };


      case MANAGE_CROPS_UPDATE_CONST.MANAGE_CROPS_UPDATE_REQUEST:
        return {
          ...state,
          isManageCropsUpdateRequesting: true,
          manageCropsUpdateData: false,
          manageCropsUpdateDataError: false,
          manageCropsUpdateDataNetworkError: false,
        };
      case MANAGE_CROPS_UPDATE_CONST.MANAGE_CROPS_UPDATE_SUCCESS:
        return {
          ...state,
          isManageCropsUpdateRequesting: false,
          manageCropsUpdateData: action.payload.response,
          manageCropsUpdateDataError: false,
          manageCropsUpdateDataNetworkError: false,
        };
      case MANAGE_CROPS_UPDATE_CONST.MANAGE_CROPS_UPDATE_FAILED:
        return {
          ...state,
          isManageCropsUpdateRequesting: false,
          manageCropsUpdateData: false,
          manageCropsUpdateDataError: action.payload.error ? false : action.payload.response,
          manageCropsUpdateDataNetworkError: action.payload.error ? action.payload.error : false,
        };



        case MANAGE_CROPS_DELETE_CONST.MANAGE_CROPS_DELETE_REQUEST:
          return {
            ...state,
            isManageCropsDeleteRequesting: true,
            manageCropsDeleteData: false,
            manageCropsDeleteDataError: false,
            manageCropsDeleteDataNetworkError: false,
          };
        case MANAGE_CROPS_DELETE_CONST.MANAGE_CROPS_DELETE_SUCCESS:
          return {
            ...state,
            isManageCropsDeleteRequesting: false,
            manageCropsDeleteData: action.payload.response,
            manageCropsDeleteDataError: false,
            manageCropsDeleteDataNetworkError: false,
          };
        case MANAGE_CROPS_DELETE_CONST.MANAGE_CROPS_DELETE_FAILED:
          return {
            ...state,
            isManageCropsDeleteRequesting: false,
            manageCropsDeleteData: false,
            manageCropsDeleteDataError: action.payload.error ? false : action.payload.response,
            manageCropsDeleteDataNetworkError: action.payload.error ? action.payload.error : false,
          };


          case MANAGE_CROPS_SEASONS_CONST.MANAGE_CROPS_SEASONS_REQUEST:
            return {
              ...state,
              isManageCropsSeasonsRequesting: true,
              manageCropsSeasonsData: false,
              manageCropsSeasonsDataError: false,
              manageCropsSeasonsDataNetworkError: false,
            };
          case MANAGE_CROPS_SEASONS_CONST.MANAGE_CROPS_SEASONS_SUCCESS:
            return {
              ...state,
              isManageCropsSeasonsRequesting: false,
              manageCropsSeasonsData: action.payload.response,
              manageCropsSeasonsDataError: false,
              manageCropsSeasonsDataNetworkError: false,
            };
          case MANAGE_CROPS_SEASONS_CONST.MANAGE_CROPS_SEASONS_FAILED:
            return {
              ...state,
              isManageCropsSeasonsRequesting: false,
              manageCropsSeasonsData: false,
              manageCropsSeasonsDataError: action.payload.error ? false : action.payload.response,
              manageCropsSeasonsDataNetworkError: action.payload.error ? action.payload.error : false,
            };


    default:
      return { ...state };
  }
};

export default ManageCrops;

import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { t } from 'react-multi-lang';
import { Carousel, CarouselCaption, CarouselControl, CarouselItem } from 'reactstrap';
import BannerImage1 from '../../assets/Images/banner-image-1.jpg';
import BannerImage2 from '../../assets/Images/banner-image-2.jpeg';
import BannerImage3 from '../../assets/Images/banner-image-3.jpg';
import { default as BannerImage4 } from '../../assets/Images/banner-image-4.JPG';
import BannerImage5 from '../../assets/Images/banner-image-5.jpg';

const HeroCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  const items = [
    {
      src: BannerImage1,
      altText: 'Slide 1',
      captionText: t('LANDING_PAGE.BANNER_TEXT_1'),
    },
    {
      src: BannerImage2,
      altText: 'Slide 2',
      captionText: t('LANDING_PAGE.BANNER_TEXT_2'),
    },
    {
      src: BannerImage3,
      altText: 'Slide 3',
      captionText: t('LANDING_PAGE.BANNER_TEXT_3'),
    },
    {
      src: BannerImage4,
      altText: 'Slide 4',
      captionText: t('LANDING_PAGE.BANNER_TEXT_4'),
    },
    {
      src: BannerImage5,
      altText: 'Slide 5',
      captionText: t('LANDING_PAGE.BANNER_TEXT_5'),
    },
  ];

  // Handle visibility change events
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        setIsPaused(true);
      } else {
        setIsPaused(false);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item, index) => (
    <CarouselItem
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
      key={`${item.src}-${index}`}
    >
      <img src={item.src} alt={item.altText} className="hero-bg" />
      <CarouselCaption captionText="" captionHeader={item.captionText} className="hero-content" />
      {/* <h1 className="hero-content m-0">{item.captionText}</h1> */}
    </CarouselItem>
  ));

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      interval={5000}
      ride={isPaused ? false : 'carousel'} // Fix the ride prop handling
      className="carousel-container"
    >
      <ol className="carousel-indicators">
        {items.map((item, index) => (
          <li
            key={item.altText}
            onClick={() => goToIndex(index)}
            className={classnames({ active: activeIndex === index })}
          />
        ))}
      </ol>
      {slides}
      <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>
  );
};

export default HeroCarousel;

/* eslint-disable react-hooks/exhaustive-deps */
import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { t } from 'react-multi-lang';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';

import { ReactComponent as AddIcon } from '../../../assets/Images/add-icon.svg';
import Loader from '../../../Components/common/TextLoader';
import { isSuperAdmin } from '../../../Components/common/utils';
import { showError, showSuccess } from '../../../Components/Notifications';
import {
  addLandingPageRequest,
  addLandingPageSuccess,
  deleteLandingPageRequest,
  deleteLandingPageSuccess,
  editLandingPageRequest,
  editLandingPageSuccess,
  getDmsPillarsRequest,
  getDmsPillarsSuccess,
} from '../../../redux/home/homeActions';
import DeleteModal from '../DeleteModal';
import NewPillarModal from './NewPillarModal';
import PillarsListing from './PillarsLists';
// import RiskMitigation from './RiskMitigation';

function ManagePillars({ title, userPermisions }) {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('monitoring_early_warning');
  const [pillarsData, setPillarsData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedPillar, setSelectedPillar] = useState('');
  // const [selectedRiskMitigationTab, setSelectedRiskMitigationtab] = useState(
  //   'risk_mitigation_category',
  // );
  const [formData, setFormData] = useState({
    pillar_type: 'monitoring_early_warning',
    name: '',
    description: '',
    website_url: '',
    latitude: '',
    longitude: '',
    icon: null,
    file: null,
  });

  const { LandingPageData } = useSelector(state => ({
    LandingPageData: state.Home,
  }));

  useEffect(() => {
    dispatch(getDmsPillarsRequest());

    return () => {
      dispatch(getDmsPillarsSuccess({}));
      dispatch(editLandingPageSuccess({}));
      dispatch(addLandingPageSuccess({}));
      dispatch(deleteLandingPageSuccess({}));
    };
  }, [dispatch]);

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (LandingPageData.isGetDmsPillarsRequesting) {
      setIsLoading(true);
    } else if (LandingPageData.getDmsPillarsData) {
      const { result } = LandingPageData.getDmsPillarsData.data;
      const filteredData = result.filter(pillar => pillar.pillar_type === activeTab);
      setCategoryData(filteredData);
      setPillarsData(result);
      setIsLoading(false);
    } else if (LandingPageData.getDmsPillarsError || LandingPageData.getDmsPillarsNetworkError) {
      showError('Something went wrong');
      setIsLoading(false);
    }
  }, [
    LandingPageData.getDmsPillarsData,
    LandingPageData.getDmsPillarsError,
    LandingPageData.getDmsPillarsNetworkError,
    LandingPageData.isGetDmsPillarsRequesting,
  ]);

  const isAddRef = useRef(true);
  useEffect(() => {
    if (isAddRef.current) {
      isAddRef.current = false;
      return;
    }
    if (LandingPageData.isAddLandingPageRequesting) {
      setIsLoading(true);
    } else if (LandingPageData?.addLandingPageData?.data) {
      showSuccess(LandingPageData?.addLandingPageData?.data?.result);
      dispatch(getDmsPillarsRequest({}));
      setIsLoading(false);
      handleResetFormData();
    } else if (
      LandingPageData.addLandingPageDataError ||
      LandingPageData.addLandingPageDataNetworkError
    ) {
      const errorMessageArray =
        LandingPageData.addLandingPageDataError?.response?.data?.response?.data?.message || [];
      const [errorMessage] = errorMessageArray;

      showError(errorMessage);
      setIsLoading(false);
    }
  }, [
    LandingPageData?.addLandingPageData,
    LandingPageData.isAddLandingPageRequesting,
    LandingPageData.addLandingPageDataError,
    LandingPageData.addLandingPageDataNetworkError,
    dispatch,
  ]);

  const isEditRef = useRef(true);
  useEffect(() => {
    if (isEditRef.current) {
      isEditRef.current = false;
      return;
    }
    if (LandingPageData.iseditLandingPageRequesting) {
      setIsLoading(true);
      setIsEdit(false);
    } else if (LandingPageData?.editLandingPageData?.data) {
      showSuccess(LandingPageData?.editLandingPageData?.data?.result);
      dispatch(getDmsPillarsRequest({}));
      setIsLoading(false);
      handleResetFormData();
      setIsEdit(false);
    } else if (
      LandingPageData.editLandingPageDataError ||
      LandingPageData.editLandingPageDataNetworkError
    ) {
      const errorMessageArray =
        LandingPageData.editLandingPageDataError?.response?.data?.response?.data?.message || [];
      const [errorMessage] = errorMessageArray;

      showError(errorMessage);
      setIsLoading(false);
      setIsEdit(false);
    }
  }, [
    LandingPageData.editLandingPageData,
    LandingPageData.iseditLandingPageRequesting,
    LandingPageData.editLandingPageDataError,
    LandingPageData.editLandingPageDataNetworkError,
    dispatch,
  ]);

  const isDeleteRef = useRef(true);
  useEffect(() => {
    if (isDeleteRef.current) {
      isDeleteRef.current = false;
      return;
    }
    if (LandingPageData.isDeleteLandingPageRequesting) {
      setIsLoading(true);
    } else if (LandingPageData?.deleteLandingPageData?.data) {
      showSuccess(LandingPageData?.deleteLandingPageData?.data?.result);
      dispatch(getDmsPillarsRequest({}));
      setIsLoading(false);
      handleResetFormData();
    } else if (
      LandingPageData.deleteLandingPageDataError ||
      LandingPageData.deleteLandingPageDataNetworkError
    ) {
      showError(LandingPageData.deleteLandingPageDataError?.response?.data?.message);
      setIsLoading(false);
    }
  }, [
    LandingPageData?.deleteLandingPageData,
    LandingPageData.isDeleteLandingPageRequesting,
    LandingPageData.deleteLandingPageDataError,
    LandingPageData.deleteLandingPageDataNetworkError,
    dispatch,
  ]);

  const handleResetFormData = () => {
    setFormData({
      pillar_type: 'monitoring_early_warning',
      name: '',
      description: '',
      website_url: '',
      latitude: '',
      longitude: '',
      icon: null,
      file: null,
    });
  };

  const toggle = tab => {
    if (activeTab !== tab) {
      const filteredData = pillarsData.filter(pillar => pillar.pillar_type === tab);
      setCategoryData(filteredData);
      setActiveTab(tab);
    }
  };

  const onHandlePillars = data => {
    const submissionData = new FormData();

    // Append form fields to FormData
    if (data.pillar_type) submissionData.append('pillar_type', data.pillar_type);
    if (data.name) submissionData.append('name', data.name);
    if (data.description) submissionData.append('description', data.description);
    if (data.website_url) submissionData.append('website_url', data.website_url);
    if (data.latitude) submissionData.append('latitude', data.latitude);
    if (data.longitude) submissionData.append('longitude', data.longitude);

    if (data.file) {
      submissionData.append('file', data.file);
    }

    if (data.icon) {
      submissionData.append('icon', data.icon);
    }

    if (data.category) {
      submissionData.append('category', data.category);
    }

    if (isEdit) {
      // * MAKE SURE TO SEND IT AS submissionData
      submissionData.append('id', formData.id);
      dispatch(editLandingPageRequest({ submissionData, end_point: 'update_pillars' }));
      setIsEdit(false);
    } else {
      dispatch(addLandingPageRequest({ submissionData, end_point: 'add_pillars' }));
    }
  };

  const onHandleDelete = () => {
    setDeleteModal(false);
    dispatch(
      deleteLandingPageRequest({
        id: selectedPillar?.id,
        end_point: 'delete_pillars',
        query: 'id',
      }),
    );
  };

  const onHandleUpdateOrder = payload => {
    const submissionData = { ...payload };

    setTimeout(() => {
      dispatch(editLandingPageRequest({ submissionData, end_point: 'update_pillars' }));
    }, 1000);
  };

  return (
    <>
      {isLoading && <Loader />}
      {isModalOpen ? (
        <NewPillarModal
          modal={isModalOpen}
          setModal={setIsModalOpen}
          setFormData={setFormData}
          formData={formData}
          onHandlePillars={onHandlePillars}
          isEditable={isEdit}
          // selectedRiskMitigationTab={selectedRiskMitigationTab}
        />
      ) : null}
      {deleteModal ? (
        <DeleteModal
          isOpen={deleteModal}
          toggle={() => setDeleteModal(!deleteModal)}
          message={`This will delete the “${selectedPillar?.name}” and will not be able to see it.`}
          onHandleDelete={onHandleDelete}
        />
      ) : null}
      <div className="dms-title-container">
        <h1 className="dms-title">{title.label}</h1>
        {isSuperAdmin() || userPermisions.includes('add_dmspillars') ? (
          <Button
            className="dms-add-edit-btn"
            onClick={() => {
              setIsModalOpen(!isModalOpen);
              setIsEdit(false);
            }}
          >
            {t('LANDING_PAGE.ADD_NEW')} <AddIcon height={20} width={20} />
          </Button>
        ) : null}
      </div>
      <Container fluid className=" p-0">
        <Row className=" m-0">
          <Col md={12} className=" p-0">
            <Nav tabs className="dms-pillars-tabs">
              <NavItem
                className={classnames('dms-navitem', {
                  'dms-active-pillar': activeTab === 'monitoring_early_warning',
                })}
              >
                <NavLink
                  className="dms-tabs-name"
                  onClick={() => {
                    toggle('monitoring_early_warning');
                  }}
                >
                  {t('LANDING_PAGE.MONITORING_EARLY_WARNING')}
                </NavLink>
              </NavItem>
              <NavItem
                className={classnames('dms-navitem', {
                  'dms-active-pillar': activeTab === 'risk_impact',
                })}
              >
                <NavLink
                  className="dms-tabs-name"
                  onClick={() => {
                    toggle('risk_impact');
                  }}
                >
                  {t('LANDING_PAGE.RISK_IMPACT')}
                </NavLink>
              </NavItem>
              <NavItem
                className={classnames('dms-navitem', {
                  'dms-active-pillar': activeTab === 'risk_mitigation',
                })}
              >
                <NavLink
                  className="dms-tabs-name"
                  onClick={() => {
                    toggle('risk_mitigation');
                  }}
                >
                  {t('LANDING_PAGE.RISK_MITIGATION')}
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab} className="my-3">
              <TabPane tabId="monitoring_early_warning">
                <Row>
                  <Col className=" h-100 dms-tabs-name">
                    <PillarsListing
                      data={categoryData?.length ? categoryData[0].values : []}
                      selectedPillar={activeTab}
                      isModalOpen={isModalOpen}
                      setIsModalOpen={setIsModalOpen}
                      isLoading={isLoading}
                      setIsEdit={setIsEdit}
                      isEdit={isEdit}
                      setFormData={setFormData}
                      formData={formData}
                      setSelectedPillar={setSelectedPillar}
                      setDeleteModal={setDeleteModal}
                      onHandleUpdateOrder={onHandleUpdateOrder}
                      userPermisions={userPermisions}
                    />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="risk_impact">
                <Row>
                  <Col className=" h-100 dms-tabs-name ">
                    <PillarsListing
                      data={categoryData?.length ? categoryData[0].values : []}
                      selectedPillar={activeTab}
                      isModalOpen={isModalOpen}
                      setIsModalOpen={setIsModalOpen}
                      isLoading={isLoading}
                      setIsEdit={setIsEdit}
                      isEdit={isEdit}
                      setFormData={setFormData}
                      formData={formData}
                      setSelectedPillar={setSelectedPillar}
                      setDeleteModal={setDeleteModal}
                      onHandleUpdateOrder={onHandleUpdateOrder}
                      userPermisions={userPermisions}
                    />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="risk_mitigation">
                <Row>
                  <Col className=" h-100 dms-tabs-name">
                    <PillarsListing
                      data={categoryData?.length ? categoryData[0].values : []}
                      selectedPillar={activeTab}
                      isModalOpen={isModalOpen}
                      setIsModalOpen={setIsModalOpen}
                      isLoading={isLoading}
                      setIsEdit={setIsEdit}
                      isEdit={isEdit}
                      setFormData={setFormData}
                      formData={formData}
                      setSelectedPillar={setSelectedPillar}
                      setDeleteModal={setDeleteModal}
                      onHandleUpdateOrder={onHandleUpdateOrder}
                      userPermisions={userPermisions}
                    />
                    {/* <RiskMitigation
                      data={categoryData}
                      selectedPillar={activeTab}
                      isModalOpen={isModalOpen}
                      setIsModalOpen={setIsModalOpen}
                      isLoading={isLoading}
                      setIsEdit={setIsEdit}
                      isEdit={isEdit}
                      setFormData={setFormData}
                      formData={formData}
                      setSelectedPillar={setSelectedPillar}
                      setDeleteModal={setDeleteModal}
                      onHandleUpdateOrder={onHandleUpdateOrder}
                      setSelectedRiskMitigationtab={setSelectedRiskMitigationtab}
                      userPermisions={userPermisions}
                    /> */}
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ManagePillars;

import {
  addUserGuideSuccess,
  addUserGuideFailed,
  getAllTopicSuccess,
  getAllTopicFailed,
  getAllUserGuideSuccess,
  getAllUserGuideFailed,
  getAllTopicsListSuccess,
  getAllTopicsListFailed,
  updateUserGuideSuccess,
  updateUserGuideFailed,
  deleteUserGuideSuccess,
  deleteUserGuideFailed,
  changeStatusUserGuideSuccess,
  changeStatusUserGuideFailed,
  searchByCriteriaUserGuideSuccess,
  searchByCriteriaUserGuideFailed,
  searchContentUserGuideSuccess,
  searchContentUserGuideFailed,
} from './userGuideActions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { USER_GUIDE_CONSTS } from './userGuideConsts';
import { AXIOS_INSTANCE, USER_GUIDE_URL } from '../api/apiEndPoint';

const addUserGuideApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.post(`${USER_GUIDE_URL}/add`, payload);
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* addUserGuide(action) {
  try {
    const response = yield call(addUserGuideApiRequest, action.payload);
    if (response?.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response,
        },
      };
      yield put(addUserGuideSuccess(responseData));
    } else if (response?.status === 400) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(addUserGuideFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(addUserGuideFailed(errorData));
  }
}

const allTopicsApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.post(
      `${USER_GUIDE_URL}/getAllTopicSubtopic`,
      payload ? payload : {},
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getAllTopics(action) {
  try {
    const response = yield call(allTopicsApiRequest, action.payload);
    if (response) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response,
        },
      };
      yield put(getAllTopicSuccess(responseData));
    } else {
      const responseData = {
        response: { statusCode: 200, response: response.error },
      };
      yield put(getAllTopicFailed(responseData));
    }
  } catch (error) {
    const errorData = { error: { statusText: error, netWorkError: true } };
    yield put(getAllTopicFailed(errorData));
  }
}

const allUserguideApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.post(
      `${USER_GUIDE_URL}/getAllByCriteria${payload.page ? `?page=${payload.page}` : ''}`,
      payload.data,
    );
    response = {
      data: apiResponseData.data,
      fromBackOffice: payload.fromBackOffice,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getAllUserGuideRequest(action) {
  try {
    const response = yield call(allUserguideApiRequest, action.payload);
    if (response) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.data,
          fromBackOffice: response.fromBackOffice
        },
      };
      yield put(getAllUserGuideSuccess(responseData));
    } else {
      const responseData = {
        response: { statusCode: 200, response: response.error },
      };
      yield put(getAllUserGuideFailed(responseData));
    }
  } catch (error) {
    const errorData = { error: { statusText: error, netWorkError: true } };
    yield put(getAllUserGuideFailed(errorData));
  }
}

const getAllTopicsListApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.get(
      `${USER_GUIDE_URL}/topic/getAll${payload.page ? `?page=${payload.page}` : ''}`,
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getAllTopicsList(action) {
  try {
    const response = yield call(getAllTopicsListApiRequest, action.payload);
    if (response) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response,
        },
      };
      yield put(getAllTopicsListSuccess(responseData));
    } else {
      const responseData = {
        response: { statusCode: 200, response: response.error },
      };
      yield put(getAllTopicsListFailed(responseData));
    }
  } catch (error) {
    const errorData = { error: { statusText: error, netWorkError: true } };
    yield put(getAllTopicsListFailed(errorData));
  }
}

const updateUserGuideApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.put(`${USER_GUIDE_URL}/update`, payload);
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* updateUserGuide(action) {
  try {
    const response = yield call(updateUserGuideApiRequest, action.payload);
    if (response.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response,
        },
      };
      yield put(updateUserGuideSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(updateUserGuideFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(updateUserGuideFailed(errorData));
  }
}

const deleteUserGuideApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.delete(`${USER_GUIDE_URL}/delete/${payload.id}`);
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* deleteUserGuide(action) {
  try {
    const response = yield call(deleteUserGuideApiRequest, action.payload);
    if (response) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response,
        },
      };
      yield put(deleteUserGuideSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(deleteUserGuideFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(deleteUserGuideFailed(errorData));
  }
}

const changeStatusUserGuideApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.put(`${USER_GUIDE_URL}/changeStatus`, payload);
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* changeStatusUserGuide(action) {
  try {
    const response = yield call(changeStatusUserGuideApiRequest, action.payload);
    if (response) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response,
        },
      };
      yield put(changeStatusUserGuideSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(changeStatusUserGuideFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(changeStatusUserGuideFailed(errorData));
  }
}

const searchByCriteriaUserGuideApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.get(
      `${USER_GUIDE_URL}/sub-topic/searchByCriteria/${payload.topic_name}/${payload.name}`,
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* searchByCriteriaUserGuide(action) {
  try {
    const response = yield call(searchByCriteriaUserGuideApiRequest, action.payload);
    if (response) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response,
        },
      };
      yield put(searchByCriteriaUserGuideSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(searchByCriteriaUserGuideFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(searchByCriteriaUserGuideFailed(errorData));
  }
}

const searchContentUserGuideApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.get(
      `${USER_GUIDE_URL}/search-content/${payload.content}`,
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};
function* searchContentUserGuide(action) {
  try {
    const response = yield call(searchContentUserGuideApiRequest, action.payload);
    if (response) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response,
        },
      };
      yield put(searchContentUserGuideSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(searchContentUserGuideFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(searchContentUserGuideFailed(errorData));
  }
}
function* watchAddUserGuide() {
  yield takeEvery(USER_GUIDE_CONSTS.ADD_USER_GUIDE_REQUEST, addUserGuide);
  yield takeEvery(USER_GUIDE_CONSTS.GET_ALL_TOPICS_REQUEST, getAllTopics);
  yield takeEvery(USER_GUIDE_CONSTS.GET_ALL_USER_GUIDE_REQUEST, getAllUserGuideRequest);
  yield takeEvery(USER_GUIDE_CONSTS.GET_All_TOPICS_LIST_REQUEST, getAllTopicsList);
  yield takeEvery(USER_GUIDE_CONSTS.UPDATE_USER_GUIDE_REQUEST, updateUserGuide);
  yield takeEvery(USER_GUIDE_CONSTS.DELETE_USER_GUIDE_REQUEST, deleteUserGuide);
  yield takeEvery(USER_GUIDE_CONSTS.CHANGE_STATUS_USER_GUIDE_REQUEST, changeStatusUserGuide);
  yield takeEvery(
    USER_GUIDE_CONSTS.SEARCH_BY_CRITERIA_USER_GUIDE_REQUEST,
    searchByCriteriaUserGuide,
  );
  yield takeEvery(USER_GUIDE_CONSTS.SEARCH_CONTENT_USER_GUIDE_REQUEST, searchContentUserGuide);
}

function* userGuideSaga() {
  yield all([fork(watchAddUserGuide)]);
}

export default userGuideSaga;

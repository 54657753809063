import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';

const Menu = props => {
  const { selectedMenuItem, handleSelectedItem, categoriesList } = props;

  const handleSelectedMenuItem = value => {
    handleSelectedItem(value);
  };

  return (
    <div className="news-feed-menu">
      <ListGroup>
        {categoriesList?.map((i,index) => {
          return (
            <ListGroupItem
              active={selectedMenuItem.category_name === i.category_name ? true : false}
              tag="button"
              onClick={() => handleSelectedMenuItem(i)}
              action
              key={index}
            >
              {i.category_name}
            </ListGroupItem>
          );
        })}
      </ListGroup>
    </div>
  );
};
export default Menu;

import { t } from 'react-multi-lang';

const Loader = (props) => {
  return (
    <>
      <div className="loader-container">
        <div className="loader">
          <div className="loadingio-spinner-spinner-m63cop84tg">
            <div className="ldio-3ucpol5dcb2">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          {props.showText && <p className="loader-idsi-vwi">
              {t('LOADER.LOADER_TEXT')}</p> }
        </div>
      </div>
    </>
  );
};

export default Loader;

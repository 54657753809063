import React, { useEffect, useRef, useState } from 'react';
import { t } from 'react-multi-lang';
import _ from 'lodash';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import Button from 'reactstrap-button-loader';
import { BULLETIN_CODES } from '../../Components/constants';
import RightArrow from '../../assets/Images/back-button.svg';
import EmailEditor from 'react-email-editor';
import { showSuccess, showError } from '../../Components/Notifications';
import { isSuperAdmin } from '../../Components/common/utils';
import {
  approveRejectBulletinRequest,
  previewBulletinRequest,
  previewBulletinSuccess,
} from '../../redux/bulletin/bulletinActions';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const ApproveEditor = props => {
  let form = new FormData();
  const emailEditorRef = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();


  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [rejectionText, setRejectionText] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [Buttonclicked, setButtonclicked] = useState(false);
  const [hideApproveButton, setHideApproveButton] = useState(true);
  const [hideRejectButton, setHideRejectButton] = useState(true);
  const [textAreaCount, ChangeTextAreaCount] = useState(0);
  const [approveButtonLoader, setApproveButtonLoader] = useState(false);
  const [isSuperUser, setSuperUser] = useState(isSuperAdmin());
  const [userPermisions, setUserPermisions] = useState([]);

  const { Bulletin, isApproveRejectRequesting } = useSelector(state => ({
    isApproveRejectRequesting: state.Bulletin.isApproveRejectRequesting,
    Bulletin: state.Bulletin,
  }));

  const nextProps = useSelector(state => ({
    userDetailData: state.userList.userDetailData || null,
    userPermissionsData: state.userList.userPermissionsData || null,
  }));

  useEffect(() => {
    return () => {
      dispatch(previewBulletinSuccess({}));
    };
  }, []);

  useEffect(() => {
    if (Bulletin?.approveRejectData && Buttonclicked) {
      showSuccess(Bulletin?.approveRejectData?.data?.data?.result);
      history.goBack();
    }
  }, [Bulletin.approveRejectData]);

  useEffect(() => {
    if (Bulletin?.approveRejectDataError?.data?.response?.data?.message && Buttonclicked) {
      showError(Bulletin?.approveRejectDataError?.data?.response?.data?.message);
      history.goBack();
    }
  }, [Bulletin.approveRejectDataError]);

  useEffect(() => {
    if (nextProps.userPermissionsData) {
      const {
        data: { result },
      } = nextProps.userPermissionsData;
      const {
        data: {
          result: { user_permissions },
        },
      } = nextProps.userDetailData;
      let a = [];
      if (user_permissions) {
        BULLETIN_CODES.map(i => {
          user_permissions?.map(j => {
            if (i === j.codename) {
              a.push(i);
            }
          });
        });
      }
      setUserPermisions(a);
    }
  }, [nextProps.userPermissionsData]);

  useEffect(() => {
    switch (location.state[1]) {
      case 'Pending':
        setHideApproveButton(true);
        setHideRejectButton(true);
        break;

      case 'Approved':
        setHideApproveButton(true);
        setHideRejectButton(false);
        break;

      case 'Rejected':
        setHideApproveButton(false);
        setHideRejectButton(true);
        break;
    }
  });

  useEffect(() => {
    if (Bulletin?.getPreviewBulletinData?.data?.data?.result) {
      window.open(Bulletin?.getPreviewBulletinData?.data?.data?.result);
    }
  }, [Bulletin.getPreviewBulletinData]);

  //EXECUTES AFTER LOADING THE EDITOR
  const onReady = () => {
    if (!_.isEmpty(location?.state[0]?.json_content)) {
      let jsonObject = JSON.parse(location.state[0].json_content);
      if (!_.isEmpty(emailEditorRef?.current) && !_.isEmpty(emailEditorRef?.current?.editor)) {
        emailEditorRef.current.editor?.loadDesign(jsonObject);
      }
    }
    setIsButtonDisabled(false);
  };

  const handleApprove = data => {
    setApproveButtonLoader(true);
    form.append('id', location?.state[0]?.id);
    form.append('bulletin_status', '1');
    dispatch(approveRejectBulletinRequest(form));
    setButtonclicked(true);
  };

  const handleModalSubmitReject = data => {
    form.append('id', location?.state[0]?.id);
    form.append('reject_response', rejectionText);
    form.append('bulletin_status', '2');
    dispatch(approveRejectBulletinRequest(form));
    setButtonclicked(true);
  };

  const handleReject = data => {
    setOpenModal(true);
  };

  const handleRejectionText = e => {
    setRejectionText(e?.target?.value);
    ChangeTextAreaCount(e?.target?.value?.length);
  };

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const handlePreview = () => {
    dispatch(previewBulletinRequest(location?.state[0]?.id));
  };

  return (
    <div className="email-editor-container">
      <span className="col-lg-1 position-absolute">
        <img
          src={RightArrow}
          className="approve-bulletin-back-button"
          onClick={() => history.goBack()}
        />
      </span>
      <div className="approve-editor-email-editor-form-container">
        {
          // <a href={pdfurl} target="_blank" rel="noopener noreferrer" download>
          <Button
            onClick={handlePreview}
            className="btn btn-block st-btn-custom mt-3 me-3 create-template-save-update"
            disabled={isButtonDisabled}
            loading={Bulletin?.isPreviewBulletinRequesting}
          >
            {t('BULLET_IN.PREVIEW')}
          </Button>
          // </a>
        }
        {(isSuperAdmin() || userPermisions?.includes(BULLETIN_CODES[3])) && hideApproveButton && (
          <Button
            onClick={handleReject}
            className="btn me-3 btn-block mt-3 approve-editor-reject-button"
            disabled={isButtonDisabled}
          >
            {t('BULLET_IN.REJECT')}
          </Button>
        )}
        {(isSuperAdmin() || userPermisions?.includes(BULLETIN_CODES[3])) && hideRejectButton && (
          <Button
            onClick={handleApprove}
            className="btn btn-block st-btn-custom mt-3 me-3 create-template-save-update"
            disabled={isButtonDisabled}
            loading={Bulletin?.isApproveRejectRequesting && approveButtonLoader}
          >
            {t('BULLET_IN.APPROVE')}
          </Button>
        )}
      </div>
      <Modal isOpen={openModal} className="bullet-modal">
        <ModalHeader toggle={handleModal} className="reason-modal-header">
          {t('BULLET_IN.REJECTION_REASON')}
        </ModalHeader>
        <ModalBody>
          <div className="reject-char-count">
            <p>{textAreaCount}/500</p>
          </div>
          <textarea
            type="text"
            rows={5}
            placeholder={t('BULLET_IN.ENTER_SOMETHING_HERE')}
            className="approve-editor-input"
            maxLength={500}
            onChange={e => {
              handleRejectionText(e);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn btn-block st-btn-custom mt-0 create-template-save-update"
            disabled={_.isEmpty(rejectionText?.replace(/\s/g, ''))}
            onClick={() => {
              handleModalSubmitReject();
            }}
            loading={isApproveRejectRequesting}
          >
            {t('BULLET_IN.SUBMIT')}
          </Button>
        </ModalFooter>
      </Modal>
      <EmailEditor
        ref={emailEditorRef}
        onReady={onReady}
        minHeight="75vh"
        options={{
          appearance: { theme: 'light', panels: { tools: { dock: 'left' } } },
          features: {
            preview: false,
            stockImages: true,
            undoRedo: true,
            textEditor: {
              spellChecker: true,
            },
          },
          tools: {
            form: {
              enabled: true,
            },
          },
          locale: 'en-US',
        }}
      />
    </div>
  );
};

export default ApproveEditor;

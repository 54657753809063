import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Card,
  CardImg,
  CardTitle,
  CardSubtitle,
  CardBody,
  Row,
  Col,
} from 'reactstrap';
import moment from 'moment';
import {
  NEWS_FEED_CATEGORIES,
  NEWS_FEED_LENGTH_CONSTANT,
} from '../../../Components/constants/index';
import InfiniteScroll from 'react-infinite-scroll-component';
import { DISPLAY_DATE_FORMAT } from '../../../Components/constants';
import { useTranslation } from 'react-multi-lang';
import { htmlToText } from '../../../Components/common/utils';
import Loader from '../../../Components/common/loader';
import { getAllNewsFeedRequest } from '../../../redux/newsFeed/newsFeedAction';

const ProjectNews = props => {
  const {
    newsFeedList,
    isNewsFeedRequesting,
    pageNumber,
    pageCount,
    setPageNumber,
    selectedMenuItem,
  } = props;

  const t = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();


  const handleTitleClick = data => {
    history.push(`/news-feed-details-page?newsFeedId=${data.id}`);
  };

  const fetchNextTemplateData = selectedMenuItem => {
    setPageNumber(pageNumber + 1);
    dispatch(
      getAllNewsFeedRequest({
        page: pageNumber + 1,
        data: { newsfeed_category_id: selectedMenuItem?.id, status: 'true' },
      }),
    );
  };

  return (
    <div className="news-feed-cls">
      <div className="content-title">
        <span className="">{NEWS_FEED_CATEGORIES.Project_News}</span>
        <hr className="title-hr" />
      </div>
      <div className="cards-section">
        {isNewsFeedRequesting && <Loader />}
        {!isNewsFeedRequesting && !newsFeedList?.length && (
          <h5 className="news-feed-no-records">{t('NEWS_FEED.NO_RECORDS')}</h5>
        )}
        <InfiniteScroll
          dataLength={pageNumber * 6 < pageCount ? pageNumber * 6 : ''}
          next={() => fetchNextTemplateData(selectedMenuItem)}
          hasMore={pageNumber * 6 < pageCount ? true : false}
          loader={
            isNewsFeedRequesting && (
              <center>
                <h4>{t('BULLET_IN.LOADING')} </h4>
              </center>
            )
          }
          className="flex flex-wrap"
        >
          <Row>
            {newsFeedList?.map((i,index) => {
              return (
                <Col xs={4} md={4} lg={4} className="card-column" key ={index}>
                  <Card className="news-feed-card" onClick={() => handleTitleClick(i)}>
                    <CardBody>
                    <CardTitle className="news-feed-card-title">
                          <a
                            className="news-feed-title-link"
                            href={i?.link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {i?.title?.length > NEWS_FEED_LENGTH_CONSTANT.SIXTY
                              ? `${i?.title.substring(0, 60)}... `
                              : i?.title}
                          </a>
                        </CardTitle>
                        <CardImg top src={i.media_name} alt="Card image cap" />
                      <div className="news-feed-card-content">
                        {htmlToText(i.content)?.length > 300 ? (
                            <div id="card-description">
                            <div className="news-feed-raw-data-section">
                              {htmlToText(i.content).substring(0, 200)}
                              <span>...</span>
                              <a className="read-more" onClick={() => handleTitleClick(i)}>
                                {t('NEWS_FEED.READ_MORE')}
                              </a>
                            </div>
                            </div>
                        ) : (
                          <div dangerouslySetInnerHTML={{ __html: i?.content }} />
                        )}
                      </div>
                      <CardSubtitle className="news-feed-card-sub-title">
                        {t('BULLET_IN.PUBLISHED_ON') +
                          ' ' +
                          moment(i?.updated_date).format(DISPLAY_DATE_FORMAT)}
                      </CardSubtitle>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </InfiniteScroll>
      </div>
    </div>
  );
};
export default ProjectNews;

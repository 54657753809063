import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Card,
  Col,
} from 'reactstrap';
import { convertTimeFormat, isStaffUser, isSuperAdmin } from '../../Components/common/utils';
import { t } from 'react-multi-lang';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import RightArrow from '../../assets/Images/back-button.svg';
import DummyResourceImage from '../../assets/Images/dummy_resource_image.jpg';
import { useHistory } from 'react-router-dom';
import { BiPlus } from 'react-icons/bi';
import Loader from '../../Components/common/loader';
import { DISPLAY_DATE_FORMAT } from '../../Components/constants';
import moment from 'moment';
import Filter from './Filter';
import CreateNewsForm from './CreateNewsForm';
import { showError, showSuccess } from '../../Components/Notifications';
import { FILE_EXTENSION } from '../../Components/constants/index';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  getByIdNewsFeedRequest,
  newsFeedDeleteRequest,
  newsFeedCategoriesRequest,
  newsFeedSubCategoriesRequest,
  newsFeedAddRequest,
  getAllNewsFeedRequest,
  newsFeedUpdateRequest,
  newsFeedAddSuccess,
  getAllNewsFeedSuccess,
  getByIdNewsFeedSuccess,
  newsFeedUpdateSuccess,
  newsFeedDeleteSuccess,
  newsFeedCategoriesSuccess,
  newsFeedChangeStatusRequest,
  newsFeedChangeStatusSuccess,
  newsFeedSubCategoriesSuccess,
} from '../../redux/newsFeed/newsFeedAction';
import AlertModal from './AlertModal';

const ManageNews = props => {
  let history = useHistory();
  let form = new FormData();
  const dispatch = useDispatch();
  const [toggleRightBar, setToggleRightBar] = useState(false);
  const [isHideBar, setHideBar] = useState(false);
  const [newsFeedDataById, setNewsFeedDataById] = useState();
  const [categoryList, setCategoryList] = useState([]);
  const [pageCount, setPageCount] = useState();
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [selectedFilterCategory, setSelectedFilterCategory] = useState();
  const [filterSubCategoriesList, setFilterSubCategoriesList] = useState();
  const [isResourceSelected, setIsResourceSelected] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [typeList, setTypeList] = useState([]);
  const [content, setContent] = useState();
  const [time, setTime] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [fromListing, setFromListing] = useState(false);
  const [fileType, setFileType] = useState();
  const [filterChanges, setFilterChanges] = useState({
    filterCategory: '',
    filterTitle: '',
    filterDate: '',
  });
  const [imageFile, setImageFile] = useState();
  const [uploadedFile, setUploadedFile] = useState();
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [newsFeedList, setNewsFeedList] = useState([]);
  const [formReset, setFormReset] = useState();
  const [filterCategoryList, setFilterCategoryList] = useState();
  const [isPublishModal, setIsPublishModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [updateNewsFeed, setUpdateNewsFeed] = useState('');
  const [showChangeStatus, setShowChangeStatus] = useState();
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    category_id: '',
    sub_category_id: '',
    upload_image: '',
    external_link: '',
    upload_file: '',
    start_date: '',
    location: '',
    publish_status: false,
  });
  const HIDE_CATEGORIES = {
    IWMI_NEWS: 'iwmi_news',
    RESOURCES: 'resources',
    EXTERNAL_NEWS: 'external_news',
    EVENTS: 'events',
  };
  const handleManageCropsBackButton = () => {
    history.push('/settingsDashboard');
  };

  const { NewsFeed } = useSelector(state => ({
    NewsFeed: state.NewsFeed,
  }));

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
    if (isSuperAdmin() || isStaffUser()) {
      dispatch(newsFeedCategoriesRequest());
      dispatch(getAllNewsFeedRequest({ page: pageNumber }));
      dispatch(getByIdNewsFeedSuccess({}));
      setFromListing(false);
    } else {
      history.push('/drought-management-system?Country=mali');
    }
  }, []);

  useEffect(() => {
    if (NewsFeed.newsFeedCategoriesListData) {
      const {
        data: { result },
      } = NewsFeed.newsFeedCategoriesListData;
      if (result.length) {
        let a = [];
        result.map(i => {
          return a.push({
            id: i.id,
            name: i.category_name,
            slug: i.slug,
          });
        });
        let filteredList = a.filter(
          data =>
            data.slug !== HIDE_CATEGORIES.IWMI_NEWS &&
            data.slug !== HIDE_CATEGORIES.EVENTS &&
            data.slug !== HIDE_CATEGORIES.RESOURCES,
        );
        setCategoryList(filteredList);
        setFilterCategoryList(filteredList);
      }
    }
  }, [NewsFeed.newsFeedCategoriesListData]);
  useEffect(() => {
    if (NewsFeed.newsFeedSubCategoriesListData) {
      const {
        data: { result },
      } = NewsFeed.newsFeedSubCategoriesListData;
      if (result.length) {
        let a = [];
        result.map(i => {
          return a.push({
            id: i.id,
            name: i.sub_category_name,
          });
        });
        setSubCategoryList(a);
        if (!_.isEmpty(selectedFilterCategory)) setFilterSubCategoriesList(a);
      }
    }
  }, [NewsFeed.newsFeedSubCategoriesListData]);

  useEffect(() => {
    if (
      !_.isEmpty(formData?.title) &&
      !_.isEmpty(formData?.description) &&
      !_.isEmpty(formData?.category_id) &&
      (selectedCategory === HIDE_CATEGORIES.EXTERNAL_NEWS ||
        !_.isEmpty(imageFile) ||
        !_.isEmpty(uploadedFile)) &&
      ((selectedCategory === HIDE_CATEGORIES.EVENTS && !_.isEmpty(formData?.event_time)) ||
        selectedCategory !== HIDE_CATEGORIES.EVENTS)
    ) {
      setSaveButtonDisabled(false);
    } else {
      setSaveButtonDisabled(true);
    }
  }, [formData, imageFile]);

  useEffect(() => {
    if (NewsFeed.newsFeedAddData) {
      const {
        data: { result },
      } = NewsFeed.newsFeedAddData;
      if (result) {
        showSuccess(result);
        setImageFile();
        setContent();
        setIsResourceSelected(false);
        setToggleRightBar(!toggleRightBar);
        setSelectedCategory();
        setUploadedFile();
        setFormData({
          title: '',
          description: '',
          category_id: '',
          sub_category_id: '',
          upload_image: '',
          external_link: '',
          upload_file: '',
          publish_status: false,
        });
        setNewsFeedDataById('');
        setFormReset(true);
        setPageNumber(1);
        setNewsFeedList([]);
        dispatch(getAllNewsFeedRequest({ page: 1 }));
      }
    }
  }, [NewsFeed.newsFeedAddData]);

  useEffect(() => {
    if (NewsFeed.newsFeedAddDataError || NewsFeed.newsFeedAddDataNetworkError) {
      let dataError = NewsFeed.newsFeedAddDataError ? NewsFeed.newsFeedAddDataError : '';
      if (dataError) {
        let {
          data: {
            response: { data: responseData },
          },
        } = dataError;
        if (responseData) {
          if (responseData?.exception) {
            showError(responseData?.exception);
          }
        }
      }
      setImageFile();
      setContent();
      setIsResourceSelected(false);
      setToggleRightBar(!toggleRightBar);
      setSelectedCategory();
      setUploadedFile();
      setFormData({
        title: '',
        description: '',
        category_id: '',
        sub_category_id: '',
        upload_image: '',
        external_link: '',
        upload_file: '',
        publish_status: false,
      });
      setNewsFeedDataById('');
      setFormReset(true);
    }
  }, [NewsFeed.newsFeedAddDataError, NewsFeed.newsFeedAddDataNetworkError]);

  useEffect(() => {
    if (NewsFeed.newsFeedUpdateDataError || NewsFeed.newsFeedUpdateDataNetworkError) {
      let dataError = NewsFeed.newsFeedUpdateDataError ? NewsFeed.newsFeedUpdateDataError : '';
      if (dataError) {
        let {
          data: {
            response: { data: responseData },
          },
        } = dataError;
        if (responseData) {
          if (responseData?.exception) {
            showError(responseData?.exception);
          }
        }
      }
      setImageFile();
      setContent();
      setIsResourceSelected(false);
      setToggleRightBar(!toggleRightBar);
      setSelectedCategory();
      setUploadedFile();
      setFormData({
        title: '',
        description: '',
        category_id: '',
        sub_category_id: '',
        upload_image: '',
        external_link: '',
        upload_file: '',
        publish_status: false,
      });
      setNewsFeedDataById('');
      setFormReset(true);
    }
  }, [NewsFeed.newsFeedUpdateDataError, NewsFeed.newsFeedUpdateDataNetworkError]);

  useEffect(() => {
    if (NewsFeed.newsFeedUpdateData) {
      const {
        data: { result },
      } = NewsFeed.newsFeedUpdateData;
      if (result) {
        setIsPublishModal(false);
        showSuccess(result);
        setImageFile();
        setIsResourceSelected();
        setSelectedCategory();
        setToggleRightBar(!toggleRightBar);
        setContent();
        setUploadedFile();
        setFormData({
          title: '',
          description: '',
          category_id: '',
          sub_category_id: '',
          upload_image: '',
          external_link: '',
          upload_file: '',
          publish_status: false,
        });
        setNewsFeedDataById('');
        setFormReset(true);
        setPageNumber(1);
        setNewsFeedList([]);
        window.scrollTo({ top: 0, behavior: 'auto' });
        dispatch(getAllNewsFeedRequest({ page: 1 }));
      }
    }
  }, [NewsFeed.newsFeedUpdateData]);

  useEffect(() => {
    if (NewsFeed.newsFeedChangeStatusData) {
      const {
        data: { result },
      } = NewsFeed.newsFeedChangeStatusData;
      if (result) {
        if (showChangeStatus) {
          showSuccess(t('NEWS_FEED.SHOW_PUBLISH'));
        } else {
          showSuccess(t('NEWS_FEED.SHOW_UNPUBLISH'));
        }
        setIsPublishModal(false);
        setImageFile();
        setIsResourceSelected();
        setSelectedCategory();
        setToggleRightBar(false);
        setContent();
        setUploadedFile();
        setFormData({
          title: '',
          description: '',
          category_id: '',
          sub_category_id: '',
          upload_image: '',
          external_link: '',
          upload_file: '',
          publish_status: false,
        });
        setFormReset(true);
        setPageNumber(1);
        setNewsFeedList([]);
        dispatch(getAllNewsFeedRequest({ page: 1 }));
      }
    }
  }, [NewsFeed.newsFeedChangeStatusData]);

  useEffect(() => {
    if (NewsFeed.newsFeedDeleteData) {
      const {
        data: { result },
      } = NewsFeed.newsFeedDeleteData;
      if (result) {
        setIsDeleteModal(false);
        showSuccess(result);
        setImageFile();
        setContent();
        setUploadedFile();
        setIsResourceSelected();
        setSelectedCategory();
        setToggleRightBar(false);
        setFormData({
          title: '',
          description: '',
          category_id: '',
          sub_category_id: '',
          upload_image: '',
          external_link: '',
          upload_file: '',
          publish_status: false,
        });
        setFormReset(true);
        setPageNumber(1);
        setNewsFeedList([]);
        dispatch(getAllNewsFeedRequest({ page: 1 }));
      }
    }
  }, [NewsFeed.newsFeedDeleteData]);

  useEffect(() => {
    if (NewsFeed.getAllNewsFeedData) {
      let new_list = [];
      const {
        data: { result },
      } = NewsFeed.getAllNewsFeedData;
      if (result) {
        if (newsFeedList) {
          if (pageNumber > 1) {
            new_list = newsFeedList.concat(result.results);
          } else {
            new_list = result.results;
          }
        } else {
          new_list = result.results;
        }
        setNewsFeedList(new_list);
        setPageCount(result?.count);
      } else {
        setNewsFeedList([]);
      }
    }
  }, [NewsFeed.getAllNewsFeedData]);

  useEffect(() => {
    return () => {
      dispatch(newsFeedAddSuccess({}));
      dispatch(getAllNewsFeedSuccess({}));
      dispatch(newsFeedUpdateSuccess({}));
      dispatch(getByIdNewsFeedSuccess({}));
      dispatch(newsFeedChangeStatusSuccess({}));
      dispatch(newsFeedDeleteSuccess({}));
      dispatch(newsFeedCategoriesSuccess({}));
      dispatch(newsFeedSubCategoriesSuccess({}));
    };
  }, []);
  useEffect(() => {
    if (
      filterChanges &&
      (!_.isEmpty(filterChanges.filterCategory) ||
        !_.isEmpty(filterChanges.filterTitle) ||
        !_.isEmpty(filterChanges.filterDate))
    ) {
      dispatch(
        getAllNewsFeedRequest({
          data: {
            newsfeed_category_id: filterChanges?.filterCategory?.id,
            title: filterChanges?.filterTitle,
            updated_date: filterChanges?.filterDate,
            status: '',
          },
        }),
      );
      setNewsFeedList([]);
      setPageNumber(1);
    } else {
      dispatch(getAllNewsFeedRequest({ page: 1 }));
    }
  }, [filterChanges]);

  useEffect(() => {
    if (fromListing) {
      afterUpdate(NewsFeed.getByIdNewsFeedData);
    }
  }, [NewsFeed.getByIdNewsFeedData]);

  useEffect(() => {
    if (selectedCategory && selectedCategory === HIDE_CATEGORIES.RESOURCES) {
      setIsResourceSelected(true);
    }
  }, [selectedCategory]);

  const afterUpdate = res => {
    if (res) {
      const {
        data: { result },
      } = res;
      if (result?.length) {
        setToggleRightBar(true);
        let arr = {
          title: result[0]?.title,
          description: result[0]?.content,
          category: {
            id: result[0]?.newsfeed_category_id,
            value: result[0]?.newsfeed_category_name,
            label: result[0]?.newsfeed_category_name,
          },
          type: {
            id: result[0]?.newsfeed_sub_category_id,
            name: result[0]?.newsfeed_sub_category_name,
            label: result[0]?.newsfeed_sub_category_name,
          },
          upload_image: result[0].media_name,
          upload_file: result[0].upload_file,
          external_link: result[0].external_link,
          start_date: result[0].event_date,
          location: result[0].event_location,
          event_time: result[0].event_time,
          publish_status: result[0].status,
        };
        setContent(result[0].content);
        if (result[0]?.event_time) setTime(result[0]?.event_time);
        setSelectedCategory(result[0].newsfeed_category_slug);
        setNewsFeedDataById(arr);
        let fileExtension = result[0].media_name.split('.').pop();
        let latest_file;
        if (fileExtension === FILE_EXTENSION.MP4) {
          latest_file = new File([result[0].media_name], result[0].media_name, {
            type: 'video/mp4',
          });
          setFileType('video');
        } else {
          latest_file = new File([result[0].media_name], result[0].media_name, {
            type: 'img/jpeg',
          });
          setFileType('image');
        }
        formData.title = result[0].title;
        formData.description = result[0].content;
        formData.category_id = result[0].newsfeed_category_id;
        formData.sub_category_id = result[0].newsfeed_sub_category_id;
        formData.upload_image = '';
        formData.newsfeed_id = result[0].id;
        formData.external_link = result[0].external_link;
        formData.location = result[0].event_location;
        formData.start_date = result[0].event_date;
        formData.event_time = result[0].event_time;
        formData.upload_file = '';
        formData.publish_status = result[0].status;

        setFormData({ ...formData });
        setImageFile(result[0].media_name);
        if (result[0].newsfeed_category_slug === 'resources') {
          setUploadedFile(result[0].upload_file);
          setIsResourceSelected(true);
        }
      }
    }
  };

  const handleSubmit = () => {
    if (!saveButtonDisabled) {
      if (isResourceSelected) {
        form.append('upload_file', formData?.upload_file);
      }
      if (selectedCategory === HIDE_CATEGORIES.EVENTS) {
        form.append('event_date', formData?.start_date);
        form.append('event_location', formData?.location);
        form.append('event_time', formData?.event_time);
      }
      form.append('upload_media', formData?.upload_image);
      form.append('newsfeed_category_id', formData?.category_id);
      form.append('newsfeed_sub_category_id', formData?.sub_category_id);
      form.append('title', formData?.title);
      form.append('content', formData?.description);
      form.append('external_link', formData?.external_link);
      form.append('status', `${formData?.publish_status}`);
      setFormReset(false);
      dispatch(newsFeedAddRequest(form));
    }
  };

  const handlePublishChanges = (data, { publish }) => {
    form.append('newsfeed_id', data.id);
    form.append('status', `${_.capitalize(publish)}`);
    dispatch(newsFeedChangeStatusRequest(form));
  };

  const handleReadMore = data => {
    history.push(`/news-feed-details-page?newsFeedId=${data.id}&&from=${'bo'}`);
  };

  const handleUpdate = () => {
    if (!saveButtonDisabled) {
      if (isResourceSelected) {
        form.append('upload_file', formData?.upload_file);
      }
      if (selectedCategory === HIDE_CATEGORIES.EVENTS) {
        form.append('event_date', formData?.start_date);
        form.append('event_location', formData?.location);
        form.append('event_time', formData?.event_time);
      }
      form.append('upload_media', formData?.upload_image);
      form.append('newsfeed_category_id', formData?.category_id);
      form.append(
        'newsfeed_sub_category_id',
        formData?.sub_category_id ? formData?.sub_category_id : '',
      );
      form.append('title', formData?.title);
      form.append('content', formData?.description);
      form.append('newsfeed_id', formData?.newsfeed_id);
      form.append('external_link', formData?.external_link);
      form.append('status', `${formData?.publish_status}`);
      setFormReset(false);
      dispatch(newsFeedUpdateRequest(form));
    }
  };

  const fileHandler = (value, fileExtension) => {
    if (fileExtension === FILE_EXTENSION.MP4) {
      setFileType('video');
    } else {
      setFileType('image');
    }

    formData.upload_image = value;
    setFormData({ ...formData });
    setImageFile(URL.createObjectURL(value));
  };

  const uploadFileHandler = value => {
    formData.upload_file = value;
    setFormData({ ...formData });
    setUploadedFile(value.name);
  };

  const closeRightBar = () => {
    setImageFile();
    setContent();
    setIsResourceSelected(false);
    setSelectedCategory();
    setUploadedFile();
    setFormData({
      title: '',
      description: '',
      category_id: '',
      sub_category_id: '',
      upload_image: '',
      external_link: '',
      upload_file: '',
      event_time: '',
      publish_status: false,
    });
    setFormReset(true);
    setFromListing(false);
    setNewsFeedDataById();
    dispatch(getByIdNewsFeedSuccess({}));
    setToggleRightBar(false);
  };

  const handleCategoryChange = data => {
    setSubCategoryList([]);
    formData.sub_category_id = '';
    formData.category_id = data.id;
    setFormData({ ...formData });
    setSelectedCategory(data.slug);
    if (data.slug === HIDE_CATEGORIES.RESOURCES) {
      setIsResourceSelected(true);
    } else {
      setIsResourceSelected(false);
    }
    dispatch(newsFeedSubCategoriesRequest({ category_id: data?.id }));
  };

  const handleSubCategoryChange = data => {
    formData.sub_category_id = data.id;
    setFormData({ ...formData });
  };

  const handleTitle = data => {
    formData.title = data;
    setFormData({ ...formData });
  };

  const handleDescription = data => {
    setContent(data);
    formData.description = data;
    setFormData({ ...formData });
  };

  const handleLink = data => {
    formData.external_link = data;
    setFormData({ ...formData });
  };

  const dateHandler = value => {
    formData.start_date = value;
    setFormData({ ...formData });
  };

  const timeHandler = value => {
    setTime(value);
    formData.event_time = value;
    setFormData({ ...formData });
  };
  const locationHandler = value => {
    formData.location = value;
    setFormData({ ...formData });
  };

  const publishHandler = value => {
    formData.publish_status = value;
    setFormData({ ...formData });
  };

  const handleDelete = value => {
    dispatch(newsFeedDeleteRequest({ newsfeed_id: value }));
  };

  const handlePublishModal = data => {
    setShowChangeStatus(data.status);
    setIsPublishModal(!isPublishModal);
    if (!isPublishModal) setUpdateNewsFeed(data);
  };

  const handleDeleteModal = data => {
    setIsDeleteModal(!isDeleteModal);
    if (!isDeleteModal) setUpdateNewsFeed(data);
  };

  const handleEdit = value => {
    setFromListing(true);
    dispatch(getByIdNewsFeedRequest({ newsfeed_id: value.id }));
    if (HIDE_CATEGORIES.RESOURCES === value.newsfeed_category_slug) {
      dispatch(newsFeedSubCategoriesRequest({ category_id: value?.newsfeed_category_id }));
    }
  };

  const handleFilterCategoryChange = data => {
    if (data.id === 'select') {
      filterChanges.filterCategory = '';
    } else {
      filterChanges.filterCategory = data;
    }
    setFilterChanges({ ...filterChanges });
  };

  const handleFilterTitleChange = data => {
    filterChanges.filterTitle = data;
    setFilterChanges({ ...filterChanges });
  };

  const filterDateHandler = data => {
    filterChanges.filterDate = data;
    setFilterChanges({ ...filterChanges });
  };

  const fetchNextNewsFeedData = () => {
    setPageNumber(pageNumber + 1);
    dispatch(
      getAllNewsFeedRequest({
        page: pageNumber + 1,
        data: {
          newsfeed_category_id: filterChanges?.filterCategory?.id,
          title: filterChanges?.filterTitle,
          updated_date: filterChanges?.filterDate,
          status: '',
        },
      }),
    );
  };
  const handleReset = () => {
    setFormData({});
    formData.title = '';
    formData.description = '';
    formData.category_id = '';
    formData.sub_category_id = '';
    formData.upload_image = '';
    formData.external_link = '';
    formData.upload_file = '';
    formData.start_date = '';
    formData.location = '';
    formData.publish_status = false;
    setFilterChanges(formData);
  };
  return (
    <>
      {isPublishModal && (
        <>
          <AlertModal
            isOpen={isPublishModal}
            isToggle={handlePublishModal}
            onClick={handlePublishChanges}
            data={updateNewsFeed}
            onPublishClick={true}
            onDeleteClick={false}
          />
        </>
      )}
      {isDeleteModal && (
        <>
          <AlertModal
            isOpen={isDeleteModal}
            isToggle={handleDeleteModal}
            onClick={handleDelete}
            data={updateNewsFeed}
            onDeleteClick={true}
            onPublishClick={false}
          />
        </>
      )}
      <div className="st-helper-sidebar">
        {toggleRightBar && (
          <CreateNewsForm
            propsData={{
              categoryList,
              subCategoryList,
              typeList,
              saveButtonDisabled,
              formReset,
              imageFile,
              fileType,
              uploadedFile,
              isResourceSelected,
              selectedCategory,
              content,
              time,
            }}
            initialValues={newsFeedDataById}
            handleUpdate={handleUpdate}
            fileHandler={fileHandler}
            uploadFileHandler={uploadFileHandler}
            closeRightBar={closeRightBar}
            handleCategoryChange={handleCategoryChange}
            handleSubCategoryChange={handleSubCategoryChange}
            handleTitle={handleTitle}
            handleDescription={handleDescription}
            handleLink={handleLink}
            handleSubmit={handleSubmit}
            dateHandler={dateHandler}
            timeHandler={timeHandler}
            locationHandler={locationHandler}
            publishHandler={publishHandler}
            isNewsFeedAddRequesting={NewsFeed.isNewsFeedAddRequesting}
            fromListing={fromListing}
            setFromListing={setFromListing}
          />
        )}
        <div className="static-resource-wrapper">
          {/* <Row className="">
            {!isHideBar && (
              <Col md={2} lg={2} className="filter-col">
                <div className="hide-bar">
                  <span className="cursor-ponter navyBlueColor ">
                    {t('FILTER.HIDE_BAR')}
                    <span>
                      <MdKeyboardArrowRight
                        className="hide-icon expand-margin"
                        onClick={() => setHideBar(!isHideBar)}
                        size={25}
                      />
                    </span>
                  </span>
                </div>
              </Col>
            )}
          </Row> */}
          <Row>
            {/* {!isHideBar ? ( */}
              <Col lg={2} md={2} className="filter-col">
                <div className={isHideBar ? "expand-filter-section-st news-filter-section" :"news-filter-section filter-section-st"}>
                  <Filter
                    isHideBar={isHideBar}
                    setHideBar={setHideBar}
                    categoryList={filterCategoryList}
                    subCategoryList={filterSubCategoriesList}
                    typeList={typeList}
                    handleFilterCategoryChange={handleFilterCategoryChange}
                    filterTitleChange={handleFilterTitleChange}
                    filterDateHandler={filterDateHandler}
                    handleReset={handleReset}
                    selectedFilterCategory={selectedFilterCategory}
                  />
                </div>
              </Col>
            {/* ) : (
              ''
            )} */}
            <Col lg={isHideBar ? 12 : 10} md={10} className={ isHideBar ? "expand-static-data-col-st" : "static-data-col-st"}>
              {/* {isHideBar && (
                <div className="filter-expand-bar">
                  <span className="cursor-ponter navyBlueColor ">
                    {t('FILTER.EXPAND_BAR')}
                    <span>
                      <MdKeyboardArrowLeft
                        className="hide-icon expand-margin"
                        onClick={() => setHideBar(!isHideBar)}
                        size={25}
                      />
                    </span>
                  </span>
                </div>
              )} */}
              <div className={isHideBar ? 'st-heading-left row mt-4' : 'manage-static-heading row'}>
                <span className="col-lg-1">
                  <img
                    src={RightArrow}
                    className="manage-static-header-back-button"
                    onClick={() => handleManageCropsBackButton()}
                  />
                </span>
                <span className="col-lg-5">
                  <h5>{t('NEWS_FEED.MANAGE_NEWS')}</h5>
                </span>
                <div
                  className="news-feed-filter-icon-text col-lg-6"
                >
                  <span 
                  className='manage-news-feed-cursor-pointer'
                       onClick={() => {
                    setToggleRightBar(!toggleRightBar);
                  }}
                   >
                  <BiPlus size={30} color="#2a547c" className="news-feed-add-style" 
                  />
                  <h5 className="st-filter-text news-feed-add-text">{t('NEWS_FEED.ADD_NEWS')} </h5>
                  </span>
                </div>
              </div>

              <div
                className={isHideBar ? 'st-tab-content mt-4 st-tab-margin' : 'st-tab-content mt-4'}
              >
                <Row>
                  <Row>
                    <Col lg="12">
                      <div className="st-card-wrapper st-card-wrapper-scroll">
                        <div className="loader-div">
                          {NewsFeed.isGetAllNewsFeedRequesting && <Loader isManageNewsFeed={true}/>}
                        </div>
                        {_.isEmpty(newsFeedList) && !NewsFeed.isGetAllNewsFeedRequesting && (
                          <p className="no-news-feed-records">No records found</p>
                        )}
                        <InfiniteScroll
                          dataLength={pageNumber * 10 < pageCount ? pageNumber * 10 : ''}
                          next={fetchNextNewsFeedData}
                          hasMore={pageNumber * 10 < pageCount ? true : false}
                          loader={
                            <h4>
                              {' '}
                              <center>Loading ...</center>{' '}
                            </h4>
                          }
                        >
                          {newsFeedList?.map((i,index) => {
                            return (
                              <Card className="news-feed-st-card" key={index}>
                                <div className="card-body">
                                  <Row className="news-col-gap">
                                    <Col md="3">
                                      <div className="news-feed-img-div">
                                        <img
                                          src={i?.media_name ? i?.media_name : DummyResourceImage}
                                          alt={'not-available'}
                                          className="news-feed-img"
                                        />
                                      </div>
                                    </Col>
                                    <Col md="8">
                                      <div className="card-body news-card-body">
                                        <h5 className="card-title font-weight-light st-txt-primary">
                                          {i?.title}
                                        </h5>
                                        {i?.content?.length > 100 ? (
                                          <div id="card-description">
                                            <div
                                              className="news-feed-raw-data-section"
                                              dangerouslySetInnerHTML={{
                                                __html: i?.content?.substring(0, 200),
                                              }}
                                            />
                                            <span>...</span>
                                            <a
                                              className="read-more"
                                              onClick={() => handleReadMore(i)}
                                            >
                                              {t('NEWS_FEED.READ_MORE')}
                                            </a>
                                          </div>
                                        ) : (
                                          <div dangerouslySetInnerHTML={{ __html: i?.content }} />
                                        )}
                                        <p className="card-text">
                                          {t('NEWS_FEED.CATEGORY')}: {i?.newsfeed_category_name}
                                        </p>
                                        {!_.isEmpty(i?.newsfeed_sub_category_name) && (
                                          <p className="card-text">
                                            {t('NEWS_FEED.SUB_CATEGORY')}:{' '}
                                            {i?.newsfeed_sub_category_name}
                                          </p>
                                        )}
                                        {!_.isEmpty(i?.updated_date) && (
                                          <p className="card-text">
                                            {t('NEWS_FEED.UPDATED_DATE')}:{' '}
                                            {moment(i?.updated_date).format(DISPLAY_DATE_FORMAT)}
                                          </p>
                                        )}
                                        {!_.isEmpty(i?.event_date) && (
                                          <p className="card-text">
                                            Event Date:{' '}
                                            {moment(i?.event_date).format(DISPLAY_DATE_FORMAT)}
                                          </p>
                                        )}
                                        {!_.isEmpty(i?.event_time) && (
                                          <p className="card-text">
                                            Event Time: {convertTimeFormat(i?.event_time)}
                                          </p>
                                        )}
                                        <div className="edit-delete-options">
                                          {i?.status === true ? (
                                            <>
                                              <i
                                                id="unpublish_news"
                                                data-tip
                                                data-for="unpublish_news"
                                                size={23}
                                                className="fa fa-check edit-btn publish-icon-style"
                                                onClick={() => handlePublishModal(i)}
                                              />
                                            </>
                                          ) : (
                                            <>
                                              <i
                                                id="publish_news"
                                                className="fa fa-times edit-btn  publish-icon-style"
                                                data-tip
                                                data-for="publish_news"
                                                size={23}
                                                onClick={() => handlePublishModal(i)}
                                              ></i>
                                            </>
                                          )}

                                          <i
                                            className="fa fa-pencil edit-btn"
                                            onClick={() => handleEdit(i)}
                                            id="edit-news"
                                            data-tip
                                            data-for="edit_news"
                                          ></i>

                                          <i
                                            className="fa fa-trash delete-btn"
                                            onClick={() => handleDeleteModal(i)}
                                            id="delete-news"
                                            data-tip
                                            data-for="delete_news"
                                          ></i>
                                        </div>
                                        <ReactTooltip id="edit_news" effect="solid" place="top">
                                          {t('NEWS_FEED.EDIT')}
                                        </ReactTooltip>
                                        <ReactTooltip id="delete_news" effect="solid" place="top">
                                          {t('NEWS_FEED.DELETE')}
                                        </ReactTooltip>
                                        <ReactTooltip id="publish_news" effect="solid" place="top">
                                          {t('NEWS_FEED.CLICK_PUBLISH')}
                                        </ReactTooltip>
                                        <ReactTooltip
                                          id="unpublish_news"
                                          effect="solid"
                                          place="top"
                                        >
                                          {t('NEWS_FEED.CLICK_UNPUBLISH')}
                                        </ReactTooltip>
                                      </div>
                                      
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            );
                          })}
                        </InfiniteScroll>
                      </div>
                    </Col>
                  </Row>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default ManageNews;

import React, { useEffect, useState } from 'react';
import { t } from 'react-multi-lang';
import { default as MultiDropdown } from 'react-multilevel-dropdown';
import { useHistory } from 'react-router-dom';

import Cookies from 'js-cookie';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from 'reactstrap';
import HeaderLogo from '../../assets/Images/dms-header-logo.svg';
import IWMI from '../../assets/Images/iwmi-logo-tools.svg';
import ProfileImage from '../../assets/Images/profile-placeholder.png';
import RightArrowIcon from '../../assets/Images/right_arrow_icon.svg';
import DataPopUp from '../../Components/common/DataModal';
import { getDmsPillarsRequest } from '../../redux/home/homeActions';
import Routes from '../../routes/routes';
import {
  countrySpecificScreenRoute,
  loginInHidden,
  menuBarMoreOptions,
  STATIC_RESOURCE_GET_CATEGORY_LIST,
} from '../constants/index';
import { handleLogoutRedirect, isStaffUser, isSuperAdmin } from './utils';

const NavbarComponent = () => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [systemType, setSystemType] = useState(
    <>
      {'Mali Drought'}
      <br />
      {'Management'}
      <br />
      {'System'}
    </>,
  );
  const [subHeadingList, setSubHeadingList] = useState();
  const dispatch = useDispatch();
  const [selectedDataSourceId, setSelectedDataSourceId] = useState();
  const [isDefaultSelectedSourceId, setIsDefaultSelectedSourceId] = useState(false);
  const [dataModal, setDataModal] = useState(false);
  const [countryData, setCountryData] = useState({});

  const togglePopUp = () => setDataModal(!dataModal);
  const URLParams = new URLSearchParams(window.location.search);
  const queryCountry = URLParams.get('Country');
  const toggle = () => setIsOpen(!isOpen);
  const dropdownToggle = () => setDropdownOpen(prevState => !prevState);
  const isUserLoggedIn = !!Cookies.get('user');
  const isLoginHidden = loginInHidden.includes(window.location.pathname);

  const TOOLS_MENU = [
    {
      name: t('LANDING_PAGE.MONITORING_EARLY_WARNING'),
      path: '/tools?selectedTool=monitoring_early_warning',
    },
    { name: t('LANDING_PAGE.RISK_IMPACT'), path: '/tools?selectedTool=risk_impact', subMenu: [] },
    {
      name: t('LANDING_PAGE.RISK_MITIGATION'),
      path: '/tools?selectedTool=risk_mitigation',
    },
  ];

  const COUNTRY_SPECIFIC_TOOLS_MENU = [
    {
      name: t('LANDING_PAGE.DROUGHT_INSIGHTS'),
      path: {
        pathname: `/drought-management-system`, // The page where the sections exist
        hash: '#drought-insigths', // Add the hash to navigate to the section
        search: `?Country=${queryCountry}`,
        state: {
          countryData: countryData,
        },
      },
    },
    // {
    //   name: t('LANDING_PAGE.RISK_IMPACT'),
    //   path: {
    //     pathname: `/drought-management-system`, // The page where the sections exist
    //     hash: '#drought-alerts', // Add the hash to navigate to the section
    //     search: `?Country=${queryCountry}`,
    //     state: {
    //       countryData: countryData,
    //     },
    //   },
    // },
  ];

  useEffect(() => {
    // ! COMMENTING HEADER TRANSPERANCY FUNCTIONALITY
    // const handleScroll = () => {
    //   const header = document.querySelector('.header-navbar');
    //   if (window.scrollY > 370) {
    //     header.classList.add('scroll-active');
    //   } else {
    //     header.classList.remove('scroll-active');
    //   }
    // };

    // window.addEventListener('scroll', handleScroll);

    dispatch(getDmsPillarsRequest({}));

    // return () => {
    //   window.removeEventListener('scroll', handleScroll);
    // };
  }, []);

  const { LandingPageData } = useSelector(state => ({
    LandingPageData: state.Home,
  }));

  const setDocumentTitle = countryName => {
    document.title = countryName
      ? `${countryName} Drought Monitor | IWMI`
      : 'Drought Monitor | IWMI';
  };

  useEffect(() => {
    if (LandingPageData?.getDmsPillarsData) {
      const { result } = LandingPageData.getDmsPillarsData.data || {};

      const earlyWarningPillar = result?.find(
        pillar => pillar?.pillar_type === 'monitoring_early_warning',
      );

      let selectedCountry;
      const countryInfo = localStorage.getItem('selectedCountry');
      // Convert the JSON string back to an object
      const countrySpecificData = JSON.parse(countryInfo);
      if (countrySpecificData) {
        selectedCountry = earlyWarningPillar?.values?.find(
          value => value?.country_slug === countrySpecificData,
        );
      } else {
        selectedCountry = earlyWarningPillar?.values?.find(
          value => value?.country_slug === queryCountry,
        );
      }
      if (selectedCountry) {
        localStorage.setItem('countryData', JSON.stringify(selectedCountry));
        localStorage.setItem('selectedCountry', JSON.stringify(selectedCountry?.country_slug));
        setCountryData(selectedCountry);
        setDocumentTitle(selectedCountry?.country_name);
      } else {
        setCountryData({});
        setDocumentTitle();
        localStorage.setItem('countryData', JSON.stringify({}));
      }
    }
  }, [LandingPageData.getDmsPillarsData, queryCountry]);

  useEffect(() => {
    let selectedSytem;
    if (!_.isEmpty(countryData)) {
      if (countryData.country_name === null || countryData.country_name === undefined) {
        selectedSytem = (
          <>
            {'Mali Drought'}
            <br />
            {'Management'}
            <br />
            {'System'}
          </>
        );
      } else if (countryData.country_name) {
        selectedSytem = (
          <>
            {`${countryData.country_name} Drought`}
            <br />
            {'Management'}
            <br />
            {'System'}
          </>
        );
      }
    } else {
      selectedSytem = (
        <>
          {'Mali Drought'}
          <br />
          {'Management'}
          <br />
          {'System'}
        </>
      );
    }
    setSystemType(selectedSytem);
  }, [countryData]);

  const handleChangePassword = () => {
    history.push('/change-password');
  };

  const redirectToDashBoard = () => {
    history.push('/settingsDashboard');
  };

  const handleLogout = () => {
    handleLogoutRedirect();
    if (_.isEmpty(countryData)) {
      history.push('/drought-management-system?Country=mali');
      const country = JSON.stringify({});
      localStorage.setItem('countryData', country);
      localStorage.setItem('selectedCountry', JSON.stringify(''));
      setCountryData({});
    } else {
      const countrySlug = countryData?.country_slug;

      if (countrySlug === 'southasia') {
        // Special case handling for South Asia
        history.push('/drought-management-system?Country=mali');
        const country = JSON.stringify({});
        localStorage.setItem('countryData', country);
        localStorage.setItem('selectedCountry', JSON.stringify(''));
        setCountryData({});
      } else {
        const sanitizedCountryData = { ...countryData };

        history.push({
          pathname: `/drought-management-system`,
          search: `?Country=${encodeURIComponent(countrySlug)}`,
          state: { countryData: sanitizedCountryData },
        });
      }
    }
    // history.push('login');
    // localStorage.setItem('countryData', JSON.stringify({}));
    // setCountryData({});
  };

  useEffect(() => {
    if (Routes) {
      let a = [];
      Routes.forEach(i => {
        if (i.visible) {
          a.push(i);
        }
      });
      setSubHeadingList(a);
    }
  }, [Routes]);

  const checkPages = () => {
    return (
      window.location.href.indexOf('home') === -1 &&
      window.location.href.indexOf('register') === -1 &&
      window.location.href.indexOf('login') === -1 &&
      window.location.href.indexOf('forgot-password') === -1 &&
      window.location.href.indexOf('reset-password') === -1 &&
      window.location.href.indexOf('change-password') === -1 &&
      window.location.href.indexOf('ManageCrops') === -1 &&
      window.location.href.indexOf('drought-management-system') === -1 &&
      window.location.href.indexOf('tools') === -1 &&
      window.location.href.indexOf('partners-donors') === -1 &&
      window.location.href.indexOf('news-events-reports') === -1 &&
      window.location.href.indexOf('contact-us') === -1 &&
      window.location.href.indexOf('about-us') === -1
    );
  };

  const handleMenuClick = (data, e) => {
    if (data.name === 'Public API') {
      history.push(isSuperAdmin() ? '/public-api' : data.path);
    } else if (data.name === 'Drought Management' && countryData) {
      // Handling Drought Management navigation
      const result = countryData.country_slug;
      const sanitizedCountryData = { ...countryData }; // Spread to create a shallow copy

      history.push({
        pathname: '/drought-monitor',
        search: `?Country=${encodeURIComponent(result)}&key=drought-monitor`,
        state: { countryData: sanitizedCountryData },
      });

      localStorage.setItem('countryData', JSON.stringify(sanitizedCountryData));
    } else if (data.name === 'Home' && countryData) {
      if (_.isEmpty(countryData)) {
        history.push('/drought-management-system?Country=mali');
        const country = JSON.stringify({});
        localStorage.setItem('countryData', country);
        localStorage.setItem('selectedCountry', JSON.stringify(''));
        setCountryData({});
      } else {
        const countrySlug = countryData?.country_slug;

        if (countrySlug === 'southasia') {
          // Special case handling for South Asia
          history.push(data.path);
          const country = JSON.stringify({});
          localStorage.setItem('countryData', country);
          localStorage.setItem('selectedCountry', JSON.stringify(''));
          setCountryData({});
        } else {
          const sanitizedCountryData = { ...countryData };
          history.push({
            pathname: `/drought-management-system`,
            search: `?Country=${encodeURIComponent(countrySlug)}`,
            state: { countryData: sanitizedCountryData },
          });
        }
      }
    } else {
      history.push(data.path);
    }
  };

  useEffect(() => {
    if (selectedDataSourceId) {
      if (countryData !== undefined) {
        let result = countryData.country_slug;

        history.push({
          pathname: '/weather-forecast',
          search: `?Country=${result}&dataSourceId=${selectedDataSourceId}&isDefaultSelectedSourceId=${isDefaultSelectedSourceId}`,
          state: {
            countryData: countryData,
          },
        });

        const country = JSON.stringify(countryData);
        localStorage.setItem('countryData', country);
      }
    }
  }, [selectedDataSourceId]);

  const handleSubCategory = (value, type, e) => {
    // let result;
    if (countryData !== undefined) {
      let result = countryData.country_slug;

      if (value === selectedDataSourceId) {
        history.push({
          pathname: '/weather-forecast',
          search: `?Country=${result}&dataSourceId=${selectedDataSourceId}&isDefaultSelectedSourceId=${isDefaultSelectedSourceId}`,
          state: {
            countryData: countryData,
          },
        });

        const country = JSON.stringify(countryData);
        localStorage.setItem('countryData', country);
        setIsDefaultSelectedSourceId(true);
      } else {
        setIsDefaultSelectedSourceId(true);
        setSelectedDataSourceId(value);
      }
    }
  };

  const handleRedirectToHome = () => {
    window.scrollTo(0, 0);
    history.push('/drought-management-system?Country=mali');
    const country = JSON.stringify({});
    localStorage.setItem('countryData', country);
    localStorage.setItem('selectedCountry', JSON.stringify(''));
    setCountryData({});
    toggle();
  };

  const onHandleLogin = () => {
    if (queryCountry && window.location.pathname === countrySpecificScreenRoute) {
      history.push({
        pathname: `/login`,
        search: `?Country=${queryCountry}&prevPath=drought-monitor`,
        state: {
          countryData: countryData,
        },
      });
    } else {
      history.push('/login');
    }
  };
  /**
   * Hiding Login Button. because we cannot control redirection of login system for small devices
   *
   */

  const renderLoginButton = () => {
    return (
      <>
        <NavItem className="ml-3 d-none d-md-block">
          <Button disabled={false} className="login-button" onClick={onHandleLogin}>
            {t('LANDING_PAGE.LOGIN_REGISTER')}
          </Button>
        </NavItem>
      </>
    );
  };

  /**
   * Hiding Login Button. because we cannot control redirection of login system
   *
   */
  const renderProfileDropdown = () => {
    return (
      <>
        <NavItem className="profile-item d-none d-md-block">
          <span className="profile">
            <Dropdown isOpen={dropdownOpen} toggle={dropdownToggle} className="profile-dropdown">
              <DropdownToggle className="text-black profile-user-btn">
                <NavLink className="profile-image">
                  <img className="profile-image" src={ProfileImage} alt="not-available" />
                </NavLink>
                {t('HEADER.ACCOUNT')}
              </DropdownToggle>
              <DropdownMenu className="profile-dropdown-menu">
                {isStaffUser() && (
                  <DropdownItem className="profile-dropdown-item" onClick={redirectToDashBoard}>
                    {t('LANDING_PAGE.SETTINGS')}
                  </DropdownItem>
                )}
                <DropdownItem className="profile-dropdown-item" onClick={handleChangePassword}>
                  {t('LANDING_PAGE.CHANGE_PASSWORD')}
                </DropdownItem>
                <DropdownItem className="profile-dropdown-item" onClick={handleLogout}>
                  {t('HEADER.LOGOUT')}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </span>
        </NavItem>
      </>
    );
  };

  const renderNavItem = (onClick, label) => (
    <NavItem>
      <NavLink onClick={onClick} className="cursor-pointer">
        {label}
      </NavLink>
    </NavItem>
  );

  const renderDmsMultiDropdown = () => (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav>{t('LANDING_PAGE.TOOLS')}</DropdownToggle>
      <DropdownMenu>
        {TOOLS_MENU.map(tool => (
          <DropdownItem
            onClick={e => {
              e.preventDefault(); // Prevents default behavior
              history.push(tool.path);
            }}
            className="cursor-pointer"
            key={tool.name}
          >
            {tool.name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const renderCountrySpecificMultiDropdown = () => (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav>{t('LANDING_PAGE.TOOLS')}</DropdownToggle>
      <DropdownMenu>
        {COUNTRY_SPECIFIC_TOOLS_MENU.map(tool => (
          <DropdownItem
            onClick={e => {
              e.preventDefault(); // Prevents default behavior
              history.push(tool.path);
            }}
            className="cursor-pointer"
            key={tool.name}
          >
            {tool.name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const renderDropdownResources = () => (
    <UncontrolledDropdown nav inNavbar className="d-none d-md-block">
      <DropdownToggle nav>{t('LANDING_PAGE.RESOURCES')}</DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          onClick={() => history.push('/partners-donors')}
          className="cursor-pointer"
          style={{ whiteSpace: 'break-spaces', padding: '1px 6px' }}
        >
          {t('LANDING_PAGE.PARTNERS_DONORS')}
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const NavigationDmsMenu = () => {
    if (_.isEmpty(countryData) || countryData?.country_slug === 'southasia') {
      return (
        <>
          {renderNavItem(handleRedirectToHome, t('LANDING_PAGE.HOME'))}
          {/* Mobile view */}
          <NavItem className="d-block d-md-none">
            <NavLink className="cursor-pointer">
              {t('LANDING_PAGE.TOOLS')}
              <ul className="dropdown-options-mb">
                {TOOLS_MENU.map(tool => (
                  <li
                    onClick={e => {
                      e.preventDefault(); // Prevents default behavior
                      history.push(tool.path);
                      toggle();
                    }}
                    className="cursor-pointer"
                    key={tool.name}
                  >
                    {tool.name}
                  </li>
                ))}
              </ul>
            </NavLink>
          </NavItem>
          <NavItem className="d-none d-md-block">
            <NavLink className="cursor-pointer">{renderDmsMultiDropdown()}</NavLink>
          </NavItem>
          {renderNavItem(() => {
            history.push('/news-events-reports');
            toggle();
          }, t('LANDING_PAGE.INSIGHTS'))}
          {renderDropdownResources()}
          {/* Mobile view */}
          <NavItem className="d-block d-md-none">
            <NavLink className="cursor-pointer">
              {t('LANDING_PAGE.RESOURCES')}
              <ul className="dropdown-options-mb">
                <li
                  onClick={() => {
                    history.push('/partners-donors');
                    toggle();
                  }}
                  className="cursor-pointer"
                  style={{ whiteSpace: 'break-spaces', padding: '1px 6px' }}
                >
                  {t('LANDING_PAGE.PARTNERS_DONORS')}
                </li>
              </ul>
            </NavLink>
          </NavItem>
        </>
      );
    }

    return null;
  };

  const NavigateCountrySpecificMenu = () => {
    if (_.isEmpty(countryData) || countryData?.country_slug !== 'southasia') {
      return (
        <>
          {renderNavItem(handleRedirectToHome, t('LANDING_PAGE.HOME'))}
          <NavItem>
            <NavLink className="cursor-pointer">{renderCountrySpecificMultiDropdown()}</NavLink>
          </NavItem>
          {renderNavItem(() => history.push('/news-events-reports'), t('LANDING_PAGE.INSIGHTS'))}
        </>
      );
    }

    return null;
  };

  return (
    <>
      <DataPopUp isOpen={dataModal} toggle={togglePopUp} />
      <Navbar expand="md" className="main-navbar header-navbar">
        <NavbarBrand
          className="navbarnd-container cursor-ponter"
          onClick={() => handleRedirectToHome()}
        >
          <img src={IWMI} alt="Logo" />
          <span className="divider"></span>
          <img src={HeaderLogo} alt="dms-logo" width={48} />
          <span className="drought-text">{systemType}</span>
        </NavbarBrand>
        <NavbarToggler onClick={toggle} style={{ marginRight: '10px', boxShadow: 'none' }} />
        <Collapse isOpen={isOpen} navbar className="justify-content-end navbar-dropdown-container">
          <Nav
            navbar
            className={
              _.isEmpty(countryData)
                ? ''
                : countryData?.country_slug === 'southasia'
                ? ''
                : ' justify-content-end'
            }
          >
            {_.isEmpty(countryData) || countryData?.country_slug === 'southasia'
              ? NavigationDmsMenu()
              : NavigateCountrySpecificMenu()}

            {!isUserLoggedIn && !isLoginHidden && renderLoginButton()}
            {isUserLoggedIn && !isLoginHidden && renderProfileDropdown()}
          </Nav>
        </Collapse>
      </Navbar>
      <div className="headernav-div">
        {checkPages() && (
          <Navbar className="public-api-navbar p-0 nav2" expand="md">
            <Nav className="mx-auto mobi-scroll w-100 justify-content-center" navbar>
              <ul className="nav-menu-items mobi-scroll">
                <li
                  key={0}
                  className={
                    window.location.href.includes(subHeadingList?.[0].path) ? 'nav-text active' : ''
                  }
                >
                  <span
                    className={
                      window.location.href.includes(subHeadingList?.[0].path) ? 'active-link' : ''
                    }
                    onClick={e => handleMenuClick(subHeadingList?.[0], e)}
                  >
                    <div className="api-block">
                      <span className="icon-color">{subHeadingList?.[0].icon}</span>
                      {subHeadingList?.[0].name}
                    </div>
                  </span>
                </li>
                <li
                  key={1}
                  className={
                    window.location.href.includes(subHeadingList?.[1].path) ? 'nav-text active' : ''
                  }
                >
                  <div className="api-block">
                    <MultiDropdown
                      title={subHeadingList?.[1].name}
                      className="weather-forecast-dropdown-icon"
                    >
                      {STATIC_RESOURCE_GET_CATEGORY_LIST?.map((i, index) => (
                        <>
                          <MultiDropdown.Item key={index} className="category-menu-item">
                            {i.category}
                            <span>
                              <img src={RightArrowIcon} alt={'not-available'} />
                            </span>
                            <MultiDropdown.Submenu position="right" className="weather-menusub-ul">
                              {i.data &&
                                i.data.map(submenu => (
                                  <>
                                    <MultiDropdown.Item
                                      className="weather-menu-sub"
                                      key={submenu.forecast_data_source.id}
                                      onClick={() =>
                                        handleSubCategory(submenu.forecast_data_source.id)
                                      }
                                    >
                                      {submenu.forecast_data_source.name}
                                    </MultiDropdown.Item>
                                  </>
                                ))}
                            </MultiDropdown.Submenu>
                          </MultiDropdown.Item>
                        </>
                      ))}
                    </MultiDropdown>
                  </div>
                </li>
                <li
                  key={3}
                  className={
                    window.location.href.includes(subHeadingList?.[3].path) ? 'nav-text active' : ''
                  }
                >
                  <span
                    className={
                      window.location.href.includes(subHeadingList?.[3].path) ? 'active-link' : ''
                    }
                    onClick={e => handleMenuClick(subHeadingList?.[3], e)}
                  >
                    <div className="api-block">
                      <span className="icon-color">{subHeadingList?.[3].icon}</span>
                      {/* &nbsp; &nbsp; */}
                      {subHeadingList?.[3].name}
                    </div>
                  </span>
                </li>
                <li
                  key={4}
                  className={
                    window.location.href.includes(subHeadingList?.[4].path)
                      ? 'nav-text active '
                      : ' custom-tooltip-country'
                  }
                >
                  <span
                    className={
                      window.location.href.includes(subHeadingList?.[4].path) ? 'active-link' : ''
                    }
                    onClick={e => handleMenuClick(subHeadingList?.[4], e)}
                    style={countryData?.country_slug !== 'india' ? { pointerEvents: 'none' } : {}}
                  >
                    <div className="api-block">
                      <span className="icon-color">{subHeadingList?.[4].icon}</span>
                      {/* &nbsp; &nbsp; */}
                      {subHeadingList?.[4].name}
                      {/* Custom Tooltip */}
                      {countryData?.country_slug !== 'india' && (
                        <div className="custom-tooltip-navbar">
                          Feature not available in your country
                        </div>
                      )}
                    </div>
                  </span>
                </li>

                <li
                  key={2}
                  className={
                    window.location.href.includes(subHeadingList?.[2].path) ? 'nav-text active' : ''
                  }
                >
                  <span
                    className={
                      window.location.href.includes(subHeadingList?.[2].path) ? 'active-link' : ''
                    }
                    onClick={e => handleMenuClick(subHeadingList?.[2], e)}
                  >
                    <div className="api-block">
                      <span className="icon-color">{subHeadingList?.[2].icon}</span>
                      {subHeadingList?.[2].name}
                    </div>
                  </span>
                </li>
              </ul>
              <UncontrolledDropdown className="link-text menubar-dropdown" nav inNavbar>
                <DropdownToggle nav caret className="dropdown-more">
                  {t('MENU_BAR.MORE')}
                </DropdownToggle>
                <DropdownMenu left="true" className="dropdown-more-menu">
                  <DropdownItem className="menu-drop-title" header>
                    {t('MENU_BAR.TOOLS')}
                    {/* <article className="menu-tool-desc">{t('MENU_BAR.TOOLS_DESC')}</article> */}
                  </DropdownItem>
                  <DropdownItem divider />
                  {menuBarMoreOptions.map((item, index) => {
                    // if(item.name==="Disclaimer"){
                    //   return(
                    //     <DropdownItem className="menu-drop-item" key={index} onClick={() => setDataModal(true)}>
                    //     {item.name}
                    //   </DropdownItem>
                    //   )
                    // }else{
                    return (
                      <DropdownItem className="menu-drop-item" href={item?.path}>
                        {item.name}
                      </DropdownItem>
                    );
                    //}
                  })}
                </DropdownMenu>
              </UncontrolledDropdown>
              <li className="disclaimer-text" onClick={() => setDataModal(true)}>
                <i className="cursor-ponter fa fa-exclamation-triangle info-icon-edit-btn1"></i>
                {t('Disclaimer')}
              </li>
            </Nav>
          </Navbar>
        )}
      </div>
    </>
  );
};

export default NavbarComponent;

import React from 'react';
import Header from './Header';
import { DistrictProfileTable } from './Table';
import { useDispatch } from 'react-redux';
import { IoMdDownload } from 'react-icons/io';
import List from './List';
import Loader from '../../../Components/common/loader';
import _ from 'lodash';
import { useTranslation } from 'react-multi-lang';
import EditModal from '../EditModal';
import { contingencyPlanDPEditRequest } from '../../../redux/contingencyPlan/contingencyPlanAction';
import {isSuperAdmin} from "../../../Components/common/utils"

const Index = props => {
  const t = useTranslation();
  const dispatch = useDispatch();

  const onHandleSaveEdit = () => {
    const changedValues = Object.keys(props.editValues).reduce((acc, key) => {
      if (props.editValues[key] !== props.selectedRow?.values?.[key]) {
        acc[key] = props.editValues[key];
      }
      return acc;
    }, {});
    // Implement save functionality here
    props?.setDistrictProfileTable([]);
    dispatch(
      contingencyPlanDPEditRequest({
        id: props.selectedRow?.original?.id,
        district_profile_category_id: props?.districtProfileCategoryId?.id,
        data: changedValues,
      }),
    );

    props.setSelectedRow();
  };
  return (
    <>
      <Header
        handleDistrictProfileCloseModal={props.handleDistrictProfileCloseModal}
        categoryList={props.categoryList}
        districtData={props.districtData}
        stateData={props.stateData}
        districtRainfallData={props.districtRainfallData}
        showRainfallGraphModal={props.showRainfallGraphModal}
        handleCategoryChange={props.handleCategoryChange}
        subCategoryList={props.subCategoryList}
        handleSubCategoryChange={props.handleSubCategoryChange}
        selectedCategory={props.selectedCategory}
        selectedSubCategory={props.selectedSubCategory}
        isCategoryLoader={props.isCategoryLoader}
        isSubCategoryLoader={props.isSubCategoryLoader}
        handleRainfallModalToggle={props.handleRainfallModalToggle}
      ></Header>
      <div className="contigency-plan-btn-alingnment">
        <h5 className="contingency-plan-table-title">
          {t('CONTINGENCY_PLAN.DISTRICT_PROFILE_TABLE_TITLE')}
        </h5>
        
        
      </div>
      {props.isDistrictProfileRequesting && <Loader />}
      {props.districtProfileView === 'list' ? (
        <List districtProfileList={props.districtProfileList} />
      ) : (
        props.districtProfileTable.rowData !== undefined && (
          <DistrictProfileTable
            districtProfileTable={props.districtProfileTable}
            setdistrictProfileCategoryId={props?.setdistrictProfileCategoryId}
            districtProfileCategoryId={props?.districtProfileCategoryId}
            formData={props?.formData}
            setDistrictProfileTable={props?.setDistrictProfileTable}
            setSelectedRow={props.setSelectedRow}
            setEditValues={props.setEditValues}
            selectedRow={props.selectedRow}
            editValues={props.editValues}
            setColumnHeader={props.setColumnHeader}
            columnHeader={props.columnHeader}
            userPermissions={props.userPermissions}
          ></DistrictProfileTable>
        )
      )}

      {props.selectedRow ? (
        <div>
          <EditModal
            setSelectedRow={props.setSelectedRow}
            setEditValues={props.setEditValues}
            selectedRow={props.selectedRow}
            editValues={props.editValues}
            setColumnHeader={props.setColumnHeader}
            columnHeader={props.columnHeader}
            onHandleSaveEdit={onHandleSaveEdit}
          />
        </div>
      ) : (
        ''
      )}
    </>
  );
};
export default Index;

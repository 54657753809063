import classnames from 'classnames';
import React from 'react';
import { useHistory } from 'react-router-dom';

import RightArrow from '../../assets/Images/back-button.svg';
import { MANAGE_DMS_LANDING_PAGE } from '../../Components/constants';

function Sidebar({ handleSelectOption, selectedOption }) {
  const history = useHistory();

  const handleBackToSettings = e => {
    e.preventDefault();
    history.push('/settingsDashboard');
  };

  return (
    <div className="dms-sidebar">
      <p className="dms-back mb-5" onClick={e => handleBackToSettings(e)}>
        <img src={RightArrow} alt="back-arrow" height={24} width={24} /> <span>Back</span>
      </p>

      {MANAGE_DMS_LANDING_PAGE.map(item => (
        <div
          className={classnames('dms-landing-page-options mb-2 w-100', {
            'dms-selected-option': selectedOption.key === item.key,
          })}
          key={item.key}
          onClick={() => handleSelectOption(item)}
        >
          {item.label}
        </div>
      ))}
    </div>
  );
}

export default Sidebar;

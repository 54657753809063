import React from 'react';
import { Modal, ModalBody, Row, Col } from 'reactstrap';
import { MdOutlineClose } from 'react-icons/md';
import PointData from './PointData';

const DataPopUp = props => {
  const { isOpen, toggle , countryJsonData,countryJson, setManuleMarker, pointsRef, mapLayers, setMapLayers} = props;

return (
    <>
      <div className="news-feed-alert-modal">
        <Row>
          <Col>
            <Modal
              className="modal-dialog modal-lg modal-dialog-centered"
              isOpen={isOpen}
              toggle={toggle}
              centered> 
              <div className="alert-heading">
                <div className="header-text">
            &nbsp;&nbsp;
            <h6 className="heading">Point data</h6>
            </div>
            <span className="cursor-ponter close-drought-icon-new" onClick={toggle}>
              <MdOutlineClose size={20} />
            </span>
            </div>
              <ModalBody className="text-center delete-modal-body">
               <PointData countryJsonData={countryJsonData} countryJson={countryJson} setManuleMarker={setManuleMarker} toggle={toggle} pointsRef={pointsRef} mapLayers={mapLayers} setMapLayers={setMapLayers}/>
		        </ModalBody>
            </Modal>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default DataPopUp;
import USER_CONST from './usersConst';

export const addUserRequest = () => ({
  type: USER_CONST.ADD_USER_REQUEST,
  payload: null,
});

export const addUserSuccess = (response) => ({
  type: USER_CONST.ADD_USER_SUCCESS,
  payload: response,
});

export const addUserFailed = (error) => ({
  type: USER_CONST.ADD_USER_FAILED,
  payload: error,
});

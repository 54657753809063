export const BULLETIN_CONSTS = {
  ADD_BULLETIN_REQUEST: 'ADD_BULLETIN_REQUEST',
  ADD_BULLETIN_SUCCESS: 'ADD_BULLETIN_SUCCESS',
  ADD_BULLETIN_FAILED: 'ADD_BULLETIN_FAILED',

  GET_ALL_BULLETIN_REQUEST: 'GET_ALL_BULLETIN_REQUEST',
  GET_ALL_BULLETIN_SUCCESS: 'GET_ALL_BULLETIN_SUCCESS',
  GET_ALL_BULLETIN_FAILED: 'GET_ALL_BULLETIN_FAILED',

  GET_ALL_TEMPLATE_LIST_REQUEST: 'GET_ALL_TEMPLATE_LIST_REQUEST',
  GET_ALL_TEMPLATE_LIST_SUCCESS: 'GET_ALL_TEMPLATE_LIST_SUCCESS',
  GET_ALL_TEMPLATE_LIST_FAILED: 'GET_ALL_TEMPLATE_LIST_FAILED',

  UPDATE_BULLETIN_REQUEST: 'UPDATE_BULLETIN_REQUEST',
  UPDATE_BULLETIN_SUCCESS: 'UPDATE_BULLETIN_SUCCESS',
  UPDATE_BULLETIN_FAILED: 'UPDATE_BULLETIN_FAILED',

  ADD_TEMPLATE_REQUEST: 'ADD_TEMPLATE_REQUEST',
  ADD_TEMPLATE_SUCCESS: 'ADD_TEMPLATE_SUCCESS',
  ADD_TEMPLATE_FAILED: 'ADD_TEMPLATE_FAILED',

  GET_TEMPLATE_REQUEST: 'GET_TEMPLATE_REQUEST',
  GET_TEMPLATE_SUCCESS: 'GET_TEMPLATE_SUCCESS',
  GET_TEMPLATE_FAILED: 'GET_TEMPLATE_FAILED',

  UPDATE_TEMPLATE_REQUEST: 'UPDATE_TEMPLATE_REQUEST',
  UPDATE_TEMPLATE_SUCCESS: 'UPDATE_TEMPLATE_SUCCESS',
  UPDATE_TEMPLATE_FAILED: 'UPDATE_TEMPLATE_FAILED',

  APPROVE_REJECT_BULLETIN_REQUEST: 'APPROVE_REJECT_BULLETIN_REQUEST',
  APPROVE_REJECT_BULLETIN_SUCCESS: 'APPROVE_REJECT_BULLETIN_SUCCESS',
  APPROVE_REJECT_BULLETIN_FAILED: 'APPROVE_REJECT_BULLETIN_FAILED',

  BULLETIN_PUBLISH_REQUEST: 'BULLETIN_PUBLISH_REQUEST',
  BULLETIN_PUBLISH_SUCCESS: 'BULLETIN_PUBLISH_SUCCESS',
  BULLETIN_PUBLISH_FAILED: 'BULLETIN_PUBLISH_FAILED',

  BULLETIN_DELETE_REQUEST: 'BULLETIN_DELETE_REQUEST',
  BULLETIN_DELETE_SUCCESS: 'BULLETIN_DELETE_SUCCESS',
  BULLETIN_DELETE_FAILED: 'BULLETIN_DELETE_FAILED',

  PREVIEW_BULLETIN_REQUEST: 'PREVIEW_BULLETIN_REQUEST',
  PREVIEW_BULLETIN_SUCCESS: 'PREVIEW_BULLETIN_SUCCESS',
  PREVIEW_BULLETIN_FAILED: 'PREVIEW_BULLETIN_FAILED',

  BULLETIN_SUBSCRIBE_REQUEST: 'BULLETIN_SUBSCRIBE_REQUEST',
  BULLETIN_SUBSCRIBE_SUCCESS: ' BULLETIN_SUBSCRIBE_SUCCESS',
  BULLETIN_SUBSCRIBE_FAILED: 'BULLETIN_SUBSCRIBE_FAILED',

  PREVIEW_TEMPLATE_REQUEST: 'PREVIEW_TEMPLATE_REQUEST',
  PREVIEW_TEMPLATE_SUCCESS: 'PREVIEW_TEMPLATE_SUCCESS',
  PREVIEW_TEMPLATE_FAILED: 'PREVIEW_TEMPLATE_FAILED',

  TEMPLATE_DELETE_REQUEST: 'TEMPLATE_DELETE_REQUEST',
  TEMPLATE_DELETE_SUCCESS: 'TEMPLATE_DELETE_SUCCESS',
  TEMPLATE_DELETE_FAILED: 'TEMPLATE_DELETE_FAILED',

  GET_BULLETIN_INDICATORS_REQUEST: 'GET_BULLETIN_INDICATORS_REQUEST',
  GET_BULLETIN_INDICATORS_SUCCESS: 'GET_BULLETIN_INDICATORS_SUCCESS',
  GET_BULLETIN_INDICATORS_FAILED: 'GET_BULLETIN_INDICATORS_FAILED',
};

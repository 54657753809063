import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'react-multi-lang';
import { Row, Col } from 'reactstrap';
import Menu from './Menu/index';
import { useHistory } from 'react-router-dom';
import NewsFeedContent from './NewsFeedContent/index';
import Footer from '../new-landing-page/Footer';
import {
  newsFeedCategoriesRequest,
  getAllNewsFeedRequest,
  subscribeNewsFeedRequest,
  subscribeNewsFeedSuccess,
} from '../../redux/newsFeed/newsFeedAction';
import _ from 'lodash';
import { isValidEmail, isLogedIn } from '../../Components/common/utils';
import { showSuccess, showError } from '../../Components/Notifications';
import Cookies from 'js-cookie';
import RightArrow from '../../assets/Images/back-button.svg';

const NewsFeed = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [categoriesList, setCategoriesList] = useState();
  const [selectedMenuItem, setSelectedMenuItem] = useState();
  const [newsFeedList, setNewsFeedList] = useState([]);
  const [email, setEmail] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [emailSubscribed, setEmailSubscribed] = useState(false);
  const [isSubscribeClicked, setIsSubscribeClicked] = useState(false);
  const [isEmailSubmit, setIsEmailSubmit] = useState(false);
  const nextProps = useSelector(state => ({
    newsFeedCategoriesListData: state.NewsFeed.newsFeedCategoriesListData,
    isGetAllNewsFeedRequesting: state.NewsFeed.isGetAllNewsFeedRequesting,
    getAllNewsFeedData: state.NewsFeed.getAllNewsFeedData,
    newsFeedSubscribeData: state.NewsFeed.newsFeedSubscribeData,
    isNewsFeedSubscribeRequesting: state.NewsFeed.isNewsFeedSubscribeRequesting,
    newsFeedSubscribeDataError: state.NewsFeed.newsFeedSubscribeDataError,
  }));

  useEffect(() => {
    if (!isLogedIn()) {
      let path = window.location.pathname;
      history.push(`/login?prevPath=${path.substring(1, path.length)}`);
    }
    window.scrollTo({ top: 0, behavior: 'instant' });
    dispatch(newsFeedCategoriesRequest());
  }, []);

  useEffect(() => {
    return () => {
      dispatch(subscribeNewsFeedSuccess({}));
    };
  }, []);

  useEffect(() => {
    if (nextProps.newsFeedCategoriesListData) {
      const {
        data: { result },
      } = nextProps.newsFeedCategoriesListData;
      if (result) {
        setCategoriesList(result);
        setSelectedMenuItem(result[0]);
        dispatch(
          getAllNewsFeedRequest({
            page: pageNumber,
            data: { newsfeed_category_id: result[0].id, status: 'true' },
          }),
        );
      }
    }
  }, [nextProps.newsFeedCategoriesListData]);

  useEffect(() => {
    if (nextProps.getAllNewsFeedData) {
      let news_list = [];
      let list = _.cloneDeep(newsFeedList);
      const {
        data: { result },
      } = nextProps.getAllNewsFeedData;
      if (result) {
        if (newsFeedList?.length) {
          if (pageNumber > 1) {
            news_list = list?.concat(result.results);
          } else {
            news_list = result?.results;
          }
        } else {
          news_list = result?.results;
        }
        setNewsFeedList(news_list);
        setPageCount(result?.count);
      } else {
        setNewsFeedList([]);
      }
    }
  }, [nextProps.getAllNewsFeedData]);

  useEffect(() => {
    if (nextProps.newsFeedSubscribeData) {
      const {
        data: { result },
      } = nextProps.newsFeedSubscribeData;
      if (result) {
        showSuccess(result);
        setIsEmailSubmit(false);
        setIsSubscribeClicked(false);
        setEmail();
        setEmailSubscribed(true);
      }
    }
  }, [nextProps.newsFeedSubscribeData]);

  useEffect(() => {
    if (nextProps.newsFeedSubscribeDataError) {
      const {
        data: { response },
      } = nextProps.newsFeedSubscribeDataError;
      if (response) {
        showError(response?.data?.message[0]);
        setIsEmailSubmit(false);
        setIsSubscribeClicked(false);
        setEmail();
      }
    }
  }, [nextProps.newsFeedSubscribeDataError]);

  const handleSelectedItem = value => {
    setNewsFeedList([]);
    setPageNumber(1);
    setPageCount(0);
    dispatch(
      getAllNewsFeedRequest({
        page: 1,
        data: { newsfeed_category_id: value.id, status: 'true' },
      }),
    );
    setSelectedMenuItem(value);
  };

  const subscribeButtonHandler = () => {
    setIsSubscribeClicked(true);
  };

  const subscribeLoggedInButtonHandler = () => {
    setIsSubscribeClicked(true);
    dispatch(subscribeNewsFeedRequest({ email: Cookies.get('loggedin_user_details') }));
  };

  const emailInputHandler = value => {
    setEmail(value);
    if (isValidEmail(value)) {
      setIsEmailSubmit(true);
    } else {
      setIsEmailSubmit(false);
    }
  };

  const emailSubmitHandler = () => {
    setIsEmailSubmit(false);
    dispatch(subscribeNewsFeedRequest({ email: email }));
  };

  const handleManageCropsBackButton = () => {
    //history.goBack();
    history.push('/drought-management-system?Country=mali');
  };

  return (
    <>
      <div className="news-feed-header">
        <Row className="news-feed-top-text">
          <Col xs={2} lg={2}>
            <img
              src={RightArrow}
              className="news-feed-header-back-button news-feed-backarrow-position"
              onClick={() => handleManageCropsBackButton()}
            />
          </Col>
          <Col xs={10} lg={10}>
            <h3 className="news-feed-header-h3 text-left">{t('NEWS_FEED.NEWS_FEED')}</h3>
          </Col>
        </Row>
      </div>
      <div className="">
        <div className="w-100">
          <Row className="content-section">
            <Col xs={3} md={3} lg={3} className="news_feed_menu">
              <Menu
                selectedMenuItem={selectedMenuItem}
                handleSelectedItem={handleSelectedItem}
                categoriesList={categoriesList}
              />
            </Col>
            <Col xs={9} md={9} lg={9} className="news_feed_data">
              <NewsFeedContent
                categoriesList={categoriesList}
                selectedMenuItem={selectedMenuItem}
                newsFeedList={newsFeedList}
                pageNumber={pageNumber}
                pageCount={pageCount}
                setPageCount={setPageCount}
                setPageNumber={setPageNumber}
                subscribeButtonHandler={subscribeButtonHandler}
                subscribeLoggedInButtonHandler={subscribeLoggedInButtonHandler}
                isSubscribeClicked={isSubscribeClicked}
                emailInputHandler={emailInputHandler}
                isEmailSubmit={isEmailSubmit}
                emailSubmitHandler={emailSubmitHandler}
                isSubscribeLoading={nextProps.isNewsFeedSubscribeRequesting}
                emailSubscribed={emailSubscribed}
                isNewsFeedRequesting={nextProps.isGetAllNewsFeedRequesting}
              />
            </Col>
          </Row>
        </div>
      </div>
      <Footer publicFooter={false} />
    </>
  );
};
export default NewsFeed;

export const USER_GUIDE_CONSTS = {
  ADD_USER_GUIDE_REQUEST: 'ADD_USER_GUIDE_REQUEST',
  ADD_USER_GUIDE_SUCCESS: 'ADD_USER_GUIDE_SUCCESS',
  ADD_USER_GUIDE_FAILED: 'ADD_USER_GUIDE_FAILED',

  GET_ALL_TOPICS_REQUEST: ' GET_ALL_TOPICS_REQUEST',
  GET_ALL_TOPICS_SUCCESS: ' GET_ALL_TOPICS_SUCCESS',
  GET_ALL_TOPICS_FAILED: ' GET_ALL_TOPICS_FAILED',

  GET_ALL_USER_GUIDE_REQUEST: 'GET_ALL_USER_GUIDE_REQUEST',
  GET_ALL_USER_GUIDE_SUCCESS: 'GET_ALL_USER_GUIDE_SUCCESS',
  GET_ALL_USER_GUIDE_FAILED: 'GET_ALL_USER_GUIDE_FAILED',

  GET_All_TOPICS_LIST_REQUEST: 'GET_All_TOPICS_LIST_REQUEST',
  GET_All_TOPICS_LIST_SUCCESS: 'GET_All_TOPICS_LIST_SUCCESS',
  GET_All_TOPICS_LIST_FAILED: 'GET_All_TOPICS_LIST_FAILED',

  UPDATE_USER_GUIDE_REQUEST: 'UPDATE_USER_GUIDE_REQUEST',
  UPDATE_USER_GUIDE_SUCCESS: 'UPDATE_USER_GUIDE_SUCCESS',
  UPDATE_USER_GUIDE_FAILED: 'UPDATE_USER_GUIDE_FAILED',

  DELETE_USER_GUIDE_REQUEST: 'DELETE_USER_GUIDE_REQUEST',
  DELETE_USER_GUIDE_SUCCESS: 'DELETE_USER_GUIDE_SUCCESS',
  DELETE_USER_GUIDE_FAILED: 'DELETE_USER_GUIDE_FAILED',

  CHANGE_STATUS_USER_GUIDE_REQUEST: 'CHANGE_STATUS_USER_GUIDE_REQUEST',
  CHANGE_STATUS_USER_GUIDE_SUCCESS: 'CHANGE_STATUS_USER_GUIDE_SUCCESS',
  CHANGE_STATUS_USER_GUIDE_FAILED: 'CHANGE_STATUS_USER_GUIDE_FAILED',

  SEARCH_BY_CRITERIA_USER_GUIDE_REQUEST: 'SEARCH_BY_CRITERIA_USER_GUIDE_REQUEST',
  SEARCH_BY_CRITERIA_USER_GUIDE_SUCCESS: 'SEARCH_BY_CRITERIA_USER_GUIDE_SUCCESS',
  SEARCH_BY_CRITERIA_USER_GUIDE_FAILED: 'SEARCH_BY_CRITERIA_USER_GUIDE_FAILED',

  SEARCH_CONTENT_USER_GUIDE_REQUEST: 'SEARCH_CONTENT_USER_GUIDE_REQUEST',
  SEARCH_CONTENT_USER_GUIDE_SUCCESS: 'SEARCH_CONTENT_USER_GUIDE_SUCCESS',
  SEARCH_CONTENT_USER_GUIDE_FAILED: 'SEARCH_CONTENT_USER_GUIDE_FAILED',
};

import moment from 'moment';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-multi-lang';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Row, Card, Col, CardTitle, CardBody, CardSubtitle, CardText } from 'reactstrap';
import Loader from '../../../Components/common/loader';
import { DISPLAY_DATE_FORMAT } from '../../../Components/constants/index';
import {
  NEWS_FEED_LENGTH_CONSTANT,
  NEWS_FEED_URL_CONSTANT,
} from '../../../Components/constants';
import { htmlToText } from '../../../Components/common/utils';

const Resources = props => {
  const { newsFeedList, isNewsFeedRequesting } = props;
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const t = useTranslation();
  const [subCategoriesFilteredList, setSubCategoriesFilteredList] = useState([]);
  const [rssFeedData, setRssFeedData] = useState();
  const [secondaryRssFeedData, setSecondaryRssFeedData] = useState();

  useEffect(() => {
    const axios = require('axios');
    axios.get(NEWS_FEED_URL_CONSTANT.RESOURCE_URL).then(response => {
      setSecondaryRssFeedData(response.data);
    });
  }, []);

  useEffect(() => {
    if (secondaryRssFeedData?.items) {
      let le = secondaryRssFeedData.items?.slice(0, 6);
      le.sort(function(a, b) {
        return new Date(b.pubDate) - new Date(a.pubDate);
      });
      setRssFeedData({ ...secondaryRssFeedData, items: le });
      setPageCount(secondaryRssFeedData?.items?.length);
    }
  }, [secondaryRssFeedData]);

  useEffect(() => {
    if (newsFeedList?.length) {
      let newList = subCategoriesFilteredList;
      newsFeedList.map(i => {
        let arr = [];
        subCategoriesFilteredList.map(x => arr?.push(x.newsfeed_sub_category_id));
        if (arr?.includes(i?.newsfeed_sub_category_id)) {
          return newList;
        } else {
          return newList.push({
            newsfeed_sub_category_id: i?.newsfeed_sub_category_id,
            newsfeed_sub_category_name: i?.newsfeed_sub_category_name,
          });
        }
      });
      setSubCategoriesFilteredList([...newList]);
    }
  }, [newsFeedList]);

  useEffect(() => {
    let subCatIdList = [];
    let newList = [];
    if (subCategoriesFilteredList?.length) {
      subCategoriesFilteredList.map(data => {
        subCatIdList = newsFeedList.filter(
          i => data.newsfeed_sub_category_id === i.newsfeed_sub_category_id,
        );
        newList.push({
          subCategoryId: data.newsfeed_sub_category_id,
          subCategoryName: data.newsfeed_sub_category_name,
          resources_list: subCatIdList,
        });
      });
    }
  }, [subCategoriesFilteredList]);

  const fetchNextTemplateData = () => {
    let pageNuM = pageNumber + 1;
    setPageNumber(pageNuM);
    if (secondaryRssFeedData?.items) {
      let news_list = [];
      let list = _.cloneDeep(rssFeedData?.items);
      let hasMoreData =
        rssFeedData?.items?.length + 6 < secondaryRssFeedData?.items?.length ? true : false;
      let data = secondaryRssFeedData?.items?.slice(
        rssFeedData?.items?.length,
        hasMoreData ? rssFeedData?.items?.length + 6 : secondaryRssFeedData?.items?.length,
      );
      let updatedData = data;
      if (rssFeedData?.items?.length) {
        if (pageNuM > 1) {
          news_list = updatedData?.length ? list?.concat(updatedData) : list;
        }
      } else {
        news_list = list;
      }
      news_list.sort(function(a, b) {
        return new Date(b.pubDate) - new Date(a.pubDate);
      });
      setRssFeedData({ ...rssFeedData, items: news_list });
    } else {
      setRssFeedData([]);
    }
  };

  return (
    <div className="iwmi-content-section news-feed-cls">
      <div className="content-title">
        <span className="">{t('NEWS_FEED.PUBLICATIONS')}</span>
        <hr className="title-hr" />
      </div>
      <div className="">
        {isNewsFeedRequesting && <Loader />}
        <InfiniteScroll
          dataLength={pageNumber * 6 < pageCount ? pageNumber * 6 : ''}
          next={() => fetchNextTemplateData()}
          hasMore={pageNumber * 6 < pageCount ? true : false}
          loader={
            isNewsFeedRequesting && (
              <center>
                <h4>{t('BULLET_IN.LOADING')} </h4>
              </center>
            )
          }
          className="flex flex-wrap"
        >
          <Row>
            {rssFeedData?.items.map((i,index) => {
              return (
                <Col xs="4" md="4" lg="4" sm="4" key={index} className="iwmi-card-section">
                  <Card className="news-feed-card iwmi-card">
                    <CardBody className="iwmi-event-resource-card-body">
                      <CardTitle className="news-feed-card-title">
                        <a
                          className="news-feed-title-link"
                          href={i?.link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {i?.title?.length > NEWS_FEED_LENGTH_CONSTANT.SIXTY
                            ? `${i?.title.substring(0, 60)}... `
                            : i?.title}
                        </a>
                      </CardTitle>
                      <div className="news-feed-card-content">
                      {htmlToText(i.content)?.length > 170 ? (
                            <div id="card-description">
                              <div className="news-feed-raw-data-section">
                              {htmlToText(i.content).substring(0, 100)}
                              <span>...</span>
                            </div>
                            </div>
                        ) : (
                          <div dangerouslySetInnerHTML={{ __html: i?.content }} />
                        )}
                      </div>
                      <CardSubtitle className="news-feed-card-sub-title">
                        {t('BULLET_IN.PUBLISHED_ON') +
                          ' ' +
                          moment(i?.pubDate).format(DISPLAY_DATE_FORMAT)}
                      </CardSubtitle>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </InfiniteScroll>
      </div>
    </div>
  );
};
export default Resources;

import React, { useEffect, useState } from 'react';
import {useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, Row, Col, ModalFooter } from 'reactstrap';
import { t } from 'react-multi-lang';
import _ from 'lodash';
import { Field, reduxForm } from 'redux-form';
import {
  renderReactSelectInputField,
} from '../../Components/common/renderInputField';
import Button from 'reactstrap-button-loader';
import {MONTHS} from "../../Components/constants"

const Addmodalcrop = (props) => {
  const {handleSubmit} = props;
  const [fromDaysList, setFromDaysList] = useState();
  const [toDaysList, setToDaysList] = useState();

  const addtoggle = () => {
    props.handleSetModal(!props.modal);
    props.handleResetInitialValues();
  };
  const nextProps = useSelector((state) => ({
    isManageCropsUpdateRequesting: state.ManageCrops.isManageCropsUpdateRequesting,
  }));
  useEffect(() => {
    if(props.initialValues){
      if(props.initialValues?.from_month?.label){
        let days = [];
        MONTHS.map((data) => {
          if(data.label === props.initialValues.from_month.label){
            for(var i=1;i<=data.days;i++){
            days.push({
              value: i,
              label: i,
              id: i,
            })
          }
          setFromDaysList(days);
        }
      })
     }
     else{
       setFromDaysList([]);
     }
     if(props.initialValues?.to_month?.label){
      let days = [];
      MONTHS.map((data) => {
        if(data.label === props.initialValues.to_month.label){
          for(var i=1;i<=data.days;i++){
          days.push({
            value: i,
            label: i,
            id: i,
          })
        }
      }
    })
    setToDaysList(days);
     }
     else{
      setToDaysList([])
     }
    }
  },[props.initialValues])

const handleSelectedItem = (item) => {
  props.handleSelectedSeason(item);
}

 const handleAddClick = (value) => {
  props.handleAddClick(value);
 } 

 const handleUpdate = () => {
   props.handleUpdate();
 }

 const renderAPIParameterOptions = (list) => {
  if (list) {
    let params = [...list];
    let options = [];
    if (params.length) {
      params.map((param) => {
        let data = {
          value: param.label,
          label: `${param.label}`,
          id: param.value
        };
        options.push(data);
        return true;
      });
      return options;
    }
  }
};

const handleCancel = () => {
  props.handleSetModal(!props.modal);
  props.handleResetInitialValues();
}

const onFormSubmit = () => {
  props.handleAddClick('add');
}

const renderBreadCrumbs = (values) => {
  if(props.editButtonClicked){
    return <p>{props.initialValues?.breadCrumbs}</p>
  }
  else{
    return <p>{values?.country?.label}  { values?.state?.label ? " | " + values?.state?.label : ""}  {values?.district?.label ? " | " + values?.district?.label : ""}</p>
  }
}


const handleFromMonthChange = (item) => {
  let days = [];
  MONTHS.map((data) => {
    if(data.label === item.label){
      for(var i=1;i<=data.days;i++){
      days.push({
        value: i,
        label: i,
        id: i,
      })
    }
  }
})
setFromDaysList(days);
props.handleFromMonth(item);
}

const handleToMonthChange = (item) => {
  let days = [];
  MONTHS.map((data) => {
    if(data.label === item.label){
      for(var i=1;i<=data.days;i++){
      days.push({
        value: i,
        label: i,
        id: i,
      })
    }
  }
})
setToDaysList(days);
props.handleToMonth(item);
}

const handleToDayChange = (item) => {
  props.handleToDay(item);
}



const handleFromDayChange = (item) => {
  props.handleFromDay(item);
}


return (
    <div className="add-crop-modal">
      {props.hideAddButton && 
      <button className="crop-button" 
      onClick={addtoggle} 
      disabled={props.addModalDisabled}>
      {t('MANAGE_CROP.ADD_CROP')}
      </button>
      }
      <Modal isOpen={props.modal} toggle={addtoggle} className="crop-modal-sec modal-sm">
      <form id="filter-form" onSubmit={handleSubmit(onFormSubmit)}>
        <ModalHeader className="addcrop-modal-header" toggle={addtoggle}>
        {props.editButtonClicked ? "Edit": t('MANAGE_CROP.ADD_CROP') }
        </ModalHeader>
        <ModalBody className="pt-0 addcrop-modal-body">
          <Row>
            <Col className="drought-mod">
              <div className="">
                <Field
            name="breadCrumbs"
            component={() => renderBreadCrumbs(props.breadCrumbsData)}
            type="text"
          />
                <div className="">
                <Field
                      component={renderReactSelectInputField}
                      name="season"
                      className="input-style-css"
                      placeholder="Type Crop Season"
                      type="select"
                      onChange={handleSelectedItem}
                      required={true}
                      isMulti={false}
                      options={renderAPIParameterOptions(props.seasonsList)}
                    > 
                      </Field>
                      <Row className="manage-crop-row">
                        <Col xs="2" md="2" lg="2">
                          <span>From</span>
                        </Col>
                        <Col xs="5" md="5" lg="5">
                        <Field
                      component={renderReactSelectInputField}
                      name="from_month"
                      className="input-style-css"
                      placeholder="Month"
                      type="select"
                      onChange={handleFromMonthChange}
                      required={true}
                      isMulti={false}
                      options={renderAPIParameterOptions(MONTHS)}
                    > 
                    </Field>
                        </Col>
                        <Col xs="5" md="5" lg="5">
                        <Field
                      component={renderReactSelectInputField}
                      name="from_day"
                      className="input-style-css"
                      placeholder="Day"
                      type="select"
                      onChange={handleFromDayChange}
                      required={true}
                      isMulti={false}
                      options={renderAPIParameterOptions(fromDaysList)}
                    > 
                    </Field>
                        </Col>
                      </Row>
                      <Row className="manage-crop-row">
                        <Col xs="2" md="2" lg="2">
                          <span>To</span>
                        </Col>
                        <Col xs="5" md="5" lg="5">
                        <Field
                      component={renderReactSelectInputField}
                      name="to_month"
                      className="input-style-css manage-crop-modal-dropdown"
                      placeholder="Month"
                      type="select"
                      onChange={handleToMonthChange}
                      required={true}
                      isMulti={false}
                      options={renderAPIParameterOptions(MONTHS)}
                    > 
                    </Field>
                        </Col>
                        <Col xs="5" md="5" lg="5">
                        <Field
                      component={renderReactSelectInputField}
                      name="to_day"
                      className="input-style-css manage-crop-modal-dropdown"
                      placeholder="Day"
                      type="select"
                      onChange={handleToDayChange}
                      required={true}
                      isMulti={false}
                      options={renderAPIParameterOptions(toDaysList)}
                    > 
                    </Field>
                        </Col>
                      </Row>
                </div>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="crop-modal-footer">
          <Button className="crop-cancle-button" onClick={() => handleCancel()}>{t('MANAGE_CROP.CANCEL')}</Button>
         {!props.editButtonClicked ? 
           (<Button 
            loading={props.isManageCropsAddRequesting} 
            disabled={props.addButtonDisabled} 
            className="crop-add-button" 
         onClick={() => handleAddClick('add')}
          >{t('MANAGE_CROP.ADD')}</Button>):
           (<Button loading={nextProps.isManageCropsUpdateRequesting} disabled={props.editButtonDisabled} className="crop-add-button"
           onClick={() => handleUpdate()} 
          >{t('MANAGE_CROP.UPDATE')}</Button>)}
      </ModalFooter>
      </form>
      </Modal>
    </div>
  );
};

export default reduxForm({
  form: 'filter-form',
  enableReinitialize: true,
})(Addmodalcrop);
import React from 'react';

const Loader = (props) => {
  return (
    <>
      <div className="loader-container">
      <div className={props?.isManageNewsFeed? "manage-new-feed-loader" :'loader'}>
        <div className="loadingio-spinner-spinner-m63cop84tg">
              <div className="ldio-3ucpol5dcb2">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default Loader;

import React, { useEffect, useMemo, useState } from 'react';
import { useTable, usePagination, useSortBy, useGlobalFilter } from 'react-table';
import PropTypes from 'prop-types';
import Detailsdropdown from './DetailsDropdown';
import DisabledLeftArrowIcon from '../../assets/Images/disable_previous_image.svg';
import DisabledRightArrowIcon from '../../assets/Images/disable_next_image.svg';
import EnabledLeftArrowIcon from '../../assets/Images/enable_previous_image.svg';
import EnabledRightArrowIcon from '../../assets/Images/enable_next_image.svg';
import { useTranslation } from 'react-multi-lang';
import { Row, Col, Table } from 'reactstrap';
import { USER_LIST_CONST_NA } from '../../Components/constants';

export const UserListTable = props => {
  const { loading = true, activeTab, handleReset } = props;
  const t = useTranslation();
  const [selectedPageTab, setSelectedPageTab] = useState();

  const COLUMNS = [
    {
      Header: t('PUBLIC_API_ADMIN.NAME'),
      accessor: row => (row.name ? row.name : USER_LIST_CONST_NA),
      sticky: 'top',
    },
    {
      Header: t('PUBLIC_API_ADMIN.EMAIL'),
      accessor: row => (row.email ? row.email : USER_LIST_CONST_NA),
      sticky: 'top',
    },
    {
      Header: t('PUBLIC_API_ADMIN.COUNTRY'),
      accessor: row => (row.country ? row.country : USER_LIST_CONST_NA),
      sticky: 'top',
    },
    {
      Header: t('USER_LIST.PHONE'),
      accessor: row => (row.mobile_number ? row.mobile_number : USER_LIST_CONST_NA),
      sticky: 'top',
    },
    {
      Header: t('USER_LIST.ROLE'),
      accessor: row => (row.role ? row.role : USER_LIST_CONST_NA),
      sticky: 'top',
    },
  ];

  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => props.req_data, [props.req_data]);
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  useEffect(() => {
    if (props.selectedTab) {
      setSelectedPageTab(props.selectedTab);
    }
  }, [props.selectedTab]);

  const pageSizeHandler = e => {
    setPageSize(Number(e.target.value));
    props.handlePageSize(Number(e.target.value));
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    setPageSize,
    prepareRow,
  } = tableInstance;
  const { pageSize } = state;
  const page_range = [10, 15, 20, 25];

  const handlepreviousPage = value => {
    props.handlePageNumber(props.pageNumber - 1);
  };
  const handlenextPage = value => {
    props.handlePageNumber(props.pageNumber + 1);
  };
  const handleGoToPage = e => {
    props.handlePageNumber(Number(e.target.value));
  };

  return (
    <>
      <div className="">
        <Table {...getTableProps()} className="table-list">
          <thead className="table-list-th">
            {headerGroups.map(headerGroup => (
              <>
                <tr {...headerGroup.getHeaderGroupProps()} className="user-list-keys">
                  {headerGroup.headers.map(column => {
                    return (
                      <>
                        <th
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          className="name-list"
                          style={{ width: '18%' }}
                        >
                          {column.render('Header')}
                          <span className="sort-icons">
                            {!column.notShowSortingDisplay ? (
                              column.isSorted ? (
                                column.isSortedDesc ? (
                                  <>
                                    <i className="fa fa-sort-down"></i>
                                  </>
                                ) : (
                                  <>
                                    <i className="fa fa-sort-up"></i>
                                  </>
                                )
                              ) : (
                                <i className="fa fa-sort"></i>
                              )
                            ) : (
                              ''
                            )}
                          </span>
                        </th>
                      </>
                    );
                  })}
                  <th style={{ width: '10%' }}>&nbsp;</th>
                </tr>
              </>
            ))}
          </thead>
          {loading ? (
            <tbody className="table-body">
              <tr>
                <td colSpan="10000" className="text-center">
                  <img src="" alt="Loading..." />
                </td>
              </tr>
            </tbody>
          ) : (
            <>
              {page.length === 0 ? (
                <tbody>
                  <tr>
                    <h5 className="" style={{ marginLeft: '45%', marginTop: '15%' }}>
                      {t('USER_LIST.NO_RECORDS_FOUND')}
                    </h5>
                  </tr>
                </tbody>
              ) : (
                <tbody
                  {...getTableBodyProps()}
                  className="body table-wrapper-scroll-y my-custom-scrollbar"
                >
                  {page.map((row, index) => {
                    prepareRow(row);
                    return (
                      <>
                        <tr {...row.getRowProps()} className="user-list-details">
                          {row.cells.map(cell => {
                            return (
                              <>
                                {cell.column.Header === 'Role' ? (
                                  <>
                                    <td
                                      {...cell.getCellProps()}
                                      style={{ width: '18%' }}
                                      className=" name-list-details e-div"
                                    >
                                      {cell.render('Cell')}
                                    </td>
                                    <td style={{ width: '10%', paddingTop: 0 }}>
                                      <Detailsdropdown
                                        request_Id={row.original.user_id}
                                        email={row.original.email}
                                        key={index}
                                        handleLatestData={props.handleLatestData}
                                        selectedPageTab={selectedPageTab}
                                        latestData={props.latestData}
                                        activeTab={activeTab}
                                        handleReset={handleReset}
                                      />
                                    </td>
                                  </>
                                ) : (
                                  <td
                                    {...cell.getCellProps()}
                                    className=" name-list-details e-div"
                                    style={{ width: '18%' }}
                                  >
                                    {cell.render('Cell')}
                                  </td>
                                )}
                              </>
                            );
                          })}
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              )}
            </>
          )}
        </Table>
        <div className="pagination-section">
          <Row>
            <Col md="6" lg="6">
              <span className="item-text">{t('PUBLIC_API_ADMIN.ITEMS_PER_PAGE')}</span>
              <select value={pageSize} onChange={e => pageSizeHandler(e)} className="page-count">
                {page_range.map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
              <span className="num-of-items">
                {(props.pageNumber - 1) * pageSize + 1}-
                {props.pageCount < props.pageNumber * pageSize
                  ? props.pageCount
                  : props.pageNumber * pageSize}{' '}
                of {props.pageCount ? props.pageCount : ''} items
              </span>
            </Col>
            <Col md="6" lg="6">
              <div className="pagination-right-col">
                <span>
                  <input
                    type="number"
                    value={props.pageNumber}
                    onChange={e => {
                      handleGoToPage(e);
                    }}
                    disabled={
                      (props.pageCount % pageSize
                        ? Math.trunc(props.pageCount / pageSize + 1)
                        : Math.trunc(props.pageCount / pageSize)) === props.pageNumber ||
                      (props.pageCount % pageSize
                        ? Math.trunc(props.pageCount / pageSize + 1)
                        : Math.trunc(props.pageCount / pageSize)) === 0
                        ? true
                        : false
                    }
                    style={{ width: '50px', border: 'none' }}
                  />
                </span>
                <span>
                  of{' '}
                  {props.pageCount % pageSize
                    ? Math.trunc(props.pageCount / pageSize + 1)
                    : Math.trunc(props.pageCount / pageSize)}{' '}
                  Pages
                </span>
                <span>
                  <button
                    onClick={() => handlepreviousPage()}
                    className="previous-btn"
                    disabled={props.pageNumber <= 1 ? true : false}
                  >
                    {props.pageNumber <= 1 ? (
                      <img src={DisabledLeftArrowIcon} alt={'disabled icon'} />
                    ) : (
                      <img src={EnabledLeftArrowIcon} alt="enabled icon" />
                    )}
                  </button>
                  <button
                    onClick={() => handlenextPage()}
                    className="next-btn"
                    disabled={
                      (props.pageCount % pageSize
                        ? Math.trunc(props.pageCount / pageSize + 1)
                        : Math.trunc(props.pageCount / pageSize)) === props.pageNumber ||
                      (props.pageCount % pageSize
                        ? Math.trunc(props.pageCount / pageSize + 1)
                        : Math.trunc(props.pageCount / pageSize)) === 0
                        ? true
                        : false
                    }
                  >
                    {(props.pageCount % pageSize
                      ? Math.trunc(props.pageCount / pageSize + 1)
                      : Math.trunc(props.pageCount / pageSize)) === props.pageNumber ||
                    (props.pageCount % pageSize
                      ? Math.trunc(props.pageCount / pageSize + 1)
                      : Math.trunc(props.pageCount / pageSize)) === 0 ? (
                      <img src={DisabledRightArrowIcon} alt={'disaled right arrow'} />
                    ) : (
                      <img src={EnabledRightArrowIcon} alt={'enabled right arrow'} />
                    )}
                  </button>
                </span>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
UserListTable.propTypes = {
  req_data: PropTypes.array,
  handleLatestData: PropTypes.func,
  selectedTab: PropTypes.string,
  latestData: PropTypes.func,
  handlePageSize: PropTypes.func,
  pageCount: PropTypes.number,
  handlePageNumber: PropTypes.func,
  pageNumber: PropTypes.number,
  recordsCount: PropTypes.number,
  activeTab: PropTypes.string,
};

import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import { t } from 'react-multi-lang';
import {DROUGHT_MANAGEMENT_MODULE_ID,DROUGHT_MODULES_CONST,  DROUGHT_MONITOR_MODULE ,FINAL_DROUGHT_MAP,SPI,SPI_GPM,CHECK_DROUGHT} from '../../Components/constants/index'

const IndicesInfoIcons = props => {


  const handleInfo = e => {
    e?.stopPropagation();
    // TODO Remove Hard coded text in compare 
    if(props.dss && props?.indicesName === FINAL_DROUGHT_MAP){
      window.open(`/user-guide-details?topicId=${DROUGHT_MANAGEMENT_MODULE_ID.DSS}&&sub-topic-name=${props?.indicesName}&topic_name=${props?.selectedModule?.label}`);
    }else{
      switch (props?.selectedModule?.label) {
        case DROUGHT_MODULES_CONST[0].label:
          window.open(`/user-guide-details?topicId=${DROUGHT_MANAGEMENT_MODULE_ID.DROUGHT_PREDICTION}&&sub-topic-name=${props?.indicesName}&topic_name=${props?.selectedModule?.label}`);
          break;
        case DROUGHT_MODULES_CONST[1].label:
          window.open(`/user-guide-details?topicId=${DROUGHT_MANAGEMENT_MODULE_ID.DROUGHT_MONITOR}&&sub-topic-name=${props?.indicesName}&topic_name=${ DROUGHT_MONITOR_MODULE.label}`);
          break;
        case DROUGHT_MODULES_CONST[2].label:
          if(props?.indicesName === SPI){
          window.open(`/user-guide-details?topicId=${DROUGHT_MANAGEMENT_MODULE_ID.DROUGHT_MONITOR}&&sub-topic-name=${SPI_GPM}&topic_name=${props?.selectedModule?.label}`);
          }else if(props?.indicesName === CHECK_DROUGHT){
          window.open(`/user-guide-details?topicId=${DROUGHT_MANAGEMENT_MODULE_ID.DSS}&&sub-topic-name=${props?.indicesName}&topic_name=${props?.selectedModule?.label}`);
          }
          else{
          window.open(`/user-guide-details?topicId=${DROUGHT_MANAGEMENT_MODULE_ID.DROUGHT_MONITOR}&&sub-topic-name=${props?.indicesName}&topic_name=${props?.selectedModule?.label}`);
          }
          break;
        case DROUGHT_MODULES_CONST[3].label:
          window.open(`/user-guide-details?topicId=${DROUGHT_MANAGEMENT_MODULE_ID.OTHER_INDICES}&&sub-topic-name=${props?.indicesName}&topic_name=${props?.selectedModule?.label}`);
          break;
        case  DROUGHT_MONITOR_MODULE.label:
          window.open(`/user-guide-details?topicId=${DROUGHT_MANAGEMENT_MODULE_ID.DROUGHT_MONITOR}&&sub-topic-name=${props?.indicesName}&topic_name=${props?.selectedModule?.label}`);
          break;
    }
    }
    
  };


  if (props.dss) {
    return (
      <li className=" d-inline-block">
        <div
          className={
            'indices-info-sidebar-location bg-white info-icon-dss  d-inline-block position-absolute end-0'
          }
        >
          <span className={'info-style'}>
            <i
              className="cursor-ponter fa fa-info info-icon-edit-btn"
              id="info-icon-tool-tip"
              data-tip
              data-for="info-icon-tool-tip"
              onClick={handleInfo}
            />
            <ReactTooltip id="info-icon-tool-tip" effect="solid" place="top">
              {t('DROUGHT_MONITORING.CLICK_HERE_TO_KNOW_MORE')}
            </ReactTooltip>
          </span>
        </div>
      </li>
    );
  } 
  else if(props.contingencyPlan){
    return(
    <li className=" d-inline-block">
    <div
      className={
        'indices-info-sidebar-location-cp d-inline-block  end-0'
      }
    >
      <span className={'info-style'}>
        <i
          className="cursor-ponter fa fa-info info-icon-edit-btn"
          id="info-icon-tool-tip"
          data-tip
          data-for="info-icon-tool-tip"
          onClick={handleInfo}
        />
        <ReactTooltip id="info-icon-tool-tip" effect="solid" place="top">
          {t('DROUGHT_MONITORING.CLICK_HERE_TO_KNOW_MORE')}
        </ReactTooltip>
      </span>
    </div>
  </li>)
  }
  else {
    return (
      <li className=" d-inline-block">
        <div
          className={
            props.checked
              ? 'indices-info-sidebar-location bg-white info-icon-on-check  d-inline-block position-absolute end-0'
              : 'indices-info-sidebar-location bg-white info-icon  d-inline-block position-absolute end-0'
          }
        >
          <span className={'info-style'}>
            <i
              className="cursor-ponter fa fa-info info-icon-edit-btn"
              id="info-icon-tool-tip"
              data-tip
              data-for="info-icon-tool-tip"
              onClick={handleInfo}
            />
            <ReactTooltip id="info-icon-tool-tip" effect="solid" place="top">
              {t('DROUGHT_MONITORING.CLICK_HERE_TO_KNOW_MORE')}
            </ReactTooltip>
          </span>
        </div>
      </li>
    );
  }
};

export default IndicesInfoIcons;

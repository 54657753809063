import React, { useEffect, useRef, useState } from 'react';
import DraggableList from 'react-draggable-list';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'react-multi-lang';
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from 'reactstrap';

import OrderIcon from '../../assets/Images/drag-icon.svg';
import { showError, showSuccess } from '../../Components/Notifications';
import Loader from '../../Components/common/TextLoader';
import { isSuperAdmin } from '../../Components/common/utils';
import { ReactComponent as AddIcon } from '../../assets/Images/add-icon.svg';
import { ReactComponent as LinkIcon } from '../../assets/Images/link-icon.svg';
import NoResultIcon from '../../assets/Images/no-results-icon.svg';
import {
  addLandingPageRequest,
  addLandingPageSuccess,
  deleteLandingPageRequest,
  deleteLandingPageSuccess,
  editLandingPageRequest,
  editLandingPageSuccess,
  getDmsPartnersDonorsRequest,
  getDmsPartnersDonorsSuccess,
} from '../../redux/home/homeActions';
import DeleteModal from './DeleteModal';
import NewPartnersModal from './NewPartnersModal';

function ManagePartners({ title, userPermisions }) {
  const containerRef = useRef();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [partnersData, setPartnersData] = useState([]);
  const [selectedPartner, setSelectedPartners] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeDropdownId, setActiveDropdownId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    partner_donor_url: '',
    icon: null,
    is_fo: false,
  });

  const { LandingPageData } = useSelector(state => ({
    LandingPageData: state.Home,
  }));

  useEffect(() => {
    dispatch(getDmsPartnersDonorsRequest({}));

    return () => {
      dispatch(getDmsPartnersDonorsSuccess({}));
      dispatch(editLandingPageSuccess({}));
      dispatch(addLandingPageSuccess({}));
      dispatch(deleteLandingPageSuccess({}));
    };
  }, [dispatch]);

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (LandingPageData.isGetDmsPartnersDonorsRequesting) {
      setIsLoading(true);
    } else if (LandingPageData.getDmsPartnersDonorsData) {
      const data = LandingPageData.getDmsPartnersDonorsData.data;
      if (data && Array.isArray(data.result)) {
        setPartnersData(data.result);
      }
      setIsLoading(false);
    } else if (
      LandingPageData.getDmsPartnersDonorsDataError ||
      LandingPageData.getDmsPartnersDonorsDataNetworkError
    ) {
      showError(t('LANDING_PAGE.SOMETHING_WENT_WORNG'));
      setIsLoading(false);
    }
  }, [
    LandingPageData.getDmsPartnersDonorsData,
    LandingPageData.getDmsPartnersDonorsDataError,
    LandingPageData.getDmsPartnersDonorsDataNetworkError,
    LandingPageData.isGetDmsPartnersDonorsRequesting,
  ]);

  const isAddRef = useRef(true);
  useEffect(() => {
    if (isAddRef.current) {
      isAddRef.current = false;
      return;
    }
    if (LandingPageData.isAddLandingPageRequesting) {
      setIsLoading(true);
    } else if (LandingPageData?.addLandingPageData?.data) {
      showSuccess(LandingPageData?.addLandingPageData?.data?.result);
      dispatch(getDmsPartnersDonorsRequest({}));
      setIsLoading(false);
      setFormData({
        name: '',
        partner_donor_url: '',
        icon: null,
        is_fo: false,
      });
    } else if (
      LandingPageData.addLandingPageDataError ||
      LandingPageData.addLandingPageDataNetworkError
    ) {
      const errorMessageArray =
        LandingPageData.addLandingPageDataError?.response?.data?.response?.data?.message || [];
      const [errorMessage] = errorMessageArray;

      showError(errorMessage);
      setIsLoading(false);
    }
  }, [
    LandingPageData?.addLandingPageData,
    LandingPageData.isAddLandingPageRequesting,
    LandingPageData.addLandingPageDataError,
    LandingPageData.addLandingPageDataNetworkError,
    dispatch,
  ]);

  const isEditRef = useRef(true);
  useEffect(() => {
    if (isEditRef.current) {
      isEditRef.current = false;
      return;
    }
    if (LandingPageData.iseditLandingPageRequesting) {
      setIsLoading(true);
      setIsEdit(false);
    } else if (LandingPageData?.editLandingPageData?.data) {
      showSuccess(LandingPageData?.editLandingPageData?.data?.result);
      dispatch(getDmsPartnersDonorsRequest({}));
      setIsLoading(false);
      setFormData({
        name: '',
        partner_donor_url: '',
        icon: null,
        is_fo: false,
      });
      setIsEdit(false);
    } else if (
      LandingPageData.editLandingPageDataError ||
      LandingPageData.editLandingPageDataNetworkError
    ) {
      const errorMessageArray =
        LandingPageData.editLandingPageDataError?.response?.data?.response?.data?.message || [];
      const [errorMessage] = errorMessageArray;

      showError(errorMessage);
      setIsLoading(false);
      setIsEdit(false);
    }
  }, [
    LandingPageData.editLandingPageData,
    LandingPageData.iseditLandingPageRequesting,
    LandingPageData.editLandingPageDataError,
    LandingPageData.editLandingPageDataNetworkError,
    dispatch,
  ]);

  const isDeleteRef = useRef(true);
  useEffect(() => {
    if (isDeleteRef.current) {
      isDeleteRef.current = false;
      return;
    }
    if (LandingPageData.isDeleteLandingPageRequesting) {
      setIsLoading(true);
    } else if (LandingPageData?.deleteLandingPageData?.data) {
      showSuccess(LandingPageData?.deleteLandingPageData?.data?.result);
      dispatch(getDmsPartnersDonorsRequest({}));
      setIsLoading(false);
      setFormData({
        name: '',
        partner_donor_url: '',
        icon: null,
        is_fo: false,
      });
    } else if (
      LandingPageData.deleteLandingPageDataError ||
      LandingPageData.deleteLandingPageDataNetworkError
    ) {
      showError(LandingPageData.deleteLandingPageDataError?.response?.data?.message);
      setIsLoading(false);
    }
  }, [
    LandingPageData?.deleteLandingPageData,
    LandingPageData.isDeleteLandingPageRequesting,
    LandingPageData.deleteLandingPageDataError,
    LandingPageData.deleteLandingPageDataNetworkError,
    dispatch,
  ]);

  const toggleDropdown = id => {
    setActiveDropdownId(prevId => (prevId === id ? null : id));
  };

  const handleEdit = editData => {
    const userData = {
      name: editData.name,
      partner_donor_url: editData.partner_donor_url,
      icon: editData.file_url,
      id: editData.id,
      is_fo: editData.is_fo,
    };
    setFormData(userData);
    setIsModalOpen(true);
    setIsEdit(true);
  };

  const handleDelete = data => {
    setSelectedPartners(data);
    setDeleteModal(true);
  };

  const onHandleDelete = () => {
    setDeleteModal(false);
    dispatch(
      deleteLandingPageRequest({
        id: selectedPartner?.id,
        end_point: 'delete_partners_donors',
        query: 'partner_donor_id',
      }),
    );
  };

  const onHandlePartners = data => {
    const submissionData = new FormData();

    // Append form fields to FormData
    if (data.name) submissionData.append('name', data.name);
    if (data.partner_donor_url) submissionData.append('partner_donor_url', data.partner_donor_url);

    if (data.icon) {
      submissionData.append('icon', data.icon);
    }

    if (data.is_fo) {
      submissionData.append('is_fo', 'True');
    } else {
      submissionData.append('is_fo', 'False');
    }
    if (isEdit) {
      // * MAKE SURE TO SEND IT AS submissionData
      submissionData.append('partner_donor_id', formData.id);
      dispatch(editLandingPageRequest({ submissionData, end_point: 'update_partners_donors' }));
      setIsEdit(false);
    } else {
      dispatch(addLandingPageRequest({ submissionData, end_point: 'add_partners_donors' }));
    }
  };

  const _onListChange = (newList, e, old, newIndex) => {
    const submissionData = {
      order: newIndex + 1,
      partner_donor_id: e.id,
    };

    setTimeout(() => {
      dispatch(editLandingPageRequest({ submissionData, end_point: 'update_partners_donors' }));
    }, 1000);

    setPartnersData(newList);
  };

  const Item = ({ item, dragHandleProps }) => {
    const { onMouseDown, onTouchStart } = dragHandleProps;

    return (
      <>
        <Col xs={12} key={item.id} className="d-flex align-items-start">
          <img
            src={OrderIcon}
            alt="order-icon"
            className="dms-drag-icon"
            onTouchStart={e => {
              onTouchStart(e);
            }}
            onMouseDown={e => {
              onMouseDown(e);
            }}
          />
          <Card className="partners-card w-100">
            <CardBody className="d-flex flex-column py-0 h-100 m-0">
              <Row className="h-100 m-0">
                <Col
                  xs={1}
                  className=" d-flex justify-content-center align-items-center dms-partners-img-container"
                >
                  <img src={item.file_url} alt="Avatar" className="dms-partners-image" />
                </Col>
                <Col xs={11} className="d-flex flex-column justify-content-center">
                  <CardTitle className="dms-partners-title">
                    {item.name ? item.name : 'N/A'}{' '}
                    {isSuperAdmin() ||
                    userPermisions.some(permission =>
                      ['change_dmspartnersdonors', 'delete_dmspartnersdonors'].includes(permission),
                    ) ? (
                      <Dropdown
                        isOpen={activeDropdownId === item.id}
                        toggle={() => toggleDropdown(item.id)}
                        direction="left"
                        className=" d-inline w-auto"
                      >
                        <DropdownToggle
                          tag="span"
                          data-toggle="dropdown"
                          aria-expanded={activeDropdownId === item.id}
                          className="dms-pillar-list-dropdown-toggle"
                        >
                          <i className="fa fa-ellipsis-v dms-toggle-size" aria-hidden="true"></i>
                        </DropdownToggle>
                        <DropdownMenu className=" p-0">
                          {isSuperAdmin() || userPermisions.includes('change_dmspartnersdonors') ? (
                            <DropdownItem
                              onClick={() => handleEdit(item)}
                              className="dms-dropdown-options dms-dropdown-options-border"
                            >
                              {t('LANDING_PAGE.EDIT_DETAILS')}
                            </DropdownItem>
                          ) : null}
                          {isSuperAdmin() || userPermisions.includes('delete_dmspartnersdonors') ? (
                            <DropdownItem
                              onClick={() => handleDelete(item)}
                              className="dms-dropdown-options dms-dropdown-options-delete"
                            >
                              {t('LANDING_PAGE.DELETE')}
                            </DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </Dropdown>
                    ) : null}
                  </CardTitle>
                  <CardText
                    className="dms-partners-link dms-partners-link-hide"
                    onClick={() => window.open(item.partner_donor_url, '_blank')}
                  >
                    <LinkIcon height={20} width={20} style={{ marginRight: '10px' }} />
                    <span className="trucate-link" title={item.partner_donor_url}>
                      {item.partner_donor_url}
                    </span>
                  </CardText>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      {isModalOpen ? (
        <NewPartnersModal
          modal={isModalOpen}
          setModal={setIsModalOpen}
          onHandlePartners={onHandlePartners}
          setFormData={setFormData}
          formData={formData}
          isEditable={isEdit}
        />
      ) : null}
      {deleteModal ? (
        <DeleteModal
          isOpen={deleteModal}
          toggle={() => setDeleteModal(!deleteModal)}
          message={`This will delete the Partners or Donors from “${selectedPartner?.name}” and will not be able to see it.`}
          onHandleDelete={onHandleDelete}
        />
      ) : null}
      <div className="dms-title-container">
        <h1 className="dms-title">{title.label}</h1>
        {isSuperAdmin() || userPermisions.includes('add_dmspartnersdonors') ? (
          <Button
            className="dms-add-edit-btn"
            onClick={() => {
              setIsModalOpen(!isModalOpen);
              setIsEdit(false);
            }}
          >
            {t('LANDING_PAGE.ADD_NEW')} <AddIcon height={20} width={20} />
          </Button>
        ) : null}
      </div>
      <Container fluid>
        <Row>
          {!isLoading ? (
            partnersData?.length ? (
              <div ref={containerRef} className="scrollable-list-container">
                <DraggableList
                  itemKey="id"
                  template={Item}
                  list={partnersData}
                  onMoveEnd={(newList, e, oldIndex, newIndex) =>
                    _onListChange(newList, e, oldIndex, newIndex)
                  }
                  container={() => containerRef.current}
                />
              </div>
            ) : (
              <div className=" h-100 d-flex justify-content-center align-items-center flex-column p-5">
                <img src={NoResultIcon} alt="No results to show" className=" d-block" />
                <p className="no-result-text">{t('LANDING_PAGE.NO_RESULTS_FOUND')}</p>
              </div>
            )
          ) : null}
        </Row>
      </Container>
    </>
  );
}

export default ManagePartners;

import MAP_PREFERENCE_CONST from './mapPreferenceConst';

const Map_Preference = (state = {}, action) => {
  switch (action.type) {
    case MAP_PREFERENCE_CONST.GET_MAP_PREFERENCE_REQUEST:
      return {
        ...state,
        isMapPreferenceRequesting: true,
        mapPreferenceData: false,
        mapPreferenceDataError: false,
        mapPreferenceDataNetworkError: false,
      };
    case MAP_PREFERENCE_CONST.GET_MAP_PREFERENCE_SUCCESS:
      return {
        ...state,
        isMapPreferenceRequesting: false,
        mapPreferenceData: action.payload.response,
        mapPreferenceDataError: false,
        mapPreferenceDataNetworkError: false,
      };
    case MAP_PREFERENCE_CONST.GET_MAP_PREFERENCE_FAILED:
      return {
        ...state,
        isMapPreferenceRequesting: false,
        mapPreferenceData: false,
        mapPreferenceDataError: action.payload.error ? false : action.payload.response,
        mapPreferenceDataNetworkError: action.payload.error ? action.payload.error : false,
      };

    case MAP_PREFERENCE_CONST.GET_MAP_PREFERENCES_REQUEST:
        return {
          ...state,
          isMapPreferencesRequesting: true,
          mapPreferencesData: false,
          mapPreferencesDataError: false,
          mapPreferencesDataNetworkError: false,
        };
      case MAP_PREFERENCE_CONST.GET_MAP_PREFERENCES_SUCCESS:
        return {
          ...state,
          isMapPreferencesRequesting: false,
          mapPreferencesData: action.payload.response,
          mapPreferencesDataError: false,
          mapPreferencesDataNetworkError: false,
        };
      case MAP_PREFERENCE_CONST.GET_MAP_PREFERENCES_FAILED:
        return {
          ...state,
          isMapPreferencesRequesting: false,
          mapPreferencesData: false,
          mapPreferencesDataError: action.payload.error ? false : action.payload.response,
          mapPreferencesDataNetworkError: action.payload.error ? action.payload.error : false,
        };
      case MAP_PREFERENCE_CONST.GET_MAP_PREFERENCE_BOUNDARIES_REQUEST:
        return {
          ...state,
          isMapPreferenceBoundariesRequesting: true,
          mapPreferenceBoundariesData: false,
          mapPreferenceBoundariesDataError: false,
          mapPreferenceBoundariesDataNetworkError: false,
        };
      case MAP_PREFERENCE_CONST.GET_MAP_PREFERENCE_BOUNDARIES_SUCCESS:
        return {
          ...state,
          isMapPreferenceBoundariesRequesting: false,
          mapPreferenceBoundariesData: action.payload.response,
          mapPreferenceBoundariesDataError: false,
          mapPreferenceBoundariesDataNetworkError: false,
        };
      case MAP_PREFERENCE_CONST.GET_MAP_PREFERENCE_BOUNDARIES_FAILED:
        return {
          ...state,
          isMapPreferenceBoundariesRequesting: false,
          mapPreferenceBoundariesData: false,
          mapPreferenceBoundariesDataError: action.payload.error ? false : action.payload.response,
          mapPreferenceBoundariesDataNetworkError: action.payload.error ? action.payload.error : false,
        };


    default:
      return { ...state };
  }
};

export default Map_Preference;
